import type { FormApi } from "final-form";
import { type FormProps, Form as ReactFinalForm } from "react-final-form";

import { FormDirtyFieldsProvider, useDirtyFieldsRef } from "./FormDirtyFields";
import { useFocusOnErrorDecorator } from "./FormFocusOnError";
import { FormSubmitterProvider, useSubmitterRef } from "./FormSubmitter";

export type BaseFormContext = {
  submitter: HTMLElement | undefined;
  dirtyFields: Record<string, boolean> | undefined;
};

export type BaseFormProps<FormValues = Record<string, any>> = Omit<
  FormProps<FormValues>,
  "onSubmit"
> & {
  name?: string;
  onSubmit: (
    values: FormValues,
    form: FormApi<FormValues, Partial<FormValues>>,
    context: BaseFormContext,
  ) => void;
};

function BaseFinalForm<FormValues = Record<string, any>>({
  name,
  onSubmit,
  decorators = [],
  ...props
}: BaseFormProps<FormValues>): React.ReactElement {
  const focusOnError = useFocusOnErrorDecorator(name);
  const submitterRef = useSubmitterRef();
  const dirtyFieldsRef = useDirtyFieldsRef();
  return (
    <ReactFinalForm
      subscription={{}}
      decorators={[...decorators, focusOnError]}
      name={name}
      onSubmit={async (values: FormValues, form) => {
        const context = {} as BaseFormContext;
        // submitter is inspired from https://developer.mozilla.org/en-US/docs/Web/API/SubmitEvent/submitter
        // it represents the button that triggers the submit of the form
        Object.defineProperty(context, "submitter", {
          get: () => submitterRef.current,
        });
        Object.defineProperty(context, "dirtyFields", {
          get: () => dirtyFieldsRef.current,
        });
        return onSubmit(values, form, context);
      }}
      {...props}
    />
  );
}

export function BaseForm<FormValues = Record<string, any>>(
  props: BaseFormProps<FormValues>,
): React.ReactElement {
  return (
    <FormSubmitterProvider>
      <FormDirtyFieldsProvider>
        <BaseFinalForm {...props} />
      </FormDirtyFieldsProvider>
    </FormSubmitterProvider>
  );
}
