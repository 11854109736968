import { createContext, memo, useContext, useEffect, useMemo } from "react";
import { IoPencil } from "swash/Icon";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { EditorBlockCapsuleToolbarButton } from "@/components/teleporters/EditorBlockCapsule";
import {
  RemoteEditImageDialogDisclosure,
  useEditImageRemoteDialogContext,
} from "@/containers/image/ImageEditDialog";

const ImageNodeEditImageContext = createContext();

export const useImageNodeEditImageContext = () =>
  useContext(ImageNodeEditImageContext);

export const ImageNodeEditImageProvider = ({
  children,
  renderPreview,
  renderEditor,
}) => {
  const value = useMemo(
    () => ({
      renderPreview,
      renderEditor,
    }),
    [renderPreview, renderEditor],
  );
  return (
    <ImageNodeEditImageContext.Provider value={value}>
      {children}
    </ImageNodeEditImageContext.Provider>
  );
};

export const ImageNodeEditImage = memo(({ image, onEdit }) => {
  const dialog = useEditImageRemoteDialogContext();
  const editing = dialog.open;
  const previousEditing = usePrevious(editing);
  const onEditRef = useLiveRef(onEdit);
  const { renderPreview, renderEditor } = useImageNodeEditImageContext();

  useEffect(() => {
    const onEdit = onEditRef.current;
    if (!onEdit) return;
    if (previousEditing === undefined) return;
    if (previousEditing === editing) return;
    onEdit(editing);
  }, [onEditRef, editing, previousEditing]);

  return (
    <>
      <RemoteEditImageDialogDisclosure
        dialogProps={{
          imageId: image.id,
          preview: renderPreview({ imageId: image.id }),
          editor: renderEditor({ imageId: image.id }),
        }}
        render={
          <EditorBlockCapsuleToolbarButton
            title="Éditer l’image"
            icon={IoPencil}
          />
        }
      />
    </>
  );
});
