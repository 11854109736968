import * as React from "react";
import { forwardRef } from "react";

const SvgBubbleExclamation = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12c0-5.376 4.374-9.75 9.75-9.75s9.75 4.374 9.75 9.75-4.374 9.75-9.75 9.75a9.69 9.69 0 0 1-4.53-1.118l-3.853.964a1 1 0 0 1-1.213-1.213l.964-3.853A9.69 9.69 0 0 1 2.25 12Zm9.23 5.088a.937.937 0 1 0 1.04-1.559.937.937 0 0 0-1.04 1.559Zm1.27-3.552.268-5.72-.002.001a1.02 1.02 0 1 0-2.036 0v.003l.27 5.716a.75.75 0 0 0 1.5 0Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBubbleExclamation);
export default ForwardRef;
