import { createContext, useCallback, useContext, useState } from "react";

export type ActiveContextValue = {
  readonly form: boolean;
  readonly fields: string[];
};

const ActiveContext = createContext<ActiveContextValue | null>(null);
const SetActiveContext = createContext<React.Dispatch<
  React.SetStateAction<ActiveContextValue>
> | null>(null);

export const ActiveProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [active, setActive] = useState<ActiveContextValue>({
    form: false,
    fields: [],
  });
  return (
    <ActiveContext.Provider value={active}>
      <SetActiveContext.Provider value={setActive}>
        {children}
      </SetActiveContext.Provider>
    </ActiveContext.Provider>
  );
};

export const useActiveState = () => useContext(ActiveContext);
export const useSetActiveState = () => useContext(SetActiveContext);

export const useActivateForm = () => {
  const setActive = useSetActiveState();
  return useCallback(
    (active: boolean) => {
      if (!setActive) return;
      setActive((state) => ({ ...state, form: active }));
    },
    [setActive],
  );
};

export const useActivateField = (name: string) => {
  const setActive = useSetActiveState();
  return useCallback(
    (active: boolean) => {
      if (!setActive) return;
      setActive((state) => {
        const { fields } = state;
        const index = fields.indexOf(name);
        if (!active && index !== -1) {
          fields.splice(index, 1);
          return { ...state, fields };
        }
        if (active && index === -1) {
          return { ...state, fields: [...fields, name] };
        }
        return state;
      });
    },
    [name, setActive],
  );
};
