import { EnumFilterField } from "@/containers/search/filterFields/EnumFilterField";

export function BooleanFilterField(props) {
  return (
    <EnumFilterField
      format={(v) => props.enum.find((e) => e.value === v)}
      parse={(v) => v?.value}
      clearable
      {...props}
    />
  );
}
