import { Button as AriakitButton } from "ariakit/button";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";
import { Button } from "swash/Button";
import { Card, CardBody } from "swash/Card";
import { IoAddCircle, IoCloseCircleOutline } from "swash/Icon";
import { cn } from "swash/utils/classNames";

import { Tree } from "@/components/Tree";
import {
  useFocusErrorField,
  useFocusFirstField,
} from "@/components/forms/FormFocusOnError";

const TreeLabel = forwardRef(({ children, className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      className,
      "cursor-pointer overflow-hidden text-ellipsis text-primary-on",
      "hover:text-primary-on-hover hover:underline",
      "hover:aria-selected:text-dusk-on hover:aria-selected:no-underline",
      "focus:text-primary-on-strong focus:outline-none",
      "aria-selected:cursor-default aria-selected:text-dusk-on",
    )}
    style={{ maxWidth: "150px" }}
    {...props}
  >
    {children}
  </div>
));

function getTabId(name) {
  return `tab-${name}`;
}

function getTabPanelId(name) {
  return `tabpanel-${name}`;
}

function TreeFormTabPanel({ hidden, name, value, children }) {
  const ref = useRef();
  const focusFirstField = useFocusFirstField(ref);
  useEffect(() => {
    if (!hidden) {
      focusFirstField();
    }
  }, [hidden, focusFirstField, name]);
  return (
    <div
      role="tabpanel"
      id={getTabPanelId(name)}
      aria-labelledby={getTabId(name)}
      hidden={hidden}
      ref={ref}
    >
      {children({ name, value })}
    </div>
  );
}

export function TreeArrayField({
  name,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledby,
  formatValues,
  itemLabel = (item) => item.label,
  addButtonLabel,
  deleteButtonLabel,
  noResultMessage,
  renderFields,
  ...props
}) {
  const { fields, meta } = useFieldArray(name);
  const form = useForm();
  const treeFormContainerRef = useRef();
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const focusErrorField = useFocusErrorField(treeFormContainerRef);
  const selectField = (index) => {
    if (meta.invalid && selectedFieldId !== null) {
      focusErrorField({
        [name]: meta.error,
      });
      return;
    }
    setSelectedFieldId(fields.value[index].id);
  };
  const addField = () => {
    if (meta.invalid && selectedFieldId !== null) {
      focusErrorField({
        [name]: meta.error,
      });
      return;
    }
    const value = formatValues();
    fields.push(value);
    setSelectedFieldId(value.id);
  };
  const removeField = (index) => {
    fields.remove(index);
  };

  const items = fields.map((name, index) => {
    const fieldValue = fields.value[index];
    return {
      id: getTabId(name),
      first: index === 0,
      last: index === fields.length - 1,
      children: [],
      hasChildren: false,
      isExpanded: true,
      isChildrenLoading: false,
      data: {
        title: fieldValue.label,
        field: fieldValue,
      },
      leaf: true,
      content: (
        <div className="flex items-center gap-1">
          <AriakitButton
            as={TreeLabel}
            role="tab"
            aria-selected={fieldValue.id === selectedFieldId}
            aria-controls={getTabPanelId(name)}
            id={getTabId(name)}
            onClick={() => selectField(index)}
          >
            {itemLabel(fieldValue) || "..."}
          </AriakitButton>
          <Button
            appearance="text"
            variant="secondary"
            scale="xs"
            iconOnly
            title={deleteButtonLabel}
            onClick={(event) => {
              event.stopPropagation();
              removeField(index);
            }}
          >
            <IoCloseCircleOutline />
          </Button>
        </div>
      ),
    };
  });

  const tree = {
    rootId: 0,
    items: {
      0: {
        id: "0",
        children: items.map((item) => item.id),
        hasChildren: items.length > 0,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          title: "root",
        },
      },
      ...items.reduce((acc, item) => ({ ...acc, [item.id]: item }), []),
    },
  };
  const addButton = (
    <Button
      type="button"
      scale="sm"
      onClick={() => {
        addField();
      }}
    >
      <IoAddCircle />
      {addButtonLabel}
    </Button>
  );

  return (
    <>
      <Card {...props}>
        <CardBody className="py-0">
          {items.length > 0 ? (
            <>
              <div
                className="-mx-4 flex flex-wrap"
                style={{ minHeight: "280px" }}
              >
                <div
                  className="max-w-1/3 flex flex-col rounded-md border-r border-r-grey-border-light bg-grey-bg-light p-4"
                  style={{ flex: "0 0 33.333%" }}
                >
                  <div className="mb-4">{addButton}</div>
                  <div
                    className="flex-1 overflow-auto"
                    aria-label={ariaLabel}
                    aria-labelledby={ariaLabelledby}
                    role="tablist"
                  >
                    <Tree
                      value={tree}
                      onChange={(nextTree) => {
                        form.change(
                          name,
                          nextTree.items[0].children.map(
                            (childId) => nextTree.items[childId].data.field,
                          ),
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="max-w-2/3 p-4"
                  style={{ flex: "0 0 66.667%" }}
                  ref={treeFormContainerRef}
                >
                  {fields.map((name, index) => {
                    const value = fields.value[index];
                    return (
                      <TreeFormTabPanel
                        key={name}
                        hidden={value.id !== selectedFieldId}
                        name={name}
                        value={value}
                      >
                        {renderFields}
                      </TreeFormTabPanel>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="flex  flex-col items-center gap-2 py-6">
              <div>{noResultMessage}</div>
              <div>{addButton}</div>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
