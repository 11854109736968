import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { LockRequestCompletion, RequestCompletionListener } from "./LockApi";

type ResourceMap = { [key: string]: string };

const ResourcesContext = createContext<string[] | null>(null);

type Callbacks = {
  setResourceMap: React.Dispatch<React.SetStateAction<ResourceMap>>;
  listenRequestCompletion: (
    resource: string,
    listener: import("./LockApi").RequestCompletionListener,
  ) => () => void;
  emitRequestCompletion: (resource: string, data: any) => void;
};

const CallbacksContext = createContext<Callbacks | null>(null);

export const LockTrackerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const requestCompletionListeners = useRef<{
    [resource: string]: RequestCompletionListener[];
  }>({});
  const [resourceMap, setResourceMap] = useState<ResourceMap>({});
  const resources = useMemo(
    () => Array.from(new Set(Object.values(resourceMap))),
    [resourceMap],
  );
  const listenRequestCompletion = useCallback(
    (resource: string, listener: RequestCompletionListener) => {
      const listeners = requestCompletionListeners.current[resource] || [];
      requestCompletionListeners.current[resource] = listeners;
      listeners.push(listener);
      return () => {
        const listeners = requestCompletionListeners.current[resource];
        if (listeners) {
          requestCompletionListeners.current[resource] = listeners.filter(
            (x) => x !== listener,
          );
        }
      };
    },
    [],
  );

  const emitRequestCompletion = useCallback(
    (resource: string, data: LockRequestCompletion) => {
      const listeners = requestCompletionListeners.current[resource];
      if (!listeners) return;
      listeners.forEach((listener) => {
        listener(data);
      });
    },
    [],
  );

  // Cleanup listeners when resources change
  useEffect(() => {
    const listeners = requestCompletionListeners.current;
    Object.keys(listeners).forEach((key) => {
      if (!resources.includes(key)) delete listeners[key];
    });
  }, [resources]);

  const callbacks = useMemo(
    () => ({ listenRequestCompletion, emitRequestCompletion, setResourceMap }),
    [listenRequestCompletion, emitRequestCompletion],
  );

  return (
    <ResourcesContext.Provider value={resources}>
      <CallbacksContext.Provider value={callbacks}>
        {children}
      </CallbacksContext.Provider>
    </ResourcesContext.Provider>
  );
};

export function useLockedResources() {
  const resources = useContext(ResourcesContext);
  if (!resources) {
    throw new Error(`LockTrackerProvider is missing`);
  }
  return resources;
}

function useCallbacks() {
  const callbacks = useContext(CallbacksContext);
  if (!callbacks) {
    throw new Error(`LockTrackerProvider is missing`);
  }
  return callbacks;
}

function useSetLockResourceMap() {
  const callbacks = useCallbacks();
  return callbacks.setResourceMap;
}

/**
 * Get listenRequestCompletion callback.
 */
export function useListenRequestCompletion() {
  const { listenRequestCompletion } = useCallbacks();
  return listenRequestCompletion;
}

/**
 * Get emitRequestCompletion callback.
 */
export function useEmitRequestCompletion() {
  const { emitRequestCompletion } = useCallbacks();
  return emitRequestCompletion;
}

/**
 * Track a lock resource.
 */
export function useTrackLockResource(resource: string) {
  const [trackerId] = useState(() =>
    String(Math.random().toString(36).slice(2, 9)),
  );
  const setResourceMap = useSetLockResourceMap();
  useEffect(() => {
    setResourceMap((map) => ({ ...map, [trackerId]: resource }));
    return () => {
      setResourceMap((map) => {
        const cloned = { ...map };
        delete cloned[trackerId];
        return cloned;
      });
    };
  }, [resource, trackerId, setResourceMap]);
}

/**
 * Track a lock resource.
 */
export const TrackLockResource: React.FC<{ id: string }> = ({ id }) => {
  useTrackLockResource(id);
  return null;
};
