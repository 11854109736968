import moment from "moment/moment";

export const formatDuration = (duration?: moment.Duration) => {
  if (!duration?.isValid() || !duration.asSeconds()) {
    return "–";
  }
  if (duration.months()) {
    return `${duration.months()}mois et ${duration.days()}j`;
  }
  if (duration.days()) {
    return `${duration.days()}j et ${duration.hours()}h`;
  }
  if (duration.hours()) {
    return `${duration.hours()}h et ${duration.minutes()}min`;
  }
  if (duration.minutes()) {
    return `${duration.minutes()}min et ${duration.seconds()}sec`;
  }
  return `${duration.seconds()}sec`;
};
