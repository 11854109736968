import { useMemo, useState } from "react";

const resolvePreset = (preset) => {
  const [getConfig, options] = Array.isArray(preset) ? preset : [preset, {}];
  return resolve(getConfig(options));
};

export const resolve = ({ presets = [], plugins = [] }) => {
  return [...presets.flatMap((preset) => resolvePreset(preset)), ...plugins];
};

export const useEditorPlugins = ({ presets, plugins }) => {
  const [initial] = useState({
    plugins,
    presets,
  });

  return useMemo(
    () => resolve({ presets: initial.presets, plugins: initial.plugins }),
    [initial],
  );
};
