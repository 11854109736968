import { toggledArticlesQuery } from "@/containers/hoc/store/articles";

const defaults = {
  toggledArticles: [],
};

const Mutation = {
  toggleMoreInfoArticle: (_, { id, value }, { cache }) => {
    const { toggledArticles } = cache.readQuery({
      query: toggledArticlesQuery,
    });

    const updatedArticle = {
      __typename: "ToggledArticle",
      id,
      isToggled: value || false,
    };

    cache.writeQuery({
      query: toggledArticlesQuery,
      data: {
        toggledArticles: [
          ...toggledArticles.filter(
            (toggledArticle) => toggledArticle.id !== id,
          ),
          updatedArticle,
        ],
      },
    });
    return null;
  },
};

export default { defaults, resolvers: { Mutation } };
