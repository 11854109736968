/* eslint-disable no-param-reassign */
import {
  Disclosure,
  DisclosureContent,
  DisclosureContentProps,
  DisclosureProps,
  DisclosureStore,
  DisclosureStoreProps,
  useDisclosureStore,
} from "@ariakit/react-legacy";
import * as React from "react";
import { forwardRef, useLayoutEffect, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

const DEFAULT_IN_TRANSITION = `height ease 250ms`;
const DEFAULT_OUT_TRANSITION = `height ease 250ms`;

function expand(element: HTMLElement) {
  const transition = element.style.transition || DEFAULT_IN_TRANSITION;

  element.style.height = "";
  element.style.transition = "";

  requestAnimationFrame(() => {
    const height = element.scrollHeight;
    element.style.height = "0";

    requestAnimationFrame(() => {
      element.style.transition = transition;
      element.style.height = `${height}px`;
    });
  });
}

function collapse(element: HTMLElement) {
  const height = element.scrollHeight;
  const transition = element.style.transition || DEFAULT_OUT_TRANSITION;

  element.style.transition = "";

  requestAnimationFrame(() => {
    element.style.height = `${height}px`;

    requestAnimationFrame(() => {
      element.style.transition = transition;
      element.style.height = "0";
    });
  });
}

export type ExpandDisclosureStore = {
  disclosure: DisclosureStore;
  elementRef: React.MutableRefObject<HTMLElement | undefined>;
};

export function useExpandDisclosureStore(
  options: DisclosureStoreProps,
): ExpandDisclosureStore {
  const elementRef = useRef<HTMLElement>();
  const readyRef = useRef(false);
  const disclosure = useDisclosureStore({ ...options, animated: true });
  const open = disclosure.useState("open");

  useLayoutEffect(() => {
    if (!readyRef.current) {
      readyRef.current = true;
      return;
    }
    if (!elementRef.current) return;
    if (open) {
      expand(elementRef.current);
    } else {
      collapse(elementRef.current);
    }
  }, [open]);

  return {
    elementRef,
    disclosure,
  };
}

export type ExpandDisclosureOptions = ExpandDisclosureStore &
  Omit<DisclosureProps, "store">;

export const ExpandDisclosure = forwardRef<
  HTMLButtonElement,
  ExpandDisclosureOptions
>(({ elementRef, disclosure, ...props }, ref) => {
  return <Disclosure ref={ref} store={disclosure} {...props} />;
});

if (process.env["NODE_ENV"] !== "production") {
  ExpandDisclosure.displayName = "ExpandDisclosure";
}

export type ExpandDisclosureContentOptions = ExpandDisclosureStore &
  Omit<DisclosureContentProps, "store">;

export const ExpandDisclosureContent = forwardRef<
  HTMLDivElement,
  ExpandDisclosureContentOptions
>(({ elementRef, disclosure, ...props }, ref) => {
  const refs = useMemo(() => mergeRefs([ref, elementRef]), [ref, elementRef]);
  return (
    <DisclosureContent
      ref={refs}
      store={disclosure}
      style={{ overflow: "hidden", ...props.style }}
      onTransitionEnd={(event) => {
        if (event.target !== event.currentTarget) return;
        event.currentTarget.style.height = "";
        event.currentTarget.style.transition = "";
        disclosure.stopAnimation();
      }}
      {...props}
    />
  );
});

if (process.env["NODE_ENV"] !== "production") {
  ExpandDisclosureContent.displayName = "ExpandDisclosureContent";
}
