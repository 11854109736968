import { memo } from "react";

export const EndRow = memo(() => {
  return (
    <div className="flex grow justify-center p-4 text-center font-accent text-sm">
      Fin des résultats
    </div>
  );
});

export const estimateEndRowSize = () => {
  return 52;
};
