import { ApolloClient, gql } from "@apollo/client";

import { cache } from "./cache";
import { link } from "./link";
import { defaults, resolvers } from "./resolvers";

/* Using this trick we ignore checking with ESLint */
const gqlClient = gql;

cache.writeQuery({
  query: gqlClient`
    query InitQuery {
      rightPanels @client {
        id
        isOpened
        metadata
      }
      expandedPublicationColumns @client {
        id
        isExpanded
      }
      toggledArticles @client {
        id
        isToggled
      }
    }
  `,
  data: defaults,
});

export const apolloClient = new ApolloClient({
  cache,
  link,
  resolvers,
});
