import { EditorState, SelectionState } from "draft-js-es";

/**
 * @param {import('draft-js').Editor} editor
 */
export const getFocusedBlockKey = ({ editorState, hasFocus }) => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  return hasFocus ? anchorKey : null;
};

/**
 * Get a list of blocks with their own selection.
 * @param {import('draft-js').EditorState} editorState
 * @returns {{ blockKey: string, startOffset: number, endOffset: number }[]}
 */
export const expandSelection = (editorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const selections = [];

  let blockKey = selection.getStartKey();
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const block = content.getBlockForKey(blockKey);
    selections.push({
      blockKey,
      startOffset:
        blockKey === selection.getStartKey() ? selection.getStartOffset() : 0,
      endOffset:
        blockKey === selection.getEndKey()
          ? selection.getEndOffset()
          : block.getLength(),
    });
    if (blockKey === selection.getEndKey()) {
      return selections;
    }
    const nextBlock = content.getBlockAfter(blockKey);
    if (!nextBlock) return selections;

    blockKey = nextBlock.getKey();
  }
};

/**
 * @param {object} params
 * @param {import('draft-js').ContentState} params.contentState
 * @param {string} params.startKey
 * @param {string} params.endKey
 */
export const getSelectedBlocks = ({ contentState, startKey, endKey }) => {
  const isSameBlock = startKey === endKey;
  const startingBlock = contentState.getBlockForKey(startKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = startKey;

    while (blockKey !== endKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      if (!nextBlock) break;
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
    }
  }

  return selectedBlocks;
};

/**
 * @param {import('draft-js').Editor} editor
 */
export const getFocusedEntityKey = (editor) => {
  const focusedBlockKey = getFocusedBlockKey(editor);
  if (!focusedBlockKey) return null;

  const { editorState } = editor;

  const blockMap = editorState.getCurrentContent().getBlockMap();
  const anchorBlock = blockMap.get(focusedBlockKey);
  if (!anchorBlock) return null;

  const selection = editorState.getSelection();
  const entityKey = anchorBlock.getEntityAt(selection.getAnchorOffset());
  if (!entityKey) return null;

  return entityKey;
};

/**
 * @param {import('draft-js').ContentState} contentState
 * @param {import('draft-js').SelectionState} selectionState
 */
export const getSelectedBlocksMap = (contentState, selectionState) => {
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
};

/**
 * @description https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
 * @param {import('draft-js').ContentState} contentState
 * @param {import('draft-js').SelectionState | null} [selectionState]
 * @returns {string}
 */
export const getTextFromSelection = (contentState, selectionState) => {
  if (!selectionState) return "";
  const selectedBlocks = getSelectedBlocksMap(
    contentState,
    selectionState,
  ).toList();

  if (!selectedBlocks || selectedBlocks.size === 0) return "";

  let selectedText = "";
  let start = selectionState.getAnchorOffset();
  let end = selectionState.getFocusOffset();

  if (selectionState.getIsBackward()) {
    const temp = start;
    start = end;
    end = temp;
  }

  for (let i = 0; i < selectedBlocks.size; i += 1) {
    const blockStart = i === 0 ? start : 0;
    const selectedBlock = selectedBlocks.get(i);
    if (!selectedBlock || selectedBlock.getType() === "atomic") continue;
    if (i > 0) selectedText += "\n";
    const blockEnd =
      i === selectedBlocks.size - 1 ? end : selectedBlock.getText().length;
    selectedText += selectedBlock.getText().slice(blockStart, blockEnd);
  }

  return selectedText;
};

/**
 * Check if the selection is a valid one.
 * @param {import('draft-js').ContentState} contentState
 * @param {import('draft-js').SelectionState} selection
 * @returns {boolean}
 */
export const checkIsValidSelection = (contentState, selection) => {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  return Boolean(
    contentState.getBlockForKey(startKey) &&
      contentState.getBlockForKey(endKey),
  );
};

/**
 * @param {import('draft-js').ContentBlock} block
 * @param {import('draft-js').EditorState} editorState
 */
export const getSelectionByTripleClick = (block, editorState) => {
  const startSelection = new SelectionState({
    anchorOffset: 0,
    anchorKey: block.key,
    focusOffset: block.getText().length,
    focusKey: block.key,
  });

  return EditorState.forceSelection(editorState, startSelection);
};
