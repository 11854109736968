import { forwardRef } from "react";
import { type VariantProps, cn, cva } from "swash/utils/classNames";

export const BannerBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      "flex h-11 items-center px-4 py-2 font-bold",
      props.className,
    )}
  >
    {children}
  </div>
);

const innerBannerVariants = cva(
  "relative h-11 overflow-hidden font-accent text-sm",
  {
    variants: {
      variant: {
        success: "text-success-on-strong bg-success-bg",
        danger: "text-danger-on-strong bg-danger-bg",
        warning: "text-warning-on-strong bg-warning-bg",
        neutral: "text-primary-on-strong bg-primary-bg",
      },
    },
    defaultVariants: {
      variant: "warning",
    },
  },
);

const InnerBanner = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & BannerProps
>(({ children, variant = "warning", animated, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn(
      innerBannerVariants({ variant }),
      animated &&
        cn(
          "h-0 opacity-50 transition-all duration-300 ease-in-out",
          "data-[enter]:h-11 data-[enter]:opacity-100",
          "data-[leave]:duration-200",
        ),
      props.className,
    )}
  >
    {children}
  </div>
));

export const BannerIcon = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn("mr-2 inline-flex align-[-15%]", props.className)}
  >
    {children}
  </div>
));

export type BannerProps = VariantProps<typeof innerBannerVariants> & {
  animated?: boolean;
};

export const Banner = forwardRef<
  HTMLDivElement,
  BannerProps & React.HTMLAttributes<HTMLDivElement>
>(({ variant = "warning", animated, ...props }, ref) => {
  return (
    <InnerBanner
      ref={ref}
      role="alert"
      variant={variant}
      animated={animated}
      {...props}
    />
  );
});
