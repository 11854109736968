import { gql } from "@apollo/client";
import clsx from "clsx";
import { Clickable } from "swash/Clickable";

import { ArticleExposurePrintDate_articleFragment } from "@/gql-types";

import { formatDate } from "./ArticlePublishDate";
import { ArticleExposurePanel } from "./panels/exposure/ArticleExposurePanel";

export type ArticleExposurePrintDateProps = {
  article: ArticleExposurePrintDate_articleFragment;
  className?: string;
};

export const ArticleExposurePrintDate = (
  props: ArticleExposurePrintDateProps,
) => {
  const { article, className } = props;
  const activateMetaPanel = ArticleExposurePanel.useActivate();

  return (
    <Clickable
      className={clsx(
        className,
        "flex h-full select-none items-start gap-2 p-1 text-sm",
      )}
      onClick={(e) => {
        e.stopPropagation();
        activateMetaPanel({
          articleId: article.id,
          defaultOpenPrintPanel: true,
        });
      }}
    >
      <div>
        {article.printDate ? formatDate(article.printDate) : "Non daté"}
      </div>
    </Clickable>
  );
};

ArticleExposurePrintDate.fragments = {
  article: gql`
    fragment ArticleExposurePrintDate_article on Article {
      id
      printDate
    }
  `,
};
