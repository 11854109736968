import { EditorState } from "draft-js-es";

import { selectBlock } from "./selectBlock";

const getDirectionBlock = (contentState, focusKey, method, withSelection) => {
  const directionBlock = contentState[method](focusKey);
  if (directionBlock?.getType() === "atomic" && withSelection) {
    return getDirectionBlock(
      contentState,
      directionBlock.getKey(),
      method,
      withSelection,
    );
  }
  return directionBlock;
};

const getBlock = ({
  contentState,
  direction,
  atEnd,
  atBegin,
  focusKey,
  withSelection,
}) => {
  const currentBlock = contentState.getBlockForKey(focusKey);

  if (direction === "UP" || direction === "LEFT") {
    const previousBlock = getDirectionBlock(
      contentState,
      focusKey,
      "getBlockBefore",
      withSelection,
    );
    return atBegin ? previousBlock || currentBlock : currentBlock;
  }
  if (direction === "DOWN" || direction === "RIGHT") {
    const nextBlock = getDirectionBlock(
      contentState,
      focusKey,
      "getBlockAfter",
      withSelection,
    );
    return atEnd ? nextBlock || currentBlock : currentBlock;
  }
};

const getIndexBlock = (blockKeys, key) =>
  blockKeys.findIndex((blockKey) => blockKey === key);

const getIsBackward = (
  contentState,
  anchorKey,
  focusKey,
  anchorOffset,
  focusOffset,
) => {
  let blockKeys = [];
  contentState.getBlockMap().map(({ key }) => blockKeys.push(key));
  const anchorPosition = getIndexBlock(blockKeys, anchorKey);
  const focusPosition = getIndexBlock(blockKeys, focusKey);
  const sameBlock = focusKey === anchorKey;
  return sameBlock
    ? focusOffset <= anchorOffset
    : focusPosition <= anchorPosition;
};

const ALL_CHARS_WITH_ACCENT = "([a-zA-ZÀ-ÖØ-öø-ɏ])";
const ALL_SPECIAL_CHARS =
  "(\\.|,|\\?|!|\"|'|-|`|\\^|@|#|_|&|\\(|\\)|:|\\$|\\*|%|¨|=|\\+|;|<|>|«|»|’|{|})";

const getFocusOffset = (block, selection, direction, atEnd, atBegin) => {
  const REGEX_FIRST_WORD = new RegExp(
    `^(\\s+)?((${ALL_CHARS_WITH_ACCENT}+|(${ALL_SPECIAL_CHARS}+|[^\\s])))`,
    "gm",
  );
  const REGEX_LAST_WORD = new RegExp(
    `(${ALL_CHARS_WITH_ACCENT}+|(${ALL_SPECIAL_CHARS}+|[^\\s]))(\\s+)*$`,
    "gm",
  );

  const lengthBlockText = block.getLength();
  const focusOffset = selection.getFocusOffset();
  const firstPartOfText = block.getText().slice(0, focusOffset);
  const secondPartPartOfText = block
    .getText()
    .slice(focusOffset, lengthBlockText);

  if (direction === "UP") return 0;
  if (direction === "DOWN") return lengthBlockText;
  if (direction === "LEFT") {
    const match = REGEX_LAST_WORD.exec(firstPartOfText);
    return atBegin ? lengthBlockText : match?.index ?? 0;
  }
  if (direction === "RIGHT") {
    const match = REGEX_FIRST_WORD.exec(secondPartPartOfText);
    const focusPosition = match?.[0].length + firstPartOfText.length;
    return atEnd
      ? 0
      : match?.index !== undefined
        ? focusPosition
        : lengthBlockText;
  }
};

/**
 * Set selection to a block.
 * @param {EditorState} editorState
 * @param {string} direction
 */
export const moveFromCursor = (state, direction, withSelection) => {
  const { editorState, setEditorState } = state;
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  const anchorKey = selectionState.getAnchorKey();
  const focusKey = selectionState.getFocusKey();

  const focusBlock = contentState.getBlockForKey(focusKey);

  const atBegin =
    selectionState.getFocusOffset() === 0 || focusBlock.getType() === "atomic";
  const atEnd =
    selectionState.getFocusOffset() === focusBlock.getLength() ||
    focusBlock.getType() === "atomic";

  const newFocusBlock = getBlock({
    contentState,
    direction,
    atBegin,
    atEnd,
    focusKey,
    withSelection,
  });

  const anchorOffset = selectionState.anchorOffset;
  const focusOffset = getFocusOffset(
    newFocusBlock,
    selectionState,
    direction,
    atEnd,
    atBegin,
  );

  if (newFocusBlock.getType() === "atomic" && !withSelection) {
    setEditorState(selectBlock(editorState, newFocusBlock.getKey()));
    return "handled";
  }

  const selection = selectionState.merge({
    anchorKey: withSelection ? anchorKey : newFocusBlock.getKey(),
    anchorOffset: withSelection ? anchorOffset : focusOffset,
    focusOffset,
    focusKey: newFocusBlock.getKey(),
    isBackward: withSelection
      ? getIsBackward(
          contentState,
          anchorKey,
          newFocusBlock.getKey(),
          anchorOffset,
          focusOffset,
        )
      : false,
  });

  setEditorState(EditorState.forceSelection(editorState, selection));
  return "handled";
};
