import { gql } from "@apollo/client";
import { ButtonProps } from "swash/Button";

import { PublishedLink } from "@/components/PublishedLink";

export type LivePostPublishedLinkProps = {
  post: {
    url: string | null;
  };
} & { className?: string; scale?: ButtonProps["scale"] };

export const LivePostPublishedLink = (props: LivePostPublishedLinkProps) => {
  return (
    <PublishedLink
      url={props.post.url}
      className={props.className}
      scale={props.scale}
      iconOnly
    />
  );
};

LivePostPublishedLink.fragments = {
  post: gql`
    fragment LivePostPublishedLink_post on Post {
      url
    }
  `,
};
