import * as React from "react";
import { forwardRef } from "react";

const SvgWorkflowStateCorrected = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.5 8c0-3.589 2.911-6.5 6.5-6.5s6.5 2.911 6.5 6.5-2.911 6.5-6.5 6.5A6.5 6.5 0 0 1 1.5 8M8 2.5A5.5 5.5 0 0 0 2.5 8c0 3.036 2.464 5.5 5.5 5.5s5.5-2.464 5.5-5.5S11.036 2.5 8 2.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M8.474 11.333q-.873 0-1.587-.383a2.87 2.87 0 0 1-1.139-1.121q-.414-.747-.415-1.81 0-1.05.426-1.799a2.95 2.95 0 0 1 1.16-1.15 3.2 3.2 0 0 1 1.597-.403q.66 0 1.171.246.51.244.841.57l-.66.737a2.1 2.1 0 0 0-.596-.403 1.6 1.6 0 0 0-.724-.157 1.84 1.84 0 0 0-1.011.285 1.9 1.9 0 0 0-.692.796q-.245.521-.245 1.25 0 1.1.522 1.73.52.62 1.394.62.469 0 .83-.178.363-.186.66-.482l.66.718q-.425.453-.979.698a3 3 0 0 1-1.213.236"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWorkflowStateCorrected);
export default ForwardRef;
