import { memo, useEffect } from "react";

export function removeScript(src) {
  const script = document.querySelector(`[src="${src}"]`);
  if (script) {
    script.remove();
  }
}

export async function injectScript({
  src,
  onload,
  type = "text/javascript",
  parent: customParent,
}) {
  if (document.querySelector(`[src="${src}"]`)) return undefined;

  const parent = customParent || document.getElementsByTagName("head")[0];

  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = type;
    script.async = true;
    script.src = src;
    script.onload = () => {
      try {
        if (onload && typeof onload === "function") {
          onload();
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    script.onerror = reject;

    parent.appendChild(script);
  });
}

export const useScript = (src, { onload, parent, type } = {}) => {
  useEffect(() => {
    injectScript({ src, onload, parent, type }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

    return () => removeScript(src);
  }, [src, onload, parent, type]);
};

export const Script = memo(({ src, onload, parent }) => {
  useScript(src, { onload, parent });
  return null;
});

if (process.env["NODE_ENV"] !== "production") {
  Script.displayName = "Script";
}
