import * as React from "react";
import { forwardRef } from "react";

const SvgVersions = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.364 1c.3 0 .545.224.545.5v.75a2.183 2.183 0 0 1 0 4.227v3.41a2.183 2.183 0 0 1 0 4.226v3.41a2.183 2.183 0 0 1 0 4.227v.75c0 .276-.244.5-.545.5-.302 0-.546-.224-.546-.5v-.75a2.183 2.183 0 0 1 0-4.227v-3.41l.132.03a2.182 2.182 0 0 1-.132-4.256v-3.41a2.182 2.182 0 1 1 0-4.226V1.5c0-.276.244-.5.546-.5Zm.23 5.533c-.064.007-.13.011-.195.012l.195-.012Zm.244 7.597a2.185 2.185 0 0 1-.44.051l.44-.05Zm-.474-8.585a1.182 1.182 0 1 0 0-2.363 1.182 1.182 0 0 0 0 2.363Zm0 7.637a1.182 1.182 0 1 0 0-2.364 1.182 1.182 0 0 0 0 2.364Zm0 7.636a1.182 1.182 0 1 0 0-2.363 1.182 1.182 0 0 0 0 2.363ZM9.818 3.273h12V2.182h-12c-.903 0-1.636.733-1.636 1.636V4.91c0 .904.732 1.637 1.636 1.637h12V5.455h-12a.545.545 0 0 1-.545-.546V3.82c0-.302.244-.546.545-.546ZM9.818 10.909h12v-1.09h-12c-.903 0-1.636.732-1.636 1.635v1.091c0 .904.732 1.637 1.636 1.637h12V13.09h-12a.545.545 0 0 1-.545-.546v-1.09c0-.302.244-.546.545-.546ZM9.818 18.546h12v-1.091h-12c-.903 0-1.636.732-1.636 1.636v1.09c0 .905.732 1.637 1.636 1.637h12v-1.09h-12a.545.545 0 0 1-.545-.546V19.09c0-.301.244-.546.545-.546Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVersions);
export default ForwardRef;
