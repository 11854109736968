import { gql } from "@apollo/client";

import {
  DateCellArticle,
  dateCell,
} from "@/containers/article/list/cells/date";
import {
  DeleteCellArticle,
  deleteCell,
} from "@/containers/article/list/cells/delete";
import {
  DescriptionCellArticle,
  descriptionCell,
} from "@/containers/article/list/cells/description";
import {
  EditorialLevelCellArticle,
  editorialLevelCell,
} from "@/containers/article/list/cells/editorial-level";
import {
  ExposuresCellArticle,
  exposuresCell,
} from "@/containers/article/list/cells/exposures";
import {
  MediasCellArticle,
  mediasCell,
} from "@/containers/article/list/cells/medias";
import {
  ModificationsCellArticle,
  modificationsCell,
} from "@/containers/article/list/cells/modifications";
import {
  PrintCellArticle,
  printCell,
} from "@/containers/article/list/cells/print";
import {
  SectionsCellArticle,
  sectionsCell,
} from "@/containers/article/list/cells/sections";
import {
  SignaturesCellArticle,
  signaturesCell,
} from "@/containers/article/list/cells/signatures";
import {
  StatesCellArticle,
  statesCell,
} from "@/containers/article/list/cells/states";
import {
  StatusCellArticle,
  statusCell,
} from "@/containers/article/list/cells/status";
import {
  DraggableArticle,
  draggable,
} from "@/containers/article/list/interactions/draggable";
import {
  AuditTrailArticle,
  auditTrailProvider,
} from "@/containers/article/list/providers/auditTrail";
import type {
  CellType,
  Interaction,
  NodeProvider,
} from "@/containers/list/ListState";

export type Article = {
  id: number;
} & AuditTrailArticle &
  DateCellArticle &
  DeleteCellArticle &
  DescriptionCellArticle &
  DraggableArticle &
  EditorialLevelCellArticle &
  ExposuresCellArticle &
  MediasCellArticle &
  ModificationsCellArticle &
  PrintCellArticle &
  SectionsCellArticle &
  SignaturesCellArticle &
  StatesCellArticle &
  StatusCellArticle;

export const ArticleFragment = gql`
  fragment ListSearch_article on Article {
    id
    ...auditTrailProvider_article
    ...dateCell_article
    ...deleteCell_article
    ...descriptionCell_article
    ...draggableInteraction_article
    ...editorialLevelCell_article
    ...exposuresCell_article
    ...mediasCell_article
    ...modificationsCell_article
    ...printCell_article
    ...sectionsCell_article
    ...signaturesCell_article
    ...statesCell_article
    ...statusCell_article
  }

  ${auditTrailProvider.fragment}
  ${dateCell.fragment}
  ${deleteCell.fragment}
  ${draggable.fragment}
  ${descriptionCell.fragment}
  ${editorialLevelCell.fragment}
  ${exposuresCell.fragment}
  ${mediasCell.fragment}
  ${modificationsCell.fragment}
  ${printCell.fragment}
  ${sectionsCell.fragment}
  ${signaturesCell.fragment}
  ${statesCell.fragment}
  ${statusCell.fragment}
`;

export const cells: CellType<Article>[] = [
  statusCell,
  dateCell,
  editorialLevelCell,
  descriptionCell,
  sectionsCell,
  signaturesCell,
  modificationsCell,
  mediasCell,
  exposuresCell,
  printCell,
  statesCell,
  deleteCell,
];

export const interactions: Interaction<Article>[] = [draggable];
export const provider: NodeProvider<Article> = auditTrailProvider;
