import { gql, useSubscription } from "@apollo/client";

import { prependNode } from "@/containers/Apollo";
import { useArticleCommentQueriesOptions } from "@/containers/article/ArticleCommentsContext";
import { ArticleComment } from "@/containers/article/panels/comments/ArticleComment";

const ArticleCommentCreatedSubscription = gql`
  subscription ArticleCommentCreatedSubscription($articleId: Int!) {
    articleCommentCreated(where: { articleId: { eq: $articleId } }) {
      id
      ...ArticleComment_commentThread
    }
  }
  ${ArticleComment.fragments.commentThread}
`;

const ArticleCommentUpdatedSubscription = gql`
  subscription ArticleCommentUpdatedSubscription($articleId: Int!) {
    articleCommentUpdated(where: { articleId: { eq: $articleId } }) {
      id
      ...ArticleComment_commentThread
    }
  }
  ${ArticleComment.fragments.commentThread}
`;

const ArticleCommentResolutionToggledSubscription = gql`
  subscription ArticleCommentResolutionToggledSubscription($articleId: Int!) {
    articleCommentResolutionToggled(where: { articleId: { eq: $articleId } }) {
      comment {
        id
        ...ArticleComment_commentThread
      }
      rank
    }
  }
  ${ArticleComment.fragments.commentThread}
`;

const ArticleCommentDeletionToggledSubscription = gql`
  subscription ArticleCommentDeletionToggledSubscription($articleId: Int!) {
    articleCommentDeletionToggled(where: { articleId: { eq: $articleId } }) {
      comment {
        id
        deleted
        ...ArticleComment_commentThread
      }
      rank
    }
  }
  ${ArticleComment.fragments.commentThread}
`;

const handleArticleCommentToggled =
  (name, queriesOptions) =>
  ({ client, data: { data } }) => {
    if (!data?.[name]) return;
    const { comment, rank } = data[name];
    const queryOptions = queriesOptions[comment.scope];
    const extendedQueryOptions = {
      query: queryOptions.query,
      variables: {
        ...queryOptions.variables,
        resolved: comment.deleted ? comment.resolved : !comment.resolved,
      },
    };
    const queryData = client.readQuery({
      query: extendedQueryOptions.query,
      variables: {
        ...extendedQueryOptions.variables,
      },
    });

    const target = comment.scope === "text" ? "comments" : "notes";
    if (queryData) {
      client.writeQuery({
        query: queryOptions.query,
        variables: extendedQueryOptions.variables,
        data: {
          article: {
            ...queryData.article,
            [target]: {
              ...queryData.article[target],
              nodes: queryData.article[target].nodes.filter(
                ({ id }) => id !== comment.id,
              ),
              totalCount: queryData.article[target].totalCount - 1,
            },
          },
        },
      });
    }
    if (comment.deleted) return;
    const reverseQueryData = client.readQuery({
      query: queryOptions.query,
      variables: {
        ...queryOptions.variables,
        resolved: comment.resolved,
      },
    });

    if (reverseQueryData) {
      if (rank > reverseQueryData.article[target].nodes.length + 1) {
        // rank is out of bounds, no need to update cache, load more will take care of it
        return;
      }
      let nodes = [...reverseQueryData.article[target].nodes];
      // push new comment to the right rank
      nodes.splice(rank, 0, comment);
      client.writeQuery({
        query: queryOptions.query,
        variables: {
          ...queryOptions.variables,
          resolved: comment.resolved,
        },
        data: {
          article: {
            ...reverseQueryData.article,
            [target]: {
              ...reverseQueryData.article[target],
              nodes,
              totalCount: reverseQueryData.article[target].totalCount + 1,
            },
          },
        },
      });
    }
  };

export const useArticleCommentCRUDSubscriptions = (articleId) => {
  const queriesOptions = useArticleCommentQueriesOptions();

  useSubscription(ArticleCommentCreatedSubscription, {
    variables: { articleId },
    onData: ({ client, data: { data } }) => {
      if (!data?.articleCommentCreated) return;
      const articleCommentCreated = data.articleCommentCreated;
      const queryOptions = queriesOptions[articleCommentCreated.scope];
      const queryData = client.readQuery({
        query: queryOptions.query,
        variables: { ...queryOptions.variables, resolved: false },
      });
      const target =
        articleCommentCreated.scope === "text" ? "comments" : "notes";

      // ne need to update cache if no data yet
      if (!queryData) return;
      const article = queryData.article;
      const comments = article[target];
      client.writeQuery({
        query: queryOptions.query,
        variables: { ...queryOptions.variables, resolved: false },
        data: {
          article: {
            ...article,
            [target]: {
              ...comments,
              nodes: prependNode(comments.nodes, articleCommentCreated),
              totalCount: comments.totalCount + 1,
            },
          },
        },
      });
    },
  });

  useSubscription(ArticleCommentUpdatedSubscription, {
    variables: { articleId },
    onData: ({ client, data: { data } }) => {
      if (!data?.articleCommentUpdated) return;
      const articleCommentUpdated = data.articleCommentUpdated;
      client.writeFragment({
        id: `CommentThread:${articleCommentUpdated.id}`,
        fragment: ArticleComment.fragments.commentThread,
        fragmentName: "ArticleComment_commentThread",
        data: articleCommentUpdated,
      });
    },
  });

  useSubscription(ArticleCommentResolutionToggledSubscription, {
    variables: { articleId },
    onData: handleArticleCommentToggled(
      "articleCommentResolutionToggled",
      queriesOptions,
    ),
  });

  useSubscription(ArticleCommentDeletionToggledSubscription, {
    variables: { articleId },
    onData: handleArticleCommentToggled(
      "articleCommentDeletionToggled",
      queriesOptions,
    ),
  });
};
