import { gql } from "@apollo/client";
import { useMemo } from "react";

import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";

export function PeriodicalSectionField({
  children,
  required,
  disabled,
  periodical,
  name = "periodicalSectionId",
  label = "Rubrique",
  placeholder = "Sélectionnez une rubrique",
  modal,
}) {
  const selectableSections = useMemo(
    () =>
      disabled
        ? periodical?.sections.nodes
        : periodical?.sections.nodes.filter(({ enabled }) => enabled),
    [periodical, disabled],
  );

  const enumValues = useNodesToEnumArray(selectableSections ?? []);

  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    if (values.periodicalId !== previousValues.periodicalId) {
      form.change(name, null);
    }
  });

  if (!periodical) return null;

  const field = (
    <EnumField
      name={name}
      label={label}
      placeholder={placeholder}
      clearable
      enum={enumValues}
      modal={modal}
      required={required}
      disabled={disabled}
      labelSelector={(item) => item.label}
      sortEntries={(itemA, itemB) => itemA.rank - itemB.rank}
    />
  );

  return typeof children === "function" ? children({ children: field }) : field;
}

export const PeriodicalSectionFieldPeriodicalSectionFragment = gql`
  fragment PeriodicalSectionFieldPeriodicalSectionFragment on PeriodicalSection {
    id
    label
    enabled
    rank
  }
`;
