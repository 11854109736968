/* eslint-disable no-param-reassign */
import { memo } from "react";

import { Superscript } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { handleBinding } from "../utils";
import { INLINE_STYLE, command } from "./superscript";

const SHORTCUT = ["⌘", "⌥", "s"];

export const shortcuts = [
  {
    description: "Exposant",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "superscript-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const SuperscriptToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      command={command}
      active={active}
      label="Exposant"
      shortcut={SHORTCUT}
    >
      <Superscript />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <SuperscriptToolbarButton
      active={editorState.getCurrentInlineStyle().has(INLINE_STYLE)}
    />
  );
};

BlockControls.group = "inline-style";
