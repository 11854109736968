import { gql } from "@apollo/client";
import { memo } from "react";

import { BagRoundOutline } from "@/components/icons";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const ProductNodeIcon = memo(() => {
  return (
    <EditorNodeIcon>
      <BagRoundOutline color="dusk" />
    </EditorNodeIcon>
  );
});

export const ProductNode = ({ product, tooltip }) => (
  <EditorNodeCard>
    <EditorNodeLayout>
      <EditorNodeTooltip tooltip={tooltip}>
        <ProductNodeIcon />
      </EditorNodeTooltip>
      <EditorNodeBody>
        <ChangeText rawText={product.name}>{product.name}</ChangeText>
      </EditorNodeBody>
    </EditorNodeLayout>
  </EditorNodeCard>
);

ProductNode.fragments = {
  product: gql`
    fragment ProductNode_product on Product {
      name
    }
  `,
};
