import { PUBLICATIONS } from "@/config/constants";

export function isNewsletterWorkspace(workspace) {
  return Boolean(workspace && workspace.startsWith("$$newsletter$$"));
}

export function isDigitalPublicationWorkspace(workspace) {
  return Boolean(workspace && workspace.startsWith("$$digitalPublication$$"));
}

export function isPublicationWorkspace(workspace) {
  return (
    PUBLICATIONS.TYPES.includes(workspace) ||
    isNewsletterWorkspace(workspace) ||
    isDigitalPublicationWorkspace(workspace)
  );
}

export function getWorkspaceType(workspace) {
  if (isNewsletterWorkspace(workspace)) return "newsletter";
  if (isDigitalPublicationWorkspace(workspace)) return "digitalPublication";
  return PUBLICATIONS?.[workspace]?.type ?? null;
}
