import { useEffect, useRef } from "react";

/**
 * Keeps the reference of the previous value to be used in the render phase.
 * At first render, the previous value is undefined.
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T | undefined>(undefined);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
