import { Tooltip } from "swash/Tooltip";

export const DisabledAction = ({ reason, children }) => {
  if (!reason) return children({ disabled: false });
  return (
    <Tooltip tooltip={reason}>
      <div className="inline-block">
        {
          // tooltip can't be applied directly on button, events aren't triggered on disabled button
          children({ disabled: true })
        }
      </div>
    </Tooltip>
  );
};
