import { gql } from "@apollo/client";
import { Button } from "swash/Button";
import { IoClose } from "swash/Icon";

import { ResourceDraggableField } from "../article/fields/ResourceDraggableField";
import { TagIcon } from "../tag/TagLabel";

const TagFragment = gql`
  fragment ArticleTagsSelector_tag on Tag {
    id
    title
    tagType {
      ...TagIcon_tagType
    }
  }
  ${TagIcon.fragments.tagType}
`;

const TagQuery = gql`
  query RelatedTagsSelector_tags(
    $offset: Int
    $limit: Int
    $filters: TagWhere
  ) {
    connection: tags(offset: $offset, limit: $limit, where: $filters) {
      pageInfo {
        hasMore
      }
      nodes {
        id
        ...ArticleTagsSelector_tag
      }
    }
  }
  ${TagFragment}
`;

const TagItem = ({ tag, onRemove, disabled, dragIndex }) => {
  return (
    <div className="group flex h-5 w-full flex-row items-center justify-between text-sm">
      <div className="flex items-center gap-2">
        <TagIcon tagType={tag.tagType} />
        {tag.title}
      </div>
      {onRemove && !disabled && (
        <Button
          aria-label="Supprimer le tag"
          appearance="text"
          variant="secondary"
          scale="xs"
          iconOnly
          onClick={() => onRemove(dragIndex)}
          className="opacity-0 group-hover:opacity-100"
        >
          <IoClose />
        </Button>
      )}
    </div>
  );
};

export const RelatedTagSelector = ({
  value,
  placeholder,
  name,
  label,
  onChange,
  limit,
  multi,
  disabled,
}) => {
  return (
    <ResourceDraggableField
      value={value}
      name={name}
      label={label}
      placeholder={placeholder || "Ajouter une rubrique"}
      onChange={onChange}
      disabled={disabled}
      query={TagQuery}
      model="Tag"
      labelSelector={({ item, onRemove, disabled, dragIndex }) => (
        <TagItem
          tag={item}
          onRemove={onRemove}
          disabled={disabled}
          dragIndex={dragIndex}
        />
      )}
      labelElementSelector={(item) => <TagItem tag={item} />}
      limit={limit}
      multi={multi}
      fragment={TagFragment}
    />
  );
};
