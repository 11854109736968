import { forwardRef } from "react";

const SvgUnderline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.846 5.88v4.76c0 1.082.438 2.12 1.217 2.885A4.193 4.193 0 0 0 12 14.72c1.102 0 2.158-.43 2.937-1.195a4.044 4.044 0 0 0 1.217-2.885V5.88M6.462 18.12h11.077"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnderline);
export default ForwardRef;
