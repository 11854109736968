import { gql } from "@apollo/client";
import { memo, useMemo } from "react";

import { useSafeQuery } from "@/containers/Apollo";
import { useNodesToEnumArray } from "@/containers/admin/CRUD";
import { EnumFilterField } from "@/containers/search/filterFields/EnumFilterField";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const AgencyFragment = gql`
  fragment AgencyFilter_agency on ImageAgency {
    id: name
    label
  }
`;

const query = gql`
  query AgencyFilter_imageAgencies($where: ImageAgencyWhere) {
    connection: imageAgencies(where: $where, limit: 100) {
      nodes {
        id: name
        ...AgencyFilter_agency
      }
      ...ConnectionFragment
    }
  }
  ${AgencyFragment}
  ${ConnectionFragment}
`;

const name = "agencies";

const Filter = memo(() => {
  const queryResult = useSafeQuery(query, {
    variables: { where: { enabled: true } },
  });

  const formattedData = useNodesToEnumArray(
    queryResult?.data?.connection?.nodes ?? [],
  );

  const items = useMemo(() => {
    return formattedData?.length
      ? [...formattedData, { value: "other", label: "Autres" }]
      : formattedData;
  }, [formattedData]);

  if (!queryResult) return null;

  return (
    <EnumFilterField
      name={name}
      label="Agences"
      enum={items}
      multi
      sortEntries={() => false}
    />
  );
});

export const AgencyFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
