import * as React from "react";
import { forwardRef } from "react";

const SvgArrowBarUp = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.251 9.744a.888.888 0 0 1 0-1.238l5.143-5.25a.845.845 0 0 1 1.212 0l5.143 5.25a.888.888 0 0 1 0 1.238.845.845 0 0 1-1.212 0L12 5.112 7.463 9.744a.845.845 0 0 1-1.212 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16c-.552 0-1-.348-1-.777V5.777c0-.428.448-.776 1-.776s1 .348 1 .776v9.447c0 .43-.448.777-1 .777ZM3 20a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowBarUp);
export default ForwardRef;
