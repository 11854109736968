import { gql } from "@apollo/client";
import {
  EnumSelectList,
  EnumSelectState,
  useEnumSelectState,
} from "swash/v2/EnumSelect";

import { useSafeQuery } from "@/containers/Apollo";
import { ExposureTypeIcon } from "@/containers/ExposureIcon";
import { ExposureIndicator } from "@/containers/ExposureIndicator";

import { Range } from "./util/publicationSlots";

const ArticleExposureFragment = gql`
  fragment ArticleExposuresSelect_articleExposure on ArticleExposure {
    ...ExposureIndicator_articleExposure
    correlatedPlannedDateToArticle
    exposure {
      ...ExposureIndicator_exposure
      plannableMode
    }
    article {
      ...ExposureIndicator_article
    }
    publication {
      ...ExposureIndicator_publication
    }
  }
  ${ExposureIndicator.fragments.articleExposure}
  ${ExposureIndicator.fragments.article}
  ${ExposureIndicator.fragments.publication}
  ${ExposureIndicator.fragments.exposure}
`;

const ArticleFragment = gql`
  fragment ArticleExposuresSelect_article on Article {
    articleExposures {
      nodes {
        ...ArticleExposuresSelect_articleExposure
      }
    }
  }
  ${ArticleExposureFragment}
`;

const ArticleExposureQuery = gql`
  query ArticleExposuresSelect_article_exposures($id: Int!) {
    article(id: $id) {
      id
      articleExposures {
        nodes {
          id: gid
          ...ArticleExposuresSelect_articleExposure
        }
      }
    }
  }
  ${ArticleExposureFragment}
`;

export type Exposure = {
  id: number;
  label: string;
  icon: string;
  acronym: string;
  type: string;
  requiredPriorArticlePublication: boolean;
  warnPosteriorArticlePublication: boolean;
  plannableMode: "slot" | "hour" | "slot_and_hour";
  newsletter: {
    id: number;
    timeDeadline: string;
  };
  periodical: {
    id: number;
    periodicity: {
      timeDeadline: string;
    };
  };
};

export type ArticleExposureQueryData = {
  article: {
    articleExposures: {
      nodes: ArticleExposure[];
    };
  };
};

type ArticleExposureVariables = {
  id: number;
};

type Planning = {
  date: string;
  range: Range;
};

export type ArticleExposure = {
  gid: string;
  exposureId: number;
  exposure: Exposure;
  correlatedPlannedDateToArticle: boolean;
  article: {
    initialFirstPublished: boolean;
    printInfos: {
      exportedAt: string;
      exportedBy: {
        id: number;
        fullName: string;
      };
      decorrelated: boolean;
      decorrelatedAt: string;
    };
    schedulerDate: string;
    planning: Planning;
  };
  publication: {
    id: number;
    date: string;
  };
  planning: Planning;
  fulfilled: boolean;
  fulfilledAt: string;
  selected: boolean;
  selectedAt: string;
  suggested: boolean;
  suggestedAt: string;
  plannedDatetimeSlots: { startBound: string; endBound: string };
};

export type ArticleExposuresSelectStateProps = {
  articleId: number;
  value: ArticleExposure[];
  onChange: (values: ArticleExposure[]) => void;
};

export const getArticleExposure = (
  articleExposures: ArticleExposure[],
  exposureId: number,
) => {
  return articleExposures.find(
    (articleExposure) => articleExposure.exposureId === exposureId,
  );
};

export const useArticleExposuresSelectState = (
  props: ArticleExposuresSelectStateProps,
): EnumSelectState<ArticleExposure> => {
  const { data } = useSafeQuery<
    ArticleExposureQueryData,
    ArticleExposureVariables
  >(ArticleExposureQuery, { variables: { id: props.articleId } });
  const exposures = data?.article.articleExposures.nodes ?? [];
  const items = exposures.map((articleExposure) => ({
    ...articleExposure,
    suggested:
      articleExposure.selected || articleExposure.fulfilled
        ? articleExposure.suggested
        : true,
  }));

  const enumSelect = useEnumSelectState({
    value: props.value,
    onChange: props.onChange,
    items,
    iconSelector: ({ exposure }) => {
      return <ExposureTypeIcon exposure={exposure} />;
    },
    labelSelector: ({ exposure }) => exposure.label,
    valueSelector: (articleExposure) => String(articleExposure.exposureId),
    disabledSelector: (articleExposure) => {
      return articleExposure.fulfilled || articleExposure.selected;
    },
    required: false,
  });
  return enumSelect;
};

useArticleExposuresSelectState.fragments = {
  article: ArticleFragment,
};

export type ArticleExposuresSelectListProps = {
  state: EnumSelectState<ArticleExposure>;
};

export const ArticleExposuresSelectList = (
  props: ArticleExposuresSelectListProps,
) => {
  return <EnumSelectList state={props.state} />;
};
