import { gql } from "@apollo/client";
import { graphql } from "@apollo/react-hoc";
import { withProps } from "recompose";

import { findById } from "@/services/utils";

/* Using this trick we ignore checking with ESLint */
const gqlClient = gql;

export const toggledArticlesQuery = gqlClient`
  query ToggleArticlesQuery {
    toggledArticles @client {
      id
      isToggled
    }
  }
`;

export const toggledArticlesQueryOptions = {
  props: ({ data: { toggledArticles } }) => ({
    toggledArticles,
  }),
};

const withToggledArticles = graphql(
  toggledArticlesQuery,
  toggledArticlesQueryOptions,
);

const toggleMoreInfoArticleArticleMutation = gqlClient`
  mutation toggleMoreInfoArticle($id: Int!, $value: Boolean!) {
    toggleMoreInfoArticle(id: $id, value: $value) @client
  }
`;

export const toggleMoreInfoArticleMutationOptions = {
  props: ({ mutate }) => ({
    toggleMoreInfoArticle: (id, value) => mutate({ variables: { id, value } }),
  }),
};

const withToggleMoreInfoArticleArticleMutation = graphql(
  toggleMoreInfoArticleArticleMutation,
  toggleMoreInfoArticleMutationOptions,
);

export const articlesWithToggleData = (ownerProps) => {
  const { toggleMoreInfoArticle, toggledArticles } = ownerProps;
  const { articles } = ownerProps;

  const newArticles = (articles || []).map((article) => {
    const toggledArticle = findById(toggledArticles, article.id);
    return {
      ...article,
      isToggled: toggledArticle?.isToggled,
      toggleInfo: (value) => toggleMoreInfoArticle(article.id, value),
    };
  });

  if (newArticles.length) {
    return { ...ownerProps, articles: newArticles };
  }

  return ownerProps;
};

const enhanceArticleWithToggleData = withProps(articlesWithToggleData);

export const createArticles = (ownerProps) => {
  const { deleteMessage, deleteTask, selectedProfile } = ownerProps;
  const type = selectedProfile?.type;
  const typeOfTask = type === "messages" ? type : "tasks";
  const deleteMutation = type === "messages" ? deleteMessage : deleteTask;

  const articles = (ownerProps[typeOfTask] || []).map(
    ({ id, article, sender, creator }) => ({
      ...article,
      sender,
      creator,
      type,
      deleteArticle: () => deleteMutation(id),
    }),
  );
  if (articles.length) {
    return { ...ownerProps, articles };
  }
  return ownerProps;
};

const createArticlesEnhancer = withProps(createArticles);

export {
  withToggledArticles,
  withToggleMoreInfoArticleArticleMutation,
  enhanceArticleWithToggleData,
  createArticlesEnhancer,
};
