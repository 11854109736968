import { useEditorConfig } from "@/containers/editor/EditorConfig";
import { useConfigHosts } from "@/services/hooks/useConfigHosts";

import { useEditorPlugins } from "../EditorPlugins";
import presetBlocks from "./preset-blocks";
import presetRichText from "./preset-rich-text";
import { useTextPresetOptions } from "./preset-text";
import presetTextStyles from "./preset-text-styles";

/**
 * @typedef {import('./preset-rich-text').Options & import('./preset-text-styles').Options & import('./preset-blocks').Options & { blocks?: boolean }} Options
 */

/**
 * @property {Options} options
 */
const presetRichEditor = (options) => ({
  presets: [
    [presetRichText, options],
    [presetTextStyles, options],
    ...(options.blocks !== false
      ? [
          [
            presetBlocks,
            {
              services: options.services,
              frontHost: options.frontHost,
              imageHost: options.imageHost,
              separator: options.separator,
              image: options.image,
              video: options.video,
              snippet: options.snippet,
              tweet: options.tweet,
              article: options.article,
              articleMedia: options.articleMedia,
              customTypesContent: options.customTypesContent,
            },
          ],
        ]
      : []),
  ],
});

export default presetRichEditor;

/**
 * @param {Options} options
 */
export const useRichEditorPreset = (options) => {
  const editorConfig = useEditorConfig();
  const textPresetOptions = useTextPresetOptions(options);
  const { frontHost, imageHost } = useConfigHosts();
  return useEditorPlugins({
    presets: [
      [
        presetRichEditor,
        {
          ...textPresetOptions,
          services: editorConfig.services,
          frontHost,
          imageHost,
        },
      ],
    ],
  });
};
