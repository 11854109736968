import { createContext, useCallback, useContext, useMemo, useRef } from "react";

const SpellCheckContext = createContext();

export const SpellCheckProvider = ({ children }) => {
  const editorsRef = useRef([]);

  const registerEditor = useCallback((editor) => {
    const editors = editorsRef.current;
    if (!editors.find((e) => e.name === editor.name)) {
      editors.push(editor);
    }
    return () => {
      const index = editors.findIndex((e) => e.name === editor.name);
      if (index !== -1) {
        editors.splice(index, 1);
      }
    };
  }, []);

  const value = useMemo(
    () => ({
      registerEditor,
      getEditors: () => editorsRef.current,
    }),
    [registerEditor],
  );

  return (
    <SpellCheckContext.Provider value={value}>
      {children}
    </SpellCheckContext.Provider>
  );
};

export function useSpellCheck() {
  return useContext(SpellCheckContext);
}
