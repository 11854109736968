import type { htmlToEntity as HtmlToEntity } from "../types";
import { COMPOSE_ENTITY_TYPE as ANCHOR_ENTITY_TYPE } from "./anchor-convert";

export const ATOMIC_ENTITY_TYPE = "COMPOSITE";

export const COMPOSE_ENTITY_TYPE = "LINK";

export const allowedTagNames = ["a"];

export const allowedAttributes = {
  a: ["href", "style", "data-type", "data-affiliate", "data-entity-type"],
};

export const htmlToEntity: HtmlToEntity = ({ element, composeWithEntity }) => {
  if (element.tagName === "a" && element.properties?.["href"]) {
    if (!element.children.length) return;
    if (element.properties?.["dataEntityType"] === ANCHOR_ENTITY_TYPE) {
      return null;
    }
    composeWithEntity(COMPOSE_ENTITY_TYPE, {
      url: element.properties["href"],
      affiliate:
        // Angular
        element.properties["dataType"] === "huit-affiliate" ||
        // React
        element.properties["dataAffiliate"] != null,
    });
  }
  return;
};
