import type { ContentState } from "draft-js-es";
import { RefObject, useEffect } from "react";
import { useHovered } from "swash/utils/useHovered";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useSetHoveredComposeEntityKeys } from "@/components/rich-editor/RichEditorCompositeEntityContext";
import { addDecorator } from "@/components/rich-editor/modifiers/addDecorator";
import type { ComposeEntity } from "@/components/rich-editor/utils/CompositeEntity";
import type { State } from "@/components/rich-editor/utils/PluginPopover";

export const name = "core-composite-entity";

type CompositeLayerDecoratorProps = {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
};

const CompositeLayerDecorator = ({
  entityKey,
  children,
  contentState,
}: CompositeLayerDecoratorProps) => {
  const entity = contentState.getEntity(entityKey);
  const composeEntityKeys: Set<string> = new Set(
    entity.getData().entities.map(({ key }: ComposeEntity) => key),
  );
  const [containerRefHandler, hovered] = useHovered();
  const setHoveredComposeEntityKeys = useSetHoveredComposeEntityKeys();
  const refs = useLiveRef({ composeEntityKeys, setHoveredComposeEntityKeys });

  useEffect(() => {
    const { composeEntityKeys, setHoveredComposeEntityKeys } = refs.current;
    setHoveredComposeEntityKeys(hovered ? composeEntityKeys : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <span ref={containerRefHandler as RefObject<HTMLDivElement>}>
      {children}
    </span>
  );
};

export const getInitialEditorState = ({ editorState }: State) => {
  return addDecorator(editorState, {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (!entityKey) return false;
        const entity = contentState.getEntity(entityKey);
        if (!entity) return false;
        return entity.getType() === "COMPOSITE";
      }, callback);
    },
    component: CompositeLayerDecorator,
  });
};
