import * as Ariakit from "@ariakit/react";
import * as React from "react";
import { forwardRef } from "react";

import { PopoverCard } from "./Popover";
import { cn } from "./utils/classNames";

export { useMenuStore, MenuProvider } from "@ariakit/react";

export interface MenuButtonProps extends Ariakit.MenuButtonProps {
  /**
   * Use the child as the component.
   */
  asChild?: boolean;
}

export const MenuButton = forwardRef<HTMLDivElement, MenuButtonProps>(
  ({ asChild, children, ...props }, ref) => {
    return (
      <Ariakit.MenuButton
        ref={ref}
        render={
          asChild && React.isValidElement(children) ? (
            children
          ) : (
            <Ariakit.MenuButton />
          )
        }
        {...props}
      >
        {asChild ? null : children}
      </Ariakit.MenuButton>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  MenuButton.displayName = "MenuButton";
}

export type MenuProps = Ariakit.MenuProps & {
  /**
   * Whether the menu contains a combobox.
   * @default false
   */
  combobox?: boolean;
  gutter?: number;
};

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ className, style, combobox, gutter = 4, ...props }, ref) => {
    return (
      <Ariakit.Menu
        ref={ref}
        focusable={false}
        render={<PopoverCard />}
        gutter={gutter}
        className={cn(
          className,
          "menu",
          combobox ? "flex flex-col overflow-hidden" : "overflow-auto p-1",
        )}
        composite={!combobox}
        style={{
          maxHeight: "var(--popover-available-height, 400px)",
          ...style,
        }}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Menu.displayName = "Menu";
}

export type MenuItemScale = "sm" | "md";
export type MenuItemVariant = "default" | "danger" | "success";

const menuItemVariants: Record<MenuItemVariant, string> = {
  default: cn(
    "text-dusk-on",
    "data-[active-item]:bg-grey-bg-hover-transparent data-[active-item]:text-dusk-on-hover",
    "aria-disabled:opacity-disabled",
  ),
  danger: cn(
    "text-danger-on",
    "data-[active-item]:bg-danger-bg-hover-transparent data-[active-item]:text-danger-on-hover",
    "aria-disabled:opacity-disabled",
  ),
  success: cn(
    "text-success-on",
    "data-[active-item]:bg-success-bg-hover-transparent data-[active-item]:text-success-on-hover",
    "aria-disabled:opacity-disabled",
  ),
};

const menuItemScales: Record<MenuItemScale, string> = {
  md: "text-base py-1 px-2",
  sm: "text-sm py-1 px-2",
};

export interface ItemClassNameProps {
  /**
   * Variant of the menu item.
   * @default "default"
   */
  variant?: MenuItemVariant | undefined;
  scale?: MenuItemScale | undefined;

  className?: string | undefined;
}

export const getItemClassName = (props: ItemClassNameProps) => {
  const { variant = "default", scale = "md", className } = props;

  return cn(
    className,
    "menu-item rounded font-accent flex items-center gap-2 focus:outline-none",
    menuItemVariants[variant],
    menuItemScales[scale],
  );
};

export interface MenuItemProps
  extends Ariakit.MenuItemProps,
    ItemClassNameProps {
  /**
   * Use the child as the component.
   */
  asChild?: boolean;
  type?: "button";
}

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  ({ asChild, children, className, variant, scale, ...props }, ref) => {
    return (
      <Ariakit.MenuItem
        ref={ref}
        className={getItemClassName({
          className,
          variant,
          scale,
        })}
        render={
          asChild && React.isValidElement(children) ? (
            children
          ) : (
            <Ariakit.MenuItem />
          )
        }
        {...props}
      >
        {asChild ? null : children}
      </Ariakit.MenuItem>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  MenuItem.displayName = "MenuItem";
}

export interface ListSeparatorClassNameProps {
  className?: string | undefined;
}

export const getListSeparatorClassName = ({
  className,
}: ListSeparatorClassNameProps) => {
  return cn(
    className,
    "separator border-t border-t-grey-border-light my-1 mx-2",
  );
};

export type MenuSeparatorProps = Ariakit.MenuSeparatorProps &
  ListSeparatorClassNameProps;

export const MenuSeparator = forwardRef<HTMLHRElement, MenuSeparatorProps>(
  ({ className, ...props }, ref) => {
    const listSeparatorClassName = getListSeparatorClassName({ className });
    return (
      <Ariakit.MenuSeparator
        ref={ref}
        className={listSeparatorClassName}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  MenuSeparator.displayName = "MenuSeparator";
}
