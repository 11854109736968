import { gql } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";

const BlockTemplatesQuery = gql`
  query BlockTemplatesQuery {
    blockTemplates {
      nodes {
        id
        name
        title
        type
        editorType
        css
        enabled
      }
    }
  }
`;

export function useBlockTemplates() {
  const { data } = useSafeQuery(BlockTemplatesQuery);
  return data?.blockTemplates.nodes ?? [];
}
