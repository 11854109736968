export default "[" +
  "\\w-" +
  // Latin-1 Supplement (letters only) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin-1_Supplement
  "\u00C0-\u00D6" +
  "\u00D8-\u00F6" +
  "\u00F8-\u00FF" +
  // Latin Extended-A (without deprecated character) - https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_Extended-A
  "\u0100-\u0148" +
  "\u014A-\u017F" +
  "]";
