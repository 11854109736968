import { gql } from "@apollo/client";
import { useMemo } from "react";

import {
  Article,
  ArticleAuditTrailFragment,
  ArticleAuditTrailsProvider,
} from "@/components/ArticleAuditTrail";
import type { NodeProvider } from "@/containers/list/ListState";
import type { Task } from "@/containers/todo/list";

export type AuditTrailArticle = Article;

type NodeProviderType = Article | Task;

export const auditTrailProvider: NodeProvider<NodeProviderType> = {
  key: "articleAuditTrailProvider",
  fragment: gql`
    fragment auditTrailProvider_article on Article {
      id
      ...AuditTrail_article
    }
    ${ArticleAuditTrailFragment}
  `,
  component: ({ node, children }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const article = useMemo(() => {
      switch (node.__typename) {
        case "Article":
          return node as Article;
        case "Message":
        case "Task":
          return (node as Task).article;
        default:
          throw new Error(`Unsupported node type: ${node.__typename}`);
      }
    }, [node]);

    return (
      <ArticleAuditTrailsProvider article={article}>
        {children}
      </ArticleAuditTrailsProvider>
    );
  },
};
