import { Fragment, forwardRef } from "react";
import type { FieldMetaState } from "react-final-form";
import { cn } from "swash/utils/classNames";

import type { FieldState } from "./FieldState";

function formatErrorList(errorOrErrors: any): string[] {
  if (!errorOrErrors) return [];
  if (errorOrErrors?.error) {
    return [errorOrErrors.error];
  }
  if (!Array.isArray(errorOrErrors)) return [errorOrErrors];

  let errors: string[] = [];
  let arrayErrors: string[] = [];
  errorOrErrors.forEach((error) => {
    if (typeof error === "object") {
      arrayErrors = [
        ...arrayErrors,
        ...(error
          ? Object.entries(error).map(([key, value]) => `"${key}": ${value}`)
          : []),
      ];
      return;
    }
    errors = [...errors, error];
  });

  return [...new Set(arrayErrors), ...errors];
}

function formatErrors<FieldValue = any>({
  submitError,
  error,
}: FieldMetaState<FieldValue>): string[] {
  return [...formatErrorList(submitError), ...formatErrorList(error)];
}

const getErrors = (state: FieldState): string[] => {
  // fields with initial values and maxLength or minLength validators
  // should display errors right away without touching the field
  if (
    Boolean(state.field.meta?.initial) &&
    (state.maxLength || state.minLength)
  )
    return formatErrors(state.field.meta);
  return state.field.meta.touched ? formatErrors(state.field.meta) : [];
};

type FieldErrorProps = React.ComponentPropsWithRef<"span"> & {
  state: FieldState;
};

export const FieldError = forwardRef<HTMLSpanElement, FieldErrorProps>(
  ({ state, ...props }, ref) => {
    const errors = getErrors(state);

    return errors.length > 0 ? (
      <span
        ref={ref}
        data-field-error
        role="alert"
        {...props}
        className={cn(
          "font-accent text-danger-on",
          'group-data-[orientation="vertical"]/fieldGroup:shrink-0',
          'group-data-[orientation="horizontal"]/fieldGroup:order-3 group-data-[orientation="horizontal"]/fieldGroup:mr-2 group-data-[orientation="horizontal"]/fieldGroup:inline-block',
          props.className,
        )}
      >
        {errors.every((error) => typeof error === "string")
          ? errors.join(", ")
          : errors.map((error, index) => (
              <Fragment key={index}>{error}</Fragment>
            ))}
      </span>
    ) : null;
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FieldError.displayName = "FieldError";
}
