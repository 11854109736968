/* eslint-disable react/no-this-in-sfc */
import { List } from "immutable-es";

const PassThrough = (props) => props.children;

const replaceCharAt = (str, index, chr) =>
  str.substr(0, index) + chr + str.substr(index);

const BaseComponent = ({ decoratorInfos, children, ...props }) => {
  let result = children;
  for (let i = 0; i < decoratorInfos.length; i++) {
    const infos = decoratorInfos[i];
    const { component: Component, props: componentProps } = infos;
    result = (
      <Component {...componentProps} {...props}>
        {result}
      </Component>
    );
  }
  return result;
};

export class CompoundDecorator {
  constructor(decorators = []) {
    // Copy the decorator array, since we use this array order to determine
    // precedence of decoration matching. If the array is mutated externally,
    // we don't want to be affected here.
    this._decorators = decorators.slice();
    this._emptyKey = "0".repeat(this._decorators.length);
    this._props = {};
  }

  getDecorators() {
    return this._decorators;
  }

  getDecorations(block, contentState) {
    const decorations = Array.from({ length: block.getText().length }).fill(
      null,
    );
    for (let i = 0; i < this._decorators.length; i++) {
      const decorator = this._decorators[i];
      const { strategy } = decorator;
      strategy(
        block,
        (start, end) => {
          for (let ii = start; ii < end; ii++) {
            decorations[ii] = replaceCharAt(
              decorations[ii] || this._emptyKey,
              i,
              "1",
            );
          }
        },
        contentState,
      );
    }

    return List(decorations);
  }

  getComponentForKey() {
    return BaseComponent;
  }

  getPropsForKey(key) {
    if (!this._props[key]) {
      const decoratorInfos = [];
      for (let i = 0; i < key.length; i++) {
        const marker = key[i];
        let decorator = this._decorators[i];
        // In case of nested comments we have more keys than decorators - it's the best compromise found for user ux
        if (!decorator) {
          decorator = {
            component: PassThrough,
            props: {},
          };
        }

        decoratorInfos[i] = {
          component: marker === "1" ? decorator.component : PassThrough,
          props: marker === "1" ? decorator.props : {},
        };
      }
      this._props[key] = { decoratorInfos };
    }
    return this._props[key];
  }
}
