export const BLOCK_STYLE_TYPE = "header-three";

export const allowedBlockTypes = [BLOCK_STYLE_TYPE];
export const allowedAttributes = { h3: ["style"] };

/** @type {import('../types').htmlToBlock} */
export const htmlToBlock = ({ element }) => {
  if (element.tagName === "h3") {
    return { type: BLOCK_STYLE_TYPE };
  }
  return null;
};
