import moment from "moment";

import { UsedInList } from "./UsedInList";

export const name = "Parutions";

const columns = [
  {
    id: "label",
    Header() {
      return "Libellé";
    },
    Value({ node, EditLink }) {
      return (
        <EditLink slug="custom-fields/periodicalRelease">{node.label}</EditLink>
      );
    },
  },
  {
    id: "releaseDate",
    Header() {
      return "Date de parution";
    },
    Value({ node }) {
      return moment(node.releaseDate).format("DD/MM/YYYY");
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
