import { memo } from "react";

import { Underline } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { getBlockType } from "../utils/Block";
import { INLINE_STYLE, command } from "./underline";

const SHORTCUT = ["⌘", "u"];

export const shortcuts = [
  {
    description: "Souligné",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "underline-control";

const UnderlineToolbarButton = memo(({ active, disabled }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      label="Souligné"
      command={command}
      shortcut={SHORTCUT}
      disabled={disabled}
    >
      <Underline />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  const blockType = getBlockType(editorState);

  return (
    <UnderlineToolbarButton
      active={editorState.getCurrentInlineStyle().has(INLINE_STYLE)}
      disabled={blockType === "blockquote"}
    />
  );
};

BlockControls.group = "inline-style";
