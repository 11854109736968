import { gql } from "@apollo/client";
import { memo } from "react";

import { AuthorAvatar } from "./AuthorAvatar";

export const SelectOptionLabelAuthor = memo(({ author }) => {
  return (
    <>
      <AuthorAvatar author={author} />
      <span>
        {author.longName}
        {author.shortName && ` (${author.shortName})`}
      </span>
    </>
  );
});

SelectOptionLabelAuthor.fragments = {
  author: gql`
    fragment SelectOptionLabelAuthor_author on Author {
      ...AuthorAvatar_author
      longName
      shortName
    }

    ${AuthorAvatar.fragments.author}
  `,
};
