import * as React from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

/**
 * Simulate the behavior of `setState` that accepts a function
 * to support concurrency even if current `setState` doesn't support it.
 */

export function useEnhancedState(getInitialState, onStateChange) {
  const onStateChangeRef = useLiveRef(onStateChange);
  const [state, setState] = React.useState(getInitialState);
  const refs = React.useRef({ state, pendingResolves: [] });

  const batchSetState = React.useCallback(
    (stateOrFn) => {
      const { state: previousState } = refs.current;
      const nextState =
        typeof stateOrFn === "function" ? stateOrFn(previousState) : stateOrFn;

      if (nextState !== previousState) {
        refs.current.state = nextState;
        onStateChangeRef.current(nextState, previousState);
        setState(nextState);
      }

      return {
        then: (...args) =>
          new Promise((resolve) => {
            if (nextState === previousState) {
              resolve();
              return;
            }

            refs.current.pendingResolves.push(resolve);
          }).then(...args),
      };
    },
    [onStateChangeRef],
  );

  React.useEffect(() => {
    // Wait one tick to ensure it is triggered after the promise
    const timeout = setTimeout(() => {
      const { pendingResolves } = refs.current;
      while (pendingResolves.length > 0) {
        const resolve = pendingResolves.shift();
        resolve();
      }
    });
    return () => clearTimeout(timeout);
  }, [state]);

  return [state, batchSetState];
}
