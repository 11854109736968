import { forwardRef } from "react";
import { Helmet } from "react-helmet";
import { cn } from "swash/utils/classNames";

export const Main = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"main">
>((props, ref) => (
  <main
    ref={ref}
    id="main"
    {...props}
    className={cn(
      "shrink-0 grow basis-0 overflow-auto bg-grey-bg-light",
      props.className,
    )}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  Main.displayName = "Main";
}

export const Header = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"header">
>((props, ref) => (
  <header
    ref={ref}
    {...props}
    className={cn("m-4 flex items-end gap-4", props.className)}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  Header.displayName = "Header";
}

export const HeaderPrimary = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn("flex-1 basis-auto", props.className)}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  HeaderPrimary.displayName = "HeaderPrimary";
}

export const HeaderToolbar = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn(
      "flex shrink-0 grow-0 basis-auto flex-wrap items-center gap-4",
      props.className,
    )}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  HeaderToolbar.displayName = "HeaderToolbar";
}

export const HeaderToolbarItem = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn("w-auto shrink-0 grow-0 basis-auto", props.className)}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  HeaderToolbarItem.displayName = "HeaderToolbarItem";
}

export const HeaderTitle = forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<"h1">
>(({ children, ...props }, ref) => {
  return (
    <h1
      ref={ref}
      {...props}
      className={cn("font-accent text-2xl font-semibold", props.className)}
    >
      {children}
    </h1>
  );
});
if (process.env["NODE_ENV"] !== "production") {
  HeaderTitle.displayName = "HeaderTitle";
}

export const Section = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"section">
>((props, ref) => (
  <section
    ref={ref}
    {...props}
    className={cn(
      "max-w-[1140px] border-grey-bg-light px-4 pb-6",
      props.className,
    )}
  />
));

export const SectionTitle = forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<"h2">
>(({ children, ...props }, ref) => (
  <h2
    ref={ref}
    {...props}
    className={cn(
      "font-accent text-xl font-semibold leading-tight",
      props.className,
    )}
  >
    {children}
  </h2>
));
if (process.env["NODE_ENV"] !== "production") {
  SectionTitle.displayName = "SectionTitle";
}

export const StickyToolbar = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn("sticky top-[-74px] z-[1]", props.className)}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  StickyToolbar.displayName = "StickyToolbar";
}

export const BreadcrumbNav = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav">
>((props, ref) => {
  return (
    <nav
      ref={ref}
      aria-label="Breadcrumb"
      {...props}
      className={cn(
        "text-sm [&_li:first-child:last-child]:hidden [&_li:not(:last-child)]:after:mx-[1ex] [&_li:not(:last-child)]:after:content-['›'] [&_li]:mb-1 [&_li]:inline-block",
        props.className,
      )}
    />
  );
});
if (process.env["NODE_ENV"] !== "production") {
  BreadcrumbNav.displayName = "BreadcrumbNav";
}

export const PageTitle = forwardRef<
  HTMLHeadingElement,
  React.ComponentPropsWithoutRef<"h1"> & { headerTitle?: string }
>(({ children, headerTitle, ...props }, ref) => {
  return (
    <>
      <HeaderTitle ref={ref} {...props}>
        {children}
      </HeaderTitle>
      {typeof children === "string" && (
        <Helmet>
          <title>{children}</title>
        </Helmet>
      )}
      {headerTitle && (
        <Helmet>
          <title>{headerTitle}</title>
        </Helmet>
      )}
    </>
  );
});
if (process.env["NODE_ENV"] !== "production") {
  PageTitle.displayName = "PageTitle";
}
