import type { FieldRenderProps } from "react-final-form";
import { useId } from "swash/utils/hooks";

export type Orientation = "horizontal" | "vertical";

export type FieldState = {
  field: FieldRenderProps<any, HTMLElement, any>;
  id?: string;
  label?: string;
  "aria-label"?: string;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  labelId?: string;
  orientation?: Orientation;
  resetField?: (name: string) => void;
  fieldOptions?: any;
};

export function useFieldState({ field, id: idOption, ...options }: FieldState) {
  const id = useId(idOption || undefined);
  const labelId = useId();

  const state: FieldState = { field, id, ...options };
  if (!state.label || !state["aria-label"]) {
    state.labelId = labelId;
  }

  return { state };
}
