import { useCallback, useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useDialogStore } from "swash/v2/Dialog";

import { SpellCheck } from "@/components/icons";

import { RichEditorToolbarButton } from "../../RichEditorToolbar";
import { useSpellCheck } from "../spell-check-control/SpellCheckPluginContext";
import { MerciAppDialog } from "./MerciAppDialog";

export const name = "merciapp-spell-check-control";
export const label = "Corriger le texte avec MerciApp";

export const BlockControls = ({ render }) => {
  const dialog = useDialogStore();
  const dialogRef = useLiveRef(dialog);

  const openDialog = useCallback(
    (event) => {
      event.preventDefault();
      dialogRef.current.toggle();
    },
    [dialogRef],
  );

  return (
    <>
      <RichEditorToolbarButton
        render={render}
        label={label}
        onMouseDown={openDialog}
      >
        <SpellCheck />
      </RichEditorToolbarButton>
      <MerciAppDialog dialog={dialog} />
    </>
  );
};

BlockControls.group = "spell-check";

export const ButtonCommand = BlockControls;

export const usePluginProps = (state) => {
  const { registerEditor } = useSpellCheck();
  const stateRef = useLiveRef(state);

  useEffect(() => {
    const { name, label } = stateRef.current;
    return registerEditor({
      name,
      label,
      getEditorState: () => stateRef.current.editorState,
      setEditorState: (newEditorState) =>
        stateRef.current.setEditorState(newEditorState),
    });
  }, [stateRef, registerEditor]);

  return {};
};
