import { gql } from "@apollo/client";
import { graphql } from "@apollo/react-hoc";

/* Using this trick we ignore checking with ESLint */
const gqlClient = gql;

export const expandedPublicationColumnsQuery = gqlClient`
  query ExpandPublicationColumnsQuery {
    expandedPublicationColumns @client {
      id
      isExpanded
    }
  }
`;

export const expandedPublicationColumnsOptions = {
  props: ({ data: { expandedPublicationColumns } }) => ({
    expandedPublicationColumns,
  }),
};

const withExpandedColumns = graphql(
  expandedPublicationColumnsQuery,
  expandedPublicationColumnsOptions,
);

const expandColumnMutation = gqlClient`
  mutation ExpandColumns($id: Int!, $value: Boolean!) {
    expandColumn(id: $id, value: $value) @client
  }
`;

export const expandColumnOptions = {
  props: ({ mutate }) => ({
    expandColumn: (id, value) => mutate({ variables: { id, value } }),
  }),
};

const withExpandColumn = graphql(expandColumnMutation, expandColumnOptions);

export { withExpandedColumns, withExpandColumn };
