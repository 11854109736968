import { memo } from "react";
import { Alert } from "swash/Alert";

type AlertType = "published" | "url" | undefined;

const getAlert = (type: AlertType) => {
  switch (type) {
    case "published":
      return "Les statistiques provenant de Forecast ne sont pas disponibles pour les articles non publiés.";
    case "url":
      return "Les statistiques provenant de Forecast ne sont pas disponibles pour les articles sans URL.";
    default:
      return "Les statistiques provenant de Forecast sont incomplètes et ne peuvent pas être affichées.";
  }
};

export const ForecastDataAlert = memo(({ type }: { type: AlertType }) => {
  const alert = getAlert(type);
  return <Alert>{alert}</Alert>;
});
