import { gql } from "@apollo/client";
import { memo } from "react";

import { Time } from "@/components/Time";

export type SpreadArticleLastUserUpdateProps = {
  lastUserUpdate: string | null;
};

export const SpreadArticleLastUserUpdate =
  memo<SpreadArticleLastUserUpdateProps>((props) => {
    return (
      <div className="select-none text-sm">
        {props.lastUserUpdate ? (
          <Time date={props.lastUserUpdate} short />
        ) : (
          "-"
        )}
      </div>
    );
  });

export type ArticleLastUserUpdateProps = {
  article: {
    lastUserUpdate: string | null;
  };
};

export const ArticleLastUserUpdate = (props: ArticleLastUserUpdateProps) => {
  return (
    <SpreadArticleLastUserUpdate
      lastUserUpdate={props.article.lastUserUpdate}
    />
  );
};

ArticleLastUserUpdate.fragments = {
  article: gql`
    fragment ArticleLastUserUpdate_article on Article {
      lastUserUpdate
    }
  `,
};
