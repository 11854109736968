import moment from "moment";

import { sortByDate } from "@/containers/article/util/sortByDate";

type DateLike = Date | string | number;

// TODO: Type properly
type EventActor = any;
type Creator = any;

type Event = {
  globalId: string;
  updatedAt: DateLike;
  action: string;
  message?: string;
  actor?: EventActor;
};

function testAction(type: string, action: string): boolean {
  const regex = new RegExp(`${type}*`, "g");
  return regex.test(action);
}

function formatAddedEvents(events: Event[]): Event[] {
  const addedEvents = [...events];

  addedEvents.sort((a, b) => sortByDate(a.updatedAt, b.updatedAt));

  const articleEvents: Event[] = []; // aggregate background, node, social and video events
  const tooltipEvents: Event[] = []; // aggregate article, main and lmm events

  const nodeEvent = events.find((event) => testAction("node", event.action));
  if (nodeEvent?.action === "node_added") {
    articleEvents.push(nodeEvent);
  }
  const socialEvent = events.find((event) =>
    testAction("social", event.action),
  );
  if (socialEvent?.action === "social_added") {
    articleEvents.push(socialEvent);
  }
  const backgroundEvent = events.find((event) =>
    testAction("background", event.action),
  );
  if (backgroundEvent?.action === "background_added") {
    articleEvents.push(backgroundEvent);
  }
  if (articleEvents.length) {
    articleEvents.sort((a, b) => sortByDate(a.updatedAt, b.updatedAt));
    // only take into account the latest articleEvent
    tooltipEvents.push({ ...articleEvents[0]!, message: "Corps de l’article" });
  }
  const mainEvent = events.find((event) => testAction("main", event.action));
  if (mainEvent?.action === "main_added") {
    tooltipEvents.push({ ...mainEvent, message: "Média d’appel" });
  }
  const lmmEvent = events.find((event) => testAction("lmm", event.action));
  if (lmmEvent?.action === "lmm_added") {
    tooltipEvents.push({ ...lmmEvent, message: "Média d’appel matinale" });
  }
  return tooltipEvents.sort((a, b) => sortByDate(a.updatedAt, b.updatedAt));
}

export type MediaEventTooltipOptions = {
  used?: boolean;
  featured?: boolean;
  updatedAt?: DateLike;
  creator: Creator;
  events: Event[];
};

export function getMediaEventTooltip({
  used,
  featured,
  updatedAt,
  creator,
  events,
}: MediaEventTooltipOptions) {
  if (!events) return null;
  const addedEvents = formatAddedEvents(events);

  if (used || featured) {
    if (!addedEvents.length) return null;
    return (
      <ul className="flex flex-col gap-2">
        {addedEvents.map((event) => (
          <li key={event.globalId}>
            {`- ${event.message}: Ajouté le ${moment(event.updatedAt).format(
              "DD/MM/YY [à] HH:mm",
            )} par ${event.actor.fullName}`}
          </li>
        ))}
      </ul>
    );
  }

  if (!creator) return null;

  return `Suggéré le ${moment(updatedAt).format("DD/MM/YY [à] HH:mm")} par ${
    creator.fullName
  }`;
}
