import * as additionalShortcuts from "@/components/rich-editor/plugins/additional-shortcuts";
import * as invisibleCharacters from "@/components/rich-editor/plugins/invisible-characters";
import * as italicsBetweenQuotes from "@/components/rich-editor/plugins/italics-between-quotes";
import * as mention from "@/components/rich-editor/plugins/mention";
import * as search from "@/components/rich-editor/plugins/search";
import * as switchCase from "@/components/rich-editor/plugins/switch-case";
import * as text from "@/components/rich-editor/plugins/text";
import * as typographicRules from "@/components/rich-editor/plugins/typographic-rules";

import { useEditorConfig } from "../EditorConfig";
import { useEditorPlugins } from "../EditorPlugins";
import presetControls from "./preset-controls";

/**
 * @typedef {import('./preset-controls').Options & { search?: boolean, typographicRules?: boolean, italicsBetweenQuotes?: boolean }} Options
 */

/**
 * @param {Options} [options]
 */
const presetText = (options) => ({
  presets: [[presetControls, options]],
  plugins: [
    ...(options.mention ? [[mention, options.mention]] : []),
    ...(options.search ? [search] : []),
    ...(options.typographicRules ? [typographicRules] : []),
    ...(options.italicsBetweenQuotes ? [italicsBetweenQuotes] : []),
    additionalShortcuts,
    invisibleCharacters,
    switchCase,
    text,
  ],
});

export default presetText;

export const useTextPresetOptions = (options) => {
  const editorConfig = useEditorConfig();

  return {
    defaultLanguage: editorConfig.defaultLanguage,
    spellCheck: editorConfig.plugins.some(
      (plugin) => plugin.name === "spellCheck",
    ),
    headerThree: editorConfig.plugins.some(
      (plugin) => plugin.name === "headerThree",
    ),
    strikethrough: editorConfig.plugins.some(
      (plugin) => plugin.name === "strikethrough",
    ),
    typographicRules: editorConfig.plugins.some(
      (plugin) => plugin.name === "typographicRules",
    ),
    italicsBetweenQuotes: editorConfig.plugins.some(
      (plugin) => plugin.name === "italicsBetweenQuotes",
    ),
    image: false,
    anchor: false,
    ...options,
  };
};

/**
 * @param {Options} [options]
 */
export const useTextPreset = (options) => {
  const presetOptions = useTextPresetOptions(options);
  return useEditorPlugins({
    presets: [[presetText, presetOptions]],
  });
};
