import { forwardRef } from "react";
import { UseFieldConfig, useField } from "react-final-form";
import { Checkbox } from "swash/controls/Checkbox";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { FormLabel } from "swash/form/FormLabel";
import { As } from "swash/types";

import { FieldHint } from "@/components/fields/FieldHint";

import { FieldControl } from "./FieldControl";
import { FieldState, Orientation, useFieldState } from "./FieldState";

export type CheckboxFieldOptions = UseFieldConfig<any> & {
  id?: string;
  orientation?: Orientation;
  disabled?: boolean;
};

export function useCheckboxField(
  name: string,
  {
    id,
    orientation = "horizontal",
    disabled,
    ...options
  }: CheckboxFieldOptions = {},
) {
  const field = useField(name, {
    type: "checkbox",
    ...options,
  });

  return useFieldState({ field, id, orientation, disabled });
}

type CheckboxFieldProps = {
  state: FieldState;
  children?: React.ReactNode;
  hint?: string;
  as?: As;
} & React.HTMLAttributes<HTMLElement>;

export const CheckboxField = forwardRef<HTMLElement, CheckboxFieldProps>(
  ({ children, hint, ...props }, ref) => {
    return (
      <FieldControl as="input" ref={ref} {...props}>
        {({ "data-field-control": dataFieldControl, ...fieldControlProps }) => (
          <div data-field-control={dataFieldControl} className="flex">
            <FormCheckbox>
              <Checkbox {...fieldControlProps} />
              <FormLabel
                id={fieldControlProps["aria-labelledby"]}
                aria-disabled={fieldControlProps.disabled}
                htmlFor={fieldControlProps.id}
              >
                {children}
              </FormLabel>
              {hint && <FieldHint {...props}>{hint}</FieldHint>}
            </FormCheckbox>
          </div>
        )}
      </FieldControl>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  CheckboxField.displayName = "CheckboxField";
}
