import * as blockTemplates from "@/components/rich-editor/plugins/block-templates";
import * as blockquote from "@/components/rich-editor/plugins/blockquote";
import * as headerThree from "@/components/rich-editor/plugins/header-three";
import * as headerTwo from "@/components/rich-editor/plugins/header-two";
import * as orderedListItem from "@/components/rich-editor/plugins/ordered-list-item";
import * as unorderedListItem from "@/components/rich-editor/plugins/unordered-list-item";

/**
 * @typedef Options
 * @property {boolean} [options.headerThree]
 */

/**
 * @param {Options} [options]
 */
const presetTextStyles = (options) => ({
  plugins: [
    unorderedListItem,
    orderedListItem,
    blockquote,
    headerTwo,
    ...(options?.headerThree !== false ? [headerThree] : []),
    blockTemplates,
  ],
});

export default presetTextStyles;
