import { gql } from "@apollo/client";
import { memo } from "react";
import { ExportPrint, PaperCopy } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import {
  ArticlePrintInfos,
  ArticlePrintInfosProps,
} from "@/containers/article/ArticlePrintInfos";

export type SpreadArticlePrintStatusIconProps = {
  printInfos: {
    decorrelated: boolean;
  } & ArticlePrintInfosProps["printInfos"];
  size?: string | number;
};

export const SpreadArticlePrintStatusIcon =
  memo<SpreadArticlePrintStatusIconProps>(({ printInfos, size }) => {
    return (
      <Tooltip tooltip={<ArticlePrintInfos printInfos={printInfos} />}>
        <div className="inline-flex" style={{ fontSize: size }}>
          {printInfos.decorrelated ? <PaperCopy /> : <ExportPrint />}
        </div>
      </Tooltip>
    );
  });

export type ArticlePrintStatusIconProps = {
  article: {
    printInfos: SpreadArticlePrintStatusIconProps["printInfos"];
  };
  size?: SpreadArticlePrintStatusIconProps["size"];
};

export const ArticlePrintStatusIcon = ({
  article,
  size = "1em",
}: ArticlePrintStatusIconProps) => {
  if (!article.printInfos) return null;
  return (
    <SpreadArticlePrintStatusIcon printInfos={article.printInfos} size={size} />
  );
};

ArticlePrintStatusIcon.fragments = {
  article: gql`
    fragment ArticlePrintStatusIcon_article on Article {
      printInfos {
        decorrelated
        ...ArticlePrintInfos_printInfos
      }
    }
    ${ArticlePrintInfos.fragments.printInfos}
  `,
};
