import * as React from "react";
import { forwardRef } from "react";

export type AnchorProps = React.ComponentProps<"a">;

export const useAnchorProps = (props: AnchorProps) => {
  const isExternal = props.href?.startsWith("http");
  if (isExternal) {
    return {
      ...props,
      target: props.target ?? "_blank",
      rel: props.rel ?? "noopener noreferrer",
    };
  }

  return props;
};

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    const anchorProps = useAnchorProps(props);
    return <a ref={ref} {...anchorProps} />;
  },
);
