import { createPopper } from "@popperjs/core";
import styled, { x } from "@xstyled/styled-components";
import { Portal } from "ariakit/portal";
import {
  Fragment,
  createContext,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IoEllipsisVertical } from "swash/Icon";
import { useNextFrameTicked } from "swash/motion/Animation";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import {
  ControlToolbar,
  ControlToolbarItem,
  ControlToolbarSeparator,
} from "@/components/rich-editor/ControlToolbar";
import { generateShortcut } from "@/services/shortcuts";

const BLOCKS_ROW = {
  groups: ["block-style", "insert", "media", "text-to-speech"],
  title: "Outils d’édition des blocs",
};
const TEXT_ROW = {
  groups: ["history", "spell-check", "inline-style"],
  title: "Outils d’édition du texte",
};
const SINGLE_ROW = {
  groups: [...TEXT_ROW.groups, ...BLOCKS_ROW.groups],
  title: "Outils d’édition",
};

// All widths are in pixels
const TOOLBAR_CONFIG = {
  buttonWidth: 28,
  columnGap: 4,
  separatorWidth: 1,
};

const RichEditorToolbarContext = createContext();

export const RichFieldEditorToolbarProvider = ({ children, value }) => (
  <RichEditorToolbarContext.Provider value={value}>
    {children}
  </RichEditorToolbarContext.Provider>
);

export const RichEditorToolbarButton = forwardRef(
  (
    {
      active,
      disabled: propDisabled,
      disabledWithoutFocus = true,
      shortcut,
      command,
      ...props
    },
    ref,
  ) => {
    const {
      readOnly,
      hasFocus,
      handleKeyCommand,
      plugins,
      isAtomicAnchorBlock,
    } = useContext(RichEditorToolbarContext);

    const disabled = (() => {
      if (readOnly || propDisabled) return true;
      if (disabledWithoutFocus) {
        if (!hasFocus || (hasFocus && isAtomicAnchorBlock)) {
          return true;
        }
      }
      if (command) {
        return !plugins.some((plugin) => plugin.command === command);
      }
      return false;
    })();

    return (
      <ControlToolbarItem
        ref={ref}
        {...props}
        shortcut={generateShortcut(shortcut)}
        aria-pressed={active}
        disabled={disabled}
        onMouseDown={(event) => {
          if (props.onMouseDown) {
            props.onMouseDown(event);
          }

          if (command) {
            event.preventDefault();
            handleKeyCommand(command, event.timeStamp);
          }
        }}
        w={TOOLBAR_CONFIG.buttonWidth}
      />
    );
  },
);

/**
 * @param {import('./RichEditorState').RichEditorState & { singleLine?: boolean }} props
 */
export function RichEditorToolbar({
  singleLine = false,
  className,
  actions,
  ...props
}) {
  const { readOnly, hasFocus, plugins, anchorBlock } = props;

  const ticked = useNextFrameTicked();
  const propsRef = useLiveRef(props);

  const rowsConfig = useMemo(
    () => (singleLine ? [SINGLE_ROW] : [BLOCKS_ROW, TEXT_ROW]),
    [singleLine],
  );

  const pluginsVisibility = plugins
    .map((plugin) =>
      plugin.checkIsControlVisible
        ? {
            name: plugin.name,
            props: plugin.checkIsControlVisible(props),
          }
        : null,
    )
    .filter((pluginWithVisibility) => !!pluginWithVisibility)
    .reduce(
      (allProps, pluginWithVisibility) => ({
        ...allProps,
        [pluginWithVisibility.name]: pluginWithVisibility.props,
      }),
      {},
    );

  const rows = (() => {
    const groupsMap = propsRef.current.plugins
      .filter((plugin) => plugin.BlockControls)
      .reduce((groups, plugin) => {
        const { group } = plugin.BlockControls;
        const visible = pluginsVisibility[plugin.name];
        if (visible === undefined || visible) {
          groups[group] = groups[group] ?? [];
          groups[group].push(plugin);
        }
        return groups;
      }, {});
    return rowsConfig.map((rowConfig) => ({
      groups: rowConfig.groups.map((name) => groupsMap[name]).filter(Boolean),
      title: rowConfig.title,
    }));
  })();

  const handleKeyCommand = useCallback(
    (command, eventTimeStamp) => {
      const { handleKeyCommand, editorState } = propsRef.current;
      handleKeyCommand(command, editorState, eventTimeStamp);
    },
    [propsRef],
  );

  const isAtomicAnchorBlock = anchorBlock?.getType() === "atomic";

  const value = useMemo(
    () => ({
      readOnly,
      hasFocus,
      handleKeyCommand,
      plugins,
      isAtomicAnchorBlock,
    }),
    [readOnly, hasFocus, handleKeyCommand, plugins, isAtomicAnchorBlock],
  );

  return (
    <RichEditorToolbarContext.Provider value={value}>
      {ticked && (
        <div className="grow">
          {rows.map((row, index) => {
            if (index > 0) {
              return (
                <ToolbarRow
                  key={index}
                  groups={row.groups}
                  title={row.title}
                  {...props}
                />
              );
            }
            return (
              <div className="flex justify-between gap-2" key={index}>
                <div className="grow">
                  <ToolbarRow
                    groups={row.groups}
                    title={row.title}
                    {...props}
                  />
                </div>
                {actions}
              </div>
            );
          })}
        </div>
      )}
    </RichEditorToolbarContext.Provider>
  );
}

const ToolbarRow = ({ groups, title, ...props }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useResizeObserver((entry) => {
    setContainerWidth(entry.contentRect.width);
  });
  const { visibleGroups, hiddenGroups } = useMemo(
    () => getSplittedGroups(containerWidth, groups),
    [containerWidth, groups],
  );

  if (!groups.length) return null;

  return (
    <ControlToolbar
      ref={containerRef}
      aria-label={title}
      columnGap={`${TOOLBAR_CONFIG.columnGap}px`}
    >
      {visibleGroups.map((plugins, index) => {
        return (
          <Fragment key={index}>
            {plugins.map((plugin, pluginIndex) => {
              const isLastPlugin = groups.some(
                (group, groupIndex) =>
                  groupIndex !== groups.length - 1 &&
                  group.some(
                    (p, pIndex) =>
                      p.name === plugin.name && pIndex === group.length - 1,
                  ),
              );
              return (
                <x.div
                  display="flex"
                  key={pluginIndex}
                  columnGap={`${TOOLBAR_CONFIG.columnGap}px`}
                  flexDirection="row"
                >
                  <plugin.BlockControls
                    key={plugin.id}
                    {...props}
                    columnGap={`${TOOLBAR_CONFIG.columnGap}px`}
                    options={plugin.options}
                  />
                  {isLastPlugin && <ControlToolbarSeparator />}
                </x.div>
              );
            })}
          </Fragment>
        );
      })}
      {hiddenGroups.length > 0 && (
        <DisplayMorePlugins {...props} hiddenGroups={hiddenGroups} />
      )}
    </ControlToolbar>
  );
};

const DisplayMorePlugins = ({ hiddenGroups, ...props }) => {
  const [active, setActive] = useState(false);
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);
  const popper = useRef(null);

  const handleMouseDown = useCallback(() => {
    setActive((x) => !x);
  }, []);

  useLayoutEffect(() => {
    if (buttonRef.current && popoverRef.current && !popper.current) {
      popper.current = createPopper(buttonRef.current, popoverRef.current, {
        placement: "bottom",
      });
    }
    return () => {
      if (popper.current) {
        popper.current.destroy();
        popper.current = null;
      }
    };
  });

  return (
    <>
      <DisplayMorePluginsToolbarButton
        ref={buttonRef}
        active={active}
        onMouseDown={handleMouseDown}
      />
      <Portal>
        <div ref={popoverRef} className="z-popover">
          {active && (
            <HiddenPluginsPopover hiddenGroups={hiddenGroups} {...props} />
          )}
        </div>
      </Portal>
    </>
  );
};

const DisplayMorePluginsToolbarButton = memo(
  forwardRef((props, ref) => {
    return (
      <RichEditorToolbarButton
        ref={ref}
        label="Voir plus d’outils"
        {...props}
        onMouseDown={(event) => {
          event.preventDefault();
          props.onMouseDown(event);
        }}
      >
        <IoEllipsisVertical />
      </RichEditorToolbarButton>
    );
  }),
);

const PluginsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1;
  background-color: white;
  box-shadow: popover;
  border-radius: base;
  padding: 1;
  border: 1;
  border-color: grey-lighter;
  width: fit-content;
`;

const HiddenPluginsPopover = ({ hiddenGroups, ...props }) => {
  return (
    <PluginsContainer>
      {hiddenGroups.map((plugins, index) => (
        <Fragment key={index}>
          {plugins.map((plugin) => {
            return (
              <plugin.BlockControls
                key={plugin.id}
                {...props}
                columnGap={`${TOOLBAR_CONFIG.columnGap}px`}
                options={plugin.options}
              />
            );
          })}
          {index !== hiddenGroups.length - 1 && <ControlToolbarSeparator />}
        </Fragment>
      ))}
    </PluginsContainer>
  );
};

const getSplittedGroups = (containerWidth, groups) => {
  const { buttonWidth, columnGap, separatorWidth } = TOOLBAR_CONFIG;
  const visibleGroups = [];
  const hiddenGroups = [];
  let breakpoint = null;
  let previousGroupIndex = 0;
  let pluginsWidth = 0;

  groups.forEach((group, groupIndex) => {
    previousGroupIndex = groupIndex;
    group.forEach((plugin, pluginIndex) => {
      const { buttonsCount = 1, width } = plugin.BlockControls;

      const pluginWidth =
        width ?? buttonsCount * buttonWidth + (buttonsCount - 1) * columnGap;

      const isLastGroup = groupIndex === groups.length - 1;
      const isLastPlugin = pluginIndex === group.length - 1;
      const isLastPluginOfLastGroup = isLastGroup && isLastPlugin;

      const pluginWidthWithMargins =
        pluginWidth +
        (isLastPluginOfLastGroup ? 0 : columnGap) +
        (isLastPlugin && !isLastGroup ? separatorWidth + columnGap : 0);

      if (
        pluginsWidth + pluginWidthWithMargins <
        containerWidth - (isLastPluginOfLastGroup ? 0 : buttonWidth)
      ) {
        if (visibleGroups[groupIndex]) {
          visibleGroups[groupIndex].push(plugin);
        } else {
          visibleGroups.push([plugin]);
        }
      } else {
        if (breakpoint === null) {
          breakpoint = previousGroupIndex;
        }
        if (hiddenGroups[groupIndex - breakpoint]) {
          hiddenGroups[groupIndex - breakpoint].push(plugin);
        } else {
          hiddenGroups.push([plugin]);
        }
      }
      pluginsWidth += pluginWidthWithMargins;
    });
  });
  return {
    visibleGroups,
    hiddenGroups,
  };
};
