import {
  VirtualItem,
  Virtualizer,
  useVirtualizer,
} from "@tanstack/react-virtual";
import type { RefObject } from "react";
import * as React from "react";
import { useEffect } from "react";

import { useLiveRef } from "../utils/useLiveRef";
import type { ComboboxStore } from "./Combobox";
import { MenuCombobox, MenuComboboxList } from "./MenuCombobox";
import {
  RichSelectComboboxItem,
  RichSelectFooter,
  RichSelectScale,
  RichSelectState,
  RichSelectStateProps,
  RichSelectValue,
  useRichSelectState,
} from "./RichSelect";
import {
  Select,
  SelectClear,
  SelectPopover,
  SelectSeparator,
  SelectStore,
  useSelectStore,
} from "./Select";

type Result<TItem> = {
  items: TItem[];
  hasMore: boolean;
  totalCount: number;
};

export interface RemoteSelectState<TItem> extends RichSelectState<TItem> {
  select: SelectStore;
  combobox: ComboboxStore;
  searchable: boolean;
  data: Result<TItem> | null;
  getItem: (value: string) => TItem;
  loading: boolean;
  fetchNextPage: () => void;
}

export interface RemoteSelectStateProps<
  TItem,
  TValue extends TItem | TItem[] | null,
> extends RichSelectStateProps<TItem, TValue> {
  combobox: ComboboxStore;
  data: Result<TItem> | null;
  searchable?: boolean;
  loading: boolean;
  fetchMore: (previousData: Result<TItem>) => void;
  getItem: (value: string) => TItem;
}

export const useRemoteSelectState = <
  TItem,
  TValue extends TItem | TItem[] | null,
>(
  props: RemoteSelectStateProps<TItem, TValue>,
): RemoteSelectState<TItem> => {
  const rich = useRichSelectState(props);
  const { labelSelector, valueSelector } = rich;
  const refs = useLiveRef({
    valueSelector,
    labelSelector,
    onChange: props.onChange,
    data: props.data,
    setSearch: props.combobox.setValue,
    fetchMore: props.fetchMore,
    getItem: props.getItem,
  });
  refs.current.data = props.data;

  const value = React.useMemo(() => {
    const { valueSelector } = refs.current;
    if (props.value === null || props.value === "") return "";
    if (Array.isArray(props.value)) {
      return props.value.map((v) => valueSelector(v));
    }
    return valueSelector(props.value as TItem);
  }, [props.value, refs]);

  const setValue = React.useCallback(
    (value: string | string[]) => {
      const { getItem, onChange, data } = refs.current;
      if (value === "") {
        onChange(null as TValue);
      } else if (Array.isArray(value)) {
        if (!data) {
          throw new Error(`Invariant: data is null or undefined.`);
        }
        onChange(value.map((v) => getItem(v)) as TValue);
      } else {
        if (!data) {
          throw new Error(`Invariant: data is null or undefined.`);
        }
        onChange((getItem(value) ?? null) as TValue);
      }
    },
    [refs],
  );

  const { setValue: comboboxSetValue } = props.combobox;

  const select = useSelectStore({
    combobox: props.combobox,
    value,
    setValue,
  });
  const selectMounted = select.useState("mounted");

  const fetchNextPage = React.useCallback(() => {
    const { fetchMore, data } = refs.current;
    if (!data) {
      throw new Error(`Invariant: data is null or undefined.`);
    }
    fetchMore(data);
  }, [refs]);

  //Resets combobox value when popover is collapsed
  useEffect(() => {
    if (!selectMounted) {
      comboboxSetValue("");
    }
  }, [selectMounted, comboboxSetValue]);

  return {
    ...rich,
    select,
    searchable: props.searchable ?? true,
    combobox: props.combobox,
    data: props.data,
    fetchNextPage,
    getItem: props.getItem,
    loading: props.loading,
  };
};

export interface RemoteSelectListState<TItem> {
  listRef: RefObject<HTMLDivElement>;
  virtualItems: VirtualItem[];
  display: { selected: TItem[]; unselected: TItem[]; count: number };
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
}

export const useRemoteSelectList = <TItem,>(
  props: RemoteSelectListProps<TItem>,
): RemoteSelectListState<TItem> => {
  const {
    state: {
      select,
      combobox,
      data,
      valueSelector,
      getItem,
      fetchNextPage,
      loading,
    },
  } = props;

  const open = select.useState("open");
  const value = select.useState("value");

  const listRef = React.useRef<HTMLDivElement>(null);
  const hasMore = data?.hasMore ?? false;
  const items = React.useMemo(() => data?.items ?? [], [data?.items]);
  const totalCount = data?.totalCount ?? 0;
  const displayCount = data?.items.length ?? 0;
  const refs = useLiveRef({
    value,
    getItem,
    valueSelector,
  });
  const display = React.useMemo(() => {
    if (!open) {
      return { selected: [], unselected: [], count: 0 };
    }
    const { value, getItem, valueSelector } = refs.current;

    const arrValue = Array.isArray(value) ? value : value ? [value] : [];
    const selected = arrValue.map((v) => getItem(v));
    const unselected = items.filter(
      (item) => !arrValue.includes(valueSelector(item)),
    );
    const diff = items.length - unselected.length;
    return { selected, unselected, count: Math.max(0, totalCount - diff) };
  }, [items, totalCount, refs, open]);

  const rowVirtualizer = useVirtualizer({
    count: display.count,
    getScrollElement: () => listRef.current,
    estimateSize: () => 32,
    overscan: 30,
  });

  const { scrollToOffset } = rowVirtualizer;

  React.useEffect(() => {
    if (open) {
      scrollToOffset(0);
    }
  }, [open, scrollToOffset]);

  const virtualItems = rowVirtualizer.getVirtualItems();
  const virtualItemsRef = useLiveRef(virtualItems);

  // Fetch more when we get to the last item
  const lastItem = virtualItems[virtualItems.length - 1];
  React.useEffect(() => {
    if (
      lastItem &&
      lastItem.index >= displayCount - 1 &&
      hasMore &&
      !loading &&
      open
    ) {
      fetchNextPage();
    }
  }, [lastItem, hasMore, loading, fetchNextPage, displayCount, open]);

  // Scroll to first element when value changes
  const comboboxValue = combobox.useState("value");
  const { scrollToIndex } = rowVirtualizer;
  React.useEffect(() => {
    if (comboboxValue && virtualItemsRef.current.length > 0) {
      scrollToIndex(0);
    }
  }, [comboboxValue, scrollToIndex, virtualItemsRef]);

  return { listRef, virtualItems, display, rowVirtualizer };
};

export interface RemoteSelectListProps<TItem> {
  state: RemoteSelectState<TItem>;
  placeholder?: string;
  "aria-label"?: string;
}

export const RemoteSelectList = <TItem,>(
  props: RemoteSelectListProps<TItem>,
) => {
  const {
    state: { select, combobox, searchable, title, emptyMessage },
    placeholder = title ? `${title}...` : "Rechercher...",
    "aria-label": ariaLabel,
  } = props;

  const { listRef, virtualItems, display, rowVirtualizer } =
    useRemoteSelectList(props);

  return (
    <>
      {searchable && (
        <MenuCombobox store={combobox} placeholder={placeholder} />
      )}
      <MenuComboboxList
        ref={listRef}
        store={combobox}
        aria-label={ariaLabel}
        className="min-w-60"
        empty={!searchable}
      >
        {display.selected.map((item, i) => (
          <RichSelectComboboxItem
            key={`selected-${i}`}
            state={props.state}
            item={item}
            className="overflow-hidden text-ellipsis whitespace-nowrap"
          />
        ))}
        {display.selected.length > 0 && virtualItems.length > 0 && (
          <SelectSeparator key="separator" store={select} />
        )}
        {virtualItems.length > 0 && (
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {virtualItems.map((virtualItem) => {
              const item = display.unselected[virtualItem.index];
              if (!item) return null;
              return (
                <RichSelectComboboxItem
                  key={virtualItem.key}
                  state={props.state}
                  item={item}
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                />
              );
            })}
          </div>
        )}
        {!virtualItems.length && !display.selected.length && (
          <div className="p-2 text-sm text-gray-500">{emptyMessage}</div>
        )}
      </MenuComboboxList>
      {props.state.footer && <RichSelectFooter footer={props.state.footer} />}
    </>
  );
};

export interface RemoteSelectProps<TItem> {
  state: RemoteSelectState<TItem>;
  placeholder?: string;
  placeholderIcon?: React.ReactNode;
  clearLabel?: string;
  disabled?: boolean;
  scale?: RichSelectScale;
  modal?: boolean;
  "aria-label"?: string;
  gutter?: number;
}

const InnerRemoteSelect = <TItem,>(
  props: RemoteSelectProps<TItem>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  return (
    <>
      <Select
        ref={ref}
        store={props.state.select}
        disabled={props.disabled}
        scale={props.scale}
        aria-label={props["aria-label"]}
      >
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          <RichSelectValue
            state={props.state}
            scale={props.scale}
            placeholder={props.placeholder}
            placeholderIcon={props.placeholderIcon}
          />
        </div>
        <SelectClear
          store={props.state.select}
          aria-label={props.clearLabel}
          clearable={!props.state.required}
        />
      </Select>
      <SelectPopover
        store={props.state.select}
        modal={props.modal}
        combobox
        gutter={props.gutter}
      >
        <RemoteSelectList
          aria-label={props["aria-label"]}
          placeholder={props.placeholder}
          state={props.state}
        />
      </SelectPopover>
    </>
  );
};

export const RemoteSelect = React.forwardRef(InnerRemoteSelect) as <TItem>(
  props: RemoteSelectProps<TItem> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof InnerRemoteSelect>;
