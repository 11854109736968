/* eslint-disable no-param-reassign */
import { memo } from "react";

import { Subscript } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { handleBinding } from "../utils";
import { INLINE_STYLE, command } from "./subscript";

const SHORTCUT = ["⌘", "⌥", "i"];

export const shortcuts = [
  {
    description: "Indice",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "subscript-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const SubscriptToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      command={command}
      label="Indice"
      shortcut={SHORTCUT}
    >
      <Subscript />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <SubscriptToolbarButton
      active={editorState.getCurrentInlineStyle().has(INLINE_STYLE)}
    />
  );
};

BlockControls.group = "inline-style";
