import deepEqual from "deep-equal";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";

export function ArrayField({ name, label, hint, validate }) {
  const field = useTextInputField(name, {
    validate,
    parse: (value) => {
      const x = value
        .split(",")
        .map((x) => x.trim())
        .filter(Boolean);
      x.currentValue = value;
      return x;
    },
    format: (value) => {
      return value ? value.currentValue || value.join(", ") : "";
    },
    isEqual: (a, b) => {
      const value1 = Array.isArray(a) ? [...a] : a;
      const value2 = Array.isArray(b) ? [...b] : b;
      return deepEqual(value1, value2);
    },
  });

  return (
    <FieldGroup {...field}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <TextInputField {...field} w={1} />
    </FieldGroup>
  );
}
