import React, { useEffect, useState } from "react";

import * as storage from "./localStorage";

export function useStorage<T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(
    () => storage.getItem(key) ?? initialValue,
  );

  useEffect(() => {
    if (value === undefined) return;
    storage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}
