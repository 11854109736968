/* eslint-disable react/prop-types */
import styled, { system, x } from "@xstyled/styled-components";
import React, { forwardRef, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

import { Loader } from "../Loader";
import xSvg from "./assets/x.svg";

export const TextboxEndAdornment = (props) => {
  return <div data-swash-textbox-end-adornent="" {...props} />;
};

const OuterTextBox = styled.box`
  display: flex;

  > [data-swash-textbox-end-adornent] {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  > [data-swash-textbox-input] {
    flex: 1 0 0;
    width: 0;
    line-height: 1;
    padding: 2 2;
    font-family: base;
    border-radius: 4px;
    color: inherit;

    &:focus-visible {
      outline: none;
    }

    /* Remove arrows for number input */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="search"] {
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        border-radius: 50em;
        background: url(${xSvg}) no-repeat 50% 50%;
        background-size: contain;
        opacity: 0;
        pointer-events: none;
      }

      &:focus::-webkit-search-cancel-button {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  appearance: none;
  border: 1;
  border-color: layout-border;
  border-radius: 4px;
  background-color: white;
  transition: base;
  transition-property: default;
  color: dusk;
  position: relative;

  &::placeholder {
    color: grey-dark;
  }

  &:focus-within:not([data-invalid="true"]),
  &[data-focused]:not([data-invalid="true"]) {
    border-color: primary;
  }

  &:hover:not([data-disabled]):not([data-invalid="true"]) {
    border-color: primary;
  }

  &[data-disabled] {
    opacity: 0.5;
    > svg {
      fill: dusk;
    }
  }

  &[data-scale="base"] {
    height: 30px;

    > [data-swash-textbox-input] {
      font-size: sm;
      padding: 6px 2;
    }

    > [data-swash-textbox-end-adornent] {
      padding: 6px 2;
      padding-left: 0;
    }
  }

  &[data-scale="sm"] {
    height: 26px;

    > [data-swash-textbox-input] {
      font-size: xs;
      padding: 1 2;
    }

    > [data-swash-textbox-end-adornent] {
      padding: 1 2;
      padding-left: 0;
    }
  }

  &[data-scale="lg"] {
    height: 40px;

    > [data-swash-textbox-input] {
      font-size: base;
      padding: 6px 9px;
    }

    > [data-swash-textbox-end-adornent] {
      padding: 6px 9px;
      padding-left: 0;
    }
  }

  &[data-borderless="true"] {
    border: none;
    border-radius: 0;
  }

  &[data-invalid="true"] {
    border-color: danger-dark;

    &:focus-within {
      border-color: danger-dark;
    }

    &:hover {
      border-color: danger-dark;
    }
  }

  && {
    ${system}
  }
`;

// https://developer.mozilla.org/fr/docs/Web/HTML/Element/input
// https://developer.mozilla.org/fr/docs/Web/HTML/Element/textarea
const INPUT_ATTRS = [
  // Global
  "id",
  "tabIndex",

  // Aria
  "aria-invalid",
  "aria-labelledby",
  "aria-label",
  "aria-controls",
  "aria-expanded",
  "aria-haspopup",
  "aria-owns",
  "role",

  // <input />
  "autoCapitalize",
  "autoFocus",
  "accept",
  "alt",
  "autoComplete",
  "capture",
  "dirname",
  "disabled",
  "form",
  "formAction",
  "formEncType",
  "formMethode",
  "formNoValidate",
  "formTarget",
  "height",
  "list",
  "max",
  "maxLength",
  "min",
  "minLength",
  "multiple",
  "name",
  "pattern",
  "placeholder",
  "readOnly",
  "required",
  "size",
  "src",
  "step",
  "type",
  "value",
  "width",
  "spellCheck",

  // <textarea>
  "cols",
  "rows",
  "wrap",

  // MaskInput
  "mask",

  // events
  "onTouchCancel",
  "onTouchEnd",
  "onTouchMove",
  "onTouchStart",

  "onPointerDown",
  "onPointerMove",
  "onPointerUp",
  "onPointerCancel",
  "onGotPointerCapture",
  "onLostPointerCapture",
  "onPointerEnter",
  "onPointerLeave",
  "onPointerOver",
  "onPointerOut",

  "onClick",
  "onContextMenu",
  "onDoubleClick",
  "onDrag",
  "onDragEnd",
  "onDragEnter",
  "onDragExit",
  "onDragLeave",
  "onDragOver",
  "onDragStart",
  "onDrop",
  "onMouseDown",
  "onMouseDownCapture",
  "onMouseEnter",
  "onMouseLeave",
  "onMouseMove",
  "onMouseOut",
  "onMouseOver",
  "onMouseUp",

  "onChange",
  "onInput",
  "onInvalid",

  "onFocus",
  "onBlur",

  "onKeyDown",
  "onKeyDownCapture",
  "onKeyPress",
  "onKeyPressCapture",
  "onKeyUp",
  "onKeyUpCapture",

  "onCompositionEnd",
  "onCompositionStart",
  "onCompositionUpdate",

  "onCopy",
  "onCut",
  "onPaste",
];

const useInputProps = (props) => {
  return Object.keys(props).reduce(
    (all, key) => {
      if (INPUT_ATTRS.includes(key)) {
        all[0][key] = props[key];
      } else {
        all[1][key] = props[key];
      }
      return all;
    },
    [{}, {}],
  );
};

export const Textbox = forwardRef(
  (
    { scale = "base", borderless, children, as: As = x.input, ...props },
    ref,
  ) => {
    const [inputProps, otherProps] = useInputProps(props);
    const inputRef = useRef();
    const inputRefs = useMemo(
      () => mergeRefs([ref, inputRef]),
      [ref, inputRef],
    );

    return (
      <OuterTextBox
        data-scale={scale}
        data-borderless={borderless}
        data-disabled={inputProps.disabled ? "" : undefined}
        data-invalid={inputProps["aria-invalid"]}
        role="textbox"
        {...otherProps}
      >
        <As ref={inputRefs} data-swash-textbox-input="" {...inputProps} />
        {children}
      </OuterTextBox>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Textbox.displayName = "Textbox";
}

export const TextboxProgress = forwardRef((props, ref) => {
  return (
    <TextboxEndAdornment
      ref={ref}
      style={{ pointerEvents: "none", ...props.style }}
      {...props}
    >
      <Loader scale="inherit" delay={0} />
    </TextboxEndAdornment>
  );
});
if (process.env["NODE_ENV"] !== "production") {
  TextboxProgress.displayName = "TextboxProgress";
}
