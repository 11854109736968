import { gql } from "@apollo/client";
import { memo } from "react";
import { cn } from "swash/utils/classNames";

import type { ArticleEditorialTypeLabel_articleFragment } from "@/gql-types";

export type ArticleEditorialTypeLabelProps = {
  article: ArticleEditorialTypeLabel_articleFragment;
  className?: string;
};

type SpreadArticleEditorialTypeLabelProps =
  ArticleEditorialTypeLabelProps["article"] & { className?: string };

export const SpreadArticleEditorialTypeLabel =
  memo<SpreadArticleEditorialTypeLabelProps>(({ editorialType, className }) => {
    return (
      <span className={cn(className, "select-none font-accent uppercase")}>
        {editorialType.label}
      </span>
    );
  });

export const ArticleEditorialTypeLabel = ({
  article,
  ...props
}: ArticleEditorialTypeLabelProps) => {
  return (
    <SpreadArticleEditorialTypeLabel
      editorialType={article.editorialType}
      {...props}
    />
  );
};

ArticleEditorialTypeLabel.fragments = {
  article: gql`
    fragment ArticleEditorialTypeLabel_article on Article {
      editorialType {
        id
        label
      }
    }
  `,
};
