import { gql } from "@apollo/client";

import { EventStatusIcon } from "./EventStatusIcon";
import { List } from "./List";

type EventStatus = {
  id: number;
  status: "queued" | "in_progress" | "completed";
  conclusion: "failure" | "timed_out" | "success";
  output: {
    title: string;
    summary: string;
  };
  app: {
    id: number;
    name: string;
  };
};

type StatusDetailProps = {
  status: EventStatus;
};

const StatusDetail = ({ status }: StatusDetailProps) => {
  return (
    <div
      className="grid items-center gap-1"
      style={{ gridTemplateColumns: "min-content 1fr" }}
    >
      <EventStatusIcon status={status.conclusion ?? status.status} />{" "}
      {status.app.name} - {status.output.title}
    </div>
  );
};

export type EventStatusListProps = {
  event: {
    statuses: EventStatus[];
  };
};

export const EventStatusList = (props: EventStatusListProps) => {
  return (
    <List>
      {props.event.statuses.map((status) => (
        <li key={status.id}>
          <StatusDetail status={status} />
        </li>
      ))}
    </List>
  );
};

EventStatusList.fragments = {
  event: gql`
    fragment EventStatusList_event on Event {
      statuses {
        id
        status
        conclusion
        output {
          title
          summary
        }
        app {
          id
          name
        }
      }
    }
  `,
};
