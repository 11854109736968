import { memo, useCallback } from "react";
import { Headphone } from "swash/Icon";

import { useSetPlayerUrl } from "@/components/audio-player/AudioPlayerContext";
import { useAmplitude } from "@/containers/Amplitude";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { getTextFromSelection } from "../utils/Selection";

export const name = "text-to-speech-control";
export const label = "Écouter la sélection";

const TextToSpeechToolbarButton = memo(({ disabled, label, onPressPlay }) => {
  return (
    <RichEditorToolbarButton
      label={label}
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();
        onPressPlay();
      }}
    >
      <Headphone />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({
  hasFocus,
  readOnly,
  editorState,
  options: { articleId },
}) => {
  const selection = editorState.getSelection();
  const setPlayerUrl = useSetPlayerUrl();
  const { logEvent } = useAmplitude();
  const contentState = editorState.getCurrentContent();
  const text = getTextFromSelection(contentState, selection);
  const disabled = !text.trim().length;

  const handlePressPlay = useCallback(() => {
    setPlayerUrl(
      `/text-to-speech-extract/${articleId}?text=${encodeURIComponent(
        text,
      )}&id=${Date.now()}`,
    );
    logEvent("listenSelectionArticle");
  }, [setPlayerUrl, text, articleId, logEvent]);

  return (
    <TextToSpeechToolbarButton
      label={label}
      disabled={!hasFocus || readOnly || selection.isCollapsed() || disabled}
      onPressPlay={handlePressPlay}
    />
  );
};

BlockControls.group = "text-to-speech";

export const checkIsControlVisible = ({ audioEnabled }) => audioEnabled;
