import { forwardRef } from "react";

export const CapsuleList = forwardRef(({ children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className="relative flex-1 p-3 opacity-100 transition-all aria-[disabled=true]:opacity-70"
      style={{ minHeight: 60 }}
      {...props}
    >
      {children}
    </div>
  );
});
