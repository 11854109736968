// @ts-check
import { visit } from "unist-util-visit";

const SPACE_CHAR = "\u0020";
const NON_BREAKING_SPACE_CHAR = "\u00a0";
const SPACES_REGEXP = new RegExp(`${SPACE_CHAR}+`, "g");
const NON_BREAKING_SPACES_REGEXP = new RegExp(
  `${NON_BREAKING_SPACE_CHAR}+`,
  "g",
);

/**
 * Normalize spaces.
 * @param {import('hast').Root} root
 * @returns {import('hast').Root}
 */
export const normalizeSpaces = (root) => {
  visit(root, "text", (textNode) => {
    // Replace multiple spaces
    textNode.value = textNode.value.replace(SPACES_REGEXP, SPACE_CHAR);
    // // Replace multiple no-break spaces
    textNode.value = textNode.value.replace(
      NON_BREAKING_SPACES_REGEXP,
      NON_BREAKING_SPACE_CHAR,
    );
  });

  return root;
};
