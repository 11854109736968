import { memo } from "react";

import { getHeaderStyle } from "../List";
import type { CellType } from "../ListState";
import { ListHeader, ListHeaderCell } from "../NudeList";

export type HeaderRowProps<TNode> = {
  cells: CellType<TNode>[];
};

const HeaderCell = <TNode,>({ cell }: { cell: CellType<TNode> }) => {
  const visible = cell.useIsVisible ? cell.useIsVisible() : true;
  if (!visible) return null;
  return (
    <ListHeaderCell key={cell.key} style={getHeaderStyle(cell)}>
      {cell.title}
    </ListHeaderCell>
  );
};

const HeaderRowWrapped = <TNode,>(props: HeaderRowProps<TNode>) => {
  return (
    <ListHeader>
      {props.cells.map((cell) => {
        return <HeaderCell key={cell.key} cell={cell} />;
      })}
    </ListHeader>
  );
};

export const HeaderRow = memo(HeaderRowWrapped) as typeof HeaderRowWrapped;

export const estimateHeaderRowSize = () => {
  return 24;
};
