import { useCallback, useRef } from "react";

import { useLiveRef } from "./useLiveRef";

type Cleanup = () => void;

/**
 * Create a ref handler.
 */
export const useRefHandler = <T>(effect: (value: T) => Cleanup | undefined) => {
  const effectRef = useLiveRef(effect);
  const cleanupRef = useRef<Cleanup | undefined>();
  const valueRef = useRef<T | null>(null);
  const setup = useCallback(
    (value: T) => {
      if (Object.is(value, valueRef.current)) return;
      if (typeof cleanupRef.current === "function") cleanupRef.current();
      valueRef.current = value;
      cleanupRef.current = effectRef.current(value);
    },
    [effectRef],
  );
  return setup;
};
