import { createContext, useContext, useState } from "react";

const PlayerUrlContext = createContext();
const SetPlayerUrlContext = createContext();

export const PlayerProvider = ({ children }) => {
  const [url, setUrl] = useState(null);
  return (
    <PlayerUrlContext.Provider value={url}>
      <SetPlayerUrlContext.Provider value={setUrl}>
        {children}
      </SetPlayerUrlContext.Provider>
    </PlayerUrlContext.Provider>
  );
};

export const usePlayerUrl = () => useContext(PlayerUrlContext);
export const useSetPlayerUrl = () => useContext(SetPlayerUrlContext);
