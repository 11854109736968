import {createRoot} from "react-dom/client";

import Login from "./Login";
import "swash/global.css";
import "./styles/static.css";
import "./styles/static-login.css";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<Login />);


