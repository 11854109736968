import { gql } from "@apollo/client";
import { memo } from "react";

import { useUser } from "@/containers/User";
import type { ArticleTitleLink_articleFragment } from "@/gql-types";

import { ArticleLink } from "./ArticleLink";
import { ArticleTitle, SpreadArticleTitle } from "./ArticleTitle";

export type SpreadArticleTitleLinkProps = {
  id: number;
  title?: string | null;
  className?: string;
};

export const SpreadArticleTitleLink = memo<SpreadArticleTitleLinkProps>(
  ({ id, title, className }) => {
    const user = useUser();
    return (
      <ArticleLink
        articleId={id}
        inherit
        target={user.settings.openLinkInNewTab ? "_blank" : undefined}
        onClick={(event) => event.stopPropagation()}
        className={className}
      >
        <SpreadArticleTitle title={title} />
      </ArticleLink>
    );
  },
);

export type ArticleTitleLinkProps = {
  article: ArticleTitleLink_articleFragment;
  className?: string;
};

export const ArticleTitleLink = (props: ArticleTitleLinkProps) => {
  return (
    <SpreadArticleTitleLink
      id={props.article.id}
      title={props.article.title}
      className={props.className}
    />
  );
};

ArticleTitleLink.fragments = {
  article: gql`
    fragment ArticleTitleLink_article on Article {
      id
      ...ArticleTitle_article
    }

    ${ArticleTitle.fragments.article}
  `,
};
