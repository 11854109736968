import { IoExitOutline } from "swash/Icon";
import { Link } from "swash/Link";

import { useRemoteConfig } from "@/containers/RemoteConfig";

import { UsedInList } from "./UsedInList";

export const name = "Campagnes";

const columns = [
  {
    id: "title",
    Header() {
      return "Nom";
    },
    Value({ node, EditLink }) {
      return <EditLink slug="campaigns">{node.title}</EditLink>;
    },
  },
  {
    id: "partnerName",
    Header() {
      return "Nom du partenaire";
    },
    Value({ node }) {
      return node.partnerName;
    },
  },
  {
    id: "url",
    Header() {
      return "URL";
    },
    Value({ node }) {
      const { frontBaseUrl } = useRemoteConfig();
      return (
        <Link target="_blank" href={`${frontBaseUrl}/${node.url}`}>
          {node.url} <IoExitOutline />
        </Link>
      );
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
