export const INLINE_STYLE = "UNDERLINE";

export const allowedInlineStyles = [INLINE_STYLE];
export const allowedAttributes = { u: [] };

/** @type {import('../types').htmlToStyle} */
export const htmlToStyle = ({ element, getStyle, entity }) => {
  const { tagName } = element;
  // If inside a link, do nothing
  if (
    entity?.getType() === "COMPOSITE" &&
    entity?.getData().entities.some(({ type }) => type === "LINK")
  ) {
    return;
  }
  const checkHasUnderlineStyle = () => {
    const styles = getStyle();
    const textDecoration = styles["text-decoration"];
    return textDecoration === "underline";
  };
  if (tagName === "u" || checkHasUnderlineStyle()) {
    // If it is inside a link, do not add underline
    // if (ancestors.length && ancestors[0].tagName === "a")
    //   return currentStyle.remove(INLINE_STYLE);
    return [INLINE_STYLE];
  }
  return [];
};
