import { useCallback, useState } from "react";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { mergeConnections, useFetchMoreQuery } from "@/containers/Apollo";
import {
  useArticleCommentPanel,
  useArticleCommentsQueryOptions,
} from "@/containers/article/ArticleCommentsContext";
import { ArticleCommentDeleteDialog } from "@/containers/article/panels/comments/ArticleCommentForm";
import { ArticleCommentList } from "@/containers/article/panels/comments/ArticleCommentList";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";

type DeletingComment = {
  commentId: number;
  parentId: number | null;
};

type ArticleData = {
  article: {
    id: number;
    comments: {
      nodes: Array<any>;
      pageInfo: any;
    };
    notes: {
      nodes: Array<any>;
      pageInfo: any;
    };
  };
};

type FilterStatus = "opened" | "resolved";

interface ArticleCommentsTemplateProps {
  resolvers: {
    [key in FilterStatus]: {
      label: string;
      labelElement: string;
    };
  };
  children?:
    | React.ReactNode
    | (({
        article,
        selectedId,
      }: {
        article?: ArticleData["article"];
        selectedId: FilterStatus;
      }) => React.ReactNode);
}

export const ArticleCommentsTemplate = ({
  resolvers,
  children,
}: ArticleCommentsTemplateProps) => {
  const [selectedId, setSelectedId] = useArticleCommentPanel();
  const scope = useCommentScope();
  const template: "notes" | "comments" =
    scope === "notes" ? "notes" : "comments";
  const select = useEnumSelectState<FilterStatus, FilterStatus>({
    value: selectedId,
    onChange: setSelectedId,
    items: ["opened", "resolved"],
    labelSelector: (item) => resolvers[item].label,
    labelElementSelector: (item) => resolvers[item].labelElement,
    required: true, // not clearable
  });

  const [deletingComment, setDeletingComment] =
    useState<DeletingComment | null>(null);

  const handleCommentDeleteClose = useCallback(() => {
    setDeletingComment(null);
  }, []);

  const queryOptions = useArticleCommentsQueryOptions();

  const queryResult = useFetchMoreQuery(queryOptions.query, {
    variables: queryOptions.variables,
    skip: !queryOptions.variables.articleId,
  });

  const data = queryResult.data ?? queryResult.previousData;
  const hasMore = data?.article[template].pageInfo?.hasMore ?? false;
  const article = data?.article;
  const { fetchMore, fetchMoreLoading } = queryResult;

  const loadMore = useCallback(() => {
    if (!hasMore) return;
    fetchMore({
      variables: {
        offset: article[template].nodes.length,
      },
      updateQuery: (
        previousResult: ArticleData,
        { fetchMoreResult }: { fetchMoreResult: ArticleData },
      ) => {
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            [template]: mergeConnections(
              previousResult.article[template],
              fetchMoreResult.article[template],
            ),
          },
        };
      },
    });
  }, [article, fetchMore, hasMore, template]);

  return (
    <>
      <div className="flex select-none self-end px-4 pb-2">
        <EnumSelect state={select} aria-label="Filtrer les commentaires" />
      </div>
      <ArticleCommentList
        article={article}
        comments={article?.[template].nodes}
        setDeletingComment={setDeletingComment}
        hasMore={hasMore}
        loadMore={loadMore}
        loading={fetchMoreLoading}
      />
      <ArticleCommentDeleteDialog
        // @ts-expect-error use a js component
        comment={deletingComment}
        onClose={handleCommentDeleteClose}
      />
      {typeof children === "function"
        ? children({ article, selectedId })
        : children}
    </>
  );
};
