import { gql } from "@apollo/client";

import { ProductNode } from "@/containers/editor/nodes/ProductNode";
import { ProductSummaryNode } from "@/containers/editor/nodes/ProductSummaryNode";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";

import { ArticleNodeFragments } from "./nodes/ArticleNodeFragments";
import { ImageNodeFragment } from "./nodes/ImageNodeFragment";

export const DraftEditorNodeFragment = gql`
  fragment DraftEditorNodeFragment on EditorNode {
    id
    type
    ... on EditorNodeText {
      text
      metadata {
        type
        styleName
      }
      comments {
        commentId
        resolved
        startOffset
        endOffset
        listIndex
      }
    }

    ... on EditorNodeArticle {
      articlesId
      articleMetadata: metadata {
        title
        prefix
        displayMode
      }
      article {
        id
        ...ArticleNode_article
      }
    }

    ... on EditorNodeArticleMedia {
      articleMedia {
        __typename
        id
        media {
          id
          ... on Image {
            url
            caption
          }
          ... on Video {
            url
            videoTitle: title
          }
          ... on Snippet {
            url
            snippetTitle: title
            code
          }
          ... on Tweet {
            url
            text
          }
          ... on Article {
            url
            title
          }
          ... on CustomTypeContent {
            label
          }
          ... on Product {
            name
          }
          ... on ProductSummary {
            name
          }
        }
      }
    }

    ... on EditorNodeTweet {
      tweetsId
      tweet {
        id
        ...TweetNode_tweet
      }
    }
    ... on EditorNodeImage {
      imagesId
      imageMetadata: metadata {
        caption
        aspectRatioId
        crops
      }
      image {
        id
        ...ImageNode_image
      }
    }

    ... on EditorNodeVideo {
      videosId
      video {
        id
        ...VideoNode_video
      }
    }

    ... on EditorNodeSnippet {
      snippetsId
      snippet {
        id
        ...SnippetNode_snippet
      }
    }

    ... on EditorNodeProduct {
      productsId
      product {
        id
        ...ProductNode_product
      }
    }

    ... on EditorNodeProductSummary {
      productsSummariesId
      productSummary {
        id
        ...ProductSummaryNode_productSummary
      }
    }
  }

  ${ArticleNodeFragments.article}
  ${TweetNode.fragments.tweet}
  ${ImageNodeFragment.image}
  ${VideoNode.fragments.video}
  ${SnippetNodeFragments.snippet}
  ${ProductNode.fragments.product}
  ${ProductSummaryNode.fragments.productSummary}
`;
