import { memo } from "react";

import { Bold } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { INLINE_STYLE, command } from "./bold";

const SHORTCUT = ["⌘", "b"];

export const shortcuts = [
  {
    description: "Gras",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "bold-control";

const BoldToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      command={command}
      label="Gras"
      shortcut={SHORTCUT}
    >
      <Bold />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <BoldToolbarButton
      active={editorState.getCurrentInlineStyle().has(INLINE_STYLE)}
    />
  );
};

BlockControls.group = "inline-style";
