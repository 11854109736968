import * as React from "react";
import { forwardRef } from "react";

const SvgIconDesktop = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M27.654 4H5.346c-.887 0-1.738.342-2.366.952A3.203 3.203 0 0 0 2 7.25v15.167c0 .862.353 1.688.98 2.298.628.61 1.479.952 2.366.952h10.039v2.166h-3.346c-.296 0-.58.115-.79.318-.208.203-.326.478-.326.766 0 .287.118.563.327.766.209.203.493.317.789.317h8.923c.295 0 .58-.114.788-.317.21-.203.327-.479.327-.766 0-.288-.118-.563-.327-.766a1.132 1.132 0 0 0-.788-.318h-3.347v-2.166h10.039c.887 0 1.738-.343 2.366-.952.628-.61.98-1.436.98-2.298V7.25c0-.862-.352-1.689-.98-2.298A3.397 3.397 0 0 0 27.654 4ZM5.346 6.167h22.308c.296 0 .58.114.788.317.21.203.327.479.327.766v11.833H4.231V7.25c0-.287.117-.563.326-.766.21-.203.493-.317.79-.317ZM27.654 23.5H5.346c-.296 0-.58-.114-.789-.317a1.068 1.068 0 0 1-.326-.766V21.25h24.538v1.167c0 .287-.117.563-.326.766-.21.203-.493.317-.79.317Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconDesktop);
export default ForwardRef;
