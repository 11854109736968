import { UsedInList } from "./UsedInList";

export const name = "Rubriques";

const columns = [
  {
    id: "title",
    Header() {
      return "Titre";
    },
    Value({ node, EditLink }) {
      return <EditLink slug="tags">{node.title}</EditLink>;
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
