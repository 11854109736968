import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { memo } from "react";
import { capitalize } from "underscore.string";

import type { ArticlePublishDate_articleFragment } from "@/gql-types";

export const formatDate = (date: string) => {
  const mDate = moment(date).tz("Europe/Paris");
  if (mDate.isSame(Date.now(), "year")) {
    return capitalize(mDate.format("ddd D MMM"));
  }
  return mDate.format("DD/MM/YYYY");
};

export const formatRangeDate = (date: object, type: "from" | "to") => {
  const mDate = moment(date).tz("Europe/Paris");

  if (mDate.isSame(Date.now(), "year")) {
    return mDate.format("D MMM");
  }

  if (type === "from") {
    return mDate.format("DD/MM");
  }
  return mDate.format("DD/MM/YYYY");
};

export type SpreadArticlePublishDateProps = ArticlePublishDate_articleFragment;

export const SpreadArticlePublishDate = memo<SpreadArticlePublishDateProps>(
  (props) => {
    if (props.initialFirstPublished) {
      return <>{formatDate(props.initialFirstPublished)}</>;
    }
    if (!props.planning?.date) {
      return <div className="text-grey-on">Non daté</div>;
    }

    if (
      props.planning?.range &&
      ["week", "month"].includes(props.planning.range)
    ) {
      const mDate = moment(props.planning.date).tz("Europe/Paris");
      const range = props.planning.range as "week" | "month";
      const startDate = formatRangeDate(mDate.startOf(range), "from");
      const endDate = formatRangeDate(moment(mDate).endOf(range), "to");

      return (
        <>
          {startDate} <span className="font-sans">→</span> {endDate}
        </>
      );
    }
    return <>{formatDate(props.planning.date)}</>;
  },
);

export type ArticlePublishDateProps = {
  article: SpreadArticlePublishDateProps;
};

export const ArticlePublishDate = (props: ArticlePublishDateProps) => {
  return <SpreadArticlePublishDate {...props.article} />;
};

ArticlePublishDate.fragments = {
  article: gql`
    fragment ArticlePublishDate_article on Article {
      initialFirstPublished
      planning {
        date
        range
      }
    }
  `,
};
