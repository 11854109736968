import { clsx } from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import type { ButtonScale, ButtonVariant } from "./Button";
import { VariantIcons } from "./VariantIcons";

export type AlertLevel = "info" | "success" | "warning" | "danger";

export interface AlertProps extends React.ComponentProps<"div"> {
  /**
   * Display a compact version of the alert.
   */
  compact?: boolean;

  /**
   * Center the content of the alert.
   */
  centered?: boolean;

  /**
   * The level of the alert.
   * @default "info"
   */
  level?: AlertLevel;
  /**
   * Display actions in the alert.
   */
  actions?: (renderProps: {
    buttonProps: {
      type: "button";
      appearance: "text";
      variant?: ButtonVariant;
      scale: ButtonScale;
    };
  }) => React.ReactNode;
}

const levels: Record<AlertLevel, string> = {
  info: /* tw */ `bg-primary-bg text-blue-on-strong`,
  success: /* tw */ `bg-success-bg text-success-on-strong`,
  warning: /* tw */ `bg-warning-bg text-warning-on-strong`,
  danger: /* tw */ `bg-danger-bg text-danger-on-strong`,
};

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      level = "info",
      compact,
      children,
      actions,
      className,
      centered,
      ...props
    },
    ref,
  ) => {
    const AlertIcon = VariantIcons[level];
    const ariaLive = level === "danger" ? "assertive" : "polite";
    return (
      <div
        ref={ref}
        role="alert"
        aria-live={ariaLive}
        className={clsx(
          className,
          "flex items-start rounded text-sm",
          compact ? "gap-2 p-2" : "gap-x-4 gap-y-2 rounded p-4",
          centered && "justify-center",
          levels[level],
        )}
        {...props}
      >
        <AlertIcon
          size={compact ? 16 : 20}
          className={clsx("shrink-0", compact && "mt-0.5")}
        />
        <div className={clsx(actions && "flex-1")}>{children}</div>
        {actions && (
          <div className="col-start-2 row-start-2 flex shrink-0 justify-end gap-2 empty:hidden">
            {actions({
              buttonProps: {
                type: "button",
                appearance: "text",
                variant: level === "info" ? "primary" : level,
                scale: compact ? "xs" : "sm",
              },
            })}
          </div>
        )}
      </div>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Alert.displayName = "Alert";
}
