export const BLOCK_STYLE_TYPE = "unordered-list-item";
export const allowedBlockTypes = [BLOCK_STYLE_TYPE];
export const allowedAttributes = { ul: [] };

/** @type {import('../types').htmlToBlock} */
export const htmlToBlock = ({ element }) => {
  if (element.tagName === "li") {
    return { type: BLOCK_STYLE_TYPE };
  }
  return null;
};
