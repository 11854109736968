import { gql } from "@apollo/client";
import { Link } from "swash/Link";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { ArrayField, StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

import { PayloadField, validateTags } from "../batch";

export { default as logo } from "./logo.jpg";

export const IntegrationConfigFragment = gql`
  fragment OneSignalIntegrationConfigFragment on OnesignalIntegrationConfig {
    appId
    restApiKey
    tags
    payload
  }
`;

function formatValues(config) {
  if (!config) {
    return {
      name: "",
      appId: "",
      restApiKey: "",
      tags: [],
      payload: "",
    };
  }

  return {
    name: config.name,
    appId: config.appId,
    restApiKey: config.restApiKey,
    tags: config.tags,
    payload: config.payload,
  };
}

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="name" label="Name" required />
      </FieldsetField>
      <div className="flex">
        <FieldsetField className="grow">
          <StringField name="appId" label="App ID" required />
        </FieldsetField>
        <FieldsetField className="grow">
          <StringField name="restApiKey" label="REST API Key" required />
        </FieldsetField>
      </div>
      <FieldsetField>
        <ArrayField
          name="tags"
          label="Tags"
          hint="Espacer les tags par une virgule, exemple : Web, Mobile"
          validate={validateTags}
        />
      </FieldsetField>
      <FieldsetField>
        <PayloadField
          label={
            <>
              <Link
                href="https://documentation.onesignal.com/reference/create-notification"
                target="_blank"
              >
                Create Notification API
              </Link>
              {" - "}
              Body Payload
            </>
          }
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({
  Fields,
  formatValues,
  parseValues: (v) => v,
});
