import { gql } from "@apollo/client";
import { Badge as SwashBadge } from "swash/Badge";
import { PanelBody } from "swash/Panel";
import { Tab, TabList, TabPanel } from "swash/Tab";

import { useSafeQuery } from "@/containers/Apollo";
import { createPanel } from "@/containers/PanelManager";
import {
  ArticleCommentsProvider,
  useArticleCollaborativePanelTabState,
} from "@/containers/article/ArticleCommentsContext";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";
import { useArticleCommentCRUDSubscriptions } from "@/containers/article/panels/comments/ArticleCommentSubscriptions";
import { ArticleComments } from "@/containers/article/panels/comments/ArticleComments";
import { ArticleNotes } from "@/containers/article/panels/comments/ArticleNotes";

const ArticleCommentCountBadge = ({ totalCount }) => {
  if (!totalCount) return null;
  return (
    <SwashBadge color="primary" scale="sm">
      {totalCount < 100 ? totalCount : "99+"}
    </SwashBadge>
  );
};

const ArticleCommentCountBadgeQuery = gql`
  query ArticleCommentCountBadge_article($articleId: Int!) {
    article(id: $articleId) {
      id
      # we only need open comments count
      notes(where: { resolved: false }) {
        totalCount
      }
      comments(where: { resolved: false }) {
        totalCount
      }
    }
  }
`;

export const ArticleCommentPanelBody = ({ articleId }) => {
  useArticleCommentCRUDSubscriptions(articleId);
  return (
    <PanelBody className="flex flex-col p-0 pt-4">
      <ArticleNotes />
    </PanelBody>
  );
};

export const ArticleCollaborationPanelBody = ({ articleId }) => {
  const tab = useArticleCollaborativePanelTabState();

  const { data } = useSafeQuery(ArticleCommentCountBadgeQuery, {
    variables: { articleId },
  });
  useArticleCommentCRUDSubscriptions(articleId);

  return (
    <>
      <div className="z-secondary-side-panel flex flex-col gap-4 bg-white px-4 py-2">
        <TabList state={tab} aria-label="Commentaires de l’article">
          <Tab state={tab} id="notes">
            Consignes{" "}
            <ArticleCommentCountBadge
              totalCount={data?.article.notes.totalCount}
            />
          </Tab>
          <Tab state={tab} id="comments">
            Commentaires{" "}
            <ArticleCommentCountBadge
              totalCount={data?.article.comments.totalCount}
            />
          </Tab>
        </TabList>
      </div>
      <PanelBody className="flex flex-col !p-0">
        <TabPanel
          state={tab}
          tabId="notes"
          className="flex h-full min-h-0 flex-col"
        >
          <ArticleNotes />
        </TabPanel>
        <TabPanel
          state={tab}
          tabId="comments"
          className="flex h-full min-h-0 flex-col"
        >
          <ArticleComments />
        </TabPanel>
      </PanelBody>
    </>
  );
};

export const ArticleCommentsPanel = createPanel({
  key: "article-notes",
  title: "Consignes de l'article",
  render: ({ articleId, onClose }) => (
    <ArticleCommentsProvider articleId={articleId}>
      <TrackPanel panelKey="article-notes" />
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <ArticleCommentPanelBody articleId={articleId} />
    </ArticleCommentsProvider>
  ),
});
