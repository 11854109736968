import styled from "@xstyled/styled-components";
import { Button } from "ariakit-deprecated/button";
import {
  Composite,
  CompositeItem,
  useCompositeState,
} from "ariakit-deprecated/composite";
import {
  Popover as AriakitPopover,
  PopoverDisclosure,
  usePopoverState as useAriakitPopoverState,
} from "ariakit-deprecated/popover";
import clsx from "clsx";
import * as React from "react";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import flattenChildren from "react-flatten-children";
import { mergeRefs } from "react-merge-refs";

import { Badge } from "./Badge";
import {
  IoAddCircleOutline,
  IoCheckmark,
  IoChevronDown,
  IoCloseCircleOutline,
} from "./Icon";
import { getItemClassName } from "./Menu";
import { PopoverCard } from "./Popover";
import { Tooltip } from "./Tooltip";
import { useNextFrameTicked } from "./motion/Animation";
import { useId } from "./utils/hooks";
import { useLiveRef } from "./utils/useLiveRef";
import { usePrevious } from "./utils/usePrevious";
import { useTypeSelect } from "./utils/useTypeSelect";

export { PopoverDisclosure };

export const usePopoverState = ({
  ignoreUpdate = false,
  getAnchorRect,
  gutter,
  ...props
}) => {
  const persistedAnchorRef = useRef(null);
  const state = useAriakitPopoverState({
    ...props,
    gutter: gutter ?? 4,
    getAnchorRect: ignoreUpdate
      ? (anchor) => {
          if (getAnchorRect) return getAnchorRect(anchor);
          if (persistedAnchorRef.current === null && anchor) {
            persistedAnchorRef.current = anchor.getBoundingClientRect();
          }
          return persistedAnchorRef.current;
        }
      : getAnchorRect,
  });
  useLayoutEffect(() => {
    if (state.open) {
      persistedAnchorRef.current = null;
    }
  }, [state.open]);
  state.ignoreUpdate = ignoreUpdate;
  return state;
};

const NestedContext = React.createContext(null);

export const Popover = forwardRef(
  (
    {
      lazy = true,
      modal,
      hideOnInteractOutside: initialHideOnInteractOutside,
      ...props
    },
    ref,
  ) => {
    const ticked = useNextFrameTicked({ skip: !lazy });

    const [hideOnInteractOutside, setHideOnInteractOutside] = React.useState(
      () => initialHideOnInteractOutside,
    );
    const initialHideOnInteractOutsideRef = useLiveRef(
      initialHideOnInteractOutside,
    );
    const nestedValue = React.useMemo(() => {
      return {
        disableHideOnInteractOutside: () => {
          setHideOnInteractOutside(false);
        },
        resetHideOnInteractOutside: () => {
          setHideOnInteractOutside(initialHideOnInteractOutsideRef.current);
        },
      };
    }, [initialHideOnInteractOutsideRef]);

    const parent = React.useContext(NestedContext);
    const parentRef = useLiveRef(parent);
    React.useEffect(() => {
      const { current: parent } = parentRef;
      if (!parent) return;
      if (props.state.open) {
        parent.disableHideOnInteractOutside();
      } else {
        parent.resetHideOnInteractOutside();
      }
    }, [props.state.open, parentRef]);
    if (lazy && !ticked) return null;
    return (
      <NestedContext.Provider value={nestedValue}>
        <AriakitPopover
          ref={ref}
          as={PopoverCard}
          focusable={false}
          modal={modal}
          backdrop={false}
          hideOnInteractOutside={hideOnInteractOutside}
          {...props}
        />
      </NestedContext.Provider>
    );
  },
);

function useSearchState() {
  const ref = useRef();
  const [value, setValue] = useState("");
  const clear = useCallback(() => {
    setValue("");
  }, []);
  return useMemo(() => ({ value, setValue, clear, ref }), [clear, value]);
}

export function useListboxState() {
  const id = useId();
  return useMemo(() => ({ id }), [id]);
}

export function useComboboxState() {
  const ref = useRef();
  return useMemo(() => ({ ref }), []);
}

export function usePlaceholderState() {
  const id = useId();
  return useMemo(() => ({ id }), [id]);
}

export function getLabelProps(props) {
  if (props["aria-label"]) {
    return { "aria-label": props["aria-label"] };
  }
  if (props["aria-labelledby"]) {
    return { "aria-labelledby": props["aria-labelledby"] };
  }
  if (props.state.label) {
    return { "aria-label": props.state.label };
  }
  if (props.state.labelledBy) {
    return { "aria-labelledby": props.state.labelledBy };
  }
  return {};
}

export function useSelectState({
  id,
  disabled,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  multi,
  searchable,
  clearable = true,
  placement = "bottom-start",
  gutter,
  isEqual = Object.is,
  "aria-invalid": ariaInvalid,
  invalid = ariaInvalid,
  "aria-labelledby": ariaLabelledBy,
  labelledBy = ariaLabelledBy,
  "aria-label": ariaLabel,
  label = ariaLabel,
  "data-field-control": fieldControl,
  initialValue = null,
  defaultActiveId,
  forceSelection = true,
  popoverIgnoreUpdate,
}) {
  const placeholder = usePlaceholderState();
  const listbox = useListboxState();
  const combobox = useComboboxState();
  const search = useSearchState();
  const popover = usePopoverState({
    placement,
    gutter,
    ignoreUpdate: popoverIgnoreUpdate,
  });
  const composite = useCompositeState({
    orientation: "vertical",
    defaultActiveId,
  });
  const refs = useLiveRef({
    onChange,
    onBlur,
    onFocus,
    isEqual,
    initialValue,
    multi,
    clearable,
    value,
    composite,
    popover,
    search,
    forceSelection,
  });
  const typeSelect = useTypeSelect(composite);

  const filled = multi
    ? value?.length > 0
    : Boolean(Array.isArray(value) ? value?.length : value);

  const change = useCallback(
    (value) => {
      const { onChange, clearable } = refs.current;
      if (!clearable && !value) return;
      onChange(value);
    },
    [refs],
  );

  const reset = useCallback(() => {
    const { onChange, initialValue } = refs.current;
    onChange(initialValue);
  }, [refs]);

  const clear = useCallback(() => {
    const { onChange, multi, search } = refs.current;
    onChange(multi ? [] : null);
    search.clear();
  }, [refs]);

  const select = useCallback(
    (selectedValue) => {
      const { onChange, isEqual, multi, clearable, value, search, popover } =
        refs.current;
      if (multi) {
        const getNextValues = () => {
          if (value?.some((v) => isEqual(v, selectedValue))) {
            return value.filter((value) => !isEqual(value, selectedValue));
          }
          return value ? [...value, selectedValue] : [selectedValue];
        };
        const nextValues = getNextValues();
        if (!clearable && nextValues.length === 0) return;
        onChange(nextValues);
        search.clear();
        return;
      }

      onChange(selectedValue);
      search.clear();
      popover.hide();
    },
    [refs],
  );

  const selectCurrent = useCallback(() => {
    const { popover, composite, forceSelection } = refs.current;
    popover.show();
    if (!popover.open) return;

    const firstId = forceSelection ? composite.items[0]?.id : null;
    const id = composite.activeId ?? firstId;
    if (!id) return;

    const element = document.getElementById(id);
    if (!element) return;

    const compositeEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      key: "Enter",
      keyCode: 13,
    });
    element.dispatchEvent(compositeEvent);
  }, [refs]);

  const isChecked = useCallback(
    (optionValue) => {
      const { multi, value, isEqual } = refs.current;
      return multi
        ? value?.some((v) => isEqual(optionValue, v))
        : isEqual(optionValue, value);
    },
    [refs],
  );

  const checkedOptionRef = useRef(null);

  const state = useMemo(() => {
    return {
      id,
      checkedOptionRef,
      disabled,
      name,
      labelledBy: labelledBy || placeholder.id,
      label,
      listbox,
      combobox,
      placeholder,
      popover,
      composite,
      search,
      searchable,
      multi,
      value,
      filled,
      select,
      isChecked,
      clear,
      clearable,
      onFocus,
      onBlur,
      invalid,
      fieldControl,
      typeSelect,
      selectCurrent,
      reset,
      change,
    };
  }, [
    clear,
    clearable,
    combobox,
    composite,
    disabled,
    fieldControl,
    filled,
    id,
    invalid,
    isChecked,
    label,
    labelledBy,
    listbox,
    multi,
    name,
    onBlur,
    onFocus,
    placeholder,
    popover,
    search,
    searchable,
    select,
    value,
    typeSelect,
    selectCurrent,
    reset,
    change,
  ]);

  return { state };
}

export const Select = styled.box`
  display: flex;
`;

export const SelectDisclosureLayout = styled.box`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`;

const InnerSelectDisclosure = styled.box`
  display: inline-flex;
  border: 1;
  border-color: layout-border;
  border-radius: 4px;
  cursor: pointer;
  transition: base;
  align-items: center;
  position: relative;
  padding: 5px 2;
  font-family: base;

  &[data-scale="base"] {
    font-size: sm;
    min-height: 32;
  }

  &[data-scale="sm"] {
    font-size: sm;
    min-height: 25;
  }

  &[data-scale="lg"] {
    font-size: base;
    min-height: 40;
  }

  &:hover {
    border-color: primary;
  }

  &:focus,
  &:focus-within {
    outline: none;
    border-color: primary;
  }

  &[aria-expanded="true"] {
    border-color: primary;
  }

  &[aria-invalid="true"] {
    border-color: danger-dark;

    &:focus {
      border-color: danger-dark;
    }

    &:hover {
      border-color: danger-dark;
    }
  }

  &[aria-disabled="true"] {
    opacity: 0.38;
  }

  &[data-borderless="true"] {
    border: 0;

    &:focus,
    &:focus-within {
      outline: none;
      border: 0;
      box-shadow: none;
    }
  }
`;

export const SelectDisclosure = forwardRef(
  (
    {
      state,
      children,
      onFocus,
      onBlur,
      onClick,
      scale = "lg",
      borderless,
      ...props
    },
    ref,
  ) => {
    const refs = useMemo(
      () => mergeRefs([ref, state.combobox.ref]),
      [ref, state.combobox.ref],
    );
    return (
      <PopoverDisclosure
        ref={refs}
        state={state.popover}
        role="combobox"
        disabled={state.disabled}
        aria-owns={state.listbox.id}
        aria-haspopup="listbox"
        aria-invalid={state.invalid}
        data-field-control={state.fieldControl}
        id={state.id}
        data-name={state.name}
        tabIndex={state.searchable ? -1 : 0}
        onFocus={(event) => {
          if (onFocus) onFocus(event);
          if (event.defaultPrevented) return;
          if (state.onFocus) state.onFocus(event);
        }}
        onBlur={(event) => {
          if (onBlur) onBlur(event);
          if (event.defaultPrevented) return;
          if (
            state.onBlur &&
            !event.currentTarget.contains(event.relatedTarget) &&
            state.popover.popoverRef.current !== event.relatedTarget &&
            !state.popover.popoverRef.current?.contains(event.relatedTarget)
          ) {
            state.onBlur(event);
          }
        }}
        onClick={(event) => {
          if (onClick) onClick(event);
          if (state.search?.value) {
            event.preventDefault();
          }
        }}
        {...getLabelProps({ state, ...props })}
        {...props}
      >
        {typeof children === "function"
          ? children
          : (disclosureProps) => (
              <InnerSelectDisclosure
                data-scale={scale}
                data-borderless={borderless}
                {...disclosureProps}
              >
                {children}
              </InnerSelectDisclosure>
            )}
      </PopoverDisclosure>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectDisclosure.displayName = "SelectDisclosure";
}

const InnerSelectCaret = memo(styled.box`
  flex: 0 0 auto;
  width: 16;
  height: 16;
  pointer-events: none;
  margin: 0 1;

  &[data-animated="true"][data-expanded="true"] {
    transform: rotate(-180deg);
  }
`);

export const SelectCaret = forwardRef(
  ({ state, animated = true, as = IoChevronDown, ...props }, ref) => {
    if (state.filled && state.clearable) return null;
    return (
      <InnerSelectCaret
        ref={ref}
        data-animated={animated}
        data-expanded={state.popover.open}
        as={as}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectCaret.displayName = "SelectCaret";
}

const InnerSelectClear = styled.div`
  flex: 0 0 auto;
  display: flex;
  margin-left: 2;
  transition: base;
  margin: 0 1;

  > svg {
    width: 16;
    height: 16;
  }

  &:hover,
  &:focus {
    color: primary;
  }
`;

export const SelectClearButton = forwardRef(
  ({ state, onClick, children, ...props }, ref) => {
    if (!state.filled || !state.clearable) return null;
    return (
      <Button
        ref={ref}
        aria-label="Vider"
        onClick={(event) => {
          if (onClick) onClick(event);
          event.stopPropagation();
          if (event.defaultPrevented) return;
          state.clear();
          if (state.search && state.search.ref.current) {
            state.search.ref.current.focus();
            return;
          }
          if (state.popover.open) {
            state.popover.popoverRef.current.focus();
          } else {
            state.combobox.ref.current.focus();
          }
        }}
        {...props}
      >
        {(buttonProps) => (
          <InnerSelectClear {...buttonProps}>
            {children || <IoCloseCircleOutline />}
          </InnerSelectClear>
        )}
      </Button>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectClearButton.displayName = "SelectClearButton";
}

export const SelectClearSearchButton = forwardRef(
  ({ state, onClick, children, ...props }, ref) => {
    if (!state.search?.value) return null;
    return (
      <Button
        ref={ref}
        aria-label="Vider"
        onClick={(event) => {
          if (onClick) onClick(event);
          event.stopPropagation();
          if (event.defaultPrevented) return;
          state.search.clear();
          if (state.search && state.search.ref.current) {
            state.search.ref.current.focus();
            return;
          }
          if (state.popover.open) {
            state.popover.popoverRef.current.focus();
          } else {
            state.combobox.ref.current.focus();
          }
        }}
        {...props}
      >
        {(buttonProps) => (
          <InnerSelectClear {...buttonProps}>
            {children || <IoCloseCircleOutline />}
          </InnerSelectClear>
        )}
      </Button>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectClearSearchButton.displayName = "SelectClearSearchButton";
}

const InnerSelectPlaceholder = styled.div`
  flex: 1 0 auto;
  margin: 0 1;
  user-select: none;
  line-height: 1;
`;

export const SelectPlaceholder = forwardRef(
  ({ state, visible, className, ...props }, ref) => {
    return (
      <InnerSelectPlaceholder
        ref={ref}
        id={state.placeholder.id}
        hidden={state.filled && visible !== true}
        className={clsx(className, "text-dusk-on-light/70")}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectPlaceholder.displayName = "SelectPlaceholder";
}

const InnerSelectValue = styled.box`
  display: inline-flex;
  white-space: nowrap;
  margin: 0 1;
  user-select: none;
  align-items: center;
  flex: 1 1 auto;
  line-height: 1;

  &[data-searchable] {
    flex: 0 1 auto;
    white-space: normal;
  }
`;

export const SelectValue = forwardRef(
  ({ state, "data-test-hidden": dataTestHidden, children, ...props }, ref) => {
    if (!state.filled) return null;
    function render() {
      if (typeof children === "function") return children(state);
      if (children) return children;
      return state.multi ? state.value.join(", ") : state.value;
    }
    return (
      <InnerSelectValue
        data-test-hidden={dataTestHidden}
        data-searchable={state.searchable ? "" : undefined}
        ref={ref}
        {...props}
      >
        {render()}
      </InnerSelectValue>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectValue.displayName = "SelectValue";
}

export const SelectBadgeValue = forwardRef(
  (
    {
      children,
      state,
      renderCount = ({ state, value }) =>
        state.multi
          ? Object.keys(value)
              .map((key) => value[key])
              .filter((v) => v === 0 || !!v).length
          : 1,
      scale,
      ...props
    },
    ref,
  ) => {
    function render() {
      if (typeof children === "function") return children(state);
      return state.multi ? state.value.join(", ") : state.value;
    }
    return (
      <SelectValue ref={ref} state={state} flex="0 0 auto" {...props}>
        {({ value }) => (
          <Tooltip tooltip={render()}>
            <Badge purpose="discrete">{renderCount({ state, value })}</Badge>
          </Tooltip>
        )}
      </SelectValue>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectBadgeValue.displayName = "SelectBadgeValue";
}

const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1 0 auto;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin: 0 1;
  user-select: none;
  line-height: 1;
  min-width: 40;
  overflow: hidden;

  > span {
    color: transparent;
  }
`;

const SelectSearchInput = memo(styled.input`
  appearance: none;
  background-color: transparent;
  font-family: base;
  border: 0;
  padding: 0;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;

  &::placeholder {
    color: placeholder;
  }

  &[data-borderless="true"] {
    &:focus,
    &:focus-within {
      outline: none;
      border-color: primary;
    }
  }
`);

export const SelectSearch = forwardRef(
  ({ state, onKeyDown, onChange, borderless, children, ...props }, ref) => {
    const {
      search: { setValue },
    } = state;
    const onKeyDownRef = useLiveRef(onKeyDown);
    const stateRef = useLiveRef(state);
    const handleKeyDown = useCallback(
      (event) => {
        const { current: state } = stateRef;
        if (onKeyDownRef.current) onKeyDownRef.current(event);
        if (event.defaultPrevented) return;
        // eslint-disable-next-line default-case
        switch (event.key) {
          case "ArrowUp": {
            event.preventDefault();
            event.stopPropagation();
            state.composite.move(state.composite.previous());
            setTimeout(() => {
              state.popover.show();
            });
            return;
          }
          case "ArrowDown": {
            event.preventDefault();
            event.stopPropagation();
            state.composite.move(state.composite.next());
            setTimeout(() => {
              state.popover.show();
            });
            return;
          }
          case " ":
          case "Shift":
          case "Meta":
          case "Alt":
          case "Control":
            return;
          case "Tab": {
            setTimeout(() => {
              state.popover.hide();
            });
            return;
          }
          case "Escape": {
            event.stopPropagation();
            state.popover.hide();
            return;
          }
          case "Enter": {
            event.preventDefault();
            event.stopPropagation();
            state.selectCurrent();
            return;
          }
          default:
            state.popover.show();
        }
      },
      [stateRef, onKeyDownRef],
    );

    const onChangeRef = useLiveRef(onChange);
    const handleChange = useCallback(
      (event) => {
        if (onChangeRef.current) onChangeRef.current(event);
        if (event.defaultPrevented) return;
        setValue(event.currentTarget.value);
      },
      [onChangeRef, setValue],
    );

    const { open } = state.popover;
    const previousOpen = usePrevious(open);

    useEffect(() => {
      if (previousOpen && !open) {
        setValue("");
      }
    }, [previousOpen, open, setValue]);

    const refs = useMemo(
      () => mergeRefs([ref, state.search.ref]),
      [ref, state.search.ref],
    );

    if (typeof children === "function") {
      return children({
        ref: refs,
        "aria-autocomplete": "list",
        "aria-controls": state.listbox.id,
        value: state.search.value,
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        "data-borderless": borderless,
        ...props,
      });
    }
    return (
      <SearchInputWrapper>
        <span aria-hidden="true">{props.placeholder}</span>
        <SelectSearchInput
          ref={refs}
          aria-autocomplete="list"
          aria-controls={state.listbox.id}
          value={state.search.value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          data-borderless={borderless}
          {...props}
        />
      </SearchInputWrapper>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectSearch.displayName = "SelectSearch";
}

export const SelectMenuFooter = ({ className, ...props }) => (
  <div
    className={clsx(
      className,
      "w-full border-t border-t-grey-border-light bg-grey-bg-light p-4",
    )}
    {...props}
  />
);

export const SelectMenu = forwardRef(
  (
    {
      state,
      children,
      onFocus,
      portal,
      lazy,
      footer,
      hideOnInteractOutside,
      tabIndex = -1,
      ...props
    },
    ref,
  ) => {
    const bodyRef = useRef();
    // Lazy set body ref to be compatible with SSR
    useEffect(() => {
      bodyRef.current = document.body;
    }, []);
    const hasChildren = flattenChildren(children).length > 0;

    return (
      <Composite
        ref={ref}
        state={state.composite}
        {...state.typeSelect}
        role="listbox"
        tabIndex={tabIndex}
        onFocus={(event) => {
          if (onFocus) onFocus(event);
          if (event.defaultPrevented) return;
          if (state.search && state.search.ref.current) {
            state.search.ref.current.focus();
          }
        }}
        id={state.listbox.id}
        {...getLabelProps({ state, ...props })}
        {...props}
      >
        {({ ref, ...compositeProps }) => (
          <div ref={ref}>
            <Popover
              state={state.popover}
              modal
              portal={portal}
              lazy={lazy}
              hideOnInteractOutside={hideOnInteractOutside}
              finalFocusRef={state.popover.ignoreUpdate ? bodyRef : undefined}
              initialFocusRef={state.checkedOptionRef}
              className={clsx("overflow-hidden", !hasChildren && "invisible")}
              {...compositeProps}
            >
              {hasChildren && (
                <div className="max-h-60 overflow-auto p-1">{children}</div>
              )}
              {footer}
            </Popover>
          </div>
        )}
      </Composite>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectMenu.displayName = "SelectMenu";
}

const SelectMarker = memo(({ multi, checked }) => {
  if (multi) {
    return (
      <div
        className={clsx(
          "flex shrink-0 items-center justify-center rounded-sm text-white",
          checked
            ? "bg-primary-bg-strong"
            : "bg-white ring-1 ring-grey-border-light",
        )}
        style={{ width: 14, height: 14 }}
      >
        <IoCheckmark size={10} />
      </div>
    );
  }
  return <IoCheckmark className={clsx("shrink-0", !checked && "invisible")} />;
});

if (process.env["NODE_ENV"] !== "production") {
  SelectMarker.displayName = "SelectMarker";
}

export const SelectOption = forwardRef(
  (
    {
      state,
      onClick,
      onMouseOver,
      value,
      children,
      disabled,
      scale,
      variant,
      className,
      ...props
    },
    ref,
  ) => {
    const ownRef = useRef();
    const refs = useMemo(() => mergeRefs([ref, ownRef]), [ref, ownRef]);
    const id = useId();
    const itemClassName = getItemClassName({ variant, className });
    const checked = state.isChecked(value);

    function handleClick(event) {
      if (onClick) onClick(event);
      if (event.defaultPrevented) return;
      state.select(value);
    }

    function handleMouseOver(event) {
      if (onMouseOver) onMouseOver(event);
      if (event.defaultPrevented) return;
      state.composite.move(id);
    }

    // Continuously update the checked option ref
    useEffect(() => {
      if (!state.multi && checked) {
        state.checkedOptionRef.current = ownRef.current;
      }
    });

    return (
      <CompositeItem
        ref={refs}
        id={id}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        role="option"
        aria-checked={checked}
        data-searchable={state.searchable}
        disabled={state.disabled || disabled}
        state={state.composite}
        className={itemClassName}
        {...props}
      >
        {(compositeItemProps) => {
          return (
            <div {...compositeItemProps}>
              {state.multi || state.value ? (
                <SelectMarker multi={state.multi} checked={checked} />
              ) : null}
              {children}
            </div>
          );
        }}
      </CompositeItem>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectOption.displayName = "SelectOption";
}

export const SelectAdd = forwardRef(
  (
    {
      state,
      onMouseOver,
      value,
      children,
      selectOnHover = true,
      disabled,
      scale,
      className,
      ...props
    },
    ref,
  ) => {
    const compositeIdRef = useRef();
    const itemClassName = getItemClassName({ className });

    function handleMouseOver(event) {
      if (onMouseOver) onMouseOver(event);
      if (event.defaultPrevented) return;
      if (selectOnHover) {
        state.composite.move(compositeIdRef.current);
      }
    }

    return (
      <CompositeItem
        ref={ref}
        onMouseOver={handleMouseOver}
        role="option"
        disabled={state.disabled || disabled}
        state={state.composite}
        className={itemClassName}
        {...props}
      >
        {(compositeItemProps) => {
          compositeIdRef.current = compositeItemProps.id;
          return (
            <div {...compositeItemProps}>
              <IoAddCircleOutline /> {children}
            </div>
          );
        }}
      </CompositeItem>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  SelectAdd.displayName = "SelectAdd";
}
