import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment GenaiIntegrationConfigFragment on GenaiIntegrationConfig {
    clientId
    clientSecret
    baseUrl
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="baseUrl" label="Base URL" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="clientId" label="Client ID" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="clientSecret" label="Secret" required />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
