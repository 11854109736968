import { forwardRef } from "react";
import { Switch } from "swash/controls/Switch";

import { useCheckboxField } from "./CheckboxField";
import { FieldControl } from "./FieldControl";
import { FieldState } from "./FieldState";

export const useSwitchField = useCheckboxField;

type SwitchFieldProps = {
  state: FieldState;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const SwitchField = forwardRef<HTMLDivElement, SwitchFieldProps>(
  (props, ref) => {
    return <FieldControl ref={ref} as={Switch} {...props} />;
  },
);
if (process.env["NODE_ENV"] !== "production") {
  SwitchField.displayName = "SwitchField";
}
