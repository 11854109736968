import { memo } from "react";
import { Tooltip } from "swash/Tooltip";

export type EmbargoTimeProps = {
  time: string;
};

const title = "Embargo";

export const EmbargoTime = memo<EmbargoTimeProps>((props) => {
  return (
    <Tooltip tooltip={title}>
      <strong aria-label={title} className="underline">
        {props.time}
      </strong>
    </Tooltip>
  );
});
