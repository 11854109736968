export const BLOCK_STYLE_TYPE = "ordered-list-item";

export const allowedBlockTypes = [BLOCK_STYLE_TYPE];
export const allowedAttributes = { ol: [] };

/** @type {import('../types').htmlToBlock} */
export const htmlToBlock = ({ element, ancestors }) => {
  if (element.tagName === "li") {
    const firstListAncestor = ancestors
      .reverse()
      .find(
        (node) =>
          node.type === "element" &&
          (node.tagName === "ol" || node.tagName === "ul"),
      );

    if (firstListAncestor?.tagName === "ol") {
      return { type: BLOCK_STYLE_TYPE };
    }
  }
  return null;
};
