import { EditorState } from "draft-js-es";
import EditorBidiService from "draft-js/lib/EditorBidiService";

import { checkIsValidSelection } from "../utils/Selection";
import { selectBlock } from "./selectBlock";

/**
 * Set editor content.
 * @param {import('draft-js').EditorState} editorState
 * @param {import('draft-js').ContentState} contentState
 */
export function setContent(editorState, contentState) {
  const currentContent = editorState.getCurrentContent();

  if (currentContent === contentState) {
    return editorState;
  }

  const selection = editorState.getSelection();

  const withContentState = EditorState.set(editorState, {
    currentContent: contentState,
    directionMap: EditorBidiService.getDirectionMap(
      contentState,
      editorState.getDirectionMap(),
    ),
  });

  if (!checkIsValidSelection(contentState, selection)) {
    return selectBlock(
      withContentState,
      contentState.getFirstBlock().getKey(),
      false,
    );
  }

  return withContentState;
}
