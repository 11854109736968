import { useFormState } from "react-final-form";

import { Prompt } from "@/components/Prompt";
import { useFormAutoSubmitState } from "@/components/forms/FormAutoSubmit";

const DEFAULT_PROMPT_MESSAGE =
  "Des données ne sont pas enregistrées. Êtes-vous sûr de vouloir quitter la page ?";

/**
 *
 * @param {object} [params]
 * @param {string} [params.message]
 * @param {boolean} [params.when]
 */
export function FormPrompt({ message, when }) {
  // Redirection could occur during the submitting, if it is the case
  // then we assume it is a choice of the user
  const { dirty, submitting } = useFormState({
    subscription: {
      dirty: true,
      submitting: true,
    },
  });
  const mustSubmit = dirty || submitting;
  return (
    <Prompt
      when={when ?? mustSubmit}
      message={message ?? DEFAULT_PROMPT_MESSAGE}
      shouldBlock
    />
  );
}

export function FormSubmittingPrompt({
  message = DEFAULT_PROMPT_MESSAGE,
  when,
}) {
  const { mustSubmit, submitting, valid } = useFormAutoSubmitState();

  return (
    <Prompt
      when={when ?? (mustSubmit || submitting || !valid)}
      shouldBlock={!valid}
      message={message}
    />
  );
}
