/* eslint-disable no-param-reassign */
import { removeInlineStyle } from "../modifiers/removeInlineStyle";
import { smartToggleInlineStyle } from "../modifiers/smartToggleInlineStyle";
import { INLINE_STYLE } from "./superscript-convert";

export * from "./superscript-convert";

const OPPOSITE_INLINE_STYLE = "SUBSCRIPT";

export const name = "superscript";
export const command = "superscript";

export const handleKeyCommand = ({ setEditorState, editorState }, cmd) => {
  if (cmd === command) {
    setEditorState(
      smartToggleInlineStyle(
        removeInlineStyle(editorState, OPPOSITE_INLINE_STYLE),
        INLINE_STYLE,
      ),
    );
    return true;
  }
  return undefined;
};
