import { gql } from "@apollo/client";

import {
  ArticleLastUserUpdate,
  ArticleLastUserUpdateProps,
} from "./ArticleLastUserUpdate";
import { ArticleLocker, ArticleLockerProps } from "./ArticleLocker";

export type ArticleModificationsProps = {
  article: {
    locker: { id: string } | null;
  } & ArticleLastUserUpdateProps["article"] &
    ArticleLockerProps["article"];
};

export const ArticleModifications = (props: ArticleModificationsProps) => {
  if (!props.article.locker) {
    return <ArticleLastUserUpdate article={props.article} />;
  }

  return <ArticleLocker article={props.article} size={20} />;
};

ArticleModifications.fragments = {
  article: gql`
    fragment ArticleModifications_article on Article {
      ...ArticleLastUserUpdate_article
      ...ArticleLocker_article
      locker {
        id
      }
    }

    ${ArticleLastUserUpdate.fragments.article}
    ${ArticleLocker.fragments.article}
  `,
};
