import { DocumentNode, gql } from "@apollo/client";
import { memo } from "react";
import {
  IoAtCircle,
  IoCheckmarkCircle,
  IoPersonCircleOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { Image, ImageFixedFragment } from "@/components/Image";
import type { AuthorAvatar_authorFragment } from "@/gql-types";

const Avatar: React.FC<{
  author: AuthorAvatar_authorFragment;
  size: number;
}> = ({ author, size }) => {
  if (author.image) {
    return (
      <Image
        {...author.image.fixed}
        width={size}
        height={size}
        className="rounded-full"
      />
    );
  }
  return <IoPersonCircleOutline size={size} />;
};

const firstUpper = (s: string) => s[0]!.toUpperCase() + s.slice(1);

type AuthorAvatarProps = {
  author: AuthorAvatar_authorFragment;
};
type AuthorAvatarFragments = {
  author: DocumentNode;
};

export const AuthorAvatar = memo<AuthorAvatarProps>(({ author }) => {
  const tooltip = [
    author.verified ? "signataire vérifié" : null,
    author.user ? "lié à un compte utilisateur" : null,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <Tooltip tooltip={tooltip ? firstUpper(tooltip) : null}>
      <div className="relative flex items-center justify-center">
        <Avatar author={author} size={20} />
        {author.verified && (
          <span className="absolute right-0 top-0 text-primary-on">
            <span className="absolute inset-0 rounded bg-white" />
            <IoCheckmarkCircle size={10} className="relative" />
          </span>
        )}
        {author.user && (
          <span className="absolute bottom-0 right-0 text-primary-on">
            <span className="absolute inset-0 rounded bg-white" />
            <IoAtCircle size={10} className="relative" />
          </span>
        )}
      </div>
    </Tooltip>
  );
}) as React.NamedExoticComponent<AuthorAvatarProps> & {
  fragments: AuthorAvatarFragments;
};
if (process.env["NODE_ENV"] !== "production") {
  AuthorAvatar.displayName = "AuthorAvatar";
}

AuthorAvatar.fragments = {
  author: gql`
    fragment AuthorAvatar_author on Author {
      verified
      user {
        id
      }
      image {
        id
        fixed(width: 24, height: 24) {
          ...ImageFixedFragment
        }
      }
    }

    ${ImageFixedFragment}
  `,
};
