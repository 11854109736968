import { Link } from "swash/Link";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelHeader } from "swash/Panel";
import { Dialog } from "swash/v2/Dialog";

import { CopyButton } from "@/components/CopyButton";
import { createRemoteDialog } from "@/components/RemoteDialog";
import { useSafeQuery } from "@/containers/Apollo";
import {
  SnippetEditForm,
  SnippetQuery,
} from "@/containers/snippet/SnippetEditForm";

export const SnippetEditDialog = ({ snippetId, editor, store }) => {
  const { data } = useSafeQuery(SnippetQuery, {
    variables: { id: snippetId },
    fetchPolicy: "network-only",
    skip: !snippetId,
  });
  const snippet = data?.snippet ?? null;
  const title = snippet?.title ?? "Snippet";

  const open = store.useState("open");

  if (!open) return null;

  return (
    <Dialog
      store={store}
      style={{ width: "calc(100vw - 30px)", height: "calc(100vh - 60px)" }}
      modal={false}
      portal
    >
      <PanelHeader
        title={
          <Link inherit href={snippet?.siriusUrl ?? "#"} target="_blank">
            {title}
          </Link>
        }
        actions={
          <CopyButton
            value={snippet?.siriusUrl ?? ""}
            title="Copier l’URL du snippet"
            scale="xs"
          />
        }
        onClose={store.hide}
      />
      <PanelBody>
        {snippet ? (
          <SnippetEditForm snippet={snippet} editor={editor} />
        ) : (
          <PageLoader className="h-full" />
        )}
      </PanelBody>
    </Dialog>
  );
};

export const {
  RemoteDialogProvider: RemoteEditSnippetDialogProvider,
  RemoteDialogDisclosure: RemoteEditSnippetDialogDisclosure,
  useRemoteDialogContext: useEditSnippetRemoteDialogContext,
} = createRemoteDialog(SnippetEditDialog);
