import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";

import {
  TextareaField,
  useTextareaField,
} from "@/components/fields/TextareaField";
import { Form } from "@/components/forms/Form";
import { FormAction, FormActions } from "@/components/forms/FormActions";
import { FormError } from "@/components/forms/FormError";
import {
  FormFooter,
  FormFooterActions,
  FormFooterSide,
} from "@/components/forms/FormFooter";
import { FormReset } from "@/components/forms/FormReset";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { GenericErrorBlock } from "@/containers/GenericErrorPage";
import { useReadOnly } from "@/containers/ReadOnly";
import { EditorNodeCard } from "@/containers/editor/nodes/NodeLayout";

import { ContributionNodeFragment } from "./ContributionNodeFragment";

const ContributionNodeUpdateMutation = gql`
  mutation ContributionNodeUpdateMutation($input: UpdateContributionInput!) {
    updateContribution(input: $input) {
      ...ContributionNode_contribution
    }
  }
  ${ContributionNodeFragment}
`;

function ContributionTextareaField({ disabled }) {
  const field = useTextareaField("text", { required: true });
  const textareaRef = useRef();

  useEffect(() => {
    // couldn't get focus without... perhaps a Chrome bug
    const id = setTimeout(() => textareaRef.current.focus());
    return () => clearTimeout(id);
  }, []);

  return (
    <TextareaField
      ref={textareaRef}
      {...field}
      w={1}
      rows={5}
      resize="vertical"
      scale="lg"
      disabled={disabled}
    />
  );
}

function ContributionNodeForm({ contribution, onClose }) {
  const readOnly = useReadOnly();
  const [updateContribution, { error, loading }] = useMutation(
    ContributionNodeUpdateMutation,
  );
  if (error) throw error;

  return (
    <Form
      as="div"
      initialValues={contribution}
      onSubmit={async (values, form) => {
        const formState = form.getState();
        if (formState.dirty) {
          await updateContribution({
            variables: {
              input: {
                id: values.id,
                text: values.text,
              },
            },
          });
        }
        onClose();
      }}
    >
      <div
        onBlur={(event) => {
          if (event.currentTarget.contains(event.relatedTarget)) return;
          onClose();
        }}
      >
        <ContributionTextareaField disabled={readOnly} />
        <FormFooter mt={2} justifyContent="flex-end">
          <FormFooterSide>
            <FormError />
          </FormFooterSide>
          <FormFooterActions>
            <FormActions>
              <FormAction>
                <FormReset
                  initialValues={contribution}
                  disabled={readOnly}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClose();
                  }}
                >
                  Annuler
                </FormReset>
              </FormAction>
              <FormAction>
                <FormSubmit disabledIfPristine disabled={readOnly || loading}>
                  Valider
                </FormSubmit>
              </FormAction>
            </FormActions>
          </FormFooterActions>
        </FormFooter>
      </div>
    </Form>
  );
}

function ContributorName({ contribution }) {
  if (!contribution.authorName) return null;
  return (
    <div className="mb-2 font-semibold">De {contribution.authorName} :</div>
  );
}

export function ContributionNodeDisplay({ contribution, ...props }) {
  return (
    <EditorNodeCard {...props}>
      <div className="p-4">
        <ContributorName contribution={contribution} />
        <div className="whitespace-pre-line">{contribution.text}</div>
      </div>
    </EditorNodeCard>
  );
}

export function ContributionNode({ contribution, setEditing, editing }) {
  return editing ? (
    <EditorNodeCard>
      <div className="p-4">
        <ContributorName contribution={contribution} />
        <ErrorBoundary component={GenericErrorBlock}>
          <ContributionNodeForm
            contribution={contribution}
            onClose={() => setEditing(false)}
          />
        </ErrorBoundary>
      </div>
    </EditorNodeCard>
  ) : (
    <ContributionNodeDisplay
      contribution={contribution}
      onClick={() => setEditing(true)}
    />
  );
}
