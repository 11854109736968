import { UsedInList } from "./UsedInList";

export const name = "Sources";

const columns = [
  {
    id: "label",
    Header() {
      return "Libellé";
    },
    Value({ node, EditLink }) {
      return <EditLink slug="sources">{node.label}</EditLink>;
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
