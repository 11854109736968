import { setBlockTypeAndStyle } from "../modifiers/setBlockTypeAndStyle";
import { BLOCK_STYLE_TYPE } from "./header-two-convert";

export * from "./header-two-convert";

export const name = "header-two";
export const command = "header-two";

export const handleKeyCommand = ({ setEditorState }, cmd) => {
  if (cmd === command) {
    setEditorState((editorState) =>
      setBlockTypeAndStyle(editorState, BLOCK_STYLE_TYPE),
    );
    return true;
  }
  return undefined;
};
