import { Button as AriakitButton } from "ariakit/button";
import { LinkTilted, LinkTiltedBroken } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

const CorrelateButton = ({ disabled, onClick, resourceLabelName }) => {
  const label = `Recorréler avec le ${resourceLabelName} de l’article`;

  return (
    <Tooltip tooltip={label}>
      <AriakitButton
        as="div"
        className="group flex cursor-pointer text-base text-warning-on hover:text-primary-on"
        aria-label={label}
        onClick={onClick}
        disabled={disabled}
      >
        <LinkTiltedBroken className="group-hover:hidden" />
        <LinkTilted className="hidden group-hover:block" />
      </AriakitButton>
    </Tooltip>
  );
};

export const CorrelateLabel = ({
  children,
  hidden,
  resourceLabelName,
  onClick,
  disabled,
}) => {
  if (hidden) return children;
  return (
    <div className="flex items-center gap-2">
      <CorrelateButton
        resourceLabelName={resourceLabelName}
        onClick={onClick}
        disabled={disabled}
      />
      {children}
    </div>
  );
};
