import { gql } from "@apollo/client";
import { useEffect, useRef } from "react";
import {
  EmptyState,
  EmptyStatePicture,
  EmptyStateText,
  EmptyStateTitle,
} from "swash/EmptyState";
import { PageLoader } from "swash/Loader";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useDebouncedCallback } from "use-debounce";

// @ts-expect-error ts does not support this import
import speechBalloon from "@/assets/imgs/emojis/speech_balloon.png";
import {
  InfiniteScrollLoader,
  InfiniteScrollMarker,
  useInfiniteScrollState,
} from "@/components/InfiniteScroll";
import {
  useArticleCommentPanel,
  useArticleComments,
} from "@/containers/article/ArticleCommentsContext";
import { ArticleComment } from "@/containers/article/panels/comments/ArticleComment";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";

const wordings = {
  notes: {
    label: "Consignes",
    emptyTitle: "Aucune consigne pour cet article.",
    emptyText: "Les consignes de cet article apparaîtront ici",
    resolved: {
      label: "Consignes résolues",
      emptyTitle: "Aucune consigne résolue pour l’instant dans cet article.",
      emptyText: "Les consignes résolues de cet article apparaîtront ici",
    },
  },
  text: {
    label: "Commentaires",
    emptyTitle: "Aucun commentaire ouvert pour l’instant dans cet article.",
    emptyText: "Les commentaires ouverts de cet article apparaîtront ici",
    resolved: {
      label: "Commentaires résolus",
      emptyTitle: "Aucun commentaire résolu pour l’instant dans cet article.",
      emptyText: "Les commentaires résolus de cet article apparaîtront ici",
    },
  },
};

interface ArticleCommentListProps {
  comments: any[];
  setDeletingComment: (comment: any) => void;
  article: any;
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
}

export const ArticleCommentList = ({
  comments,
  setDeletingComment,
  article,
  hasMore,
  loadMore,
  loading,
}: ArticleCommentListProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const [selectedId] = useArticleCommentPanel();
  const { selectedCommentsState, preventPanelScroll } = useArticleComments();
  const { selectedCommentIds } = selectedCommentsState;
  const scope = useCommentScope();
  const { label, emptyTitle, emptyText } =
    selectedId === "resolved" ? wordings[scope].resolved : wordings[scope];

  const infiniteScroll = useInfiniteScrollState({
    hasMore,
    loadMore,
    loading,
  });

  const commentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollBehavior = useRef<"auto" | "smooth">("auto");

  // prevent too many scroll to comment which can cause performance issue
  const debounceScrollToComment = useDebouncedCallback(
    (selectedCommentIndex) => {
      commentRefs.current[selectedCommentIndex]?.scrollIntoView({
        behavior: scrollBehavior.current,
        block: "center",
      });
    },
    1000,
    { leading: true, trailing: false },
  );

  const refs = useLiveRef({
    hasMore,
    loadMore,
    preventPanelScroll,
    debounceScrollToComment,
  });
  useEffect(() => {
    const { hasMore, loadMore, preventPanelScroll, debounceScrollToComment } =
      refs.current;
    if (!comments || preventPanelScroll || !selectedCommentIds.length) {
      return;
    }
    // search first included comment then scroll to it
    const selectedCommentIndex = comments.findIndex((comment) =>
      selectedCommentIds.includes(comment.id),
    );
    if (selectedCommentIndex !== -1) {
      debounceScrollToComment(selectedCommentIndex);
      if (scrollBehavior.current === "auto") {
        scrollBehavior.current = "smooth";
      }
    } else if (hasMore && !loading) {
      loadMore();
    }
  }, [comments, refs, selectedCommentIds, loading]);

  if (!comments) return <PageLoader />;
  if (!comments.length) {
    return (
      <EmptyState className="flex-1 px-12">
        <EmptyStatePicture src={speechBalloon} />
        <EmptyStateTitle>{emptyTitle}</EmptyStateTitle>
        <EmptyStateText>{emptyText}</EmptyStateText>
      </EmptyState>
    );
  }
  return (
    <div
      role="list"
      aria-label={label}
      className="scrollbar-light flex h-full scroll-py-2 flex-col gap-4 overflow-y-auto px-4 pb-4"
      key={selectedId}
      ref={listRef}
    >
      {comments.map((comment, i) => (
        <ArticleComment
          ref={(el) => (commentRefs.current[i] = el)}
          key={comment.id}
          // @ts-expect-error use a js component
          article={article}
          comment={comment}
          setDeletingComment={setDeletingComment}
        />
      ))}
      <InfiniteScrollMarker {...infiniteScroll} />
      <InfiniteScrollLoader {...infiniteScroll} />
    </div>
  );
};

ArticleCommentList.fragments = {
  commentThreadConnection: gql`
    fragment ArticleCommentList_commentThreadConnection on CommentThreadConnection {
      pageInfo {
        hasMore
      }
      nodes {
        id
      }
      totalCount
    }
  `,
};
