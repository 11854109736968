import { gql } from "@apollo/client";
import { memo } from "react";

import { SearchQuery } from "@/containers/search/SearchForm";

import { ImageList, ImageListImageFragment } from "./ImageList";

export const query = gql`
  query ImageListQuery_images(
    $offset: Int
    $limit: Int
    $where: ImageWhere
    $isGrid: Boolean! = false
    $isList: Boolean! = false
  ) {
    connection: images(offset: $offset, limit: $limit, where: $where) {
      nodes {
        id
        ...ImageListImageFragment
      }
      pageInfo {
        hasMore
      }
      totalCount
    }
  }

  ${ImageListImageFragment}
`;

export const ImageListQuery = memo(({ state, ...props }) => {
  return (
    <SearchQuery state={state} query={query}>
      {({ data, loadMore, loading, refetch }) => (
        <ImageList
          data={data}
          loadMore={loadMore}
          loading={loading}
          refetch={refetch}
          {...props}
        />
      )}
    </SearchQuery>
  );
});
