import { memo } from "react";

import { OrderedList } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive, handleBinding } from "../utils";
import { BLOCK_STYLE_TYPE, command } from "./ordered-list-item";

const SHORTCUT = ["⌘", "o"];

export const shortcuts = [
  {
    description: "Liste ordonnée",
    shortcut: SHORTCUT,
    category: "Blocs",
  },
];

export const name = "ordered-list-item-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const OrderedListItemToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      command={command}
      active={active}
      label="Liste ordonnée"
      shortcut={SHORTCUT}
    >
      <OrderedList />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <OrderedListItemToolbarButton
      active={checkIsBlockActive(editorState, BLOCK_STYLE_TYPE)}
    />
  );
};

BlockControls.group = "block-style";
