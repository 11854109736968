import clsx from "clsx";
import React, { forwardRef } from "react";

import checkMark from "./assets/check-mark.svg";
import minus from "./assets/minus.svg";

const resolveSrc = (urlOrObj: string | { src: string }) => {
  if (typeof urlOrObj === "string") return urlOrObj;
  return urlOrObj.src;
};

export type CheckboxScale = "sm" | "md";

export type CheckboxProps = React.ComponentProps<"input"> & {
  scale?: CheckboxScale;
};

const scales: Record<CheckboxScale, string> = {
  sm: "h-3 w-3",
  md: "h-4 w-4",
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ scale = "md", className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={clsx(
          className,
          "m-0 appearance-none",
          "inline-flex justify-around rounded-sm border border-grey-border bg-white p-0 align-middle transition",
          "focus:border-primary-border focus:outline-none",
          "enabled:hover:border-primary-border",
          "enabled:data-[hovered]:border-primary-border",
          "disabled:opacity-disabled",
          "before:block before:h-2 before:w-2 before:self-center before:bg-[image:var(--checkmark-img)] before:bg-[length:100%] before:bg-center before:opacity-0 before:transition before:will-change-transform",
          "indeterminate:border-grey-bg-strong indeterminate:bg-grey-bg-strong indeterminate:before:bg-[image:var(--minus-img)] indeterminate:before:opacity-100",
          "checked:border-primary-bg-strong checked:bg-primary-bg-strong checked:before:opacity-100",
          scales[scale],
        )}
        type="checkbox"
        style={
          {
            "--checkmark-img": `url('${resolveSrc(checkMark)}')`,
            "--minus-img": `url('${resolveSrc(minus)}')`,
          } as React.CSSProperties
        }
        {...props}
      />
    );
  },
);
