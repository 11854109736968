import * as React from "react";
import { forwardRef } from "react";

const SvgRelatedContents = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17 8A5 5 0 1 1 7 8a5 5 0 0 1 10 0ZM14 18a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM23 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM5 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.9 8-9 8-.864-.972 9-8L11.9 8ZM21.1 16l-9-8 .864-.972 9 8L21.1 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3 18V8h1.3v10h-1.3Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRelatedContents);
export default ForwardRef;
