import * as React from "react";
import { forwardRef } from "react";

const SvgScheduler = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.945 18.235a10.097 10.097 0 0 0-3.706-15.402 10.1 10.1 0 0 0-12.71 3.665V4.143a.814.814 0 1 0-1.629 0v3.78A1.32 1.32 0 0 0 3.22 9.24H7a.814.814 0 0 0 0-1.628H4.762a8.482 8.482 0 1 1 .072 8.909c-.235-.374-.72-.535-1.12-.326-.397.206-.56.702-.32 1.09a10.098 10.098 0 0 0 12.597 3.992 10.1 10.1 0 0 0 3.954-3.043Zm-4.426-1.758.122-.122a.815.815 0 0 0 0-1.153L12.1 11.659V7a.814.814 0 0 0-.815-.814h-.2a.614.614 0 0 0-.614.614v4.926a1.474 1.474 0 0 0 .432 1.043l3.709 3.708c.25.25.656.25.907 0Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgScheduler);
export default ForwardRef;
