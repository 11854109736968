import { forwardRef } from "react";
import { FormLabel, type FormLabelProps } from "swash/form/FormLabel";
import { cn } from "swash/utils/classNames";

import type { FieldState } from "./FieldState";

type FieldLabelProps = FormLabelProps & {
  state: FieldState;
};

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(
  ({ state, children, onClick, ...props }, ref) => {
    const htmlFor = state.id;
    return (
      <FormLabel
        ref={ref}
        data-field-label
        id={state.labelId}
        htmlFor={htmlFor}
        onClick={(event) => {
          if (onClick) onClick(event);
          if (event.defaultPrevented) return;
          if (htmlFor) {
            const element = document.getElementById(htmlFor);
            if (element) {
              element.focus();
            }
          }
        }}
        {...props}
        className={cn(
          'shrink-0 group-data-[orientation="horizontal"]/fieldGroup:mr-4 group-data-[orientation="horizontal"]/fieldGroup:inline-block',
          props.className,
        )}
      >
        {children}
        {state.required ? <span title="Requis">*</span> : null}
      </FormLabel>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FieldLabel.displayName = "FieldLabel";
}
