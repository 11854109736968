import { memo } from "react";

import { Quote } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive, handleBinding } from "../utils";
import { BLOCK_STYLE_TYPE, command } from "./blockquote";

const SHORTCUT = ["⌘", "m"];

export const name = "blockquote-control";

export const shortcuts = [
  {
    description: "Citation",
    shortcut: SHORTCUT,
    category: "Blocs",
  },
];

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const BlockquoteToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      label="Citation"
      command={command}
      shortcut={SHORTCUT}
    >
      <Quote />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <BlockquoteToolbarButton
      active={checkIsBlockActive(editorState, BLOCK_STYLE_TYPE)}
    />
  );
};

BlockControls.group = "block-style";
