import { gql } from "@apollo/client";
import { EditorState, Modifier, SelectionState } from "draft-js-es";
import { Fragment, useRef } from "react";

import { useSafeQuery } from "@/containers/Apollo";

import { addDecorator } from "../modifiers/addDecorator";
import { EMOJI_STRICT_REGEX } from "./emoji-convert";

export * from "./emoji-convert";

export const name = "emoji";
const EMOJI_REGEX = /:([a-z0-9+_-]+):/;
const EMOJI_REGEX_GLOBAL = /:([a-z0-9+_-]+):/g;
const EMOJI_REGEX_FULL = /(:[a-z0-9+_-]+:)/;

const EmojisQuery = gql`
  query RichEditorEmojiPlugin_emojis {
    emojis {
      nodes {
        id
        name
        title
        encodedImage
      }
    }
  }
`;

/**
 *
 * @param {{ children: React.ReactNode, entityKey: string, contentState: import('draft-js').ContentState }} props
 */
function EmojiDecorator(props) {
  const spanRef = useRef();

  const { data } = useSafeQuery(EmojisQuery);

  if (!data) return props.children;

  const matches = props.decoratedText.match(EMOJI_REGEX);
  if (!matches) return props.children;

  const emoji = data.emojis.nodes.find((emoji) => emoji.name === matches[1]);
  if (!emoji) return props.children;
  const replacementText = `:${emoji.name}:`;

  return (
    <span ref={spanRef} data-offset-key={props.offsetKey}>
      <img
        ref={(element) => {
          if (!element) return;
          if (element.textContent === replacementText) return;
          Object.defineProperty(element, "textContent", {
            get: () => replacementText,
            configurable: true,
          });
        }}
        alt={replacementText}
        title={emoji.title}
        src={emoji.encodedImage}
        className="inline-block h-[14px]"
      />
    </span>
  );
}

const replaceTextByEmoji = (text, match, data) => {
  const emoji = data.emojis.nodes.find((emoji) => emoji.name === match[1]);
  if (!emoji) return text;
  const replacementText = `:${emoji.name}:`;

  return (
    <span>
      <img
        alt={replacementText}
        title={emoji.title}
        src={emoji.encodedImage}
        className="inline-block h-[14px]"
      />
    </span>
  );
};

const useEmojify = (text) => {
  const { data } = useSafeQuery(EmojisQuery);
  if (!data) return text;
  const matches = [...text.matchAll(EMOJI_REGEX_GLOBAL)];
  if (!matches.length) return text;

  const dirtyTexts = text.split(EMOJI_REGEX_FULL);
  const newContent = matches.reduce((contents, match) => {
    const emojiIndex = contents.findIndex((content) => content === match[0]);
    if (emojiIndex >= 0) {
      const textToReplace = contents[emojiIndex];
      contents[emojiIndex] = replaceTextByEmoji(textToReplace, match, data);
    }
    return contents;
  }, dirtyTexts);

  return (
    <>
      {newContent.map((elem, i) => (
        <Fragment key={elem + i}>{elem}</Fragment>
      ))}
    </>
  );
};

export const DisplayEmojifyText = ({ text }) => {
  return useEmojify(text);
};

export const getInitialEditorState = ({ editorState }) => {
  return addDecorator(editorState, {
    /**
     * @param {import('draft-js').ContentBlock} block
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (block, callback) => {
      const text = block.getText();
      const regex = RegExp(EMOJI_REGEX, "g");
      const matches = text.matchAll(regex);
      // eslint-disable-next-line no-restricted-syntax
      for (const match of matches) {
        const startStyles = block.getInlineStyleAt(match.index);
        const endStyles = block.getInlineStyleAt(match.index + match[0].length);
        // If there is a rupture of styles between the start and the end, we do not insert an emoji
        // because it breaks the style.
        if (startStyles !== endStyles) {
          continue;
        }
        callback(match.index, match.index + match[0].length);
      }
    },
    component: EmojiDecorator,
  });
};

export const handleKeyCommand = ({ setEditorState }, command, editorState) => {
  // Only backspace
  if (command !== "backspace") return undefined;

  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  // Only for collapsed selection
  if (!selection.isCollapsed()) return undefined;

  // Selection is collapsed, focus and offset are the same
  const key = selection.getFocusKey();
  const offset = selection.getFocusOffset();
  const block = content.getBlockForKey(key);

  // Do nothing if we are at the start of text
  if (offset === 0) return undefined;

  // Only text block
  if (block.getType() === "atomic") return undefined;

  const text = block.getText();
  const previousChar = text[offset - 1];

  // Early exit for non-emoji
  if (previousChar !== ":") return undefined;

  const leftText = text.substring(0, offset);

  const matches = leftText.match(EMOJI_STRICT_REGEX);

  // Emoji has not been matched
  if (!matches) return undefined;

  const startOffset = matches.index;
  const endOffset = offset;

  const matchedSelection = new SelectionState({
    anchorOffset: startOffset,
    anchorKey: key,
    focusOffset: endOffset,
    focusKey: key,
  });

  const modifiedContent = Modifier.removeRange(
    content,
    matchedSelection,
    "backward",
  );
  const modifiedEditorState = EditorState.push(
    editorState,
    modifiedContent,
    "remove-range",
  );

  const startSelection = new SelectionState({
    anchorOffset: startOffset,
    anchorKey: key,
    focusOffset: startOffset,
    focusKey: key,
  });

  const selectedEditorState = EditorState.forceSelection(
    modifiedEditorState,
    startSelection,
  );

  setEditorState(selectedEditorState);

  return "handled";
};

export function validateOptions(options) {
  if (!options.emojis) {
    throw new Error(`emojis is required`);
  }
  return options;
}

export const EmojiPluginFragment = gql`
  fragment EmojiPluginFragment on Emoji {
    name
    displayImage: encodedImage
    title
  }
`;
