import { gql } from "@apollo/client";
import { memo } from "react";
import { Button, ButtonProps } from "swash/Button";
import { IoPieChart } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { HasPermission } from "@/containers/User";
import { ArticleAnalyticsPanel } from "@/containers/article/panels/analytics";

export type StatsButtonButtons = {
  articleId: number;
  scale?: ButtonProps["scale"];
  className?: ButtonProps["className"];
};

export const StatsButton = memo<StatsButtonButtons>(
  ({ articleId, scale, className }) => {
    const activatePanel = ArticleAnalyticsPanel.useActivate();
    const checkIsPanelActivated = ArticleAnalyticsPanel.useCheckIsActivated();
    const active = checkIsPanelActivated({ articleId: articleId });

    if (!activatePanel) {
      return null;
    }

    const title = "Voir les statistiques";

    return (
      <HasPermission
        permission={["stats:full", "stats:exposures"]}
        method="some"
      >
        <Tooltip tooltip={title}>
          <Button
            aria-label={title}
            aria-pressed={active}
            appearance="text"
            variant="secondary"
            scale={scale ?? "md"}
            iconOnly
            onClick={(event) => {
              event.stopPropagation();
              activatePanel({ articleId: articleId });
            }}
            className={className}
          >
            <IoPieChart />
          </Button>
        </Tooltip>
      </HasPermission>
    );
  },
);

export type ArticleStatsButtonProps = {
  article: {
    id: number;
    published: boolean;
  };
  scale?: StatsButtonButtons["scale"];
  className?: string;
};

export const ArticleStatsButton = (props: ArticleStatsButtonProps) => {
  if (!props.article.published) {
    return null;
  }
  return (
    <StatsButton
      articleId={props.article.id}
      className={props.className}
      scale={props.scale}
    />
  );
};

ArticleStatsButton.fragments = {
  article: gql`
    fragment ArticleStatsButton_article on Article {
      id
      published
    }
  `,
};
