import { ApolloError } from "@apollo/client";
import { FORM_ERROR } from "final-form";
import { get, uniq } from "lodash-es";
import React, { forwardRef } from "react";
import type { FormProps as FinalFormProps } from "react-final-form";

import { CollaborativeForm } from "@/components/forms/CollaborativeForm";
import { ERRORS } from "@/config/messages";

import { BaseForm, type BaseFormProps } from "./BaseForm";
import { FormSubmitterProvider } from "./FormSubmitter";

export { FORM_ERROR } from "final-form";

function getGraphQLErrorFromCode(
  errorCode: string,
  params: any,
): Record<string, string> | null {
  const rawError = get(ERRORS, errorCode.split(":"));
  if (!rawError) return null;
  if (typeof rawError === "string") {
    return { [FORM_ERROR]: rawError };
  }
  if (typeof rawError === "function") {
    return { [FORM_ERROR]: rawError(params) };
  }
  return rawError;
}

export function extractGraphQlErrors(error: unknown) {
  if (!(error instanceof ApolloError)) return {};
  const graphQLErrors = error?.graphQLErrors;
  if (!graphQLErrors) return {};

  return graphQLErrors.reduce(
    (obj, error) => {
      const errorCode: string =
        ((error as any)?.extensions?.exception?.code ||
          (error as any)?.extensions?.code) ??
        "default";
      const gqlError = getGraphQLErrorFromCode(errorCode, error?.extensions);
      if (!gqlError) return obj;
      return Object.entries(gqlError).reduce(
        (obj, [field, value]) => ({
          ...obj,
          [field]: uniq([...(obj[field] || []), value]),
        }),
        obj,
      );
    },
    {} as Record<string, string[]>,
  );
}

export type FormProps<FormValues = Record<string, any>> =
  FinalFormProps<FormValues> & {
    onSubmit: BaseFormProps<FormValues>["onSubmit"];
    name?: string;
    style?: React.CSSProperties;
    autoComplete?: string;
    collaborative?: boolean | "readOnly";
    as?: keyof React.ReactHTML;
  };

export const Form = forwardRef<HTMLElement, FormProps>(
  (
    {
      children,
      onSubmit,
      name,
      "aria-label": ariaLabel,
      collaborative,
      as: As = "form",
      initialValues,
      initialValuesEqual,
      keepDirtyOnReinitialize,
      mutators,
      decorators,
      validate,
      destroyOnUnregister,
      ...props
    },
    ref,
  ) => {
    if (process.env["NODE_ENV"] === "development") {
      if (typeof onSubmit !== "function") {
        // eslint-disable-next-line no-console
        console.warn(`onSubmit is required in Form`);
      }
    }

    const Form = collaborative ? CollaborativeForm : BaseForm;
    const formProps = collaborative === "readOnly" ? { readOnly: true } : {};

    const handleSubmit: BaseFormProps["onSubmit"] = async (
      values,
      form,
      infos,
    ) => {
      try {
        return await onSubmit(values, form, infos);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const graphQLErrors = extractGraphQlErrors(error);

        return Object.keys(graphQLErrors).length === 0
          ? { [FORM_ERROR]: "Une erreur est survenue" }
          : graphQLErrors;
      }
    };

    return (
      <FormSubmitterProvider>
        <Form
          {...formProps}
          onSubmit={handleSubmit}
          name={name}
          initialValues={initialValues}
          initialValuesEqual={initialValuesEqual}
          keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          destroyOnUnregister={destroyOnUnregister}
          mutators={mutators}
          decorators={decorators}
          validate={validate}
        >
          {(formRenderProps): React.ReactElement => {
            const rendered =
              typeof children === "function"
                ? children(formRenderProps)
                : children;

            if (As === null) return rendered as React.ReactElement;

            const elementProps =
              As === "form"
                ? {
                    noValidate: true,
                    onSubmit: formRenderProps.handleSubmit,
                  }
                : { role: "form" };

            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore FIXME: I don't know how to type that
              <As
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore FIXME: I don't know how to type that
                ref={ref}
                name={name}
                aria-label={ariaLabel}
                style={{ display: "contents", ...props.style }}
                {...elementProps}
                {...props}
              >
                {rendered}
              </As>
            );
          }}
        </Form>
      </FormSubmitterProvider>
    );
  },
) as <FormValues = Record<string, any>>(
  props: FormProps<FormValues> & {
    ref?: React.ForwardedRef<HTMLElement>;
  },
) => React.ReactElement;
