import { KeyBindingUtil } from "draft-js-es";

const OSXToWindowsKeyMapping = {
  "⌘": "Ctrl",
  "⌥": "Alt",
};

const VisualKeyMapping = {
  ArrowUp: "↑",
  ArrowRight: "→",
  ArrowLeft: "←",
  ArrowDown: "↓",
  " ": "Espace",
};

const firstUpper = (s) => s[0].toUpperCase() + s.slice(1);

export const generateKey = (key) => {
  const isOSX = KeyBindingUtil.usesMacOSHeuristics();
  let result = firstUpper(key);
  if (!isOSX) {
    result = OSXToWindowsKeyMapping[result] ?? result;
  }
  result = VisualKeyMapping[result] ?? result;
  return result;
};

export const generateShortcut = (shortcut) => {
  if (!shortcut) return null;
  return shortcut.map((key) => generateKey(key)).join(" + ");
};
