import { EditorState, Modifier } from "draft-js-es";

export const name = "remove-style";
export const command = "remove-style";

export const handleKeyCommand = (
  { setEditorState, editorState, plugins },
  cmd,
) => {
  if (cmd !== command) return undefined;
  const allowedInlineStyles = plugins.reduce((allowedInlineStyles, plugin) => {
    if (plugin.allowedInlineStyles) {
      allowedInlineStyles.push(...plugin.allowedInlineStyles);
    }
    return allowedInlineStyles;
  }, []);
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const contentStateWithoutStyles = allowedInlineStyles.reduce(
    (newContentState, style) =>
      Modifier.removeInlineStyle(newContentState, selection, style),
    contentState,
  );

  setEditorState(
    EditorState.push(
      editorState,
      contentStateWithoutStyles,
      "change-inline-style",
    ),
  );
  return true;
};
