import { gql } from "@apollo/client";
import { ContentState, Modifier, SelectionState } from "draft-js-es";
import { Map } from "immutable-es";
import { forwardRef, useCallback, useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { FieldControl } from "@/components/fields/FieldControl";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";
import { RichEditorTextBox } from "@/components/rich-editor/RichEditorTextBox";
import { MentionProvider } from "@/components/rich-editor/plugins/mention/MentionPluginContext";
import { SpellCheckProvider } from "@/components/rich-editor/plugins/spell-check-control/SpellCheckPluginContext";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useUser } from "@/containers/User";
import { useTextPreset } from "@/containers/editor/presets/preset-text";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";
import { UserOptionLabel } from "@/containers/user/UserOptionLabel";

const CommentMentionUserFragment = gql`
  fragment CommentMention_user on User {
    id
    name: fullName
    ...UserHoverCardTooltip_user
    ...UserOptionLabel_user
  }
  ${UserHoverCardTooltip.fragments.user}
  ${UserOptionLabel.fragments.user}
`;

const CommentTextBoxFieldMentionUsersQuery = gql`
  query CommentTextBoxField_MentionUsersQuery(
    $currentUserId: Int!
    $search: String
    $offset: Int
  ) {
    mentions: users(
      offset: $offset
      where: { id: { neq: $currentUserId }, search: $search, enabled: true }
    ) {
      nodes {
        id
        ...CommentMention_user
      }
      pageInfo {
        hasMore
      }
      totalCount
    }
  }
  ${CommentMentionUserFragment}
`;

export const CommentTextBoxField = forwardRef((props, ref) => {
  const user = useUser();
  const triggerMentionQueries = Map({
    "@": {
      query: CommentTextBoxFieldMentionUsersQuery,
      mentionFragment: CommentMentionUserFragment,
      mentionType: "User",
      fragmentName: "CommentMention_user",
      variables: { currentUserId: user.id },
    },
  });

  const plugins = useTextPreset({
    mention: { triggerMentionQueries, mentionPrefix: "@" },
  });
  return (
    <FieldControl
      ref={ref}
      as={CommentRichEditorTextBox}
      plugins={plugins}
      stripPastedStyles={props.rich}
      {...props}
    />
  );
});

const getMentions = (contentState) => {
  const blocks = contentState.getBlocksAsArray();
  const mentions = [];
  blocks.map((block, blockIndex) => {
    let entity = null;
    block.findEntityRanges(
      (value) => {
        const entityKey = value.getEntity();
        if (entityKey) {
          entity = contentState.getEntity(entityKey);
        }
        return Boolean(entityKey);
      },
      (startOffset, endOffset) => {
        const mention = entity.getData().mention;
        mentions.push({
          blockIndex,
          startOffset,
          endOffset,
          data: mention,
          mutability: entity.mutability,
        });
      },
    );
  });
  return mentions;
};

/**
 * Convert content state to comment
 * @param {import('draft-js').ContentState} contentState
 */
const toComment = (contentState) => {
  return {
    value: contentState.getPlainText(),
    mentions: getMentions(contentState),
  };
};

/**
 * Convert comment to content state.
 * @returns {import('draft-js').ContentState}
 */
const fromComment = (comment) => {
  if (!comment?.value) return ContentState.createFromText("");
  let contentState = ContentState.createFromText(comment.value);
  contentState.getBlocksAsArray().forEach((block, index) => {
    if (!comment.mentions || !Array.isArray(comment.mentions)) {
      return;
    }
    const blockMentions = comment.mentions.filter(
      ({ blockIndex }) => blockIndex === index,
    );
    if (!blockMentions.length) {
      return;
    }
    blockMentions.forEach(({ startOffset, endOffset, mutability, data }) => {
      contentState.createEntity("mention", mutability, { mention: data });
      const entityKey = contentState.getLastCreatedEntityKey();
      const range = new SelectionState({
        anchorKey: block.getKey(),
        anchorOffset: startOffset,
        focusKey: block.getKey(),
        focusOffset: endOffset,
      });
      contentState = Modifier.applyEntity(contentState, range, entityKey);
    });
  });
  return contentState;
};

/**
 * @param {string | {value: string, mentions: *} } comment
 * @return {Draft.Model.ImmutableData.ContentState}
 */
const createEditorContent = (comment) => {
  return !comment || typeof comment === "string"
    ? ContentState.createFromText(comment ?? "")
    : fromComment(comment);
};

const Editor = forwardRef(
  (
    {
      value,
      readOnly = false,
      plugins = [],
      name,
      label,
      onChange,
      stripPastedStyles,
      whiteSpace,
      className,
      ...props
    },
    ref,
  ) => {
    const onChangeRef = useLiveRef(onChange);
    const [contentState, setContentState] = useEnhancedState(
      () => createEditorContent(value),
      (nextState) => {
        if (onChangeRef.current) {
          onChangeRef.current(toComment(nextState));
        }
      },
    );
    const editor = useRichEditorState({
      name,
      label,
      contentState,
      setContentState,
      multiline: true,
      acceptSoftNewLine: false,
      isRichEditorTextBox: true,
      plugins,
      readOnly: readOnly,
    });

    const refs = useLiveRef({ editor });

    const updateEditorState = useCallback(
      (comment) => {
        const {
          editor: { editorState },
        } = refs.current;
        // Compute fresh internal value
        const internalValue = editorState.getCurrentContent().getPlainText();

        // If the value has changed we set the new content
        if (internalValue !== comment.value) {
          setContentState(createEditorContent(comment));
        }
      },
      [refs, setContentState],
    );

    // Handle `value` from outside
    useEffect(() => {
      const {
        editor: { hasFocus },
      } = refs.current;

      // If editor has focus, then we ignore input value
      // this component cannot be controlled while the user is editing.
      if (!hasFocus) {
        updateEditorState(value);
      }
    }, [value, refs, setContentState, updateEditorState]);

    return (
      <RichEditorTextBox
        ref={ref}
        className={className}
        borderless
        editor={editor}
        scale="sm"
        {...props}
      />
    );
  },
);

export const CommentRichEditorTextBox = forwardRef(
  ({ readOnly = false, plugins, ...props }, ref) => {
    return (
      <MentionProvider>
        <SpellCheckProvider>
          <Editor
            ref={ref}
            rows={1}
            maxRows={5}
            showTools={false}
            readOnly={readOnly}
            plugins={plugins}
            {...props}
          />
        </SpellCheckProvider>
      </MentionProvider>
    );
  },
);
