import React from "react";

import { Button } from "../Button";
import { IoCloseCircleOutline } from "../Icon";
import { TextboxEndAdornment } from "../controls/Textbox";

export const TextboxClearAdornment = (props) => {
  return (
    <TextboxEndAdornment>
      <Button
        type="button"
        appearance="text"
        scale="xs"
        iconOnly
        aria-label="Clear"
        {...props}
      >
        <IoCloseCircleOutline />
      </Button>
    </TextboxEndAdornment>
  );
};
