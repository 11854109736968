// @ts-check
import { visit } from "unist-util-visit";

/**
 * Remove empty nodes.
 * @param {import('hast').Root} root
 * @returns {import('hast').Root}
 */
export const removeEmptyNodes = (root) => {
  visit(root, "element", (node, index, parent) => {
    if (!parent || index == null) return;

    if (
      node.children.length === 0 &&
      (node.tagName === "p" || node.tagName === "div")
    ) {
      parent.children.splice(index, 1);
    }
  });

  return root;
};
