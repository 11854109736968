import * as React from "react";
import { forwardRef } from "react";

const SvgAudioForward = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.47 2.368A.472.472 0 0 0 8 1.895 7.966 7.966 0 0 0 3.102 3.58 8.057 8.057 0 0 0 .255 7.93a8.102 8.102 0 0 0 .395 5.196A8.034 8.034 0 0 0 4.12 16.99a7.956 7.956 0 0 0 5.095.916 7.983 7.983 0 0 0 4.587-2.415 8.095 8.095 0 0 0 .982-9.81.469.469 0 0 0-.649-.15.476.476 0 0 0-.15.652 7.143 7.143 0 0 1-.866 8.656 7.02 7.02 0 0 1-8.542 1.323 7.09 7.09 0 0 1-3.064-3.41 7.149 7.149 0 0 1-.348-4.584A7.109 7.109 0 0 1 3.68 4.33 7.03 7.03 0 0 1 8 2.842c.26 0 .47-.212.47-.474Z"
      fill="currentColor"
    />
    <path
      d="M10.735 2.563c.118-.086.118-.303 0-.39L7.796.03c-.12-.086-.268.022-.268.195v4.287c0 .173.149.281.268.194l2.938-2.143ZM6.872 12.316V7.568h-.903c-.099.47-.343.55-.665.57l-.35.026v.624h.896v3.528h1.022ZM9.199 12.422c1.06 0 1.792-.703 1.792-1.81 0-1.015-.567-1.705-1.536-1.705-.441 0-.777.153-.994.385l.112-1.002h2.016v-.722H7.92l-.29 2.506.817.186c.106-.371.356-.683.752-.683.454 0 .698.351.698 1.034 0 .71-.204 1.141-.725 1.141-.533 0-.804-.524-.692-1.16l-.981.245c-.08.995.672 1.585 1.7 1.585Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAudioForward);
export default ForwardRef;
