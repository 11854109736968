import { gql } from "@apollo/client";
import { Anchor } from "swash/Anchor";
import { Button } from "swash/Button";
import { IoMailOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { HasPermission } from "@/containers/User";

import { ChannelAccounts } from "../AlertChannelLayout";

export const title = "Email";

export const type = "email";

export const fieldName = "targetingNewsletter";

export const AlertFragment = gql`
  fragment EmailChannelAlertFragment on Alert {
    targetingNewsletter
  }
`;

export const ChannelFragment = gql`
  fragment EmailChannelFragment on EmailAlertChannel {
    newsletters {
      id
      name
      draftId
    }
  }
`;

export const getInitialValues = ({ channelData, alert }) => {
  const channelEnabled = Boolean(
    channelData?.status && channelData.status !== "disabled",
  );
  return { targetingNewsletter: alert?.targetingNewsletter ?? channelEnabled };
};

export const Title = ({ data }) => {
  return (
    <div>
      <span>{title}</span>
      <HasPermission permission={["newsletters:full"]}>
        <ChannelAccounts>
          {data.newsletters.map((newsletter) => (
            <Tooltip key={newsletter.id} tooltip={newsletter.name}>
              <li>
                <Button
                  asChild
                  iconOnly
                  appearance="text"
                  scale="sm"
                  title={newsletter.name}
                >
                  <Anchor
                    href={`/admin/newsletters/edit/${newsletter.draftId}/`}
                    target="_blank"
                  >
                    <IoMailOutline />
                  </Anchor>
                </Button>
              </li>
            </Tooltip>
          ))}
        </ChannelAccounts>
      </HasPermission>
    </div>
  );
};
