import { memo } from "react";

import { H2 } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive } from "../utils";
import { BLOCK_STYLE_TYPE, command } from "./header-two";

export const name = "header-two-control";

const HeaderTwoToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      command={command}
      active={active}
      label="Intertitre (niveau 2)"
    >
      <H2 />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <HeaderTwoToolbarButton
      active={checkIsBlockActive(editorState, BLOCK_STYLE_TYPE)}
    />
  );
};

BlockControls.group = "block-style";
