import { forwardRef } from "react";
import { FormHint, type FormHintProps } from "swash/form/FormHint";
import { cn } from "swash/utils/classNames";

import type { FieldState } from "./FieldState";

type FieldHintProps = FormHintProps & { state: FieldState };

export const FieldHint = forwardRef<HTMLDivElement, FieldHintProps>(
  ({ state, ...props }, ref) => {
    return (
      <FormHint
        data-field-hint
        ref={ref}
        {...props}
        className={cn(
          'group-data-[orientation="vertical"]/fieldGroup:shrink-0 group-data-[orientation="vertical"]/fieldGroup:grow group-data-[orientation="vertical"]/fieldGroup:basis-full',
          props.className,
        )}
      />
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FieldHint.displayName = "FieldHint";
}
