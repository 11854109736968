import { gql, useMutation, useSubscription } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";

/**
 * @typedef WatchInfo
 * @property {string} id
 */

const WatchFragment = gql`
  fragment WatchFragment on Watch {
    id
    ignored
    # here we could add customization, types ...
  }
`;

const WatchQuery = gql`
  query WatchApiWatchQuery($input: WatchInput!) {
    watch(input: $input) {
      ...WatchFragment
    }
  }
  ${WatchFragment}
`;

const WatchMutation = gql`
  mutation WatchApiWatchMutation($input: WatchInput!) {
    watch(input: $input) {
      ...WatchFragment
    }
  }
  ${WatchFragment}
`;

const UnwatchMutation = gql`
  mutation WatchApiUnwatchMutation($input: WatchInput!) {
    unwatch(input: $input) {
      ...WatchFragment
    }
  }
  ${WatchFragment}
`;

const WatchUpdatedSubscription = gql`
  subscription WatchApiWatchUpdatedSubscription($id: String!) {
    userWatchingUpdated(where: { id: { eq: $id } }) {
      ...WatchFragment
    }
  }
  ${WatchFragment}
`;

/**
 * @typedef WatchApi
 * @property {() => Promise<import('@apollo/client').FetchResult>} watch
 * @property {() => Promise<import('@apollo/client').FetchResult>} unwatch
 * @property {WatchInfo | null} info
 */

/**
 * Returns info about watch and watch method.
 * @param {number} id
 * @returns {LockApi}
 */
export function useWatchApi(id) {
  const { data: watchData } = useSafeQuery(WatchQuery, {
    variables: { input: { id } },
  });

  const [watch] = useMutation(WatchMutation, {
    variables: { input: { id } },
    optimisticResponse: {
      watch: { __typename: "Watch", id, ignored: false },
    },
  });

  const [unwatch] = useMutation(UnwatchMutation, {
    variables: { input: { id } },
    optimisticResponse: {
      unwatch: { __typename: "Watch", id, ignored: true },
    },
  });

  useSubscription(WatchUpdatedSubscription, {
    variables: { id },
  });

  return {
    watch,
    unwatch,
    loading: !watchData,
    info: watchData?.watch ?? null,
  };
}
