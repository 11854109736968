import { gql } from "@apollo/client";

import { ArticleComment } from "@/containers/article/panels/comments/ArticleComment";
import { ArticleCommentList } from "@/containers/article/panels/comments/ArticleCommentList";
import { ArticleCommentsTemplate } from "@/containers/article/panels/comments/ArticleCommentsTemplate";
import { CommentScopeProvider } from "@/containers/article/panels/comments/CommentScopeProvider";

const ArticleCommentsArticleFragment = gql`
  fragment ArticleComments_article on Article {
    globalId
    comments(where: { resolved: $resolved }, offset: $offset) {
      nodes {
        id
        ...ArticleComment_commentThread
      }
      ...ArticleCommentList_commentThreadConnection
    }
  }
  ${ArticleComment.fragments.commentThread}
  ${ArticleCommentList.fragments.commentThreadConnection}
`;

export const ArticleCommentsArticleQuery = gql`
  query ArticleComments_article(
    $articleId: Int!
    $resolved: Boolean!
    $offset: Int
  ) {
    article(id: $articleId) {
      id
      ...ArticleComments_article
    }
  }
  ${ArticleCommentsArticleFragment}
`;

const resolvers = {
  opened: {
    label: "Ouverts",
    labelElement: "Commentaires ouverts",
  },
  resolved: {
    label: "Résolus",
    labelElement: "Commentaires résolus",
  },
};

export const ArticleComments = () => (
  <CommentScopeProvider scope="text">
    <ArticleCommentsTemplate resolvers={resolvers} />
  </CommentScopeProvider>
);
