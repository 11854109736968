import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageLoader } from "swash/Loader";
import { useToaster } from "swash/Toast";

import { injectScript, removeScript } from "@/components/Script";

const SCRIPT_SRC = `https://cdn-ext.merci-app.com/merciapp.sdk.js`;

const MerciAppContext = createContext();

function cleanSdk() {
  // Remove the MerciApp custom tag
  Array.from(document.querySelectorAll("#mci-mirror")).forEach((tag) =>
    tag.remove(),
  );
  // Remove init scripts
  const xpath = "//script[contains(text(),'MerciAppTrigger.load')]";
  const initScripts = [];
  const scripts = document.evaluate(
    xpath,
    document.body,
    null,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null,
  );
  let nextScript = scripts.iterateNext();
  while (nextScript) {
    initScripts.push(nextScript);
    nextScript = scripts.iterateNext();
  }
  initScripts.forEach((script) => script.remove());

  // Remove sdk
  removeScript(SCRIPT_SRC);
}

export const MerciAppProvider = ({ token, children }) => {
  const toaster = useToaster();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!token) return;

    injectScript({
      src: SCRIPT_SRC,
      parent: document.body,
      onload: () => {
        setReady(true);
      },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      toaster.danger(
        "L’outil de correction est indisponible pour le moment. Veuillez rafraîchir votre navigateur et réessayer.",
      );
    });

    return () => {
      cleanSdk();
    };
  }, [toaster, token]);

  const load = useCallback(
    (options = {}, instanceIdentifier) => {
      if (!ready) return null;
      return window.MerciApp.load(token, options, instanceIdentifier);
    },
    [token, ready],
  );

  const value = useMemo(() => ({ load }), [load]);

  if (!ready) return <PageLoader />;

  return (
    <MerciAppContext.Provider value={value}>
      {children}
    </MerciAppContext.Provider>
  );
};

export const MerciApp = ({ id, children }) => {
  const merciAppId = `merciapp-${id}`;
  return (
    <div id={merciAppId} className="flex">
      <MerciAppInit id={merciAppId}>{children}</MerciAppInit>
      <div className="merciapp-spinner" />
    </div>
  );
};

function cleanInstance(merciAppInstance) {
  const unloadEvent = new CustomEvent("MCI_SDK_DISABLE", {
    detail: {
      instanceIdentifier: merciAppInstance.instanceIdentifier,
    },
  });
  window.document.dispatchEvent(unloadEvent);
  merciAppInstance.injectedScripts.forEach((script) => script.remove());
  merciAppInstance.isLoaded = false;
}

const MerciAppInit = ({ id, children }) => {
  const { load } = useContext(MerciAppContext);

  useEffect(() => {
    if (!load) return;

    const merciAppInstance = load({
      selector: `#${id} > .merciapp-editor`,
      launchOnLoad: true,
      showLargeSpinner: true,
      enableMultilanguage: true,
      allowedLanguage: "en",
      spinnerContainerSelector: `#${id} > .merciapp-spinner`,
      // alertsPanelContainerSelector: `.merciapp-alerts`,
    });

    return () => {
      if (merciAppInstance?.isLoaded) {
        cleanInstance(merciAppInstance);
      }
    };
  }, [load, id]);

  return children;
};
