import { Draggable as ReactBeautifulDnd } from "react-beautiful-dnd";

export const Draggable = ({ children, ...props }) => (
  <ReactBeautifulDnd {...props}>
    {(provided, ...args) => {
      provided.dragHandleProps = {
        ...provided.dragHandleProps,
        onMouseDown: (event) => {
          event.currentTarget.focus();
        },
      };
      return children(provided, ...args);
    }}
  </ReactBeautifulDnd>
);
