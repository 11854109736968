/* eslint-disable no-restricted-syntax */
import { useColor } from "@xstyled/styled-components";
import { EditorState, Modifier, SelectionState } from "draft-js-es";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual } from "swash/utils/shallowEqual";
import { useEventListener } from "swash/utils/useEventListener";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useEditorName, useSelection } from "../../RichEditorContext";
import { addDecorator } from "../../modifiers/addDecorator";
import { removeDecorator } from "../../modifiers/removeDecorator";

const ControlsContext = createContext();
const CurrentMatchContext = createContext();
const CurrentMatchIndexContext = createContext();
const SearchValueContext = createContext();
const ReplaceValueContext = createContext();
const MatchCountContext = createContext();
const HasMatchContext = createContext();
const VisibleContext = createContext();

const isScrollable = (element) => {
  const hasScrollableContent = element.scrollHeight > element.clientHeight;

  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

const getScrollableParent = (element) => {
  return !element || element === document.body
    ? document.body
    : isScrollable(element)
      ? element
      : getScrollableParent(element.parentNode);
};

const checkElementIsVisible = (element, container) => {
  const { bottom, height, top, left } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const onScreen =
    top <= containerRect.top
      ? containerRect.top - top <= height
      : bottom - containerRect.bottom <= height;

  if (!onScreen) return false;

  const cx = left + element.offsetWidth / 2;
  const cy = top + element.offsetHeight / 2;
  const pointContainer = document.elementFromPoint(cx, cy);
  const notHidden = pointContainer === element;

  return notHidden;
};

const SearchDecorator = ({ children, blockKey, start, end, contentState }) => {
  const elementRef = useRef();
  const primaryLighter = useColor("primary-lighter");
  const greyLighter = useColor("grey-lighter");
  const editorName = useEditorName();
  const currentMatch = useCurrentMatch();
  const { registerMatch } = useSearchControls();
  const { hasFocus } = useSelection();
  const hasFocusRef = useLiveRef(hasFocus);

  const blockIndex = useMemo(
    () =>
      contentState
        .getBlockMap()
        .keySeq()
        .findIndex((k) => k === blockKey),
    [blockKey, contentState],
  );
  const match = useMemo(
    () => ({ editorName, blockKey, blockIndex, start, end }),
    [editorName, blockKey, blockIndex, start, end],
  );
  useEffect(() => registerMatch(match), [registerMatch, match]);

  const isCurrentMatch = currentMatch === match;

  useEffect(() => {
    if (!isCurrentMatch || hasFocusRef.current) return;
    const element = elementRef.current;
    const container = getScrollableParent(element);
    if (!checkElementIsVisible(element, container)) {
      element.scrollIntoView({ block: "center" });
    }
  }, [isCurrentMatch, hasFocusRef]);

  return (
    <span
      ref={elementRef}
      style={{
        backgroundColor: isCurrentMatch ? primaryLighter : greyLighter,
      }}
    >
      {children}
    </span>
  );
};

const checkIsSearchDecorator = (decorator) =>
  decorator.component === SearchDecorator;

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

const createDecorator = ({ searchValue }) => {
  return {
    /**
     * @param {import('draft-js').ContentBlock} contentBlock
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (contentBlock, callback) => {
      const text = contentBlock.getText().toLowerCase();
      const matches = text.matchAll(escapeRegExp(searchValue.toLowerCase()));
      for (const match of matches) {
        callback(match.index, match.index + match[0].length);
      }
    },
    component: SearchDecorator,
  };
};

const removeSearchDecorator = (editorState) => {
  const decorators = editorState.getDecorator()?.getDecorators() ?? [];
  const existingDecorator = decorators.find((decorator) =>
    checkIsSearchDecorator(decorator),
  );
  return existingDecorator
    ? removeDecorator(editorState, existingDecorator)
    : editorState;
};

const addSearchDecorator = (editorState, searchValue) => {
  return addDecorator(
    removeSearchDecorator(editorState),
    createDecorator({ searchValue }),
  );
};

export const SearchPluginProvider = ({ children }) => {
  const [editors, setEditors] = useState([]);
  const editorsRef = useLiveRef(editors);
  const [matches, setMatches] = useState([]);
  const [currentMatchProps, setCurrentMatchProps] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [replaceValue, setReplaceValue] = useState("");
  const searchFieldRef = useRef(null);

  const registerEditor = useCallback(
    (editor) => {
      setEditors((editors) => {
        if (!editors.find((e) => e.name === editor.name)) {
          return [...editors, editor];
        }

        return editors;
      });

      return () => {
        // Remove decorators
        editor.setEditorState((editorState) =>
          removeSearchDecorator(editorState),
        );
        setEditors((editors) => editors.filter((e) => e.name !== editor.name));
      };
    },
    [setEditors],
  );

  const getMatchEditor = useCallback(
    (match) => {
      const editor = editorsRef.current.find(
        (editor) => editor.name === match.editorName,
      );
      return editor ?? null;
    },
    [editorsRef],
  );

  const getMatchRank = useCallback(
    (match) => {
      const editor = getMatchEditor(match);
      if (!editor) return -1;
      const editorIndex = editorsRef.current.indexOf(editor);
      const charIndex = match.start;
      return editorIndex * 10e6 + match.blockIndex * 10e5 + charIndex;
    },
    [getMatchEditor, editorsRef],
  );

  const registerMatch = useCallback(
    (match) => {
      const sortByRank = (a, b) => getMatchRank(a) - getMatchRank(b);
      setMatches((matches) => [...matches, match].sort(sortByRank));
      return () => {
        setMatches((matches) =>
          matches.filter((_match) => _match !== match).sort(sortByRank),
        );
      };
    },
    [getMatchRank],
  );

  useEffect(() => {
    if (visible && searchValue) {
      editors.forEach((editor) => {
        editor.setEditorState((editorState) =>
          addSearchDecorator(editorState, searchValue),
        );
      });
    } else {
      editors.forEach((editor) => {
        editor.setEditorState((editorState) =>
          removeSearchDecorator(editorState),
        );
      });
    }
  }, [visible, searchValue, editors]);

  const show = useCallback(() => {
    setVisible(true);
    searchFieldRef.current?.select();
  }, []);
  const hide = useCallback(() => setVisible(false), []);

  const currentMatch = useMemo(() => {
    return (
      (currentMatchProps
        ? matches.find((match) => shallowEqual(match, currentMatchProps))
        : null) ??
      matches[0] ??
      null
    );
  }, [currentMatchProps, matches]);
  const currentMatchIndex = matches.indexOf(currentMatch);
  const matchCount = matches.length;

  const refs = useLiveRef({
    matches,
    currentMatch,
    currentMatchIndex,
    replaceValue,
  });

  const goTo = useCallback(
    (position) => {
      const { currentMatchIndex, matches } = refs.current;
      setCurrentMatchProps(
        matches.at((currentMatchIndex + position) % matches.length),
      );
    },
    [refs],
  );

  const previous = useCallback(() => goTo(-1), [goTo]);
  const next = useCallback(() => goTo(1), [goTo]);

  const getMatchSelection = useCallback((match) => {
    return SelectionState.createEmpty(match.blockKey).merge({
      anchorOffset: match.start,
      focusOffset: match.end,
    });
  }, []);

  const replaceMatches = useCallback(
    (matches, text) => {
      editorsRef.current.forEach((editor) => {
        const editorMatches = matches.filter(
          (match) => editor === getMatchEditor(match),
        );
        if (!editorMatches.length) return;
        editor.setEditorState((editorState) => {
          const contentState = editorMatches.reduce(
            (contentState, match) =>
              Modifier.replaceText(
                contentState,
                getMatchSelection(match),
                text,
              ),
            editorState.getCurrentContent(),
          );
          return EditorState.push(
            editorState,
            contentState,
            "replace-characters",
          );
        });
      });
    },
    [getMatchEditor, getMatchSelection, editorsRef],
  );

  const replaceOne = useCallback(() => {
    const { currentMatch, replaceValue } = refs.current;
    if (currentMatch) {
      replaceMatches([currentMatch], replaceValue);
    }
  }, [replaceMatches, refs]);

  const replaceAll = useCallback(() => {
    const { matches, replaceValue } = refs.current;
    if (matches.length) {
      replaceMatches(matches, replaceValue);
    }
  }, [replaceMatches, refs]);

  // Hide when escape is pressed
  useEventListener(document, "keyup", (event) => {
    if (event.key === "Escape") {
      hide();
    }
  });

  const controls = useMemo(
    () => ({
      registerEditor,
      registerMatch,
      searchFieldRef,
      show,
      hide,
      setSearchValue,
      setReplaceValue,
      previous,
      next,
      replaceOne,
      replaceAll,
    }),
    [
      registerEditor,
      registerMatch,
      searchFieldRef,
      show,
      hide,
      previous,
      next,
      replaceOne,
      replaceAll,
      setSearchValue,
      setReplaceValue,
    ],
  );

  return (
    <ControlsContext.Provider value={controls}>
      <CurrentMatchIndexContext.Provider value={currentMatchIndex}>
        <CurrentMatchContext.Provider value={currentMatch}>
          <SearchValueContext.Provider value={searchValue}>
            <ReplaceValueContext.Provider value={replaceValue}>
              <MatchCountContext.Provider value={matchCount}>
                <HasMatchContext.Provider value={matchCount > 0}>
                  <VisibleContext.Provider
                    value={visible && editors.length > 0}
                  >
                    {children}
                  </VisibleContext.Provider>
                </HasMatchContext.Provider>
              </MatchCountContext.Provider>
            </ReplaceValueContext.Provider>
          </SearchValueContext.Provider>
        </CurrentMatchContext.Provider>
      </CurrentMatchIndexContext.Provider>
    </ControlsContext.Provider>
  );
};

export const useSearchControls = () => useContext(ControlsContext);
export const useCurrentMatch = () => useContext(CurrentMatchContext);
export const useCurrentMatchIndex = () => useContext(CurrentMatchIndexContext);
export const useSearchValue = () => useContext(SearchValueContext);
export const useReplaceValue = () => useContext(ReplaceValueContext);
export const useMatchCount = () => useContext(MatchCountContext);
export const useHasMatch = () => useContext(HasMatchContext);
export const useSearchVisible = () => useContext(VisibleContext);
