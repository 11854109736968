import { RichUtils } from "draft-js-es";

export const name = "core-soft-new-line";

/**
 * @param {import('../RichEditorState').RichEditorState} state
 */
function handleInsertSoftNewLine(state) {
  const { editorState, setEditorState } = state;
  setEditorState(RichUtils.insertSoftNewline(editorState));
  return "handled";
}

export const handleKeyCommand = (state, cmd) => {
  const { acceptSoftNewLine } = state;
  if (cmd === "insert-soft-new-line") {
    return acceptSoftNewLine && handleInsertSoftNewLine(state);
  }
};
