/* eslint-disable no-param-reassign */
import { removeInlineStyle } from "../modifiers/removeInlineStyle";
import { smartToggleInlineStyle } from "../modifiers/smartToggleInlineStyle";
import { INLINE_STYLE } from "./subscript-convert";

export * from "./subscript-convert";

const OPPOSITE_INLINE_STYLE = "SUPERSCRIPT";

export const name = "subscript";
export const command = "subscript";

export const handleKeyCommand = ({ setEditorState, editorState }, cmd) => {
  if (cmd === command) {
    setEditorState(
      smartToggleInlineStyle(
        removeInlineStyle(editorState, OPPOSITE_INLINE_STYLE),
        INLINE_STYLE,
      ),
    );
    return true;
  }
  return undefined;
};
