import { forwardRef } from "react";
import { createTeleporter } from "react-teleporter";

import { PageTitle as BasePageTitle } from "@/components/Layout";

const Teleporter = createTeleporter();

export const PageTitle = forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>((props, ref) => {
  return (
    <Teleporter.Source>
      <BasePageTitle ref={ref} {...props} />
    </Teleporter.Source>
  );
});
PageTitle.displayName = "PageTitle";

export const PageTitleTarget: React.FC = () => {
  return <Teleporter.Target />;
};
