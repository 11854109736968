import { handleBinding } from "../../utils";

const SHORTCUT = ["⌘", "f"];

export const name = "search";
export const command = "search";

const handleSearchBinding = (event) => handleBinding(SHORTCUT, command, event);

export const shortcuts = [
  {
    description: "Rechercher",
    shortcut: SHORTCUT,
    category: "Général",
  },
];

export const keyBindingFn = (state, event) => handleSearchBinding(event);

export const matchKeyboardEvent = (event) =>
  Boolean(handleSearchBinding(event));
