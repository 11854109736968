import * as React from "react";
import { forwardRef } from "react";

const SvgUnscheduled = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m17.584 3.729.087-.087-.102-.067a10.105 10.105 0 0 0-10.78-.228 10.099 10.099 0 0 0-3.26 3.15V4.144a.814.814 0 1 0-1.629 0v3.78A1.32 1.32 0 0 0 3.22 9.24H7a.814.814 0 0 0 0-1.628H4.762A8.482 8.482 0 0 1 16.435 4.77l.067.041.056-.055 1.026-1.027ZM12.07 9.243l.03-.03V7a.814.814 0 0 0-.815-.814h-.2a.614.614 0 0 0-.614.614v4.043l.17-.17 1.43-1.43Zm-7.314 7.314.064-.064-.058-.07a.856.856 0 0 0-1.049-.227c-.396.206-.559.702-.32 1.09.059.096.119.19.18.284l.068.102.087-.087 1.028-1.028ZM20.13 6.01l2.41-2.409a.85.85 0 0 0-1.203-1.202L2.6 21.137a.85.85 0 0 0 1.202 1.202l2.207-2.207A10.1 10.1 0 0 0 20.131 6.01Zm-6.84 6.84 5.687-5.686A8.48 8.48 0 0 1 7.17 18.97l4.967-4.968 2.474 2.473c.25.25.656.25.907 0l.122-.121a.815.815 0 0 0 0-1.153l-2.35-2.351Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnscheduled);
export default ForwardRef;
