import * as React from "react";
import { forwardRef } from "react";

const SvgArrowBarLeft = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.256 6.251a.888.888 0 0 1 1.238 0l5.25 5.143a.845.845 0 0 1 0 1.212l-5.25 5.143a.888.888 0 0 1-1.238 0 .845.845 0 0 1 0-1.212L18.888 12l-4.632-4.537a.845.845 0 0 1 0-1.212Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12c0-.552.348-1 .776-1h9.448c.428 0 .776.448.776 1s-.348 1-.776 1H8.776C8.348 13 8 12.552 8 12ZM4 3a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowBarLeft);
export default ForwardRef;
