// @ts-check

const APOSTROPHE = "'";
const FRENCH_APOSTROPHE = "’";

/**
 * @type {import('../index').Rule}
 */
export const rule = {
  chars: [APOSTROPHE],
  transform: (ctx) => {
    const { input } = ctx;
    if (input === APOSTROPHE) {
      return [{ type: "insert", value: FRENCH_APOSTROPHE }];
    }
    return null;
  },
};
