import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useField } from "react-final-form";
import { Loader } from "swash/Loader";
import { useLiveRef } from "swash/utils/useLiveRef";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import { composeValidators, mustBeFilled } from "@/services/forms/validators";

const setupHandlers = {};

const warn = (message) =>
  // eslint-disable-next-line no-console
  console.error(`"sirius.registerCustomField(id, setup)": ${message}`);

window.sirius = window.sirius || {};
window.sirius.registerCustomField = (id, setup) => {
  // Check parameters
  if (!id || (typeof id !== "string" && !Array.isArray(id))) {
    warn('"id" is not a string or an array of string');
    return;
  }
  if (typeof setup !== "function") {
    warn('"setup" is not a function');
    return;
  }

  const ids = typeof id === "string" ? [id] : id;

  ids.forEach((id) => {
    // Useful for demo script
    if (id === "*") {
      Object.keys(setupHandlers).forEach((key) => {
        window.sirius.registerCustomField(key, setup);
      });
      return;
    }

    const setupHandler = setupHandlers[id];
    // Check parameters
    if (!setupHandler) {
      warn(`unknown "id" "${id}"`);
      return;
    }
    const teardown = setupHandler(setup);
    if (teardown !== undefined && typeof teardown !== "function") {
      warn("must return undefined or a function");
    }
  });
};

function useRender(value) {
  const initialValueRef = useRef(value);
  const listenersRef = useRef([]);
  useEffect(() => {
    listenersRef.current.forEach((listener) => listener(value));
  }, [value]);
  const render = useCallback((listener) => {
    const listeners = listenersRef.current;
    listeners.push(listener);
    listener(initialValueRef.current);
    return () => {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    };
  }, []);
  return render;
}

function useThirdPartyTextField(
  name,
  {
    parse,
    format,
    formatBeforeValidate,
    orientation = "vertical",
    isEqual,
    required,
  },
) {
  const validators = [];
  if (required) {
    validators.push(mustBeFilled);
  }

  const composedValidate = validators.length
    ? composeValidators(...validators)
    : undefined;

  const validate =
    composedValidate && formatBeforeValidate
      ? (value, ...args) => composedValidate(format(value), ...args)
      : composedValidate;

  const field = useField(name, {
    parse,
    format,
    isEqual,
    validate,
  });
  return useFieldState({
    field,
    orientation,
    required,
  });
}

export function ThirdPartyTextField({
  globalId,
  name,
  parse,
  format,
  formatBeforeValidate,
  thirdPartyScript,
  label = null,
  placeholder = null,
  hint = null,
  required = false,
  disabled = false,
  isEqual,
}) {
  const field = useThirdPartyTextField(name, {
    parse,
    format,
    formatBeforeValidate,
    isEqual,
    required,
  });

  const [loading, setLoading] = useState(true);

  const { value } = field.state.field.input;
  const fieldRef = useLiveRef(field.state.field);
  const globalIdRef = useLiveRef(globalId);
  const ref = useRef();

  const renderValue = useMemo(() => ({ value }), [value]);
  const render = useRender(renderValue);
  useEffect(() => {
    const globalId = globalIdRef.current;
    const teardowns = [];
    setupHandlers[globalId] = (setup) => {
      const teardown = setup({
        id: globalId,
        label,
        placeholder,
        hint,
        required,
        disabled,
        container: ref.current,
        onChange: (event) => {
          if (!disabled) {
            fieldRef.current.input.onChange(event);
          }
        },
        render,
      });
      if (typeof teardown === "function") {
        teardowns.push(teardown);
      }
      // handler setup is done, field should be ready
      setLoading(false);
      return teardown;
    };
    return () => {
      delete setupHandlers[globalId];
      teardowns.forEach((teardown) => teardown());
    };
  }, [
    fieldRef,
    globalIdRef,
    render,
    label,
    placeholder,
    hint,
    required,
    disabled,
  ]);

  const loadingTimeoutIdRef = useRef(null);
  const loadFailedRef = useRef(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const onLoadFailed = useCallback(() => {
    loadFailedRef.current = true;
    setLoading(false);
    if (loadingTimeoutIdRef.current) {
      clearTimeout(loadingTimeoutIdRef.current);
      loadingTimeoutIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!loading) clearTimeout(loadingTimeoutIdRef.current);
    if (scriptLoaded) {
      // script is loaded, but handler has not been called yet
      loadingTimeoutIdRef.current = setTimeout(() => {
        //something probably went wrong, handler was not called after 5s
        onLoadFailed();
      }, 5 * 1000); // 5s
    }
    if (loading) {
      loadingTimeoutIdRef.current = setTimeout(() => {
        // loading is taking too long
        onLoadFailed();
      }, 30 * 1000); // 30s
    }
    return () => {
      if (loadingTimeoutIdRef.current) {
        clearTimeout(loadingTimeoutIdRef.current);
      }
    };
  }, [loading, onLoadFailed, scriptLoaded]);

  // Load third-party script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = thirdPartyScript;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = onLoadFailed;
    document.body.append(script);
    return () => {
      script.remove();
    };
  }, [onLoadFailed, thirdPartyScript]);

  const status = useMemo(() => {
    if (loading) return "loading";
    if (loadFailedRef.current) return "error";
    return "ready";
  }, [loading]);

  return (
    <FieldGroup {...field}>
      {/* third party field is not ready yet, display a placeholder */}
      {status !== "ready" && (
        <>
          <FieldLabel {...field}>{label}</FieldLabel>
          {hint && <FieldHint {...field}>{hint}</FieldHint>}
          {status === "loading" && <Loader />}
          {status === "error" && (
            <span className="font-accent text-danger-on">
              <p>Une erreur est survenue lors du chargement du script tiers.</p>
              <p>
                Veuillez réessayer ultérieurement ou contacter votre
                administrateur.
              </p>
            </span>
          )}
        </>
      )}
      <div ref={ref} className="no-reset" />
      <FieldError {...field} />
    </FieldGroup>
  );
}
