// @ts-check
import { EditorState, Modifier } from "draft-js-es";

import { convertFromHTML } from "../convert/convertFromHTML";
import { normalize } from "../convert/normalize";
import { mergePastedSiriusContent } from "../utils/mergePastedSiriusContent";

/**
 * @typedef {import('draft-js').DraftEntityMutability} DraftEntityMutability
 * @typedef {import('draft-js').EditorState} DraftEditorState
 * @typedef {import('@/services/medias/fetchArticleMedia').fetchArticleMedia} FetchArticleMedia
 * @typedef {{[key: string]: {type: string, mutability: DraftEntityMutability, data: object}}} CreateEntities
 * @typedef {import('../utils/mergePastedSiriusContent').SiriusContent} SiriusContent
 * @typedef {import("../utils/mergePastedSiriusContent").MergeBlockData}MergeBlockData
 * @typedef {{transformPastedText: function, ATOMIC_ENTITY_TYPE?: string, allowedBlockTypes: string[], allowedInlineStyles: string[], mergeBlockData?: MergeBlockData, name: string }} Plugin
 * @typedef {{allowedAttributes: object, editorId: string, editorState: DraftEditorState, setEditorState: (editorState: DraftEditorState) => void, articleId: number, name: string, fetchArticleMedia: FetchArticleMedia, multiline: boolean, plugins: Plugin[], anchorBlock: import('draft-js-es').ContentBlock | undefined}} State
 */

/**
 * @param {State} state
 * @param {string} text
 * @param {string} html
 * @param {SiriusContent | undefined} siriusContent
 * @returns
 */
export const handlePastedText = async (state, text, html, siriusContent) => {
  const { allowedAttributes } = state;
  const pluginsOptions = state.plugins.reduce(
    (acc, plugin) => ({
      activeEntityTypes: /** @type {string[]} */ (
        [...acc.activeEntityTypes, plugin.ATOMIC_ENTITY_TYPE].filter(Boolean)
      ),
      allowedBlockTypes: [
        ...acc.allowedBlockTypes,
        ...(plugin.allowedBlockTypes ?? []),
      ],
      allowedInlineStyles: [
        ...acc.allowedInlineStyles,
        ...(plugin.allowedInlineStyles ?? []),
      ],
      mergeBlockData: plugin.mergeBlockData
        ? [...acc.mergeBlockData, plugin.mergeBlockData]
        : acc.mergeBlockData,
    }),
    {
      activeEntityTypes: /** @type {string[]} */ ([]),
      allowedBlockTypes: /** @type {string[]} */ ([]),
      allowedInlineStyles: /** @type {string[]} */ ([]),
      mergeBlockData: /** @type {MergeBlockData[]} */ ([]),
    },
  );

  if (siriusContent) {
    try {
      await mergePastedSiriusContent(
        {
          editorState: state.editorState,
          setEditorState: state.setEditorState,
        },
        siriusContent,
        {
          createNewEntities: true,
          articleId: state.articleId,
          useArticleMediaType: state.name !== "post",
          fetchArticleMedia: state.fetchArticleMedia,
          ...pluginsOptions,
        },
      );
      return "handled";
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Unable to merge sirius pasted content", e);
    }
  }

  if (
    // We have a text
    text &&
    // We have no HTML (Paste and match style)
    // or we do not handle HTML
    (html === undefined || Object.keys(allowedAttributes).length === 0)
  ) {
    handlePastedRawText(state, text);
    return "handled";
  }

  // We have HTML
  if (html) {
    handlePastedHtml(state, html);
    return "handled";
  }

  return "not-handled";
};

/**
 * @param {State} state
 * @param {string} text
 */
const handlePastedRawText = (state, text) => {
  const { setEditorState, editorState, anchorBlock, multiline, plugins } =
    state;
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const normalizedText = text.normalize();
  const sanitizedText = multiline
    ? normalizedText
    : normalizedText.replace(/[\r\n\s]+/g, " ");

  const { transformedText, newContentState } = plugins.reduce(
    (state, plugin) => {
      if (plugin.transformPastedText) {
        return {
          ...state,
          ...plugin.transformPastedText(state.transformedText, {
            editorState,
            setEditorState,
            anchorBlock,
          }),
        };
      }
      return state;
    },
    { transformedText: sanitizedText, newContentState: null },
  );

  setEditorState(
    EditorState.push(
      editorState,
      newContentState ??
        Modifier.replaceText(contentState, selection, transformedText),
      "insert-characters",
    ),
  );
};

/**
 * @param {State} state
 * @param {string} html
 */
const handlePastedHtml = (state, html) => {
  const { editorState, setEditorState } = state;
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const normalizedHtml = html.normalize();
  let nextContentState = convertFromHTML(
    normalizedHtml,
    state.plugins,
    "paste",
  );

  nextContentState = Modifier.replaceWithFragment(
    contentState,
    selection,
    nextContentState.getBlockMap(),
  );

  // Adds block data to current block, otherwise data is lost
  // Draft does not handle this by default, and it seems to be a bug
  // https://github.com/facebook/draft-js/issues/1935
  const anchorBlock = contentState.getBlockForKey(selection.getAnchorKey());
  nextContentState = anchorBlock
    ? Modifier.mergeBlockData(
        nextContentState,
        nextContentState.getSelectionAfter(),
        anchorBlock.getData(),
      )
    : nextContentState;

  setEditorState(
    EditorState.push(editorState, nextContentState, "insert-fragment"),
  );
};

/**
 * @param {import('hast').Root} ast
 */
export const transformPastedHTML = (ast) => normalize(ast);
