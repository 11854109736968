import { gql } from "@apollo/client";

import {
  ArticleLayoutPricingIndicator,
  ArticleLayoutPricingIndicatorProps,
} from "@/containers/article/ArticleLayoutPricingIndicator";

export type CapsuleLayoutEditorialTypeNbCharProps = {
  article: {
    id: number;
    nbChar: number;
    editorialType: {
      id: number;
      label: string;
    };
    editorialFormat: {
      id: number;
      label: string;
    };
  } & ArticleLayoutPricingIndicatorProps["article"];
};

export const CapsuleLayoutEditorialTypeNbChar = ({
  article,
}: CapsuleLayoutEditorialTypeNbCharProps) => {
  return (
    <ArticleLayoutPricingIndicator
      article={article}
      tooltip={
        <>
          #{article.id}
          <span className="mx-2 opacity-50">|</span>
          {article.editorialFormat && <>{article.editorialFormat.label} - </>}
          {article.layout.label} - {article.editorialType.label}
          <span className="mx-2 opacity-50">|</span>
          {article.nbChar} signes
        </>
      }
    />
  );
};

CapsuleLayoutEditorialTypeNbChar.fragments = {
  article: gql`
    fragment CapsuleLayoutEditorialTypeNbChar_article on Article {
      id
      nbChar
      editorialFormat {
        id
        label
      }
      editorialType {
        id
        label
      }
      ...ArticleLayoutPricingIndicator_article
    }
    ${ArticleLayoutPricingIndicator.fragments.article}
  `,
};
