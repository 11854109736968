import { EditorState } from "draft-js-es";
import DraftOffsetKey from "draft-js/lib/DraftOffsetKey";

function getLastTextBlock(blocks) {
  return blocks.findLast((block) => block.getType() !== "atomic");
}

export const selectAllBlocks = (state) => {
  const { editorState, setEditorState } = state;

  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const blocks = contentState.getBlockMap().toArray();
  const firstBlock = blocks.at(0);
  const lastTextBlock = getLastTextBlock(blocks);
  if (!firstBlock || !lastTextBlock) return;

  const draftSelection = selectionState.merge({
    anchorKey: firstBlock.getKey(),
    anchorOffset: 0,
    focusOffset: lastTextBlock.getText().length,
    focusKey: lastTextBlock.getKey(),
    isBackward: false,
  });

  setEditorState(EditorState.forceSelection(editorState, draftSelection));

  setTimeout(() => {
    const firstOffsetKey = DraftOffsetKey.encode(firstBlock.getKey(), 0, 0);
    const lastOffsetKey = DraftOffsetKey.encode(lastTextBlock.getKey(), 0, 0);
    const firstNode = document.querySelectorAll(
      `[data-offset-key="${firstOffsetKey}"]`,
    )[0];
    const lastTextNode = document.querySelectorAll(
      `[data-offset-key="${lastOffsetKey}"]`,
    )[0];
    if (firstNode && lastTextNode) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.setBaseAndExtent(firstNode, 0, lastTextNode, 1);
    }
  });

  return "handled";
};
