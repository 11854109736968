import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import semverCompare from "semver-compare";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useGetInactivityDuration } from "@/containers/Activity";
import { APP_UUID } from "@/containers/AppUuid";
import { useRemoteConfig } from "@/containers/RemoteConfig";

import { useLockedResources } from "./lock";

// Redis key TTL is 80s
// Chrome can throttle ping to ~60-70s max
const POLL_INTERVAL_MS = 3000;
const DIFF_THRESHOLD = 30;

// number of ping errors to consider server "offline"
const DISCONNECT_PINGS_THRESHOLD = 2;

const VersionContext = createContext();
const ConnectionContext = createContext();
const SetConnectionContext = createContext();
const ServerDateContext = createContext();

const getNumericVersion = (fullVersion) => {
  if (typeof fullVersion !== "string") {
    return null;
  }
  const rx = /(\d+\.)?(\d+\.)?(\d+)/;
  const match = fullVersion.match(rx);
  if (match && match[0]) {
    return match[0];
  }
  return null;
};

export function Ping({ children }) {
  const { version: clientVersion } = useRemoteConfig();
  const [versions, setVersions] = useState({
    client: clientVersion,
    server: null,
    outdated: false,
  });

  const [connection, setConnection] = useState({
    status: "online",
    synced: true,
  });
  const [serverDate, setServerDate] = useState(null);
  const connectionRef = useLiveRef(connection);
  const pingErrorCountRef = useRef(0);
  const lockedResources = useLockedResources();
  const lockedResourcesRef = useLiveRef(lockedResources);

  const diffCounter = useRef(0);
  const previousDiff = useRef();

  const getInactivityDuration = useGetInactivityDuration();

  useEffect(() => {
    if (!clientVersion) return undefined;

    const pullServerInfos = async () => {
      const getServerInfos = async () => {
        try {
          const { data } = await axios.post("/__ping", {
            appUuid: APP_UUID,
            lockedResources: lockedResourcesRef.current,
            version: clientVersion,
            pathname: window.location.pathname,
            inactivityDuration: getInactivityDuration(),
          });

          if (pingErrorCountRef.current > 0) {
            pingErrorCountRef.current = 0;
          }

          if (connectionRef.current?.status === "offline") {
            setConnection({ status: "online", synced: false });
          }

          return data;
        } catch (error) {
          if (error.response?.status === 401) {
            window.location.replace(
              `/login?f=${encodeURIComponent(window.location.pathname)}`,
            );
          }
          pingErrorCountRef.current += 1;
          return null;
        }
      };

      const serverInfos = await getServerInfos();
      setServerDate(serverInfos?.date ?? null);

      if (pingErrorCountRef.current >= DISCONNECT_PINGS_THRESHOLD) {
        if (connectionRef.current?.status === "online") {
          setConnection({ status: "offline", synced: false });
        }
      }

      const shortClientVersion = getNumericVersion(clientVersion);
      const shortServerVersion = getNumericVersion(serverInfos?.version);
      const diff =
        shortClientVersion && shortServerVersion
          ? semverCompare(shortClientVersion, shortServerVersion)
          : 0;
      if (diff === previousDiff.current) {
        diffCounter.current += 1;
      } else {
        diffCounter.current = 0;
      }
      previousDiff.current = diff;
      if (diffCounter.current >= DIFF_THRESHOLD) {
        diffCounter.current = 0;
        setVersions({
          client: clientVersion,
          server: serverInfos?.version,
          outdated: Boolean(diff),
        });
      }
    };

    pullServerInfos();
    const id = setInterval(pullServerInfos, POLL_INTERVAL_MS);
    return () => clearInterval(id);
  }, [clientVersion, connectionRef, lockedResourcesRef, getInactivityDuration]);

  return (
    <VersionContext.Provider value={versions}>
      <ConnectionContext.Provider value={connection}>
        <SetConnectionContext.Provider value={setConnection}>
          <ServerDateContext.Provider value={serverDate}>
            {children}
          </ServerDateContext.Provider>
        </SetConnectionContext.Provider>
      </ConnectionContext.Provider>
    </VersionContext.Provider>
  );
}

export function Desync() {
  const setConnection = useSetConnection();

  useLayoutEffect(() => {
    setConnection({ status: "online", synced: false });
  });

  return null;
}

export function useVersions() {
  return useContext(VersionContext);
}

export function useConnection() {
  return useContext(ConnectionContext);
}

export function useSetConnection() {
  return useContext(SetConnectionContext);
}

export function useServerDate() {
  return useContext(ServerDateContext);
}
