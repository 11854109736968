import { gql } from "@apollo/client";
import { ComponentProps, memo } from "react";
import {
  IoChatbubbles,
  IoEasel,
  IoImages,
  IoLogoTwitter,
  IoOpenOutline,
  IoVideocam,
  TSquareOutline,
} from "swash/Icon";

import type { LayoutType } from "@/gql-types";

const layoutIcons: Record<
  LayoutType,
  React.FunctionComponent<ComponentProps<"svg">>
> = {
  article: TSquareOutline as any,
  portfolio: IoImages,
  video: IoVideocam,
  alias: IoOpenOutline,
  multimediaContent: IoEasel,
  live: IoChatbubbles,
  siriusLive: IoChatbubbles,
  social: IoLogoTwitter,
  infographic: IoEasel,
};

const getLayoutIcon = (layoutType: LayoutType) => {
  return (
    layoutIcons[layoutType] ||
    (layoutIcons["article"] as React.FunctionComponent<ComponentProps<"svg">>)
  );
};

export type ArticleLayoutIconProps = {
  article: {
    layout: {
      id: number;
      type: LayoutType;
      label: string;
    };
  };
} & { className?: string };

type SpreadArticleLayoutIconProps = ArticleLayoutIconProps["article"] &
  ComponentProps<"svg">;

export const SpreadArticleLayoutIcon = memo<SpreadArticleLayoutIconProps>(
  ({ layout, ...props }) => {
    const Icon = getLayoutIcon(layout.type);
    return <Icon {...props} />;
  },
);

export const ArticleLayoutIcon = ({
  article,
  ...props
}: ArticleLayoutIconProps) => {
  return <SpreadArticleLayoutIcon layout={article.layout} {...props} />;
};

ArticleLayoutIcon.fragments = {
  article: gql`
    fragment ArticleLayoutIcon_article on Article {
      layout {
        id
        type
      }
    }
  `,
};
