import { gql } from "@apollo/client";

import { CapsuleArticle as ArticleBaseCapsule } from "@/containers/article/capsules/CapsuleArticle";
import { CapsuleRemoveButton } from "@/containers/article/capsules/CapsuleButtons";
import { CapsuleToolbar } from "@/containers/article/capsules/CapsuleToolbar";

import { ResourceDraggableField } from "../article/fields/ResourceDraggableField";

const ArticleCapsule = ({ article, onRemove, disabled }) => {
  const toolbar = onRemove ? (
    <CapsuleToolbar>
      <CapsuleRemoveButton onClick={onRemove} disabled={disabled} />
    </CapsuleToolbar>
  ) : null;
  return <ArticleBaseCapsule article={article} toolbar={toolbar} />;
};

const ArticleQuery = gql`
  query RelatedArticlesSelector_articles(
    $offset: Int
    $limit: Int
    $filters: ArticleWhere
  ) {
    connection: articles(offset: $offset, limit: $limit, where: $filters) {
      pageInfo {
        hasMore
      }
      nodes {
        id
        ...ArticleCapsule_article
      }
    }
  }

  ${ArticleBaseCapsule.fragments.article}
`;
export const RelatedArticleSelector = ({
  value,
  placeholder,
  name,
  label,
  onChange,
  limit,
  multi,
  disabled,
}) => {
  return (
    <ResourceDraggableField
      value={value}
      name={name}
      label={label}
      placeholder={placeholder || "Ajouter un article"}
      onChange={onChange}
      disabled={disabled}
      query={ArticleQuery}
      model="Article"
      labelSelector={({ item, onRemove, disabled, dragIndex }) => (
        <ArticleCapsule
          article={item}
          onRemove={() => onRemove(dragIndex)}
          disabled={disabled}
        />
      )}
      labelElementSelector={(item) => <ArticleCapsule article={item} />}
      multi={multi}
      limit={limit}
      fragment={ArticleBaseCapsule.fragments.article}
      searchVariables={{
        status: { eq: "published" },
      }}
    />
  );
};
