import { getIn } from "final-form";
import * as React from "react";

export { FORM_ERROR } from "final-form";

const findInput = (elements, errors) => {
  const element = elements.find((element) => {
    const name = element.name || element.dataset.name;
    if (!name) return false;
    return getIn(errors, name);
  });
  if (!element) return null;
  if (element.dataset.controlContainer !== undefined) {
    return element.querySelector("[role=textbox]");
  }
  return element;
};

const isFocusable = (element) => {
  if (!element) return false;
  if (element.dataset.controlContainer !== undefined) return true;
  return typeof element.focus === "function";
};

const getFormInputs = (form) => {
  return [
    ...form.querySelectorAll("[name]"),
    ...form.querySelectorAll("[data-name]"),
  ].filter(isFocusable);
};

function getFormInputsForName(name) {
  return () => {
    const form =
      document.forms[name] ??
      document.querySelector(`[role=form][name="${name}"]`);
    return form ? getFormInputs(form) : [];
  };
}

function getAllInputs() {
  return [
    ...document.forms,
    ...document.querySelectorAll("[role=form]"),
  ].reduce((inputs, form) => [...inputs, ...getFormInputs(form)], []);
}

const noop = () => {};

const createFormFocusDecorator = (getInputs) => (form) => {
  const focusOnFirstError = (errors) => {
    const input = findInput(getInputs(), errors);
    if (input) {
      input.focus();
    }
  };
  // Save original submit function
  const originalSubmit = form.submit;

  // Subscribe to errors, and keep a local copy of them
  let state = {};
  const unsubscribe = form.subscribe(
    (nextState) => {
      state = nextState;
    },
    { errors: true, submitErrors: true },
  );

  // What to do after submit
  const afterSubmit = () => {
    const { errors, submitErrors } = state;
    if (errors && Object.keys(errors).length) {
      focusOnFirstError(errors);
    } else if (submitErrors && Object.keys(submitErrors).length) {
      focusOnFirstError(submitErrors);
    }
  };

  // Rewrite submit function
  // By default no argument is required but we add one to disable the focus-on-error decorator
  form.submit = ({ focusOnError } = {}) => {
    const result = originalSubmit.call(form);
    if (focusOnError === false) return result;
    if (result && typeof result.then === "function") {
      // async
      result.then(afterSubmit, noop);
    } else {
      // sync
      afterSubmit();
    }
    return result;
  };

  return () => {
    unsubscribe();
    form.submit = originalSubmit;
  };
};

export function useFocusOnErrorDecorator(name) {
  return React.useMemo(
    () =>
      createFormFocusDecorator(
        name ? getFormInputsForName(name) : getAllInputs,
        findInput,
      ),
    [name],
  );
}

export function useFocusErrorField(ref) {
  return React.useCallback(
    (errors) => {
      const firstInput = findInput(
        [...ref.current.querySelectorAll("[name]")],
        errors,
      );
      if (firstInput) {
        firstInput.focus();
      }
    },
    [ref],
  );
}

export function useFocusFirstField(ref) {
  return React.useCallback(() => {
    const firstInput = ref.current.querySelector("[name]");
    if (firstInput) {
      firstInput.focus();
    }
  }, [ref]);
}
