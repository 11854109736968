import { gql } from "@apollo/client";
import { memo } from "react";

import { useSafeQuery } from "@/containers/Apollo";

import { ArticleEventDispatcherSection } from "./ArticleEventDispatcherSection";

const EventDispatchersQuery = gql`
  query EventDispatchers_article {
    eventDispatchers(where: { resource: { eq: article }, enabled: true }) {
      nodes {
        id
        key
        ...ArticleEventDispatcherSection_eventDispatchers
      }
    }
  }

  ${ArticleEventDispatcherSection.fragments.eventDispatcher}
`;

export const ArticleEventDispatchers = memo(({ articleId }) => {
  const { data } = useSafeQuery(EventDispatchersQuery);

  if (!data) {
    return null;
  }

  const eventDispatchers = data.eventDispatchers.nodes ?? [];

  return eventDispatchers.map((eventDispatcher) => {
    return (
      <ArticleEventDispatcherSection
        key={eventDispatcher.key}
        articleId={articleId}
        eventDispatcher={eventDispatcher}
      />
    );
  });
});
