// no localStorage in e2e headless tests -> hack a fake localStorage
const abstractSetItem = (key, value) => {
  if (localStorage) return localStorage.setItem(key, value);
};

const abstractGetItem = (key) => {
  if (localStorage) return localStorage.getItem(key);
};

export const setItem = (key, value) =>
  abstractSetItem(key, JSON.stringify(value));

export const getItem = (key) => {
  try {
    return JSON.parse(abstractGetItem(key));
  } catch {
    return null;
  }
};

export const setItemList = ({ key, value }) => {
  Object.keys(value).forEach((subKey) => {
    const keyName = `${key}.${subKey}`;
    abstractSetItem(keyName, JSON.stringify(value[subKey]));
  });
};
