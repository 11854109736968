import { KeyBindingUtil } from "draft-js-es";
import { memo } from "react";

import { Erase } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { handleBinding } from "../utils";
import { command } from "./remove-style";

const SHORTCUT = KeyBindingUtil.usesMacOSHeuristics() ? ["⌘", "/"] : ["⌘", ":"];

export const shortcuts = [
  {
    description: "Supprimer la mise en forme",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "remove-style-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const RemoveStyleToolbarButton = memo(() => {
  return (
    <RichEditorToolbarButton
      command={command}
      label="Supprimer la mise en forme"
      shortcut={SHORTCUT}
    >
      <Erase />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = () => {
  return <RemoveStyleToolbarButton />;
};

BlockControls.group = "inline-style";
