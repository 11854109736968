import { gql } from "@apollo/client";

import type { ArticlePublishDateTimeStatus_articleFragment } from "@/gql-types";

import { ArticlePublishDate } from "./ArticlePublishDate";
import { ArticlePublishStatus } from "./ArticlePublishStatus";
import { ArticlePublishTime } from "./ArticlePublishTime";

export type ArticlePublishDateTimeStatusProps = {
  article: ArticlePublishDateTimeStatus_articleFragment;
};

export const ArticlePublishDateTimeStatus = ({
  article,
}: ArticlePublishDateTimeStatusProps) => {
  return (
    <div className="flex items-center gap-1">
      <ArticlePublishStatus article={article} size={12} />
      <ArticlePublishDate article={article} />
      {article.initialFirstPublished && (
        <>
          <span>|</span>
          <ArticlePublishTime article={article} />
        </>
      )}
    </div>
  );
};

ArticlePublishDateTimeStatus.fragments = {
  article: gql`
    fragment ArticlePublishDateTimeStatus_article on Article {
      initialFirstPublished
      ...ArticlePublishDate_article
      ...ArticlePublishStatus_article
      ...ArticlePublishTime_article
    }

    ${ArticlePublishStatus.fragments.article}
    ${ArticlePublishDate.fragments.article}
    ${ArticlePublishTime.fragments.article}
  `,
};
