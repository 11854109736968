import { useLiveRef } from "./useLiveRef";
import { useRefHandler } from "./useRefHandler";

/**
 * Listen resize of an element.
 */
export function useResizeObserver(
  callback: (entry: ResizeObserverEntry) => void,
  ref?: (element: HTMLElement | null) => void,
) {
  const callbackRef = useLiveRef(callback);
  const handler = useRefHandler<HTMLElement | null>((element) => {
    if (!element) return undefined;
    if (ref) ref(element);
    let req: number;
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        req = window.requestAnimationFrame(() => {
          callbackRef.current(entry);
        });
      }
    });
    observer.observe(element);
    return () => {
      observer.disconnect();
      if (req) {
        window.cancelAnimationFrame(req);
      }
    };
  });

  return handler;
}
