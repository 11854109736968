import { useRef } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

const FormSubmitterContext =
  createSafeContext<React.MutableRefObject<HTMLElement | undefined>>();

export const FormSubmitterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const submitterRef = useRef<HTMLElement>();
  return (
    <FormSubmitterContext.Provider value={submitterRef}>
      {children}
    </FormSubmitterContext.Provider>
  );
};

export const useSubmitterRef = FormSubmitterContext.makeSafeHook(
  "useSubmitterRef",
  "FormSubmitterProvider",
);
