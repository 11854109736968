// @ts-check
import { visit } from "unist-util-visit";

/**
 * Ignore styles.
 * @param {string} style
 * @returns {boolean}
 */
const ignoreStyle = (style) => {
  // Ignore specific Word styles
  return style.startsWith("mso-");
};

/**
 * Skip unstyled nodes that have to specific meanings.
 * @param {import('hast').Root} root
 * @returns {import('hast').Root}
 */
export const flatten = (root) => {
  visit(root, { type: "element", tagName: "span" }, (node, index, parent) => {
    if (!parent || index == null) return;

    if (!node.properties) {
      parent.children.splice(index, 1, ...node.children);
      return;
    }

    const countProperties = Object.keys(node.properties).length;
    if (countProperties === 0) {
      parent.children.splice(index, 1, ...node.children);
      return;
    }

    if (countProperties === 1 && node.properties["style"]) {
      const style = String(node.properties["style"]);
      const styles = style.split(";");
      const relevantStyles = styles.filter((style) => !ignoreStyle(style));
      if (relevantStyles.length === 0) {
        parent.children.splice(index, 1, ...node.children);
        return;
      }
    }
  });

  return root;
};
