import * as React from "react";
import { forwardRef } from "react";

const SvgChevronDoubleForward = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33 4.455c.439-.44 1.151-.44 1.59 0l6.75 6.75c.44.439.44 1.151 0 1.59l-6.75 6.75a1.125 1.125 0 0 1-1.59-1.59L10.284 12 4.329 6.045a1.125 1.125 0 0 1 0-1.59Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.33 4.455c.439-.44 1.151-.44 1.59 0l6.75 6.75c.44.439.44 1.151 0 1.59l-6.75 6.75a1.125 1.125 0 0 1-1.59-1.59L18.284 12 12.33 6.045a1.125 1.125 0 0 1 0-1.59Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDoubleForward);
export default ForwardRef;
