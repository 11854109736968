import { EditorState, Modifier, RichUtils } from "draft-js-es";
import { fromJS } from "immutable-es";

/**
 * Set block type and style metadata.
 * @param {EditorState} editorState
 * @param {string} blockType
 * @param {string|null} blockStyleName
 */
export function setBlockTypeAndStyle(
  editorState,
  blockType,
  blockStyleName = null,
) {
  let selection = editorState.getSelection();
  const content = editorState.getCurrentContent();

  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();

  const endKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();

  // Fixes select on click that applies style to next block
  if (startKey !== endKey && startOffset === 0 && endOffset === 0) {
    const currentContentBlock = content.getBlockForKey(startKey);
    selection = selection.merge({
      anchorKey: startKey,
      anchorOffset: 0,
      focusKey: startKey,
      focusOffset: currentContentBlock.getLength(),
    });
  }

  const hasAtomicBlock = content
    .getBlockMap()
    .skipWhile((_, k) => k !== startKey)
    .reverse()
    .skipWhile((_, k) => k !== endKey)
    .some((v) => v.getType() === "atomic");

  if (hasAtomicBlock) {
    return editorState;
  }

  const contentWithType = blockStyleName
    ? Modifier.setBlockType(content, selection, blockType)
    : RichUtils.toggleBlockType(editorState, blockType).getCurrentContent();

  const newContent = Modifier.mergeBlockData(
    contentWithType,
    selection,
    fromJS({ styleName: blockStyleName }),
  );

  return EditorState.push(editorState, newContent, "change-block-data");
}
