const placeholders = {
  type: "Choisir le type de ces images",
  credit: "Saisissez la source de ces images",
  expiration: "Saisissez la date d'expiration de ces images",
  shootingDate: "Saisissez la date de prise de vue de ces images",
  city: "Saisissez le lieu de prise de vue de ces images",
  photographer: "Saisissez le créateur de ces images",
  keywords: "Saisissez les mots-clés de ces images",
  usageLimit: "Saisissez la limite d'utilisation de ces images",
};

export const renderPlaceholder = (images, field) => {
  const same = images.every((image) => image[field] === images[0][field]);
  if (same) return placeholders[field];
  return "Valeurs multiples";
};
