import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { Alert } from "swash/Alert";
import { Button } from "swash/Button";
import { IoShieldCheckmark } from "swash/Icon";
import { PageLoader } from "swash/Loader";

import { TwoFactorField } from "@/components/fields/TwoFactorField";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";

import { Step } from "./TwoFactorSteps";

function AuthenticatorValidationFormBody({ visible, onBack: handleBack }) {
  const form = useForm();
  const {
    dirtySinceLastSubmit,
    submitting,
    submitError,
    submitErrors,
    submitSucceeded,
  } = useFormState({
    subscription: {
      values: true,
      dirtySinceLastSubmit: true,
      submitting: true,
      submitError: true,
      submitErrors: true,
      submitSucceeded: true,
    },
  });

  return (
    <>
      <div className="my-2">
        Renseignez le code à usage unique généré dans votre application.
      </div>
      {submitErrors && !dirtySinceLastSubmit ? (
        <Alert level="danger" className="mt-2">
          {submitError || submitErrors.code}
        </Alert>
      ) : null}
      <div className="-ml-6 flex flex-col items-center gap-3">
        {visible && (
          <TwoFactorField
            name="code"
            disabled={submitting || submitSucceeded}
            valid={submitSucceeded}
          />
        )}
      </div>
      <div className="flex justify-end gap-2 pt-6">
        <Button
          type="button"
          variant="secondary"
          appearance="text"
          onClick={() => {
            handleBack();
            setTimeout(() => {
              form.reset();
            }, 800);
          }}
        >
          Précédent
        </Button>
        <Button type="button" disabled>
          Suivant
        </Button>
      </div>
    </>
  );
}

function formatSecret(secret) {
  return secret
    .match(/.{1,4}/g)
    .join(" ")
    .toLowerCase();
}

const defaultState = {
  secret: null,
  qrcode: null,
  otpauth: null,
  generatingQrCode: false,
  step: 1,
};

export function TwoFactorAuthenticatorSteps({
  visible,
  cancel,
  finish,
  showPhoneNumberDialog,
  generateAuthenticatorURL,
  validateAuthenticator,
}) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!visible) {
      setState(defaultState);
      return;
    }

    if (visible && !state.generatingQrCode) {
      setState({ ...state, generatingQrCode: true });
      (async () => {
        const data = await generateAuthenticatorURL();
        setState({ ...state, generatingQrCode: true, ...data });
      })();
    }
  }, [visible, state, generateAuthenticatorURL]);

  return (
    <>
      <Step
        num="1"
        label="Téléchargement d’une application d’authentification"
        disabled={state.step !== 1}
      >
        <div className="py-2">
          Téléchargez et installez sur votre téléphone une application
          d’authentification comme « Authy » ou « Google Authenticator ».
        </div>
        <div className="flex items-center justify-end gap-2 pt-6">
          {showPhoneNumberDialog ? (
            <Button
              scale="sm"
              appearance="text"
              onClick={() => showPhoneNumberDialog()}
              color="dusk-lightest"
            >
              Utiliser un numéro de téléphone
            </Button>
          ) : null}
          {cancel ? (
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={cancel}
            >
              Annuler
            </Button>
          ) : null}
          <Button type="button" onClick={() => setState({ ...state, step: 2 })}>
            Suivant
          </Button>
        </div>
      </Step>
      <Step
        num="2"
        label="Scan du QR code depuis l’application"
        disabled={state.step !== 2}
      >
        <>
          <div
            className="mt-2 flex items-center"
            style={{ minHeight: "224px" }}
          >
            {state.qrcode ? (
              <div className="flex gap-4">
                <img
                  alt={state.otpauth}
                  src={state.qrcode}
                  width={176}
                  height={176}
                />
                <div className="mt-4 px-4">
                  Si vous n’avez pas de caméra, vous pouvez saisir manuellement
                  le code suivant dans votre application :
                  <div className="mt-4 text-center text-lg font-semibold tracking-widest text-grey-on-strong">
                    {formatSecret(state.secret)}
                  </div>
                </div>
              </div>
            ) : (
              <PageLoader />
            )}
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={() => setState({ ...state, step: 1 })}
            >
              Précédent
            </Button>
            <Button
              type="button"
              onClick={() => setState({ ...state, step: 3 })}
            >
              Suivant
            </Button>
          </div>
        </>
      </Step>
      <Step
        num="3"
        label="Validation de l’appareil"
        disabled={state.step !== 3}
      >
        {visible && (
          <Form
            initialValues={{ code: "" }}
            onSubmit={async ({ code }) => {
              const result = await validateAuthenticator({ code });
              if (result?.error) return result.error;

              setTimeout(() => {
                setState({ ...state, step: 4 });
              }, 800);
            }}
          >
            <FormAutoSubmit />
            <AuthenticatorValidationFormBody
              visible={state.step === 3}
              onBack={() => {
                setState({ ...state, step: 2 });
              }}
            />
          </Form>
        )}
      </Step>
      <Step
        icon={
          <IoShieldCheckmark className="inline-block h-6 w-6 shrink-0 text-success-on" />
        }
        label="Merci, vous avez terminé la sécurisation de votre compte"
        disabled={state.step !== 4}
        hidden
      >
        <div className="py-2">
          Une application est bien associée à votre compte.
        </div>
        <div className="flex flex-col items-end pt-6">
          <Button type="button" onClick={finish}>
            Terminer
          </Button>
        </div>
      </Step>
    </>
  );
}
