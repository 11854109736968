import { gql } from "@apollo/client";
import { useEffect } from "react";

import { useDragUrl } from "@/containers/Dnd";
import type { Interaction } from "@/containers/list/ListState";
import { useListRowRef } from "@/containers/list/NudeList";

import { getArticleUrl } from "../../ArticleLink";

export type DraggableArticle = {
  id: number;
};

export const draggable: Interaction<DraggableArticle> = {
  key: "draggable",
  fragment: gql`
    fragment draggableInteraction_article on Article {
      id
    }
  `,
  useInteraction: ({ node }) => {
    const listRowRef = useListRowRef();
    const [, connectDragRef, connectDragPreview] = useDragUrl(() =>
      getArticleUrl(node.id, true),
    );
    useEffect(() => {
      if (listRowRef?.current) {
        connectDragRef(listRowRef.current);
        connectDragPreview(listRowRef.current);
      }
    }, [listRowRef, connectDragRef, connectDragPreview]);
  },
};
