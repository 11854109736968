const LISTENERS_MAP = {};

const callListeners =
  (name) =>
  (...args) => {
    Object.values(LISTENERS_MAP).forEach((listeners) => {
      const listener = listeners[name];
      if (listener) {
        listener(...args);
      }
    });
  };

const dndManager = {
  addListener: (id, listeners) => {
    LISTENERS_MAP[id] = listeners;
  },
  removeListener: (id) => {
    delete LISTENERS_MAP[id];
  },
  onDragStart: callListeners("onDragStart"),
  onDragUpdate: callListeners("onDragUpdate"),
  onDragEnd: callListeners("onDragEnd"),
  getListeners: () => LISTENERS_MAP,
};

export default dndManager;
