import { gql } from "@apollo/client";
import { Fragment } from "react";

import { UserAvatar, UserAvatarUser } from "@/containers/user/UserAvatar";

export type UserCardUser = UserAvatarUser & {
  fullName: string;
  email: string;
  currentRole?: {
    id: number;
    name: string;
  };
  currentSection?: {
    id: number;
    name: string;
  };
};

type UserCardProps = {
  user: UserCardUser;
};

export const UserCard = ({ user }: UserCardProps) => {
  const roleService = [
    user.currentRole?.name,
    user.currentSection?.name,
  ].filter(Boolean);
  return (
    <div
      className="flex flex-col items-center gap-1 rounded border border-grey-border-light bg-white p-4 text-dusk-on drop-shadow-md"
      style={{ minWidth: 224 }}
    >
      <UserAvatar className="mb-1" user={user} size={42} />
      <div className="font-accent font-semibold">{user.fullName}</div>
      <div className="text-sm text-grey-on">{user.email}</div>
      <div className="mt-2 text-sm font-semibold">
        {roleService.map((name, index) => (
          <Fragment key={name}>
            {index > 0 && <span className="text-grey-on"> | </span>}
            {name}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

UserCard.fragments = {
  user: gql`
    fragment UserCard_user on User {
      fullName
      email
      currentRole {
        id
        name
      }
      currentSection {
        id
        name
      }
      ...UserAvatar_user
    }

    ${UserAvatar.fragments.user}
  `,
};
