import { gql } from "@apollo/client";
import { useMemo, useState } from "react";

import { useSafeMutation } from "@/containers/Apollo";
import { createSafeContext } from "@/services/hooks/useSafeContext";

// TODO: Type properly
type PinnedRevision = any;

const ArticleRevisionContext = createSafeContext<{
  articleRevisionId: number | null;
  setArticleRevisionId: React.Dispatch<React.SetStateAction<number | null>>;
}>();
const PinnedRevisionContext = createSafeContext<PinnedRevision | null>();
const SetPinnedRevisionContext =
  createSafeContext<(value: PinnedRevision | null) => void>();

export const ArticleRevisionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Uses 0 for the current version
  const [articleRevisionId, setArticleRevisionId] = useState<number | null>(0);
  const [pinnedRevision, setPinnedRevision] = useState<PinnedRevision | null>(
    null,
  );

  const value = useMemo(
    () => ({
      articleRevisionId,
      setArticleRevisionId,
    }),
    [articleRevisionId],
  );
  return (
    <ArticleRevisionContext.Provider value={value}>
      <PinnedRevisionContext.Provider value={pinnedRevision}>
        <SetPinnedRevisionContext.Provider value={setPinnedRevision}>
          {children}
        </SetPinnedRevisionContext.Provider>
      </PinnedRevisionContext.Provider>
    </ArticleRevisionContext.Provider>
  );
};

const useArticleRevisionContext = ArticleRevisionContext.makeSafeHook(
  "useArticleRevisionContext",
  "ArticleRevisionProvider",
);

export const usePinnedRevision = PinnedRevisionContext.makeSafeHook(
  "usePinnedRevision",
  "ArticleRevisionProvider",
);

export const useSetPinnedRevision = SetPinnedRevisionContext.makeSafeHook(
  "useSetPinnedRevision",
  "ArticleRevisionProvider",
);

export const usePinnedRevisionId = (): number | null => {
  const pinnedRevision = usePinnedRevision();
  return pinnedRevision?.id;
};

export const useArticleRevisionId = (): number | null => {
  return useArticleRevisionContext().articleRevisionId;
};

export const useSetArticleRevisionId = () => {
  return useArticleRevisionContext().setArticleRevisionId;
};

export const useShowRevision = (): boolean => {
  const articleRevisionId = useArticleRevisionId();
  const pinnedRevisionId = usePinnedRevisionId();

  return Boolean(articleRevisionId && pinnedRevisionId !== 0);
};

const RestoreMutation = gql`
  mutation RestoreArticleRevisionMutation(
    $input: RestoreArticleRevisionInput!
  ) {
    restoreArticleRevision(input: $input) {
      id
    }
  }
`;

// TODO: Type properly
const RestoreArticleRevisionContext =
  createSafeContext<(options?: any) => Promise<any>>();
const RestoringArticleRevisionContext = createSafeContext<boolean>();

export const RestoringArticleRevisionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [restoreArticleRevision, { loading: restoring }] =
    useSafeMutation(RestoreMutation);

  return (
    <RestoreArticleRevisionContext.Provider value={restoreArticleRevision}>
      <RestoringArticleRevisionContext.Provider value={restoring}>
        {children}
      </RestoringArticleRevisionContext.Provider>
    </RestoreArticleRevisionContext.Provider>
  );
};

export const useRestoreArticleRevision =
  RestoreArticleRevisionContext.makeSafeHook(
    "useRestoreArticleRevision",
    "RestoringArticleRevisionProvider",
  );

export const useRestoringArticleRevision =
  RestoringArticleRevisionContext.makeSafeHook(
    "useRestoringArticleRevision",
    "RestoringArticleRevisionProvider",
  );
