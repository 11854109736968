import { gql } from "@apollo/client";

import { useDragUrl } from "@/containers/Dnd";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";

import { MediaLayout } from "./MediaLayout";
import { getMediaEventTooltip } from "./util";

export function MediaVideo({
  media: { media: video, ...mediaProps },
  draggable,
  onDelete,
  deletable,
  expanded,
  dropping,
  ...props
}) {
  const [, dragRef] = useDragUrl(video.url);

  return (
    <MediaLayout
      draggable={draggable}
      ref={draggable ? dragRef : null}
      deletable={deletable}
      onDelete={() => onDelete(video.id)}
      expanded={expanded}
      {...props}
    >
      <VideoNode
        video={video}
        expanded={expanded}
        dropping={dropping}
        tooltip={getMediaEventTooltip(mediaProps)}
      />
    </MediaLayout>
  );
}

MediaVideo.fragments = {
  media: gql`
    fragment MediaVideo_media on Media {
      id
      ...VideoNode_video
    }
    ${VideoNode.fragments.video}
  `,
};
