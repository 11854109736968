import { smartToggleInlineStyle } from "../modifiers/smartToggleInlineStyle";
import { INLINE_STYLE } from "./underline-convert";

export * from "./underline-convert";

export const name = "underline";
export const command = "underline";

export const handleKeyCommand = ({ setEditorState, editorState }, cmd) => {
  if (cmd === command) {
    setEditorState(smartToggleInlineStyle(editorState, INLINE_STYLE));
    return true;
  }
  return undefined;
};
