import { gql } from "@apollo/client";
import { cn } from "swash/utils/classNames";
import { useMediaQuery } from "swash/utils/useMediaQuery";

import { mediaQueries } from "@/config/mediaQueries";
import { useListReadOnly } from "@/containers/list/List";
import type { CellType } from "@/containers/list/ListState";

export type SignaturesCellArticle = {
  signature: string | null;
};

export const signaturesCell: CellType<SignaturesCellArticle> = {
  key: "signatures",
  title: "Signataires",
  width: 120,
  fragment: gql`
    fragment signaturesCell_article on Article {
      signature
    }
  `,
  useIsVisible: () => {
    const matchesMediaQuery = useMediaQuery(mediaQueries.desktop.list.md);
    const listReadOnly = useListReadOnly();
    return !matchesMediaQuery || listReadOnly;
  },
  render: ({ node }) => (
    <div
      className={cn("select-none text-sm", !node.signature && "text-grey-on")}
    >
      {node.signature || "Aucun"}
    </div>
  ),
};
