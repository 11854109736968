import { type TypedDocumentNode, gql } from "@apollo/client";
import { getCurrentScope as getCurrentSentryScope } from "@sentry/browser";
import { createContext, useContext, useEffect } from "react";

import type {
  MeQueryQuery,
  MeQueryQueryVariables,
  UserLevel,
} from "@/gql-types";

import { useAmplitude } from "./Amplitude";
import { useSafeQuery } from "./Apollo";
import { UserAvatar } from "./user/UserAvatar";

type UserLevelData = {
  name: UserLevel;
  label: string;
};

export const USER_LEVELS: UserLevelData[] = [
  { name: "user", label: "Utilisateur" },
  { name: "manager", label: "Manager" },
  { name: "developer", label: "Développeur" },
  { name: "admin", label: "Administrateur" },
];

export type User = NonNullable<MeQueryQuery["me"]>;

type CheckMethod = "every" | "some";

const UserContext = createContext<User | null>(null);

const GET_USER: TypedDocumentNode<MeQueryQuery, MeQueryQueryVariables> = gql`
  query MeQuery {
    me {
      id
      createdAt
      globalId
      permissions
      firstName
      firstNameInitials
      lastName
      fullName
      email
      level
      signature {
        id
        longName
      }
      isSuperAdmin
      currentRole {
        id
        name
      }
      roles {
        id
        name
      }
      sections {
        id
        name
      }
      currentSection {
        id
        name
      }
      experimentalFeatures {
        feature {
          name
        }
      }
      notificationPermissionRequestedAt
      slackId
      settings {
        openLinkInNewTab
        articleMediaDisplayRead
        articleMediaDisplayWrite
        articleTypographySerif
        articleDeviceDisplay
      }
      preferences
      selectableColumns
      ...UserAvatar_user
    }
  }

  ${UserAvatar.fragments.user}
`;

export const UserInitializer: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { initUser: initAmplitudeUser } = useAmplitude();
  const { loading, data } = useSafeQuery(GET_USER);
  const me = data ? data.me : null;
  useEffect(() => {
    if (me) {
      getCurrentSentryScope().setUser({
        id: me.globalId,
        permissions: me.permissions,
        firstName: me.firstName,
        lastName: me.lastName,
        email: me.email,
      });
      if (initAmplitudeUser) initAmplitudeUser(me);
    }
  }, [me, initAmplitudeUser]);
  if (loading) return null;
  if (!me) throw new Error("No user loaded");
  return <UserContext.Provider value={me}>{children}</UserContext.Provider>;
};

export function useUser(): User {
  const user = useContext(UserContext);
  if (!user) throw new Error("No user loaded");
  return user;
}

export function useUserPermissions(): string[] {
  const user = useUser();
  return user ? user.permissions : [];
}

/**
 * Check if the user has a permission.
 */
const checkUserHasPermission =
  (user: User) =>
  (name: string): boolean =>
    user.permissions.includes(name);

/**
 * Check if the user has a permission.
 */
export const checkUserHasPermissions = (
  user: User,
  permissions: string[],
  {
    method = "every" as CheckMethod,
    checkFunction = checkUserHasPermission,
  } = {},
): boolean => {
  if (permissions.length === 0) return true;

  switch (method) {
    case "every":
      return permissions.every(checkFunction(user));
    case "some":
      return permissions.some(checkFunction(user));
    default:
      return false;
  }
};

/**
 * Check if the user has a permission
 */
export function useHasPermission(
  permission: string | string[],
  { method = "every" as CheckMethod } = {},
): boolean {
  const user = useUser();
  const permissionsArray =
    typeof permission === "string" ? [permission] : permission;
  return checkUserHasPermissions(user, permissionsArray, {
    method,
  });
}

type HasPermissionProps = {
  permission: string | string[];
  method?: CheckMethod;
  children?: React.ReactNode;
};

export const HasPermission: React.FC<HasPermissionProps> = ({
  permission,
  method = "every",
  children,
}) => {
  const hasPermission = useHasPermission(permission, { method });
  return hasPermission ? <>{children}</> : null;
};

const checkUserHasExperimentalFeature =
  (user: User) =>
  (name: string): boolean =>
    user.experimentalFeatures.some(
      (experimentalFeature) => experimentalFeature.feature.name === name,
    );

/**
 * Check if the user has an experimental feature activated.
 */
export function useHasExperimentalFeature(
  feature: string | string[],
  { method = "every" as CheckMethod } = {},
): boolean {
  const user = useUser();
  if (feature === undefined) return true;
  const featureArray = typeof feature === "string" ? [feature] : feature;
  switch (method) {
    case "every":
      return featureArray.every(checkUserHasExperimentalFeature(user));
    case "some":
      return featureArray.some(checkUserHasExperimentalFeature(user));
    default:
      return false;
  }
}

type HasExperimentalFeatureProps = {
  feature: string | string[];
  method?: CheckMethod;
  children?: React.ReactNode;
};

export const HasExperimentalFeature: React.FC<HasExperimentalFeatureProps> = ({
  feature,
  method,
  children,
}) => {
  const hasFeature = useHasExperimentalFeature(feature, { method });
  return hasFeature ? <>{children}</> : null;
};

const getLevelIndex = (levelName: UserLevel): number =>
  USER_LEVELS.findIndex(({ name }) => name === levelName);

/**
 * Check if the user has a level access
 */
export function checkHasLevelAccess(user: User, level: UserLevel): boolean {
  return getLevelIndex(user.level) >= getLevelIndex(level);
}

/**
 * Check if the user has a level access
 */
export function useHasLevelAccess(level: UserLevel | null): boolean {
  const user = useUser();
  if (level === null) return true;
  return checkHasLevelAccess(user, level);
}

type HasLevelAccessProps = {
  level: UserLevel | null;
  children?: React.ReactNode;
};

export const HasLevelAccess: React.FC<HasLevelAccessProps> = ({
  level,
  children,
}) => {
  const hasLevelAccess = useHasLevelAccess(level);
  return hasLevelAccess ? <>{children}</> : null;
};

type HasPermissionOrLevelAccessProp = HasPermissionProps & HasLevelAccessProps;

export const HasPermissionOrLevelAccess: React.FC<
  HasPermissionOrLevelAccessProp
> = ({ permission, method = "every", level, children }) => {
  const hasPermission = useHasPermission(permission, { method });
  const hasLevelAccess = useHasLevelAccess(level);

  return hasLevelAccess || hasPermission ? <>{children}</> : null;
};
