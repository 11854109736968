import * as React from "react";
import { forwardRef } from "react";

const SvgPushpinInclined = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m19.485 9.965-4.096 4.095a1 1 0 0 0-.292.707v2.415l-.915.914-2.912-2.913a.881.881 0 0 0-1.246 0l-4.681 4.68a.5.5 0 0 1-.707-.706l4.68-4.68a.88.88 0 0 0 0-1.247l-2.912-2.912.914-.915h2.416a1 1 0 0 0 .707-.292l4.095-4.096.354-.354a1 1 0 0 1 1.414 0l3.535 3.536a1 1 0 0 1 0 1.414l-.354.354Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPushpinInclined);
export default ForwardRef;
