import { EditorState, Modifier } from "draft-js-es";

import { setContent } from "./setContent";

/**
 * Remove inline style.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} inlineStyle
 */
export const removeInlineStyle = (editorState, inlineStyle) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  // If the style is already present for the selection range, remove it.
  // Otherwise, apply it.
  if (currentStyle.has(inlineStyle)) {
    // Style override is used to setup style for the next typed character
    const withoutStyleOverride = EditorState.setInlineStyleOverride(
      editorState,
      currentStyle.remove(inlineStyle),
    );
    return setContent(
      withoutStyleOverride,
      Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        inlineStyle,
      ),
    );
  }

  return editorState;
};
