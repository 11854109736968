import { FloatingPortal } from "@floating-ui/react";
import * as React from "react";
import { createContext, useContext } from "react";

const PortalContext = createContext<HTMLElement | null>(null);

export const usePortalRootNode = () => {
  return useContext(PortalContext);
};

export type PortalProviderProps = {
  root: HTMLElement;
  children: React.ReactNode;
};

export const PortalProvider = (props: PortalProviderProps) => {
  return (
    <PortalContext.Provider value={props.root}>
      {props.children}
    </PortalContext.Provider>
  );
};

export type PortalProps = { children: React.ReactNode };

export const Portal = (props: PortalProps) => {
  const root = usePortalRootNode();
  return <FloatingPortal root={root}>{props.children}</FloatingPortal>;
};
