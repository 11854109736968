import { gql } from "@apollo/client";
import "moment/locale/fr";
import { createTeleporter } from "react-teleporter";

import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";

import { RouteError } from "../../Router";
import { ImageFragment } from "../ImageFragments";
import { ImageMultipleFormContent } from "./ImageMultipleFormContent";

const ImagesQuery = gql`
  query ImageForm_images($ids: [Int!]!) {
    images(where: { id: { in: $ids } }) {
      nodes {
        id
        ...ImageForm_image
      }
    }
  }
  ${ImageFragment}
`;

export const FooterTeleporter = createTeleporter();

const UpdateImagesMutation = gql`
  mutation ImageForm_updateImages($input: UpdateImagesInput!) {
    updateImages(input: $input) {
      id
      ...ImageForm_image
    }
  }
  ${ImageFragment}
`;

const ImageMultipleForm = ({ imageIds, children, store, ...props }) => {
  const [updateImages] = useSafeMutation(UpdateImagesMutation);

  return (
    <div className="contents">
      {children({
        title: `Éditer ${imageIds.length} images`,
        body: (
          <ImageMultipleFormContent
            imageIds={imageIds}
            updateImages={updateImages}
            store={store}
            {...props}
          />
        ),
        footer: <FooterTeleporter.Target />,
      })}
    </div>
  );
};

const ImageMultipleFormDataLoader = ({ imageIds, store, ...props }) => {
  const { data } = useSafeQuery(ImagesQuery, {
    variables: { ids: imageIds },
  });

  if (data && !data.images.nodes.length) {
    throw new RouteError("Images not found", 404);
  }

  return (
    <ImageMultipleForm
      imageIds={imageIds}
      data={data}
      store={store}
      {...props}
    />
  );
};

export { ImageMultipleFormDataLoader as ImageMultipleForm };
