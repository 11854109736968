// @ts-check
import rehypeParse from "rehype-parse";
import { unified } from "unified";

import { sanitizeHTML } from "./sanitizeHTML";

/**
 *
 * @param {string} html
 * @param {Parameters<typeof sanitizeHTML>[1]} options
 * @returns {import('hast').Root}
 */
export const parseHtmlToAst = (html, options = {}) => {
  const sanitizedHTML = sanitizeHTML(html, options);
  const wrappedHtml = `<sirius-root>${sanitizedHTML}</sirius-root>`;
  return unified()
    .data("settings", { fragment: true })
    .use(rehypeParse)
    .parse(wrappedHtml);
};
