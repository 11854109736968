import * as React from "react";
import { forwardRef } from "react";

const SvgHeadphone = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 4.5A7.5 7.5 0 0 0 4.5 12v1.208A2.5 2.5 0 0 1 8 15.5v3a2.5 2.5 0 0 1-5 0V12a9 9 0 1 1 18 0v6.5a2.5 2.5 0 0 1-5 0v-3a2.5 2.5 0 0 1 3.5-2.292V12A7.5 7.5 0 0 0 12 4.5Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHeadphone);
export default ForwardRef;
