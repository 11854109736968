import { memo } from "react";

import { H3 } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive } from "../utils";
import { BLOCK_STYLE_TYPE, command } from "./header-three";

export const name = "header-three-control";

const HeaderThreeToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      command={command}
      active={active}
      label="Intertitre (niveau 3)"
    >
      <H3 />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <HeaderThreeToolbarButton
      active={checkIsBlockActive(editorState, BLOCK_STYLE_TYPE)}
    />
  );
};

BlockControls.group = "block-style";
