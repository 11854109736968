/* eslint-disable @lemonde/import/no-illegal-import */
import { gql, useMutation, useSubscription } from "@apollo/client";
import { memo, useCallback } from "react";
import { useToaster } from "swash/Toast";

import { useReadOnly } from "@/containers/ReadOnly";
import { ArticleNode } from "@/containers/editor/nodes/ArticleNode";
import { ArticleNodeFragments } from "@/containers/editor/nodes/ArticleNodeFragments";
import { CustomTypeContentNode } from "@/containers/editor/nodes/CustomTypeContentNode";
import { ImageNode } from "@/containers/editor/nodes/ImageNode";
import { ImageNodeFragment } from "@/containers/editor/nodes/ImageNodeFragment";
import { ProductNode } from "@/containers/editor/nodes/ProductNode";
import { ProductSummaryNode } from "@/containers/editor/nodes/ProductSummaryNode";
import { SnippetNode } from "@/containers/editor/nodes/SnippetNode";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";
import { fetchArticleMedia } from "@/services/medias/fetchArticleMedia";
import { getMediaProviderData } from "@/services/medias/providers";

import { useArticleEditorExpandedContext } from "../RichEditorContext";
import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "article-media";
export const ATOMIC_ENTITY_TYPE = "ARTICLE_MEDIA";

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

const RichEditorArticleMediaPluginFragment = gql`
  fragment RichEditorArticleMediaPluginFragment on ArticleMediaBase {
    __typename
    metadata
    published
    media {
      id
      ... on Article {
        ...ArticleNode_article__expanded
      }
      ... on CustomTypeContent {
        ...CustomTypeContentNode_customTypeContent
      }
      ... on Image {
        ...ImageNode_image__expanded
      }
      ... on Product {
        ...ProductNode_product
      }
      ... on ProductSummary {
        ...ProductSummaryNode_productSummary
      }
      ... on Snippet {
        ...SnippetNode_snippet__expanded
      }
      ... on Tweet {
        ...TweetNode_tweet
      }
      ... on Video {
        ...VideoNode_video
      }
    }
  }

  ${ArticleNodeFragments.article__expanded}
  ${CustomTypeContentNode.fragments.content}
  ${ImageNodeFragment.image__expanded}
  ${ProductNode.fragments.product}
  ${ProductSummaryNode.fragments.productSummary}
  ${SnippetNodeFragments.snippet__expanded}
  ${TweetNode.fragments.tweet}
  ${VideoNode.fragments.video}
`;

const ArticleMediaQuery = gql`
  query RichEditorArticleMediaPlugin_articleMedia($id: Int!) {
    node: articleMedia(id: $id) {
      __typename
      id
      ...RichEditorArticleMediaPluginFragment
    }
  }
  ${RichEditorArticleMediaPluginFragment}
`;

const ArticleMediaRevisionQuery = gql`
  query RichEditorArticleMediaPlugin_articleMediaRevision($id: Int!) {
    node: articleMediaRevision(id: $id) {
      __typename
      id
      ...RichEditorArticleMediaPluginFragment
    }
  }
  ${RichEditorArticleMediaPluginFragment}
`;

const ArticleMediaUpdatedSubscription = gql`
  subscription RichEditorArticleMediaPlugin_articleMediasUpdated(
    $ids: [Int!]!
  ) {
    articleMediasUpdated(where: { id: { in: $ids } }) {
      id
      ...RichEditorArticleMediaPluginFragment
    }
  }
  ${RichEditorArticleMediaPluginFragment}
`;

const ArticleMediaBlock = (props) => {
  const contentState = props.contentState;
  const entityKey = props.block.getEntityAt(0);
  const type = contentState.getEntity(entityKey).getData().type;

  const query = (() => {
    switch (type) {
      case "ArticleMediaRevision":
        return ArticleMediaRevisionQuery;
      case "ArticleMedia":
        return ArticleMediaQuery;
      default:
        throw new Error(`Unknown type`);
    }
  })();

  const atomic = useAtomicBlockState({
    props,
    query,
    getEntityData: (articleMedia) => ({
      type: articleMedia.__typename,
    }),
  });
  const expanded = useArticleEditorExpandedContext();
  const { options } = props.blockProps;

  return (
    <AtomicBlock {...atomic}>
      {({ node }) => (
        <ArticleMedia
          node={node}
          expanded={expanded}
          editable={options.editable}
          setEditing={props.setEditing}
        />
      )}
    </AtomicBlock>
  );
};

const UpdateArticleMediaMutation = gql`
  mutation ArticleArticleEdit_updateArticleMedia(
    $id: Int!
    $metadata: UpdateArticleMediaMetadataInput!
  ) {
    updateArticleMedia(input: { id: $id, metadata: $metadata }) {
      id
      metadata
      type
      media {
        id
        ... on Article {
          ...ArticleNode_article__expanded
        }
      }
    }
  }

  ${ArticleNodeFragments.article__expanded}
`;

const ArticleMediaArticle = ({
  articleMediaId,
  article,
  metadata,
  expanded,
}) => {
  const toaster = useToaster();
  const [updateArticleMedia] = useMutation(UpdateArticleMediaMutation, {
    variables: { id: articleMediaId },
  });

  const handleUpdate = useCallback(
    async (metadata, titleDirty) => {
      try {
        await updateArticleMedia({
          variables: {
            metadata: {
              ...metadata,
              title: titleDirty ? metadata.title : null,
            },
          },
        });
      } catch {
        toaster.danger("La mise à jour du média a échoué");
      }
    },
    [updateArticleMedia, toaster],
  );

  return (
    <ArticleNode
      article={article}
      metadata={metadata}
      onUpdate={handleUpdate}
      expanded={expanded}
    />
  );
};

const ArticleMedia = memo(
  ({
    node: { id, media, metadata, published, __typename },
    expanded,
    editable,
    setEditing,
  }) => {
    useSubscription(ArticleMediaUpdatedSubscription, {
      variables: {
        ids: [id],
      },
      skip: __typename === "ArticleMediaRevision",
    });
    const readOnly = useReadOnly();

    return (() => {
      switch (media.__typename) {
        case "Article":
          return (
            <ArticleMediaArticle
              articleMediaId={id}
              article={media}
              metadata={metadata}
              expanded={expanded}
            />
          );
        case "CustomTypeContent":
          return (
            <CustomTypeContentNode
              articleMediaId={id}
              content={media}
              metadata={metadata}
              expanded={expanded}
            />
          );
        case "Image":
          return (
            <ImageNode
              image={media}
              metadata={metadata}
              published={published}
              editable={!readOnly && editable}
              onEdit={setEditing}
              expanded={expanded}
              articleMediaId={id}
            />
          );
        case "Product":
          return <ProductNode product={media} />;
        case "ProductSummary":
          return <ProductSummaryNode productSummary={media} />;
        case "Snippet":
          return (
            <SnippetNode
              snippet={media}
              expanded={expanded}
              onEdit={setEditing}
              editable={!readOnly && editable}
            />
          );
        case "Tweet":
          return <TweetNode tweet={media} expanded={expanded} />;
        case "Video":
          return <VideoNode video={media} expanded={expanded} />;
        default:
          return null;
      }
    })();
  },
);

export const blockRendererFn = () => {
  return { component: ArticleMediaBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return { editable: false, ...options };
}

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

export const createBlockFromUrls = async (state, urls) => {
  const [url] = urls;
  const { articleId, frontHost, imageHost, services } = state.options;

  const mediaData = getMediaProviderData({
    url,
    frontHost,
    imageHost,
    services,
  });

  // "archives" is not draggable in article Editor
  if (!mediaData || mediaData.provider.type === "archives") return;

  const { data, provider } = mediaData;

  const articleMedia = await fetchArticleMedia({
    articleId,
    type: provider.type,
    ...data,
  });

  if (!articleMedia) return null;

  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: articleMedia.id,
        type: "ArticleMedia",
        media: {
          id: articleMedia.media.id,
          url: articleMedia.media.url,
          videoTitle: articleMedia.media.videoTitle,
          snippetTitle: articleMedia.media.snippetTitle,
          code: articleMedia.media.code,
          caption: articleMedia.media.caption,
          text: articleMedia.media.text,
          authorName: articleMedia.media.authorName,
          title: articleMedia.media.title,
          label: articleMedia.media.label,
          name: articleMedia.media.name,
          __typename: articleMedia.media.__typename,
        },
      },
    },
  };
};
