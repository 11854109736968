import { memo } from "react";
import { Separator } from "swash/Icon";

import { RichEditorToolbarButton } from "@/components/rich-editor/RichEditorToolbar";

import { command } from "./separator";

export const name = "separator-control";

const SeparatorToolbarButton = memo(() => {
  return (
    <RichEditorToolbarButton
      label="Insérer une ligne horizontale"
      command={command}
    >
      <Separator />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = () => {
  return <SeparatorToolbarButton />;
};

BlockControls.group = "insert";
