import * as React from "react";

type ConnectedComponent<T extends React.ComponentType, S> = React.ComponentType<
  Omit<React.ComponentProps<T>, keyof S>
>;

// This is a generic factory to create set from a linked state components
// See https://codesandbox.io/s/linked-state-component-set-9b96h
export function createSetFactory<
  State,
  Options,
  Components extends Record<string, React.ComponentType<any>>,
>({
  useState,
  components,
}: {
  useState: (options: Options) => State;
  components: Components;
}) {
  return () => {
    const Context = React.createContext<State | null>(null);

    function Provider({
      options,
      children,
    }: {
      options: Options;
      children: React.ReactNode;
    }) {
      const state = useState(options);
      return <Context.Provider value={state}>{children}</Context.Provider>;
    }

    function useContext() {
      return React.useContext(Context);
    }

    function connect<T extends React.ComponentType<any>>(Component: T) {
      const ConnectedComponent = React.forwardRef((props, ref) => {
        const state = useContext();
        return <Component ref={ref} {...state} {...(props as any)} />;
      });
      return ConnectedComponent;
    }

    return {
      Provider,
      useContext,
      ...Object.entries(components).reduce(
        (obj, [name, Component]) => ({
          ...obj,
          [name]: connect(Component),
        }),
        {} as {
          [Key in keyof Components]: ConnectedComponent<Components[Key], State>;
        },
      ),
    };
  };
}

export function useDelayed({ skip = false } = {}) {
  const [ticked, setTicked] = React.useState(false);
  React.useEffect(() => {
    if (skip || ticked) return undefined;
    const id = requestAnimationFrame(() => setTicked(true));
    return () => cancelAnimationFrame(id);
  }, [skip, ticked]);
  return ticked;
}
