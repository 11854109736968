/* eslint-disable react/prop-types */
import {
  NavLink as ReactRouterLink,
  type To,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { createTeleporter } from "react-teleporter";
import { Link as SwashLink } from "swash/Link";

import { BreadcrumbNav as BaseBreadcrumbNav } from "@/components/Layout";

const BreadcrumbTeleporter = createTeleporter({ multiSources: true });

export const BreadcrumbNav: React.FC = () => {
  return (
    <BaseBreadcrumbNav>
      <BreadcrumbTeleporter.Target as="ol" />
    </BaseBreadcrumbNav>
  );
};

export const BreadcrumbLink: React.FC<{
  to: To;
  end?: boolean;
  children?: React.ReactNode;
}> = ({ to, end, children }) => {
  const path = useResolvedPath(to);
  const match = useMatch({ path: path.pathname, end });

  return (
    <BreadcrumbTeleporter.Source>
      <li aria-current={match ? "page" : undefined}>
        {match ? (
          children
        ) : (
          <SwashLink asChild>
            <ReactRouterLink to={to}>{children}</ReactRouterLink>
          </SwashLink>
        )}
      </li>
    </BreadcrumbTeleporter.Source>
  );
};
