import { memo, useMemo } from "react";
import { useField } from "react-final-form";
import { cn } from "swash/utils/classNames";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";

import { EnumFieldCheckboxControl } from "../fields/EnumField";

function formatNumber(value) {
  if (value === null) return value;
  if (value === undefined) return value;
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value.map(formatNumber);
  const numValue = Number(value);
  return Number.isNaN(numValue) ? value : String(value);
}

function parseString(value) {
  if (value === null) return value;
  if (value === undefined) return value;
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value.map(parseString);
  const numValue = Number(value);
  return Number.isNaN(numValue) ? value : numValue;
}

//TODO: replace with getFormattersV2 when checkbox is migrated to v2
const getFormatters = ({ multi }) => {
  const operator = multi ? "in" : "eq";
  const defaultValue = multi ? [] : null;

  return {
    format: (v) => (v ? formatNumber(v[operator]) : defaultValue),
    parse: (v) =>
      parseString(v) ? { [operator]: parseString(v) } : defaultValue,
  };
};

function formatNumberV2(value, items) {
  if (value === undefined) return value;
  if (value === null) return value;
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value.map((v) => formatNumberV2(v, items));
  return items.find((i) => i.value === value) || null;
}

function parseStringV2(objectValue) {
  const value = Array.isArray(objectValue)
    ? objectValue
    : objectValue?.value ?? null;
  if (value === undefined) return value;
  if (value === null) return value;
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value.map(parseStringV2);
  const numValue = Number(value);
  return Number.isNaN(numValue) ? value : numValue;
}

const getFormattersV2 = ({ multi, items }) => {
  const operator = multi ? "in" : "eq";
  const defaultValue = multi ? [] : null;

  return {
    format: (v) => (v ? formatNumberV2(v[operator], items) : defaultValue),
    parse: (v) =>
      parseStringV2(v) ? { [operator]: parseStringV2(v) } : defaultValue,
  };
};

const CheckboxFieldLabel = (props) => (
  <FieldLabel
    {...props}
    className={cn('after:content-[" :"]', props.className)}
  />
);

//TODO: migrate to V2
export function CheckboxEnumFiltersField({
  name,
  label,
  enum: enumValues,
  multi = true,
  sortEntries = ([, labelA], [, labelB]) => labelA.localeCompare(labelB),
  w,
  scale,
  ...others
}) {
  const field = useSelectField(name, {
    orientation: "horizontal",
    ...getFormatters({ multi }),
    ...others,
  });
  return (
    <div style={{ width: w || "auto" }}>
      <FieldGroup {...field} className="mr-2">
        <CheckboxFieldLabel {...field}>{label}</CheckboxFieldLabel>
        <SelectField
          {...field}
          as={EnumFieldCheckboxControl}
          enum={enumValues}
          multi={multi}
          sortEntries={sortEntries}
        />
      </FieldGroup>
    </div>
  );
}

export const SelectEnumFiltersField = ({
  name,
  label,
  enum: unsortedItems,
  multi,
  disabled,
  required,
  labelSelector = (field) => field.label,
  sortEntries = (itemA, itemB) => itemA.label.localeCompare(itemB.label),
  className,
  ...others
}) => {
  const defaultValue = multi ? [] : null;

  const items = useMemo(
    () => unsortedItems.sort(sortEntries),
    [unsortedItems, sortEntries],
  );

  const {
    input: { value, onChange },
  } = useField(name, {
    ...getFormattersV2({ multi, items }),
    ...others,
  });

  const state = useEnumSelectState({
    title: label,
    value: value && items.length ? value : defaultValue,
    onChange,
    items,
    labelSelector,
    valueSelector: (field) => field.value?.toString(),
    disabledSelector: () => disabled,
    required,
  });

  return (
    <div className={className}>
      <EnumSelect
        placeholder={label}
        aria-label={label}
        state={state}
        disabled={disabled}
      />
    </div>
  );
};

/** @type {React.FC<?>} */
export const EnumFiltersField = memo(
  ({ appearance = "select", modal, ...others }) => {
    switch (appearance) {
      case "select":
        return <SelectEnumFiltersField {...others} modal={modal} />;
      case "checkbox":
        return <CheckboxEnumFiltersField {...others} />;
      default:
        return null;
    }
  },
);
