import { createContext, useContext, useMemo } from "react";

import { RichEditorCompositeEntitiesContextProvider } from "@/components/rich-editor/RichEditorCompositeEntityContext";

import { getFocusedEntityKey, getSelectedBlocks } from "./utils/Selection";

const SelectedBlocksContext = createContext();

/**
 * @param {string} blockKey
 */
export const useIsBlockSelected = (blockKey) => {
  const selectedBlocks = useContext(SelectedBlocksContext);
  return selectedBlocks.some((block) => block && block.key === blockKey);
};

const EntityFocusContext = createContext();

/**
 * obtain the current focused entity key
 * @return {string | null}
 */
export const useFocusedEntityKey = () => {
  return useContext(EntityFocusContext);
};

const ReadOnlyContext = createContext();

export const useReadOnlyContext = () => {
  return useContext(ReadOnlyContext);
};
const ArticleEditorExpandedContext = createContext();

export const useArticleEditorExpandedContext = () => {
  return useContext(ArticleEditorExpandedContext);
};

const SelectionContext = createContext();

export const useSelection = () => {
  return useContext(SelectionContext);
};

const EditorNameContext = createContext();

export const useEditorName = () => {
  return useContext(EditorNameContext);
};

const BlockTemplatesContext = createContext();

export const useBlockTemplates = () => {
  return useContext(BlockTemplatesContext);
};

export const RichEditorContextProvider = ({ value, children }) => {
  const { name, readOnly, expanded, blockTemplates } = value;

  const contentState = value.editorState.getCurrentContent();
  const selection = value.editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  const selectedBlocks = useMemo(
    () =>
      readOnly ? [] : getSelectedBlocks({ contentState, startKey, endKey }),
    [contentState, startKey, endKey, readOnly],
  );

  return (
    <RichEditorCompositeEntitiesContextProvider>
      <BlockTemplatesContext.Provider value={blockTemplates}>
        <EditorNameContext.Provider value={name}>
          <SelectedBlocksContext.Provider value={selectedBlocks}>
            <EntityFocusContext.Provider value={getFocusedEntityKey(value)}>
              <ReadOnlyContext.Provider value={readOnly}>
                <ArticleEditorExpandedContext.Provider value={expanded}>
                  <SelectionContext.Provider value={selection}>
                    {children}
                  </SelectionContext.Provider>
                </ArticleEditorExpandedContext.Provider>
              </ReadOnlyContext.Provider>
            </EntityFocusContext.Provider>
          </SelectedBlocksContext.Provider>
        </EditorNameContext.Provider>
      </BlockTemplatesContext.Provider>
    </RichEditorCompositeEntitiesContextProvider>
  );
};
