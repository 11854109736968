import { Virtualizer } from "@tanstack/react-virtual";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "swash/Button";
import { IoArrowDown, IoArrowUp, IoClose } from "swash/Icon";
import { ToasterButton } from "swash/ToasterButton";
import { cn } from "swash/utils/classNames";

type Position = "bottom" | "center" | "top";

type TrackItem = {
  id: number;
  viewed: boolean;
};

type VirtualItem = TrackItem & {
  position: Position;
  index: number;
  visible: boolean;
};

type Item = TrackItem | VirtualItem;

type CallBack = ({ items }: { items: Item[] }) => void;
type Listener = ({ items }: { items: Item[] }) => void;

type VirtualNotifier = {
  track: (id: Item["id"]) => void;
  untrack: (id: Item["id"]) => void;
  notify: (item: VirtualItem) => void;
  subscribe: (callback: CallBack) => any;
  clear: (position: Position) => void;
  registerScrollFunction: (fn: ListVirtuaLizer["scrollToIndex"]) => void;
  scrollToIndex: (index: VirtualItem["index"]) => void;
};

const VirtualNotifierContext = createContext<VirtualNotifier | undefined>(
  undefined,
);

export const useVirtualNotifierState = () => {
  const itemsRef = useRef<Item[]>([]);
  const subscribeListenersRef = useRef<Listener[]>([]);

  const publish = useCallback(() => {
    subscribeListenersRef.current.forEach((listener) => {
      listener({
        items: itemsRef.current,
      });
    });
  }, []);

  const subscribe = useCallback((callback: CallBack) => {
    const listener: Listener = ({ items }) => callback({ items });
    subscribeListenersRef.current.push(listener);
    return () => {
      subscribeListenersRef.current = subscribeListenersRef.current.filter(
        (iListener) => iListener !== listener,
      );
    };
  }, []);

  const track = useCallback(
    (id: Item["id"]) => {
      const items = itemsRef.current;
      itemsRef.current = [
        ...items.filter((iItem) => iItem.id !== id),
        { id, viewed: false },
      ];
      publish();
    },
    [publish],
  );

  const untrack = useCallback(
    (id: Item["id"]) => {
      const items = itemsRef.current;
      itemsRef.current = items.filter((item) => item.id !== id);
      publish();
    },
    [publish],
  );

  const notify = useCallback(
    (item: VirtualItem) => {
      const items = itemsRef.current;
      itemsRef.current = items.map((iItem) => {
        if (iItem.id !== item.id) return iItem;
        return { ...iItem, ...item };
      });
      publish();
    },
    [publish],
  );

  const clear = useCallback(
    (position: VirtualItem["position"]) => {
      const items = itemsRef.current.filter((item) => {
        if (!("position" in item)) return true; // keep trackedItem
        return item.position !== position;
      });
      itemsRef.current = items;
      publish();
    },
    [publish],
  );

  const scrollToIndexRef = useRef<ListVirtuaLizer["scrollToIndex"]>();

  const registerScrollFunction = useCallback(
    (fn: ListVirtuaLizer["scrollToIndex"]) => {
      if (scrollToIndexRef.current === fn) return;
      scrollToIndexRef.current = fn;
    },
    [],
  );

  const scrollToIndex = useCallback((index: VirtualItem["index"]) => {
    scrollToIndexRef.current?.(index, {
      align: "center",
    });
  }, []);

  return {
    track,
    untrack,
    notify,
    subscribe,
    clear,
    registerScrollFunction,
    scrollToIndex,
  };
};

type NotifierButtonProps = {
  position: VirtualItem["position"];
  count: number;
  index?: VirtualItem["index"];
};

const NotifierButton = ({ position, count, index }: NotifierButtonProps) => {
  const notifier = useVirtualNotifier();

  const handleClick = useCallback(() => {
    if (index === undefined) return;
    notifier?.scrollToIndex(index);
  }, [notifier, index]);

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      notifier?.clear(position);
    },
    [notifier, position],
  );

  const topPosition = position === "top";

  return (
    <ToasterButton
      className={cn(
        "absolute left-1/2 z-popover -translate-x-1/2",
        topPosition ? "top-2" : "bottom-2",
      )}
      onClick={handleClick}
    >
      {topPosition ? <IoArrowUp /> : <IoArrowDown />}
      {count} {count > 1 ? "nouveaux éléments" : "nouvel élément"}
      <Button
        aria-label="Vider"
        variant="secondary"
        appearance="text"
        onClick={handleClear}
        asChild
      >
        <div style={{ padding: 8 }}>
          <IoClose />
        </div>
      </Button>
    </ToasterButton>
  );
};

const getNotifications = (items: VirtualItem[]) => {
  const top = items
    .filter(({ position }) => position === "top")
    .sort((a, b) => a.index - b.index);
  const bottom = items
    .filter(({ position }) => position === "bottom")
    .sort((a, b) => a.index - b.index);

  return {
    top: { count: top.length, scrollIndex: top[top.length - 1]?.index },
    bottom: { count: bottom.length, scrollIndex: bottom[0]?.index },
  };
};

export const NotifierButtonManager = () => {
  const notifier = useVirtualNotifier();
  const [items, setItems] = useState<VirtualItem[]>();

  useEffect(
    () =>
      notifier?.subscribe(({ items }) => {
        const virtualItems = items.filter((item) => "position" in item);
        setItems(virtualItems as VirtualItem[]);
      }),
    [notifier],
  );

  if (!items) return null;

  const { top, bottom } = getNotifications(items);

  return (
    <>
      {top.count > 0 ? (
        <NotifierButton
          count={top.count}
          position="top"
          index={top.scrollIndex}
        />
      ) : null}
      {bottom.count > 0 ? (
        <NotifierButton
          count={bottom.count}
          position="bottom"
          index={bottom.scrollIndex}
        />
      ) : null}
    </>
  );
};

export const VirtualNotifierContainer = ({
  notifier,
  children,
}: {
  notifier?: VirtualNotifier;
  children: ReactNode;
}) => {
  return (
    <VirtualNotifierContext.Provider value={notifier}>
      <div className="relative flex flex-1 overflow-hidden">
        <NotifierButtonManager />
        {children}
      </div>
    </VirtualNotifierContext.Provider>
  );
};

export const useVirtualNotifier = () => {
  const ctx = useContext(VirtualNotifierContext);
  return ctx;
};

const getVirtualItem = ({
  id,
  itemIndex,
  startIndex,
  endIndex,
}: {
  id: number;
  itemIndex: number;
  startIndex: number;
  endIndex: number;
}) => {
  if (itemIndex > endIndex) {
    return {
      id,
      position: "bottom" as Position,
      visible: false,
      viewed: false,
      index: itemIndex,
    };
  }

  if (itemIndex < startIndex) {
    return {
      id,
      position: "top" as Position,
      visible: false,
      viewed: false,
      index: itemIndex,
    };
  }

  return {
    id,
    position: "center" as Position,
    visible: true,
    viewed: false,
    index: itemIndex,
  };
};

type ListVirtuaLizer = Virtualizer<any, Element>;

type ListItem = { id: Item["id"] };

type TimeOutRef = {
  [id: number]: NodeJS.Timeout;
};

type VirtualItemRef = {
  [id: number]: VirtualItem;
};

const VIEW_DELAY_MS = 1000;

export const useTrackVirtualItems = ({
  notifier,
  list,
  virtualizer,
}: {
  notifier?: VirtualNotifier;
  list: (ListItem | undefined)[];
  virtualizer: ListVirtuaLizer;
}) => {
  const [items, setItems] = useState<Item[]>([]);

  const timeoutRef = useRef<TimeOutRef>({});
  const previousVirtualItemRef = useRef<VirtualItemRef>({});

  const [visibleDoc, setVisibleDoc] = useState(true);

  const { startIndex, endIndex } = virtualizer.calculateRange();

  useEffect(
    () => notifier?.registerScrollFunction(virtualizer.scrollToIndex),
    [notifier, virtualizer],
  );

  useEffect(
    () =>
      notifier?.subscribe(({ items }) => {
        setItems(items);
      }),
    [notifier],
  );

  useEffect(() => {
    if (!notifier) return;
    const listener = () => {
      setVisibleDoc(document.visibilityState === "visible");
    };
    document.addEventListener("visibilitychange", listener);
    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [notifier]);

  const checkViewed = useCallback(
    (item: ListItem) => {
      const virtualItem = items.find((iItem) => iItem?.id === item.id);
      return virtualItem?.viewed ?? true;
    },
    [items],
  );

  const shouldNotify = useCallback((virtualItem: VirtualItem) => {
    const previousVirtualItem = previousVirtualItemRef.current[virtualItem.id];

    return (
      virtualItem.position !== previousVirtualItem?.position ||
      virtualItem.index !== previousVirtualItem?.index
    );
  }, []);

  const handleVisible = useCallback(
    (virtualItem: VirtualItem) => {
      if (!notifier) return;
      const { visible, id } = virtualItem;
      const { notify, untrack } = notifier;
      if ((!visibleDoc || !visible) && timeoutRef.current[id]) {
        clearTimeout(timeoutRef.current[id]);
        delete timeoutRef.current[id];
      }
      if (visibleDoc && visible && !timeoutRef.current[id]) {
        timeoutRef.current[id] = setTimeout(() => {
          notify({ ...virtualItem, viewed: true });
          untrack(id);
        }, VIEW_DELAY_MS);
      }
    },
    [notifier, visibleDoc],
  );

  useEffect(() => {
    if (!notifier) return;
    const { notify, untrack } = notifier;
    const trackedIds = items.map(({ id }) => id);

    trackedIds.forEach((id) => {
      const itemIndex = list.findIndex((item) => item?.id === id);
      // if element not in list untrack it
      if (itemIndex < 0) {
        untrack(id);
        return;
      }

      const virtualItem = getVirtualItem({
        id,
        itemIndex,
        startIndex,
        endIndex,
      });

      handleVisible(virtualItem);

      if (shouldNotify(virtualItem)) {
        notify(virtualItem);
        previousVirtualItemRef.current[id] = virtualItem;
      }
    });
  }, [
    list,
    startIndex,
    endIndex,
    handleVisible,
    notifier,
    items,
    shouldNotify,
  ]);

  return { checkViewed };
};
