import { gql } from "@apollo/client";

import * as email from "./email";
import * as facebook from "./facebook";
import * as push from "./push";
import * as twitter from "./twitter";

export const channels = [push, email, twitter, facebook];

export const ChannelsChannelFragment = gql`
  fragment ChannelsChannelFragment on AlertChannel {
    ...PushChannelFragment
    ...EmailChannelFragment
    ...TwitterChannelFragment
    ...FacebookChannelFragment
  }

  ${push.ChannelFragment}
  ${email.ChannelFragment}
  ${twitter.ChannelFragment}
  ${facebook.ChannelFragment}
`;

export const ChannelsAlertFragment = gql`
  fragment ChannelsAlertFragment on Alert {
    ...PushChannelAlertFragment
    ...EmailChannelAlertFragment
    ...TwitterChannelAlertFragment
    ...FacebookChannelAlertFragment
  }

  ${push.AlertFragment}
  ${email.AlertFragment}
  ${twitter.AlertFragment}
  ${facebook.AlertFragment}
`;
