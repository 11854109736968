import * as React from "react";
import { forwardRef } from "react";

const SvgPaperCopy = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#paper-copy_svg__a)" fill="currentColor">
      <path d="M9 0a2 2 0 0 0-2 2v2h8a2 2 0 0 1 2 2v14h4a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H9Z" />
      <path d="M10.25 7.5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3ZM9.75 11a.5.5 0 0 1 .5-.5h3a.5.5 0 1 1 0 1h-3a.5.5 0 0 1-.5-.5ZM4.25 13.5a.5.5 0 1 0 0 1h9a.5.5 0 0 0 0-1h-9ZM3.75 17a.5.5 0 0 1 .5-.5h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1-.5-.5ZM4.25 19.5a.5.5 0 1 0 0 1h9a.5.5 0 0 0 0-1h-9ZM7.78 11.53a.75.75 0 0 1-.53.22h-3A.75.75 0 0 1 3.5 11V8a.75.75 0 0 1 .75-.75h3A.75.75 0 0 1 8 8v3a.75.75 0 0 1-.22.53Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H3Zm12 1H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z"
      />
    </g>
    <defs>
      <clipPath id="paper-copy_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgPaperCopy);
export default ForwardRef;
