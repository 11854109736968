import { gql } from "@apollo/client";

import { SnippetNode } from "@/containers/editor/nodes/SnippetNode";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { fetchMedia } from "@/services/medias/fetchMedia";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "snippet";
export const ATOMIC_ENTITY_TYPE = "SNIPPET";

const SnippetQuery = gql`
  query RichEditorSnippetPlugin_snippet($id: Int!) {
    node: snippet(id: $id) {
      id
      ...SnippetNode_snippet__expanded
      ... on Snippet {
        url
      }
    }
  }

  ${SnippetNodeFragments.snippet__expanded}
`;

function SnippetBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: SnippetQuery,
  });

  return (
    <AtomicBlock {...atomic}>
      {({ node }) => (
        <SnippetNode
          snippet={node}
          expanded={props.blockProps.state.expanded}
        />
      )}
    </AtomicBlock>
  );
}

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: SnippetBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return options;
}

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

const SnippetUrlQuery = gql`
  query RichEditorSnippetPluginUrl_snippet(
    $id: Int
    $url: String
    $type: String!
  ) {
    media(id: $id, url: $url, type: $type) {
      id
      ... on Snippet {
        snippetTitle: title
        code
      }
    }
  }
`;

export const createBlockFromUrls = async (state, urls) => {
  const data = getDataFromUrls(state, urls);
  const [url] = urls;
  if (!data) return null;

  const snippet = await fetchMedia({
    id: data.id,
    url: url,
    type: "snippets",
    query: SnippetUrlQuery,
  });

  if (!snippet) return null;

  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: snippet.id,
        media: {
          id: snippet.id,
          url: snippet.url,
          snippetTitle: snippet.snippetTitle,
          code: snippet.code,
        },
      },
    },
  };
};
