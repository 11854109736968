import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.svg";

export const IntegrationConfigFragment = gql`
  fragment GcpIntegrationConfigFragment on GcpIntegrationConfig {
    clientEmail
    privateKey
    projectId
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="name" label="Name" required />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="clientEmail"
          label="Client Email"
          type="email"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField name="privateKey" label="Private Key" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="projectId" label="Project ID" required />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
