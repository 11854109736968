import { applyNlcstTransform } from "@lemonde/hast-util-nlcst-transform";
import { toFrench as hastToFrench } from "@lemonde/hast-util-to-french";
import { toFrench as nlcstToFrench } from "@lemonde/nlcst-util-to-french";
import { toHtml } from "hast-util-to-html";
import rehypeParse from "rehype-parse";
import { unified } from "unified";

/**
 * Apply french rules to an HTML text.
 * @param {string} html
 * @returns {string}
 */
export const legacyFrenchifyHTML = (html) => {
  const parsedHtml = unified()
    .data("settings", { fragment: true })
    .use(rehypeParse)
    .parse(html);

  const result = hastToFrench(
    applyNlcstTransform(parsedHtml, (nlcstTree) => nlcstToFrench(nlcstTree)),
  );
  return toHtml(result);
};

/**
 * Apply french rules to an HTML text.
 */
export const frenchifyHTML = (astHtml) =>
  applyNlcstTransform(astHtml, (nlcstTree) => nlcstToFrench(nlcstTree));
