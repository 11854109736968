import { KeyBindingUtil } from "draft-js-es";
import { useEffect } from "react";

import { addDecorator } from "../../modifiers/addDecorator";
import { removeDecorator } from "../../modifiers/removeDecorator";
import { handleBinding } from "../../utils";
import {
  useInvisibleCharacterVisible,
  useToggleInvisibleCharacter,
} from "./InvisibleCharacterPluginContext";

const SPACE_CHAR_CODE = " ".charCodeAt(0);
const NO_BREAK_SPACE_CHAR_CODE = "\u00A0".charCodeAt(0);
const NARROW_NO_BREAK_SPACE_CHAR_CODE = "\u202F".charCodeAt(0);

export const name = "invisible-characters";

export const command = "toggle-display-spaces";

const SHORTCUT = KeyBindingUtil.usesMacOSHeuristics() ? ["⌘", "$"] : ["⌘", "*"];

export const shortcuts = [
  {
    description: "Afficher les caractères invisibles",
    shortcut: SHORTCUT,
    category: "Affichage",
  },
];

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const decorators = [
  {
    /**
     * @param {import('draft-js').ContentBlock} contentBlock
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (contentBlock, callback) => {
      findWithCharCodes(SPACE_CHAR_CODE, contentBlock, callback);
    },
    component: SpaceDecorator,
  },
  {
    /**
     * @param {import('draft-js').ContentBlock} contentBlock
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (contentBlock, callback) => {
      findWithCharCodes(NO_BREAK_SPACE_CHAR_CODE, contentBlock, callback);
    },
    component: NonBreakingSpaceDecorator,
  },
  {
    /**
     * @param {import('draft-js').ContentBlock} contentBlock
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (contentBlock, callback) => {
      findWithCharCodes(
        NARROW_NO_BREAK_SPACE_CHAR_CODE,
        contentBlock,
        callback,
      );
    },
    component: NarrowNonBreakingSpaceDecorator,
  },
];

export const usePluginProps = ({ setEditorState }) => {
  const active = useInvisibleCharacterVisible();
  const toggleInvisibleCharacter = useToggleInvisibleCharacter();
  useEffect(() => {
    if (active) {
      setEditorState((editorState) =>
        decorators.reduce(
          (editorState, decorator) => addDecorator(editorState, decorator),
          editorState,
        ),
      );
    } else {
      setEditorState((editorState) =>
        decorators.reduce(
          (editorState, decorator) => removeDecorator(editorState, decorator),
          editorState,
        ),
      );
    }
  }, [active, setEditorState]);
  return { toggleInvisibleCharacter };
};

export const handleKeyCommand = ({ props }, cmd) => {
  if (cmd !== command) return undefined;
  props.toggleInvisibleCharacter((visible) => !visible);
  return "handled";
};

function findWithCharCodes(charCode, contentBlock, callback) {
  const text = contentBlock.getText();
  for (let i = 0; i < text.length; i++) {
    if (charCode === text.charCodeAt(i)) {
      callback(i, i + 1);
    }
  }
}

/**
 *
 * @param {{ children: React.ReactNode, entityKey: string, contentState: import('draft-js').ContentState }} props
 */
function SpaceDecorator({ children }) {
  return <span data-space-highlight="space">{children}</span>;
}

/**
 *
 * @param {{ children: React.ReactNode, entityKey: string, contentState: import('draft-js').ContentState }} props
 */
function NonBreakingSpaceDecorator({ children }) {
  return <span data-space-highlight="no-break-space">{children}</span>;
}

/**
 *
 * @param {{ children: React.ReactNode, entityKey: string, contentState: import('draft-js').ContentState }} props
 */
function NarrowNonBreakingSpaceDecorator({ children }) {
  return <span data-space-highlight="narrow-no-break-space">{children}</span>;
}
