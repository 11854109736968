import { createContext, useContext, useMemo } from "react";

import { useUsedInResource } from "./usages/useUsedInResource";

const ListContext = createContext();
const UsedInContext = createContext();

export const useUsedInContext = () => {
  return useContext(UsedInContext);
};

export function useCRUDListContext() {
  return useContext(ListContext);
}

export function CRUDListContextProvider({ children, descriptor, refetch }) {
  const value = useMemo(
    () => ({
      descriptor,
      refetch,
    }),
    [refetch, descriptor],
  );
  return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
}

export function CRUDFormContextProvider({
  usedInEnabled,
  node,
  operations,
  children,
}) {
  const usedInValue = useUsedInResource({
    node,
    enabled: usedInEnabled,
    queries: operations.UsedInQueries,
  });

  return (
    <UsedInContext.Provider value={usedInValue}>
      {children}
    </UsedInContext.Provider>
  );
}
