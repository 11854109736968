import * as React from "react";
import { forwardRef } from "react";

const SvgIndice0 = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M10 5.757 12.743 10 10 14.243 7.257 10z"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIndice0);
export default ForwardRef;
