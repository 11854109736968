import { useEffect, useState } from "react";

export const useDelayedVisible = (delay: number): boolean => {
  const [visible, setVisible] = useState(delay === 0);
  useEffect(() => {
    if (delay === 0) return;
    const timeout = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return visible;
};
