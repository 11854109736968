import { gql } from "@apollo/client";

import { SnippetCardFragment } from "@/containers/snippet/SnippetCardFragment";

const BaseSnippetNodeFragment = gql`
  fragment BaseSnippetNodeFragment on Snippet {
    id
    providerName
    snippetTitle: title
    url
    screenshotUrl
    code
  }
`;

export const SnippetNodeFragments = {
  snippet: gql`
    fragment SnippetNode_snippet on Snippet {
      ...BaseSnippetNodeFragment
    }
    ${BaseSnippetNodeFragment}
  `,
  snippet__expanded: gql`
    fragment SnippetNode_snippet__expanded on Snippet {
      ...BaseSnippetNodeFragment
      ...SnippetCardArticle_snippet
    }
    ${BaseSnippetNodeFragment}
    ${SnippetCardFragment}
  `,
};
