import { TextboxProgress } from "swash/controls/Textbox";
import { cn } from "swash/utils/classNames";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { FieldSignCounter } from "@/components/fields/FieldSignCounter";
import { RichEditorTextBoxField } from "@/components/fields/RichEditorTextBoxField";
import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";
import { TextareaField } from "@/components/fields/TextareaField";

const RICH_FIELD_DEFAULT_MAX_ROWS = 5;

/**
 * @param {*} props
 */
export function StringField({
  name,
  label,
  hint,
  placeholder,
  disabled,
  w = 1,
  readOnly,
  multiline,
  rows,
  maxRows,
  cols,
  autoFocus,
  min,
  max,
  rich,
  showTools = true,
  limit,
  limitType = "soft",
  showCounter,
  scale = "lg",
  parse = (v) => v,
  onKeyDown,
  children: childrenProp,
  progress,
  ...options
}) {
  const maxLength =
    options.maxLength ?? (limitType === "hard" ? limit : undefined);

  const field = useTextInputField(name, {
    parse,
    type: "text",
    ...options,
    maxLength,
  });
  const fieldProps = {
    ...field,
    scale,
    placeholder,
    w,
    disabled,
    readOnly,
    rows,
    cols,
    autoFocus,
    min,
    max,
    onKeyDown,
  };

  const labelElement = label ? (
    <FieldLabel {...field}>{label}</FieldLabel>
  ) : null;

  return (
    <FieldGroup scale={scale} {...field}>
      {limit || showCounter ? (
        <div
          data-field-label
          className={cn(
            "grid grid-flow-col gap-2",
            'shrink-0 group-data-[orientation="horizontal"]/fieldGroup:mr-4 group-data-[orientation="horizontal"]/fieldGroup:inline-block',
          )}
          style={{ gridAutoColumns: "1fr max-content", flex: "1 0 100%" }}
        >
          {labelElement}
          <FieldSignCounter {...field} limit={limit} />
        </div>
      ) : (
        labelElement
      )}
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      {rich ? (
        <RichEditorTextBoxField
          {...fieldProps}
          multiline={multiline}
          rich
          maxRows={maxRows ?? RICH_FIELD_DEFAULT_MAX_ROWS}
          showTools={showTools}
          readOnly={disabled}
        />
      ) : multiline ? (
        <TextareaField {...fieldProps} />
      ) : (
        <TextInputField {...fieldProps}>
          {progress && <TextboxProgress />}
        </TextInputField>
      )}
    </FieldGroup>
  );
}
