import { gql } from "@apollo/client";

import { TweetNode } from "@/containers/editor/nodes/TweetNode";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "tweet";
export const ATOMIC_ENTITY_TYPE = "TWEET";

const TweetQuery = gql`
  query RichEditorTweetPlugin_tweet($id: Int) {
    node: tweet(id: $id) {
      id
      ...TweetNode_tweet
      ... on Tweet {
        url
      }
    }
  }

  ${TweetNode.fragments.tweet}
`;

function TweetBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: TweetQuery,
  });

  return (
    <AtomicBlock {...atomic}>
      {({ node }) => <TweetNode tweet={node} />}
    </AtomicBlock>
  );
}

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: TweetBlock, editable: false };
};
