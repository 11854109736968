import { gql } from "@apollo/client";
import { Alert } from "swash/Alert";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment SelligentIntegrationConfigFragment on SelligentIntegrationConfig {
    organization
    baseUrl
    apiKey
    apiSecret
    privateSendingLimit
    publicSendingLimit
  }
`;

const ENDPOINTS = [
  "GET /status",
  "GET /organizations/:organization",
  "GET /organizations/:organization/lists",
  "GET /organizations/:organization/lists/:list",
  "GET /organizations/:organization/lists/:list/segments",
  "GET /organizations/:organization/content/:content",
  "GET /organizations/:organization/journeys",
  "GET /organizations/:organization/journeys/:type",
  "GET /organizations/:organization/journeys/:type/:journey",
  "POST /organizations/:organization/journeys/singlebatch/message",
];

function Fields() {
  return (
    <>
      <Alert className="mb-6">
        <p>
          Pour le bon fonctionnement de Selligent au sein de Sirius,
          assurez-vous que vos accès vous permettent d’accéder aux API suivantes
          :
        </p>
        <ul className="mt-2 pl-6">
          {ENDPOINTS.map((endpoint, index) => (
            <li key={index} className="list-disc">
              <code>{endpoint}</code>
            </li>
          ))}
        </ul>
      </Alert>
      <Fieldset>
        <FieldsetField>
          <StringField name="organization" label="Organisation" required />
        </FieldsetField>
        <FieldsetField>
          <StringField name="baseUrl" label="Base URL" required />
        </FieldsetField>
        <FieldsetField>
          <StringField name="apiKey" label="Api Key" required />
        </FieldsetField>
        <FieldsetField>
          <StringField name="apiSecret" label="Api Secret" required />
        </FieldsetField>
        <FieldsetField>
          <StringField
            name="privateSendingLimit"
            label="Limite pour les envois privés"
            type="number"
            parse={(value) => (value ? Number(value) : null)}
          />
        </FieldsetField>
        <FieldsetField>
          <StringField
            name="publicSendingLimit"
            label="Limite pour les envois public"
            type="number"
            parse={(value) => (value ? Number(value) : null)}
          />
        </FieldsetField>
      </Fieldset>
    </>
  );
}

export const ServiceForm = createServiceForm({ Fields });
