import { insertOrReplaceCharacters } from "../modifiers/insertOrReplaceCharacters";
import { moveFromCursor } from "../modifiers/moveFromCursor";
import { selectAllBlocks } from "../modifiers/selectAllBlocks";
import { MAC_OS, handleBinding } from "../utils";

const NO_BREAK_SPACE = "\u00A0";

export const name = "additional-shortcuts";

export const shortcuts = [
  {
    category: "Caractères spéciaux",
    description: "Croix de multiplication",
    insertChar: "×",
    command: "insert-multiply",
    shortcut: MAC_OS ? ["⌥", "8"] : ["Ctrl", "⌥", "*"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "×"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "Guillemet anglais fermant",
    insertChar: "”",
    command: "insert-closing-english-quote",
    shortcut: ["⌥", "⇧", "7"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "”"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "Guillemet anglais ouvrant",
    insertChar: "“",
    command: "insert-opening-english-quote",
    shortcut: ["⌥", "7"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "“"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "Ellipse",
    insertChar: "…",
    command: "insert-ellipsis",
    shortcut: MAC_OS ? ["⌥", ";"] : ["Ctrl", "⌥", ";"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "…"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "Tiret demi-cadratin",
    insertChar: "–",
    command: "insert-en-dash",
    shortcut: MAC_OS ? ["⌥", "⇧", "-"] : ["Ctrl", "8"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "–"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "e dans l’o",
    insertChar: "Œ",
    command: "insert-oe-uppercase-tied-letter",
    shortcut: ["⌥", "⇧", "o"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "Œ"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "E dans l’O",
    insertChar: "œ",
    command: "insert-oe-tied-letter",
    shortcut: ["⌥", "o"],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, "œ"));
    },
  },
  {
    category: "Caractères spéciaux",
    description: "Espace insécable",
    insertChar: "␣",
    command: "insert-no-break-space",
    shortcut: MAC_OS ? ["⌥", " "] : ["Ctrl", " "],
    handleCommand: ({ editorState, setEditorState }) => {
      setEditorState(insertOrReplaceCharacters(editorState, NO_BREAK_SPACE));
    },
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Sélection du curseur au début",
    command: "selection-to-start",
    shortcut: MAC_OS ? ["⌥", "⇧", "ArrowUp"] : ["Ctrl", "⇧", "ArrowUp"],
    handleCommand: (state) => moveFromCursor(state, "UP", true),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Sélection du curseur à la fin",
    command: "selection-to-end",
    shortcut: MAC_OS ? ["⌥", "⇧", "ArrowDown"] : ["Ctrl", "⇧", "ArrowDown"],
    handleCommand: (state) => moveFromCursor(state, "DOWN", true),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Sélection du curseur au mot suivant",
    command: "selection-to-next-word",
    shortcut: MAC_OS ? ["⌥", "⇧", "ArrowRight"] : ["Ctrl", "⇧", "ArrowRight"],
    handleCommand: (state) => moveFromCursor(state, "RIGHT", true),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Sélection du curseur au mot précédent",
    command: "selection-to-previous-word",
    shortcut: MAC_OS ? ["⌥", "⇧", "ArrowLeft"] : ["Ctrl", "⇧", "ArrowLeft"],
    handleCommand: (state) => moveFromCursor(state, "LEFT", true),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Déplacement du curseur au début",
    command: "move-to-start",
    shortcut: MAC_OS ? ["⌥", "ArrowUp"] : ["Ctrl", "ArrowUp"],
    handleCommand: (state) => moveFromCursor(state, "UP"),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Déplacement du curseur à la fin",
    command: "move-to-end",
    shortcut: MAC_OS ? ["⌥", "ArrowDown"] : ["Ctrl", "ArrowDown"],
    handleCommand: (state) => moveFromCursor(state, "DOWN"),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Déplacement du curseur au mot suivant",
    command: "move-to-next-word",
    shortcut: MAC_OS ? ["⌥", "ArrowRight"] : ["Ctrl", "ArrowRight"],
    handleCommand: (state) => moveFromCursor(state, "RIGHT"),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Déplacement du curseur au mot précédent",
    command: "move-to-previous-word",
    shortcut: MAC_OS ? ["⌥", "ArrowLeft"] : ["Ctrl", "ArrowLeft"],
    handleCommand: (state) => moveFromCursor(state, "LEFT"),
  },
  {
    category: "Navigation dans l’éditeur",
    description: "Tout sélectionner",
    command: "select-all-blocks",
    shortcut: ["⌘", "a"],
    handleCommand: (state) => selectAllBlocks(state),
  },
  {
    category: "Général",
    description: "Imprimer",
    shortcut: ["⌘", "p"],
  },
  {
    category: "Général",
    description: "Commenter",
    shortcut: MAC_OS ? ["⌘", "j"] : ["Ctrl", "j"],
  },
  {
    category: "Affichage",
    description: "Activer et désactiver le mode concentration",
    shortcut: MAC_OS ? ["⌘", "e"] : ["Ctrl", "e"],
  },
];

const internalShortcuts = [
  ...shortcuts,
  {
    command: "ignore",
    shortcut: ["⌘", "s"],
    handleCommand: () => {},
  },
];

export const keyBindingFn = (state, event) => {
  for (const action of internalShortcuts) {
    if (!action.command) continue;
    const result = handleBinding(action.shortcut, action.command, event);
    if (result) return result;
  }
  return undefined;
};

export const handleKeyCommand = (state, command) => {
  const action = internalShortcuts.find((action) => action.command === command);
  if (!action) return undefined;
  return action.handleCommand(state);
};
