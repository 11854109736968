/* eslint-disable react/prop-types */
import styled from "@xstyled/styled-components";
import { forwardRef, useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export const Textarea = styled.textareaBox`
  appearance: none;
  padding: 2 2;
  border: 1;
  border-color: layout-border;
  border-radius: base;
  background-color: white;
  font-size: base;
  font-family: base;
  line-height: 22px;
  transition: base;
  color: dusk;

  &::placeholder {
    color: grey-dark;
  }
  &:focus {
    border-color: primary;
  }
  &:hover:not(:disabled) {
    border-color: primary;
  }
  &:active:not(:disabled) {
    box-shadow: active;
  }
  &:disabled {
    opacity: 0.38;
  }

  &:read-only {
    opacity: 0.75;
  }

  &[aria-invalid="true"] {
    border-color: danger-dark;

    &:focus {
      border-color: danger-dark;
    }

    &:hover {
      border-color: danger-dark;
    }
  }
`;

export const DebouncedTextarea = forwardRef(
  ({ value, onChange, delay, ...props }, ref) => {
    const [localValue, setLocalValue] = useState(value);

    useLayoutEffect(() => {
      setLocalValue(value);
    }, [value]);

    const debouncedOnChange = useDebouncedCallback(onChange, delay);

    return (
      <Textarea
        ref={ref}
        value={localValue}
        onChange={(event) => {
          event.persist();
          setLocalValue(event.target.value);
          debouncedOnChange(event);
        }}
        {...props}
      />
    );
  },
);
