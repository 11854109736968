import { gql } from "@apollo/client";

import { PublishedLink, PublishedLinkProps } from "@/components/PublishedLink";
import type { ArticlePublishedLink_articleFragment } from "@/gql-types";

export type ArticlePublishedLinkProps = Omit<PublishedLinkProps, "url"> & {
  article: ArticlePublishedLink_articleFragment;
};

export const ArticlePublishedLink = ({
  article,
  ...props
}: ArticlePublishedLinkProps) => {
  return <PublishedLink url={article.url} {...props} />;
};

ArticlePublishedLink.fragments = {
  article: gql`
    fragment ArticlePublishedLink_article on Article {
      url
    }
  `,
};
