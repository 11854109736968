import * as React from "react";
import { forwardRef } from "react";

const SvgVolumeMute = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.97 3.22a.75.75 0 0 1 1.06 0l16.5 16.5a.75.75 0 1 1-1.06 1.06L3.97 4.28a.75.75 0 0 1 0-1.06Z"
      fill="currentColor"
    />
    <path
      d="M12.906 4.634a1.12 1.12 0 0 0-1.197.085l-.031.024-1.337 1.095a.374.374 0 0 0-.028.555L12.86 8.94a.375.375 0 0 0 .64-.265V5.652a1.15 1.15 0 0 0-.594-1.018ZM13.281 15.717 6.032 8.47a.75.75 0 0 0-.53-.22H4.125A1.125 1.125 0 0 0 3 9.375v5.25a1.125 1.125 0 0 0 1.125 1.125h3.27l4.313 3.53a1.121 1.121 0 0 0 1.212.08 1.15 1.15 0 0 0 .58-1.01v-2.104a.75.75 0 0 0-.219-.53Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVolumeMute);
export default ForwardRef;
