import { forwardRef } from "react";

const SvgErase = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#erase_svg__a)" stroke="currentColor" strokeWidth={1.5}>
      <path strokeLinecap="round" d="M6.083 17.917h11.834" />
      <path
        fill="currentColor"
        strokeLinejoin="round"
        d="m9.09 10.087 4.982-4.982 4.141 4.141-4.981 4.982z"
      />
      <path
        strokeLinejoin="round"
        d="m5.71 13.47 8.363-8.364 4.14 4.14L9.85 17.61z"
      />
    </g>
    <defs>
      <clipPath id="erase_svg__a">
        <path
          fill="currentColor"
          transform="translate(4.5 4.5)"
          d="M0 0h15v15H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgErase);
export default ForwardRef;
