import { gql } from "@apollo/client";

import { EventStatusIndicator } from "./EventStatusIndicator";

export const EventStatusOverlay = ({ event, scale = 0.6, children }) => {
  if (!event) return children;
  return (
    <div className="relative">
      {children}
      {event.statuses.length > 0 && (
        <div
          className="absolute left-2/3 top-1/2 flex rounded-full"
          style={{
            fontSize: `${scale}em`,
          }}
        >
          <EventStatusIndicator event={event} />
        </div>
      )}
    </div>
  );
};

EventStatusOverlay.fragments = {
  event: gql`
    fragment EventStatusOverlay_event on Event {
      statuses {
        id
      }
      ...EventStatusIndicator_event
    }

    ${EventStatusIndicator.fragments.event}
  `,
};
