import { gql } from "@apollo/client";
import { graphql } from "@apollo/react-hoc";
import moment from "moment";
import { compose, withHandlers } from "recompose";

import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { PUBLICATIONS } from "@/config/constants";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";
import { getWorkspaceType } from "@/containers/routes/home-edition/util";

export const defaultSelectLabel = "Sélectionner une édition";

export function formatEditionLabel(date) {
  return `Edition du ${moment(date).format("DD/MM/YYYY")}`;
}

const ListPublicationsQuery = gql`
  query ListPublications($name: String!, $type: String!) {
    publications(
      limit: 60
      where: { name: { eq: $name }, type: { eq: $type } }
    ) {
      nodes {
        id
        date
      }
    }
  }
`;

export const ListPublicationsQueryOptions = {
  options: ({ workspace }) => ({
    variables: {
      name: workspace,
      type: getWorkspaceType(workspace),
    },
    fetchPolicy: "network-only",
  }),
  props: ({
    data: { publications: { nodes = [] } = {} } = {},
    ownProps: { userPublications = [] },
  }) => {
    const userPublicationIds = userPublications?.map((pub) => pub.id);

    return {
      listPublications: nodes.filter(
        ({ id }) => !userPublicationIds.includes(id),
      ),
    };
  },
};

const ListPublicationsSelector = ({
  listPublications = [],
  clickEditionHandler,
}) => {
  const enumPublications = useNodesToEnumArray(listPublications, {
    valueSelector: "date",
    labelSelector: "date",
  });
  return (
    <Form onSubmit={clickEditionHandler}>
      <FormAutoSubmit />
      <EnumField
        aria-label="Sélectionnez une édition"
        placeholder="Sélectionnez une édition"
        enum={enumPublications}
        name="publicationDate"
        sortEntries={() => false}
        labelSelector={({ label }) => formatEditionLabel(label)}
      />
    </Form>
  );
};

const withListPublicationHandlers = withHandlers({
  clickEditionHandler:
    ({ clickEdition, listPublications }) =>
    ({ publicationDate }, form) => {
      const publication = listPublications.find(
        ({ date }) => date === publicationDate,
      );
      clickEdition({
        type: PUBLICATIONS.GENERIC_NAME,
        id: publication.id,
        title: formatEditionLabel(publicationDate),
        name: `publication-${publication.id}`,
      });
      form.reset();
    },
});

const withGraphQL = graphql(
  ListPublicationsQuery,
  ListPublicationsQueryOptions,
);

export default compose(
  withGraphQL,
  withListPublicationHandlers,
)(ListPublicationsSelector);
