/* eslint-disable no-param-reassign */
/* eslint-disable no-irregular-whitespace */
const NO_BREAK_SPACE = "\u00A0";
const USED_NARROW_NO_BREAK_SPACE = NO_BREAK_SPACE; // instead of "\u202F"
const QUOTE_MARK = '"';
const LEFT_DOUBLE_QUOTATION_MARK = "“";
const RIGHT_DOUBLE_QUOTATION_MARK = "”";
const LEFT_POINTING_QUOTATION_MARK = "«";
const RIGHT_POINTING_QUOTATION_MARK = "»";
const APOSTROPHE = "'";
const TYPO_APOSTROPHE = "’";
const QUESTION_MARK = "?";
const EXCLAMATION_MARK = "!";
const SEMICOLON = ";";
const COLON = ":";

const flatMap = (ctx, ast, fn) => {
  const transform = (ctx, node, index, parent) => {
    if (node.children) {
      const out = [];
      for (let i = 0, n = node.children.length; i < n; i++) {
        const xs = transform(ctx, node.children[i], i, node);
        if (xs) {
          for (let j = 0, m = xs.length; j < m; j++) {
            out.push(xs[j]);
          }
        }
      }
      node.children = out;
    }

    return fn(ctx, node, index, parent);
  };

  return transform(ctx, ast, 0, null)[0];
};

const is = (node, type) => {
  if (!node) return false;
  return node.type === type;
};

const detectQuotationMarkNode = (ctx, node) => {
  const { quoteDepth = 0 } = ctx;
  if (!is(node, "PunctuationNode")) return null;
  switch (node.value) {
    case LEFT_DOUBLE_QUOTATION_MARK:
    case LEFT_POINTING_QUOTATION_MARK:
      return { type: "left", oriented: true };
    case RIGHT_DOUBLE_QUOTATION_MARK:
    case RIGHT_POINTING_QUOTATION_MARK:
      return { type: "right", oriented: true };
    case QUOTE_MARK:
      return { type: quoteDepth === 0 ? "left" : "right", oriented: false };
    default:
      return null;
  }
};

const getLeftQuoteMarkChar = (depth = 0) => {
  return depth > 0 ? LEFT_DOUBLE_QUOTATION_MARK : LEFT_POINTING_QUOTATION_MARK;
};

const getRightQuoteMarkChar = (depth = 0) => {
  return depth > 0
    ? RIGHT_DOUBLE_QUOTATION_MARK
    : RIGHT_POINTING_QUOTATION_MARK;
};

const insertOrReplaceWhiteSpaceNode = (parent, index, value) => {
  const node = parent.children[index];
  if (is(node, "WhiteSpaceNode")) {
    node.value = value;
    return [];
  }
  return [{ type: "WhiteSpaceNode", value }];
};

const markUrl = (ctx, node) => {
  if (!ctx.url && node.type === "TextNode" && node.value.startsWith("http")) {
    ctx.url = true;
  }
  if (ctx.url && node.type === "WhiteSpaceNode") {
    ctx.url = false;
  }
};

const markEmoji = (ctx, node, index, parent) => {
  if (!ctx.emoji && node.value === ":" && parent && parent.children) {
    const { children } = parent;
    const childrenLength = children.length;
    if (
      (parent.type === "WordNode" &&
        children[childrenLength - 1] &&
        children[childrenLength - 1].type === "TextNode" &&
        children[childrenLength - 2] &&
        (children[childrenLength - 2].value === ":" ||
          children[childrenLength - 2].value === "::")) ||
      (parent.type === "SentenceNode" &&
        children[childrenLength - 2] &&
        children[childrenLength - 2].type === "WordNode" &&
        children[childrenLength - 3] &&
        (children[childrenLength - 3].value === ":" ||
          children[childrenLength - 3].value === "::"))
    ) {
      ctx.emoji = true;
    }
  }

  if (ctx.emoji && node.type === "WhiteSpaceNode") {
    ctx.emoji = false;
  }
};

const PunctuationNode = (ctx, node, index, parent) => {
  // Add narrow breaking space before ";", "!" or "?"
  if ([QUESTION_MARK, EXCLAMATION_MARK, SEMICOLON].includes(node.value)) {
    if (ctx.url) return [node];
    const whiteSpaceNodes = insertOrReplaceWhiteSpaceNode(
      parent,
      index - 1,
      USED_NARROW_NO_BREAK_SPACE,
    );
    return [...whiteSpaceNodes, node];
  }

  // Add breaking space before ":"
  if (node.value === COLON) {
    if (ctx.url || ctx.emoji || index === 0) return [node];
    const whiteSpaceNodes = insertOrReplaceWhiteSpaceNode(
      parent,
      index - 1,
      NO_BREAK_SPACE,
    );
    return [...whiteSpaceNodes, node];
  }

  // Replace apostrophe with typographical one
  if (node.value === APOSTROPHE) {
    return [{ ...node, value: TYPO_APOSTROPHE }];
  }

  // Handle quotes
  const { orientedQuoteDepth = 0, quoteDepth = 0 } = ctx;
  const mark = detectQuotationMarkNode(ctx, node);
  if (!mark) return [node];
  switch (mark.type) {
    case "left": {
      const whiteSpaceNodes = insertOrReplaceWhiteSpaceNode(
        parent,
        index + 1,
        USED_NARROW_NO_BREAK_SPACE,
      );
      if (mark.oriented) {
        ctx.orientedQuoteDepth = orientedQuoteDepth + 1;
        return [{ ...node, value: getLeftQuoteMarkChar() }, ...whiteSpaceNodes];
      }
      ctx.quoteDepth = quoteDepth + 1;
      return [
        { ...node, value: getLeftQuoteMarkChar(orientedQuoteDepth) },
        ...(orientedQuoteDepth > 0 ? [] : whiteSpaceNodes),
      ];
    }
    case "right": {
      const whiteSpaceNodes = insertOrReplaceWhiteSpaceNode(
        parent,
        index - 1,
        USED_NARROW_NO_BREAK_SPACE,
      );
      if (mark.oriented) {
        ctx.orientedQuoteDepth = orientedQuoteDepth - 1;
        return [
          ...whiteSpaceNodes,
          { ...node, value: getRightQuoteMarkChar() },
        ];
      }
      ctx.quoteDepth = quoteDepth - 1;
      return [
        ...(orientedQuoteDepth > 0 ? [] : whiteSpaceNodes),
        { ...node, value: getRightQuoteMarkChar(orientedQuoteDepth) },
      ];
    }
    default:
      throw new Error(`Unknown quotation mark type "${mark.type}"`);
  }
};

const WordNode = (ctx, node, index, parent) => {
  const firstNode = node.children[0];

  // Handle non-breaking space before etc.
  if (firstNode && firstNode.type === "TextNode" && firstNode.value === "etc") {
    const whiteSpaceNodes = insertOrReplaceWhiteSpaceNode(
      parent,
      index - 1,
      NO_BREAK_SPACE,
    );
    return [...whiteSpaceNodes, node];
  }

  return [node];
};

export const toFrench = (node) => {
  const ctx = {};
  return flatMap(ctx, node, (ctx, node, index, parent) => {
    markUrl(ctx, node, index, parent);
    markEmoji(ctx, node, index, parent);
    switch (node.type) {
      case "WordNode":
        return WordNode(ctx, node, index, parent);

      case "PunctuationNode":
        return PunctuationNode(ctx, node, index, parent);
      default:
        return [node];
    }
  });
};
