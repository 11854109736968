import { getIn, setIn } from "final-form";
import { useEffect } from "react";
import { useForm } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";

/**
 * Synchronize two fields if their values are equals.
 * @param {{ from: string, to: string, isEqual?: (a: any, b: any) => boolean }} params
 */
export const useSyncFields = ({ from, to, isEqual = Object.is }) => {
  const isEqualRef = useLiveRef(isEqual);
  const form = useForm();
  useEffect(() => {
    const isEqual = isEqualRef.current;
    let previousValues;
    form.subscribe(
      ({ values }) => {
        if (!previousValues) {
          previousValues = values;
        }
        const fromValue = getIn(values, from);
        const previousFromValue = getIn(previousValues, from);
        const toValue = getIn(values, to);
        const previousToValue = getIn(previousValues, to);
        const fromModified = !isEqual(fromValue, previousFromValue);
        const previousSynced = isEqual(previousFromValue, previousToValue);
        const synced = isEqual(fromValue, toValue);
        previousValues = values;
        if (fromModified && previousSynced && !synced) {
          form.change(to, fromValue);
          setIn(previousValues, from, fromValue);
        }
      },
      { values: true },
    );
  }, [form, from, to, isEqualRef]);
};
