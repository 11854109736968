import * as React from "react";
import { forwardRef } from "react";

const SvgErase = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.046 3.12a2.5 2.5 0 0 1 3.54-.104l3.639 3.443a2.5 2.5 0 0 1 .151 3.476l-9.545 10.758a2.033 2.033 0 0 1-.034.038h9.046a.5.5 0 1 1 0 1H2.5a.5.5 0 0 1 0-1h4.625l-3.703-3.942a2.5 2.5 0 0 1 0-3.424l9.623-10.244Zm.058 13.505-5.686-6.053L4.15 14.05a1.5 1.5 0 0 0 0 2.054l3.717 3.957a1.5 1.5 0 0 0 2.215-.032l3.02-3.404Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgErase);
export default ForwardRef;
