export const INLINE_STYLE = "BOLD";

export const allowedInlineStyles = [INLINE_STYLE];
export const allowedAttributes = { b: [], strong: [] };

/** @type {import('../types').htmlToStyle} */
export const htmlToStyle = ({ element, getStyle }) => {
  const { tagName } = element;
  const checkHasBoldStyle = () => {
    const styles = getStyle();
    const fontWeight = styles["font-weight"];
    return fontWeight === "bold" || Number(fontWeight) >= 700;
  };
  if (tagName === "b" || tagName === "strong" || checkHasBoldStyle()) {
    return [INLINE_STYLE];
  }

  return [];
};

// to handle paste from Google doc that wraps html in <b> tag
// remove bold attribute when b tag has font-weight:normal
export const transformTags = {
  b: (tagName, attrs) => {
    const isBold =
      !attrs?.style ||
      !(
        attrs?.style?.includes("font-weight:normal") ||
        attrs?.style?.includes("font-weight:400")
      );
    return {
      tagName: isBold ? "b" : "span",
    };
  },
};
