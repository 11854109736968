import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { useNativePrompt } from "@/components/Prompt";

const DetachedStateContext = createContext();
const SetDetachedStateContext = createContext();
export const DetachedProvider = ({ children }) => {
  const [detached, setDetached] = useState({ detached: false, value: null });
  return (
    <DetachedStateContext.Provider value={detached}>
      <SetDetachedStateContext.Provider value={setDetached}>
        {children}
      </SetDetachedStateContext.Provider>
    </DetachedStateContext.Provider>
  );
};

export const useDetachedState = () => useContext(DetachedStateContext);
const useSetDetachedState = () => useContext(SetDetachedStateContext);

const getSelectedValues = (options) => {
  const result = [];
  if (options) {
    for (let index = 0; index < options.length; index++) {
      const option = options[index];
      if (option.selected) {
        result.push(option.value);
      }
    }
  }
  return result;
};

const getValue = (event, currentValue, valueProp, isReactNative) => {
  if (
    !isReactNative &&
    event.nativeEvent &&
    event.nativeEvent.text !== undefined
  ) {
    return event.nativeEvent.text;
  }
  if (isReactNative && event.nativeEvent) {
    return event.nativeEvent.text;
  }
  const {
    target: { type, value, checked },
  } = event;
  switch (type) {
    case "checkbox":
      if (valueProp !== undefined) {
        // we are maintaining an array, not just a boolean
        if (checked) {
          // add value to current array value
          return Array.isArray(currentValue)
            ? currentValue.concat(valueProp)
            : [valueProp];
        } else {
          // remove value from current array value
          if (!Array.isArray(currentValue)) {
            return currentValue;
          }
          const index = currentValue.indexOf(valueProp);
          if (index < 0) {
            return currentValue;
          } else {
            return currentValue
              .slice(0, index)
              .concat(currentValue.slice(index + 1));
          }
        }
      } else {
        // it's just a boolean
        return !!checked;
      }
    case "select-multiple":
      return getSelectedValues(event.target.options);
    default:
      return value;
  }
};

const isEvent = (eventOrValue) => Boolean(eventOrValue && eventOrValue.target);

export const useDetachedInputState = ({
  value: rootValue,
  onChange: rootOnChange,
}) => {
  const onChangeRef = useLiveRef(rootOnChange);
  const [active, setActive] = useState(false);
  const sessionRef = useRef({});
  const [detachedState, setLocalDetachedState] = useState(
    active
      ? { detached: true, value: rootValue }
      : { detached: false, value: null },
  );
  const setRemoteDetachedState = useSetDetachedState();

  const setDetachedState = useCallback(
    (state) => {
      setLocalDetachedState(state);
      setRemoteDetachedState?.(state);
    },
    [setRemoteDetachedState],
  );

  useNativePrompt({
    message:
      "Les dernières modifications que vous avez apportées ne seront pas enregistrées.",
    when: detachedState.detached && detachedState.value !== rootValue,
  });

  // Sync detached mode when "active" changes
  const previousActive = usePrevious(active);

  useEffect(() => {
    if (previousActive === undefined || previousActive === active) return;

    // Update detached states
    setDetachedState(
      active
        ? { detached: true, value: rootValue }
        : { detached: false, value: null },
    );

    if ("eventOrValue" in sessionRef.current && !sessionRef.current.cancelled) {
      onChangeRef.current(sessionRef.current.eventOrValue);
    }

    // Reset session
    sessionRef.current = {};
  }, [previousActive, active, rootValue, onChangeRef, setDetachedState]);

  const onChange = useCallback(
    (eventOrValue) => {
      if (active) {
        // Persist the event if it is a SyntheticEvent from React
        if (isEvent(eventOrValue) && eventOrValue.persist) {
          eventOrValue.persist();
        }
        sessionRef.current.eventOrValue = eventOrValue;

        // Synchronize value
        setDetachedState({
          detached: true,
          value: isEvent(eventOrValue) ? getValue(eventOrValue) : eventOrValue,
        });
      } else {
        onChangeRef.current(eventOrValue);
      }
    },
    [active, onChangeRef, setDetachedState],
  );
  const value = detachedState.detached ? detachedState.value : rootValue;
  return {
    value,
    onChange,
    onFocus: () => setActive(true),
    onBlur: (evt) => {
      // avoid triggered blur after prompt cancel
      if (document.activeElement !== evt.target) {
        setActive(false);
        onChangeRef.current(value);
      }
    },
  };
};
