import * as React from "react";
import { forwardRef } from "react";

const SvgAlertBubbleOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.84 17.439c.065-.203-.045-.494-.153-.705a2.111 2.111 0 0 0-.11-.183 9.366 9.366 0 0 1-1.306-5.223C2.499 6.071 7.056 2.01 12.446 2.26c4.7.22 8.472 3.658 9.187 8.023a9.33 9.33 0 0 1 .116 1.96c-.245 5.263-4.637 9.4-10.026 9.149-.857-.04-2.004-.31-2.626-.515a17.797 17.797 0 0 1-1.401-.54 1.456 1.456 0 0 0-1.088-.034l-3.23.999a.753.753 0 0 1-.22.047.45.45 0 0 1-.392-.63l1.073-3.28Z"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.002 7.564c-.035-.562.486-.964.998-.964s1.033.402.998.964l-.249 6.341a.686.686 0 0 1-.22.488.774.774 0 0 1-.529.207.774.774 0 0 1-.53-.207.686.686 0 0 1-.22-.488l-.248-6.34Zm1.008-.016Zm-.02 0ZM11 16.6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlertBubbleOutline);
export default ForwardRef;
