/**
 * Get the block relative to the current block key.
 * @param {EditorState} editorState
 * @param {string} blockKey
 * @param {'previous' | 'next'} relative determine the relative position
 */
export function getAdjacentBlock(editorState, blockKey, relative) {
  const blocks = editorState.getCurrentContent().getBlockMap();
  const blocksKeySeq = blocks.keySeq();
  const blockIndex = blocksKeySeq.findIndex((key) => key === blockKey);

  if (blockIndex === 0 && relative === "previous") return null;
  if (blockIndex === blocks.size - 1 && relative === "next") return null;

  const adjacentBlockIndex =
    relative === "next" ? blockIndex + 1 : blockIndex - 1;
  const adjacentBlockKey = blocksKeySeq.get(adjacentBlockIndex);
  return adjacentBlockKey ? blocks.get(adjacentBlockKey) : null;
}
