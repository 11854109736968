import { ComponentProps, forwardRef } from "react";
import { cn } from "swash/utils/classNames";

type ModernCapsuleLimitProps = ComponentProps<"hr"> & {
  position?: "top" | "bottom";
};

export const ModernCapsuleLimit = forwardRef<
  HTMLHRElement,
  ModernCapsuleLimitProps
>(function ModernCapsuleLimit({ position, ...props }, ref) {
  return (
    <hr
      ref={ref}
      {...props}
      className={cn(
        "absolute block w-full border-0 border-b border-dashed border-secondary-border",
        position === "top" && "top-[-8px]",
        position === "bottom" && "bottom-[-9px]",
        props.className,
      )}
    />
  );
});
