import articles from "@/services/cache/resolvers/articles";
import columns from "@/services/cache/resolvers/columns";

export const { defaults, resolvers } = [columns, articles].reduce(
  (all, v) => ({
    defaults: { ...all.defaults, ...v.defaults },
    resolvers: {
      Query: { ...all.resolvers.Query, ...v.resolvers?.Query },
      Mutation: { ...all.resolvers.Mutation, ...v.resolvers?.Mutation },
    },
  }),
  {
    defaults: {},
    resolvers: { Query: {}, Mutation: {} },
  },
);
