import { memo } from "react";

import { Italic } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive } from "../utils";
import { INLINE_STYLE, command } from "./italic";

const SHORTCUT = ["⌘", "i"];

export const shortcuts = [
  {
    description: "Italique",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "italic-control";

const ItalicToolbarButton = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      label="Italique"
      command={command}
      shortcut={SHORTCUT}
    >
      <Italic />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  const hasStyle = editorState.getCurrentInlineStyle().has(INLINE_STYLE);
  const inverted = checkIsBlockActive(editorState, "blockquote");
  return <ItalicToolbarButton active={inverted ? !hasStyle : hasStyle} />;
};

BlockControls.group = "inline-style";
