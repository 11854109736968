import { getHTMLFromBlock } from "../convert/getHTMLFromBlock";

/**
 * Html to node
 * @param {string} tagName
 * @param {string} html
 * @returns {HTMLElement}
 */
const htmlToNode = (tagName, html) => {
  const node = document.createElement(tagName);
  node.innerHTML = html;
  return node;
};

/**
 * wrapElement
 * @param {string} tagName
 * @param {HTMLElement | string} element
 * @param {boolean | undefined} addLineReturn
 * @returns {HTMLElement}
 */
const wrapElement = (tagName, element, addLineReturn = true) => {
  const node = document.createElement(tagName);
  if (addLineReturn) node.appendChild(document.createTextNode("\n"));
  node.appendChild(element);
  if (addLineReturn) node.appendChild(document.createTextNode("\n"));
  return node;
};

/**
 * blocksToHtml
 * @param {import('draft-js').ContentBlock[]} blocks
 * @param {import('draft-js').ContentState} contentState
 * @returns {string}
 */
export const blocksToHtml = (blocks, contentState) => {
  const entityMap = contentState.getEntityMap();
  const root = document.createElement("div");

  /** @type {{nodes: (HTMLElement|Text)[], type: string | null}} */
  let list = { nodes: [], type: null };
  blocks.forEach((block) => {
    const blockHtml = getHTMLFromBlock(entityMap, block);
    const blockType = block.getType();
    if (list.type && blockType !== list.type) {
      root.appendChild(document.createTextNode("\n"));
      list.nodes.push(document.createTextNode("\n"));
      const nodeList = document.createElement(
        list.type === "ordered-list-item" ? "ol" : "ul",
      );
      list.nodes.forEach((node) => nodeList.appendChild(node));
      root.appendChild(nodeList);
      list = { nodes: [], type: null };
    }

    (() => {
      switch (blockType) {
        case "paragraph":
        case "unstyled":
          root.appendChild(document.createTextNode("\n"));
          return root.appendChild(htmlToNode("p", blockHtml));
        case "unordered-list-item":
        case "ordered-list-item":
          list.type = blockType;
          list.nodes.push(document.createTextNode("\n"));
          list.nodes.push(root.appendChild(htmlToNode("li", blockHtml)));
          return;
        case "blockquote":
          root.appendChild(document.createTextNode("\n"));
          return root.appendChild(htmlToNode("blockquote", blockHtml));
        case "header-two":
          root.appendChild(document.createTextNode("\n"));
          return root.appendChild(htmlToNode("h2", blockHtml));
        case "header-three":
          root.appendChild(document.createTextNode("\n"));
          return root.appendChild(htmlToNode("h3", blockHtml));
        case "atomic": {
          const entityKey = block.getEntityAt(0);
          if (!entityKey) throw new Error("Entity key not found");
          const entity = contentState.getEntity(entityKey);
          if (!entity) throw new Error("Entity not found");
          const entityType = entity.getType();
          const { media: metadata } = entity.getData();

          switch (entityType) {
            case "SEPARATOR":
              root.appendChild(document.createTextNode("\n"));
              return root.appendChild(document.createElement("hr"));

            case "ARTICLE_MEDIA": {
              switch (metadata.__typename) {
                case "Video":
                case "Tweet":
                case "Article": {
                  return articleBlockToHtml(root, metadata);
                }

                case "Image": {
                  return imageBlockToHtml(root, metadata);
                }

                case "Snippet": {
                  return snippetBlockToHtml(root, metadata);
                }

                case "CustomTypeContent":
                case "Product":
                case "ProductSummary": {
                  root.appendChild(document.createTextNode("\n"));
                  const articleMediaNode = document.createElement("p");
                  articleMediaNode.appendChild(document.createTextNode("\n"));
                  articleMediaNode.appendChild(
                    document.createTextNode(metadata.name ?? metadata.label),
                  );
                  articleMediaNode.appendChild(document.createTextNode("\n"));
                  return root.appendChild(articleMediaNode);
                }
                default:
                  throw new Error(
                    `Unknown metadata type "${metadata.__typename}"`,
                  );
              }
            }

            case "CONTRIBUTION": {
              root.appendChild(document.createTextNode("\n"));
              const articleMediaNode = document.createElement("div");
              articleMediaNode.appendChild(document.createTextNode("\n"));
              articleMediaNode.appendChild(
                wrapElement(
                  "p",
                  wrapElement(
                    "strong",
                    document.createTextNode(metadata.authorName),
                    false,
                  ),
                ),
              );
              articleMediaNode.appendChild(document.createTextNode("\n"));
              articleMediaNode.appendChild(
                wrapElement("p", document.createTextNode(metadata.text)),
              );
              articleMediaNode.appendChild(document.createTextNode("\n"));
              return root.appendChild(articleMediaNode);
            }
            case "IMAGE": {
              return imageBlockToHtml(root, metadata);
            }
            case "TWEET":
            case "VIDEO":
            case "ARTICLE": {
              return articleBlockToHtml(root, metadata);
            }
            case "SNIPPET": {
              return snippetBlockToHtml(root, metadata);
            }
            default:
              throw new Error(`Unknown entity type "${entityType}"`);
          }
        }

        default:
          throw new Error(`Unknown block type "${blockType}"`);
      }
    })();
  });

  return root.innerHTML;
};
const snippetBlockToHtml = (root, metadata) => {
  root.appendChild(document.createTextNode("\n"));
  const articleMediaNode = document.createElement("div");
  articleMediaNode.appendChild(document.createTextNode("\n"));
  articleMediaNode.appendChild(
    wrapElement("p", document.createTextNode(metadata.snippetTitle)),
  );
  articleMediaNode.appendChild(document.createTextNode("\n"));
  articleMediaNode.appendChild(
    wrapElement("code", document.createTextNode(metadata.code)),
  );
  articleMediaNode.appendChild(document.createTextNode("\n"));

  return root.appendChild(wrapElement("div", articleMediaNode));
};

const articleBlockToHtml = (root, metadata) => {
  root.appendChild(document.createTextNode("\n"));
  const articleMediaNode = document.createElement("a");
  articleMediaNode.appendChild(document.createTextNode("\n"));
  articleMediaNode.target = "_blank";
  articleMediaNode.href = metadata.url;
  articleMediaNode.appendChild(
    document.createTextNode(
      metadata.title || metadata.videoTitle || metadata.text,
    ),
  );
  articleMediaNode.appendChild(document.createTextNode("\n"));
  return root.appendChild(wrapElement("p", articleMediaNode));
};

const imageBlockToHtml = (root, metadata) => {
  root.appendChild(document.createTextNode("\n"));
  const articleMediaNode = document.createElement("img");
  articleMediaNode.appendChild(document.createTextNode("\n"));
  articleMediaNode.src = metadata.url;
  articleMediaNode.alt = metadata.caption;
  return root.appendChild(wrapElement("p", articleMediaNode));
};
