import * as React from "react";
import { forwardRef } from "react";

const SvgArrowBarRight = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.744 17.749a.888.888 0 0 1-1.238 0l-5.25-5.143a.845.845 0 0 1 0-1.212l5.25-5.143a.888.888 0 0 1 1.238 0 .845.845 0 0 1 0 1.212L5.112 12l4.632 4.537a.845.845 0 0 1 0 1.212Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 12c0 .552-.348 1-.777 1H5.777C5.348 13 5 12.552 5 12s.348-1 .776-1h9.447c.43 0 .777.448.777 1ZM20 21a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v16a1 1 0 0 1-1 1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowBarRight);
export default ForwardRef;
