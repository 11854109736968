import { gql } from "@apollo/client";

import { useDragUrl } from "@/containers/Dnd";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";

import { MediaLayout } from "./MediaLayout";
import { getMediaEventTooltip } from "./util";

export function MediaTweet({
  media: { media: tweet, ...mediaProps },
  draggable,
  onDelete,
  deletable,
  expanded,
  dropping,
  ...props
}) {
  const [, dragRef] = useDragUrl(() => tweet.url);

  return (
    <MediaLayout
      draggable={draggable}
      ref={draggable ? dragRef : null}
      deletable={deletable}
      onDelete={() => onDelete(tweet.id)}
      expanded={expanded}
      {...props}
    >
      <TweetNode tweet={tweet} tooltip={getMediaEventTooltip(mediaProps)} />
    </MediaLayout>
  );
}

MediaTweet.fragments = {
  media: gql`
    fragment MediaTweet_media on Media {
      id
      ...TweetNode_tweet
    }
    ${TweetNode.fragments.tweet}
  `,
};
