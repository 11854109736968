import * as React from "react";
import { forwardRef } from "react";

const SvgPaper = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.25 9.75A.75.75 0 0 0 11 9V6a.75.75 0 0 0-.75-.75h-3A.75.75 0 0 0 6.5 6v3a.75.75 0 0 0 .75.75h3ZM13.25 5.5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3ZM13.25 8.5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3ZM6.75 12a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5ZM7.25 14.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9ZM6.75 18a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4Zm2-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPaper);
export default ForwardRef;
