import { forwardRef } from "react";

const SvgSubscript = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17.304 19.5a.5.5 0 0 1-.5-.5v-4.56l-.752.672a.5.5 0 0 1-.694-.027l-.019-.02a.5.5 0 0 1 .007-.7l1.466-1.468a.5.5 0 0 1 .354-.147h.334a.5.5 0 0 1 .5.5V19a.5.5 0 0 1-.5.5h-.196Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M14.25 6h-7.5M10.875 6v12"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSubscript);
export default ForwardRef;
