// @ts-check
import * as React from "react";
import { useForm, useFormState } from "react-final-form";
import { Button } from "swash/Button";

/**
 *
 * @param {import('final-form').FormApi} form
 * @param {object} values
 */
function changeFormValues(form, values) {
  form.batch(() => {
    Object.entries(values).forEach(([key, value]) => {
      form.change(key, value);
    });
  });
}

/**
 * @typedef BaseFormResetProps
 * @property {React.ReactNode?} [children="Réinitialiser"]
 * @property {object?} [initialValues] Reset form to this initial values if provided, else use `form.reset()`
 *
 * @typedef {BaseFormResetProps & React.ComponentProps<typeof Button>} FormResetProps
 */

/**
 * @type {React.FC<FormResetProps>}
 */
export const FormReset = React.forwardRef(
  (
    { children = "Réinitialiser", initialValues, disabled, onClick, ...props },
    ref,
  ) => {
    const form = useForm();
    const { submitting, dirty } = useFormState({
      subscription: { submitting: true, dirty: true },
    });

    return (
      <Button
        ref={ref}
        type="reset"
        variant="secondary"
        appearance="text"
        disabled={disabled !== undefined ? disabled : submitting || !dirty}
        onClick={(event) => {
          if (onClick) onClick(event);
          if (event.defaultPrevented) return;
          if (initialValues) {
            changeFormValues(form, initialValues);
          } else {
            form.reset();
          }
        }}
        {...props}
      >
        {children}
      </Button>
    );
  },
);
