import {
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  DialogDisclosure,
  type DialogDisclosureProps,
  DialogStore,
  type DialogStoreProps,
  useDialogStore,
} from "swash/v2/Dialog";

export const createRemoteDialog = <TProps extends object>(
  DialogComponent: React.FC<{ store: DialogStore } & Partial<TProps>>,
) => {
  const RemoteDialogContext = createContext<{
    setProps: React.Dispatch<React.SetStateAction<Partial<TProps>>>;
    store: DialogStore;
  }>(null!);
  const RemoteDialogProvider: React.FC<
    {
      children: ReactNode;
      options: DialogStoreProps;
    } & Partial<TProps>
  > = ({ children, options, ...otherProps }) => {
    const store = useDialogStore(options);
    const [props, setProps] = useState<Partial<TProps>>({});

    const value = useMemo(
      () => ({
        setProps,
        store,
      }),
      [store, setProps],
    );

    return (
      <RemoteDialogContext.Provider value={value}>
        <DialogComponent store={store} {...props} {...otherProps} />
        {children}
      </RemoteDialogContext.Provider>
    );
  };

  const RemoteDialogDisclosure = forwardRef<
    HTMLButtonElement,
    {
      children?: React.ReactNode;
      dialogProps: Partial<TProps>;
      render?: DialogDisclosureProps["render"];
      onClick?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      ) => void;
    }
  >(({ dialogProps, ...props }, ref) => {
    const value = useContext(RemoteDialogContext);
    if (!value) return null;
    const { setProps, store } = value;
    return (
      <DialogDisclosure
        store={store}
        {...props}
        onClick={(event) => {
          //merge props, new props have priority
          setProps((prevProps) => ({ ...prevProps, ...dialogProps }));
          if (props.onClick) {
            props.onClick(event);
          }
        }}
        ref={ref}
      />
    );
  });

  const useRemoteDialogContext = () => {
    return useContext(RemoteDialogContext);
  };

  return {
    RemoteDialogProvider,
    RemoteDialogDisclosure,
    useRemoteDialogContext,
  };
};
