import { ReactElement, ReactNode, RefObject, useRef } from "react";
import { useIsomorphicLayoutEffect } from "swash/utils/useIsomorphicLayoutEffect";

import { useMention } from "@/components/rich-editor/plugins/mention/MentionPluginContext";
import { useMentionRemoteSelect } from "@/components/rich-editor/plugins/mention/MentionRemoteSelectProvider";

export interface MentionSuggestionsPortalProps {
  offsetKey: string;
  children: ReactNode;
}

export const MentionSuggestionsPortal = ({
  offsetKey,
  children,
}: MentionSuggestionsPortalProps): ReactElement => {
  const { register, unregister } = useMention();
  const { updateAnchorRect } = useMentionRemoteSelect();
  const anchorRef = useRef<HTMLSpanElement>();

  const updateClientRect = (): void => {
    updateAnchorRect(
      offsetKey,
      () => anchorRef.current?.getBoundingClientRect() ?? null,
    );
  };

  useIsomorphicLayoutEffect(() => {
    register(offsetKey);
    updateClientRect();
    return () => {
      unregister(offsetKey);
    };
  }, []);

  return <span ref={anchorRef as RefObject<HTMLSpanElement>}>{children}</span>;
};
