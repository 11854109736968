import moment from "moment";

/*
 *  move element in a list/array
 */
export const moveItems = <T>(
  fromIndex: number,
  toIndex: number,
  list: readonly T[],
): T[] => {
  const movedElement = list[fromIndex];
  const newList = Array.from(list);
  newList.splice(fromIndex, 1);
  newList.splice(toIndex, 0, movedElement!);
  return newList;
};

/*
 * Reset rank property for a list
 */
export const cleanRanks = <T>(values: readonly T[]): (T & { rank: number })[] =>
  values.map((resource, index) => ({
    ...resource,
    rank: index,
  }));

/*
 * Remove element from a list based on id
 */
export const removeFromListById = <ID, T extends { id?: ID }>(
  id: ID,
  list: readonly T[],
): T[] => list.filter((item) => item.id !== id);

/*
 * Find element in a list based on an id
 */
export const findById = <ID, T extends { id?: ID }>(
  list: readonly T[],
  id: ID,
): T | undefined => list.find((item) => item.id === id);

/**
 * Split Dnd ID 'type:id' -> { type, id, tagTemplateId }
 */
export const parseDndId = (
  value: string,
): { type: string; id: number; tagTemplateId?: number } => {
  const [type, id, tagTemplateId] = value.split(":");
  return {
    type: type!,
    id: parseInt(id ?? "0", 10),
    ...(tagTemplateId && { tagTemplateId: Number(tagTemplateId) }),
  };
};

export const shortenSentence = (
  sentence: string,
  length: number,
  separator = " ",
): string => {
  if (sentence.length <= length) return sentence;
  return `${sentence.substr(
    0,
    sentence.replace(/(\r\n|\n|\r)/gim, " ").lastIndexOf(separator, length),
  )} ...`;
};

export const formatPublicationDate = (date: moment.MomentInput): string => {
  const mDate = moment(date);
  return mDate.isSame(moment(), "day")
    ? `à ${mDate.format("H:mm")}`
    : `le ${mDate.format("DD/MM/YYYY")}`;
};

export const omitTypename = <T extends { __typename?: any }>({
  __typename,
  ...values
}: T) => values;
