import { RichEditor } from "@/components/rich-editor/RichEditor";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";
import { useBlockTemplates } from "@/containers/BlockTemplates";

import { useArticleNodePlugins } from "./Plugins";

export const ArticleNodeEditor = ({ contentState }) => {
  const plugins = useArticleNodePlugins();
  const blockTemplates = useBlockTemplates();

  const editor = useRichEditorState({
    contentState,
    setContentState: () => {},
    readOnly: true,
    plugins,
    blockTemplates,
  });

  return <RichEditor {...editor} />;
};
