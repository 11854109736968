import { ContentState, SelectionState } from "draft-js-es";

import { applyComposeEntity } from "@/components/rich-editor/utils/CompositeEntity";

import type { EnhanceTextBlock } from "../../convert/fromNodes";

export const ATOMIC_ENTITY_TYPE = "COMPOSITE";

export const COMPOSE_ENTITY_TYPE = "COMMENT";

export const enhanceTextBlock: EnhanceTextBlock = (
  initialBlock,
  { comments },
  state,
) => {
  if (!comments?.length) return initialBlock;

  let contentState = ContentState.createFromBlockArray([initialBlock]);
  const blockKey = initialBlock.getKey();

  comments.forEach(
    ({ commentId, startOffset, endOffset, listIndex, ...data }) => {
      const range = new SelectionState({
        anchorKey: blockKey,
        anchorOffset: startOffset,
        focusKey: blockKey,
        focusOffset: endOffset,
      });
      contentState = applyComposeEntity(contentState, range, {
        type: COMPOSE_ENTITY_TYPE,
        id: commentId,
        key: String(commentId),
        ...data,
      });
    },
  );

  state.entityMap.merge(contentState.getEntityMap());

  return contentState.getFirstBlock();
};
