import { ThemeProvider, defaultTheme, th } from "@xstyled/styled-components";
import { transparentize } from "polished";
import React from "react";

const aliasColor = /** @type {Function} */ (th.color);

const defaultFonts = [
  "ui-sans-serif",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  "Segoe UI",
  "Roboto",
  "Helvetica Neue",
  "Arial",
  "Noto Sans",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji",
];

const theme = {
  ...defaultTheme,
  backgrounds: {
    main: "#fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAGFBMVEXt7vDv8fP19/n19/n19/n19/kAAAAAAACgaIAXAAAAQElEQVQImXWNQQ4AIAjDCv9/tCAjEqNclrFBMY8xp8VSXBJb24WSsDDtKWekV/Nj5dmFK9UtP5AsDxCDQIOatwCG9QFHhJI9wgAAAABJRU5ErkJggg==')",
  },
  fonts: {
    base: ["Source Sans Pro", "Source Sans Pro Fallback", ...defaultFonts].join(
      ",",
    ),
    accent: ["Proxima Nova", "Proxima Nova Fallback", ...defaultFonts].join(
      ",",
    ),
  },
  fontSizes: {
    "2xs": 10,
    xs: 12,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 28,
    "2xl": 40,
  },
  sizes: {
    "article-width": 698,
    "dialog-panel-width": 400,
    "dialog-filter-field-width": 334,
  },
  colors: {
    // Dusk
    "dusk-lightest": "#3F5973",
    "dusk-lighter": "#384F66",
    "dusk-light": "#314559",
    dusk: "#2A3B4C",
    "dusk-dark": "#22303F",
    "dusk-darker": "#1B2631",
    "dusk-darkest": "#141C24",

    // Grey
    "grey-lightest": "#F9FAFB",
    "grey-lighter": "#E2E6E9",
    "grey-light": "#CAD1D8",
    grey: "#B3BDC7",
    "grey-dark": "#9BA8B5",
    "grey-darker": "#8494A4",
    "grey-darkest": "#6C7F93",

    // Green
    "green-lightest": "#F7FDF9",
    "green-lighter": "#C7F0D6",
    "green-light": "#96E4B5",
    green: "#65D793",
    "green-dark": "#34CB70",
    "green-darker": "#289A55",
    "green-darkest": "#1B693A",

    // Red
    "red-lightest": "#FFF5F5",
    "red-lighter": "#FFB8BA",
    "red-light": "#FF7A7F",
    red: "#FF3D44",
    "red-dark": "#FF0008",
    "red-darker": "#C20006",
    "red-darkest": "#850004",

    // Blue
    "blue-lightest": "#f5f9fb",
    "blue-lighter": "#C7E4F0",
    "blue-light": "#96CEE4",
    blue: "#65B7D7",
    "blue-dark": "#34A0CB",
    "blue-darker": "#287A9A",
    "blue-darkest": "#1B5369",

    // Yellow
    "yellow-lightest": "#FFFBF5",
    "yellow-lighter": "#FFE5B8",
    "yellow-light": "#FFCE7A",
    yellow: "#FFB83D",
    "yellow-dark": "#FFA200",
    "yellow-darker": "#C27B00",
    "yellow-darkest": "#855400",

    // Primary
    "primary-lightest": aliasColor("blue-lightest"),
    "primary-lighter": aliasColor("blue-lighter"),
    "primary-light": aliasColor("blue-light"),
    primary: aliasColor("blue"),
    "primary-dark": aliasColor("blue-dark"),
    "primary-darker": aliasColor("blue-darker"),
    "primary-darkest": aliasColor("blue-darkest"),

    // Success
    "success-lightest": aliasColor("green-lightest"),
    "success-lighter": aliasColor("green-lighter"),
    "success-light": aliasColor("green-light"),
    success: aliasColor("green"),
    "success-dark": aliasColor("green-dark"),
    "success-darker": aliasColor("green-darker"),
    "success-darkest": aliasColor("green-darkest"),

    // Warning
    "warning-lightest": aliasColor("yellow-lightest"),
    "warning-lighter": aliasColor("yellow-lighter"),
    "warning-light": aliasColor("yellow-light"),
    warning: aliasColor("yellow"),
    "warning-dark": aliasColor("yellow-dark"),
    "warning-darker": aliasColor("yellow-darker"),
    "warning-darkest": aliasColor("yellow-darkest"),

    // Danger
    "danger-lightest": aliasColor("red-lightest"),
    "danger-lighter": aliasColor("red-lighter"),
    "danger-light": aliasColor("red-light"),
    danger: aliasColor("red"),
    "danger-dark": aliasColor("red-dark"),
    "danger-darker": aliasColor("red-darker"),
    "danger-darkest": aliasColor("red-darkest"),

    // Info
    "info-lightest": aliasColor("blue-lightest"),
    "info-lighter": aliasColor("blue-lighter"),
    "info-light": aliasColor("blue-light"),
    info: aliasColor("blue"),
    "info-dark": aliasColor("blue-dark"),
    "info-darker": aliasColor("blue-darker"),
    "info-darkest": aliasColor("blue-darkest"),

    // Other
    on: aliasColor("dusk"),
    "on-light": aliasColor("grey-darkest"),
    "on-lighter": aliasColor("grey-darker"),
    "on-priced": aliasColor("yellow"),

    text: aliasColor("on"),
    placeholder: aliasColor("grey-dark"),
    background: aliasColor("primary-lightest"),
    "background-neutral": aliasColor("grey-lightest"),

    draft: aliasColor("grey-light"),

    "layout-border": aliasColor("grey-lighter"),

    "panel-background": aliasColor("white"),

    "primary-nav-color": aliasColor("primary"),
    "primary-nav-background": aliasColor("dusk"),
    "primary-nav-active": aliasColor("dusk-darker"),
    "primary-nav-hover": aliasColor("dusk-light"),
    "primary-nav-border": aliasColor("dusk-lighter"),

    "secondary-nav-color": aliasColor("grey-dark"),
    "secondary-nav-background": aliasColor("primary-lightest"),
    "secondary-nav-active": aliasColor("white"),
    "secondary-nav-hover": aliasColor("primary"),
    "secondary-nav-border": aliasColor("grey-light"),
    "secondary-nav-selected-border": aliasColor("dusk"),

    "change-deleted": aliasColor("red-darkest"),
    "change-deleted-media": aliasColor("red"),
    "change-deleted-bg": aliasColor("red-lightest"),
    "change-added": aliasColor("green-darkest"),
    "change-added-media": aliasColor("green"),
    "change-added-bg": aliasColor("green-lightest"),

    free: aliasColor("grey"),
  },
  space: [0, 4, 8, 16, 24, 32, 48, 96, 144, 192],
  states: {
    ...defaultTheme.states,
  },
  transitions: {
    base: "150ms ease-in-out",
  },
  screens: defaultTheme.screens,
  shadows: {
    glow: (p) => `0 0 0 2px ${transparentize(0.7, aliasColor("primary")(p))}`,
    "glow-danger": (p) =>
      `0 0 0 2px ${transparentize(0.7, aliasColor("danger")(p))}`,
    active: (p) => `0 0 0 4px ${transparentize(0.7, aliasColor("primary")(p))}`,
    "active-danger": (p) =>
      `0 0 0 4px ${transparentize(0.7, aliasColor("danger")(p))}`,
    popover: (p) =>
      `0 2px 10px 0 ${transparentize(0.4, aliasColor("grey")(p))}`,
    dialog: (p) =>
      `0 2px 10px 0 ${transparentize(0.4, aliasColor("dusk-dark")(p))}`,
    "cell-focus": (p) =>
      `0 0 2px 1px inset ${transparentize(0.7, aliasColor("primary")(p))}`,
    card: `0px 7px 14px 0px #3C425714`,
    "toolbar-drawer": `inset 0px 4px 14px 0px #3C425714`,
    toolbar: "0px 4px 14px 0px #3C42570A",
    panel: `rgba(60,66,87,0.08) -10px 0 12px 0px, rgba(0, 0, 0, 0.04) -2px 0 4px 0px`,
    banner: (p) =>
      `inset 0 -4px 60px -30px ${transparentize(0.7, aliasColor("gray")(p))}`,
    "article-content": `0px -8px 14px rgba(60, 66, 87, 0.04)`,
    tab: `0px 0px 8px 0px #2A3B4C14`,
    switch: (p) => `0 1px 2px 0 ${transparentize(0.8, aliasColor("dusk")(p))}`,
    "audio-player": (p) =>
      `0px 0px 10px ${transparentize(0.9, aliasColor("dusk")(p))}`,
  },
  radii: {
    sm: 4,
    base: 6,
    lg: 16,
    xl: 24,
  },
  zIndices: {
    "sticky-under": 2,
    "capsule-toolbar": 3,
    "sticky-upper": 3,
    "sticky-decorator": 4,
    notifier: 100,
    "plugins-toolbar-popover": 150,
    "secondary-side-panel": 200,
    "side-panel": 300,
    "content-backdrop": 400,
    "main-nav": 502,
    nav: 500,
    backdrop: 800,
    "floating-alert": 1000,
    "editor-popover": 2500,
    dialog: 3000,
    popover: 3000,
    tooltip: 4000,
    toast: 5000,
  },
};

/**
 * @typedef {typeof theme} Theme
 */

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
export function ThemeInitializer({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
