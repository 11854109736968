import * as React from "react";
import { forwardRef } from "react";

const SvgSproutOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M23.028 3.75a.563.563 0 0 0-.528-.528c-4.781-.281-8.614 1.165-10.252 3.868-1.09 1.801-1.066 4.002.05 6.094a6.507 6.507 0 0 0-1.451 2.868L8.99 14.195c.764-1.51.759-3.09-.03-4.393C7.76 7.82 4.969 6.757 1.49 6.962a.562.562 0 0 0-.528.53c-.205 3.477.857 6.268 2.84 7.469a4.102 4.102 0 0 0 2.144.601A4.985 4.985 0 0 0 8.197 15l2.49 2.483v2.767a.563.563 0 0 0 1.126 0v-2.765a5.395 5.395 0 0 1 1.29-3.516c1.05.556 2.13.835 3.17.835a5.522 5.522 0 0 0 2.887-.803c2.703-1.636 4.15-5.47 3.868-10.251ZM4.386 13.998c-1.53-.925-2.38-3.123-2.324-5.936 2.813-.06 5.008.792 5.933 2.32.532.88.577 1.911.144 2.962l-2.492-2.492a.563.563 0 0 0-.795.796l2.495 2.494c-1.05.433-2.083.39-2.961-.144Zm14.192-.958c-1.382.836-3.014.86-4.657.084l5.227-5.227a.562.562 0 1 0-.796-.795l-5.227 5.227c-.776-1.643-.75-3.275.084-4.656 1.362-2.25 4.6-3.485 8.72-3.351.133 4.115-1.103 7.356-3.351 8.718Z"
      fill="#2B3C50"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSproutOutline);
export default ForwardRef;
