import { gql } from "@apollo/client";

// eslint-disable-next-line @lemonde/import/no-illegal-import
import { LivePostFragment } from "@/containers/routes/live/LivePostOperations";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { PostListProvider } from "@/containers/routes/search/resources/posts/PostList";

export const name = "Posts";

export const List = ({ nodes, ...props }) => (
  <PostListProvider posts={nodes} {...props} />
);

export const UsedInPostFragment = gql`
  fragment UsedInPostFragment on Post {
    id
    liveId
    ...LivePostFragment
  }

  ${LivePostFragment}
`;
