// @ts-check

const SPACE = "\u0020";
const NARROW_NO_BREAK_SPACE = "\u202F";
const NO_BREAK_SPACE = "\u00A0";
const QUESTION_MARK = "?";
const EXCLAMATION_MARK = "!";
const COLON = ":";
const SEMICOLON = ";";

const EURO = "€";
const DOLLAR = "$";
const DEGREE = "°";
const PERCENT = "%";

const UNITS = [EURO, DOLLAR, DEGREE, PERCENT];

const PUNCTUATION_SIGNS = [QUESTION_MARK, EXCLAMATION_MARK, COLON, SEMICOLON];

const CHARS = [...PUNCTUATION_SIGNS, ...UNITS];

const SPACES = [SPACE, NARROW_NO_BREAK_SPACE, NO_BREAK_SPACE];

/**
 * Get the last word.
 * @param {import('..').Context} ctx
 */
const getLastWord = (ctx) => {
  let i = ctx.position;
  let word = "";
  while (i > 0) {
    i--;
    const char = ctx.text.charAt(i);
    if (SPACES.includes(char)) {
      break;
    }
    word = char + word;
  }
  return word;
};

/**
 * Check is we are writing a word.
 * @param {import('..').Context} ctx
 * @returns {boolean}
 */
const checkIsValidWord = (ctx) => {
  const word = getLastWord(ctx);
  if (word.length === 0) return true;
  // URL
  if (word.startsWith("http")) return false;
  // Emoji
  if (word.startsWith(":")) return false;
  return true;
};

/**
 * @type {import('../index').Rule}
 */
export const rule = {
  chars: CHARS,
  transform: (ctx) => {
    const { input } = ctx;
    if (CHARS.includes(input)) {
      const insert = {
        type: /** @type {"insert"} */ ("insert"),
        value: NO_BREAK_SPACE + input,
      };
      if (SPACES.includes(ctx.text.charAt(ctx.position - 1))) {
        return [
          { type: /** @type {"delete"} */ ("delete"), length: 1 },
          insert,
        ];
      }
      if (checkIsValidWord(ctx)) {
        return [insert];
      }
    }
    return null;
  },
};
