import { createContext, useContext, useState } from "react";

import { useConnection } from "@/containers/Ping";
import { createSafeContext } from "@/services/hooks/useSafeContext";

const ReadOnlyContext = createContext<boolean>(false);
const SetReadonlyContext = createSafeContext<(value: boolean) => void>();

export const ReadOnlyProvider: React.FC<{
  children: React.ReactNode;
  readOnly?: boolean;
}> = ({ children, readOnly: readOnlyProp }) => {
  const connection = useConnection();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  return (
    <ReadOnlyContext.Provider
      value={readOnlyProp || readOnly || !connection.synced}
    >
      <SetReadonlyContext.Provider value={setReadOnly}>
        {children}
      </SetReadonlyContext.Provider>
    </ReadOnlyContext.Provider>
  );
};

export const useReadOnly = () => useContext(ReadOnlyContext);

export const useSetReadOnly = SetReadonlyContext.makeSafeHook(
  "useSetReadOnly",
  "ReadOnlyProvider",
);
