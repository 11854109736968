import { gql } from "@apollo/client";
import { useMemo } from "react";

import { useSafeQuery } from "@/containers/Apollo";

import * as usages from ".";

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getUsedInQuery = ({ node, relationUsages }) => {
  const query = `query UsedInQuery($id: Int!, $offset: Int, $limit: Int) {
    node: ${node?.__typename.toLowerCase()}(id: $id) {
      id
      ${relationUsages.map(
        (usage) => `
          usedIn_${usage}(offset: $offset, limit: $limit) {
            totalCount
          }
        `,
      )}
    }
  }`;

  return gql(query);
};

export function useUsedInResource({ node, queries, enabled = true }) {
  const relationUsages = Object.keys(usages).filter(
    (usage) => queries?.[`UsedIn${capitalize(usage)}Query`],
  );
  const usedInQuery = useMemo(
    () => getUsedInQuery({ node, relationUsages }),
    [node, relationUsages],
  );
  const { data, loading } = useSafeQuery(usedInQuery, {
    variables: { id: Number(node?.id) },
    skip: !enabled || !node,
  });
  const isUsed = useMemo(() => {
    if (!enabled || !node) return false;
    if (!data || loading) return true;
    return Boolean(
      Object.values(data.node).find((usedIn) => usedIn.totalCount),
    );
  }, [loading, data, enabled, node]);

  const usedInValue = useMemo(
    () => ({
      isUsed,
      loading,
    }),
    [isUsed, loading],
  );

  return usedInValue;
}
