import { DocumentNode, gql } from "@apollo/client";
import { omit } from "lodash-es";
import React from "react";
import { capitalize } from "underscore.string";

import type { User } from "@/containers/User";
import {
  formatCustomFields,
  parseCustomFields,
} from "@/containers/custom-fields/formatters";
import { EventsHistory } from "@/containers/events/EventsHistory";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";
import { omitTypename } from "@/services/utils";

import { createCRUDCreate } from "./Create";
import { createCRUDEdit } from "./Edit";
import { createCRUDFilters } from "./Filters";
import { createCRUDForm } from "./Form";
import { createCRUDList } from "./List";
import { createCRUDRoot } from "./Root";
import { createCRUDSettings } from "./Settings";
import { createCRUDTabs } from "./Tabs";

type Column<TNode = object> = {
  id: string;
  Header: React.FC;
  Value: React.FC<{ node: TNode }>;
};

export type UseFiltersState<TFilters = object> = () => {
  filters: TFilters;
  setFilters: (filters: TFilters) => void;
  initialFilters: TFilters;
};

export type FieldsMap = {
  [key: string]: {
    label?: string;
    formatLabel?: () => unknown;
    formatValue?: () => unknown;
  };
};

type CRUDOperation = "list" | "create" | "edit" | "delete";

export type CRUDTerm = {
  article: string;
  specificArticle: string;
  singular: string;
  plural?: string;
};

export type CRUDConfig<TNode = object, TFilters = object, TValues = object> = {
  slug: string;
  baseUrl?: string;
  term: CRUDTerm;
  fieldsMap?: FieldsMap;
  crudOperations?: CRUDOperation[];
  permissions: string[];
  operations: {
    ConnectionQuery: DocumentNode;
    NodeQuery?: DocumentNode;
    nodeQueryVariables?: object;
    CustomFieldsQuery?: DocumentNode;
    NodeSubscriptions?: DocumentNode[];
    CsvSubscription?: DocumentNode;
    UpdateNodeMutation?: DocumentNode;
    CreateNodeMutation?: DocumentNode;
    DeleteNodeMutation?: DocumentNode;
    MoveNodeMutation?: DocumentNode;
    AuditTrailConnectionQuery?: DocumentNode;
    SettingsQuery?: DocumentNode;
    SettingsUpdateMutation?: DocumentNode;
    operationVariables?: object;
  };
  formatValues?: (node: TNode, ctx: { user: User }) => TValues;
  parseValues?: (values: TValues, node: TNode | undefined) => object;
  getInitialFilters?: (node: TNode) => TFilters;
  listOnly?: boolean;
  components: {
    Fields?: React.FC;
    FiltersFields?: React.FC;
    columns: Column<TNode>[];
    List?: React.FC;
    Create?: React.FC;
    Edit?: React.FC;
    Form?: React.FC;
    Filters?: React.FC;
    SettingsButton?: React.FC;
    SettingsFields?: React.FC;
    useFiltersState?: UseFiltersState<TFilters>;
  };
  formatCSV?: (options: { nodes: TNode[] }) => {
    columns: string[];
    rows: string[][];
  };
};

export type CRUDDescriptor<
  TNode = object,
  TFilters = object,
  TValues = object,
> = {
  title?: string;
  slug: string;
  baseUrl: string;
  term: {
    article: string;
    specificArticle: string;
    singular: string;
    plural?: string;
  };
  fieldsMap?: object;
  crudOperations: CRUDOperation[];
  operations: {
    ConnectionQuery?: DocumentNode;
    NodeQuery?: DocumentNode;
    nodeQueryVariables?: object;
    CustomFieldsQuery?: DocumentNode;
    NodeSubscriptions?: DocumentNode[];
    CsvSubscription?: DocumentNode;
    UpdateNodeMutation?: DocumentNode;
    CreateNodeMutation?: DocumentNode;
    DeleteNodeMutation?: DocumentNode;
    MoveNodeMutation?: DocumentNode;
    AuditTrailConnectionQuery?: DocumentNode;
    SettingsQuery?: DocumentNode;
    SettingsUpdateMutation?: DocumentNode;
    operationVariables?: object;
  };
  formatValues?: (node: TNode, ctx: { user: User }) => TValues;
  parseValues?: (values: TValues, node: TNode | undefined) => object;
  getInitialFilters: (node: TNode) => TFilters;
  components: {
    Fields: React.FC;
    FiltersFields?: React.FC;
    columns: Column<TNode>[];
    List: React.FC | null;
    Filters: React.FC<{
      filters: TFilters;
      setFilters: (value: TFilters) => void;
    }> | null;
    tabs?: unknown[];
    Tabs?: React.FC<{
      node: TNode;
      initialValues: TValues;
      onSubmit: () => void;
    }> | null;
    useFiltersState: UseFiltersState<TFilters>;
    Create: React.FC | null;
    Edit: React.FC | null;
    Form: React.FC<{
      node: TNode;
      initialValues: TValues;
      onSubmit: () => void;
    }> | null;
    SettingsButton?: React.FC | null;
    SettingsFields?: React.FC;
  };
  formatCSV: (options: { nodes: TNode[] }) => {
    columns: string[];
    rows: string[][];
  };
  wrapPageElement?: <T>({ element }: { element: T }) => T;
};

/**
 * Format CSV.
 */
function defaultFormatCSV<TNode = object>({ nodes }: { nodes: TNode[] }) {
  if (!nodes.length) return { columns: [], rows: [] };
  const columns = Object.keys(omitTypename(nodes[0]!));
  const rows = nodes.map(
    (node) => Object.values(omitTypename(node!)) as string[],
  );
  return { columns, rows };
}

/**
 * Get CRUD descriptor from crud configuration.
 */
function getCrudContext<TNode = object, TFilters = object, TValues = object>(
  config: CRUDConfig<TNode, TFilters, TValues>,
) {
  const descriptor = { ...config } as CRUDDescriptor<TNode, TFilters, TValues>;
  const ctx = {
    get descriptor() {
      return descriptor;
    },
  };

  if (!descriptor.title) {
    descriptor.title = capitalize(descriptor.term.plural ?? "");
  }

  if (!descriptor.wrapPageElement) {
    descriptor.wrapPageElement = ({ element }) => element;
  }

  if (!descriptor.crudOperations) {
    const crudOperations: CRUDOperation[] = ["list", "edit"];
    if (descriptor.operations.CreateNodeMutation) {
      crudOperations.push("create");
    }
    if (descriptor.operations.DeleteNodeMutation) {
      crudOperations.push("delete");
    }

    descriptor.crudOperations = crudOperations;
  }

  if (!descriptor.operations.operationVariables) {
    descriptor.operations.operationVariables = {};
  }

  descriptor.parseValues = (values, node) => {
    const result: any = config.parseValues
      ? config.parseValues({ ...values }, node)
      : { ...values };
    if (descriptor.operations.CustomFieldsQuery && result.customFields) {
      result.customFields = parseCustomFields(result.customFields);
    }
    return result;
  };
  descriptor.formatValues = (node, ctx) => {
    const result: any = config.formatValues
      ? omit(config.formatValues(node, ctx)!, ["__typename", "id"])
      : {};
    if (descriptor.operations.CustomFieldsQuery) {
      result.customFields = formatCustomFields(result.customFields);
    }
    return result;
  };
  if (!descriptor.baseUrl) {
    descriptor.baseUrl = "/admin";
  }
  if (!descriptor.formatCSV) {
    descriptor.formatCSV = defaultFormatCSV;
  }
  if (!descriptor.getInitialFilters) {
    descriptor.getInitialFilters = () => ({}) as TFilters;
  }
  if (!descriptor.components.SettingsButton) {
    const { SettingsButton } = createCRUDSettings(ctx);
    descriptor.components.SettingsButton = SettingsButton;
  }
  if (
    !descriptor.components.Filters &&
    !descriptor.components.useFiltersState
  ) {
    const { Filters, useFiltersState } = createCRUDFilters(ctx);
    descriptor.components.Filters = Filters!;
    descriptor.components.useFiltersState = useFiltersState as any;
  }
  if (!descriptor.components.Form) {
    const { Form } = createCRUDForm(ctx);
    descriptor.components.Form = Form;
  }
  if (!descriptor.components.Tabs) {
    const { Tabs } = createCRUDTabs(ctx);
    descriptor.components.Tabs = Tabs;
  }
  if (!descriptor.components.Create) {
    const { Create } = createCRUDCreate(ctx);
    descriptor.components.Create = Create;
  }
  if (!descriptor.components.List) {
    const { List } = createCRUDList(ctx);
    descriptor.components.List = List;
  }
  if (!descriptor.components.Edit) {
    const { Edit } = createCRUDEdit(ctx);
    descriptor.components.Edit = Edit;
  }
  return ctx;
}

/**
 * Create CRUD admin.
 */
export function createCRUD<TNode = object, TFilters = object, TValues = object>(
  config: CRUDConfig<TNode, TFilters, TValues>,
) {
  const ctx = getCrudContext(config);
  return createCRUDRoot(ctx);
}

export * from "./fields";
export * from "./filtersFields";
export * from "./FiltersToolbar";
export {
  CustomFieldFragment,
  CustomFieldValueFragment,
} from "../../custom-fields/CustomFieldsFragments";

export const CRUDEventFragment = gql`
  fragment CRUDEventFragment on Event {
    id
    ...EventsHistory_events
  }

  ${EventsHistory.fragments.events}
`;

export const CRUDNodeFragment = gql`
  fragment CRUDNodeFragment on Node {
    id
    globalId
  }
`;

export const CRUDConnectionFragment = ConnectionFragment;
