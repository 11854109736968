import {
  IoAlertCircle,
  IoCheckmarkCircle,
  IoCloseCircle,
  IoInformationCircle,
} from "./Icon";

export const VariantIcons = {
  success: IoCheckmarkCircle,
  warning: IoAlertCircle,
  danger: IoCloseCircle,
  info: IoInformationCircle,
};
