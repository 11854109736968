import * as React from "react";
import { forwardRef } from "react";

const SvgFocusModeOut = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3 6.974v-2a2 2 0 0 1 2-2h2M17 2.974h2a2 2 0 0 1 2 2v2M21 16.974v2a2 2 0 0 1-2 2h-2M7 20.974H5a2 2 0 0 1-2-2v-2M15.5 8.5l-7 7M15.5 15.5l-7-7"
      stroke="#6B7178"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFocusModeOut);
export default ForwardRef;
