import { gql } from "@apollo/client";

import { ArticleComment } from "@/containers/article/panels/comments/ArticleComment";
import { CreateArticleCommentForm } from "@/containers/article/panels/comments/ArticleCommentForm";
import { ArticleCommentList } from "@/containers/article/panels/comments/ArticleCommentList";
import { ArticleCommentsTemplate } from "@/containers/article/panels/comments/ArticleCommentsTemplate";
import { CommentScopeProvider } from "@/containers/article/panels/comments/CommentScopeProvider";

const ArticleNotesArticleFragment = gql`
  fragment ArticleNotes_article on Article {
    globalId
    notes(where: { resolved: $resolved }, offset: $offset) {
      nodes {
        id
        ...ArticleComment_commentThread
      }
      ...ArticleCommentList_commentThreadConnection
    }
  }
  ${ArticleComment.fragments.commentThread}
  ${ArticleCommentList.fragments.commentThreadConnection}
`;

export const ArticleNotesArticleQuery = gql`
  query ArticleNotes_article(
    $articleId: Int!
    $resolved: Boolean!
    $offset: Int
  ) {
    article(id: $articleId) {
      id
      ...ArticleNotes_article
    }
  }
  ${ArticleNotesArticleFragment}
`;

const resolvers = {
  opened: {
    label: "Ouvertes",
    labelElement: "Consignes ouvertes",
  },
  resolved: {
    label: "Résolues",
    labelElement: "Consignes résolues",
  },
};

export const ArticleNotes = () => (
  <CommentScopeProvider scope="notes">
    <ArticleCommentsTemplate resolvers={resolvers}>
      {({ article, selectedId }) =>
        selectedId === "opened" &&
        article && (
          <CreateArticleCommentForm
            article={article}
            confirmMessage="Ajouter"
            placeholder="Ajouter une nouvelle consigne..."
            className="pt-4"
          />
        )
      }
    </ArticleCommentsTemplate>
  </CommentScopeProvider>
);
