import { gql } from "@apollo/client";
import { Anchor } from "swash/Anchor";
import { Tooltip } from "swash/Tooltip";

import { useSyncFields } from "@/components/forms/FormFieldSync";
import { StringField } from "@/containers/admin/CRUD";

import { ChannelAccountPicture, ChannelAccounts } from "../AlertChannelLayout";

export const title = "Facebook";

export const type = "facebook";

export const fieldName = "targetingFacebook";

export const AlertFragment = gql`
  fragment FacebookChannelAlertFragment on Alert {
    targetingFacebook
    facebookText
  }
`;

export const ChannelFragment = gql`
  fragment FacebookChannelFragment on FacebookAlertChannel {
    facebookPages {
      id
      name
      pictureUrl
      link
    }
  }
`;

export const getInitialValues = ({ channelData, alert, article }) => {
  const channelEnabled = Boolean(
    channelData?.status && channelData.status !== "disabled",
  );
  return {
    targetingFacebook: alert?.targetingFacebook ?? channelEnabled,
    facebookText: alert?.facebookText ?? article.chapo,
  };
};

export const Title = ({ data }) => {
  return (
    <div>
      <span>{title}</span>
      <ChannelAccounts>
        {data.facebookPages.map((page) => (
          <Tooltip key={page.id} tooltip={page.name}>
            <li>
              <Anchor href={page.link} target="_blank">
                <ChannelAccountPicture src={page.pictureUrl} alt={page.name} />
              </Anchor>
            </li>
          </Tooltip>
        ))}
      </ChannelAccounts>
    </div>
  );
};

export const Body = ({ disabled }) => {
  useSyncFields({ from: "text", to: "facebookText" });
  return (
    <>
      <StringField
        required
        name="facebookText"
        label="Post"
        multiline
        rich
        disabled={disabled}
        scale="base"
      />
      <div className="mt-2 text-xs text-grey-on-light">
        L’URL de l’article sera automatiquement ajoutée à ce post.
      </div>
    </>
  );
};
