import { gql } from "@apollo/client";
import { Button } from "swash/Button";
import { IoClose } from "swash/Icon";

import { ResourceDraggableField } from "../article/fields/ResourceDraggableField";

const CustomTypeContentFragment = gql`
  fragment customTypeContentsSelector_customTypeContent on CustomTypeContent {
    id
    label
  }
`;

const CustomTypeContentQuery = gql`
  query RelatedCustomTypeContentSelector_customTypeContents(
    $offset: Int
    $limit: Int
    $filters: CustomTypeContentWhere
  ) {
    connection: customTypesContents(
      offset: $offset
      limit: $limit
      where: $filters
    ) {
      pageInfo {
        hasMore
      }
      nodes {
        id
        ...customTypeContentsSelector_customTypeContent
      }
    }
  }
  ${CustomTypeContentFragment}
`;

const CustomTypeContentItem = ({
  customTypeContent,
  onRemove,
  disabled,
  dragIndex,
}) => {
  return (
    <div className="group flex h-5 w-full flex-row items-center justify-between text-sm">
      {customTypeContent.label}
      {onRemove && !disabled && (
        <Button
          aria-label="Supprimer le contenu"
          appearance="text"
          variant="secondary"
          scale="xs"
          iconOnly
          onClick={() => onRemove(dragIndex)}
          className="opacity-0 group-hover:opacity-100"
        >
          <IoClose />
        </Button>
      )}
    </div>
  );
};

export const RelatedCustomTypeContentSelector = ({
  value,
  placeholder,
  name,
  label,
  onChange,
  limit,
  multi,
  disabled,
  searchVariables,
}) => {
  return (
    <ResourceDraggableField
      value={value}
      name={name}
      label={label}
      placeholder={placeholder || "Ajouter un contenu"}
      onChange={onChange}
      disabled={disabled}
      query={CustomTypeContentQuery}
      model="CustomTypeContent"
      fragment={CustomTypeContentFragment}
      labelSelector={({ item, onRemove, disabled, dragIndex }) => (
        <CustomTypeContentItem
          customTypeContent={item}
          onRemove={onRemove}
          disabled={disabled}
          dragIndex={dragIndex}
        />
      )}
      labelElementSelector={(item) => (
        <CustomTypeContentItem customTypeContent={item} />
      )}
      limit={limit}
      multi={multi}
      searchVariables={searchVariables}
    />
  );
};
