import { forwardRef } from "react";
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from "react-router-dom";
import { Link } from "swash/Link";

export const getArticleUrl = (articleId: number, absolute = false) => {
  if (!absolute) return `/articles/${articleId}`;
  return `${window.location.origin}/articles/${articleId}`;
};

export type ArticleLinkProps = {
  articleId: number;
  className?: string;
  inherit?: boolean;
} & Omit<ReactRouterLinkProps, "to">;

export const ArticleLink = forwardRef<HTMLAnchorElement, ArticleLinkProps>(
  ({ articleId, className, inherit, onClick, ...props }, ref) => {
    return (
      <Link asChild className={className} inherit={inherit} ref={ref}>
        <ReactRouterLink
          to={`/articles/${articleId}`}
          onClick={(event) => {
            event.stopPropagation();
            if (onClick) onClick(event);
          }}
          {...props}
        />
      </Link>
    );
  },
);
