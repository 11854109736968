import { omit } from "lodash-es";

const omitTypename = (value) =>
  value && typeof value === "object" ? omit(value, "__typename") : value;

export function formatCustomFields(values) {
  return values.reduce((acc, { name, value, type, rank, multi }) => {
    return {
      ...acc,
      [name]: {
        value:
          rank != null
            ? {
                [type]: value[type]
                  ? [
                      ...(acc[name]?.value[type] ?? []),
                      omitTypename(value[type]),
                    ]
                  : null,
              }
            : { [type]: omitTypename(value[type]) },
        type,
        name,
        multi,
      },
    };
  }, {});
}

export function parseCustomFields(customFields) {
  return Object.entries(customFields).flatMap(([key, customField]) => {
    if (Array.isArray(customField.value[customField.type])) {
      if (!customField.value[customField.type].length) {
        return {
          name: key,
          type: customField.type,
          value: {
            [`${customField.type}`]: null,
          },
          ...(customField.multi && { rank: 0 }),
        };
      }

      return customField.value[customField.type].map((value, index) => ({
        name: key,
        type: customField.type,
        value: {
          [`${customField.type}`]: value,
        },
        rank: index,
      }));
    }

    return {
      name: key,
      type: customField.type,
      value: {
        [`${customField.type}`]: customField.value[`${customField.type}`],
      },
      ...(customField.multi && { rank: 0 }),
    };
  });
}
