import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

import type { FieldState } from "./FieldState";

type FieldGroupProps = React.ComponentPropsWithoutRef<"div"> & {
  state: FieldState;
  scale?: string;
};

export const FieldGroup = forwardRef<HTMLDivElement, FieldGroupProps>(
  ({ state, scale, ...props }, ref) => {
    return (
      <div
        ref={ref}
        data-orientation={state.orientation}
        data-scale={scale}
        {...props}
        className={cn(
          "group/fieldGroup flex",
          'data-[orientation="vertical"]:flex-col data-[orientation="vertical"]:flex-wrap',
          'data-[orientation="horizontal"]:items-center',
          props.className,
        )}
      />
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FieldGroup.displayName = "FieldGroup";
}
