export const ATOMIC_ENTITY_TYPE = "SEPARATOR";

export const allowedTagNames = ["hr", "figure"];
export const allowedAttributes = { hr: [] };

export const transformTags = {
  figure: "hr",
};

/** @type {import('../types').htmlToEntity} */
export const htmlToEntity = ({ element, createEntity }) => {
  if (element.tagName === "hr") {
    createEntity(ATOMIC_ENTITY_TYPE, "IMMUTABLE", {});
  }
};

/** @type {import('../types').htmlToBlock} */
export const htmlToBlock = ({ element }) => {
  if (element.tagName === "hr") {
    return {
      type: "atomic",
      data: {
        type: ATOMIC_ENTITY_TYPE,
        mutability: "IMMUTABLE",
      },
    };
  }
  return null;
};
