import { createContext, useContext, useRef, useState } from "react";
import { useRefHandler } from "swash/utils/useRefHandler";

const ArticleEditorShiftingObserverContext = createContext();

export const useArticleEditorShifting = () =>
  useContext(ArticleEditorShiftingObserverContext);

export const ArticleEditorResizeObserver = ({ children }) => {
  const [shifting, setShifting] = useState(true);
  /**
   * @type {React.MutableRefObject<number | undefined>}
   */
  const timeoutIdRef = useRef();

  const handler = useRefHandler((element) => {
    if (!element) return undefined;
    let req;

    const resetTimeout = () => {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        // no shift since 250ms
        setShifting(false);
      }, 250);
    };

    // disconnect observer after 10s
    const observerTimeoutId = setTimeout(() => {
      disconnect();
    }, 1000 * 10);

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        req = window.requestAnimationFrame(() => {
          setShifting(true);
          resetTimeout();
        });
      }
    });

    const disconnect = () => {
      observer.disconnect();
      if (req) {
        window.cancelAnimationFrame(req);
      }
    };
    observer.observe(element);

    return () => {
      disconnect();
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      if (observerTimeoutId) {
        clearTimeout(observerTimeoutId);
      }
    };
  });

  return (
    <ArticleEditorShiftingObserverContext.Provider value={shifting}>
      <div ref={handler}>{children}</div>
    </ArticleEditorShiftingObserverContext.Provider>
  );
};
