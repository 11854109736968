import deepEqual from "deep-equal";
import { UseFieldConfig, useField } from "react-final-form";

import { composeValidators, mustBeFilled } from "@/services/forms/validators";

import { FieldControl } from "./FieldControl";
import { Orientation, useFieldState } from "./FieldState";

export const selectFieldIntegerFormatters = {
  format: (v: unknown) => (v ? String(v) : v),
  parse: (v: unknown) => (v ? Number(v) : v),
  isEqual: Object.is,
};

type SelectFieldOptions = UseFieldConfig<any> & {
  required?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  orientation?: Orientation;
};

export function useSelectField(
  name: string,
  {
    required = false,
    disabled,
    validate,
    id,
    label,
    orientation = "vertical",
    ...options
  }: SelectFieldOptions = {},
) {
  const validators = [];
  if (validate) {
    validators.push(validate);
  }
  if (required) {
    validators.push(mustBeFilled);
  }

  const field = useField(name, {
    validate: validators.length ? composeValidators(...validators) : undefined,
    format: (v) => v,
    parse: (v) => v,
    isEqual: deepEqual,
    allowNull: true,
    // @ts-expect-error Disabled is not an option, apparently
    disabled,
    ...options,
  });

  return useFieldState({ field, required, orientation, label });
}

export const SelectField = FieldControl;
