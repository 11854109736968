import { memo, useCallback, useMemo } from "react";
import { IoChevronForward } from "swash/Icon";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelHeader } from "swash/Panel";
import { useLiveRef } from "swash/utils/useLiveRef";
import { Dialog, DialogDisclosure, useDialogStore } from "swash/v2/Dialog";

import { SectionTitle } from "@/components/Layout";
import { mergeConnections, useFetchMoreQuery } from "@/containers/Apollo";

import * as usages from ".";
import { useUsedInContext } from "../Context";
import { capitalize } from "./useUsedInResource";

const UsedInDialog = ({
  usage,
  name,
  usedInQuery,
  as: As,
  label,
  resource,
}) => {
  const dialog = useDialogStore();
  const queryOptions = {
    query: usedInQuery,
    variables: { id: Number(resource.id) },
    fetchPolicy: "network-only",
  };

  const { query, ...otherQueryOptions } = queryOptions;
  const { error, data, loading, fetchMore, fetchMoreLoading } =
    useFetchMoreQuery(query, otherQueryOptions);

  const refs = useLiveRef({ data, queryOptions });
  const usedIn = `usedIn_${usage}`;
  const loadMore = useCallback(() => {
    const { data } = refs.current;
    if (!data) return null;
    fetchMore({
      variables: { offset: data.node[usedIn].nodes.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          node: {
            ...previousResult.node,
            [`usedIn_${usage}`]: mergeConnections(
              previousResult.node[usedIn],
              fetchMoreResult.node[usedIn],
            ),
          },
        };
      },
    });
  }, [fetchMore, refs, usage, usedIn]);

  const open = dialog.useState("open");

  if (!data?.node) return null;
  if (error) throw error;
  if (loading) return <PageLoader />;

  const {
    node: {
      [`usedIn_${usage}`]: { nodes, pageInfo, totalCount },
    },
  } = data;

  if (!totalCount) return null;

  return (
    <>
      <DialogDisclosure className="mb-1 text-start" store={dialog}>
        Voir les {name.toLowerCase()} contenant la ressource « {label} » (
        {totalCount})
        <IoChevronForward className="inline-block align-middle" />
      </DialogDisclosure>
      <Dialog style={{ width: 968 }} store={dialog}>
        <PanelHeader
          title={`${name} ayant la ressource « ${label} »`}
          onClose={dialog.hide}
        />
        <PanelBody>
          {open ? (
            <As
              resource={resource}
              nodes={nodes}
              hasMore={pageInfo.hasMore}
              loadMore={loadMore}
              loading={fetchMoreLoading}
              totalCount={totalCount}
            />
          ) : null}
        </PanelBody>
      </Dialog>
    </>
  );
};

export const UsedInSection = memo(
  ({
    operations = {},
    label,
    usedInResource,
    resource,
    title = "Utilisation",
  }) => {
    const usedInContext = useUsedInContext() || usedInResource;
    const dialogs = useMemo(() => {
      return Object.entries(usages).map(([usage, { List, name }]) => {
        const usedInQuery =
          operations.UsedInQueries?.[`UsedIn${capitalize(usage)}Query`];
        if (!usedInQuery || !resource?.id) return null;
        return (
          <div
            key={usage}
            className="flex flex-col gap-2 text-base text-primary-on"
          >
            <UsedInDialog
              usage={usage}
              name={name}
              usedInQuery={usedInQuery}
              as={List}
              label={label}
              resource={resource}
            />
          </div>
        );
      });
    }, [operations, resource, label]);

    if (!dialogs.filter(Boolean).length) return null;

    return (
      <div>
        {title && <SectionTitle className="mb-4">{title}</SectionTitle>}
        {!usedInContext?.isUsed && !usedInContext?.loading ? (
          <span className="mb-1 text-grey-on">
            La ressource « {label} » n’est pas utilisée
          </span>
        ) : (
          dialogs.map((dialog) => dialog)
        )}
      </div>
    );
  },
);
