import {
  Popover as AriakitPopover,
  PopoverProps as AriakitPopoverProps,
  PopoverStore as AriakitPopoverStore,
  PopoverStoreProps as AriakitPopoverStoreProps,
  usePopoverStore as useAriakitPopoverStore,
} from "@ariakit/react-legacy";
import * as React from "react";
import { forwardRef, useLayoutEffect, useRef } from "react";

import { useNextFrameTicked } from "./motion/Animation";
import { cn } from "./utils/classNames";
import { useLiveRef } from "./utils/useLiveRef";

export { PopoverDisclosure } from "@ariakit/react-legacy";

export interface PopoverStoreProps extends AriakitPopoverStoreProps {
  /**
   * Ignore any resize or scroll. The position of the popover will not be updated after it is shown.
   * @default false
   */
  ignoreUpdate?: boolean;
}

export interface PopoverStore extends AriakitPopoverStore {
  /**
   * Ignore any resize or scroll. The position of the popover will not be updated after it is shown.
   */
  ignoreUpdate: boolean;
}

export const usePopoverStore = ({
  ignoreUpdate = false,
  ...props
}: PopoverStoreProps = {}): PopoverStore => {
  const store = useAriakitPopoverStore({
    ...props,
  }) as PopoverStore;
  store.ignoreUpdate = ignoreUpdate;
  return store;
};

export type PopoverCardProps = React.HTMLAttributes<HTMLDivElement>;

export const PopoverCard = forwardRef<HTMLDivElement, PopoverCardProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          className,
          "z-popover select-none rounded-md border border-grey-border-light bg-white shadow-md focus:outline-none",
        )}
        {...props}
      />
    );
  },
);

interface PopoverProps extends AriakitPopoverProps {
  lazy?: boolean;
  store?: PopoverStore;
}

const NestedContext = React.createContext<{
  disableHideOnInteractOutside: () => void;
  resetHideOnInteractOutside: () => void;
} | null>(null);

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      lazy = true,
      modal,
      gutter = 4,
      hideOnInteractOutside: initialHideOnInteractOutside,
      ...props
    },
    ref,
  ) => {
    const persistedAnchorRef = useRef<DOMRect | null>(null);
    const ticked = useNextFrameTicked({ skip: !lazy });
    const open = props.store?.useState("open");

    const [hideOnInteractOutside, setHideOnInteractOutside] = React.useState(
      () => initialHideOnInteractOutside,
    );
    const initialHideOnInteractOutsideRef = useLiveRef(
      initialHideOnInteractOutside,
    );
    const nestedValue = React.useMemo(() => {
      return {
        disableHideOnInteractOutside: () => {
          setHideOnInteractOutside(false);
        },
        resetHideOnInteractOutside: () => {
          setHideOnInteractOutside(initialHideOnInteractOutsideRef.current);
        },
      };
    }, [initialHideOnInteractOutsideRef]);

    const parent = React.useContext(NestedContext);
    const parentRef = useLiveRef(parent);
    useLayoutEffect(() => {
      if (open) {
        persistedAnchorRef.current = null;
      }
    }, [open]);
    React.useEffect(() => {
      const { current: parent } = parentRef;
      if (!parent) return;
      if (open) {
        parent.disableHideOnInteractOutside();
      } else {
        parent.resetHideOnInteractOutside();
      }
    }, [open, parentRef]);
    if (lazy && !ticked) return null;
    return (
      <NestedContext.Provider value={nestedValue}>
        <AriakitPopover
          ref={ref}
          render={<PopoverCard />}
          gutter={gutter}
          getAnchorRect={
            props.store?.ignoreUpdate
              ? (anchor) => {
                  if (props.getAnchorRect) return props.getAnchorRect(anchor);
                  if (persistedAnchorRef.current === null && anchor) {
                    persistedAnchorRef.current = anchor.getBoundingClientRect();
                  }
                  return persistedAnchorRef.current;
                }
              : props.getAnchorRect
          }
          focusable={false}
          modal={modal}
          hideOnInteractOutside={hideOnInteractOutside}
          {...props}
        />
      </NestedContext.Provider>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Popover.displayName = "Popover";
}
