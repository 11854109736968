/* eslint-disable no-cond-assign */
import { toString } from "nlcst-to-string";
import retextLatin from "retext-latin";
import { unified } from "unified";

const stringToNlcst = (str) => {
  return unified().use(retextLatin).parse(str);
};

const nodesToString = (nodes) => {
  return nodes
    .map((node, index) => {
      switch (node.type) {
        case "text":
          return node.value;
        case "hast":
          return `$_${index}_$`;
        default:
          throw new Error(`Unknown node type "${node.type}"`);
      }
    })
    .join("");
};

const stringToNodes = (string, originalNodes) => {
  const regexp = /\$_(\d+)_\$/g;
  const nodes = [];
  let result = null;
  let index = 0;
  while ((result = regexp.exec(string))) {
    const str = string.slice(index, result.index);
    if (str) {
      nodes.push({
        type: "text",
        value: str,
      });
    }
    index = result.index + result[0].length;
    const nodeIndex = result[1];
    nodes.push(originalNodes[nodeIndex]);
  }
  const str = string.slice(index);
  if (str) {
    nodes.push({
      type: "text",
      value: str,
    });
  }
  return nodes;
};

const toOne = (node) => {
  switch (node.type) {
    case "text":
      return node;
    case "hast":
      return node.data.children
        ? { ...node.data, children: toAll(node.data) }
        : node.data;
    default:
      throw new Error(`Unknown tNode type "${node.type}"`);
  }
};

const toAll = (node) => {
  return node.children.map((child) => toOne(child));
};

const fromAll = (ctx, node) => {
  const nodes = node.children.map((child) => fromOne(ctx, child));
  const str = nodesToString(nodes);
  const tree = stringToNlcst(str);
  const resultTree = ctx.iteratee(tree);
  const resultStr = toString(resultTree);
  const resultNodes = stringToNodes(resultStr, nodes);
  return { ...node, children: resultNodes };
};

const fromOne = (ctx, node) => {
  switch (node.type) {
    case "text":
      return node;
    default:
      return {
        type: "hast",
        data: node.children ? fromAll(ctx, node) : node,
      };
  }
};

export const applyNlcstTransform = (ast, iteratee) => {
  return toOne(fromOne({ iteratee }, ast));
};
