import { useMemo, useState } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

type LockUpdatingContextValue = {
  lockUpdating: boolean;
  setLockUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

const LockUpdatingContext = createSafeContext<LockUpdatingContextValue>();

export const LockUpdatingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [lockUpdating, setLockUpdating] = useState(false);
  const value = useMemo<LockUpdatingContextValue>(
    () => ({ lockUpdating, setLockUpdating }),
    [lockUpdating],
  );
  return (
    <LockUpdatingContext.Provider value={value}>
      {children}
    </LockUpdatingContext.Provider>
  );
};

export const useLockUpdating = LockUpdatingContext.makeSafeHook(
  "useLockUpdating",
  "LockUpdatingProvider",
);
