import * as React from "react";
import { forwardRef } from "react";

const SvgFeaturedMedia = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M18.429 2H5.57c-.681 0-1.335.247-1.817.684A2.232 2.232 0 0 0 3 4.334v9.333c0 .618.272 1.211.754 1.649A2.713 2.713 0 0 0 5.571 16H18.43c.681 0 1.335-.247 1.817-.684.482-.438.753-1.03.754-1.65V4.334c0-.618-.272-1.211-.754-1.649A2.713 2.713 0 0 0 18.429 2Zm-3.215 2.333c.382 0 .755.103 1.072.295.317.193.564.466.71.786a1.6 1.6 0 0 1 .11 1.01c-.075.34-.258.652-.528.897s-.613.411-.987.479a2.112 2.112 0 0 1-1.115-.1 1.901 1.901 0 0 1-.865-.644 1.637 1.637 0 0 1-.325-.973c0-.464.204-.908.565-1.237a2.035 2.035 0 0 1 1.363-.513Zm-9.643 10.5c-.34 0-.668-.123-.909-.341a1.115 1.115 0 0 1-.376-.825V11.2l3.81-3.073a2.052 2.052 0 0 1 1.337-.441c.491.013.959.195 1.307.51l2.61 2.363-4.71 4.273H5.57Zm14.143-1.166c0 .31-.135.606-.376.825a1.355 1.355 0 0 1-.91.341h-7.97l4.879-4.426a2.037 2.037 0 0 1 1.237-.415c.453-.001.892.144 1.24.409l1.9 1.437v1.829Z"
      fill="#2B3C50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18.25c0-.414.302-.75.675-.75h16.65c.373 0 .675.336.675.75s-.302.75-.675.75H3.675C3.302 19 3 18.664 3 18.25ZM3 21.25a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFeaturedMedia);
export default ForwardRef;
