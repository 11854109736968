import { useStorage } from "swash/utils/useStorage";

import { AgencyFilter } from "@/containers/image/filters/AgencyFilter";
import { ExpiredFilter } from "@/containers/image/filters/ExpiredFilter";
import { MediaFilter } from "@/containers/image/filters/MediaFilter";
import { PrintAuthorizedFilter } from "@/containers/image/filters/PrintAuthorizedFilter";
import { ShootingDateFilter } from "@/containers/image/filters/ShootingDateFilter";
import { WebAuthorizedFilter } from "@/containers/image/filters/WebAuthorizedFilter";
import { useSearchFormState } from "@/containers/search/SearchForm";

const searchConfig = {
  filters: [
    AgencyFilter,
    MediaFilter,
    ShootingDateFilter,
    PrintAuthorizedFilter,
    WebAuthorizedFilter,
    ExpiredFilter,
  ],
};

export const useImageSearchFormState = (name) => {
  const [layoutMode, setLayoutMode] = useStorage(`${name}-layout-mode`, "grid");
  return useSearchFormState({
    ...searchConfig,
    layoutMode,
    onLayoutModeChange: setLayoutMode,
    name,
  });
};
