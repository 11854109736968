import Immutable from "immutable";

export const version = Immutable.version;
export const Collection = Immutable.Collection;
export const Iterable = Immutable.Iterable;
export const Seq = Immutable.Seq;
export const Map = Immutable.Map;
export const OrderedMap = Immutable.OrderedMap;
export const List = Immutable.List;
export const Stack = Immutable.Stack;
export const Set = Immutable.Set;
export const OrderedSet = Immutable.OrderedSet;
export const Record = Immutable.Record;
export const Range = Immutable.Range;
export const Repeat = Immutable.Repeat;
export const is = Immutable.is;
export const fromJS = Immutable.fromJS;
export const hash = Immutable.hash;
export const isImmutable = Immutable.isImmutable;
export const isCollection = Immutable.isCollection;
export const isKeyed = Immutable.isKeyed;
export const isIndexed = Immutable.isIndexed;
export const isAssociative = Immutable.isAssociative;
export const isOrdered = Immutable.isOrdered;
export const isValueObject = Immutable.isValueObject;
export const isPlainObject = Immutable.isPlainObject;
export const isSeq = Immutable.isSeq;
export const isList = Immutable.isList;
export const isMap = Immutable.isMap;
export const isOrderedMap = Immutable.isOrderedMap;
export const isStack = Immutable.isStack;
export const isSet = Immutable.isSet;
export const isOrderedSet = Immutable.isOrderedSet;
export const isRecord = Immutable.isRecord;
export const get = Immutable.get;
export const getIn = Immutable.getIn;
export const has = Immutable.has;
export const hasIn = Immutable.hasIn;
export const merge = Immutable.merge;
export const mergeDeep = Immutable.mergeDeep;
export const mergeWith = Immutable.mergeWith;
export const mergeDeepWith = Immutable.mergeDeepWith;
export const remove = Immutable.remove;
export const removeIn = Immutable.removeIn;
export const set = Immutable.set;
export const setIn = Immutable.setIn;
export const update = Immutable.update;
export const updateIn = Immutable.updateIn;
