import { createContext, useContext, useMemo } from "react";

import { useRemoteConfig } from "../RemoteConfig";

const EditorConfigContext = createContext();

export const EditorConfigProvider = ({ children, config }) => {
  const { editorConfig, services, defaultLanguage } = useRemoteConfig();
  const value = useMemo(() => {
    const baseConfig = config ?? editorConfig;
    return { ...baseConfig, services, defaultLanguage };
  }, [config, services, defaultLanguage, editorConfig]);

  return (
    <EditorConfigContext.Provider value={value}>
      {children}
    </EditorConfigContext.Provider>
  );
};

export const useEditorConfig = () => {
  return useContext(EditorConfigContext);
};
