import { gql } from "@apollo/client";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { FeaturedMedia, IoCheckmarkCircle } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { CardItemTitle, CardListItemBody } from "@/components/CardListItem";
import { Time } from "@/components/Time";
import { useSafeQuery } from "@/containers/Apollo";
import { WithLayoutMode } from "@/containers/search/LayoutModeContext";
import { agencyFormatter } from "@/services/images";

const PublicationsQuery = gql`
  query ImageSummary_mediaPublications($id: Int!) {
    image(id: $id) {
      id
      mediaPublications(limit: 5) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          date
        }
      }
    }
  }
`;

const FeaturedPublicationsQuery = gql`
  query ImageSummary_featuredMediaPublications($id: Int!) {
    image(id: $id) {
      id
      mediaPublications(where: { featured: true }, limit: 5) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          date
        }
      }
    }
  }
`;

const intersperse = (values, separator) => {
  return values.map((value, index) => (
    <Fragment key={index}>
      {index > 0 ? separator : null}
      {value}
    </Fragment>
  ));
};

const ImageInfos = ({ image }) => {
  const infos = intersperse(
    [
      agencyFormatter.format(image.agencies.map((x) => x.label)),
      image.city,
      image.shootingDate && (
        <Time date={image.shootingDate}>
          {moment(image.shootingDate).format("D MMM")}
        </Time>
      ),
    ].filter(Boolean),
    " - ",
  );

  return (
    <div className="line-clamp-1 shrink-0 text-xs text-grey-on">
      {infos.length ? infos : "Aucun crédit"}
    </div>
  );
};
ImageInfos.fragments = {
  image: gql`
    fragment ImageInfos_image on Image {
      id
      agencies {
        label
      }
      city
      shootingDate
    }
  `,
};

const ImageTitle = ({ caption }) => {
  return <CardItemTitle>{caption || "Aucune légende"}</CardItemTitle>;
};

export const ImageSummary = ({ image }) => {
  return (
    <CardListItemBody>
      <ImageTitle caption={image.caption} />
      <WithLayoutMode mode="grid" fallback>
        <ImageInfos image={image} />
        <div className="mt-auto inline-flex w-full shrink-0 items-center gap-2">
          <ImageIcons image={image} />
        </div>
      </WithLayoutMode>
    </CardListItemBody>
  );
};

const PublicationsTooltip = ({ title, query, image, children }) => {
  const [hovered, setHovered] = useState(false);

  const { data } = useSafeQuery(query, {
    variables: { id: image.id },
    skip: !hovered,
  });

  const tooltip = useMemo(() => {
    const { nodes: publications, pageInfo } =
      data?.image?.mediaPublications || {};

    return (
      <div className="flex flex-col pb-1">
        <div className="mb-1 font-semibold">{title}</div>
        {publications ? (
          <>
            <Time date={publications[0].date} format="DD/MM/YYYY à HH:mm" />
            {publications.length > 1 && (
              <div className="mb-1 mt-3 font-semibold">
                Anciennes publications :
              </div>
            )}
            {publications.slice(1).map(({ id, date }) => (
              <Time key={id} date={date} format="DD/MM/YYYY à HH:mm" />
            ))}
            {pageInfo.hasMore && <div>...</div>}
          </>
        ) : (
          "..."
        )}
      </div>
    );
  }, [data, title]);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip tooltip={tooltip}>
        <span>{children}</span>
      </Tooltip>
    </div>
  );
};
PublicationsTooltip.fragments = {
  image: gql`
    fragment PublicationsTooltip_image on Image {
      id
    }
  `,
};

const PublishedTooltip = ({ image }) => {
  if (!image.published) return null;
  return (
    <PublicationsTooltip
      title="Dernière publication :"
      image={image}
      query={PublicationsQuery}
    >
      <IoCheckmarkCircle className="h-4 w-4 text-success-on-light" />
    </PublicationsTooltip>
  );
};
PublishedTooltip.fragments = {
  image: gql`
    fragment PublishedTooltip_image on Image {
      id
      published
      ...PublicationsTooltip_image
    }
    ${PublicationsTooltip.fragments.image}
  `,
};

const FeaturedPublishedTooltip = ({ image }) => {
  if (!image.featuredPublished) return null;
  return (
    <PublicationsTooltip
      title={
        <>
          <div>Dernière publication</div>
          <div>en tant que média d’appel :</div>
        </>
      }
      image={image}
      query={FeaturedPublicationsQuery}
    >
      <FeaturedMedia className="h-4 w-4 text-dusk-on" />
    </PublicationsTooltip>
  );
};
FeaturedPublishedTooltip.fragments = {
  image: gql`
    fragment FeaturedPublishedTooltip_image on Image {
      id
      featuredPublished: published(where: { featured: true })
      ...PublicationsTooltip_image
    }
    ${PublicationsTooltip.fragments.image}
  `,
};

const ImageIcons = ({ image }) => {
  if (!image.published && !image.featuredPublished) return null;

  return (
    <div className="flex gap-1 py-0.5">
      <PublishedTooltip image={image} />
      <FeaturedPublishedTooltip image={image} />
    </div>
  );
};

ImageIcons.fragments = {
  image: gql`
    fragment ImageIcons_image on Image {
      id
      published
      featuredPublished: published(where: { featured: true })
      ...PublishedTooltip_image
      ...FeaturedPublishedTooltip_image
    }
    ${PublishedTooltip.fragments.image}
    ${FeaturedPublishedTooltip.fragments.image}
  `,
};

ImageSummary.fragments = {
  image: gql`
    fragment ImageSummary_image on Image {
      id
      expired
      caption
      ...ImageInfos_image @include(if: $isGrid)
      ...ImageIcons_image @include(if: $isGrid)
    }
    ${ImageInfos.fragments.image}
    ${ImageIcons.fragments.image}
  `,
};
