import { gql } from "@apollo/client";
import { memo } from "react";
import { Tooltip, TooltipProps } from "swash/Tooltip";

import {
  ArticleStateIconLabel,
  ArticleStateIconLabelProps,
} from "@/containers/article/state/ArticleStateIconLabel";

type TooltipContentProps = {
  states: ArticleStateIconLabelProps["state"][];
};

const TooltipContent = memo<TooltipContentProps>(({ states }) => {
  const [state, ...previousStates] = states;
  if (!state) return null;
  return (
    <>
      <div className="mb-1 font-semibold">État:</div>
      <ArticleStateIconLabel state={state} />
      {previousStates.length > 0 && (
        <>
          <div className="mb-1 mt-4 font-semibold">États précédents:</div>
          <div className="flex flex-col gap-2">
            {previousStates.map((state, index) => (
              <ArticleStateIconLabel key={index} state={state} />
            ))}
          </div>
        </>
      )}
    </>
  );
});

export type SpreadArticleStatesTooltipProps = {
  states: ArticleStateIconLabelProps["state"][];
} & Omit<TooltipProps, "tooltip">;

export const SpreadArticleStatesTooltip = memo<SpreadArticleStatesTooltipProps>(
  ({ states, ...props }) => {
    const [state] = states;
    if (!state) return props.children;
    return (
      <Tooltip
        tooltip={<TooltipContent states={states} />}
        aria-label={state.label}
        {...props}
      />
    );
  },
);

export type ArticleStatesTooltipProps = {
  article: {
    states: ArticleStateIconLabelProps["state"][];
  };
} & Omit<TooltipProps, "tooltip">;

export const ArticleStatesTooltip = ({
  article,
  ...props
}: ArticleStatesTooltipProps) => {
  return <SpreadArticleStatesTooltip states={article.states} {...props} />;
};

ArticleStatesTooltip.fragments = {
  article: gql`
    fragment ArticleStatesTooltip_article on Article {
      states {
        id
        ...ArticleStateIconLabel_state
      }
    }

    ${ArticleStateIconLabel.fragments.state}
  `,
};
