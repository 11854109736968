import * as React from "react";
import { forwardRef } from "react";

const SvgAnchor = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 7.92c.765 0 1.385-.609 1.385-1.36 0-.751-.62-1.36-1.385-1.36s-1.384.609-1.384 1.36c0 .751.62 1.36 1.384 1.36ZM6.115 13.36a1.03 1.03 0 0 0 1.039-1.02 1.03 1.03 0 0 0-1.039-1.02 1.03 1.03 0 0 0-1.038 1.02c0 .563.465 1.02 1.038 1.02ZM17.885 13.36a1.03 1.03 0 0 0 1.038-1.02 1.03 1.03 0 0 0-1.038-1.02 1.03 1.03 0 0 0-1.039 1.02c0 .563.465 1.02 1.039 1.02ZM12 18.8V7.92M9.923 10.64h4.154M6.462 13.36c0 1.082.583 2.12 1.622 2.885C9.123 17.01 10.53 17.44 12 17.44c1.469 0 2.878-.43 3.916-1.195 1.04-.765 1.623-1.803 1.623-2.885"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnchor);
export default ForwardRef;
