import { createContext, useContext } from "react";

const DragContext = createContext();

export function useDragContext() {
  return useContext(DragContext);
}

export function useIsDragging(draggableId) {
  const dragContext = useDragContext();
  return Boolean(dragContext && dragContext.draggableId === draggableId);
}

export function useDragLimit({ draggableId, dragIndex, limit }) {
  const dragging = useIsDragging(draggableId);
  if (dragging) return null;
  if (dragIndex === limit) return { position: "top" };
  if (dragIndex === limit - 1) return { position: "bottom" };
  return null;
}

export function useDragIndex({ index, draggableId, droppableId }) {
  const dragging = useIsDragging(draggableId);
  const dragContext = useDragContext();
  if (dragContext && dragContext.source && dragContext.destination) {
    const { source, destination } = dragContext;
    // Current element is dragging
    if (dragging) return destination.index;
    // We only change the index of the destination droppable zone
    if (destination.droppableId !== droppableId) return index;
    // // We are in the same droppable zone
    if (source.droppableId === droppableId) {
      if (source.index > index && destination.index <= index) {
        return index + 1;
      }
      if (source.index < index && destination.index >= index) {
        return index - 1;
      }
      return index;
    }
    // We are in another droppable zone
    if (destination.index <= index) return index + 1;
  }
  return index;
}

export const DragContextProvider = DragContext.Provider;
