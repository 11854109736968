import * as React from "react";
import { forwardRef } from "react";

const SvgAudioBackward = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.872 12.316V7.568h-.903c-.099.47-.343.55-.665.57l-.35.026v.624h.896v3.528h1.022ZM9.198 12.422c1.061 0 1.793-.703 1.793-1.81 0-1.015-.567-1.705-1.536-1.705-.441 0-.777.153-.994.385l.112-1.002h2.016v-.722H7.92l-.29 2.506.817.186c.106-.371.356-.683.752-.683.454 0 .698.351.698 1.034 0 .71-.204 1.141-.725 1.141-.534 0-.804-.524-.692-1.16l-.981.245c-.08.995.672 1.585 1.7 1.585Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.53 2.368c0-.261.21-.473.47-.473 1.773 0 3.496.593 4.898 1.685a8.057 8.057 0 0 1 2.847 4.35 8.102 8.102 0 0 1-.394 5.196 8.034 8.034 0 0 1-3.472 3.864 7.956 7.956 0 0 1-5.095.916 7.983 7.983 0 0 1-4.586-2.415 8.095 8.095 0 0 1-.982-9.81.469.469 0 0 1 .648-.15c.22.138.287.43.15.652a7.143 7.143 0 0 0 .866 8.656 7.02 7.02 0 0 0 8.542 1.323 7.09 7.09 0 0 0 3.064-3.41c.617-1.447.74-3.06.348-4.584A7.108 7.108 0 0 0 12.32 4.33 7.029 7.029 0 0 0 8 2.842a.472.472 0 0 1-.47-.474Z"
      fill="currentColor"
    />
    <path
      d="M5.266 2.563c-.12-.086-.12-.303 0-.39L8.204.03c.118-.086.267.022.267.195v4.287c0 .173-.149.281-.268.194L5.267 2.563Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAudioBackward);
export default ForwardRef;
