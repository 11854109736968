import { gql } from "@apollo/client";

import { VideoNode } from "@/containers/editor/nodes/VideoNode";
import { fetchMedia } from "@/services/medias/fetchMedia";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "video";
export const ATOMIC_ENTITY_TYPE = "VIDEO";

const VideoQuery = gql`
  query RichEditorVideoPlugin_video($id: Int) {
    node: video(id: $id) {
      id
      ...VideoNode_video
    }
  }

  ${VideoNode.fragments.video}
`;

function VideoBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: VideoQuery,
  });

  return (
    <AtomicBlock {...atomic}>
      {({ node }) => (
        <VideoNode video={node} expanded={props.blockProps.state.expanded} />
      )}
    </AtomicBlock>
  );
}

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: VideoBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return options;
}

const VideoUrlQuery = gql`
  query DraftVideoPluginVideoUrlQuery($id: Int, $url: String, $type: String!) {
    media(id: $id, url: $url, type: $type) {
      id
      ... on Video {
        videoTitle: title
      }
    }
  }
`;

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

export const createBlockFromUrls = async (state, urls) => {
  const [url] = urls;

  const video = await fetchMedia({
    url,
    type: "videos",
    query: VideoUrlQuery,
  });

  if (!video) return null;

  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: video.id,
        media: {
          id: video.id,
          url: video.url,
          videoTitle: video.videoTitle,
        },
      },
    },
  };
};
