import { EditorState } from "draft-js-es";
import { useCallback, useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useSafeQuery } from "@/containers/Apollo";
import { NodeLoader } from "@/containers/editor/nodes/NodeLoader";

import { mergeEntityData } from "../modifiers/mergeEntityData";
import { removeBlock } from "../modifiers/removeBlock";
import { selectBlock } from "../modifiers/selectBlock";
import { setContent } from "../modifiers/setContent";
import { getBlockEntity, getBlockEntityKey } from "./BlockEntity";

export const matchAtomicBlock = (entityType) => (state, block) => {
  if (block.getType() !== "atomic") return false;
  const entity = getBlockEntity({
    block,
    contentState: state.editorState.getCurrentContent(),
  });
  return entity?.getType() === entityType;
};

const useUpdateEntityData = (props) => {
  const { setEditorState } = props.blockProps.state;
  const blockKey = props.block.key;
  const entityKey = getBlockEntityKey(props);
  return useCallback(
    (data) => {
      setEditorState((editorState) =>
        EditorState.push(
          selectBlock(editorState, blockKey),
          mergeEntityData(editorState.getCurrentContent(), entityKey, data),
          "apply-entity",
        ),
      );
    },
    [setEditorState, blockKey, entityKey],
  );
};

export const useAtomicBlockState = ({
  props,
  query,
  getEntityData = () => ({}),
}) => {
  const refs = useLiveRef({ props, getEntityData });
  const entityKey = getBlockEntityKey({ block: props.block });
  const entity = getBlockEntity({
    block: props.block,
    contentState: props.contentState,
  });
  if (!entity) {
    throw new Error("Entity not found");
  }
  const { id, ...metadata } = entity.getData();
  if (!id) {
    throw new Error("Invalid entity");
  }

  const { data } = useSafeQuery(query, {
    variables: { id },
  });

  useEffect(() => {
    if (!data) return;
    const { node } = data;
    const {
      current: { props, getEntityData },
    } = refs;
    const { editorState, setEditorState } = props.blockProps.state;

    // If not does not exist, then remove block
    if (!node) {
      setEditorState((editorState) =>
        removeBlock(editorState, props.block.getKey()),
      );
      return;
    }

    const contentState = editorState.getCurrentContent();
    const entity = contentState.getEntity(entityKey);
    const entityData = entity.getData();
    const entityDataKeys = Object.keys(entityData);
    const dataFromNode = {
      id: node.id,
      media: entityData?.media,
      ...getEntityData(node, entityData),
    };
    const dataFromNodeKeys = Object.keys(dataFromNode);
    // If entity is already populated, then do nothing
    if (entityDataKeys.every((key) => dataFromNodeKeys.includes(key))) {
      return;
    }

    // Else update entity
    setEditorState((editorState) =>
      setContent(
        editorState,
        contentState.mergeEntityData(entityKey, dataFromNode),
      ),
    );
  }, [data, entityKey, refs]);

  const update = useUpdateEntityData(props);

  return { data, metadata, update };
};

export const AtomicBlock = ({ data, metadata, update, children }) => {
  if (!data) return <NodeLoader />;
  if (!data.node) return null;

  return children({ node: data.node, metadata, update });
};
