import * as React from "react";
import { forwardRef } from "react";

const SvgSansSerif = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.356 21.5V5.347H5V2.5h14v2.847h-5.356V21.5h-3.288Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSansSerif);
export default ForwardRef;
