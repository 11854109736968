import { gql } from "@apollo/client";
import { memo } from "react";
import { Indice0, Indice1, Indice2, Indice3 } from "swash/Icon";

import { ArticleEditorialLevelIcon_articleFragment } from "@/gql-types";

type EditorialLevelConfig = {
  icon: typeof Indice0;
  colorClassName: string;
};

const editorialLevels: EditorialLevelConfig[] = [
  { icon: Indice0, colorClassName: "text-grey-on" },
  { icon: Indice1, colorClassName: "text-primary-300" },
  { icon: Indice2, colorClassName: "text-primary-600" },
  { icon: Indice3, colorClassName: "text-primary-900" },
];

const getEditorialLevel = (
  editorialLevel: number | null | undefined,
): EditorialLevelConfig => {
  if (editorialLevel && editorialLevels[editorialLevel]) {
    return editorialLevels[editorialLevel];
  }
  return editorialLevels[0]!;
};

export type SpreadArticleEditorialLevelIconProps =
  ArticleEditorialLevelIcon_articleFragment;

export const SpreadArticleEditorialLevelIcon =
  memo<SpreadArticleEditorialLevelIconProps>((props) => {
    const { icon: Icon, colorClassName } = getEditorialLevel(
      props.editorialLevel,
    );
    return <Icon className={colorClassName} />;
  });

export type ArticleEditorialLevelIconProps = {
  article: SpreadArticleEditorialLevelIconProps;
};

export const ArticleEditorialLevelIcon = (
  props: ArticleEditorialLevelIconProps,
) => {
  return (
    <SpreadArticleEditorialLevelIcon
      editorialLevel={props.article.editorialLevel}
    />
  );
};

ArticleEditorialLevelIcon.fragments = {
  article: gql`
    fragment ArticleEditorialLevelIcon_article on Article {
      editorialLevel
    }
  `,
};
