import { gql } from "@apollo/client";
import { forwardRef } from "react";

type ImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, "alt"> & {
  maxWidth?: number | null;
  maxHeight?: number | null;
  alt?: string | null;
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt, src, srcSet, width, height, maxWidth, maxHeight, ...props }, ref) => {
    return (
      <img
        ref={ref}
        sizes={
          maxWidth
            ? `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`
            : undefined
        }
        srcSet={srcSet}
        src={src}
        alt={alt || "image"}
        width={width}
        height={height || maxHeight || undefined}
        {...props}
        style={{
          ...(maxWidth ? { width: "100%", maxWidth } : null),
          ...props.style,
        }}
      />
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  Image.displayName = "Image";
}

export const ImageFixedFragment = gql`
  fragment ImageFixedFragment on ImageFixed {
    srcSet
    src
    width
    height
    alt
  }
`;

export const ImageFluidFragment = gql`
  fragment ImageFluidFragment on ImageFluid {
    srcSet
    src
    maxWidth
    maxHeight
    alt
  }
`;
