import type { EditorState } from "draft-js-es";
import { Map } from "immutable-es";
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";

import { MentionRemoteSelectProvider } from "@/components/rich-editor/plugins/mention/MentionRemoteSelectProvider";
import defaultRegExp from "@/components/rich-editor/plugins/mention/defaultRegExp";
import type { MentionPluginConfig } from "@/components/rich-editor/plugins/mention/index";

type MentionContextSubRichEditorState = {
  getEditorState(): EditorState;
  setEditorState(newEditorState: EditorState): void;
};

export interface MentionContext {
  registerEditor(editor: MentionContextSubRichEditorState): () => void;
  getEditor(): MentionContextSubRichEditorState;
  isEscaped(offsetKey: string): boolean;
  escapeSearch(offsetKey: string): void;
  resetEscapedSearch(): void;
  register(offsetKey: string): void;
  unregister(offsetKey: string): void;
  getAllSearches(): Map<string, string>;
}

interface MentionPluginConfigContext {
  getConfig(config?: Partial<MentionPluginConfig>): MentionPluginConfig;
}

const MentionContext = createContext<MentionContext | null>(null);
const MentionPluginConfigContext =
  createContext<MentionPluginConfigContext | null>(null);

type MentionProviderProps = {
  config: MentionPluginConfig;
  children: ReactElement;
};

const defaultConfig: MentionPluginConfig = {
  mentionTriggers: ["@"],
  mentionRegExp: defaultRegExp,
  supportWhitespace: true,
  mentionPrefix: "",
  entityMutability: "SEGMENTED",
};

export const MentionProvider = ({
  config: contextConfig = defaultConfig,
  children,
}: MentionProviderProps): ReactElement => {
  const editorRef = useRef<MentionContextSubRichEditorState>();

  const searches = useRef(Map<string, string>());
  const escapedSearch = useRef<string | undefined>();
  const configRef = useRef<MentionPluginConfig>(contextConfig);

  const registerEditor = useCallback(
    (editor: MentionContextSubRichEditorState) => {
      if (!editorRef.current) {
        editorRef.current = editor;
      }
      return () => {
        editorRef.current = undefined;
      };
    },
    [],
  );

  const value: MentionContext = useMemo(
    () => ({
      registerEditor,
      getEditor: () => {
        if (!editorRef.current) {
          throw new Error(
            "Editor has not been registered, call 'registerEditor' before",
          );
        }
        return editorRef.current;
      },

      isEscaped: (offsetKey: string) => escapedSearch.current === offsetKey,
      escapeSearch: (offsetKey: string) => {
        escapedSearch.current = offsetKey;
      },
      resetEscapedSearch: () => {
        escapedSearch.current = undefined;
      },

      register: (offsetKey: string) => {
        searches.current = searches.current.set(offsetKey, offsetKey);
      },
      unregister: (offsetKey: string) => {
        searches.current = searches.current.delete(offsetKey);
      },
      getAllSearches: () => searches.current,
    }),
    [registerEditor],
  );

  const config = useMemo(
    () => ({
      getConfig: (overrideConfig?: Partial<MentionPluginConfig>) => {
        configRef.current = {
          ...configRef.current,
          ...overrideConfig,
        };
        return configRef.current;
      },
    }),
    [],
  );

  return (
    <MentionContext.Provider value={value}>
      <MentionPluginConfigContext.Provider value={config}>
        <MentionRemoteSelectProvider>{children}</MentionRemoteSelectProvider>
      </MentionPluginConfigContext.Provider>
    </MentionContext.Provider>
  );
};

export const useMention = (): MentionContext => {
  const mentionContext = useContext(MentionContext);
  if (!mentionContext) {
    throw new Error(`MentionContextProvider is missing`);
  }
  return mentionContext;
};

const useMentionPluginConfigContext = (): MentionPluginConfigContext => {
  const mentionPluginConfigContext = useContext(MentionPluginConfigContext);
  if (!mentionPluginConfigContext) {
    throw new Error(`MentionPluginConfigProvider is missing`);
  }
  return mentionPluginConfigContext;
};

export const useMentionPluginConfig = (
  config?: Partial<MentionPluginConfig>,
): MentionPluginConfig => {
  const mentionPluginConfigContext = useMentionPluginConfigContext();
  const mergedConfig = mentionPluginConfigContext.getConfig(config);
  if (!mergedConfig) {
    throw new Error(`Plugin mention config missing`);
  }
  return mergedConfig;
};
