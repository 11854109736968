import { memo } from "react";

import { Link as LinkIcon } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { handleBinding } from "../utils";
import type { State } from "../utils/PluginPopover";
import { checkIsActive, command } from "./link";

const SHORTCUT = ["⌘", "k"];

export const shortcuts = [
  {
    description: "Lien",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "link-control";

export const keyBindingFn = (_state: any, event: KeyboardEvent) =>
  handleBinding(SHORTCUT, command, event);

const LinkToolbarButton = memo(({ active }: { active: boolean }) => {
  return (
    // @ts-expect-error use a js component
    <RichEditorToolbarButton
      active={active}
      label="Lien"
      command={command}
      shortcut={SHORTCUT}
    >
      <LinkIcon />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = (state: State) => {
  return <LinkToolbarButton active={checkIsActive(state)} />;
};

BlockControls.group = "inline-style";
