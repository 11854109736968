import { gql } from "@apollo/client";

import { SelectOptionLabelAuthor } from "@/components/SelectOptionLabelAuthor";

export const AuthorFragment = gql`
  fragment SignaturesField_author on Author {
    id
    origin
    ...SelectOptionLabelAuthor_author
  }

  ${SelectOptionLabelAuthor.fragments.author}
`;

export const SignaturesFieldFragment = gql`
  fragment SignaturesField_signatures on Signature {
    id
    origin
    author {
      ...SignaturesField_author
    }
  }

  ${AuthorFragment}
`;
