export const EMOJI_STRICT_REGEX = /:[a-z0-9+_-]+:$/;

export const allowedAttributes = { img: ["alt"] };

export const transformTags = {
  img: (tagName, attrs) => {
    if (attrs.alt?.match(EMOJI_STRICT_REGEX)) {
      return {
        tagName: "span",
        text: attrs.alt,
      };
    }
    return {
      tagName: "span",
      text: "",
    };
  },
};
