import * as React from "react";
import { forwardRef } from "react";

const SvgImagesOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M20.25 5.25V4.5A2.256 2.256 0 0 0 18 2.25H3A2.257 2.257 0 0 0 .75 4.5v12A2.257 2.257 0 0 0 3 18.75h.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M21.094 6H6.656C5.466 6 4.5 6.965 4.5 8.156v11.438c0 1.19.965 2.156 2.156 2.156h14.438c1.19 0 2.156-.965 2.156-2.156V8.156c0-1.19-.965-2.156-2.156-2.156Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M17.48 11.728c.797 0 1.443-.641 1.443-1.432 0-.791-.646-1.432-1.442-1.432-.797 0-1.443.64-1.443 1.432 0 .79.646 1.432 1.443 1.432Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="m16.038 17.445-4.085-4.05a1.45 1.45 0 0 0-1.977-.056L4.5 18.171M12.433 21.75l5.559-5.518a1.453 1.453 0 0 1 1.943-.088l3.315 2.743"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImagesOutline);
export default ForwardRef;
