import type { DocumentNode } from "graphql";
import { orderBy } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { useEventCallback } from "swash/utils/useEventCallback";

export type OrderBy = string;
export type OrderDirection = "asc" | "desc";

export type Grouping<TNode> = {
  getGroupKey: (props: {
    orderDirection: OrderDirection;
  }) => (node: TNode) => string;
  getGroupLabel: (key: string) => string;
};

export type Group<TNode> = {
  key: string;
  nodes: TNode[];
  groups: Group<TNode>[];
  count: number;
};

type GroupToggleState = {
  closed: Record<string, boolean>;
  toggle: (key: string) => void;
};

export type CellType<TNode> = {
  key: string;
  title: string;
  width: number | "1fr";
  minWidth?: number | string;
  cellPadding?: number | string;
  padding?: number | string;
  margin?: number | string;
  align?: "left" | "center" | "right";
  render: (props: { node: TNode }) => React.ReactNode;
  fragment?: DocumentNode;
  useIsVisible?: () => boolean;
  useIsHighlighted?: () => boolean;
};

export type NodeProvider<TNode> = {
  key: string;
  component: (props: {
    node: TNode;
    children: React.ReactNode;
  }) => React.ReactElement;
  fragment?: DocumentNode;
};

type NodeProviderComponentProps<TNode> = {
  provider: NodeProvider<TNode> | null;
  children: React.ReactElement;
  node: TNode;
};

export const NodeProviderComponent = <TNode,>(
  props: NodeProviderComponentProps<TNode>,
) => {
  const { provider, children, node } = props;

  if (!provider) return children;

  const Component = provider.component;
  return (
    <Component key={provider.key} node={node}>
      {children}
    </Component>
  );
};

export type Interaction<TNode> = {
  key: string;
  useInteraction: (props: { node: TNode }) => void;
  fragment?: DocumentNode;
};

export type RunnableInteraction<TNode> = {
  key: string;
  component: (props: { node: TNode }) => null;
  fragment?: DocumentNode;
};

const useGroupToggleState = (): GroupToggleState => {
  const [closed, setClosed] = useState<Record<string, boolean>>({});
  const toggle = useCallback((key: string) => {
    setClosed((closed) => ({
      ...closed,
      [key]: !closed[key],
    }));
  }, []);
  return { closed, toggle };
};

const getGroupMap = <TNode,>(props: {
  nodes: TNode[];
  grouping: Grouping<TNode> | null;
  defaultGroups: Array<string> | null;
  hiddenSelector: (node: TNode) => boolean;
  direction: OrderDirection;
  nodeDirection?: OrderDirection;
  orderBy?: OrderBy | null;
}) => {
  const groupMap = props.nodes.reduce(
    (groups, node) => {
      const key = props.grouping
        ? props.grouping.getGroupKey({ orderDirection: props.direction })(node)
        : "all";
      // We create group if it does not already exist
      const group = groups[key] ?? {
        key,
        nodes: [],
        groups: [],
        count: 0,
      };
      groups[key] = group;
      group.nodes.push(node);
      if (!props.hiddenSelector(node)) {
        group.count += 1;
      }
      return groups;
    },
    {} as Record<string, Group<TNode>>,
  );
  if (props.defaultGroups) {
    props.defaultGroups.forEach((defaultGroup) => {
      const [, defaultInterval, ,] = defaultGroup.split("/");
      const isGroupExist = Object.values(groupMap).find((group) => {
        const [, interval, ,] = group.key.split("/");
        return defaultInterval?.split("--")[0] === interval?.split("--")[0];
      });
      if (!isGroupExist) {
        groupMap[defaultGroup] = {
          key: defaultGroup,
          nodes: [],
          groups: [],
          count: 0,
        };
      }
    });
  }
  const nodeDirection = props.nodeDirection ?? props.direction;

  return orderBy(
    Array.from(Object.values(groupMap)),
    ["key"],
    [props.direction],
  ).map((group) => {
    if (!props.orderBy) {
      return group;
    }
    group.nodes = orderBy(
      group.nodes,
      [props.orderBy, "id"],
      [nodeDirection, nodeDirection],
    );
    return group;
  });
};

const getGroupsFromNodes = <TNode,>(props: {
  nodes: TNode[];
  grouping: Grouping<TNode> | null;
  subGrouping: Grouping<TNode> | null;
  defaultSubGroups: Array<string> | null;
  orderBy: OrderBy | null;
  subGroupingOrderBy?: OrderBy | null;
  subGroupingOrderDirection?: OrderDirection | null;
  orderDirection?: OrderDirection | null;
  hiddenSelector: (node: TNode) => boolean;
}): Group<TNode>[] => {
  const direction = props.orderDirection ?? "asc";
  const groupMap = getGroupMap({
    nodes: props.nodes,
    grouping: props.grouping,
    defaultGroups: null,
    orderBy: props.orderBy,
    hiddenSelector: props.hiddenSelector,
    direction,
  });
  //add props default sub grouping
  if (props.subGrouping) {
    groupMap.forEach((group) => {
      const [, , , allowSubGrouping] = group.key.split("/");
      if (allowSubGrouping === "subgroup") {
        group.groups = getGroupMap({
          nodes: group.nodes,
          grouping: props.subGrouping,
          defaultGroups: props.defaultSubGroups,
          hiddenSelector: props.hiddenSelector,
          orderBy: props.subGroupingOrderBy || props.orderBy,
          direction: direction,
          nodeDirection: props.subGroupingOrderDirection || direction,
        });
        group.nodes = [];
      }
    });
  }
  return groupMap;
};

export const useGroupsFromNodes = <TNode,>(props: {
  nodes: TNode[];
  grouping: Grouping<TNode> | null;
  subGrouping: Grouping<TNode> | null;
  defaultSubGroups: Array<string> | null;
  orderBy: OrderBy | null;
  subGroupingOrderBy?: OrderBy | null;
  subGroupingOrderDirection?: OrderDirection | null;
  orderDirection?: OrderDirection | null;
  hiddenSelector?: (node: TNode) => boolean;
}): Group<TNode>[] => {
  return useMemo(() => {
    return getGroupsFromNodes({
      nodes: props.nodes,
      grouping: props.grouping,
      subGrouping: props.subGrouping,
      subGroupingOrderBy: props.subGroupingOrderBy,
      defaultSubGroups: props.defaultSubGroups,
      orderBy: props.orderBy,
      orderDirection: props.orderDirection,
      subGroupingOrderDirection: props.subGroupingOrderDirection,
      hiddenSelector: props.hiddenSelector ?? (() => false),
    });
  }, [
    props.nodes,
    props.grouping,
    props.orderBy,
    props.orderDirection,
    props.defaultSubGroups,
    props.hiddenSelector,
    props.subGrouping,
    props.subGroupingOrderBy,
    props.subGroupingOrderDirection,
  ]);
};

export type GroupRowType<TNode> = {
  type: "group";
  group: Group<TNode>;
  open: boolean;
};

export type SubGroupRowType<TNode> = {
  type: "sub-group";
  group: Group<TNode>;
  open: boolean;
};

export type HeaderRowType = {
  type: "header";
};

export type NodeRowType<TNode> = {
  type: "node";
  node: TNode;
  first: boolean;
  last: boolean;
  active: boolean;
  hidden: boolean;
};

export type LoaderRowType = {
  type: "loader";
};

export type EndRowType = {
  type: "end";
};

export type RowType<TNode> =
  | GroupRowType<TNode>
  | SubGroupRowType<TNode>
  | HeaderRowType
  | NodeRowType<TNode>
  | LoaderRowType
  | EndRowType;

const buildGroupRow = <TNode,>(params: {
  group: Group<TNode>;
  closed: Record<string, boolean>;
  activeSelector: (node: TNode) => boolean;
  hiddenSelector: (node: TNode) => boolean;
  type: "group" | "sub-group";
}): RowType<TNode>[] => {
  const { group, closed, hiddenSelector, activeSelector, type } = params;
  const open = !closed[group.key];
  if (!open) {
    return [
      {
        type,
        group,
        open,
      },
    ];
  }
  const visibleNodes = group.nodes.filter((node) => {
    return !hiddenSelector(node);
  });
  const header = [];
  if (!group.groups.length && group.nodes.length)
    header.push({ type: "header" as const });
  return [
    {
      type,
      group,
      open,
    },
    ...header,
    ...group.nodes.map((node) => {
      const first = visibleNodes[0] === node;
      const last = visibleNodes[visibleNodes.length - 1] === node;
      return {
        type: "node" as const,
        node,
        first,
        last,
        active: activeSelector(node),
        hidden: hiddenSelector(node),
      };
    }),
  ];
};

const getRows = <TNode,>(params: {
  groups: Group<TNode>[];
  closed: Record<string, boolean>;
  activeSelector: (node: TNode) => boolean;
  hiddenSelector: (node: TNode) => boolean;
  showEmptyGroups?: boolean;
}): RowType<TNode>[] => {
  return params.groups
    .map((group) => {
      const mainGroup = buildGroupRow({
        group,
        closed: params.closed,
        activeSelector: params.activeSelector,
        hiddenSelector: params.hiddenSelector,
        type: "group",
      });
      const open = !params.closed[group.key];
      const secondGroup = !open
        ? []
        : group.groups.flatMap((subGroup) => {
            return [
              ...buildGroupRow({
                group: subGroup,
                closed: params.closed,
                activeSelector: params.activeSelector,
                hiddenSelector: params.hiddenSelector,
                type: "sub-group",
              }),
            ];
          });
      return [...mainGroup, ...secondGroup];
    })
    .flat()
    .filter((row) => {
      if (row.type === "sub-group") {
        return params.showEmptyGroups === false
          ? Boolean(row.group.count)
          : true;
      }
      return true;
    });
};

const useRows = <TNode,>(props: {
  nodes: TNode[];
  grouping: Grouping<TNode> | null;
  subGrouping: Grouping<TNode> | null;
  defaultSubGroups: Array<string> | null;
  orderBy: OrderBy | null;
  subGroupingOrderBy: OrderBy | null;
  orderDirection?: OrderDirection | null;
  subGroupingOrderDirection?: OrderDirection | null;
  groupToggleState: GroupToggleState;
  activeSelector: (node: TNode) => boolean;
  hiddenSelector: (node: TNode) => boolean;
  showEmptyGroups?: boolean;
}): RowType<TNode>[] => {
  const groups = useGroupsFromNodes(props);
  return useMemo(
    () =>
      getRows({
        groups,
        closed: props.groupToggleState.closed,
        activeSelector: props.activeSelector,
        hiddenSelector: props.hiddenSelector,
        showEmptyGroups: props.showEmptyGroups,
      }),
    [
      groups,
      props.groupToggleState.closed,
      props.activeSelector,
      props.hiddenSelector,
      props.showEmptyGroups,
    ],
  );
};

export type ListStateProps<TNode> = {
  cells: CellType<TNode>[];
  interactions?: Interaction<TNode>[];
  provider?: NodeProvider<TNode> | null;
  nodes: TNode[];
  hasMore: boolean;
  orderBy?: OrderBy | null;
  subGroupingOrderBy?: OrderBy | null;
  subGroupingOrderDirection?: OrderDirection | null;
  orderDirection?: OrderDirection | null;
  grouping?: Grouping<TNode> | null;
  subGrouping?: Grouping<TNode> | null;
  defaultSubGroups?: Array<string> | null;
  activeSelector?: (node: TNode) => boolean;
  hiddenSelector?: (node: TNode) => boolean;
  onActiveChange?: (node: TNode) => void;
  readOnly?: boolean;
  portal?: boolean;
  toolbar?: React.ReactNode;
  showEmptyGroups?: boolean;
};

export type ListState<TNode> = {
  cells: CellType<TNode>[];
  interactions: RunnableInteraction<TNode>[];
  provider: NodeProvider<TNode> | null;
  grouping: Grouping<TNode> | null;
  subGrouping: Grouping<TNode> | null;
  defaultSubGroups: Array<string> | null;
  toggleGroup: (key: string) => void | null;
  rows: RowType<TNode>[];
  hasMore: boolean;
  readOnly: boolean;
  portal: boolean;
  toolbar: React.ReactNode | null;
  onActiveChange: (node: TNode) => void;
};

const defaultActiveSelector = () => false;
const defaultHiddenSelector = () => false;

export const useListState = <TNode,>(
  props: ListStateProps<TNode>,
): ListState<TNode> => {
  const groupToggleState = useGroupToggleState();
  const { toggle: toggleGroup } = groupToggleState;
  const rows = useRows({
    grouping: props.grouping ?? null,
    subGrouping: props.subGrouping ?? null,
    defaultSubGroups: props.defaultSubGroups ?? null,
    orderBy: props.orderBy ?? null,
    subGroupingOrderBy: props.subGroupingOrderBy ?? null,
    orderDirection: props.orderDirection,
    subGroupingOrderDirection: props.subGroupingOrderDirection,
    nodes: props.nodes,
    groupToggleState,
    activeSelector: props.activeSelector ?? defaultActiveSelector,
    hiddenSelector: props.hiddenSelector ?? defaultHiddenSelector,
    showEmptyGroups: props.showEmptyGroups,
  });
  const rowsWithLoader = useMemo(() => {
    if (!props.hasMore) {
      return [...rows, { type: "end" as const }];
    }
    return [...rows, { type: "loader" as const }];
  }, [rows, props.hasMore]);
  const onActiveChange = useEventCallback((node: TNode) => {
    props.onActiveChange?.(node);
  });
  return useMemo(
    () => ({
      rows: rowsWithLoader,
      toggleGroup,
      cells: props.cells,
      interactions: (props.interactions ?? []).map((interaction) => {
        return {
          ...interaction,
          component: (props: { node: TNode }) => {
            interaction.useInteraction(props);
            return null;
          },
        };
      }),
      provider: props.provider ?? null,
      grouping: props.grouping ?? null,
      subGrouping: props.subGrouping ?? null,
      defaultSubGroups: props.defaultSubGroups ?? null,
      hasMore: props.hasMore,
      readOnly: props.readOnly ?? false,
      portal: props.portal ?? false,
      toolbar: props.toolbar ?? null,
      onActiveChange,
    }),
    [
      rowsWithLoader,
      toggleGroup,
      props.cells,
      props.interactions,
      props.provider,
      props.grouping,
      props.subGrouping,
      props.defaultSubGroups,
      props.hasMore,
      props.readOnly,
      props.portal,
      props.toolbar,
      onActiveChange,
    ],
  );
};
