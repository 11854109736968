import { gql } from "@apollo/client";

import { useDragUrl } from "@/containers/Dnd";
import { ArchiveNode } from "@/containers/editor/nodes/ArchiveNode";

import { MediaLayout } from "./MediaLayout";
import { getMediaEventTooltip } from "./util";

export function MediaArchive({
  media: { media: archive, ...mediaProps },
  layoutBg,
  draggable,
  deletable,
  onDelete,
  ...props
}) {
  const [, dragRef] = useDragUrl(archive.cloudPath);

  return (
    <MediaLayout
      draggable={draggable}
      ref={draggable ? dragRef : null}
      deletable={deletable}
      onDelete={() => onDelete(archive.id)}
      {...props}
    >
      <ArchiveNode
        archive={archive}
        tooltip={getMediaEventTooltip(mediaProps)}
        editable
      />
    </MediaLayout>
  );
}

MediaArchive.fragments = {
  media: gql`
    fragment MediaArchive_media on Media {
      id
      ...ArchiveNode_archive
    }
    ${ArchiveNode.fragments.archive}
  `,
};
