import { gql } from "@apollo/client";
import { PanelBody, PanelHeader } from "swash/Panel";
import { Dialog, DialogDisclosure, useDialogStore } from "swash/v2/Dialog";

import { DescriptionList } from "@/components/DescriptionList";
import { useSafeQuery } from "@/containers/Apollo";

export { useDialogStore, DialogDisclosure };

const ImageQuery = gql`
  query ImageDetailDialog_image($imageId: Int!) {
    image(id: $imageId) {
      id
      metadata
      width
      height
      url
      mimeType
      fromAgencyFlow
    }
  }
`;

export const ImageDetailDialog = ({ imageId, store }) => {
  const open = store.useState("open");
  const { data } = useSafeQuery(ImageQuery, {
    skip: !open,
    variables: { imageId },
  });

  return (
    <Dialog
      store={store}
      style={{
        width: "100%",
        maxWidth: 896,
      }}
    >
      <PanelHeader
        title={`Détail de l’image #${imageId}`}
        onClose={store.hide}
      />
      <PanelBody>
        <DescriptionList className="mb-4">
          <dt>Type d’import</dt>
          <dd>
            {data
              ? data.image.fromAgencyFlow
                ? "Flux FTP"
                : "Import manuel"
              : "-"}
          </dd>
          <dt>Type de média</dt>
          <dd>{data ? data.image.mimeType : "-"}</dd>
          <dt>Dimensions originales</dt>
          <dd>{data ? `${data.image.width}x${data.image.height}` : "-"}</dd>
          <dt>URL de l’image originale</dt>
          <dd>{data ? data.image.url : "-"}</dd>
          <dt>Metadata originales</dt>
          <dd>
            <pre className="my-1 h-72 overflow-auto whitespace-pre-wrap rounded-md border border-grey-border-light p-2 text-sm">
              <code>
                {data ? JSON.stringify(data.image.metadata, null, 2) : "-"}
              </code>
            </pre>
          </dd>
        </DescriptionList>
      </PanelBody>
    </Dialog>
  );
};
