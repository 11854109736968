import { getIn } from "final-form";
import * as React from "react";
import { useForm } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";

export function useSubscribeFormValue(name, { isEqual = Object.is } = {}) {
  const form = useForm();
  const [value, setValue] = React.useState(() =>
    getIn(form.getState().values, name),
  );
  const valueRef = useLiveRef(value);
  const isEqualRef = useLiveRef(isEqual);
  React.useEffect(
    () =>
      form.subscribe(
        ({ values }) => {
          const value = getIn(values, name);
          if (!isEqualRef.current(valueRef.current, value)) {
            setValue(value);
          }
        },
        { values: true },
      ),
    [form, name, valueRef, isEqualRef],
  );
  return value;
}

export function useSubscribePreviousFormValues(subscribe) {
  const form = useForm();
  const currentValuesRef = React.useRef();
  const previousValuesRef = React.useRef();
  const subscribeRef = useLiveRef(subscribe);
  React.useEffect(() => {
    // Set initial values
    const { values } = form.getState();
    currentValuesRef.current = values;
    previousValuesRef.current = values;
    return form.subscribe(
      ({ values }) => {
        currentValuesRef.current = values;
        subscribeRef.current({
          form,
          values: currentValuesRef.current,
          previousValues: previousValuesRef.current,
        });
        previousValuesRef.current = values;
      },
      {
        values: true,
      },
    );
  }, [form, subscribeRef]);
}

export function useFormCalculate({ skip, field, calculate }) {
  const form = useForm();
  const propsRef = useLiveRef({ field, skip, calculate });

  React.useEffect(() => {
    return form.subscribe(
      ({ values, touched, active }) => {
        const { field, skip, calculate } = propsRef.current;
        const fieldTouchedOrActive = touched[field] || active === field;
        const result = calculate({
          values,
        });
        if (skip || fieldTouchedOrActive || result === undefined) return;
        form.change(field, result);
      },
      { values: true, touched: true, active: true },
    );
  }, [form, propsRef]);
}
