import {
  Dialog as AriakitDialog,
  DialogDisclosure,
  DialogProps,
  DialogStoreProps,
  useDialogStore as useAriakitDialogState,
} from "@ariakit/react-legacy";
import clsx from "clsx";
import { forwardRef } from "react";

import { Panel } from "./Panel";

export const DialogPanelDisclosure = DialogDisclosure;

export function useDialogPanelStore(props: DialogStoreProps) {
  return useAriakitDialogState({
    animated: true,
    ...props,
  });
}

export type DialogPanelProps = DialogProps & {
  animation?: "from-right" | "from-right-complete";
  fullScreen?: boolean;
};

export const DialogPanel = forwardRef<HTMLDivElement, DialogPanelProps>(
  (
    {
      store,
      className,
      fullScreen = true,
      children,
      animation = "from-right",
      ...props
    },
    ref,
  ) => {
    const animated = store?.useState("animated");
    return (
      <AriakitDialog
        ref={ref}
        className={clsx(
          "swash-dialog-panel",
          fullScreen && "fixed right-0 top-0 z-dialog-panel h-screen",
          className,
        )}
        store={store}
        modal={false}
        hideOnInteractOutside={false}
        autoFocusOnShow={false}
        autoFocusOnHide={false}
        data-animation={animation}
        data-animated={animated ? "" : undefined}
        render={<Panel />}
        {...props}
      >
        {children}
      </AriakitDialog>
    );
  },
);
