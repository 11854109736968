// @ts-check
import rehypeMinifyWhitespace from "rehype-minify-whitespace";
import { unified } from "unified";

import { flatten } from "./flatten";
import { normalizeSpaces } from "./normalizeSpaces";
import { parseWordList } from "./parseWordList";
import { removeEmptyNodes } from "./removeEmptyNodes";

/**
 * @param {import('hast').Root} root
 */
export const normalize = (root) => {
  root = /** @type {import('hast').Root} */ (
    unified().use(rehypeMinifyWhitespace).runSync(root)
  );
  root = flatten(root);
  root = removeEmptyNodes(root);
  root = parseWordList(root);
  root = normalizeSpaces(root);

  return root;
};
