import { gql, useSubscription } from "@apollo/client";
import { memo } from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Button, ButtonProps } from "swash/Button";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { useUser } from "@/containers/User";
import type { ArticleLiveLink_articleFragment } from "@/gql-types";

const LiveUpdatedSubscription = gql`
  subscription ArticleLiveLink_liveUpdated($liveId: Int!) {
    liveUpdated(where: { id: { eq: $liveId } }) {
      id
      closed
    }
  }
`;

export type SpreadArticleLiveLinkProps = {
  liveId: number;
  closed: boolean;
  className?: string;
  scale?: ButtonProps["scale"];
};

export const SpreadArticleLiveLink = memo<SpreadArticleLiveLinkProps>(
  ({ liveId, closed, scale, className }) => {
    const user = useUser();

    useSubscription(LiveUpdatedSubscription, {
      variables: { liveId },
    });
    return (
      <Button asChild appearance="text" scale={scale} className={className}>
        <ReactRouterLink
          to={`/lives/${liveId}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          target={user.settings.openLinkInNewTab ? "_blank" : undefined}
        >
          <Tooltip tooltip={closed ? "Live clos" : "Live ouvert"}>
            <div
              className={cn(
                closed ? "bg-danger-bg-strong" : "bg-success-bg-strong",
                "h-2 w-2 rounded-full",
              )}
            />
          </Tooltip>
          Accéder au live
        </ReactRouterLink>
      </Button>
    );
  },
);

export type ArticleLiveLinkProps = {
  article: ArticleLiveLink_articleFragment;
  scale?: ButtonProps["scale"];
  className?: string;
};

export const ArticleLiveLink = ({
  article,
  ...props
}: ArticleLiveLinkProps) => {
  if (!article.live || article.layout.type !== "siriusLive") return null;
  return (
    <SpreadArticleLiveLink
      liveId={article.live.id}
      closed={article.live.closed}
      {...props}
    />
  );
};

ArticleLiveLink.fragments = {
  article: gql`
    fragment ArticleLiveLink_article on Article {
      live {
        id
        closed
      }
      layout {
        type
      }
    }
  `,
};
