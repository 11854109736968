import { DocumentNode, gql } from "@apollo/client";
import {
  ForwardRefExoticComponent,
  HTMLAttributes,
  forwardRef,
  memo,
} from "react";

import { ArticleStateIcon, ArticleStateIconProps } from "./ArticleStateIcon";

export type ArticleStateIconLabelProps = {
  state: {
    label: string;
  } & ArticleStateIconProps["state"];
} & HTMLAttributes<HTMLDivElement>;

export const ArticleStateIconLabel = memo<ArticleStateIconLabelProps>(
  forwardRef<HTMLDivElement, ArticleStateIconLabelProps>(
    ({ state, ...props }, ref) => {
      return (
        <div
          ref={ref}
          className="flex select-none items-center gap-2 font-accent"
          {...props}
        >
          <ArticleStateIcon state={state} />
          {state.label}
        </div>
      );
    },
  ),
) as ForwardRefExoticComponent<ArticleStateIconLabelProps> & {
  fragments: { state: DocumentNode };
};

ArticleStateIconLabel.fragments = {
  state: gql`
    fragment ArticleStateIconLabel_state on AbstractWorkflowState {
      label
      ...ArticleStateIcon_state
    }

    ${ArticleStateIcon.fragments.state}
  `,
};
