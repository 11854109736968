import * as article from "@/components/rich-editor/plugins/article";
import * as articleMedia from "@/components/rich-editor/plugins/article-media";
import * as customTypesContent from "@/components/rich-editor/plugins/custom-types-content";
import * as image from "@/components/rich-editor/plugins/image";
import * as separator from "@/components/rich-editor/plugins/separator";
import * as snippet from "@/components/rich-editor/plugins/snippet";
import * as tweet from "@/components/rich-editor/plugins/tweet";
import * as video from "@/components/rich-editor/plugins/video";
import {
  articlesProvider,
  customTypeContentsProvider,
  imagesProvider,
  snippetsProvider,
  videosProvider,
} from "@/services/medias/providers";

/**
 *
 * @typedef {'image'|'caption'} ImageEditOptions
 */

/**
 * @typedef Options
 * @property {string} frontHost
 * @property {string} imageHost
 * @property {boolean} [separator]
 * @property {boolean} [product]
 * @property {object} [image]
 * @property {boolean} [image.add]
 * @property {ImageEditOptions} [image.editable]
 */

/**
 * @param {Options} options
 */
const presetBlocks = (options) => ({
  plugins: [
    ...(options.separator !== false ? [separator] : []),
    ...(options.article !== false
      ? [
          [
            article,
            {
              getDataFromUrl: (url) =>
                articlesProvider.getData({
                  url,
                  frontHost: options.frontHost,
                }),
            },
          ],
        ]
      : []),
    ...(options.image !== false
      ? [
          [
            image,
            {
              getDataFromUrl: (url) =>
                imagesProvider.getData({ url, imageHost: options.imageHost }),
              editable: options.image?.editable,
            },
          ],
        ]
      : []),
    ...(options.video !== false
      ? [
          [
            video,
            {
              getDataFromUrl: (url) =>
                videosProvider.getData({ url, services: options.services }),
            },
          ],
        ]
      : []),
    ...(options.tweet !== false ? [[tweet]] : []),
    ...(options.snippet !== false
      ? [
          [
            snippet,
            {
              getDataFromUrl: (url) => snippetsProvider.getData({ url }),
            },
          ],
        ]
      : []),
    ...(options.articleMedia !== false
      ? [
          [
            articleMedia,
            {
              getDataFromUrl: (url) => url,
              services: options.services,
              ...options.articleMedia,
            },
          ],
        ]
      : []),
    ...(options.customTypesContent !== false
      ? [
          [
            customTypesContent,
            {
              getDataFromUrl: (url) =>
                customTypeContentsProvider.getData({ url }),
            },
          ],
        ]
      : []),
  ],
});

export default presetBlocks;
