import { gql, useSubscription } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { Card, CardBody } from "swash/Card";
import { EmptyState, EmptyStateTitle } from "swash/EmptyState";
import { Link } from "swash/Link";
import { PageLoader } from "swash/Loader";

import { SectionTitle } from "@/components/Layout";
import {
  mergeConnections,
  prependNode,
  useSafeQuery,
} from "@/containers/Apollo";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { ErrorBlock, ErrorMessage } from "@/containers/GenericErrorPage";
import { useUser } from "@/containers/User";
import { ActivityItem } from "@/containers/admin/CRUD/history/ActivityInfo";
import { AuditTrail } from "@/containers/admin/CRUD/history/AuditTrail";
import {
  Comment,
  CommentDeleteDialog,
  CreateCommentForm,
} from "@/containers/admin/CRUD/history/Comment";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const ActivityItemFragment = gql`
  fragment ActivityItemFragment on ActivityItem {
    id
    globalId
    ...ActivityItemAvatarFragment
    ...AuditTrailFragment
    ...CommentFragment
  }
  ${ActivityItem.fragments.activityItemAvatar}
  ${AuditTrail.fragments.auditTrail}
  ${Comment.fragments.comment}
`;

const ActivitiesQuery = gql`
  query ActivitiesQuery($offset: Int, $limit: Int, $where: ActivityWhere) {
    activities(offset: $offset, limit: $limit, where: $where) {
      nodes {
        id
        ...ActivityItemFragment
      }
      ...ConnectionFragment
    }
  }

  ${ActivityItemFragment}
  ${ConnectionFragment}
`;

const CreateActivitySubscription = gql`
  subscription ActivityHistory_activityCreated($where: ActivityWhere!) {
    activity: activityCreated(where: $where) {
      ...ActivityItemFragment
    }
  }

  ${ActivityItemFragment}
`;

export const ActivityHistory = ({
  resource,
  resourceId,
  scope,
  fieldsMap = [],
  term,
  noComment = false,
}) => {
  const user = useUser();
  const where = useMemo(() => {
    const filters = { resource, noComment };
    if (resourceId) filters.resourceId = { eq: resourceId };
    if (scope) filters.scope = { eq: scope };
    return filters;
  }, [resource, resourceId, scope, noComment]);

  const queryOptions = useMemo(() => {
    return {
      query: ActivitiesQuery,
      variables: { where },
    };
  }, [where]);

  const { data, loading, fetchMore } = useSafeQuery(ActivitiesQuery, {
    fetchPolicy: "network-only",
    variables: { where },
  });

  useSubscription(CreateActivitySubscription, {
    skip: !data,
    variables: { where },
    onData: ({ client, data: { data } }) => {
      if (!data?.activity) return;
      const queryData = client.readQuery(queryOptions);
      if (!queryData) return;

      client.writeQuery({
        ...queryOptions,
        data: {
          ...queryData,
          activities: {
            ...queryData.activities,
            nodes: prependNode(
              queryData.activities.nodes,
              data.activity,
              "globalId",
            ),
          },
        },
      });
    },
  });

  const nodes = data?.activities.nodes ?? null;
  const activities = useMemo(() => nodes ?? [], [nodes]);
  const offset = activities.length;

  const loadMore = useCallback(() => {
    fetchMore({
      variables: { offset },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          activities: mergeConnections(
            previousResult.activities,
            fetchMoreResult.activities,
          ),
        };
      },
    });
  }, [fetchMore, offset]);

  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const handleCommentDeleteClose = useCallback(() => {
    setDeletingCommentId(null);
  }, []);

  if (!data) return <PageLoader />;

  return (
    <>
      <div
        role="list"
        aria-label="Activité"
        className="flex flex-col gap-6 py-4"
      >
        {noComment ? null : (
          <ActivityItem user={user}>
            <CreateCommentForm
              resource={resource}
              resourceId={resourceId}
              scope={scope}
            />
          </ActivityItem>
        )}
        {!activities.length && (
          <EmptyState>
            <EmptyStateTitle>Aucune activité</EmptyStateTitle>
          </EmptyState>
        )}
        {activities.map((activity) => {
          switch (activity.__typename) {
            case "AuditTrail": {
              return (
                <ErrorBoundary key={activity.globalId} component={ErrorMessage}>
                  <AuditTrail
                    auditTrail={activity}
                    fieldsMap={fieldsMap}
                    term={term}
                  />
                </ErrorBoundary>
              );
            }
            case "CommentThread":
              return (
                <ActivityItem user={activity.user} key={activity.globalId}>
                  <Comment
                    comment={activity}
                    setDeletingCommentId={setDeletingCommentId}
                  />
                </ActivityItem>
              );
            default:
              return null;
          }
        })}
        {data?.activities.pageInfo.hasMore && (
          <div className="text-center">
            <Link asChild>
              <button
                type="button"
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  loadMore();
                }}
              >
                Voir plus...
              </button>
            </Link>
          </div>
        )}
      </div>
      <CommentDeleteDialog
        commentId={deletingCommentId}
        queryOptions={queryOptions}
        onClose={handleCommentDeleteClose}
      />
    </>
  );
};

export const CRUDActivityHistory = (props) => {
  return (
    <Card className="mt-4">
      <CardBody>
        <SectionTitle>Activité</SectionTitle>
        <ErrorBoundary component={ErrorBlock}>
          <ActivityHistory {...props} />
        </ErrorBoundary>
      </CardBody>
    </Card>
  );
};
