import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useScrollListener } from "swash/utils/useScrollListener";

type Scrolling = boolean;

type ScrollingContext = {
  scrolling: Scrolling;
  scrollListenerRef: (value: HTMLElement | null) => void;
};

const ScrollingContext = createContext<ScrollingContext | undefined>(undefined);

export const useScrolling = () => {
  const ctx = useContext(ScrollingContext);
  if (ctx === undefined) {
    throw new Error("useScrolling must be used within a ScrollingProvider");
  }
  return ctx;
};

type ScrollingProviderProps = {
  children: React.ReactNode;
};

type TimeOutRef = NodeJS.Timeout | null;

export const ScrollingProvider = ({ children }: ScrollingProviderProps) => {
  const timeoutRef = useRef<TimeOutRef>(null);

  const [scrolling, setScrolling] = useState(false);

  const scrollListener = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => setScrolling(false), 300);
    setScrolling(true);
  }, []);

  const scrollListenerRef = useScrollListener(scrollListener);

  const value = useMemo(
    () => ({ scrollListenerRef, scrolling }),
    [scrollListenerRef, scrolling],
  );
  return (
    <ScrollingContext.Provider value={value}>
      {children}
    </ScrollingContext.Provider>
  );
};
