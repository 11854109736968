import type { DraftHandleValue } from "draft-js-es";
import { useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { addDecorator } from "@/components/rich-editor/modifiers/addDecorator";
import { Mention as DecoratedMention } from "@/components/rich-editor/plugins/mention/Mention";
import {
  useMention,
  useMentionPluginConfig,
} from "@/components/rich-editor/plugins/mention/MentionPluginContext";
import { useMentionRemoteSelect } from "@/components/rich-editor/plugins/mention/MentionRemoteSelectProvider";
import { MentionSuggestionsPortal as DecoratedMentionSuggestionsPortal } from "@/components/rich-editor/plugins/mention/MentionSuggestionsPortal";
import type { TriggerMentionQuery } from "@/components/rich-editor/plugins/mention/MentionSuggestionsSelect";
import mentionStrategy from "@/components/rich-editor/plugins/mention/mentionStrategy";
import { mentionSuggestionsStrategy } from "@/components/rich-editor/plugins/mention/mentionSuggestionsStrategy";

import type { RichEditorState } from "../../RichEditorState";

export const name = "mention";

export interface MentionData {
  name: string;
  id: number;
  ___typename: string;
  [x: string]: any;
}

export interface MentionPluginConfig {
  mentionPrefix: string;
  entityMutability: "SEGMENTED" | "IMMUTABLE" | "MUTABLE";
  mentionTriggers: string[];
  mentionRegExp: string;
  supportWhitespace: boolean;
  triggerMentionQueries?: Map<string, TriggerMentionQuery>;
}

export interface MentionPluginProps {
  isMentionOpen(): boolean;
  onDownArrow?(event: KeyboardEvent): void;
  onUpArrow?(event: KeyboardEvent): void;
  onEscape?(event: KeyboardEvent): void;
  onTab?(event: KeyboardEvent): void;
  onMove?(event: KeyboardEvent): void;
  handleReturn?(event: KeyboardEvent): DraftHandleValue;
}

export const usePluginProps = (
  state: RichEditorState & { options?: MentionPluginConfig },
): MentionPluginProps => {
  const { mentionTriggers, supportWhitespace, mentionRegExp } =
    useMentionPluginConfig(state.options);
  const { registerEditor } = useMention();
  const {
    isOpen,
    onDownArrow,
    onUpArrow,
    onEscape,
    onMove,
    onTab,
    handleReturn,
    onEditorStateChange,
  } = useMentionRemoteSelect();
  const stateRef = useLiveRef(state);
  const timeoutId = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    return registerEditor({
      getEditorState: () => stateRef.current.editorState,
      setEditorState: (newEditorState) =>
        stateRef.current.setEditorState(newEditorState),
    });
  }, [registerEditor, stateRef]);

  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      stateRef.current.setEditorState(
        addDecorator(stateRef.current.editorState, [
          {
            strategy: mentionStrategy(mentionTriggers),
            component: DecoratedMention,
          },
          {
            strategy: mentionSuggestionsStrategy(
              mentionTriggers,
              supportWhitespace,
              mentionRegExp,
            ),
            component: DecoratedMentionSuggestionsPortal,
          },
        ]),
      );
    });
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [mentionRegExp, mentionTriggers, stateRef, supportWhitespace]);

  useEffect(() => {
    if (onEditorStateChange) onEditorStateChange(state);
  }, [onEditorStateChange, state]);

  return {
    isMentionOpen: isOpen,
    onDownArrow,
    onUpArrow,
    onEscape,
    onMove,
    onTab,
    handleReturn,
  };
};

export const keyBindingFn = (
  state: RichEditorState & { props: MentionPluginProps },
  event: KeyboardEvent,
) => {
  if (state.props.isMentionOpen()) {
    if (event.key === "ArrowDown") {
      state.props.onDownArrow?.(event);
    }
    if (event.key === "ArrowUp") {
      state.props.onUpArrow?.(event);
    }
    if (event.key === "Escape") {
      state.props.onEscape?.(event);
    }
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      state.props.onMove?.(event);
    }
    if (event.key === "Tab") {
      state.props.onTab?.(event);
    }
  }
};

export const handleReturn = (
  state: RichEditorState & { props: MentionPluginProps },
  event: KeyboardEvent,
): DraftHandleValue | undefined | void => {
  if (state.props.isMentionOpen()) {
    return state.props.handleReturn?.(event);
  }
};
