const TAG_REGEXP = /<li>(?<content>(?:(?!<\/li>).)+)<\/li>/gsu;

const splitListNode = (node) => {
  return [...node.text.matchAll(TAG_REGEXP)].reduce(
    (result, match, currentIndex) => {
      const comments = node.comments?.filter(
        (comment) => comment.listIndex === currentIndex,
      );
      // Handle h2 in li
      if (match.groups.content.startsWith("<h2>")) {
        result.push({
          ...node,
          comments,
          text: match.groups.content,
          metadata: { type: "header_two", styleName: "chapterTitle" },
          id: undefined,
        });
        return result;
      }

      result.push({
        ...node,
        comments,
        text: match.groups.content,
        metadata: {
          type: node.metadata.type,
          styleName: node.metadata.styleName,
        },
        id: undefined,
      });
      return result;
    },
    [],
  );
};

const addSeparator = (type, nodes, index, result) => {
  if (nodes[index + 1]?.metadata?.type === type) {
    result.push({
      type: "text",
      text: "",
      metadata: { type: "paragraph" },
    });
  }
};

/**
 * Some legacy nodes contains `<li></li>` inside, we need to split them.
 * @param {*} nodes
 * @returns
 */
export const expandArticleNodes = (nodes) => {
  return nodes.reduce((result, node, index) => {
    switch (node.metadata?.type) {
      case "ordered_list_item":
      case "unordered_list_item":
        result.push(...splitListNode(node));
        addSeparator(node.metadata.type, nodes, index, result);
        return result;
      default:
        result.push(node);
        return result;
    }
  }, []);
};
