import { memo, useEffect, useState } from "react";
import { cn } from "swash/utils/classNames";

const identity = (x) => x;

export const EditInline = memo(
  ({
    editing,
    initialValue,
    placeholder,
    onClose,
    onSubmit,
    className,
    ariaLabel,
    inputComponent: InputComponent = "input",
    renderValue = identity,
  }) => {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
      if (!editing) {
        setValue(initialValue);
      }
    }, [initialValue, editing]);
    const renderedValue = renderValue(value);
    const empty = renderedValue === "";
    return (
      <div
        className={cn(
          className,
          "relative rounded border border-transparent transition",
          "focus-within:border-primary-border focus-within:bg-white",
          "hover:bg-grey-bg-hover-transparent hover:focus-within:bg-white",
          "group-hover/edit-inline:bg-grey-bg-hover-transparent group-hover/edit-inline:focus-within:bg-white",
        )}
        aria-label={ariaLabel}
      >
        <span
          aria-hidden={editing || undefined}
          className={cn(
            "whitespace-pre",
            editing && "invisible",
            empty && "text-grey-on",
          )}
        >
          {empty ? placeholder : renderedValue}
        </span>
        {editing && (
          <InputComponent
            autoFocus
            placeholder={placeholder}
            className={cn(
              className,
              "absolute left-0 top-0 w-full border-0 bg-transparent placeholder:text-grey-on",
            )}
            value={value}
            onChange={(event) => {
              setValue(event.currentTarget.value);
            }}
            onBlur={() => {
              if (initialValue !== value) {
                onSubmit(value);
              } else {
                onClose();
              }
            }}
            onKeyDown={(event) => {
              switch (event.key) {
                case "Escape": {
                  event.preventDefault();
                  onClose();
                  return;
                }
                case "Enter": {
                  event.preventDefault();
                  onSubmit(value);
                  return;
                }
              }
            }}
          />
        )}
      </div>
    );
  },
);
