import { useEffect, useMemo, useRef, useState } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

import { useReadOnly, useSetReadOnly } from "../ReadOnly";

export type CompareState = {
  enabled: boolean;
  featureOpened: boolean;
  count: number | null;
};

const CompareContext = createSafeContext<CompareState>();

const SetCompareContext =
  createSafeContext<React.Dispatch<React.SetStateAction<CompareState>>>();

export type CompareVersionsValuesState = {
  selectedRevision: number | null | undefined;
  setSelectedRevision: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
  pinnedRevision: number | null | undefined;
  setPinnedRevision: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
};

const CompareVersionsValuesContext =
  createSafeContext<CompareVersionsValuesState>();

const useComparedModeReadOnly = (compare: CompareState) => {
  const setReadOnly = useSetReadOnly();
  const readOnly = useReadOnly();
  const readOnlyRef = useRef<boolean>();

  useEffect(() => {
    if (compare.enabled && compare.featureOpened) {
      readOnlyRef.current = readOnly;
      setReadOnly(true);
    }
    if (readOnlyRef.current && (!compare.enabled || !compare.featureOpened)) {
      readOnlyRef.current = false;
      setReadOnly(readOnlyRef.current);
    }
    return () => {
      if (readOnlyRef.current && (!compare.enabled || !compare.featureOpened)) {
        readOnlyRef.current = false;
        setReadOnly(readOnlyRef.current);
      }
    };
  }, [compare.enabled, compare.featureOpened, readOnly, setReadOnly]);
};

export const CompareVersionsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [compare, setCompare] = useState<CompareState>({
    enabled: true,
    featureOpened: false,
    count: null,
  });
  const [selectedRevision, setSelectedRevision] = useState<number | null>();
  const [pinnedRevision, setPinnedRevision] = useState<number | null>();

  useComparedModeReadOnly(compare);

  const value = useMemo(
    () => ({
      selectedRevision,
      setSelectedRevision,
      pinnedRevision,
      setPinnedRevision,
    }),
    [selectedRevision, setSelectedRevision, pinnedRevision, setPinnedRevision],
  );

  return (
    <CompareContext.Provider value={compare}>
      <SetCompareContext.Provider value={setCompare}>
        <CompareVersionsValuesContext.Provider value={value}>
          {children}
        </CompareVersionsValuesContext.Provider>
      </SetCompareContext.Provider>
    </CompareContext.Provider>
  );
};

export const useCompare = CompareContext.makeSafeHook(
  "useCompare",
  "CompareVersionsProvider",
);

export const useSetCompare = SetCompareContext.makeSafeHook(
  "useSetCompare",
  "CompareVersionsProvider",
);

export const useCompareVersionsValuesContext =
  CompareVersionsValuesContext.makeSafeHook(
    "useCompareVersionsValuesContext",
    "CompareVersionsProvider",
  );
