export const sentence = {
  name: "sentence" as const,
  type: "text" as const,
  parseText: (text: string) => {
    if (text.match(/"/g)?.length === 2) {
      return {
        matchedText: text.substring(
          text.indexOf('"') + 1,
          text.lastIndexOf('"'),
        ),
        slicedText:
          text.slice(0, text.indexOf('"')) +
          text.slice(text.lastIndexOf('"') + 1, text.length),
      };
    }
    return {
      matchedText: "",
    };
  },
  format: (text: string) => (text.length ? `"${text}"` : ""),
  label: "Rechercher la phrase suivante",
  isDefault: false,
};
