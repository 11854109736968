import { gql } from "@apollo/client";
import { memo, useMemo, useState } from "react";
import { Anchor } from "swash/Anchor";
import { Button } from "swash/Button";
import { IoDownload } from "swash/Icon";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useToaster } from "swash/Toast";
import { Dialog } from "swash/v2/Dialog";

import { createRemoteDialog } from "@/components/RemoteDialog";
import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { Form } from "@/components/forms/Form";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";

import { BooleanField, StringField } from "../admin/CRUD";

const ArchiveDialogArchiveFragment = gql`
  fragment ArchiveDialogArchiveFragment on Archive {
    id
    title
    desktopCompatible
    mobileCompatible
    createdAt
    updatedAt
  }
`;

const ArchiveQuery = gql`
  query ArchiveQuery($id: Int!) {
    archive(id: $id) {
      ...ArchiveDialogArchiveFragment
    }
  }
  ${ArchiveDialogArchiveFragment}
`;

const UpdateArchiveMutation = gql`
  mutation ArchiveUpdateMutation($input: UpdateArchiveInput!) {
    updateArchive(input: $input) {
      ...ArchiveDialogArchiveFragment
    }
  }
  ${ArchiveDialogArchiveFragment}
`;

const DialogContent = memo(({ archiveId, store }) => {
  const { data } = useSafeQuery(ArchiveQuery, {
    variables: { id: archiveId },
  });

  if (!data) return <PageLoader />;

  return <DialogContentForm archive={data.archive} store={store} />;
});

const DialogContentForm = memo(({ archive, store }) => {
  const [updateArchive] = useSafeMutation(UpdateArchiveMutation, {
    onCompleted: () => {
      store.hide();
    },
  });
  const [initialArchive] = useState(archive);
  const toaster = useToaster();

  const initialValues = useMemo(() => {
    return {
      title: initialArchive.title,
      allCompatible:
        initialArchive.desktopCompatible && initialArchive.mobileCompatible,
      desktopCompatible: initialArchive.desktopCompatible,
      mobileCompatible: initialArchive.mobileCompatible,
    };
  }, [initialArchive]);

  const handleSubmit = async ({ allCompatible, ...values }) => {
    try {
      await updateArchive({
        variables: { input: { id: archive.id, ...values } },
        optimisticResponse: {
          __typename: "Mutation",
          updateArchive: {
            __typename: "Archive",
            ...archive,
            ...values,
          },
        },
      });
    } catch {
      toaster.danger("L’édition de l’archive a échoué");
    }
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <PanelHeader
        title={`Édition de l’archive ${archive.title}`}
        onClose={store.hide}
        actions={
          <FormSavingIndicator date={archive.updatedAt ?? archive.createdAt} />
        }
      />
      <PanelBody>
        <Fieldset className="pb-0">
          <FieldsetField className="flex-1">
            <StringField name="title" row="6" label="Titre" />
          </FieldsetField>
          <FieldsetField className="flex-1">
            <b>Sur quels supports ce zip est-il valide ?</b>
            <FieldsetField className="flex pb-0 pl-0">
              <div className="pr-4">
                <AllCompatibilityField name="allCompatible" label="Tous" />
              </div>
              <div className="pr-4">
                <BooleanField name="desktopCompatible" label="Desktop" />
              </div>
              <div>
                <BooleanField name="mobileCompatible" label="Mobile" />
              </div>
            </FieldsetField>
          </FieldsetField>
        </Fieldset>
      </PanelBody>
      <PanelFooter>
        <Button asChild variant="secondary" appearance="text">
          <Anchor href={`/api/archives/${archive.id}/download`} download>
            <IoDownload />
            Télécharger le zip
          </Anchor>
        </Button>
        <FormSubmit>Valider</FormSubmit>
      </PanelFooter>
    </Form>
  );
});

const AllCompatibilityField = memo((props) => {
  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    const desktopAndMobileCompatible =
      values.desktopCompatible && values.mobileCompatible;
    const desktopOrMobileNotCompatible =
      !values.desktopCompatible || !values.mobileCompatible;

    switch (true) {
      case !previousValues.allCompatible && desktopAndMobileCompatible:
        form.change("allCompatible", true);
        break;
      case previousValues.allCompatible && desktopOrMobileNotCompatible:
        form.change("allCompatible", false);
        break;
      case !values.allCompatible && desktopAndMobileCompatible:
        form.change("desktopCompatible", false);
        form.change("mobileCompatible", false);
        break;
      case values.allCompatible:
        form.change("desktopCompatible", true);
        form.change("mobileCompatible", true);
        break;
      default:
        break;
    }
  });

  return <BooleanField {...props} />;
});

export const ArchiveEditDialog = ({ archiveId, store }) => {
  const open = store.useState("open");
  if (!open) return null;

  return (
    <Dialog
      store={store}
      aria-label="Éditer l’archive"
      style={{
        width: "100%",
        maxWidth: "975px",
      }}
    >
      <DialogContent archiveId={archiveId} store={store} />
    </Dialog>
  );
};

export const {
  RemoteDialogProvider: RemoteEditArchiveDialogProvider,
  RemoteDialogDisclosure: RemoteEditArchiveDialogDisclosure,
  useRemoteDialogContext: useEditArchiveRemoteDialogContext,
} = createRemoteDialog(ArchiveEditDialog);
