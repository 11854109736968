import { gql, useMutation } from "@apollo/client";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { IoIosCloudDownload } from "react-icons/io";
import { Anchor } from "swash/Anchor";
import { Button } from "swash/Button";
import { Loader } from "swash/Loader";

import { FileDrop, useFileDropState } from "@/components/controls/FileDrop";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import {
  FileDropField,
  useFileDropField,
} from "@/components/fields/FileDropField";
import { ERRORS } from "@/config/messages";
import { useSafeQuery } from "@/containers/Apollo";

const MAX_FILE_SIZE = 20; // in Mega bytes

const FileFragment = gql`
  fragment FileFragment on File {
    id
    path
    name
  }
`;

const CreateFileMutation = gql`
  mutation FileFieldCreateFileMutation($input: CreateFileInput!) {
    createFile(input: $input) {
      ...FileFragment
    }
  }
  ${FileFragment}
`;

const FileQuery = gql`
  query FileQuery($id: Int!) {
    file(id: $id) {
      ...FileFragment
    }
  }
  ${FileFragment}
`;

function FileDropControl({ disabled, ...props }) {
  const [fileUrl, setFileUrl] = useState();
  const drop = useFileDropState(props);
  const form = useForm();

  const { data: { file } = {} } = useSafeQuery(FileQuery, {
    skip: !drop.state.value,
    variables: { id: drop.state.value },
  });
  const [createFile, { loading }] = useMutation(CreateFileMutation, {
    update: (cache, { data: { createFile } }) => {
      cache.writeQuery({
        query: FileQuery,
        data: {
          file: createFile,
        },
      });
    },
  });

  useEffect(() => {
    let fileUrl;
    async function downloadFile() {
      try {
        const download = await axios({
          url: "/download",
          params: {
            filePath: file.path,
          },
          responseType: "blob",
        });

        const fileBlob = new Blob([download.data]);
        fileUrl = URL.createObjectURL(fileBlob);

        setFileUrl(fileUrl);
      } catch {
        form.mutators.setFieldError({
          fieldName: drop.state.name,
          errorMessage: ERRORS.download.bucketStorage,
        });
      }
    }

    if (file) downloadFile();

    return () => URL.revokeObjectURL(fileUrl);
  }, [file, drop.state.name, form.mutators]);

  if (loading) return <Loader />;

  return (
    <FileDrop
      {...drop.state}
      onChange={(file) => {
        if (file) {
          createFile({ variables: { input: { file } } })
            .then(({ data: { createFile } }) => {
              drop.state.onChange(createFile.id);
            })
            .catch(() => {
              form.mutators.setFieldError({
                fieldName: drop.state.name,
                errorMessage: ERRORS.upload.bucketStorage,
              });
            });
        }
      }}
      accept={{
        "application/pdf": [".pdf"],
        "text/csv": [".csv"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "image/svg+xml": [".svg"],
      }}
      maxFileSize={MAX_FILE_SIZE}
      type="file"
      disabled={disabled}
      data-field-control
    >
      <div className="p-4 font-semibold">
        {drop.state.value ? (
          <>
            {file && <div className="mb-2"> {file.name} </div>}
            <div className="flex justify-center gap-3">
              <Button
                type="button"
                scale="sm"
                disabled={disabled}
                onClick={(event) => {
                  event.stopPropagation();
                  drop.state.onChange(null);
                }}
              >
                Supprimer le fichier
              </Button>
              {file && fileUrl && (
                <Button scale="sm" asChild>
                  <Anchor
                    href={fileUrl}
                    download={file.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <IoIosCloudDownload />
                    Téléchargez le fichier
                  </Anchor>
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            {drop.state.placeholder ||
              `Faire glisser un fichier ici PDF, CSV, SVG, DOC et DOCX de ${MAX_FILE_SIZE} Mo max ou cliquez pour sélectionner un fichier`}
          </>
        )}
      </div>
    </FileDrop>
  );
}

export function FileField({
  name,
  format,
  parse,
  required,
  label,
  hint,
  placeholder,
  disabled,
}) {
  const field = useFileDropField(name, { required, format, parse, label });

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FileDropField
        {...field}
        as={FileDropControl}
        placeholder={placeholder}
        disabled={disabled}
      />
    </FieldGroup>
  );
}
