import { gql } from "@apollo/client";

import {
  CaptionCellArticle,
  captionCell,
} from "@/containers/article/list/cells/caption";
import {
  DateCellArticle,
  dateCell,
} from "@/containers/article/list/cells/date";
import {
  DescriptionCellArticle,
  descriptionCell,
} from "@/containers/article/list/cells/description";
import {
  FeaturedMediaCellArticle,
  featuredMediaCell,
} from "@/containers/article/list/cells/featured-media";
import {
  StatesCellArticle,
  statesCell,
} from "@/containers/article/list/cells/states";
import {
  StatusCellArticle,
  statusCell,
} from "@/containers/article/list/cells/status";
import type { CellType } from "@/containers/list/ListState";

export type Article = {
  id: number;
} & CaptionCellArticle &
  DateCellArticle &
  DescriptionCellArticle &
  FeaturedMediaCellArticle &
  StatesCellArticle &
  StatusCellArticle;

export const ArticleFragment = gql`
  fragment ListUsedIn_article on Article {
    id
    ...captionCell_article
    ...dateCell_article
    ...descriptionCell_article
    ...featuredMediaCell_article
    ...statesCell_article
    ...statusCell_article
  }

  ${captionCell.fragment}
  ${dateCell.fragment}
  ${descriptionCell.fragment}
  ${featuredMediaCell.fragment}
  ${statesCell.fragment}
  ${statusCell.fragment}
`;

const baseCells: CellType<Article>[] = [
  statusCell,
  dateCell,
  descriptionCell,
  statesCell,
];

type Resource = {
  __typename: string;
};

export const getUsedInCells = (resource: Resource) => {
  if (resource.__typename !== "Image") return baseCells;
  return [...baseCells, captionCell, featuredMediaCell];
};
