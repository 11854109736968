import { gql, useMutation } from "@apollo/client";
import { useToaster } from "swash/Toast";
import {
  EnumSelectList,
  EnumSelectState,
  useEnumSelectState,
} from "swash/v2/EnumSelect";

import { MediaDescriptor, MediaIndicator } from "@/components/MediaIndicator";
import { NamedIcon } from "@/components/NamedIcon";
import { useSafeQuery } from "@/containers/Apollo";

const ArticleFragment = gql`
  fragment ArticleSuggestedMediasSelect_article on Article {
    suggestedMedias {
      ...MediaIndicator_suggestedMedias
    }
  }
  ${MediaIndicator.fragments.suggestedMedias}
`;

const MediaDescriptorQuery = gql`
  query ArticleSuggestedMediasSelect_mediaDescriptor {
    mediaDescriptors {
      nodes {
        ...MediaIndicator_mediaDescriptor
      }
    }
  }
  ${MediaIndicator.fragments.mediaDescriptor}
`;

const Mutation = gql`
  mutation ArticleSuggestedMediasSelect_updateArticle(
    $articleId: Int!
    $suggestedMediaIds: [Int!]!
  ) {
    updateArticle(
      input: { id: $articleId, suggestedMediaIds: $suggestedMediaIds }
    ) {
      id
      ...ArticleSuggestedMediasSelect_article
    }
  }
  ${ArticleFragment}
`;

export type MediaDescriptorQueryData = {
  mediaDescriptors: {
    nodes: MediaDescriptor[];
  };
};

export type ArticleSuggestedMedia = {
  media: MediaDescriptor;
  suggested: boolean;
  included: boolean;
};

export type ArticleSuggestedMediasSelectStateProps = {
  article: {
    id: number;
    suggestedMedias: ArticleSuggestedMedia[];
  };
};

const getSuggestedMedia = (
  suggestedMedias: ArticleSuggestedMedia[],
  media: MediaDescriptor,
) => {
  return suggestedMedias.find(
    (suggestedMedia) => suggestedMedia.media.id === media.id,
  );
};

export const useArticleSuggestedMediasSelectState = (
  props: ArticleSuggestedMediasSelectStateProps,
): EnumSelectState<MediaDescriptor> => {
  const suggestedMediaIds = props.article.suggestedMedias.map(
    ({ media }) => media.id,
  );
  const { data } = useSafeQuery<MediaDescriptorQueryData, never>(
    MediaDescriptorQuery,
  );
  const medias = data?.mediaDescriptors.nodes ?? [];

  const [updateArticleSuggestedMedias] = useMutation(Mutation);
  const toaster = useToaster();
  const enumSelect = useEnumSelectState({
    value: medias.filter(({ id }) => suggestedMediaIds.includes(id)),
    onChange: (value) => {
      updateArticleSuggestedMedias({
        variables: {
          articleId: props.article.id,
          suggestedMediaIds: value.map((media) => media.id),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: props.article.id,
            suggestedMedias: value.map((media) => {
              const suggestedMedia = getSuggestedMedia(
                props.article.suggestedMedias,
                media,
              );
              return {
                __typename: "ArticleSuggestedMedia",
                media,
                suggested: true,
                included: suggestedMedia?.included ?? false,
              };
            }),
          },
        },
      }).catch(() => {
        toaster.danger("La mise à jour du média a échoué");
      });
    },
    items: medias,
    iconSelector: (media) => {
      return <NamedIcon name={media.icon} />;
    },
    labelSelector: (media) => media.label,
    valueSelector: (media) => media.id.toString(),
    disabledSelector: (media) => {
      const suggestedMedia = getSuggestedMedia(
        props.article.suggestedMedias,
        media,
      );
      return suggestedMedia?.included ?? false;
    },
    required: false,
  });
  return enumSelect;
};

useArticleSuggestedMediasSelectState.fragments = {
  article: ArticleFragment,
};

export type ArticleSuggestedMediasSelectListProps = {
  state: EnumSelectState<MediaDescriptor>;
};

export const ArticleSuggestedMediasSelectList = (
  props: ArticleSuggestedMediasSelectListProps,
) => {
  return <EnumSelectList state={props.state} />;
};
