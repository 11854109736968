import { forwardRef, memo, useEffect } from "react";
import { Loader } from "swash/Loader";
import { cn } from "swash/utils/classNames";
import { useLiveRef } from "swash/utils/useLiveRef";
import useIsInViewport from "use-is-in-viewport";

type InfiniteScrollOptions = {
  hasMore?: boolean;
  loading?: boolean;
  loadMore: () => void;
};

export function useInfiniteScrollState({
  hasMore,
  loading,
  loadMore,
}: InfiniteScrollOptions) {
  const [endMarkerInViewport, targetRef] = useIsInViewport();

  const loadMoreRef = useLiveRef(loadMore);
  useEffect(() => {
    if (hasMore && endMarkerInViewport && !loading) {
      loadMoreRef.current();
    }
  }, [endMarkerInViewport, hasMore, loading, loadMoreRef]);

  return {
    state: {
      targetRef,
      endMarkerInViewport,
      hasMore,
      loadMore,
      loading,
    },
  };
}

type InfiniteScrollState = ReturnType<typeof useInfiniteScrollState>;

type InfiniteScrollMarkerProps = React.HTMLAttributes<HTMLDivElement> &
  InfiniteScrollState;

export const InfiniteScrollMarker: React.FC<InfiniteScrollMarkerProps> = ({
  state: { targetRef },
  children,
  ...props
}) => {
  return <div ref={targetRef} {...props} className="pb-px" />;
};

type InfiniteScrollLoaderProps = InfiniteScrollState;

export const InfiniteScrollLoader = forwardRef<
  SVGSVGElement,
  InfiniteScrollLoaderProps
>(({ state: { loading } }, ref) => {
  if (!loading) return null;
  return (
    <div className="flex items-center justify-center p-4">
      <Loader ref={ref} size={16} />
    </div>
  );
});
if (process.env["NODE_ENV"] !== "production") {
  InfiniteScrollLoader.displayName = "InfiniteScrollLoader";
}

type InfiniteScrollEndProps = React.HTMLAttributes<HTMLDivElement> &
  InfiniteScrollState;

export const InfiniteScrollEnd = forwardRef<
  HTMLDivElement,
  InfiniteScrollEndProps
>(({ state: { hasMore, loading }, ...props }, ref) => {
  return !hasMore && !loading ? (
    <div
      ref={ref}
      {...props}
      className={cn("mb-6 text-center font-accent text-xs", props.className)}
    >
      Fin des résultats
    </div>
  ) : null;
});
if (process.env["NODE_ENV"] !== "production") {
  InfiniteScrollEnd.displayName = "InfiniteScrollEnd";
}

type SimpleInfiniteScrollProps = InfiniteScrollOptions & {
  marker?: boolean;
  loader?: boolean;
};

export const SimpleInfiniteScroll = memo<SimpleInfiniteScrollProps>(
  ({ hasMore, loadMore, loading, marker = true, loader = true }) => {
    const infiniteScroll = useInfiniteScrollState({
      hasMore,
      loadMore,
      loading,
    });

    return (
      <>
        {marker && <InfiniteScrollMarker {...infiniteScroll} />}
        {loader && <InfiniteScrollLoader {...infiniteScroll} />}
        <InfiniteScrollEnd {...infiniteScroll} />
      </>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  SimpleInfiniteScroll.displayName = "SimpleInfiniteScroll";
}
