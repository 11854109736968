import { gql } from "@apollo/client";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Button } from "swash/Button";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useToaster } from "swash/Toast";
import { cn } from "swash/utils/classNames";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import { Dialog, useDialogStore } from "swash/v2/Dialog";

import { Form } from "@/components/forms/Form";
import { useAmplitude } from "@/containers/Amplitude";
import { appendNode, prependNode, useSafeMutation } from "@/containers/Apollo";
import { Comment } from "@/containers/Comment";
import {
  CommentFormContent,
  formatMentions,
} from "@/containers/admin/CRUD/history/Comment";
import { useArticleCommentsQueryOptions } from "@/containers/article/ArticleCommentsContext";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";
import { useStorage } from "@/services/hooks/useStorage";

const BaseArticleCommentFragment = gql`
  fragment ArticleCommentForm_comment on Comment {
    id
    date
    ...Comment_comment
    mine
    user {
      id
      firstNameInitials
      lastName
      ...UserHoverCardTooltip_user
    }
  }
  ${UserHoverCardTooltip.fragments.user}
  ${Comment.fragments.comment}
`;

const ArticleResponseFormFragment = gql`
  fragment ArticleCommentForm_commentResponse on CommentResponse {
    parentId
    ...ArticleCommentForm_comment
  }
  ${BaseArticleCommentFragment}
`;

export const ArticleCommentFormFragment = gql`
  fragment ArticleCommentForm_commentThread on CommentThread {
    id
    ...ArticleCommentForm_comment
    responses {
      nodes {
        id
        ...ArticleCommentForm_commentResponse
      }
    }
  }
  ${BaseArticleCommentFragment}
  ${ArticleResponseFormFragment}
`;

const CreateArticleCommentMutation = gql`
  mutation ArticleCommentForm_createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      ... on CommentThread {
        ...ArticleCommentForm_commentThread
      }
      ... on CommentResponse {
        ...ArticleCommentForm_commentResponse
      }
    }
  }
  ${ArticleCommentFormFragment}
  ${ArticleResponseFormFragment}
`;

const wordings = {
  notes: {
    create: "Ajouter une consigne",
    update: "Modifier une consigne",
    thread: {
      title: "Supprimer la consigne",
      text: "La consigne et toutes ses réponses seront supprimées.",
    },
    response: {
      title: "Supprimer la réponse à la consigne",
      text: "Seule la réponse à la consigne sera supprimée.",
    },
  },

  text: {
    create: "Ajouter un commentaire",
    update: "Modifier un commentaire",
    thread: {
      title: "Supprimer un commentaire",
      text: "Le commentaire et toutes ses réponses seront supprimées.",
    },
    response: {
      title: "Supprimer la réponse au commentaire",
      text: "Seule la réponse au commentaire sera supprimée.",
    },
  },
};

const handleCreateNoteResponse = (notes, createComment) => {
  return notes.reduce((acc, note) => {
    if (createComment.parentId === note.id) {
      acc.push({
        ...note,
        responses: {
          ...note.responses,
          nodes: appendNode(note.responses.nodes, createComment),
        },
      });
      return acc;
    }
    acc.push(note);
    return acc;
  }, []);
};

const updateCache = (
  cache,
  { data: incomingData },
  { queryOptions, action, scope, anchorText },
) => {
  const data = cache.readQuery(queryOptions);
  if (!data) return;
  const key = (() => {
    switch (scope) {
      case "notes":
        return "notes";
      case "text":
        return "comments";
      default:
        // eslint-disable-next-line no-console
        console.error("Unknown scope", scope);
        return null;
    }
  })();
  if (!key) return;
  const comments = data.article?.[key].nodes ?? [];

  const nodes = (() => {
    switch (action) {
      case "delete": {
        const deleteComment = incomingData.deleteComment;
        return deleteComment.parentId
          ? handleDeleteResponse(comments, deleteComment)
          : comments.filter((note) => note.id !== deleteComment.id);
      }
      case "create": {
        const createComment = {
          ...incomingData.createComment,
          // prevent missing fields while writing result to cache
          anchorText: anchorText ?? null,
          scope,
          resolvedAt: null,
          resolved: false,
          resolvedBy: null,
        };
        return createComment.parentId
          ? handleCreateNoteResponse(comments, createComment)
          : prependNode(comments, createComment);
      }
      default:
        // eslint-disable-next-line no-console
        console.error("Invalid action", action);
        return [];
    }
  })();

  cache.writeQuery({
    ...queryOptions,
    data: {
      article: {
        ...data.article,
        [key]: {
          ...data.article[key],
          nodes,
        },
      },
    },
  });
};

export const CreateArticleCommentForm = forwardRef(
  (
    {
      article,
      confirmMessage,
      parentId,
      placeholder,
      onCommentCreated,
      storedIdentifier,
      anchorText,
      className,
      autoFocus,
      showFormButtons,
      onEscape,
    },
    ref,
  ) => {
    const { logEvent } = useAmplitude();
    const toaster = useToaster();
    const scope = useCommentScope();
    const storageIdentifier = parentId
      ? `article-comment-content-${storedIdentifier}-${parentId}`
      : `article-comment-content-${storedIdentifier}`;
    const [storedComment, setStoredComment] = useStorage(
      storageIdentifier,
      null,
    );
    const queryOptions = useArticleCommentsQueryOptions();
    const queryOptionsRef = useRef(queryOptions);
    const extendedQueryOptions = useMemo(
      () => ({
        ...queryOptionsRef.current,
        variables: { ...queryOptionsRef.current.variables, resolved: false },
      }),
      [],
    );
    const [createComment, { loading }] = useSafeMutation(
      CreateArticleCommentMutation,
      {
        update: (cache, result) => {
          updateCache(cache, result, {
            queryOptions: extendedQueryOptions,
            action: "create",
            scope,
            anchorText,
          });
        },
        onCompleted: ({ createComment }) => {
          logEvent("comment:publish", {
            resource: "article",
            scope,
            firstPublish: true,
            mentions: createComment.mentions.length,
          });
          if (onCommentCreated) onCommentCreated(createComment);
        },
        onError: () => {
          toaster.danger("La création du commentaire a échoué");
        },
      },
    );

    const refs = useLiveRef({
      parentId,
      storedComment,
      scope,
      articleId: article.id,
      createComment,
      onCommentCreated,
    });

    const initialValues = useMemo(() => {
      return {
        comment: refs.current.storedComment ?? "",
      };
    }, [refs]);

    const label = wordings[scope]?.create ?? "Ajouter un commentaire";

    const handleSubmit = useCallback(
      (values, form) => {
        if (values.comment) {
          const { articleId, scope, createComment, parentId } = refs.current;
          createComment({
            variables: {
              input: {
                value: values.comment.value,
                mentions: formatMentions(values.comment.mentions),
                resource: "article",
                resourceId: articleId,
                scope,
                parentId,
              },
            },
          });
          form.reset({ comment: "" });
        }
      },
      [refs],
    );

    const handleDiscard = useCallback(
      (form) => {
        form.reset({ comment: "" });
        setTimeout(() => {
          if (onEscape) onEscape();
        });
      },
      [onEscape],
    );

    if (!article) return null;

    return (
      <div className={cn("-m flex flex-col px-4 pb-4", className)}>
        <Form
          aria-label={label}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <CommentFormContent
            ref={ref}
            parentId={parentId}
            disabled={loading}
            onCommentChange={setStoredComment}
            onDiscard={handleDiscard}
            placeholder={placeholder}
            confirmMessage={confirmMessage}
            autoFocus={autoFocus}
            scale="xs"
            showFormButtons={showFormButtons}
          />
        </Form>
      </div>
    );
  },
);

const UpdateArticleCommentMutation = gql`
  mutation ArticleCommentForm_updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      ... on CommentThread {
        ...ArticleCommentForm_commentThread
      }
      ... on CommentResponse {
        ...ArticleCommentForm_commentResponse
      }
    }
  }
  ${ArticleCommentFormFragment}
  ${ArticleResponseFormFragment}
`;

export const UpdateArticleCommentForm = ({ comment, onSubmit, onDiscard }) => {
  const { logEvent } = useAmplitude();
  const [updateComment, { loading }] = useSafeMutation(
    UpdateArticleCommentMutation,
  );

  const commentRef = useLiveRef(comment);

  const scope = useCommentScope();

  const initialValues = useMemo(
    () => ({
      comment: {
        value: commentRef.current.value,
        mentions: commentRef.current.mentions,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const label = wordings[scope]?.update ?? "Modifier un commentaire";

  return (
    <Form
      aria-label={label}
      initialValues={initialValues}
      onSubmit={async (values) => {
        logEvent("comment:publish", {
          resource: "article",
          scope: comment.scope,
          firstPublish: false,
          mentions: values.comment.mentions.length,
        });
        const formattedMentions = formatMentions(values.comment.mentions);
        await updateComment({
          variables: {
            input: {
              id: comment.id,
              value: values.comment.value,
              mentions: formattedMentions,
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateComment: {
              __typename: "BaseComment",
              ...comment,
              value: values.comment.value,
              mentions: formattedMentions,
            },
          },
        });
        onSubmit();
      }}
    >
      <CommentFormContent
        context="update"
        disabled={loading}
        onDiscard={onDiscard}
        autoFocus
        placeholder={`${label}...`}
        scale="xs"
      />
    </Form>
  );
};

const DeleteArticleCommentMutation = gql`
  mutation ArticleCommentForm_deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
      ... on CommentResponse {
        parentId
      }
    }
  }
`;

const handleDeleteResponse = (notes, response) => {
  return notes.reduce((acc, note) => {
    acc.push({
      ...note,
      responses: {
        ...note.responses,
        nodes: note.responses.nodes.filter((res) => res.id !== response.id),
      },
    });
    return acc;
  }, []);
};

export const ArticleCommentDeleteDialog = memo(({ comment, onClose }) => {
  const scope = useCommentScope();
  const dialog = useDialogStore();
  const refs = useLiveRef({ onClose, dialog });
  const visible = dialog.useState("open");
  const previousVisible = usePrevious(visible);
  const type = comment?.parentId ? "response" : "thread";
  const { title, text } = wordings[scope][type];
  const queryOptions = useArticleCommentsQueryOptions();

  const [deleteComment, { loading }] = useSafeMutation(
    DeleteArticleCommentMutation,
  );

  useEffect(() => {
    if (previousVisible !== undefined && previousVisible && !visible) {
      refs.current.onClose();
    }
  }, [previousVisible, visible, refs]);

  useEffect(() => {
    if (comment) {
      refs.current.dialog.show();
    } else {
      refs.current.dialog.hide();
    }
  }, [comment, refs]);

  const handleConfirmClick = () => {
    deleteComment({
      variables: { input: { id: comment.id } },
      optimisticResponse: {
        __typename: "Mutation",
        deleteComment: comment,
      },
      update: (cache, result) => {
        updateCache(cache, result, {
          queryOptions,
          action: "delete",
          scope,
        });
      },
    }).then(() => {
      dialog.hide();
    });
  };

  return (
    <Dialog store={dialog}>
      <PanelHeader title={title} onClose={dialog.hide} />
      <PanelBody>{text}</PanelBody>
      <PanelFooter>
        <Button
          type="button"
          variant="secondary"
          appearance="text"
          disabled={loading}
          onClick={onClose}
        >
          Annuler
        </Button>
        <Button
          type="button"
          variant="danger"
          disabled={loading}
          onClick={handleConfirmClick}
        >
          Supprimer
        </Button>
      </PanelFooter>
    </Dialog>
  );
});
