import { useMemo } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

export type Node = Record<string, any>;
type Selector<T> = (node: T) => string;
export interface NodesToEnumOptions<T extends Node> {
  valueSelector?: string | Selector<T>;
  labelSelector?: string | Selector<T>;
}

export function nodesToEnum<T extends Node>(
  nodes: T[],
  { valueSelector = "id", labelSelector = "label" }: NodesToEnumOptions<T> = {},
) {
  return nodes.reduce((obj, node) => {
    obj[resolveProperty(valueSelector, node)] = resolveProperty(
      labelSelector,
      node,
    );
    return obj;
  }, {} as Node);
}

export function nodesToEnumArray<T extends Node>(
  nodes: T[],
  { valueSelector = "id", labelSelector = "label" }: NodesToEnumOptions<T> = {},
) {
  return nodes.map((node) => ({
    ...node,
    value: resolveProperty(valueSelector, node),
    label: resolveProperty(labelSelector, node),
  }));
}

export function useNodesToEnumArray<T extends Node>(
  nodes: T[],
  options?: NodesToEnumOptions<T>,
) {
  const optionsRef = useLiveRef(options);
  return useMemo(
    () => nodesToEnumArray(nodes, optionsRef.current),
    [optionsRef, nodes],
  );
}

export function useNodesToEnum<T extends Node>(
  nodes: T[],
  options: NodesToEnumOptions<T> | undefined = undefined,
) {
  const optionsRef = useLiveRef(options);
  return useMemo(
    () => nodesToEnum(nodes, optionsRef.current),
    [optionsRef, nodes],
  );
}

export const resolveProperty = <T extends Node>(
  selector: string | Selector<T>,
  node: T | null,
) => {
  if (node == null) return null;
  switch (typeof selector) {
    case "string":
      return node[selector];
    case "function":
      return selector(node);
    default: {
      if (process.env["NODE_ENV"] !== "production") {
        throw new Error(`Unsupported selector ${selector}`);
      }
      return null;
    }
  }
};
