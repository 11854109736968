import { UsedInList } from "./UsedInList";

export const name = "Types de contenu";

const columns = [
  {
    id: "label",
    Header() {
      return "Libellé";
    },
    Value({ node, EditLink }) {
      return (
        <EditLink slug={`contents/${node.customType.id}`}>
          {node.label}
        </EditLink>
      );
    },
    width: 0.4,
  },
  {
    id: "type",
    Header() {
      return "Type";
    },
    Value({ node }) {
      return node.customType.label;
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
