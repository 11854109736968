/* eslint-env node */
const palette = {
  transparent: "transparent",
  white: "#ffffff",
  "off-white": "#FCFEFF",
  dusk: {
    50: "#EBEDF2",
    100: "#BCC4D3",
    200: "#92A1B9",
    300: "#70839F",
    400: "#546984",
    500: "#3E526A",
    600: "#2B3C50",
    700: "#1F2C3A",
    800: "#141C25",
    900: "#090B0F",
  },
  grey: {
    50: "#F9FAFB",
    100: "#E3E7EC",
    200: "#CAD2DA",
    300: "#B3BDC7",
    400: "#9AA3AD",
    500: "#828A92",
    600: "#6B7178",
    700: "#53585E",
    800: "#3C4043",
    900: "#252729",
  },
  blue: {
    50: "#EDF7FA",
    100: "#B8E1EE",
    200: "#87CDE1",
    300: "#5EBCD5",
    400: "#4BA2B9",
    500: "#3D899C",
    600: "#327080",
    700: "#285864",
    800: "#1E3F48",
    900: "#13262B",
  },
  green: {
    50: "#EDFAF5",
    100: "#ABE9D2",
    200: "#71D8B3",
    300: "#42C798",
    400: "#32AD81",
    500: "#27926C",
    600: "#1F7858",
    700: "#195E45",
    800: "#134332",
    900: "#0D291F",
  },
  red: {
    50: "#FAEDED",
    100: "#FFD1D1",
    200: "#FF9496",
    300: "#FF6B6E",
    400: "#DB4A4C",
    500: "#B73032",
    600: "#931F21",
    700: "#6F1415",
    800: "#4A0C0D",
    900: "#260606",
  },
  orange: {
    50: "#FDF6EC",
    100: "#FAE6CB",
    200: "#F5CA93",
    300: "#F0AF60",
    400: "#ED9B42",
    500: "#E68D35",
    600: "#CE7C2E",
    700: "#A86826",
    800: "#895620",
    900: "#603E19",
  },
  purple: {
    50: "#EEF1FF",
    100: "#E1E6FE",
    200: "#C8D1FD",
    300: "#A7B2FA",
    400: "#8489F5",
    500: "#5855EC",
    600: "#5849E2",
    700: "#4C3BC7",
    800: "#3D33A0",
    900: "#211C4A",
  },
};

const getColorTokens = (shades) => {
  return {
    on: {
      light: shades[500],
      DEFAULT: shades[600],
      strong: shades[900],
      hover: {
        light: shades[600],
        DEFAULT: shades[700],
        strong: shades[900],
      },
    },
    bg: {
      light: shades[50],
      DEFAULT: shades[100],
      strong: shades[400],
      stronger: shades[800],
      hover: {
        transparent: `${shades[400]}1A`,
        light: shades[100],
        DEFAULT: shades[200],
        strong: shades[500],
        stronger: shades[900],
      },
    },
    border: {
      light: shades[100],
      DEFAULT: shades[300],
      strong: shades[600],
      hover: {
        light: shades[200],
        DEFAULT: shades[400],
        strong: shades[700],
      },
    },
  };
};

const baseColors = {
  ...palette,
  dusk: {
    ...palette.dusk,
    ...getColorTokens(palette.dusk),
  },
  grey: {
    ...palette.grey,
    ...getColorTokens(palette.grey),
  },
  blue: {
    ...palette.blue,
    ...getColorTokens(palette.blue),
  },
  green: {
    ...palette.green,
    ...getColorTokens(palette.green),
  },
  red: {
    ...palette.red,
    ...getColorTokens(palette.red),
  },
  orange: {
    ...palette.orange,
    ...getColorTokens(palette.orange),
  },
  purple: {
    ...palette.purple,
    ...getColorTokens(palette.purple),
  },
};

const colors = {
  ...baseColors,
  primary: baseColors.blue,
  secondary: baseColors.grey,
  success: baseColors.green,
  danger: baseColors.red,
  warning: baseColors.orange,
  info: baseColors.blue,
  purple: baseColors.purple,
};

exports.colors = colors;
exports.palette = palette;
