/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql, useMutation } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useForm } from "react-final-form";
import { Clickable } from "swash/Clickable";
import {
  Popover,
  PopoverDisclosure,
  PopoverStore,
  usePopoverStore,
} from "swash/Popover";
import { useToaster } from "swash/Toast";

import { ArticleExposureDateTimeFields } from "@/components/fields/ExposurePlannedDateField";
import { Form } from "@/components/forms/Form";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";

import {
  ExposureIndicator,
  ExposureIndicatorProps,
} from "../ExposureIndicator";
import { formatDate } from "./ArticlePublishDate";
import { HourRange } from "./ArticlePublishTime";
import { Range, useResolvePublicationSlots } from "./util/publicationSlots";

type ArticleExposure = {
  exposure: {
    plannableMode: "slot" | "slot_and_hour";
  } & ExposureIndicatorProps["exposure"];
} & ExposureIndicatorProps["articleExposure"];

export type ArticleExposureAlertEditorProps = {
  article: {
    id: number;
    alertSchedulerDate: string;
    articleExposures: {
      nodes: ArticleExposure[];
    };
  };
  className?: string;
};

type ArticleAlertEditPopoverProps = {
  store: PopoverStore;
  article: ArticleExposureAlertEditorProps["article"];
  articleExposure: ArticleExposure;
};

const Mutation = gql`
  mutation UpdateArticleAlertExposure($input: updateArticleExposureInput!) {
    updateArticleExposure(input: $input) {
      gid
      ...ExposureIndicator_articleExposure
    }
  }
  ${ExposureIndicator.fragments.articleExposure}
`;

const ArticleAlertEditPopover = (props: ArticleAlertEditPopoverProps) => {
  const form = useForm();
  const { startDate, endDate } = useResolvePublicationSlots({
    plannedDate: props.articleExposure.planning.date,
    range: props.articleExposure.planning.range,
  });

  const toaster = useToaster();
  const [updateArticle] = useMutation(Mutation, {
    onError: () => {
      toaster.danger(
        "La date de publication de l’article n’a pas été mise à jour",
      );
    },
  });

  const handleClose = useCallback(() => {
    updateArticle({
      variables: {
        input: {
          gid: props.articleExposure.gid,
          plannedStartDate: startDate,
          plannedEndDate: startDate === endDate ? null : endDate,
        },
      },
    });
  }, [endDate, props.articleExposure.gid, startDate, updateArticle]);

  return (
    <Popover store={props.store} modal onClose={handleClose}>
      <ArticleExposureDateTimeFields
        name="articleExposure.planning"
        onChange={(value: { date: string; range: Range }) => {
          form.change("articleExposure.planning", value);
        }}
        correlatedFieldName="correlated"
        value={props.articleExposure.planning}
        hasSlotOption={["slot", "slot_and_hour"].includes(
          props.articleExposure.exposure.plannableMode,
        )}
        hasTimeOption={true}
      />
    </Popover>
  );
};

const ArticleAlertSchedulerTime = (props: {
  planning: ArticleExposure["planning"];
  fulfilledAt: string;
}) => {
  if (
    props.fulfilledAt ||
    (props.planning.date &&
      (!props.planning.range || props.planning.range === "time"))
  ) {
    return (
      <div>
        {moment(props.fulfilledAt || props.planning.date)
          .tz("Europe/Paris")
          .format("HH:mm")}
      </div>
    );
  }
  return <HourRange date={props.planning.date} />;
};

const ArticleAlertSchedulerDate = (props: {
  planning: ArticleExposure["planning"];
  fulfilledAt: string;
}) => {
  if (!props.fulfilledAt && !props.planning.date) {
    return <>Non daté</>;
  }
  return (
    <div className="block select-none text-start font-accent text-sm">
      <div>{formatDate(props.fulfilledAt || props.planning.date)}</div>
      <ArticleAlertSchedulerTime
        planning={props.planning}
        fulfilledAt={props.fulfilledAt}
      />
    </div>
  );
};

export const ArticleAlertDisplay = (props: {
  article: ArticleExposureAlertEditorProps["article"];
  articleExposure: ArticleExposure;
  className?: string;
}) => {
  const { article, articleExposure, className } = props;

  return (
    <div
      className={clsx(
        className,
        "flex h-full select-none items-start gap-2 p-1 text-sm",
      )}
    >
      <div className="mt-1.5">
        <ExposureIndicator
          article={article}
          exposure={articleExposure.exposure}
          articleExposure={articleExposure}
        />
      </div>
      <ArticleAlertSchedulerDate
        planning={articleExposure.planning}
        fulfilledAt={articleExposure.fulfilledAt}
      />
    </div>
  );
};

export const ArticleExposureAlertEditor = (props: {
  article: ArticleExposureAlertEditorProps["article"];
  articleExposure: ArticleExposure;
  className?: string;
}) => {
  const popover = usePopoverStore({ placement: "bottom-start" });

  const planning = useSubscribeFormValue("articleExposure.planning");

  const updatedAlertArticleExposure = useMemo(
    () => ({
      ...props.articleExposure,
      planning,
    }),
    [planning, props.articleExposure],
  );

  return (
    <span onClick={(event) => event.stopPropagation()}>
      <PopoverDisclosure
        store={popover}
        render={
          <Clickable className="h-full">
            <ArticleAlertDisplay
              article={props.article}
              articleExposure={updatedAlertArticleExposure}
              className={props.className}
            />
          </Clickable>
        }
      />
      <ArticleAlertEditPopover
        store={popover}
        article={props.article}
        articleExposure={updatedAlertArticleExposure}
      />
    </span>
  );
};

ArticleExposureAlertEditor.fragments = {
  article: gql`
    fragment ArticleExposureAlertEditor_article on Article {
      id
      alertSchedulerDate
      ...ExposureIndicator_article
      articleExposures {
        nodes {
          ...ExposureIndicator_articleExposure
          exposure {
            ...ExposureIndicator_exposure
            plannableMode
          }
        }
      }
    }
    ${ExposureIndicator.fragments.articleExposure}
    ${ExposureIndicator.fragments.exposure}
    ${ExposureIndicator.fragments.article}
  `,
};

export const ArticleAlertSelector = (
  props: ArticleExposureAlertEditorProps,
) => {
  const { article } = props;
  const alertArticleExposure = useMemo(() => {
    return article.articleExposures.nodes.find(
      (node) => node.exposure.type === "alert",
    );
  }, [article.articleExposures.nodes]);

  const initialValues = useMemo(() => {
    if (!alertArticleExposure)
      return {
        articleExposure: {
          planning: {
            date: moment().format("YYYY-MM-DD"),
            range: "time",
          },
        },
        correlated: false,
      };
    return {
      articleExposure: {
        planning: {
          date: alertArticleExposure.planning.date,
          range: alertArticleExposure.planning.range || "time",
        },
      },
      correlated: false,
    };
  }, [alertArticleExposure]);

  if (!alertArticleExposure) {
    return null;
  }

  if (alertArticleExposure.fulfilled) {
    return (
      <ArticleAlertDisplay articleExposure={alertArticleExposure} {...props} />
    );
  }

  return (
    <Form initialValues={initialValues} onSubmit={() => {}}>
      <ArticleExposureAlertEditor
        articleExposure={alertArticleExposure}
        {...props}
      />
    </Form>
  );
};
