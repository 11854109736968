import { gql } from "@apollo/client";

import { ArticleEditorialFormatEdit } from "@/containers/article/ArticleEditorialFormatEdit";
import { ArticlePublishDateTimeStatus } from "@/containers/article/ArticlePublishDateTimeStatus";
import { ArticleTitleLink } from "@/containers/article/ArticleTitleLink";
import { useListReadOnly } from "@/containers/list/List";
import type { ArticleDescription_articleFragment } from "@/gql-types";

import { ArticleConfidentialLabel } from "./ArticleConfidentialLabel";
import { ArticleDateEditor } from "./ArticleDateEditor";
import { ArticleDescriptionEditorialLevel } from "./ArticleDescriptionEditorialLevel";
import {
  ArticleDescriptionNarrowSection,
  ArticleDescriptionNarrowSectionProps,
} from "./ArticleDescriptionNarrowSection";
import { ArticleDescriptionSigns } from "./ArticleDescriptionSigns";
import { ArticleEditorialTypeLabel } from "./ArticleEditorialTypeLabel";
import { ArticleEvergreenAlert } from "./ArticleEvergreenAlert";
import { ArticleIdLabel } from "./ArticleIdLabel";
import { ArticleLayoutPricingIndicator } from "./ArticleLayoutPricingIndicator";
import { ArticleLiveLink } from "./ArticleLiveLink";
import { ArticleNotesButton } from "./ArticleNotesButton";
import { ArticlePredicates } from "./ArticlePredicates";
import { ArticlePublishedLink } from "./ArticlePublishedLink";
import { ArticleReferenceLabel } from "./ArticleReferenceLabel";
import { ArticleStatsButton } from "./ArticleStatsButton";

export type ArticleDescriptionProps = {
  article: ArticleDescription_articleFragment;
  onNarrowSectionClick?: ArticleDescriptionNarrowSectionProps["onClick"];
  /** @default false */
  showSignature?: boolean;
  /** @default true */
  showEditorialLevel?: boolean;
  /** @default false */
  showPublicationDate?: boolean;
  /** @default false */
  showArticleDateIndicator?: boolean;
};

export const ArticleDescription = ({
  article,
  onNarrowSectionClick,
  showSignature = false,
  showEditorialLevel = true,
  showPublicationDate = false,
  showArticleDateIndicator = false,
}: ArticleDescriptionProps) => {
  const readOnly = useListReadOnly();
  return (
    <div className="flex gap-4">
      <div className="mt-0.5 flex flex-col items-center gap-1">
        <ArticleLayoutPricingIndicator className="text-lg" article={article} />
        {showEditorialLevel && (
          <ArticleDescriptionEditorialLevel
            article={article}
            disabled={readOnly}
          />
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-base leading-5 [&>*:first-child]:before:hidden [&>*]:before:px-1.5 [&>*]:before:text-grey-border-light [&>*]:before:content-['|']">
          <ArticleTitleLink article={article} />
          {article.narrowSection && (
            <span>
              <ArticleDescriptionNarrowSection
                article={article}
                onClick={onNarrowSectionClick}
              />
            </span>
          )}
        </div>
        {showSignature && article.signature && (
          <div className="text-sm text-grey-on">Par {article.signature}</div>
        )}
        <div className="flex flex-wrap items-center gap-x-4">
          <div className="flex flex-wrap items-center font-accent text-xs leading-5 text-grey-on [&>*:first-child]:before:hidden [&>*]:before:px-1.5 [&>*]:before:text-grey-border-light [&>*]:before:content-['|']">
            {showPublicationDate && (
              <ArticlePublishDateTimeStatus article={article} />
            )}
            {showArticleDateIndicator && (
              <ArticleDateEditor detailed article={article} />
            )}
            <ArticleConfidentialLabel article={article} />
            <ArticleReferenceLabel article={article} />
            <ArticleEditorialFormatEdit article={article} disabled={readOnly} />
            <ArticleEditorialTypeLabel article={article} />
            <span className="flex items-center">
              <ArticleIdLabel article={article} />
            </span>
            <span className="flex items-center">
              <ArticleDescriptionSigns article={article} disabled={readOnly} />
            </span>
          </div>
          <div className="-mx-2 flex flex-wrap items-center">
            <ArticleNotesButton
              article={article}
              scale="xs"
              alwaysVisible={false}
              className="mx-1"
            />
            <ArticleStatsButton article={article} scale="xs" className="mx-1" />
            <ArticlePublishedLink article={article} scale="xs" />
            <ArticleLiveLink article={article} scale="xs" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <ArticleEvergreenAlert article={article} />
          <ArticlePredicates article={article} />
        </div>
      </div>
    </div>
  );
};

ArticleDescription.fragments = {
  article: gql`
    fragment ArticleDescription_article on Article {
      id
      narrowSection {
        id
      }
      signature
      hasNotes
      ...ArticleLayoutPricingIndicator_article
      ...ArticleDescriptionEditorialLevel_article
      ...ArticleTitleLink_article
      ...ArticleConfidentialLabel_article
      ...ArticleEditorialTypeLabel_article
      ...ArticleReferenceLabel_article
      ...ArticleIdLabel_article
      ...ArticleDescriptionSigns_article
      ...ArticlePublishedLink_article
      ...ArticleLiveLink_article
      ...ArticleEvergreenAlert_article
      ...ArticlePredicates_article
      ...ArticleDescriptionNarrowSection_article
      ...ArticleStatsButton_article
      ...ArticleEditorialFormatSelect_article
      ...ArticlePublishDateTimeStatus_article
      ...ArticleDateEditor_article
    }

    ${ArticleLayoutPricingIndicator.fragments.article}
    ${ArticleDescriptionEditorialLevel.fragments.article}
    ${ArticleTitleLink.fragments.article}
    ${ArticleConfidentialLabel.fragments.article}
    ${ArticleEditorialTypeLabel.fragments.article}
    ${ArticleReferenceLabel.fragments.article}
    ${ArticleIdLabel.fragments.article}
    ${ArticleDescriptionSigns.fragments.article}
    ${ArticlePublishedLink.fragments.article}
    ${ArticleLiveLink.fragments.article}
    ${ArticleEvergreenAlert.fragments.article}
    ${ArticlePredicates.fragments.article}
    ${ArticleDescriptionNarrowSection.fragments.article}
    ${ArticleEditorialFormatEdit.fragments.article}
    ${ArticleStatsButton.fragments.article}
    ${ArticlePublishDateTimeStatus.fragments.article}
    ${ArticleDateEditor.fragments.article}
  `,
};
