/* eslint-disable react/prop-types */
import { FieldValidator } from "final-form";
import { forwardRef } from "react";
import { UseFieldConfig, useField, useForm } from "react-final-form";
import { Textbox } from "swash/controls/Textbox";
import { Component } from "swash/types";

import {
  FieldControl,
  FieldControlOptions,
  FieldControlProps,
} from "@/components/fields/FieldControl";
import {
  composeValidators,
  mustBeEmail,
  mustBeFilled,
  mustBePhoneNumber,
  mustBeURL,
  mustHaveMaxLength,
  mustHaveMinLength,
} from "@/services/forms/validators";

import { Orientation, useFieldState } from "./FieldState";

type TextInputFieldOptions = UseFieldConfig<any> & {
  required?: boolean;
  formatBeforeValidate?: boolean;
  id?: string;
  "aria-label"?: string;
  orientation?: Orientation;
  minLength?: number;
  maxLength?: number;
  regionCode?: string;
};

/**
 * @param {string} name
 * @param {*} options
 */
export const useTextInputField = (
  name: string,
  {
    required = false,
    validate: validateOption,
    formatBeforeValidate,
    id,
    orientation = "vertical",
    minLength,
    maxLength,
    format,
    "aria-label": ariaLabel,
    ...options
  }: TextInputFieldOptions = {},
) => {
  const form = useForm();
  const validators = [];
  if (validateOption) {
    validators.push(validateOption);
  }
  if (required) {
    validators.push(mustBeFilled);
  }
  if (options.type === "email") {
    validators.push(mustBeEmail);
  }
  if (options.type === "phone-number") {
    validators.push(mustBePhoneNumber(options.regionCode));
  }
  if (options.type === "url") {
    validators.push(mustBeURL);
  }
  if (minLength != null) {
    validators.push(mustHaveMinLength(minLength));
  }
  if (maxLength != null) {
    validators.push(mustHaveMaxLength(maxLength));
  }

  const composedValidate = validators.length
    ? composeValidators(...validators)
    : undefined;

  const validate: FieldValidator<any> | undefined =
    composedValidate && formatBeforeValidate
      ? (value, ...args) => composedValidate(format!(value, name), ...args)
      : composedValidate;

  const field = useField(name, { validate, format, ...options });

  const resetField = (name: string) => {
    const {
      meta: { initial },
    } = field;
    form.change(name, initial);
  };

  return useFieldState({
    "aria-label": ariaLabel,
    field,
    id,
    orientation,
    required,
    minLength,
    maxLength,
    resetField,
  });
};

export const TextInputField = forwardRef<
  HTMLElement,
  FieldControlProps<"input">
>(({ as = Textbox, ...props }, ref) => {
  return <FieldControl ref={ref} as={as} {...props} />;
}) as Component<FieldControlOptions<"input">>;
if (process.env["NODE_ENV"] !== "production") {
  TextInputField.displayName = "TextInputField";
}
