import { useCallback } from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Link } from "swash/Link";

import {
  List,
  ListBody,
  ListCell,
  ListHeader,
  ListHeaderCell,
  ListRow,
  useListState,
} from "@/components/List";
import { HasExperimentalFeature, HasPermission } from "@/containers/User";

const Cell = ({ column, node, index, draggable, ...list }) => {
  const EditLink = useCallback(
    ({ slug, children }) => (
      <Link asChild>
        <ReactRouterLink to={`/admin/${slug}/${node.id}`}>
          {children}
        </ReactRouterLink>
      </Link>
    ),
    [node.id],
  );

  return (
    <ListCell {...list} colId={column.id}>
      <column.Value node={node} EditLink={EditLink} />
    </ListCell>
  );
};

export const UsedInList = ({ nodes, columns }) => {
  const list = useListState();
  const { experimentalFeatures = [], permissions = [] } = columns;
  return (
    <List className="pb-2" {...list}>
      <ListHeader {...list}>
        {columns.map((column) => (
          <HasExperimentalFeature
            key={column.id}
            feature={experimentalFeatures}
          >
            <HasPermission permission={permissions}>
              <ListHeaderCell {...list} key={column.id} colId={column.id}>
                <column.Header />
              </ListHeaderCell>
            </HasPermission>
          </HasExperimentalFeature>
        ))}
      </ListHeader>
      <ListBody {...list}>
        {nodes.map((node) => (
          <ListRow key={node.id} {...list}>
            {columns.map((column) => (
              <HasExperimentalFeature
                key={column.id}
                feature={column.experimentalFeatures || []}
              >
                <HasPermission permission={column.permissions || []}>
                  <Cell {...list} key={column.id} node={node} column={column} />
                </HasPermission>
              </HasExperimentalFeature>
            ))}
          </ListRow>
        ))}
      </ListBody>
    </List>
  );
};
