import * as React from "react";
import { forwardRef } from "react";

const SvgAudioModal = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <rect
      x={3}
      y={3.25}
      width={18}
      height={1.5}
      rx={0.75}
      fill="currentColor"
    />
    <rect
      x={3}
      y={7.25}
      width={18}
      height={1.5}
      rx={0.75}
      fill="currentColor"
    />
    <rect
      x={3}
      y={11.25}
      width={6}
      height={1.5}
      rx={0.75}
      fill="currentColor"
    />
    <rect
      x={3}
      y={15.25}
      width={6}
      height={1.5}
      rx={0.75}
      fill="currentColor"
    />
    <rect
      x={3}
      y={19.25}
      width={6}
      height={1.5}
      rx={0.75}
      fill="currentColor"
    />
    <path
      d="M16 11.583a4.167 4.167 0 0 0-4.167 4.167v.671a1.389 1.389 0 0 1 1.944 1.273v1.667a1.389 1.389 0 1 1-2.777 0V15.75a5 5 0 1 1 10 0v3.611a1.389 1.389 0 1 1-2.778 0v-1.667a1.389 1.389 0 0 1 1.945-1.273v-.671A4.167 4.167 0 0 0 16 11.583Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAudioModal);
export default ForwardRef;
