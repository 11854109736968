import { gql } from "@apollo/client";

import { ImageNode } from "@/containers/editor/nodes/ImageNode";
import { ImageNodeFragment } from "@/containers/editor/nodes/ImageNodeFragment";
import { fetchMedia } from "@/services/medias/fetchMedia";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "image";
export const ATOMIC_ENTITY_TYPE = "IMAGE";

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

const ImageQuery = gql`
  query RichEditorImagePlugin_image($id: Int) {
    node: image(id: $id) {
      id
      ...ImageNode_image__expanded
    }
  }

  ${ImageNodeFragment.image__expanded}
`;

function ImageBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: ImageQuery,
    getEntityData: (image, entityData) => {
      return {
        credit: image.credit,
        caption: entityData?.caption ?? image.caption,
        aspectRatioId: image.aspectRatioId,
        crops: image.crops,
      };
    },
  });

  const { state, options } = props.blockProps;

  return (
    <AtomicBlock {...atomic}>
      {({ node, metadata, update }) => (
        <ImageNode
          image={node}
          metadata={metadata}
          onUpdate={update}
          editable={options.editable}
          onEdit={props.setEditing}
          expanded={state.expanded}
        />
      )}
    </AtomicBlock>
  );
}

export const blockRendererFn = () => {
  return { component: ImageBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return { editable: false, ...options };
}

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

const ImageUrlQuery = gql`
  query RichEditorImagePluginUrl_image($id: Int, $url: String, $type: String!) {
    media(id: $id, url: $url, type: $type) {
      id
      ... on Image {
        url
        caption
      }
    }
  }
`;

export const createBlockFromUrls = async (state, urls) => {
  const data = getDataFromUrls(state, urls);
  const [url] = urls;

  if (!data) return null;

  const image = await fetchMedia({
    id: data.id,
    url,
    type: "images",
    query: ImageUrlQuery,
  });

  if (!image.id) return null;
  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: image.id,
        media: {
          id: image.id,
          url: image.url,
          caption: image.caption,
        },
      },
    },
  };
};
