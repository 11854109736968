import { memo } from "react";
import {
  IoAlertCircle,
  IoCheckmarkCircle,
  IoEllipsisHorizontalCircle,
} from "swash/Icon";
import { cn } from "swash/utils/classNames";

export type EventStatusIconProps = {
  status: "queued" | "in_progress" | "failure" | "timed_out" | "success";
  className?: string;
};

export const EventStatusIcon = memo<EventStatusIconProps>(
  ({ status, className }) => {
    switch (status) {
      case "queued":
      case "in_progress":
        return (
          <IoEllipsisHorizontalCircle
            className={cn(
              className,
              "inline-block rounded-full bg-white text-warning-on",
            )}
            role="status"
            aria-busy="true"
            aria-label="En cours"
          />
        );
      case "failure":
        return (
          <IoAlertCircle
            className={cn(
              className,
              "inline-block rounded-full bg-white text-danger-on",
            )}
            role="status"
            aria-label="Échoué"
          />
        );
      case "timed_out":
        return (
          <IoAlertCircle
            className={cn(
              className,
              "inline-block rounded-full bg-white text-danger-on",
            )}
            role="status"
            aria-label="Expiré"
          />
        );
      case "success":
        return (
          <IoCheckmarkCircle
            className={cn(
              className,
              "inline-block rounded-full bg-white text-success-on",
            )}
            role="status"
            aria-label="Réussi"
          />
        );
      default:
        return null;
    }
  },
);
