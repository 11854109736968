import { EditorBlock } from "draft-js-es";
import * as React from "react";

export * from "./text-convert";

export const name = "text";

// Following similar issue for editable component in readOnly mode
// the editable:true flag should be set on the custom block component not in the
// blockRendererFn (see https://stackoverflow.com/a/37024438s)
const TextBlock = React.memo((props) => <EditorBlock {...props} editable />);

export const matchBlock = (state, block) => block.getType() !== "atomic";

export const blockRendererFn = () => {
  return { component: TextBlock };
};
