import moment from "moment";
import "moment/locale/fr";
import { useFormState } from "react-final-form";

export function getDefaultLayout(layouts) {
  return layouts.find((layout) => layout.default) ?? null;
}

export function getDefaultEditorialFormat(layouts) {
  const defaultLayout = getDefaultLayout(layouts);
  if (!defaultLayout) return null;
  return defaultLayout.editorialFormats.nodes.find(
    (editorialFormat) => editorialFormat.default,
  );
}

export function getEditorialType({ values, layout }) {
  if (!layout) return null;
  return layout.layoutEditorialTypes.nodes.find(
    (node) => node.editorialType.id === values.editorialTypeId,
  );
}

export function getLayout({ values, layouts }) {
  if (!values.layoutId) return null;
  return layouts.nodes.find((node) => node.id === values.layoutId);
}

export function useFormLayout({ layouts }) {
  const { values } = useFormState({ subscription: { values: true } });
  return getLayout({ values, layouts });
}

export function getDefaultPeriodical({ user, periodicals }) {
  const defaultPeriodicalId = user.currentSection?.defaultPeriodical?.id;
  if (!defaultPeriodicalId) return null;
  return (
    periodicals.nodes
      .filter(({ enabled }) => enabled)
      .find((periodical) => periodical.id === defaultPeriodicalId) || null
  );
}

export function getPeriodical({ values, periodicals }) {
  return values.periodicalId
    ? periodicals.nodes.find((node) => node.id === values.periodicalId)
    : null;
}

export function useFormPeriodical({ periodicals }) {
  const { values } = useFormState({ subscription: { values: true } });
  return getPeriodical({ values, periodicals });
}

export function getPeriodicalSection({ values, periodicals }) {
  const periodical = getPeriodical({ values, periodicals });
  return periodical && values.periodicalSectionId
    ? periodical.sections.nodes.find(
        (node) => node.id === values.periodicalSectionId,
      )
    : null;
}

export function getPeriodicalSectionLayout({ values, periodicals }) {
  const periodicalSection = getPeriodicalSection({ values, periodicals });
  return periodicalSection && values.periodicalSectionLayoutId
    ? periodicalSection.layouts.nodes.find(
        (node) => node.id === values.periodicalSectionLayoutId,
      )
    : null;
}

export function getPeriodicalRelease({ values, periodicals }) {
  if (!values.periodicalReleaseId) return null;
  const periodical = getPeriodical({ values, periodicals });
  if (!periodical) return null;
  return periodical.releases.nodes.find(
    ({ id }) => id === values.periodicalReleaseId,
  );
}

export function getDefaultPeriodicalSection({ sections, user, periodical }) {
  if (!periodical) return null;
  const defaultSection = sections.nodes.find(
    (section) =>
      section?.defaultPeriodicalSection?.id ===
      user.currentSection?.defaultPeriodicalSection?.id,
  );
  if (!defaultSection) return null;

  return periodical.sections.nodes
    .filter(({ enabled }) => enabled)
    .find(
      (section) =>
        section.id === (defaultSection.defaultPeriodicalSection?.id ?? null),
    );
}

export function useFormPeriodicalSection({ periodicals }) {
  const { values } = useFormState({ subscription: { values: true } });
  return getPeriodicalSection({ values, periodicals });
}

function getCreateAfterDuration(periodicity) {
  // Daily publications are ready before the limit,
  // so after it, we add 1 day to the suggested date
  const createAfter = moment.duration(periodicity.suggestDateAfterDuration);
  if (periodicity.timeDeadline) {
    const deadline = moment(periodicity.timeDeadline, "hh:mm:ss");
    if (moment().isAfter(deadline)) {
      return createAfter.add(1, "day");
    }
  }
  return createAfter;
}

function checkIsEditionDateValid(periodicity, date) {
  const dayOfWeek = moment(date).day() - 1;
  return periodicity.weekDays.includes(dayOfWeek);
}

export function getDefaultPeriodicalEditionDate(periodicalSection, section) {
  if (!periodicalSection || !section?.defaultPeriodicalSectionDate) return null;
  const { periodicity } = periodicalSection;
  if (periodicity.weekDays.length === 7) return null;
  const createAfterDuration = getCreateAfterDuration(periodicity);
  // Trying all week days
  for (
    let duration = createAfterDuration;
    duration.as("days") < 14;
    duration.add("1", "d")
  ) {
    const potentialDate = moment().add(duration);
    if (checkIsEditionDateValid(periodicity, potentialDate)) {
      return moment(potentialDate.toDate()).format("YYYY-MM-DD");
    }
  }
  return null;
}
