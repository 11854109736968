import React, { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

import { SectionTitle } from "@/components/Layout";

export const Fieldset = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { layout?: "vertical" | "horizontal" }
>(({ layout = "vertical", ...props }, ref) => (
  <div
    ref={ref}
    data-layout={layout}
    {...props}
    className={cn("-m-2", props.className)}
  />
));
if (process.env["NODE_ENV"] !== "production") {
  Fieldset.displayName = "Fieldset";
}

export const FieldsetField = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>((props, ref) => (
  <div ref={ref} {...props} className={cn("p-2", props.className)} />
));
if (process.env["NODE_ENV"] !== "production") {
  FieldsetField.displayName = "FieldsetField";
}

export const FieldsetTitle: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props,
) => (
  <SectionTitle
    {...props}
    className={cn("m-2 [&:not(:first-child)]:mt-6", props.className)}
  />
);
