import getCommentsFromContentBlock from "@/components/rich-editor/plugins/comment/utils/getCommentFromBlock";

import { getHTMLFromBlock } from "./getHTMLFromBlock";

const KEY_ID_REGEXP = /^node-(?<id>\d+)$/;

/**
 * Try to extract ID from key.
 * @param {string} key
 */
const getIdFromKey = (key) => {
  const matches = key.match(KEY_ID_REGEXP);
  return matches ? Number(matches.groups.id) : undefined;
};

/**
 * @param {ContentToStateContext} ctx
 * @param {import('draft-js').ContentBlock} block
 */
function blockToNode(ctx, block) {
  const id = getIdFromKey(block.getKey());
  const entityMap = ctx.contentState.getEntityMap();
  const styleName = block.getData().get("styleName") ?? null;
  const node = {
    id,
    type: "text",
    text: getHTMLFromBlock(entityMap, block),
  };
  const comments = getCommentsFromContentBlock(block, ctx.contentState);
  if (comments.length) {
    node.comments = comments;
  }
  switch (block.type) {
    case "paragraph":
      return {
        ...node,
        metadata: {
          type: block.type,
          styleName,
        },
      };
    case "unordered-list-item":
      return {
        ...node,
        metadata: { type: "unordered_list_item", styleName },
      };
    case "ordered-list-item":
      return {
        ...node,
        metadata: { type: "ordered_list_item", styleName },
      };
    case "blockquote":
      return {
        ...node,
        metadata: { type: "blockquote", styleName },
      };
    case "header-two":
      return {
        ...node,
        metadata: { type: "header_two", styleName },
      };
    case "header-three":
      return {
        ...node,
        metadata: { type: "header_three", styleName },
      };
    case "unstyled":
      return {
        ...node,
        metadata: { type: "paragraph", styleName },
      };
    case "atomic": {
      const entityKey = block.getEntityAt(0);
      if (!entityKey) throw new Error("Entity not found");
      const entity = ctx.contentState.getEntity(entityKey);
      if (!entity) throw new Error("Entity not found");
      const entityType = entity.getType();
      const data = entity.getData();

      switch (entityType) {
        case "SEPARATOR":
          return {
            id,
            type: "separator",
          };
        case "ARTICLE":
          return {
            id,
            type: "article",
            articlesId: data.id,
            metadata:
              data.title || data.prefix || data.displayMode
                ? {
                    title: data.title,
                    prefix: data.prefix,
                    displayMode: data.displayMode,
                  }
                : null,
          };
        case "CONTRIBUTION":
          return {
            id,
            type: "contribution",
            contributionsId: data.id,
          };
        case "TWEET":
          return {
            id,
            type: "tweet",
            tweetsId: data.id,
          };
        case "IMAGE":
          return {
            id,
            type: "image",
            imagesId: data.id,
            metadata:
              data.caption != null || data.aspectRatioId || data.crops
                ? {
                    caption: data.caption,
                    aspectRatioId: data.aspectRatioId,
                    crops: data.crops,
                  }
                : null,
          };
        case "ARTICLE_MEDIA":
          return {
            id,
            type: "article_media",
            articleMediasId: data.id,
          };
        case "VIDEO":
          return {
            id,
            type: "video",
            videosId: data.id,
          };
        case "SNIPPET":
          return {
            id,
            type: "snippet",
            snippetsId: data.id,
          };
        case "PRODUCT":
          return {
            id,
            type: "product",
            productsId: data.id,
          };
        case "PRODUCT_SUMMARY":
          return {
            id,
            type: "product_summary",
            productsSummariesId: data.id,
          };
        case "CUSTOM_TYPES_CONTENT":
          return {
            id,
            type: "custom_types_content",
            customTypesContentsId: data.id,
          };
        default:
          throw new Error(`Unknown entity type "${entityType}"`);
      }
    }

    default:
      throw new Error(`Unknown block type "${block.type}"`);
  }
}

const shouldIncludeBlock = (ctx, block) => {
  if (block.type !== "atomic") return true;
  const entityKey = block.getEntityAt(0);
  if (!entityKey) return false;
  const entity = ctx.contentState.getEntity(entityKey);
  if (!entity) return false;
  const entityType = entity.getType();
  return entityType !== "PENDING";
};

/**
 * Convert content state to editor nodes.
 * @param {import('draft-js').ContentState} contentState
 */
export const toNodes = (contentState) => {
  const ctx = { contentState };

  return contentState.getBlockMap().reduce((nodes, block) => {
    if (!shouldIncludeBlock(ctx, block)) return nodes;
    nodes.push(blockToNode(ctx, block));
    return nodes;
  }, []);
};
