import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

export const FormActions = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn("-m-2 flex grow flex-wrap", props.className)}
  />
));

export const FormAction = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => (
  <div
    ref={ref}
    {...props}
    className={cn(
      "w-auto max-w-none shrink-0 grow-0 basis-auto p-2",
      props.className,
    )}
  />
));
