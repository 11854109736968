import { gql } from "@apollo/client";
import { RefObject, memo } from "react";
import { Chip } from "swash/Chip";
import { Tooltip } from "swash/Tooltip";
import { useElementsOverflow } from "swash/utils/useElementsOverflow";

type Keyword = { id: number; label: string };

type KeywordListProps = {
  keywords: Keyword[];
};

const KeywordList = memo<KeywordListProps>((props) => {
  const { containerRef, listRef, listStyle, items, overflowItems } =
    useElementsOverflow({
      items: props.keywords,
    });

  return (
    <div
      ref={containerRef as RefObject<HTMLDivElement>}
      className="grow overflow-hidden py-0.5"
    >
      <div
        ref={listRef as RefObject<HTMLDivElement>}
        style={listStyle}
        className="flex gap-2"
      >
        {items.map((keyword) => (
          <Chip key={keyword.id}>{keyword.label}</Chip>
        ))}
        {overflowItems.length > 0 && (
          <Tooltip tooltip={overflowItems.map((item) => item.label).join(", ")}>
            <Chip>+{overflowItems.length}</Chip>
          </Tooltip>
        )}
      </div>
    </div>
  );
});

export type SnippetKeywordListProps = {
  snippet: {
    keywords: Keyword[];
  };
};

export const SnippetKeywordList = (props: SnippetKeywordListProps) => {
  return <KeywordList keywords={props.snippet.keywords} />;
};

SnippetKeywordList.fragments = {
  snippet: gql`
    fragment SnippetKeywordList_snippet on Snippet {
      keywords {
        id
        label
      }
    }
  `,
};
