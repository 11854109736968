/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { Anchor } from "swash/Anchor";
import { Tooltip } from "swash/Tooltip";

import { HasLevelAccess } from "@/containers/User";
import * as servicesElements from "@/containers/routes/admin/integrations/services";

import { ChannelAccountPicture, ChannelAccounts } from "../AlertChannelLayout";

const OTHERS_TAG_KEY = "xxx-others";

export const title = "Notification push";

export const type = "push";

export const fieldName = "targetingPushNotification";

export const AlertFragment = gql`
  fragment PushChannelAlertFragment on Alert {
    targetingPushNotification
    pushTags
  }
`;

export const ChannelFragment = gql`
  fragment PushChannelFragment on PushAlertChannel {
    integrations(tags: $pushTags) {
      id
      name
      config {
        ...BatchIntegrationConfigFragment
      }
      service {
        key
        name
      }
    }
    tags
  }
  ${servicesElements.batch.IntegrationConfigFragment}
`;

export const getInitialValues = ({ channelData, alert }) => {
  const channelEnabled = Boolean(
    channelData?.status && channelData.status !== "disabled",
  );

  const delivery = alert?.deliveries.find(
    (delivery) => delivery.channel === type,
  );

  const channelTags = channelData?.tags ?? [];

  if (!alert) {
    return {
      targetingPushNotification: channelEnabled,
      pushTags: channelTags.reduce((obj, tag) => ({ ...obj, [tag]: true }), {}),
    };
  }

  const delivered = delivery?.conclusion === "success";

  // When delivered we use tags from the alert
  // else we use tags from the channel
  const pushTags = (() => {
    const alertTags = alert.pushTags ?? [];
    if (delivered) {
      return alertTags.reduce((obj, tag) => {
        obj[tag] = true;
        return obj;
      }, {});
    }

    const uniqTags = Array.from(new Set([...channelTags, ...alertTags]));
    return uniqTags.reduce((obj, tag) => {
      obj[tag] = !alert.pushTags ? true : alertTags.includes(tag);
      return obj;
    }, {});
  })();

  const channelHasNoTags = !channelTags.length;
  const alertHasActivePushTags = Object.values(pushTags).some(Boolean);

  // If an alert has active pushTags, then push notification is enabled
  const targetingPushNotification =
    channelEnabled &&
    ((channelHasNoTags && alert.targetingPushNotification) ||
      alertHasActivePushTags);

  return {
    targetingPushNotification,
    pushTags,
  };
};

export const parseValues = (values) => {
  const { pushTags } = values;
  const tags = Object.keys(pushTags).filter((tag) => pushTags[tag]);
  return { ...values, pushTags: tags };
};

export const Title = ({ data, delivery, title, tag }) => {
  return (
    <>
      <div>
        <span>{title}</span>
        <HasLevelAccess level="developer">
          {delivery?.conclusion !== "success" && (
            <ChannelAccounts>
              {data.integrations
                .filter((integration) => {
                  if (!tag) return integration;
                  const tags = integration.config.tags || [];
                  return (
                    tags.join() === tag ||
                    (tag === OTHERS_TAG_KEY && !tags.length)
                  );
                })
                .map((integration) => {
                  // eslint-disable-next-line import/namespace
                  const element = servicesElements[integration.service.key];

                  return (
                    <Tooltip
                      key={integration.id}
                      tooltip={`${integration.service.name} - ${integration.name}`}
                    >
                      <li>
                        <Anchor
                          href={`/admin/integrations/${integration.service.key}/${integration.id}/`}
                          target="_blank"
                        >
                          <ChannelAccountPicture
                            className="border border-grey-border-light"
                            src={element.logo}
                            alt={integration.service.name}
                          />
                        </Anchor>
                      </li>
                    </Tooltip>
                  );
                })}
            </ChannelAccounts>
          )}
        </HasLevelAccess>
      </div>
    </>
  );
};

export const getSubChannels = ({ data, delivery, alert }) => {
  const delivered = delivery?.conclusion === "success";
  const alertTags = alert?.pushTags || [];
  // When delivered we use tags from the alert
  // else we use tags from the channel
  const tags = delivered ? alertTags : data?.tags ?? [];
  return tags.map((tag) => {
    const title = tag === OTHERS_TAG_KEY ? "Push autres" : `Push ${tag}`;
    return {
      type: tag,
      fieldName: `pushTags.${tag}`,
      title: <Title data={data} tag={tag} delivery={delivery} title={title} />,
      active: alertTags.includes(tag),
    };
  });
};
