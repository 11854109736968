import { memo } from "react";

import { EditorNodeCard, EditorNodeLayout } from "./NodeLayout";

export const NodeLoader = memo(() => {
  return (
    <EditorNodeCard role="status" aria-busy="true" aria-label="Chargement">
      <EditorNodeLayout>Chargement...</EditorNodeLayout>
    </EditorNodeCard>
  );
});
