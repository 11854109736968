// @ts-check
import { memoize } from "lodash-es";

/**
 * @typedef Context
 * @property {string[]} allowedBlockTypes
 * @property {string[]} allowedInlineStyles
 * @property {string[]} allowedTagNames
 * @property {Record<string, string[]>} allowedAttributes
 * @property {Record<string, string | Function>} transformTags
 * @property {(ast: import('hast').Root) => import('hast').Root} transformPastedHTML
 * @property {import('../types').htmlToStyle} htmlToStyle
 * @property {import('../types').htmlToEntity} htmlToEntity
 * @property {import('../types').htmlToBlock} htmlToBlock
 * @property {import('./convertFromHTML').Mode} mode
 */

/**
 * @param {any[]} plugins
 * @returns {Context}
 */
export const getConvertContext = memoize(
  /** @param {any[]} plugins */ (plugins) => {
    const ctx = /** @type {Context} */ (
      plugins.reduce(
        (ctx, plugin) => {
          if (plugin.allowedBlockTypes) {
            ctx.allowedBlockTypes.push(...plugin.allowedBlockTypes);
          }
          if (plugin.allowedInlineStyles) {
            ctx.allowedInlineStyles.push(...plugin.allowedInlineStyles);
          }
          if (plugin.allowedTagNames) {
            ctx.allowedTagNames.push(...plugin.allowedTagNames);
          }
          if (plugin.allowedAttributes) {
            Object.assign(ctx.allowedAttributes, plugin.allowedAttributes);
          }
          if (plugin.transformTags) {
            Object.assign(ctx.transformTags, plugin.transformTags);
          }

          return ctx;
        },
        /** @type {Partial<Context>} */ ({
          allowedBlockTypes: ["paragraph", "atomic"],
          allowedInlineStyles: [],
          allowedTagNames: [],
          allowedAttributes: {},
          transformTags: {},
        }),
      )
    );

    ctx.transformPastedHTML = (ast) => {
      let result = ast;
      for (const plugin of plugins) {
        if (plugin.transformPastedHTML) {
          result = plugin.transformPastedHTML(result);
        }
      }
      return result;
    };

    ctx.htmlToStyle = (value) => {
      /** @type {string[]} */
      let result = [];
      for (const plugin of plugins) {
        if (plugin.htmlToStyle) {
          const styles = plugin.htmlToStyle(value);
          if (styles) {
            result = [...result, ...styles];
          }
        }
      }
      return result;
    };

    ctx.htmlToEntity = (value) => {
      for (const plugin of plugins) {
        if (plugin.htmlToEntity) {
          const result = plugin.htmlToEntity(value);
          if (result) return result;
        }
      }
      return null;
    };

    ctx.htmlToBlock = (value) => {
      /** @type {Partial<import('../types').RawBlock> | null} */
      let block = null;
      for (const plugin of plugins) {
        if (plugin.htmlToBlock) {
          const result = plugin.htmlToBlock(value);
          if (result) {
            block =
              /** @type {Partial<import('../types').RawBlock>} */
              (block ?? {});
            block = {
              ...block,
              ...result,
              data: { ...block.data, ...result.data },
            };
          }
        }
      }
      return block;
    };

    return ctx;
  },
);
getConvertContext.cache = new WeakMap();
