import * as React from "react";
import { forwardRef } from "react";

const SvgUncheck = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12 2.25a9.693 9.693 0 0 0-5.331 1.59l6.823 6.824 2.434-2.896a.75.75 0 0 1 1.148.964l-2.517 2.997 5.603 5.602A9.693 9.693 0 0 0 21.75 12c0-5.376-4.374-9.75-9.75-9.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.109 5.109 18.891 18.89l.887.887a1 1 0 1 1-1.414 1.414L17.33 20.16A9.693 9.693 0 0 1 12 21.75c-5.376 0-9.75-4.374-9.75-9.75 0-1.966.585-3.798 1.59-5.331L2.808 5.636a1 1 0 0 1 1.414-1.414l.887.887Zm5.8 8.63-.73.868-2.123-2.359a.752.752 0 0 0-1.247.17.75.75 0 0 0 .133.834l2.7 3a.75.75 0 0 0 .557.248h.013a.75.75 0 0 0 .562-.268l1.2-1.429-1.064-1.065Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUncheck);
export default ForwardRef;
