import { gql } from "@apollo/client";
import { useMemo, useState } from "react";
import { useFormState } from "react-final-form";

import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";

export function PeriodicalSectionLayoutField({
  children,
  required,
  disabled,
  periodicalSection,
  label = "Thème",
  placeholder = "Sélectionnez un thème",
  modal,
}) {
  const {
    values: { periodicalSectionLayoutId },
  } = useFormState({ subscription: { values: true } });

  const [initialPeriodicalSectionLayoutId] = useState(
    periodicalSectionLayoutId,
  );

  const selectableLayout = useMemo(() => {
    const layouts = periodicalSection?.layouts.nodes ?? [];
    return layouts.filter(
      (node) => node.enabled || initialPeriodicalSectionLayoutId === node.id,
    );
  }, [periodicalSection, initialPeriodicalSectionLayoutId]);

  const enumValues = useNodesToEnumArray(selectableLayout);

  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    const isSelectedLayoutEnable = !!enumValues.find(
      (item) => item.value === values.periodicalSectionLayoutId,
    );

    if (
      !isSelectedLayoutEnable ||
      values.periodicalSectionId !== previousValues.periodicalSectionId
    ) {
      form.change("periodicalSectionLayoutId", null);
    }
  });

  if (!selectableLayout.length) return null;

  const field = (
    <EnumField
      name="periodicalSectionLayoutId"
      label={label}
      placeholder={placeholder}
      enum={enumValues}
      clearable
      modal={modal}
      required={required}
      disabled={disabled}
    />
  );

  return typeof children === "function" ? children({ children: field }) : field;
}

export const PeriodicalSectionLayoutFieldPeriodicalSectionFragment = gql`
  fragment PeriodicalSectionLayoutFieldPeriodicalSectionFragment on PeriodicalSection {
    id
    layouts {
      nodes {
        id
        label
        path
        enabled
      }
    }
  }
`;
