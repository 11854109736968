import { gql } from "@apollo/client";
import { memo } from "react";
import { PageLoader } from "swash/Loader";
import { PanelBody } from "swash/Panel";

import { AuthorAvatar } from "@/components/AuthorAvatar";
import { useSafeQuery } from "@/containers/Apollo";
import { NoPermissionsContent } from "@/containers/BlockIfNotAllowed";
import { createPanel } from "@/containers/PanelManager";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";
import { DraftEditorNodeFragment } from "@/containers/editor/DraftEditorFragments";
import { ArticleNode } from "@/containers/editor/nodes/ArticleNode";
import { ImageNodeFragment } from "@/containers/editor/nodes/ImageNodeFragment";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";

const ArticleInitQuery = gql`
  query ArticlePanel_article($id: Int!) {
    article(id: $id) {
      id
      title
      chapo
      freeAuthors {
        longName
        origin
      }
      signatures {
        id
        author {
          id
          longName
          origin
          ...AuthorAvatar_author
        }
        origin
      }
      legacyCustomFields {
        video {
          id
          media {
            ...VideoNode_video
          }
        }
        multimediaContent {
          articleMedias {
            media {
              id
              ... on Archive {
                archiveTitle: title
                cloudPath
              }
            }
          }
        }
        snippet {
          id
          media {
            ...SnippetNode_snippet
          }
        }
        portfolio {
          id
          type
          articleMedias {
            id
            globalId
            metadata
            type
            media {
              ...ImageNode_image
            }
          }
        }
        backgroundMedia {
          id
          type
          metadata
          media {
            ...ImageNode_image
            ...VideoNode_video
            ...SnippetNode_snippet
          }
        }
        aliasSource
        aliasUrl
      }
      layout {
        id
        name
        articleLinkPrefix
      }
      acl {
        me
      }
      editorialType {
        id
        hasTitle
        hasHeading
        hasContent
        hasAuthors
      }
      nodes {
        ...DraftEditorNodeFragment
      }
    }
  }
  ${VideoNode.fragments.video}
  ${AuthorAvatar.fragments.author}
  ${ImageNodeFragment.image}
  ${SnippetNodeFragments.snippet}
  ${DraftEditorNodeFragment}
`;

const LightArticlePresentation = memo(({ articleId }) => {
  const { data } = useSafeQuery(ArticleInitQuery, {
    variables: { id: articleId },
    ignoreSubsequentErrors: true,
  });

  if (!data) return <PageLoader />;

  if (!data.article) return null;

  if (!data.article.acl.me.includes("read")) {
    if (data.article.state === "draft")
      return (
        <ArticleNode
          article={data.article}
          displayTitle={false}
          variant="side-panel"
          metadata={{ displayMode: "embed" }}
          expanded
        />
      );

    return (
      <div className="flex flex-col items-center justify-center">
        <NoPermissionsContent />
      </div>
    );
  }
  return (
    <ArticleNode
      article={data.article}
      displayTitle={false}
      variant="side-panel"
      metadata={{ displayMode: "embed" }}
      expanded
    />
  );
});

export const ArticlePanel = createPanel({
  key: "article-infos",
  title: "Informations sur l'article",
  render: ({ articleId, onClose }) => (
    <>
      <TrackPanel panelKey="article-infos" />
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <PanelBody data-testid="panel-body-article">
        <LightArticlePresentation articleId={articleId} />
      </PanelBody>
    </>
  ),
});
