import { EditorState } from "draft-js-es";

import { checkIsValidSelection } from "../utils/Selection";

/**
 * Accept selection in a "soft way".
 * If it is invalid, do nothing.
 * @param {import('draft-js').EditorState} editorState
 * @param {import('draft-js').SelectionState} selection
 */
export const acceptSelection = (editorState, selection) => {
  if (!checkIsValidSelection(editorState.getCurrentContent(), selection)) {
    return editorState;
  }
  return EditorState.acceptSelection(editorState, selection);
};
