import { memo } from "react";

import { Case } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { handleBinding } from "../utils";
import { command } from "./switch-case";

const SHORTCUT = ["⌘", "⌥", "k"];

export const shortcuts = [
  {
    description: "Inverser la casse",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "switch-case-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const SwitchCaseToolbarButton = memo(() => {
  return (
    <RichEditorToolbarButton
      command={command}
      label="Inverser la casse"
      shortcut={SHORTCUT}
    >
      <Case />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = () => {
  return <SwitchCaseToolbarButton />;
};

BlockControls.group = "inline-style";
