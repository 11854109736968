import { smartToggleInlineStyle } from "../modifiers/smartToggleInlineStyle";
import { INLINE_STYLE } from "./bold-convert";

export * from "./bold-convert";

export const name = "bold";
export const command = "bold";

export const handleKeyCommand = ({ setEditorState }, cmd) => {
  if (cmd === command) {
    setEditorState((editorState) =>
      smartToggleInlineStyle(editorState, INLINE_STYLE),
    );
    return true;
  }
  return undefined;
};
