import { useField } from "react-final-form";

import { FieldControl } from "@/components/fields/FieldControl";
import { useFieldState } from "@/components/fields/FieldState";
import { composeValidators, mustBeFilled } from "@/services/forms/validators";

// ISSUE https://github.com/final-form/final-form/issues/178
// this is a workaround since we can't mutate errors at formState level nor error at fieldState level
// the form or field components won't re-render
const fieldErrorValidate = (value, allValues, meta) => {
  const {
    data: { error },
  } = meta;

  return error;
};

export function useFileDropField(
  name,
  {
    required = false,
    id,
    orientation = "vertical",
    validate,
    label,
    ...options
  } = {},
) {
  const validators = [];

  validators.push(fieldErrorValidate);

  if (validate) {
    validators.push(validate);
  }

  if (required) {
    validators.push(mustBeFilled);
  }

  const field = useField(name, {
    validate: validators.length ? composeValidators(...validators) : undefined,
    ...options,
  });

  return useFieldState({ field, required, orientation, label });
}

export const FileDropField = FieldControl;
