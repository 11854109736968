import * as React from "react";
import { forwardRef } from "react";

const SvgPaperPlaneOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m2.49 9.372 18.75-7.097a.375.375 0 0 1 .484.485l-7.096 18.75a.375.375 0 0 1-.703-.016l-3.16-7.786a.75.75 0 0 0-.474-.474l-7.785-3.156a.375.375 0 0 1-.016-.706v0ZM21.563 2.438 10.64 13.358"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPaperPlaneOutline);
export default ForwardRef;
