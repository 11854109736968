import * as React from "react";
import { forwardRef } from "react";

const SvgBubblePlusOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.075 12H12m0 0h2.925M12 12V9.075M12 12v2.925m0 6.825c5.385 0 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25 2.25 6.615 2.25 12c0 1.775.475 3.442 1.305 4.875l-.818 4.387 4.388-.817A9.707 9.707 0 0 0 12 21.75Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBubblePlusOutline);
export default ForwardRef;
