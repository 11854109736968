/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, useEffect, useState } from "react";
import { cn } from "swash/utils/classNames";
import { usePrevious } from "swash/utils/usePrevious";

import { createSetFactory } from "./util";

type ListState = {
  columns: Record<string, { props: any }>;
};

type ListProps = React.ComponentProps<"div"> & {
  state: ListState;
};
export const List: React.FC<ListProps> = forwardRef<HTMLDivElement, ListProps>(
  ({ state, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="table"
        {...props}
        className={cn(
          "table w-full !border-separate border-spacing-0",
          props.className,
        )}
      />
    );
  },
);

type ListHeaderProps = React.ComponentProps<"div"> & {
  state: ListState;
};
export const ListHeader = forwardRef<HTMLDivElement, ListHeaderProps>(
  ({ state, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="row"
        {...props}
        className={cn("table-row", props.className)}
      />
    );
  },
);

type ListHeaderCellProps = React.ComponentProps<"div"> & {
  state: ListState;
  colId: string;
};
export const ListHeaderCell = forwardRef<HTMLDivElement, ListHeaderCellProps>(
  ({ state, colId, ...props }, ref) => {
    const width = state?.columns[colId]?.props?.width;

    return (
      <div
        ref={ref}
        role="columnheader"
        {...props}
        style={{ width }}
        className={cn(
          "table-cell pb-2 pr-1 font-accent text-xs",
          props.className,
        )}
      />
    );
  },
);

type ListBodyProps = React.ComponentProps<"div"> & {
  state: ListState;
  active?: boolean;
  version?: number;
};
export const ListBody = forwardRef<HTMLDivElement, ListBodyProps>(
  ({ state, active, onClick, version, ...props }, ref) => {
    // When activated, we animate it
    const [activated, setActivated] = useState(active);
    const previousActive = usePrevious(active);
    useEffect(() => {
      if (previousActive === undefined) return undefined;
      if (!previousActive && active) {
        setActivated(true);
        const timeout = setTimeout(() => setActivated(false), 300);
        return () => clearTimeout(timeout);
      }
      return undefined;
    }, [previousActive, active]);

    return (
      <div
        ref={ref}
        role="rowgroup"
        tabIndex={onClick ? 0 : undefined}
        data-active={active ? "" : undefined}
        data-enter-active={activated ? "" : undefined}
        data-clickable={onClick ? "" : undefined}
        onClick={onClick}
        {...props}
        className={cn(
          "table-row-group rounded-md shadow-card transition",
          "&[data-clickable]:cursor-pointer &[data-clickable]:hover:shadow-card",
          "&[data-active]:bg-primary-bg-light &[data-active]:shadow-card",
          "&[data-enter-active]:animate-appear",
          version === 2 && "border-t border-grey-border-light",
          "aria-expanded:shadow-card aria-expanded:hover:shadow-card",
          props.className,
        )}
      />
    );
  },
);

type ListRowProps = React.ComponentProps<"div"> & {
  state: ListState;
  active?: boolean;
  version?: number;
};
export const ListRow = forwardRef<HTMLDivElement, ListRowProps>(
  ({ state, active, className, version, ...props }, ref) => {
    return (
      <div
        ref={ref}
        role="row"
        className={cn(
          "group/list-row",
          "table-row transition",
          "odd:bg-white even:bg-secondary-bg-light",
          "[&:first-child>*:first-child]:rounded-tl-md",
          "[&:first-child>*:last-child]:rounded-tr-md",
          "[&:last-child>*:first-child]:rounded-bl-md",
          "[&:last-child>*:last-child]:rounded-br-md",
          "[&:first-child>*]:border-t-0 [&:first-child>*]:border-grey-border-light",
          "[&:last-child>*]:border-b-0 [&:last-child>*]:border-grey-border-light",
          version === 2 &&
            "[&:first-child>*]:border-t [&:last-child>*]:border-b",
          className,
        )}
        {...props}
      />
    );
  },
);

type ListCellProps = React.ComponentProps<"div"> & {
  state: ListState;
  colId: string;
  invalid?: boolean;
  version?: number;
};
export const ListCell = forwardRef<HTMLDivElement, ListCellProps>(
  ({ state, colId, children, invalid, version, ...props }, ref) => {
    const width = state?.columns[colId]?.props?.width;

    return (
      <div
        ref={ref}
        role="cell"
        data-invalid={invalid}
        {...props}
        style={{ width }}
        className={cn(
          "[role=button]:cursor-pointer table-cell px-2 py-4 transition",
          "focus:shadow-table-cell focus:outline-0",
          "data-[invalid=true]:border data-[invalid=true]:border-danger-border",
          "border-b border-l-0 border-r border-grey-border-light [&:first-child]:border-l-0 [&:last-child]:border-r-0 [&:last-child]:border-grey-border-light",
          version === 2 &&
            "border-l [&:first-child]:border-l [&:last-child]:border-r",
          props.className,
        )}
      >
        {children}
      </div>
    );
  },
);

export function useListState({ columns = {} } = {}): { state: ListState } {
  return { state: { columns } };
}

export const createListSet = createSetFactory({
  useState: useListState,
  components: {
    List,
    ListHeader,
    ListHeaderCell,
    ListBody,
    ListRow,
    ListCell,
  },
});
