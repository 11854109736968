import { gql } from "@apollo/client";
import { memo } from "react";
import { Link } from "swash/Link";
import { Tooltip } from "swash/Tooltip";

import type { ArticleDescriptionNarrowSection_articleFragment } from "@/gql-types";

type Section = {
  id: number;
  title: string;
};

export type SpreadArticleDescriptionNarrowSectionProps = {
  narrowSection?: Section | null;
  onClick?: (section: Section) => void;
};

export const SpreadArticleDescriptionNarrowSection =
  memo<SpreadArticleDescriptionNarrowSectionProps>((props) => {
    if (!props.narrowSection) return null;
    if (props.onClick) {
      return (
        <Tooltip tooltip="Filtrer sur la rubrique">
          <Link
            asChild
            className="text-sm"
            onClick={(event) => {
              event.stopPropagation();
              if (props.onClick && props.narrowSection) {
                props.onClick(props.narrowSection);
              }
            }}
          >
            <span>{props.narrowSection.title}</span>
          </Link>
        </Tooltip>
      );
    }
    return (
      <span className="text-sm text-grey-on">{props.narrowSection.title}</span>
    );
  });

export type ArticleDescriptionNarrowSectionProps = {
  article: ArticleDescriptionNarrowSection_articleFragment;
  onClick?: (section: Section) => void;
};

export const ArticleDescriptionNarrowSection = (
  props: ArticleDescriptionNarrowSectionProps,
) => {
  return (
    <SpreadArticleDescriptionNarrowSection
      narrowSection={props.article.narrowSection}
      onClick={props.onClick}
    />
  );
};

ArticleDescriptionNarrowSection.fragments = {
  article: gql`
    fragment ArticleDescriptionNarrowSection_article on Article {
      narrowSection {
        id
        title
      }
    }
  `,
};
