import { useRef, useState } from "react";

import { useRefHandler } from "./useRefHandler";

export const useHovered = ({ skip = false } = {}) => {
  const [hovered, setHovered] = useState(false);
  const containerRef = useRef<HTMLElement | null | undefined>();

  const refHandler = useRefHandler<HTMLElement | null>((element) => {
    containerRef.current = element;
    if (!element || skip) return undefined;
    const handleMouseEnter = () => {
      setHovered(true);
    };
    const handleMouseLeave = () => {
      setHovered(false);
    };
    element.addEventListener("mouseenter", handleMouseEnter);
    element.addEventListener("mouseleave", handleMouseLeave);
    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      element.removeEventListener("mouseleave", handleMouseLeave);
    };
  });

  return [refHandler, hovered, containerRef];
};
