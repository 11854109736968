import {
  BlockMap,
  CharacterMetadata,
  ContentBlock,
  ContentState,
  DraftDecorator,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
  genKey,
} from "draft-js-es";
import { useMemo } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useHoveredComposeEntityKeys } from "@/components/rich-editor/RichEditorCompositeEntityContext";
import { useFocusedEntityKey } from "@/components/rich-editor/RichEditorContext";
import {
  State,
  getAnchorEntity,
  getAnchorEntityKey,
} from "@/components/rich-editor/utils/PluginPopover";

export type ComposeEntity = {
  key: string;
  type: string;
} & Record<string, any>;

export type CompositeEntityDecoratorProps = {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
  filter?: (entity: ComposeEntity) => boolean;
};

interface CompositeEntityDecoratorStateProps {
  contentState: ContentState;
  entityKey: string;
  filter?: (entity: ComposeEntity) => boolean;
}

type CompositeEntityDecoratorState = {
  entities: ComposeEntity[];
  hovered: boolean;
  focused: boolean;
  props: {
    "data-hovered"?: string;
    "data-focused"?: string;
    "data-entity-types": string;
  };
};

const match = (
  activeEntities: ComposeEntity[],
  localEntities: ComposeEntity[],
): boolean => {
  const localKeysSet = new Set(localEntities.map(({ key }) => key));
  return activeEntities.some(({ key }) => localKeysSet.has(key));
};

const pickComposeEntities = (
  compositeEntity: EntityInstance,
  filter?: (entity: ComposeEntity) => boolean,
): ComposeEntity[] => {
  if (!filter) {
    return compositeEntity.getData().entities;
  }
  return compositeEntity.getData().entities.filter(filter);
};

export const genComposeEntityKey = (composeEntity?: Partial<ComposeEntity>) => {
  if (composeEntity) {
    // if "id" then use it like a key
    const id = composeEntity["id"];
    if (id && id !== -1) {
      return String(id);
    }
  }
  return genKey();
};

export const useCompositeEntityDecoratorState = ({
  contentState,
  entityKey,
  filter,
}: CompositeEntityDecoratorStateProps): CompositeEntityDecoratorState => {
  const entity = contentState.getEntity(entityKey);

  const entities = pickComposeEntities(entity, filter);
  const focusedEntityKey = useFocusedEntityKey();
  const hoveredComposeEntityKeys = useHoveredComposeEntityKeys();

  const refs = useLiveRef({
    contentState,
    entities,
  });

  const focused = useMemo(() => {
    const { entities, contentState } = refs.current;
    if (focusedEntityKey) {
      const focusedEntity = contentState.getEntity(focusedEntityKey);
      if (focusedEntity.getType() !== "COMPOSITE") return false;
      return match(focusedEntity.getData().entities, entities);
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedEntityKey]);

  const hovered = useMemo(() => {
    const { entities } = refs.current;
    if (hoveredComposeEntityKeys) {
      return entities.some(({ key }) => hoveredComposeEntityKeys.has(key));
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredComposeEntityKeys]);

  return useMemo(
    () => ({
      entities,
      focused,
      hovered,
      props: {
        "data-hovered": hovered ? "" : undefined,
        "data-focused": focused ? "" : undefined,
        "data-entity-types": Array.from(
          new Set(
            entity
              .getData()
              .entities.map(({ type }: ComposeEntity) => type.toLowerCase()),
          ),
        ).join("-"),
      },
    }),
    [entities, entity, focused, hovered],
  );
};

type CompositeStrategy = (
  ...args: [
    entities: ComposeEntity[],
    index: number,
    ...herited: Parameters<DraftDecorator["strategy"]>,
  ]
) => boolean;

export const withCompositeEntities = ({
  strategy,
  component,
  filter,
  props,
}: Pick<DraftDecorator, "component"> & {
  filter?: (entity: ComposeEntity) => boolean;
  strategy?: CompositeStrategy;
  props?: any;
}): DraftDecorator => ({
  strategy: (contentBlock, callback, contentState) => {
    let index = 0;
    contentBlock.findEntityRanges((character) => {
      index += 1;
      const entityKey = character.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (!entity) return false;
      if (entity.getType() !== "COMPOSITE") return false;
      const entities = pickComposeEntities(entity, filter);
      return strategy
        ? strategy(entities, index, contentBlock, callback, contentState)
        : entities.length > 0;
    }, callback);
  },
  component,
  props: { ...props, filter } as CompositeEntityDecoratorProps,
});

export const isActive = (composeType: string, state: State): boolean => {
  if (state.readOnly) return false;
  const entity = getAnchorEntity(state);
  return (
    entity
      ?.getData()
      .entities?.some(({ type }: ComposeEntity) => type === composeType) ??
    false
  );
};

export const getAnchorComposeEntity = (
  composeType: string,
  state: State,
): ComposeEntity[] => {
  const entity = getAnchorEntity(state);
  if (!entity || entity.getType() !== "COMPOSITE") return [];
  return (
    entity
      .getData()
      .entities.filter(({ type }: ComposeEntity) => type === composeType) ?? []
  );
};

const getComposeEntityRanges = (
  contentState: ContentState,
  contentBlock: ContentBlock,
  composeEntityKey?: string,
) => {
  const selections: SelectionState[] = [];
  contentBlock.findEntityRanges(
    (character: CharacterMetadata) => {
      const characterEntityKey = character.getEntity();
      if (!characterEntityKey) return false;
      const entity = contentState.getEntity(characterEntityKey);
      if (entity.getType() !== "COMPOSITE") return false;
      return entity
        .getData()
        .entities.some(({ key }: ComposeEntity) => composeEntityKey === key);
    },
    (start: number, end: number) => {
      const selection = SelectionState.createEmpty(contentBlock.getKey()).merge(
        {
          anchorOffset: start,
          focusOffset: end,
        },
      );
      selections.push(selection);
    },
  );
  return selections;
};

const buildFragment = (
  contentState: ContentState,
  selection: SelectionState,
  callback: (input: {
    char: CharacterMetadata;
    entityKey: string;
    block: ContentBlock;
    index: number;
  }) => CharacterMetadata,
): BlockMap => {
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();
  const blockMap = contentState.getBlockMap();
  const blockKeys = blockMap.keySeq();
  const startIndex = blockKeys.indexOf(startKey);
  const endIndex = blockKeys.indexOf(endKey) + 1;
  return blockMap.slice(startIndex, endIndex).map((block, blockKey) => {
    if (!block) return;
    const text = block.getText();
    const chars = block.getCharacterList().map((char, index) => {
      if (
        index === undefined ||
        char === undefined ||
        (blockKey === startKey && index < startOffset) ||
        (blockKey === endKey && index >= endOffset)
      )
        return char;
      const entityKey = char.getEntity();
      return callback({ char, entityKey, block, index });
    });

    if (startKey === endKey) {
      return block.merge({
        text: text.slice(startOffset, endOffset),
        characterList: chars.slice(startOffset, endOffset),
      });
    }

    if (blockKey === startKey) {
      return block.merge({
        text: text.slice(startOffset),
        characterList: chars.slice(startOffset),
      });
    }

    if (blockKey === endKey) {
      return block.merge({
        text: text.slice(0, endOffset),
        characterList: chars.slice(0, endOffset),
      });
    }

    return block.merge({
      text,
      characterList: chars,
    });
  }) as BlockMap;
};

export const getComposeEntitySelections = (
  state: State,
  composeEntityKey: string,
) => {
  const contentState = state.editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  return blockMap.reduce<SelectionState | null | undefined>(
    (selectedRange, block, blockKey) => {
      if (!block || !blockKey) return selectedRange;
      const selections = getComposeEntityRanges(
        state.editorState.getCurrentContent(),
        block,
        composeEntityKey,
      );
      if (!selections.length) {
        return selectedRange;
      }
      selections.forEach((selection) => {
        selectedRange = selectedRange
          ? selectedRange.merge({
              focusKey: blockKey,
              focusOffset: selection.getEndOffset(),
            })
          : SelectionState.createEmpty(blockKey).merge({
              anchorOffset: selection.getAnchorOffset(),
              focusOffset: selection.getFocusOffset(),
            });
      });
      return selectedRange;
    },
    null,
  );
};

const inSelection = (selection: SelectionState, ...indexes: number[]) =>
  indexes.every(
    (index) =>
      index > selection.getStartOffset() && index < selection.getEndOffset(),
  );

export const applyComposeEntity = (
  contentState: ContentState,
  selection: SelectionState,
  composeEntity: ComposeEntity,
  stackable?: boolean,
): ContentState => {
  const entityKeysMapping: { [key: string]: string | null } = {};
  const fragment = buildFragment(
    contentState,
    selection,
    ({ char, entityKey, block }) => {
      if (block.getType() === "atomic") return char;
      if (!entityKeysMapping[entityKey]) {
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          let composeEntities: ComposeEntity[] =
            entity.getData().entities ?? [];
          if (stackable !== undefined && !stackable) {
            composeEntities = composeEntities.filter(
              ({ type }) => type !== composeEntity.type,
            );
          }
          contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
            entities: [...composeEntities, composeEntity],
          });
          entityKeysMapping[entityKey] = contentState.getLastCreatedEntityKey();
        } else {
          contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
            entities: [composeEntity],
          });
          entityKeysMapping[entityKey] = contentState.getLastCreatedEntityKey();
        }
      }
      return CharacterMetadata.applyEntity(
        char,
        entityKeysMapping[entityKey] ?? null,
      );
    },
  );
  return Modifier.replaceWithFragment(contentState, selection, fragment);
};

type GetComposeEntityDataParams = {
  state: State;
  selection: SelectionState;
  composeEntity: Partial<ComposeEntity>;
};

type CompositeEntityHandlerProps = {
  composeEntityType: string;
  command: string;
  getComposeEntityData: (
    params: GetComposeEntityDataParams,
  ) => Omit<ComposeEntity, "key" | "type">;
  stackable?: boolean;
};

export const compositeEntityHandler = ({
  getComposeEntityData,
  composeEntityType,
  command,
  stackable = false,
}: CompositeEntityHandlerProps) => {
  const addEntity = (state: State, data?: any) => {
    const { editorState, setEditorState } = state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const input = {
      ...getComposeEntityData({
        state,
        selection,
        composeEntity: data,
      }),
      type: composeEntityType,
    };

    const newComposeEntity = {
      ...input,
      key: genComposeEntityKey(input),
    };

    const nextContentState = applyComposeEntity(
      contentState,
      selection,
      newComposeEntity,
      stackable,
    );

    setEditorState(
      EditorState.forceSelection(
        EditorState.push(editorState, nextContentState, "apply-entity"),
        selection,
      ),
    );
    return nextContentState;
  };
  const removeEntity = (state: State, composeEntity: ComposeEntity) => {
    if (!composeEntity) return false;
    const { editorState, setEditorState } = state;
    const composeEntitySelection = getComposeEntitySelections(
      state,
      composeEntity.key,
    );
    if (!composeEntitySelection) return false;
    let contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const hasSelection =
      selection.getAnchorOffset() !== selection.getFocusOffset();

    const entityKeysMapping: { [key: string]: string | null } = {};

    const fragment = buildFragment(
      contentState,
      composeEntitySelection,
      ({ char, entityKey, block, index }) => {
        if (!entityKey || block.getType() === "atomic") return char;
        const entity = contentState.getEntity(entityKey);
        const composeEntities = entity.getData().entities ?? [];
        const resultEntities = composeEntities.filter(
          ({ key }: ComposeEntity) => key !== composeEntity.key,
        );

        if (hasSelection) {
          if (
            index === composeEntitySelection.getStartOffset() &&
            selection.getStartOffset() > index
          ) {
            const left = SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: index,
              focusOffset: selection.getStartOffset(),
            });
            const data = getComposeEntityData({
              state,
              selection: left,
              composeEntity,
            });
            contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
              entities: [...resultEntities, { ...composeEntity, ...data }],
            });
            entityKeysMapping[entityKey] =
              contentState.getLastCreatedEntityKey();
          }

          if (
            index === selection.getStartOffset() &&
            block.getKey() === selection.getStartKey()
          ) {
            if (resultEntities.length) {
              contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
                entities: resultEntities,
              });
              entityKeysMapping[entityKey] =
                contentState.getLastCreatedEntityKey();
            } else {
              entityKeysMapping[entityKey] = null;
            }
          }

          if (
            index === selection.getEndOffset() &&
            block.getKey() === selection.getEndKey()
          ) {
            const right = SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: index,
              focusOffset: composeEntitySelection.getEndOffset(),
            });
            const data = getComposeEntityData({
              state,
              selection: right,
              composeEntity,
            });
            const input = { ...data, type: composeEntityType };
            const key = genComposeEntityKey(input);
            contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
              entities: [...resultEntities, { ...input, key }],
            });
            entityKeysMapping[entityKey] =
              contentState.getLastCreatedEntityKey();
          }

          if (
            entityKeysMapping[entityKey] === undefined &&
            resultEntities.length &&
            inSelection(selection, index)
          ) {
            contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
              entities: resultEntities,
            });
            entityKeysMapping[entityKey] =
              contentState.getLastCreatedEntityKey();
          }

          if (!resultEntities.length && inSelection(selection, index)) {
            entityKeysMapping[entityKey] = null;
          }
        } else {
          if (entityKeysMapping[entityKey] === undefined) {
            if (resultEntities.length) {
              contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
                entities: resultEntities,
              });
              entityKeysMapping[entityKey] =
                contentState.getLastCreatedEntityKey();
            } else {
              entityKeysMapping[entityKey] = null;
            }
          }
        }

        const binding = entityKeysMapping[entityKey];

        if (binding !== undefined) {
          return entityKey === binding
            ? char
            : CharacterMetadata.applyEntity(char, binding);
        }

        return char;
      },
    );

    const nextContentState = Modifier.replaceWithFragment(
      contentState,
      composeEntitySelection,
      fragment,
    );

    setEditorState(
      EditorState.forceSelection(
        EditorState.push(editorState, nextContentState, "apply-entity"),
        hasSelection ? selection : composeEntitySelection,
      ),
    );
    return true;
  };
  return {
    handleEntityKeyCommand: ({ state, cmd }: { state: State; cmd: string }) => {
      if (cmd === command) {
        const entityKey = getAnchorEntityKey(state);
        const existingEntities = getAnchorComposeEntity(
          composeEntityType,
          state,
        );
        if (
          !stackable &&
          entityKey &&
          existingEntities?.length &&
          existingEntities.some(({ type }) => type === composeEntityType)
        ) {
          const [existingEntity] = existingEntities;
          if (!existingEntity) return;
          removeEntity(state, existingEntity);
        } else {
          addEntity(state);
        }
        return true;
      }
      return undefined;
    },
    removeEntity,
    addEntity,
  };
};

export const mergeCompositeEntityData = (
  state: State,
  composeEntityKey: string,
  selection: SelectionState,
  data: any,
): ContentState => {
  let contentState = state.editorState.getCurrentContent();
  const entityKeysMapping: { [key: string]: string | null } = {};
  const fragment = buildFragment(
    contentState,
    selection,
    ({ char, entityKey, block }) => {
      if (block.getType() === "atomic") return char;
      if (!entityKeysMapping[entityKey]) {
        const entity = contentState.getEntity(entityKey);
        const composeEntities: ComposeEntity[] =
          entity.getData().entities ?? [];
        const resultEntities = composeEntities.filter(
          ({ key }) => key !== composeEntityKey,
        );
        const toMerge = composeEntities.find(
          ({ key }) => key === composeEntityKey,
        );
        contentState = contentState.replaceEntityData(entityKey, {
          entities: [...resultEntities, { ...toMerge, ...data }],
        });
        entityKeysMapping[entityKey] = entityKey;
      }
      return char;
    },
  );
  return Modifier.replaceWithFragment(contentState, selection, fragment);
};
