import { memo } from "react";

import { BooleanFilterField } from "@/containers/search/filterFields/BooleanFilterField";

const enumValues = [
  { value: true, label: "Autorisées sur le print" },
  { value: false, label: "Non-autorisées sur le print" },
];

const name = "printAuthorized";

const Filter = memo(() => {
  return (
    <BooleanFilterField
      name={name}
      label="Autorisation print"
      enum={enumValues}
    />
  );
});

export const PrintAuthorizedFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
