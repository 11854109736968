export default [
  {
    name: "Communs",
    characters: [
      {
        name: "Espace fine insécable",
        char: "\u202F",
        display: "⏑",
      },
      {
        name: "Espace insécable",
        char: "\u00A0",
        display: "␣",
      },
      {
        name: "Guillemet français ouvrant",
        char: "«",
      },
      {
        name: "Guillemet français fermant",
        char: "»",
      },
      {
        name: "Guillemet anglais ouvrant",
        char: "“",
      },
      {
        name: "Guillemet anglais fermant",
        char: "”",
      },
      {
        name: "Apostrophe",
        char: "’",
      },
      {
        name: "Apostrophe ouvrant",
        char: "‘",
      },
      {
        name: "Double prime",
        char: "″",
      },
      {
        name: "Dollar",
        char: "$",
      },
      {
        name: "Euro",
        char: "€",
      },
      {
        name: "Tiret",
        char: "–",
      },
      {
        name: "Trait d’union",
        char: "-",
      },
      {
        name: "Moins",
        char: "−",
      },
      {
        name: "Underscore",
        char: "_",
      },
      {
        name: "Multiplier ",
        char: "×",
      },
      {
        name: "Degré",
        char: "º",
      },
      {
        name: "Supérieur ou égal",
        char: "≥",
      },
      {
        name: "Inférieur ou égal",
        char: "≤",
      },
      {
        name: "e dans l'a",
        char: "æ",
      },
      {
        name: "e dans l'o",
        char: "œ",
      },
      {
        name: "E dans l'o majuscule",
        char: "Œ",
      },
      {
        name: "y tréma",
        char: "ÿ",
      },
      {
        name: "Tilde ",
        char: "~",
      },
      {
        name: "n tilde ",
        char: "ñ",
      },
      {
        name: "Points de suspension",
        char: "…",
      },
      {
        name: "Copyright",
        char: "©",
      },
      {
        name: "Pour mille",
        char: "‰",
      },
      {
        name: "Exposant 1",
        char: "¹",
      },
      {
        name: "Exposant 2",
        char: "²",
      },
      {
        name: "Exposant 3",
        char: "³",
      },
      {
        name: "Un quart",
        char: "¼",
      },
      {
        name: "Un demi",
        char: "½",
      },
      {
        name: "Trois quarts",
        char: "¾",
      },
      {
        name: "Accent aigu",
        char: "´",
      },
      {
        name: "Point médian",
        char: "·",
      },
      {
        name: "Cédille",
        char: "¸",
      },
      {
        name: "Point d'interrogation inversé",
        char: "¿",
      },
      {
        name: "Point d'exclamation inversé",
        char: "¡",
      },
      {
        name: "A majuscule accent grave",
        char: "À",
      },
      {
        name: "A majuscule accent aigu",
        char: "Á",
      },
      {
        name: "A majuscule accent circonflexe",
        char: "Â",
      },
      {
        name: "E dans l'A majuscule",
        char: "Æ",
      },
      {
        name: "a minuscule accent circonflexe",
        char: "â",
      },
      {
        name: "a minuscule tréma",
        char: "ä",
      },
      {
        name: "C cédille majuscule",
        char: "Ç",
      },
      {
        name: "c cédille minuscule",
        char: "ç",
      },
      {
        name: "E majuscule accent grave",
        char: "È",
      },
      {
        name: "E majuscule accent aigu",
        char: "É",
      },
      {
        name: "E majuscule accent circonflexe",
        char: "Ê",
      },
      {
        name: "E majuscule accent tréma",
        char: "Ë",
      },
      {
        name: "e minuscule accent grave",
        char: "è",
      },
      {
        name: "e minuscule accent aigu",
        char: "é",
      },
      {
        name: "e minuscule accent circonflexe",
        char: "ê",
      },
      {
        name: "e minuscule tréma",
        char: "ë",
      },
      {
        name: "i minuscule tréma",
        char: "ï",
      },
      {
        name: "N majuscule avec tilde",
        char: "Ñ",
      },
      {
        name: "O majuscule accent grave",
        char: "Ò",
      },
      {
        name: "O majuscule accent aigu",
        char: "Ó",
      },
      {
        name: "O majuscule accent circonflexe",
        char: "Ô",
      },
      {
        name: "O majuscule avec tilde",
        char: "Õ",
      },
      {
        name: "O majuscule barré",
        char: "Ø",
      },
      {
        name: "o minuscule accent grave",
        char: "ò",
      },
      {
        name: "o minuscule circonflexe",
        char: "ô",
      },
      {
        name: "o minuscule tréma",
        char: "ö",
      },
      {
        name: "o minuscule islandais",
        char: "ð",
      },
      {
        name: "S dur allemand",
        char: "ß",
      },
      {
        name: "U majuscule accent circonflexe",
        char: "Û",
      },
      {
        name: "U majuscule tréma",
        char: "Ü",
      },
      {
        name: "U minuscule circonflexe",
        char: "û",
      },
      {
        name: "U minuscule tréma",
        char: "ü",
      },
      {
        name: "Crochet ouvrant",
        char: "[",
      },
      {
        name: "Crochet fermant",
        char: "]",
      },
      {
        name: "Accolade ouvrante",
        char: "{",
      },
      {
        name: "Accolade fermante",
        char: "}",
      },
      {
        name: "Accent circonflexe",
        char: "^",
      },
      {
        name: "Accent grave",
        char: "`",
      },
      {
        name: "Barre verticale",
        char: "|",
      },
      {
        name: "Marque commerciale (trade mark)",
        char: "™",
      },
      {
        name: "Flèche noire pointant vers la droite",
        char: "►",
      },
      {
        name: "Gros point médian",
        char: "•",
      },
      {
        name: "Flèche vers la droite",
        char: "→",
      },
      {
        name: "Double flèche vers la droite",
        char: "⇒",
      },
      {
        name: "Double flèche vers la gauche",
        char: "⇔",
      },
      {
        name: "Carreau noir",
        char: "♦",
      },
      {
        name: "Presque égal",
        char: "≈",
      },
      {
        name: "Tiret cadratin",
        char: "—",
      },
      {
        name: "Symbole Cent",
        char: "¢",
      },
      {
        name: "Symbole Livre Sterling",
        char: "£",
      },
      {
        name: "Symbole monétaire",
        char: "¤",
      },
      {
        name: "Symbole Yen",
        char: "¥",
      },
      {
        name: "Barre verticale scindée",
        char: "¦",
      },
      {
        name: "Section",
        char: "§",
      },
      {
        name: "Tréma",
        char: "¨",
      },
      {
        name: "Indicateur ordinal féminin",
        char: "ª",
      },
      {
        name: "Crochet de négation",
        char: "¬",
      },
      {
        name: "Marque déposée",
        char: "®",
      },
      {
        name: "Macron",
        char: "¯",
      },
      {
        name: "Degré",
        char: "°",
      },
      {
        name: "Omicron",
        char: "µ",
      },
      {
        name: "Paragraphe",
        char: "¶",
      },
      {
        name: "A majuscule avec caron",
        char: "Ã",
      },
      {
        name: "A majuscule tréma",
        char: "Ä",
      },
      {
        name: "A majuscule avec un rond au-dessus",
        char: "Å",
      },
      {
        name: "I majuscule accent grave",
        char: "Ì",
      },
      {
        name: "I majuscule accent aigu",
        char: "Í",
      },
      {
        name: "I majuscule accent circonflexe",
        char: "Î",
      },
      {
        name: "I majuscule tréma",
        char: "Ï",
      },
      {
        name: "Lettre majuscule islandaise ED",
        char: "Ð",
      },
      {
        name: "O majuscule tréma",
        char: "Ö",
      },
      {
        name: "U majuscule accent grave",
        char: "Ù",
      },
      {
        name: "U majuscule accent aigu",
        char: "Ú",
      },
      {
        name: "Y majuscule accent aigu",
        char: "Ý",
      },
      {
        name: "W majuscule accent circonflexe",
        char: "Ŵ",
      },
      {
        name: "Y majuscule accent circonflexe",
        char: "Ŷ",
      },
      {
        name: "Lettre islandaise Thorn majuscule",
        char: "Þ",
      },
      {
        name: "a minuscule accent grave",
        char: "à",
      },
      {
        name: "a minuscule accent aigu",
        char: "á",
      },
      {
        name: "a minuscule avec caron",
        char: "ã",
      },
      {
        name: "a minuscule avec un rond au-dessus",
        char: "å",
      },
      {
        name: "i minuscule accent grave",
        char: "ì",
      },
      {
        name: "i minuscule accent aigu",
        char: "í",
      },
      {
        name: "i minuscule accent circonflexe",
        char: "î",
      },
      {
        name: "o minuscule accent aigu",
        char: "ó",
      },
      {
        name: "o minuscule avec caron",
        char: "õ",
      },
      {
        name: "Division",
        char: "÷",
      },
      {
        name: "o minuscule barré",
        char: "ø",
      },
      {
        name: "u minuscule accent grave",
        char: "ù",
      },
      {
        name: "u minuscule accent aigu",
        char: "ú",
      },
      {
        name: "y minuscule accent aigu",
        char: "ý",
      },
      {
        name: "w minuscule accent circonflexe",
        char: "ŵ",
      },
      {
        name: "y minuscule accent circonflexe",
        char: "ŷ",
      },
      {
        name: "Lettre islandaise thorn minuscule",
        char: "þ",
      },
    ],
  },
  {
    name: "Exposants",
    characters: [
      {
        name: "Exposant e minuscule",
        char: "ᵉ",
      },
      {
        name: "Exposant E majuscule",
        char: "ᴱ",
      },
      {
        name: "Exposant o minuscule",
        char: "ᵒ",
      },
      {
        name: "Exposant r minuscule",
        char: "ʳ",
      },
      {
        name: "Exposant s minuscule",
        char: "ˢ",
      },
      {
        name: "Exposant 0",
        char: "⁰",
      },
      {
        name: "Exposant 1",
        char: "¹",
      },
      {
        name: "Exposant 2",
        char: "²",
      },
      {
        name: "Exposant 3",
        char: "³",
      },
      {
        name: "Exposant 4",
        char: "⁴",
      },
      {
        name: "Exposant 5",
        char: "⁵",
      },
      {
        name: "Exposant 6",
        char: "⁶",
      },
      {
        name: "Exposant 7",
        char: "⁷",
      },
      {
        name: "Exposant 8",
        char: "⁸",
      },
      {
        name: "Exposant 9",
        char: "⁹",
      },
      {
        name: "Exposant +",
        char: "⁺",
      },
      {
        name: "Exposant -",
        char: "⁻",
      },
      {
        name: "Exposant =",
        char: "⁼",
      },
      {
        name: "Exposant (",
        char: "⁽",
      },
      {
        name: "Exposant )",
        char: "⁾",
      },
    ],
  },
  {
    name: "Indices",
    characters: [
      {
        name: "Indice e minuscule",
        char: "ₑ",
      },
      {
        name: "Indice 0",
        char: "₀",
      },
      {
        name: "Indice 1",
        char: "₁",
      },
      {
        name: "Indice 2",
        char: "₂",
      },
      {
        name: "Indice 3",
        char: "₃",
      },
      {
        name: "Indice 4",
        char: "₄",
      },
      {
        name: "Indice 5",
        char: "₅",
      },
      {
        name: "Indice 6",
        char: "₆",
      },
      {
        name: "Indice 7",
        char: "₇",
      },
      {
        name: "Indice 8",
        char: "₈",
      },
      {
        name: "Indice 9",
        char: "₉",
      },
      {
        name: "Indice +",
        char: "₊",
      },
      {
        name: "Indice -",
        char: "₋",
      },
      {
        name: "Indice =",
        char: "₌",
      },
      {
        name: "Indice (",
        char: "₍",
      },
      {
        name: "Indice )",
        char: "₎",
      },
    ],
  },
  {
    name: "Grecs",
    characters: [
      {
        name: "Alpha minuscule",
        char: "α",
      },
      {
        name: "Alpha majuscule",
        char: "A",
      },
      {
        name: "Beta minuscule",
        char: "β",
      },
      {
        name: "Beta majuscule",
        char: "B",
      },
      {
        name: "Gamma minuscule",
        char: "γ",
      },
      {
        name: "Gamma majuscule",
        char: "Γ",
      },
      {
        name: "Delta minuscule",
        char: "δ",
      },
      {
        name: "Delta majuscule",
        char: "Δ",
      },
      {
        name: "Epsilon minuscule",
        char: "ε",
      },
      {
        name: "Epsilon majuscule",
        char: "E",
      },
      {
        name: "Dzêta minuscule",
        char: "ζ",
      },
      {
        name: "Dzêta majuscule",
        char: "Ζ",
      },
      {
        name: "Eta minuscule",
        char: "η",
      },
      {
        name: "Eta majuscule",
        char: "Η",
      },
      {
        name: "Theta minuscule",
        char: "θ",
      },
      {
        name: "Theta majuscule",
        char: "Θ",
      },
      {
        name: "Iota minuscule",
        char: "ι",
      },
      {
        name: "Iota majuscule",
        char: "Ι",
      },
      {
        name: "Kappa minuscule",
        char: "κ",
      },
      {
        name: "Kappa majuscule",
        char: "Κ",
      },
      {
        name: "Lambda minuscule",
        char: "λ",
      },
      {
        name: "Lambda majuscule",
        char: "Λ",
      },
      {
        name: "Mu minuscule",
        char: "μ",
      },
      {
        name: "Mu majuscule",
        char: "Μ",
      },
      {
        name: "Nu minuscule",
        char: "ν",
      },
      {
        name: "Nu majuscule",
        char: "Ν",
      },
      {
        name: "Xi minuscule",
        char: "ξ",
      },
      {
        name: "Xi majuscule",
        char: "Ξ",
      },
      {
        name: "Ou minuscule",
        char: "ο",
      },
      {
        name: "Ou majuscule",
        char: "Ο",
      },
      {
        name: "Pi minuscule",
        char: "π",
      },
      {
        name: "Pi majuscule",
        char: "Π",
      },
      {
        name: "Ro minuscule",
        char: "ρ",
      },
      {
        name: "Ro majuscule",
        char: "Ρ",
      },
      {
        name: "Sigma minuscule",
        char: "σ",
      },
      {
        name: "Sigma majuscule",
        char: "Σ",
      },
      {
        name: "Tau minuscule",
        char: "τ",
      },
      {
        name: "Tau majuscule",
        char: "Τ",
      },
      {
        name: "Upsilon minuscule",
        char: "υ",
      },
      {
        name: "Upsilon majuscule",
        char: "Υ",
      },
      {
        name: "Phi minuscule",
        char: "φ",
      },
      {
        name: "Phi majuscule",
        char: "Φ",
      },
      {
        name: "Khi minuscule",
        char: "χ",
      },
      {
        name: "Khi majuscule",
        char: "Χ",
      },
      {
        name: "Psi minuscule",
        char: "ψ",
      },
      {
        name: "Psi majuscule",
        char: "Ψ",
      },
      {
        name: "Omega minuscule",
        char: "ω",
      },
      {
        name: "Omega majuscule",
        char: "Ω",
      },
    ],
  },
];
