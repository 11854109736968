/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql, useApolloClient } from "@apollo/client";
import { forwardRef, memo } from "react";
import { Clickable } from "swash/Clickable";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";
import { useComboboxStore } from "swash/v2/Combobox";
import {
  RemoteSelectList,
  RemoteSelectState,
  useRemoteSelectState,
} from "swash/v2/RemoteSelect";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { mergeConnections, useSafeQuery } from "@/containers/Apollo";

export const SectionFragment = gql`
  fragment ArticlesSectionsSelect_section on Section {
    id
    name
  }
`;

export const ArticleFragment = gql`
  fragment ArticleSectionsSelect_article on Article {
    id
    sections {
      ...ArticlesSectionsSelect_section
    }
  }

  ${SectionFragment}
`;

const SectionsQuery = gql`
  query ArticleSectionsSelect_sections($search: String, $offset: Int) {
    sections(order: popularity, offset: $offset, where: { search: $search }) {
      nodes {
        ...ArticlesSectionsSelect_section
      }
      pageInfo {
        hasMore
      }
      totalCount
    }
  }

  ${SectionFragment}
`;

export type ArticleSection = {
  id: number;
  name: string;
};

type SectionQueryVariables = {
  search: string;
};

type SectionQueryData = {
  sections: {
    pageInfo: {
      hasMore: boolean;
    };
    totalCount: number;
    nodes: ArticleSection[];
  };
};

export type ArticleSectionsSelectStateProps = {
  value: ArticleSection[];
  onChange: (value: ArticleSection[]) => void;
  required?: boolean;
};

export const useArticleSectionsSelectState = (
  props: ArticleSectionsSelectStateProps,
): RemoteSelectState<ArticleSection> => {
  const combobox = useComboboxStore();
  const comboboxValue = combobox.useState("value");
  const client = useApolloClient();
  const queryResult = useSafeQuery<SectionQueryData, SectionQueryVariables>(
    SectionsQuery,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: { search: comboboxValue },
    },
  );
  const data = queryResult.data ?? queryResult.previousData;

  const enumSelect = useRemoteSelectState<ArticleSection, ArticleSection[]>({
    value: props.value,
    onChange: props.onChange,
    data: data
      ? {
          items: data.sections.nodes ?? ([] as ArticleSection[]),
          hasMore: data.sections.pageInfo.hasMore ?? false,
          totalCount: data.sections.totalCount ?? 0,
        }
      : null,
    combobox,
    loading: queryResult.loading,
    fetchMore: (previousData) => {
      queryResult.fetchMore({
        variables: { offset: previousData.items.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!previousResult.sections) return previousResult;
          return {
            ...previousResult,
            sections: mergeConnections(
              previousResult.sections,
              fetchMoreResult.sections,
            ),
          };
        },
      });
    },
    getItem: (id) => {
      const value = client.readFragment({
        id: `Section:${id}`,
        fragment: SectionFragment,
      });
      if (!value) {
        throw new Error("No section found");
      }
      return value;
    },
    labelSelector: (section) => section.name,
    valueSelector: (section) => section.id.toString(),
    required: props.required ?? false,
  });
  return enumSelect;
};

useArticleSectionsSelectState.fragments = {
  article: ArticleFragment,
};

export type ArticleSectionsSelectListProps = {
  state: RemoteSelectState<ArticleSection>;
};

export const ArticleSectionsSelectList = (
  props: ArticleSectionsSelectListProps,
) => {
  return <RemoteSelectList placeholder="Services..." state={props.state} />;
};

type ArticleSectionsButton = {
  sections: ArticleSection[];
  placeholder?: string;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  invalid?: boolean;
  disabled?: boolean;
};

export const ArticleSectionsButton = memo(
  forwardRef<HTMLButtonElement, ArticleSectionsButton>(
    ({ sections, placeholder, invalid, ...props }, ref) => {
      const hasSection = sections.length > 0;

      return (
        <Clickable
          ref={ref}
          className={cn(
            "h-full p-1 text-sm",
            !hasSection && "text-grey-on",
            invalid && "border border-danger-border",
          )}
          {...props}
        >
          {hasSection
            ? sections.map((s) => s.name).join(", ")
            : placeholder ?? "Aucun"}
        </Clickable>
      );
    },
  ),
);

type ArticleSectionsSelectorProps = {
  placeholder?: ArticleSectionsButton["placeholder"];
  invalid?: ArticleSectionsButton["invalid"];
  tooltip?: string;
} & ArticleSectionsSelectStateProps;

export const ArticleSectionsSelector = ({
  placeholder,
  tooltip,
  invalid,
  ...props
}: ArticleSectionsSelectorProps) => {
  const state = useArticleSectionsSelectState(props);
  const open = state.select.useState("open");

  return (
    <span className="contents" onClick={(event) => event.stopPropagation()}>
      <Tooltip tooltip={open ? null : tooltip ?? "Éditer les services"}>
        <SelectButton asChild store={state.select}>
          <ArticleSectionsButton
            sections={props.value}
            placeholder={placeholder}
            invalid={invalid}
          />
        </SelectButton>
      </Tooltip>
      <SelectPopover store={state.select} combobox modal>
        <ArticleSectionsSelectList state={state} />
      </SelectPopover>
    </span>
  );
};
