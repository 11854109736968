import { forwardRef } from "react";

import { Textarea } from "@/components/controls/Textarea";
import { FieldControl } from "@/components/fields/FieldControl";

import { FieldState } from "./FieldState";
import { useTextInputField } from "./TextInputField";

export const useTextareaField = useTextInputField;

type TextareaFieldProps = {
  state: FieldState;
};

export const TextareaField = forwardRef<HTMLElement, TextareaFieldProps>(
  ({ ...props }, ref) => {
    return <FieldControl ref={ref} as={Textarea} {...props} />;
  },
);
if (process.env["NODE_ENV"] !== "production") {
  TextareaField.displayName = "TextareaField";
}
