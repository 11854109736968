import * as React from "react";
import { forwardRef } from "react";

const SvgChevronDoubleForwardOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5 5.25 11.75 12 5 18.75M12 5.25 18.75 12 12 18.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDoubleForwardOutline);
export default ForwardRef;
