/* eslint-disable no-param-reassign */

export const INLINE_STYLE = "SUBSCRIPT";

export const allowedInlineStyles = [INLINE_STYLE];
export const allowedAttributes = { sub: [] };

/** @type {import('../types').htmlToStyle} */
export const htmlToStyle = ({ element, getStyle }) => {
  const { tagName } = element;
  const checkHasSubStyle = () => {
    const styles = getStyle();
    const verticalAlign = styles["vertical-align"];
    return verticalAlign === "sub";
  };
  if (tagName === "sub" || checkHasSubStyle()) {
    return [INLINE_STYLE];
  }
  return [];
};
