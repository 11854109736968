import { frenchPreset, getChars, transform } from "@lemonde/typo-on-the-fly";

import { frenchifyHTML } from "@/components/rich-editor/convert/frenchifyHTML";
import { frenchifyText } from "@/components/rich-editor/convert/frenchifyText";
import { deleteCharacters } from "@/components/rich-editor/modifiers/deleteCharacters";
import { insertOrReplaceCharacters } from "@/components/rich-editor/modifiers/insertOrReplaceCharacters";

export const name = "typographic-rules";

const handledCharacters = getChars(frenchPreset);

export const handleBeforeInput = ({ setEditorState, editorState }, input) => {
  // For perf issue we don't want to trigger it if the input is not handled
  if (!handledCharacters.includes(input)) {
    return "not-handled";
  }

  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const position = selectionState.getStartOffset();
  const anchorKey = selectionState.getAnchorKey();
  const currentContentBlock = contentState.getBlockForKey(anchorKey);
  const text = currentContentBlock.getText();

  const result = transform(frenchPreset, { text, position, input });

  if (result === null) {
    return "not-handled";
  }

  let state = editorState;
  for (const transformation of result) {
    switch (transformation.type) {
      case "insert":
        state = insertOrReplaceCharacters(state, transformation.value);
        break;
      case "delete": {
        state = deleteCharacters(state, transformation.length);
        break;
      }
    }
  }
  setEditorState(state);
  return "handled";
};

export const transformPastedText = (text) => {
  if (text.length > 1) {
    return { transformedText: frenchifyText(text) };
  }
  return { transformedText: text };
};

export const transformPastedHTML = (ast) => frenchifyHTML(ast);
