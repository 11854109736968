import { expandedPublicationColumnsQuery } from "@/containers/hoc/store/columns";

const defaults = {
  expandedPublicationColumns: [],
};

const Mutation = {
  expandColumn: (_, { id, value }, { cache }) => {
    const { expandedPublicationColumns } = cache.readQuery({
      query: expandedPublicationColumnsQuery,
    });

    const updatedColumn = {
      __typename: "PublicationColumn",
      id,
      isExpanded: value,
    };

    cache.writeQuery({
      query: expandedPublicationColumnsQuery,
      data: {
        expandedPublicationColumns: [
          ...expandedPublicationColumns.filter(
            (expandedPublicationColumn) => expandedPublicationColumn.id !== id,
          ),
          updatedColumn,
        ],
      },
    });
    return null;
  },
};

export default { defaults, resolvers: { Mutation } };
