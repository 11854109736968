import { useEffect } from "react";

import { useLiveRef } from "./useLiveRef";

export function useEventListener(
  target: HTMLElement,
  event: keyof ElementEventMap,
  listener: (event: Event) => void,
  options?: boolean | AddEventListenerOptions,
) {
  const listenerRef = useLiveRef(listener);
  useEffect(() => {
    const listener = (event: Event) => {
      listenerRef.current(event);
    };

    target.addEventListener(event, listener, options);
    return () => {
      target.removeEventListener(event, listener, options);
    };
  }, [target, event, options, listenerRef]);
}
