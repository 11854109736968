import * as React from "react";
import { forwardRef } from "react";

const SvgChatbubbleDisabled = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.97 1.555c.39-.39.987-.427 1.332-.081l18.224 18.224c.346.345.31.942-.081 1.333-.39.39-.987.427-1.333.08L1.888 2.889c-.346-.346-.31-.942.081-1.333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.862 6.69A10.022 10.022 0 0 0 1.5 11.784c0 1.98.58 3.916 1.671 5.568l.009.013.022.032c.007.008.013.017.018.025l.016.023.018.026.01.019c.054.092.095.208.058.323l-.863 3.124A1.204 1.204 0 0 0 3.601 22.5a1.38 1.38 0 0 0 .49-.107l3.163-1.143.04-.016a.693.693 0 0 1 .524-.007l.027.01c.152.058.793.299 1.466.487.604.17 1.864.422 2.848.422 1.756 0 3.438-.426 4.928-1.23L2.862 6.69ZM20.18 18.353a10.45 10.45 0 0 1-1.406 1.422L4.05 5.05a10.555 10.555 0 0 1 1.463-1.366L20.18 18.352Zm1.127-1.702a10.385 10.385 0 0 0 .968-6.97 10.278 10.278 0 0 0-3.619-5.844A10.66 10.66 0 0 0 11.992 1.5c-1.677 0-3.29.38-4.738 1.097L21.307 16.65Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChatbubbleDisabled);
export default ForwardRef;
