import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { shallowEqual } from "./shallowEqual";
import { useLiveRef } from "./useLiveRef";

/**
 * @template T
 * @typedef {(a: T | undefined, b: T | undefined) => boolean} EqualityFn
 */

/**
 * @template TState
 */
export const createSelector = () => {
  const SelectorContext = createContext();

  /**
   * @param {object} props
   * @param {TState} props.state
   * @param {React.ReactNode} props.children
   */
  const Provider = ({ state, children }) => {
    const stateRef = useLiveRef(state);
    const getState = useCallback(() => stateRef.current, [stateRef]);

    const listenersRef = useRef([]);
    const publish = useCallback((state) => {
      listenersRef.current.forEach((listener) => {
        listener(state);
      });
    }, []);
    const subscribe = useCallback((callback) => {
      const listener = (keys) => callback(keys);
      listenersRef.current.push(listener);
      return () => {
        listenersRef.current = listenersRef.current.filter(
          (iListener) => iListener !== listener,
        );
      };
    }, []);

    useEffect(() => {
      publish(state);
    }, [publish, state]);

    const value = useMemo(
      () => ({ getState, subscribe }),
      [getState, subscribe],
    );

    return (
      <SelectorContext.Provider value={value}>
        {children}
      </SelectorContext.Provider>
    );
  };

  /**
   * @template TSelected
   * @param {(state: TState) => TSelected} selector
   * @param {EqualityFn<TSelected>} equalityFn
   * @returns {TSelected}
   */
  const useSelector = (selector, equalityFn = shallowEqual) => {
    const { subscribe, getState } = useContext(SelectorContext);
    const [value, setValue] = useState(() => selector(getState()));
    const refs = useLiveRef({ value, selector, equalityFn });
    React.useEffect(
      () =>
        subscribe((state) => {
          const { value, selector, equalityFn } = refs.current;
          const nextValue = selector(state);
          if (!equalityFn(value, nextValue)) {
            setValue(nextValue);
          }
        }),
      [subscribe, refs],
    );

    return value;
  };

  return { Provider, useSelector };
};
