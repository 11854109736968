import { gql } from "@apollo/client";
import moment from "moment";
import "moment/locale/fr";
import { PageLoader } from "swash/Loader";

import { Separator } from "@/components/Separator";
import { useSafeQuery } from "@/containers/Apollo";

import {
  AnalyticsDuration,
  AnalyticsGroup,
  AnalyticsList,
  AnalyticsListLabel,
  AnalyticsListTitle,
} from "./Group";

const SiriusAnalyticsQuery = gql`
  query SiriusAnalyticsQuery($articleId: Int!) {
    article(id: $articleId) {
      id
      alerts {
        nodes {
          id
          title
          publishedAt
        }
      }
    }
    tags(where: { homepage: true }) {
      nodes {
        id
        title
        stagingAreas(activatedOnly: true, acceptsArticleOnly: true) {
          id
          label
          articleExposurePeriods(articleId: $articleId) {
            from
            to
          }
        }
      }
    }
  }
`;

function formatAlertTime(date) {
  if (!date) return null;
  const format = getDisplayTimeFormat(date);

  return `Le ${moment(date).format(format)}`;
}

const currentYear = new Date().getFullYear();

const expositionTimeFormat = "DD/MM [à] HH[:]mm";
const expositionTimeFormatWithYear = "DD/MM/YY [à] HH[:]mm";
function getDisplayTimeFormat(time) {
  return currentYear === moment(time).year()
    ? expositionTimeFormat
    : expositionTimeFormatWithYear;
}

function formatExpositionsTime(articleExposurePeriod) {
  const { from, to } = articleExposurePeriod;

  const fromFormat = getDisplayTimeFormat(from);
  if (!to) {
    return `Depuis le ${moment(from).format(fromFormat)}`;
  }
  const toFormat = getDisplayTimeFormat(to);
  return `Du ${moment(from).format(fromFormat)} ${moment(to).format(
    `[au] ${toFormat}`,
  )}`;
}

const AlertAnalytics = ({ alerts }) => {
  return (
    <div className="pt-2">
      <AnalyticsListTitle title="Alertes" />
      <AnalyticsList
        items={alerts}
        field="publishedAt"
        format={formatAlertTime}
      />
    </div>
  );
};

const StagingAreaAnalytics = ({ stagingArea }) => {
  if (!stagingArea.articleExposurePeriods.length) return null;
  return (
    <div key={stagingArea.id} className="flex flex-col">
      <AnalyticsListLabel label={stagingArea.label} />
      <AnalyticsList
        items={stagingArea.articleExposurePeriods}
        format={formatExpositionsTime}
      />
    </div>
  );
};

const TagAnalytics = ({ tags }) => {
  return (
    <div className="flex flex-col gap-4 pt-4">
      {tags.map((tag) => (
        <div key={tag.id} className="flex flex-col gap-2">
          <AnalyticsListTitle
            title={tag.title}
            duration={<AnalyticsDuration list={tag.stagingAreas} />}
          />
          {tag.stagingAreas.map((stagingArea) => (
            <StagingAreaAnalytics
              key={stagingArea.label}
              stagingArea={stagingArea}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

function Expositions({ alerts, tags }) {
  return (
    <AnalyticsGroup title="Expositions">
      <AlertAnalytics alerts={alerts} />
      <Separator className="-mx-4 mt-2" />
      <TagAnalytics tags={tags} />
    </AnalyticsGroup>
  );
}

export function SiriusAnalytics({ articleId }) {
  const { data } = useSafeQuery(SiriusAnalyticsQuery, {
    variables: { articleId },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });
  if (!data) {
    return <PageLoader />;
  }

  return (
    <Expositions alerts={data.article.alerts.nodes} tags={data.tags.nodes} />
  );
}
