import { FormLabel } from "swash/form/FormLabel";

const StepIcon = (props) => (
  <div
    className="StepIcon flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-dusk-bg-strong pt-0.5 font-accent text-lg font-semibold text-white transition"
    {...props}
  />
);

const InnerStep = (props) => (
  <div className="security-auth-inner-step" {...props} />
);

export const Step = ({ num, icon, label, disabled, hidden, children }) => {
  return (
    <InnerStep data-disabled={disabled} data-hidden={hidden}>
      {icon ? icon : <StepIcon>{num}</StepIcon>}
      <div className="flex flex-col items-start">
        <FormLabel className="text-lg">{label}</FormLabel>
        <div>{children}</div>
      </div>
    </InnerStep>
  );
};
