import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useEventCallback } from "swash/utils/useEventCallback";

import { RemoteEditImageMultipleDialogProvider } from "./ImageEditMultipleDialog";

const ImageEditMultipleContext = createContext(null);

export const ImageEditMultipleProvider = ({ children, selectable = true }) => {
  const [imageIds, setImageIds] = useState([]);
  const [shiftKeyOn, setShiftKeyOn] = useState(false);

  const lastUnselectedImageIdRef = useRef();

  const handleMultiSelect = useCallback(
    ({ listImageIds, imageId, select }) => {
      if (shiftKeyOn && imageIds.length) {
        const lastUnselectedImageId = lastUnselectedImageIdRef.current;
        const imageIndex = listImageIds.indexOf(imageId);
        const lastSelectedImageId = imageIds[imageIds.length - 1];
        const lastImageIndex = select
          ? listImageIds.indexOf(lastSelectedImageId)
          : listImageIds.indexOf(lastUnselectedImageId ?? lastSelectedImageId);
        const forward = imageIndex > lastImageIndex;
        const start = forward ? lastImageIndex : imageIndex;
        const end = forward ? imageIndex + 1 : lastImageIndex + 1;
        const selectedImageIds = forward
          ? listImageIds.slice(start, end)
          : listImageIds.slice(start, end).reverse();

        if (select) {
          setImageIds((imageIds) => [
            ...new Set([...imageIds, ...selectedImageIds]),
          ]);
        } else {
          setImageIds((imageIds) =>
            imageIds.filter(
              (id) => !listImageIds.slice(start, end).includes(id),
            ),
          );
        }
      } else {
        if (select) {
          setImageIds((imageIds) => [...imageIds, imageId]);
        } else {
          setImageIds((imageIds) => imageIds.filter((id) => id !== imageId));
        }
      }
      lastUnselectedImageIdRef.current = select ? null : imageId;
    },
    [imageIds, shiftKeyOn],
  );

  const handleKeyEvent = useEventCallback((event) => {
    event.stopPropagation();
    if (event.keyCode === 16 || event.charCode === 16) {
      setShiftKeyOn(event.type === "keydown");
    }
  });

  useEffect(() => {
    if (!selectable) return;
    document.addEventListener("keydown", handleKeyEvent);
    document.addEventListener("keyup", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
      document.removeEventListener("keyup", handleKeyEvent);
    };
  }, [handleKeyEvent, selectable]);

  useEffect(() => {
    if (!imageIds.length) {
      lastUnselectedImageIdRef.current = null;
    }
  }, [imageIds]);

  return (
    <ImageEditMultipleContext.Provider
      value={{
        imageIds,
        setImageIds,
        ...(selectable ? { handleMultiSelect } : {}),
      }}
    >
      <RemoteEditImageMultipleDialogProvider>
        {children}
      </RemoteEditImageMultipleDialogProvider>
    </ImageEditMultipleContext.Provider>
  );
};

export const useImageEditMultiple = () => {
  return useContext(ImageEditMultipleContext);
};
