import { gql } from "@apollo/client";
import { createContext, useContext } from "react";

import { ArticleList } from "@/containers/article/list/ArticleList";
import {
  ArticleFragment,
  getUsedInCells,
} from "@/containers/article/list/usedIn";

export const name = "Articles";

const ArticleUpdatedSubscription = gql`
  subscription ArticleUsages_articleUpdated($ids: [Int!]!) {
    articleUpdated(where: { id: { in: $ids } }) {
      id
      ...ListUsedIn_article
    }
  }
  ${ArticleFragment}
`;

const ResourceUsedInContext = createContext();
const ResourceUsedInContextProvider = ({ resource, children }) => {
  return (
    <ResourceUsedInContext.Provider value={resource}>
      {children}
    </ResourceUsedInContext.Provider>
  );
};

export const useResourceUsedIn = () => {
  return useContext(ResourceUsedInContext);
};

export const List = ({
  resource,
  hasMore,
  loadMore,
  loading,
  nodes,
  totalCount,
}) => {
  const cells = getUsedInCells(resource);

  return (
    <ResourceUsedInContextProvider resource={resource}>
      <ArticleList
        readOnly
        portal
        cells={cells}
        loadMore={loadMore}
        loading={loading}
        nodes={nodes}
        hasMore={hasMore}
        totalCount={totalCount}
        subscription={ArticleUpdatedSubscription}
      />
    </ResourceUsedInContextProvider>
  );
};

export const UsedInArticleFragment = gql`
  fragment UsedInArticleFragment on Article {
    id
    ...ListUsedIn_article
  }

  ${ArticleFragment}
`;
