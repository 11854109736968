import type { MutableState, Mutator, Tools } from "final-form";

import type { ArticleExposure } from "@/containers/article/ArticleExposureSelect";

type InputValue = [ArticleExposure[]];

const FIELDS = [
  "planning",
  "publication",
  "correlatedPlannedDateToArticle",
] as const;

export const syncArticleExposures: Mutator<any> = (
  [value]: InputValue,
  state: MutableState<any>,
  { changeValue }: Tools<any>,
) => {
  changeValue(state, "articleExposures", (oldValue: ArticleExposure[]) => {
    const suggestedExposures = value.filter(
      ({ suggested, selected, fulfilled }) =>
        suggested && !selected && !fulfilled,
    );
    if (!suggestedExposures.length) return oldValue;
    suggestedExposures.forEach((suggestedExposure) => {
      const oldValueIndex = oldValue.findIndex(
        (v) => v.gid === suggestedExposure.gid,
      );
      if (oldValueIndex < 0) return;
      const oldArticleExposure = oldValue[oldValueIndex]!;

      const updateArticleExposure = FIELDS.reduce((initialValues, field) => {
        return {
          ...initialValues,
          [field]: suggestedExposure[field],
        } as ArticleExposure;
      }, oldArticleExposure);

      oldValue[oldValueIndex] = updateArticleExposure;
    });

    return oldValue;
  });
};
