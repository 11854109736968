import {
  Combobox as AriakitCombobox,
  ComboboxItem as AriakitComboboxItem,
  ComboboxItemCheck as AriakitComboboxItemCheck,
  ComboboxItemCheckProps as AriakitComboboxItemCheckProps,
  ComboboxItemProps as AriakitComboboxItemProps,
  ComboboxPopover as AriakitComboboxPopover,
  ComboboxPopoverProps as AriakitComboboxPopoverProps,
  ComboboxProps as AriakitComboboxProps,
  ComboboxSeparator as AriakitComboboxSeparator,
  ComboboxSeparatorProps as AriakitComboboxSeparatorProps,
  ComboboxStore,
  ComboboxStoreProps,
  useComboboxStore as useAriakitComboboxStore,
} from "@ariakit/react-legacy";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { Button, ButtonProps } from "../Button";
import { IoChevronDown, IoChevronUp, IoCloseCircleOutline } from "../Icon";
import {
  ItemClassNameProps,
  ListSeparatorClassNameProps,
  getItemClassName,
  getListSeparatorClassName,
} from "../Menu";
import { PopoverCard } from "../Popover";
import { SelectMarker } from "./Select";
import { InputClassNameProps, getInputClassName } from "./TextInput";

export { ComboboxList } from "ariakit-deprecated/combobox";
export type { ComboboxStore, ComboboxStoreProps } from "@ariakit/react-legacy";

export const useComboboxStore = ({ ...props }: ComboboxStoreProps = {}) => {
  return useAriakitComboboxStore(props);
};

export type ComboboxItemProps = AriakitComboboxItemProps & ItemClassNameProps;

export const ComboboxItem = forwardRef<HTMLDivElement, ComboboxItemProps>(
  ({ focusOnHover = true, variant, className, ...props }, ref) => {
    const itemClassName = getItemClassName({ variant, className });
    return (
      <AriakitComboboxItem
        ref={ref}
        focusOnHover={focusOnHover}
        className={itemClassName}
        {...props}
      />
    );
  },
);

export type ComboboxItemCheckProps = AriakitComboboxItemCheckProps & {
  value: string;
};

export const ComboboxItemCheck = forwardRef<
  HTMLDivElement,
  ComboboxItemCheckProps
>(({ store, value, ...props }, ref) => {
  const selectedValue = store?.useState("selectedValue");
  const multi = Array.isArray(selectedValue);
  const checked = multi
    ? selectedValue?.includes(value)
    : selectedValue === value;
  return (
    <AriakitComboboxItemCheck ref={ref} {...props} checked>
      <SelectMarker multi={multi} checked={checked} />
    </AriakitComboboxItemCheck>
  );
});

export interface ComboboxArrowProps {
  open: boolean;
}

export const ComboboxArrow: React.FC<ComboboxArrowProps> = (props) => {
  return (
    <div className="pointer-events-none">
      {props.open ? <IoChevronUp /> : <IoChevronDown />}
    </div>
  );
};

export interface ComboboxClearProps extends ButtonProps {
  store: ComboboxStore;
  clearable?: boolean;
}

export const ComboboxClear = forwardRef<HTMLButtonElement, ComboboxClearProps>(
  (
    { store, "aria-label": ariaLabel = "Vider", clearable = true, ...props },
    ref,
  ) => {
    const value = store.useState("value");
    const open = store.useState("open");
    const empty = !value || (Array.isArray(value) && value.length === 0);
    if (empty || !clearable) {
      return <ComboboxArrow open={open} />;
    }
    return (
      <Button
        ref={ref}
        appearance="text"
        scale="xs"
        iconOnly
        asChild
        onMouseDown={(event) => {
          event.stopPropagation();
          event.preventDefault();
          store.setValue("");
          store.hide();
        }}
        className="-my-2 -mr-1"
        aria-label={ariaLabel}
        {...props}
      >
        <div>
          <IoCloseCircleOutline />
        </div>
      </Button>
    );
  },
);

export interface ComboboxProps
  extends AriakitComboboxProps<"input">,
    Omit<InputClassNameProps, "placeholder"> {
  raw?: boolean;
  clearLabel?: string;
  store: ComboboxStore;
  showClearButton?: boolean;
}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      className,
      scale,
      raw = true,
      clearLabel,
      children,
      showClearButton,
      ...props
    },
    ref,
  ) => {
    const value = props.store.useState("value");
    const placeholder = value === "" || value?.length === 0;
    const inputClassName = getInputClassName({
      className,
      scale,
      placeholder,
    });
    const funcChildren = typeof children === "function";
    return (
      <div
        className={
          !raw
            ? clsx(
                inputClassName,
                !funcChildren &&
                  "inline-flex items-center justify-between gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap bg-white",
              )
            : ""
        }
      >
        <AriakitCombobox ref={ref} className="w-full" {...props} />
        {showClearButton && (
          <ComboboxClear
            store={props.store}
            clearable={!props.required}
            aria-label={clearLabel}
          />
        )}
      </div>
    );
  },
);

export type ComboboxPopoverProps = Omit<
  AriakitComboboxPopoverProps,
  "children"
> & {
  children: React.ReactNode;
};

export const ComboboxPopover = forwardRef<HTMLDivElement, ComboboxPopoverProps>(
  ({ className, children, style, gutter = 4, ...props }, ref) => {
    return (
      <AriakitComboboxPopover
        ref={ref}
        className={clsx(className, "flex flex-col overflow-auto p-1")}
        wrapperProps={{
          className: "!z-popover",
        }}
        gutter={gutter}
        style={{
          maxHeight: "min(var(--popover-available-height, 320px), 320px)",
          ...style,
        }}
        render={(popoverProps) => (
          <PopoverCard {...popoverProps}>{children}</PopoverCard>
        )}
        {...props}
      />
    );
  },
);

export type ComboboxSeparatorProps = AriakitComboboxSeparatorProps &
  ListSeparatorClassNameProps;

export const ComboboxSeparator = forwardRef<
  HTMLHRElement,
  ComboboxSeparatorProps
>(({ className, ...props }, ref) => {
  const listSeparatorClassName = getListSeparatorClassName({ className });
  return (
    <AriakitComboboxSeparator
      ref={ref}
      className={listSeparatorClassName}
      {...props}
    />
  );
});
