import { gql } from "@apollo/client";
import { omit } from "lodash-es";
import { useFormState } from "react-final-form";
import { NavLink as ReactRouterLink, useParams } from "react-router-dom";
import { Link } from "swash/Link";
import { FormLabel } from "swash/form/FormLabel";

import {
  Fieldset,
  FieldsetField,
  FieldsetTitle,
} from "@/components/fields/Fieldset";
import {
  BooleanField,
  EnumField,
  StringField,
} from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

const encodingList = [
  "utf8",
  "ascii",
  "utf16le",
  "ucs2",
  "base64",
  "latin1",
  "binary",
  "hex",
];

export const IntegrationConfigFragment = gql`
  fragment FtpIntegrationConfigFragment on FtpIntegrationConfig {
    type
    host
    user
    password
    secure
    dir
    encoding
    contentEncoding
    expirationMode
    keywords
    printAuthorized
    minSize {
      width
      height
    }
    include
  }
`;

function formatValues(config) {
  if (!config) {
    return {
      type: "images",
      name: "",
      host: "",
      user: "",
      password: "",
      secure: false,
      dir: "/",
      encoding: "utf8",
      contentEncoding: "utf8",
      expirationMode: "usage",
      keywords: null,
      printAuthorized: true,
      hasMinSize: false,
      minSize: null,
      include: null,
    };
  }

  return {
    type: config.type,
    name: config.name,
    host: config.host,
    user: config.user,
    password: config.password,
    secure: config.secure,
    dir: config.dir ? config.dir : "/",
    encoding: config.encoding,
    contentEncoding: config.contentEncoding,
    expirationMode: config.expirationMode,
    keywords: config.keywords,
    printAuthorized: config.printAuthorized,
    hasMinSize: Boolean(config.minSize),
    minSize: config.minSize,
    include: config.include ? config.include[0] : null,
  };
}

function parseValues(values) {
  return values.type === "images"
    ? omit(
        {
          ...values,
          include: values.include ? [values.include] : null,
          minSize: values.hasMinSize
            ? {
                width: Number(values.minSize.width),
                height: Number(values.minSize.height),
              }
            : null,
        },
        ["hasMinSize", "contentEncoding"],
      )
    : omit(values, [
        "hasMinSize",
        "expirationMode",
        "keywords",
        "printAuthorized",
        "minSize",
        "include",
      ]);
}

function ImageImportOptionsFields() {
  const {
    values: { hasMinSize },
  } = useFormState({ subscription: { values: true } });

  return (
    <>
      <FieldsetTitle>
        Champs additionnels pour l’import des images
      </FieldsetTitle>
      <FieldsetField>
        <EnumField
          name="expirationMode"
          enum={{ usage: "Usage", import: "Import" }}
          label="Mode d'expiration"
          appearance="checkbox"
          hint="L'image importée expire-t-elle à l'import ou à l'usage ?"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="keywords"
          label="Mot clef pour la recherche"
          hint="Mot clef ajouté à ceux récupérés depuis les metadata de l'image. L'image pourra être recherchée grâce à ce mot clef"
          placeholder="afp agency"
        />
      </FieldsetField>
      <FieldsetField>
        <BooleanField name="printAuthorized" label="Autorisée pour le print" />
      </FieldsetField>
      <FieldsetField>
        <BooleanField name="hasMinSize" label="Taille minimum pour l'import" />
      </FieldsetField>
      {hasMinSize && (
        <FieldsetField>
          <div className="flex gap-4">
            <StringField
              name="minSize.width"
              label="Largeur minimum"
              type="number"
              w={80}
              orientation="horizontal"
              min="0"
              required
            />
            <StringField
              name="minSize.height"
              label="Hauteur minimum"
              type="number"
              w={80}
              orientation="horizontal"
              min="0"
              required
            />
          </div>
        </FieldsetField>
      )}
      <FieldsetField>
        <StringField
          name="include"
          label="Filtre sur le nom des fichiers"
          placeholder="^test.png$"
          hint="Seuls les fichiers dont le nom correspond à l'expression régulière seront pris en compte."
        />
      </FieldsetField>
    </>
  );
}

function NewsImportOptionsFields() {
  return (
    <>
      <FieldsetTitle>
        Champs additionnels pour l’import des dépêches
      </FieldsetTitle>
      <FieldsetField>
        <EnumField
          name="contentEncoding"
          label="Encodage du contenu"
          enum={encodingList.map((key) => ({ value: key, label: key }))}
          required
        />
      </FieldsetField>
    </>
  );
}
function Fields() {
  const { integrationId } = useParams();

  const {
    values: { type },
  } = useFormState({ subscription: { values: true } });

  return (
    <Fieldset>
      <FieldsetField>
        <EnumField
          name="type"
          label="Type d'import FTP"
          appearance="checkbox"
          enum={{ images: "Images", news: "News" }}
          required
          disabled={Boolean(integrationId)}
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="name"
          label="Nom de l'intégration FTP"
          placeholder="Renseignez le nom de l'intégration"
          hint='e.g. "Images AFP"'
          required
        />
      </FieldsetField>
      <FieldsetTitle>Champs de connexion au FTP</FieldsetTitle>
      <FieldsetField>
        <StringField
          name="host"
          label="Hôte"
          placeholder="Renseignez l'hôte FTP"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="user"
          label="Utilisateur"
          placeholder="Renseignez l'utilisateur FTP"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="password"
          label="Mot de passe"
          placeholder="Renseignez le mot de passe FTP"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <FormLabel id="ftp-secure" className="inline-block pb-2">
          Connexion sécurisée
        </FormLabel>
        <BooleanField
          aria-labelledby="ftp-secure"
          name="secure"
          label="La connexion au FTP est sécurisée (FTPS)"
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="dir"
          label="Dossier"
          hint={`Renseignez un dossier sous la forme "/mon/dossier". Par défaut c'est le dossier racine "/"`}
          placeholder="Renseignez un dossier"
        />
      </FieldsetField>
      <FieldsetField>
        <EnumField
          name="encoding"
          label="Encodage"
          enum={encodingList.map((key) => ({ value: key, label: key }))}
          required
        />
      </FieldsetField>
      {type === "images" && <ImageImportOptionsFields />}
      {type === "news" && <NewsImportOptionsFields />}
      <FieldsetField>
        <Link asChild>
          <ReactRouterLink
            to={`/admin/ftp-imports?integrationId___in[]=${integrationId}`}
          >
            Voir les imports FTP
          </ReactRouterLink>
        </Link>
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({
  Fields,
  formatValues,
  parseValues,
});
