// @ts-check

const SPACE = "\u0020";
const NUMBERS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const NO_BREAK_SPACE = "\u00A0";

/**
 * @type {import('../index').Rule}
 */
export const rule = {
  chars: [SPACE],
  transform: (ctx) => {
    const { input } = ctx;
    if (
      input === SPACE &&
      NUMBERS.includes(ctx.text.charAt(ctx.position - 1))
    ) {
      return [{ type: "insert", value: NO_BREAK_SPACE }];
    }
    return null;
  },
};
