/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql } from "@apollo/client";
import { forwardRef, useState } from "react";
import { Button, ButtonProps } from "swash/Button";
import { IoTrashOutline } from "swash/Icon";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";
import { useEventCallback } from "swash/utils/useEventCallback";
import { DialogDisclosure, useDialogStore } from "swash/v2/Dialog";

import { ConfirmDialog, ConfirmDialogBody } from "@/components/ConfirmDialog";
import { useSafeMutation } from "@/containers/Apollo";

const HideMutation = gql`
  mutation ArticleRemoveButtonHideArticleMutation($articleId: Int!) {
    hideArticle(id: $articleId) {
      id
      deleted
    }
  }
`;

const RemoveButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const title = "Supprimer l’article";
    return (
      <Tooltip tooltip={title}>
        <Button
          ref={ref}
          iconOnly
          appearance="text"
          variant="danger"
          scale="sm"
          aria-label={title}
          {...props}
        >
          <IoTrashOutline />
        </Button>
      </Tooltip>
    );
  },
);

type ActiveRemoveButtonProps = {
  articleId: number;
};

const ActiveRemoveButton = (props: ActiveRemoveButtonProps) => {
  const dialog = useDialogStore();
  const [hideArticle, { loading }] = useSafeMutation(HideMutation, {
    variables: { articleId: props.articleId },
    optimisticResponse: {
      __typename: "Mutation",
      hideArticle: {
        id: props.articleId,
        deleted: true,
        __typename: "Article",
      },
    },
  });
  const toaster = useToaster();

  return (
    <span onClick={(event) => event.stopPropagation()} className="flex">
      <DialogDisclosure store={dialog} render={<RemoveButton />} />
      <ConfirmDialog
        store={dialog}
        title="Suppimer l’article ?"
        onConfirm={async () => {
          await hideArticle().catch(() => {
            toaster.danger("Une erreur est survenue");
          });
        }}
        confirmButtonLabel="Supprimer"
        hideOnInteractOutside={false}
        hideOnEscape={false}
        disabled={loading}
      >
        <ConfirmDialogBody>
          Une fois supprimé cet article n’apparaîtra plus dans les résultats et
          sera rendu inaccessible.
        </ConfirmDialogBody>
      </ConfirmDialog>
    </span>
  );
};

export type ArticleRemoveButtonProps = {
  article: {
    id: number;
    acl: {
      me: string[];
    };
  };
};

export const ArticleRemoveButton = (props: ArticleRemoveButtonProps) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  if (!props.article.acl.me.includes("delete")) {
    return null;
  }

  if (active) {
    return <ActiveRemoveButton articleId={props.article.id} />;
  }

  return <RemoveButton onFocus={activate} onMouseEnter={activate} />;
};

ArticleRemoveButton.fragments = {
  article: gql`
    fragment ArticleRemoveButton_article on Article {
      id
      acl {
        me
      }
    }
  `,
};
