import { Button } from "swash/Button";
import { IoPencil } from "swash/Icon";

import {
  RemoteEditImageDialogDisclosure,
  useEditImageRemoteDialogContext,
} from "@/containers/image/ImageEditDialog";

export const ImageEditDisclosure = ({
  imageId,
  label,
  scale = "sm",
  variant = "secondary",
  icon = true,
  editor,
  preview,
  onDelete,
  ...props
}) => {
  const dialog = useEditImageRemoteDialogContext();
  const open = dialog?.store.useState("open");

  return (
    <RemoteEditImageDialogDisclosure
      dialogProps={{
        imageId,
        editor,
        preview,
        onDelete,
      }}
      render={(disclosureProps) => {
        if (open) return null;

        return (
          <Button
            type="button"
            label={label}
            scale={scale}
            variant={variant}
            title="Éditer l’image"
            {...props}
            {...disclosureProps}
          >
            {icon && <IoPencil />} {label}
          </Button>
        );
      }}
    />
  );
};
