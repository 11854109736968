import { gql } from "@apollo/client";
import { memo } from "react";

import { UserHoverCardAvatar, UserHoverCardUser } from "../user/UserHoverCard";

type Locker = {
  user: {
    id: number;
    fullName: string;
    currentRole: {
      id: number;
      name: string;
    };
  };
};

export type SpreadArticleLockerProps = {
  locker: Locker;
  size?: number;
};

export const SpreadArticleLocker = memo<SpreadArticleLockerProps>(
  ({ locker, size = 24 }) => {
    return (
      <div className="inline-flex">
        <UserHoverCardAvatar
          user={locker.user as UserHoverCardUser}
          size={size}
        />
      </div>
    );
  },
  // We only need to compare user id to ensure it is the same locker
  (prevProps, nextProps) =>
    prevProps.locker.user.id === nextProps.locker.user.id,
);

export type ArticleLockerProps = {
  article: {
    locker: Locker | null;
  };
  size?: number;
};

export const ArticleLocker = (props: ArticleLockerProps) => {
  if (!props.article.locker) return null;
  return (
    <SpreadArticleLocker locker={props.article.locker} size={props.size} />
  );
};

ArticleLocker.fragments = {
  article: gql`
    fragment ArticleLocker_article on Article {
      locker {
        user {
          id
          fullName
          currentRole {
            id
            name
          }
          ...UserHoverCardAvatar_user
        }
      }
    }
    ${UserHoverCardAvatar.fragments.user}
  `,
};
