import { EditorState, Modifier } from "draft-js-es";

export const deleteCharacters = (editorState, length) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const range = selectionState.merge(
    selectionState.getIsBackward()
      ? {
          focusOffset: selectionState.getStartOffset() - length,
          anchorOffset: selectionState.getEndOffset(),
        }
      : {
          focusOffset: selectionState.getEndOffset(),
          anchorOffset: selectionState.getStartOffset() - length,
        },
  );
  const newContentState = Modifier.removeRange(contentState, range, "backward");
  return EditorState.push(editorState, newContentState, "delete-characters");
};
