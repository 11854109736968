import { gql } from "@apollo/client";
import "moment/locale/fr";

import { HasOneField } from "@/containers/admin/CRUD";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const PeriodicalReleaseFragments = gql`
  fragment PeriodicalReleasesSearchField_periodicalReleases on PeriodicalRelease {
    id
    label
    releaseDate
    enabled
  }
`;

export const PeriodicalReleasesQuery = gql`
  query PeriodicalReleases(
    $search: String
    $value: Int
    $periodicalId: Int
    $periodicalSectionId: Int
    $enabled: Boolean
  ) {
    connection: periodicalReleases(
      limit: 5
      where: {
        search: $search
        id: { eq: $value }
        periodicalId: { eq: $periodicalId }
        periodicalSectionId: { eq: $periodicalSectionId }
        enabled: $enabled
      }
    ) {
      nodes {
        ...PeriodicalReleasesSearchField_periodicalReleases
      }
      ...ConnectionFragment
    }
  }
  ${ConnectionFragment}
  ${PeriodicalReleaseFragments}
`;

export function PeriodicalReleaseField({
  required,
  disabled,
  periodicalId,
  periodicalSectionId,
  label = "Parution",
  placeholder = "Sélectionnez une parution",
  modal,
  selectableDisabled,
}) {
  const searchVariables = {
    periodicalId,
    periodicalSectionId,
    ...(!selectableDisabled && { enabled: true }),
  };

  return (
    <HasOneField
      name="periodicalReleaseId"
      label={label}
      placeholder={placeholder}
      query={PeriodicalReleasesQuery}
      fragment={PeriodicalReleaseFragments}
      modelName="PeriodicalRelease"
      moda={modal}
      searchVariables={searchVariables}
      required={required}
      disabled={disabled}
      clearable
      searchable
    />
  );
}
