import { memo, useCallback } from "react";

import type {
  GroupRowType,
  Grouping,
  ListState,
  SubGroupRowType,
} from "../ListState";
import { ListGroupHeader } from "../NudeList";

export type GroupRowProps<TNode> = {
  row: GroupRowType<TNode> | SubGroupRowType<TNode>;
  toggleGroup: ListState<TNode>["toggleGroup"];
  getGroupLabel: Grouping<TNode>["getGroupLabel"];
  subGroup?: boolean;
};

const GroupRowWrapped = <TNode,>(props: GroupRowProps<TNode>) => {
  const { row, toggleGroup, getGroupLabel } = props;
  const { key } = row.group;
  const handleClick = useCallback(() => {
    toggleGroup(key);
  }, [toggleGroup, key]);
  return (
    <ListGroupHeader
      count={row.group.count}
      label={getGroupLabel(row.group.key)}
      isSubGroup={props.subGroup}
      open={row.open}
      onClick={handleClick}
    />
  );
};

export const GroupRow = memo(GroupRowWrapped);

export const estimateGroupRowSize = () => {
  const groupHeight = 32;
  const groupMarginTop = 24;
  return groupHeight + groupMarginTop;
};
