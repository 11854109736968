import { gql } from "@apollo/client";

import type { CellType } from "@/containers/list/ListState";

import {
  ArticleEditorialLevel,
  ArticleEditorialLevelProps,
} from "../../ArticleEditorialLevel";

export type EditorialLevelCellArticle = ArticleEditorialLevelProps["article"];

export const editorialLevelCell: CellType<EditorialLevelCellArticle> = {
  key: "editorialLevel",
  title: "Indice",
  width: 32,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleEditorialLevel article={node} />,
  fragment: gql`
    fragment editorialLevelCell_article on Article {
      ...ArticleEditorialLevel_article
    }

    ${ArticleEditorialLevel.fragments.article}
  `,
};
