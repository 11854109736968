import { gql } from "@apollo/client";

import type { ArticleDescriptionEditorialLevel_articleFragment } from "@/gql-types";

import { ArticleEditorialLevel } from "./ArticleEditorialLevel";

export type ArticleDescriptionEditorialLevelProps = {
  article: ArticleDescriptionEditorialLevel_articleFragment;
  disabled?: boolean;
};

const ArticleFragment = gql`
  fragment ArticleDescriptionEditorialLevel_article on Article {
    ...ArticleEditorialLevel_article
  }

  ${ArticleEditorialLevel.fragments.article}
`;

export const ArticleDescriptionEditorialLevel = (
  props: ArticleDescriptionEditorialLevelProps,
) => {
  return (
    <ArticleEditorialLevel
      article={props.article}
      disabled={props.disabled}
      scale="sm"
    />
  );
};

ArticleDescriptionEditorialLevel.fragments = {
  article: ArticleFragment,
};
