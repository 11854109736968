import { useEffect } from "react";
import { useEventCallback } from "swash/utils/useEventCallback";

import * as storage from "@/services/localStorage";

import { ArticlePanel } from "./ArticlePanel";
import { ArticleAnalyticsPanel } from "./panels/analytics";
import { ArticleCommentsPanel } from "./panels/comments";
import { ArticleExposurePanel } from "./panels/exposure/ArticleExposurePanel";

const STORAGE_KEY = "article-meta-panel";

type TrackPanelProps = {
  panelKey: string;
};

export const TrackPanel = (props: TrackPanelProps) => {
  useEffect(() => {
    storage.setItem(STORAGE_KEY, props.panelKey);
  }, [props.panelKey]);
  return null;
};

export const useCheckIsActivated = () => {
  const checks = [
    ArticlePanel.useCheckIsActivated(),
    ArticleCommentsPanel.useCheckIsActivated(),
    ArticleAnalyticsPanel.useCheckIsActivated(),
    ArticleExposurePanel.useCheckIsActivated(),
  ];
  return (articleId: number) => checks.some((check) => check({ articleId }));
};

export const useActivate = () => {
  const defaultActivate = ArticlePanel.useActivate();
  const activators = {
    [ArticlePanel.key]: defaultActivate,
    [ArticleCommentsPanel.key]: ArticleCommentsPanel.useActivate(),
    [ArticleAnalyticsPanel.key]: ArticleAnalyticsPanel.useActivate(),
    [ArticleExposurePanel.key]: ArticleExposurePanel.useActivate(),
  };
  return useEventCallback((articleId: number) => {
    const lastUsed = storage.getItem(STORAGE_KEY);
    const activate =
      (lastUsed ? activators[lastUsed] : null) ?? defaultActivate;
    activate({ articleId });
  });
};
