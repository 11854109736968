// @ts-check
import { rule as apostrophe } from "../rules/french-apostrophe.js";
import { rule as numbers } from "../rules/french-numbers.js";
import { rule as punctuation } from "../rules/french-punctuation.js";
import { rule as quotes } from "../rules/french-quotes.js";

/**
 * @type {import('../index').Rule[]}
 */
export const preset = [apostrophe, quotes, punctuation, numbers];
