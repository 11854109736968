import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "swash/Button";
import {
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
} from "swash/Panel";

import { ArticleAlertLightHistoryDetail } from "@/containers/article/panels/exposure/alert-section/history/ArticleAlertLightHistoryDetail";

import { ArticleAlertHistory } from "./history/ArticleAlertHistory";

export const ArticleLightAlertSection = memo(({ articleId }) => {
  return (
    <>
      <PanelSection role="region" aria-label="Alertes">
        <PanelSectionHeader className="mb-4">
          <PanelSectionTitle>Alertes</PanelSectionTitle>
          <Button type="button" scale="sm">
            <RouterLink
              to={`/articles/${articleId}/exposure/alert/create`}
              target="_blank"
            >
              Créer une alerte
            </RouterLink>
          </Button>
        </PanelSectionHeader>
        <ArticleAlertHistory
          articleId={articleId}
          renderItem={(props) => <ArticleAlertLightHistoryDetail {...props} />}
        />
      </PanelSection>
    </>
  );
});
