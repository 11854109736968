import { memo } from "react";
import { IoChatboxOutline } from "swash/Icon";

import { RichEditorToolbarButton } from "@/components/rich-editor/RichEditorToolbar";
import { command } from "@/components/rich-editor/plugins/comment";
import { handleBinding } from "@/components/rich-editor/utils";
import type { State } from "@/components/rich-editor/utils/PluginPopover";

const SHORTCUT = ["⌘", "j"];

export const shortcuts = [
  {
    description: "Ajouter un commentaire",
    shortcut: SHORTCUT,
    category: "Commentaires",
  },
];

export const name = "comment-control";

const CommentToolbarButton = memo(({ active }: { active?: boolean }) => {
  return (
    // @ts-expect-error use a js component
    <RichEditorToolbarButton
      active={active}
      label="Commentaire"
      command={command}
      shortcut={SHORTCUT}
    >
      <IoChatboxOutline />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = () => {
  return <CommentToolbarButton />;
};

BlockControls.group = "spell-check";

export const keyBindingFn = (_state: State, event: KeyboardEvent) =>
  handleBinding(SHORTCUT, command, event);
