/* eslint-disable no-param-reassign */
/* eslint-disable no-irregular-whitespace */
// const map = require('unist-util-map');

const map = (tree, iteratee) => {
  const preorder = (node, index, parent) => {
    const bound = (child, index) => {
      return preorder(child, index, node);
    };

    const newNode = { ...iteratee(node, index, parent) };

    if (newNode.children) {
      newNode.children = newNode.children.map(bound);
    }

    return newNode;
  };
  return preorder(tree, null, null);
};

const LEFT_POINTING_QUOTATION_MARK = "«";
const RIGHT_POINTING_QUOTATION_MARK = "»";

const findLeftQuoteNode = (node) => {
  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    if (child.type === "text") {
      const index = child.value.indexOf(LEFT_POINTING_QUOTATION_MARK);
      if (index !== -1) {
        return { node: child, nodePosition: i, charPosition: index };
      }
    }
  }
  return false;
};

const findRightQuoteNode = (node) => {
  for (let i = node.children.length - 1; i >= 0; i--) {
    const child = node.children[i];
    if (child.type === "text") {
      const index = child.value.indexOf(RIGHT_POINTING_QUOTATION_MARK);
      if (index !== -1) {
        return { node: child, nodePosition: i, charPosition: index };
      }
    }
  }
  return false;
};

const isEm = (node) => {
  return node.type === "element" && node.tagName === "em";
};

const element = (node, index, parent) => {
  if (isEm(parent) || isEm(node)) {
    return node;
  }

  const leftQuoteNode = findLeftQuoteNode(node);
  if (!leftQuoteNode) return node;

  const rightQuoteNode = findRightQuoteNode(node);
  if (!rightQuoteNode) return node;

  if (leftQuoteNode.nodePosition > rightQuoteNode.nodePosition) {
    return node;
  }

  if (
    leftQuoteNode.nodePosition === rightQuoteNode.nodePosition &&
    leftQuoteNode.charPosition > rightQuoteNode.charPosition
  ) {
    return node;
  }

  const children = [];

  // Put all children before quote mark
  for (let i = 0; i < leftQuoteNode.nodePosition; i++) {
    children.push(node.children[i]);
  }

  // Put start of word in a specific text node
  if (leftQuoteNode.charPosition > 0) {
    children.push({
      type: "text",
      value: leftQuoteNode.node.value.slice(0, leftQuoteNode.charPosition),
    });
  }

  const em = { type: "element", tagName: "em", properties: {}, children: [] };
  children.push(em);

  if (leftQuoteNode.nodePosition === rightQuoteNode.nodePosition) {
    em.children.push({
      type: "text",
      value: leftQuoteNode.node.value.slice(
        leftQuoteNode.charPosition,
        rightQuoteNode.charPosition + 1,
      ),
    });
  } else {
    // Put end of word in a specific text node
    if (leftQuoteNode.charPosition < leftQuoteNode.node.value.length - 1) {
      em.children.push({
        type: "text",
        value: leftQuoteNode.node.value.slice(leftQuoteNode.charPosition),
      });
    }

    // Put nodes between the left and right quote
    for (
      let i = leftQuoteNode.nodePosition + 1;
      i < rightQuoteNode.nodePosition;
      i++
    ) {
      em.children.push(node.children[i]);
    }

    // Put start of word in a specific text node
    if (rightQuoteNode.charPosition > 0) {
      em.children.push({
        type: "text",
        value: rightQuoteNode.node.value.slice(
          0,
          rightQuoteNode.charPosition + 1,
        ),
      });
    }
  }

  // Put end of word in a specific text node
  if (rightQuoteNode.charPosition < rightQuoteNode.node.value.length - 1) {
    children.push({
      type: "text",
      value: rightQuoteNode.node.value.slice(rightQuoteNode.charPosition + 1),
    });
  }

  // Put all children after quote mark
  for (let i = rightQuoteNode.nodePosition + 1; i < node.children.length; i++) {
    children.push(node.children[i]);
  }

  node.children = children;
  return element(node, index, parent);
};

export const toFrench = (node) => {
  return map(node, (node, index, parent) => {
    if (node.type === "element") return element(node, index, parent);
    return node;
  });
};
