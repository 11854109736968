import * as React from "react";
import { forwardRef } from "react";

const SvgCustomField = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.571 13.429a1.429 1.429 0 1 0 0-2.858 1.429 1.429 0 0 0 0 2.858Zm0 1.428a2.857 2.857 0 1 0 0-5.714 2.857 2.857 0 0 0 0 5.714ZM3.43 13.43a1.429 1.429 0 1 0 0-2.858 1.429 1.429 0 0 0 0 2.858Zm0 1.428a2.857 2.857 0 1 0 0-5.714 2.857 2.857 0 0 0 0 5.714Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.714 4.143C2.714 2.959 3.674 2 4.857 2h14.286c1.183 0 2.143.96 2.143 2.143v5.714h-1.429V4.143a.714.714 0 0 0-.714-.714H4.857a.714.714 0 0 0-.714.714v5.714H2.714V4.143Zm1.429 10v5.714c0 .395.32.714.714.714h14.286c.394 0 .714-.32.714-.714v-5.714h1.429v5.714c0 1.184-.96 2.143-2.143 2.143H4.857a2.143 2.143 0 0 1-2.143-2.143v-5.714h1.429Z"
      fill="currentColor"
    />
    <path
      d="M16.906 6.286H7.094L7 9.134h.551c.3-1.788.875-2.065 3.105-2.135l.49-.016v9.061c0 .767-.166.95-1.499 1.069v.601h4.706v-.601c-1.34-.119-1.506-.3-1.506-1.069V6.983l.497.014c2.229.072 2.805.349 3.105 2.137H17l-.094-2.848Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCustomField);
export default ForwardRef;
