import { IoCloseOutline, IoPencil, PushpinInclined } from "swash/Icon";

import { CapsuleButton, CapsuleButtonProps } from "./CapsuleButton";

export const CapsuleRemoveButton = (props: CapsuleButtonProps) => {
  return (
    <CapsuleButton aria-label="Supprimer" {...props}>
      <IoCloseOutline />
    </CapsuleButton>
  );
};

export const CapsulePinButton = (props: CapsuleButtonProps) => {
  return (
    <CapsuleButton aria-label="Épingler" {...props}>
      <PushpinInclined />
    </CapsuleButton>
  );
};

export const CapsuleEditButton = (props: CapsuleButtonProps) => {
  return (
    <CapsuleButton aria-label="Éditer" {...props}>
      <IoPencil />
    </CapsuleButton>
  );
};
