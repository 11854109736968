import {
  OperatorNames,
  WordOperator,
  operators,
  textOperators,
  wordOperators,
} from "./operators";

const getOperator = (word: string): WordOperator | null => {
  for (const i in wordOperators) {
    if (wordOperators[i]!.parseWordCondition(word)) {
      return wordOperators[i]!;
    }
  }
  return null;
};

export const parseSearchText = (
  initialText: string,
): { [operator in OperatorNames]?: string } => {
  if (!initialText?.length) return {};
  let copyText = `${initialText}`;

  const parsedSearch = textOperators.reduce(
    (acc, operator) => {
      const { matchedText, slicedText } = operator.parseText(copyText);
      if (matchedText.length) {
        acc[operator.name] = matchedText;
        copyText = slicedText!;
      }
      return acc;
    },
    {} as Record<OperatorNames, string>,
  );
  copyText.split(" ").forEach((word) => {
    const operator = getOperator(word);
    if (operator?.parseWordCondition(word)) {
      const operatorName = operator.name;
      const matchedWord = operator.parseWordAction(word);
      if (matchedWord.length) {
        parsedSearch[operatorName] =
          (parsedSearch[operatorName] ?? "") + `${matchedWord} `;
      }
    }
  });
  return parsedSearch;
};

const formatExpressionText = (name: OperatorNames, value: string) => {
  const operator = operators.find((operator) => operator.name === name);
  return operator?.format(value).trim() ?? "";
};
export const formatSearchText = (values: Record<OperatorNames, string>) => {
  return Object.entries(values)
    .reduce((acc, [key, value]) => {
      acc += `${formatExpressionText(key as OperatorNames, value)} `;
      return acc;
    }, "")
    .trim();
};
