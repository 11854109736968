import { createContext, useContext } from "react";
import { createSelector } from "swash/utils/selector";

// TODO: Type properly
type Article = any;

const ArticleSelector = createSelector<Article>();
const ArticleIdContext = createContext<number>(0);
const ArticleHasCustomFieldsContext = createContext<boolean>(false);

export const ArticleContextProvider: React.FC<{
  article: Article;
  hasCustomFields?: boolean;
  children?: React.ReactNode;
}> = ({ article, hasCustomFields = false, children }) => {
  return (
    <ArticleIdContext.Provider value={article.id}>
      <ArticleHasCustomFieldsContext.Provider value={hasCustomFields}>
        <ArticleSelector.Provider state={article}>
          {children}
        </ArticleSelector.Provider>
      </ArticleHasCustomFieldsContext.Provider>
    </ArticleIdContext.Provider>
  );
};

export const useArticleId = () => {
  return useContext(ArticleIdContext);
};

export const useArticleHasCustomFields = () => {
  return useContext(ArticleHasCustomFieldsContext);
};

export const useArticleSelector = ArticleSelector.useSelector;
