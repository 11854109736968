import { useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useSearchControls } from "./SearchPluginContext";

export const name = "search";
export const command = "search";

export { keyBindingFn, shortcuts } from "./keyBinding";

export const handleKeyCommand = ({ props }, cmd) => {
  if (cmd !== command) return undefined;
  props.show();
  return "handled";
};

export const usePluginProps = (state) => {
  const { registerEditor, show } = useSearchControls();
  const stateRef = useLiveRef(state);
  const { readOnly } = state;

  useEffect(() => {
    if (readOnly) return undefined;
    const { name, setEditorState } = stateRef.current;
    return registerEditor({
      name,
      getEditorState: () => stateRef.current.editorState,
      setEditorState,
    });
  }, [stateRef, readOnly, registerEditor]);

  return { show };
};
