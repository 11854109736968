import { useRef } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

const FormDirtyFieldsContext =
  createSafeContext<
    React.MutableRefObject<Record<string, boolean> | undefined>
  >();

export const FormDirtyFieldsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dirtyFieldsRef = useRef<Record<string, boolean>>();
  return (
    <FormDirtyFieldsContext.Provider value={dirtyFieldsRef}>
      {children}
    </FormDirtyFieldsContext.Provider>
  );
};

export const useDirtyFieldsRef = FormDirtyFieldsContext.makeSafeHook(
  "useDirtyFieldsRef",
  "FormDirtyFieldsProvider",
);
