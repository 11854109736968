import { memo } from "react";
import * as icons from "react-icons/io5";

import { NamedIcon } from "@/components/NamedIcon";

import { EnumField } from "./EnumField";

const decamelize = (str) =>
  str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

const iconEnumValues = Object.keys(icons).reduce((acc, key) => {
  if (!(key.endsWith("Outline") || key.endsWith("Sharp"))) {
    acc.push({
      value: decamelize(key, { separator: "-" }),
      label: key,
    });
  }
  return acc;
}, []);

export const IconFieldLabel = memo(({ value, label }) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      <NamedIcon name={value} />
      <div>{label || value}</div>
    </div>
  );
});

export function IconField(props) {
  return (
    <EnumField
      enum={iconEnumValues.filter(({ value }) => !props?.omit?.includes(value))}
      labelSelector={({ value, label }) => (
        <IconFieldLabel value={value} label={label} />
      )}
      clearable
      {...props}
    />
  );
}
