import clsx from "clsx";
import * as React from "react";

export type DialogLayoutProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const DialogLayout = ({
  className,
  style,
  ...props
}: DialogLayoutProps) => (
  <div
    className={clsx(
      className,
      "fixed left-1/2 top-1/2 z-dialog -translate-x-1/2 -translate-y-1/2 rounded bg-white shadow-md",
    )}
    style={{
      maxWidth: "90%",
      ...style,
    }}
    {...props}
  />
);
