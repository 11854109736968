import { gql } from "@apollo/client";
import { memo } from "react";

import { useSafeQuery } from "@/containers/Apollo";
import { EnumField } from "@/containers/admin/CRUD/fields/EnumField";
import { useNodesToEnumArray } from "@/containers/admin/CRUD/fields/util";

const ImageDialogMediasQuery = gql`
  query ImageDialogMediasQuery {
    mediaDescriptors(
      offset: 0
      limit: 10
      where: { primaryType: { eq: "image" } }
    ) {
      nodes {
        id: secondaryType
        label
      }
    }
  }
`;

export const TypeField = memo((props) => {
  const { data } = useSafeQuery(ImageDialogMediasQuery);
  const enumValues = useNodesToEnumArray(data?.mediaDescriptors?.nodes ?? []);

  return <EnumField required enum={enumValues} {...props} />;
});
