import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { Button } from "swash/Button";
import { Card, CardBody, CardFooter } from "swash/Card";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { Dialog, DialogDisclosure, useDialogStore } from "swash/v2/Dialog";

import { Form } from "@/components/forms/Form";
import { FormError, FormErrorToaster } from "@/components/forms/FormError";
import { FormReset } from "@/components/forms/FormReset";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useIdParam } from "@/containers/Router";

const identity = (x) => x;

const UpdateIntegrationMutation = gql`
  mutation UpdateIntegrationEnableMutation($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      id
      enabled
    }
  }
`;
function DisableDialog({ integrationConfig, store }) {
  const integrationId = useIdParam("integrationId");

  const { serviceKey, name, label, enabled } = integrationConfig;

  const [updateIntegration] = useMutation(UpdateIntegrationMutation);

  return (
    <Dialog store={store}>
      <PanelHeader
        title={`${enabled ? "Désactiver" : "Activer"} l’intégration ${label}`}
        onClose={store.hide}
      />
      <Form
        onSubmit={async () => {
          await updateIntegration({
            variables: {
              input: {
                id: Number(integrationId),
                serviceKey,
                name,
                enabled: !enabled,
              },
            },
          });
          store.hide();
        }}
      >
        <PanelBody>
          Êtes-vous sûr de vouloir {enabled ? "désactiver" : "activer"}{" "}
          l’intégration {label} ?
        </PanelBody>
        <PanelFooter>
          <FormErrorToaster />
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={store.hide}
          >
            Annuler
          </Button>
          <FormSubmit variant={enabled ? "danger" : "success"}>
            {enabled ? "Désactiver" : "Activer"}
          </FormSubmit>
        </PanelFooter>
      </Form>
    </Dialog>
  );
}

function DisableButton({ integrationConfig }) {
  const dialog = useDialogStore();

  return (
    <>
      <DialogDisclosure
        store={dialog}
        render={
          <Button
            type="button"
            variant={integrationConfig.enabled ? "danger" : "success"}
          >
            {integrationConfig.enabled ? "Désactiver" : "Activer"}
          </Button>
        }
      />
      <DisableDialog store={dialog} integrationConfig={integrationConfig} />
    </>
  );
}

export const createServiceForm = ({
  formatValues = (raw) => {
    if (raw) {
      const { __typename, ...values } = raw;
      return values;
    }
    return {};
  },
  parseValues = identity,
  Fields,
}) => {
  return function ServiceForm({
    integrationConfig,
    createIntegrationFromConfig,
    updateIntegrationFromConfig,
  }) {
    const initialValues = useMemo(
      () => formatValues(integrationConfig),
      [integrationConfig],
    );

    return (
      <Card>
        <Form
          initialValues={initialValues}
          autoComplete="off"
          onSubmit={async (values) => {
            if (!integrationConfig) {
              await createIntegrationFromConfig(parseValues(values));
            } else {
              await updateIntegrationFromConfig(parseValues(values));
            }
          }}
        >
          <CardBody>
            <Fields initialValues={initialValues} />
          </CardBody>
          <CardFooter>
            <div className="box-border flex items-center justify-end gap-3">
              <FormError />
              {integrationConfig && (
                <DisableButton integrationConfig={integrationConfig} />
              )}
              <FormReset initialValues={initialValues} />
              <FormSubmit disabledIfPristine>
                {integrationConfig ? "Configurer" : "Installer"}
              </FormSubmit>
            </div>
          </CardFooter>
        </Form>
      </Card>
    );
  };
};
