// @ts-check

const QUOTE = '"';
const NO_BREAK_SPACE = "\u00A0";
const LEFT_POINTING_QUOTATION_MARK = "«";
const RIGHT_POINTING_QUOTATION_MARK = "»";
const LEFT_DOUBLE_QUOTATION_MARK = "“";
const RIGHT_DOUBLE_QUOTATION_MARK = "”";

/**
 * Check if quotes are opened.
 * @param {import('..').Context} ctx
 * @returns {boolean}
 */
const checkHasOpenedQuote = (ctx, quotes) => {
  let i = ctx.position;
  while (i > 0) {
    i--;
    if (ctx.text[i] === quotes[0]) {
      return true;
    }
    if (ctx.text[i] === quotes[1]) {
      return false;
    }
  }
  return false;
};

/**
 * Check if quotes are closed.
 * @param {import('..').Context} ctx
 * @returns {boolean}
 */
const checkHasClosedQuote = (ctx, quotes) => {
  let i = ctx.position;
  while (i < ctx.text.length) {
    i++;
    if (ctx.text[i] === quotes[0]) {
      return false;
    }
    if (ctx.text[i] === quotes[1]) {
      return true;
    }
  }
  return false;
};

/**
 * @type {import('../index').Rule}
 */
export const rule = {
  chars: [QUOTE],
  transform: (ctx) => {
    const { input } = ctx;
    if (input === QUOTE) {
      const opened = checkHasOpenedQuote(ctx, [
        LEFT_POINTING_QUOTATION_MARK,
        RIGHT_POINTING_QUOTATION_MARK,
      ]);
      const closed = checkHasClosedQuote(ctx, [
        LEFT_POINTING_QUOTATION_MARK,
        RIGHT_POINTING_QUOTATION_MARK,
      ]);

      // Surrounded by quotes, use english quotes
      if (opened && closed) {
        const sopened = checkHasOpenedQuote(ctx, [
          LEFT_DOUBLE_QUOTATION_MARK,
          RIGHT_DOUBLE_QUOTATION_MARK,
        ]);
        const sclosed = checkHasClosedQuote(ctx, [
          LEFT_DOUBLE_QUOTATION_MARK,
          RIGHT_DOUBLE_QUOTATION_MARK,
        ]);

        if (sopened && sclosed) {
          return null;
        }

        return [
          {
            type: "insert",
            value: sopened
              ? RIGHT_DOUBLE_QUOTATION_MARK
              : LEFT_DOUBLE_QUOTATION_MARK,
          },
        ];
      }

      return [
        {
          type: "insert",
          value: opened
            ? NO_BREAK_SPACE + RIGHT_POINTING_QUOTATION_MARK
            : LEFT_POINTING_QUOTATION_MARK + NO_BREAK_SPACE,
        },
      ];
    }
    return null;
  },
};
