import { ContentState, EditorState } from "draft-js-es";

import { selectBlock } from "./selectBlock";

const arrayMove = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

/**
 * Move block to a specific index.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} sourceBlockKey
 * @param {number} targetIndex
 */
export function moveBlock(editorState, sourceBlockKey, targetIndex) {
  const contentState = editorState.getCurrentContent();
  const sourceBlock = contentState.getBlockForKey(sourceBlockKey);

  const blocks = contentState.getBlockMap().toArray();
  const sourceIndex = blocks.indexOf(sourceBlock);
  arrayMove(blocks, sourceIndex, targetIndex);

  return selectBlock(
    EditorState.push(
      editorState,
      ContentState.createFromBlockArray(blocks),
      "move-block",
    ),
    sourceBlockKey,
  );
}
