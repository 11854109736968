/* eslint-disable react/prop-types */
import styled from "@xstyled/styled-components";
import { forwardRef, useLayoutEffect, useState } from "react";
import { Textbox } from "swash/controls/Textbox";
import { useDebouncedCallback } from "use-debounce";

const InnerTextInputGroup = styled.box`
  display: flex;
  align-items: center;

  > :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  button,
  [role="button"] {
    padding: 0 !important;
    justify-content: center;
    flex: 0 0 auto;
  }

  &[data-scale="base"] {
    button,
    [role="button"] {
      height: 30;
      width: 30;

      svg {
        width: 14;
        height: 14;
      }
    }
  }

  &[data-scale="sm"] {
    button,
    [role="button"] {
      height: 26;
      width: 26;

      svg {
        width: 14;
        height: 14;
      }
    }
  }

  &[data-scale="lg"] {
    button,
    [role="button"] {
      height: 40;
      width: 40;

      svg {
        width: 16;
        height: 16;
      }
    }
  }
`;

export const TextInputGroup = forwardRef(
  ({ scale = "base", ...props }, ref) => {
    return <InnerTextInputGroup ref={ref} data-scale={scale} {...props} />;
  },
);

export const DebouncedTextInput = forwardRef(
  ({ value, onChange, delay, ...props }, ref) => {
    const [localValue, setLocalValue] = useState(value);
    useLayoutEffect(() => {
      setLocalValue(value);
    }, [value]);
    const debouncedOnChange = useDebouncedCallback(onChange, delay);
    return (
      <Textbox
        ref={ref}
        value={localValue}
        onChange={(event) => {
          event.persist();
          setLocalValue(event.target.value);
          debouncedOnChange(event);
        }}
        {...props}
      />
    );
  },
);
