import { EditorState, Modifier } from "draft-js-es";

export const insertOrReplaceCharacters = (editorState, character) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentInlineStyle = editorState.getCurrentInlineStyle();

  const currentCursor = selectionState.getEndOffset();
  const anchorKey = selectionState.getAnchorKey();
  const currentContentBlock = contentState.getBlockForKey(anchorKey);
  const entityKey = currentContentBlock.getEntityAt(currentCursor);
  const modifier = selectionState.isCollapsed()
    ? Modifier.insertText
    : Modifier.replaceText;
  const newContentState = modifier(
    contentState,
    selectionState,
    character,
    currentInlineStyle,
    entityKey,
  );

  return EditorState.push(editorState, newContentState, "insert-characters");
};
