import * as React from "react";
import { forwardRef } from "react";

const SvgExposition = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7 15.25a3.25 3.25 0 1 1 0-6.5 3.25 3.25 0 0 1 0 6.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.805 10.975c.26.29.26.76 0 1.05l-1.576 1.757a.62.62 0 0 1-.943 0 .805.805 0 0 1 0-1.05l.438-.489h-5.057c-.368 0-.667-.333-.667-.743s.299-.743.667-.743h5.057l-.438-.488a.805.805 0 0 1 0-1.051.62.62 0 0 1 .943 0l1.576 1.757ZM18.207 22.784a.718.718 0 0 1-.718.718h-2.4a.718.718 0 0 1 0-1.436h.666l-3.852-3.853a.718.718 0 0 1 1.015-1.016l3.853 3.853v-.668a.718.718 0 0 1 1.436 0v2.402ZM18.207 1.216a.718.718 0 0 0-.718-.718h-2.4a.718.718 0 0 0 0 1.437h.666l-3.852 3.852a.718.718 0 0 0 1.015 1.016l3.853-3.853v.667a.718.718 0 0 0 1.436 0v-2.4Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExposition);
export default ForwardRef;
