import type { EditorState, SelectionState } from "draft-js-es";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import type { RichEditorState } from "@/components/rich-editor/RichEditorState";
import {
  MentionContext,
  useMention,
  useMentionPluginConfig,
} from "@/components/rich-editor/plugins/mention/MentionPluginContext";
import {
  MentionRemoteSelectContext,
  useMentionRemoteSelect,
} from "@/components/rich-editor/plugins/mention/MentionRemoteSelectProvider";
import { MentionSuggestionsSelect } from "@/components/rich-editor/plugins/mention/MentionSuggestionsSelect";
import type { MentionPluginConfig } from "@/components/rich-editor/plugins/mention/index";
import getSearchText from "@/components/rich-editor/plugins/mention/utils/getSearchText";
import getTriggerForMention from "@/components/rich-editor/plugins/mention/utils/getTriggerForMention";

export type SearchResult = {
  trigger: string;
  value: string;
  activeOffsetKey: string;
};

type SearchForMentionRef = Pick<
  MentionContext,
  "getAllSearches" | "resetEscapedSearch" | "isEscaped"
> &
  Pick<MentionPluginConfig, "mentionTriggers"> & {
    activeOffsetKey?: string;
    lastActiveTrigger: string;
    lastSearchValue?: string;
    showSelect(): void;
  };

export type SearchForMention = {
  onEditorStateChange: Parameters<
    MentionRemoteSelectContext["registerOnEditorStateChange"]
  >[0];
  search: SearchResult | null;
};
export const useSearchForMention = (): SearchForMention => {
  const { getAllSearches, resetEscapedSearch, isEscaped } = useMention();
  const { show } = useMentionRemoteSelect();
  const { mentionTriggers } = useMentionPluginConfig();

  const [search, setSearch] = useState<SearchResult | null>(null);
  const refs = useRef<SearchForMentionRef>({
    activeOffsetKey: undefined,
    lastActiveTrigger: "",
    lastSearchValue: undefined,
    getAllSearches,
    showSelect: show,
    resetEscapedSearch,
    isEscaped,
    mentionTriggers,
  });

  const editorStateRef = useRef<EditorState | undefined>();

  const onSearchChange = (
    editorState: EditorState,
    selection: SelectionState,
    activeOffsetKey: string,
    lastActiveOffsetKey: string | undefined,
    trigger: string,
  ): void => {
    const { matchingString: searchValue } = getSearchText(
      editorState,
      selection,
      [trigger],
    );
    const { lastActiveTrigger, lastSearchValue } = refs.current;

    if (
      lastActiveTrigger !== trigger ||
      lastSearchValue !== searchValue ||
      activeOffsetKey !== lastActiveOffsetKey
    ) {
      refs.current.lastActiveTrigger = trigger;
      refs.current.lastSearchValue = searchValue;
      setSearch({ trigger, value: searchValue, activeOffsetKey });
    }
  };

  const onEditorStateChange = useCallback(
    ({ editorState }: RichEditorState) => {
      const {
        getAllSearches,
        resetEscapedSearch,
        isEscaped,
        activeOffsetKey,
        mentionTriggers,
      } = refs.current;

      const searches = getAllSearches();

      const reset = () => {
        refs.current.lastActiveTrigger = "";
        refs.current.lastSearchValue = undefined;
        resetEscapedSearch();
        setSearch(null);
        return;
      };

      if (searches.size === 0) {
        return reset();
      }

      const triggerForMention = getTriggerForMention(
        editorState,
        searches,
        mentionTriggers,
      );

      if (!triggerForMention) {
        return reset();
      }

      if (isEscaped(triggerForMention?.activeOffsetKey ?? "")) {
        setSearch(null);
        return;
      }

      if (
        // content change
        editorState.getCurrentContent().getPlainText() ===
        editorStateRef.current?.getCurrentContent().getPlainText()
      ) {
        return;
      }

      refs.current.showSelect();

      const lastActiveOffsetKey = activeOffsetKey;
      refs.current.activeOffsetKey = triggerForMention.activeOffsetKey;

      onSearchChange(
        editorState,
        editorState.getSelection(),
        triggerForMention.activeOffsetKey,
        lastActiveOffsetKey,
        triggerForMention?.activeTrigger || "",
      );

      if (!isEscaped(activeOffsetKey || "")) {
        resetEscapedSearch();
      }
      editorStateRef.current = editorState;
    },
    [],
  );

  return {
    onEditorStateChange,
    search,
  };
};

export const MentionSuggestions = (): ReactElement | null => {
  const { triggerMentionQueries } = useMentionPluginConfig();
  const { search, onEditorStateChange } = useSearchForMention();
  const { registerOnEditorStateChange, getClientRectFn } =
    useMentionRemoteSelect();

  const fallbackRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    return registerOnEditorStateChange(onEditorStateChange);
  }, [onEditorStateChange, registerOnEditorStateChange]);

  // no search is active there is no need to show the select
  if (!search) return null;
  const triggerMentionQuery = triggerMentionQueries?.get(search.trigger);
  if (!triggerMentionQuery)
    throw new Error(
      `Invariant: rich editor mention plugin has not options for "${search.trigger}" trigger`,
    );
  return (
    <>
      <span ref={fallbackRef} style={{ position: "fixed" }} />
      <MentionSuggestionsSelect
        activeOffsetKey={search.activeOffsetKey}
        searchValue={search.value}
        activeTrigger={search.trigger}
        triggerMentionQuery={triggerMentionQuery}
        getAnchorRect={() => {
          const clientRectFunction = getClientRectFn(search.activeOffsetKey);
          if (clientRectFunction) {
            return clientRectFunction();
          }
          const parentElement = fallbackRef.current?.parentElement;
          if (parentElement) {
            return parentElement.getBoundingClientRect();
          }
          return null;
        }}
      />
    </>
  );
};
