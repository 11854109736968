import { createContext, useContext } from "react";

const NotProvided = Symbol("NotProvided");

/**
 * Helper wrapping `React.createContext` ensuring the context was properly provided
 * with a clear error message.
 *
 * @example
 * ```tsx
 * const SampleContext = createSafeContext<number>();
 * export const SampleContextProvider = SampleContext.Provider;
 * export const useSampleContext = SampleContext.makeSafeHook("useSampleContext", "SampleContextProvider");
 * ```
 */
export const createSafeContext = <T>() => {
  const context = createContext<T | typeof NotProvided>(NotProvided);

  const Provider = context.Provider as React.Provider<T>;
  const Consumer = context.Consumer as React.Consumer<T>;

  const makeSafeHook = (hookName: string, providerName: string) => () => {
    const value = useContext(context);
    if (value === NotProvided) {
      throw new Error(`${hookName}() must be used within a <${providerName}>`);
    }
    return value;
  };

  return {
    Provider,
    Consumer,
    makeSafeHook,
  };
};
