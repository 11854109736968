import { Map } from "immutable-es";
import {
  ReactElement,
  createContext,
  useContext,
  useMemo,
  useRef,
} from "react";

import type { RichEditorState } from "@/components/rich-editor/RichEditorState";
import { MentionSuggestions } from "@/components/rich-editor/plugins/mention/MentionSuggestions";
import type { MentionSuggestionsSelectState } from "@/components/rich-editor/plugins/mention/MentionSuggestionsSelect";
import type { MentionPluginProps } from "@/components/rich-editor/plugins/mention/index";

export type MentionRemoteSelectContext = Omit<
  MentionPluginProps,
  "isMentionOpen"
> & {
  isOpen(): boolean;
  show(): void;
  updateAnchorRect(offsetKey: string, func: () => DOMRect | null): void;
  getClientRectFn(offsetKey: string): () => DOMRect | null;
  registerMentionSelectState(state: MentionSuggestionsSelectState): () => void;
  onEditorStateChange?(state: RichEditorState): void | undefined;
  getMentionSelectState(): MentionSuggestionsSelectState;
  registerOnEditorStateChange(
    callback: (state: RichEditorState) => void | undefined,
  ): () => void;
};

type MentionRemoteSelectProviderProps = {
  children: ReactElement;
};

const MentionRemoteSelectContext =
  createContext<MentionRemoteSelectContext | null>(null);

export const MentionRemoteSelectProvider = ({
  children,
}: MentionRemoteSelectProviderProps) => {
  const clientRectFunctions = useRef(Map<string, () => DOMRect | null>());

  const mentionSelectStateRef = useRef<
    MentionSuggestionsSelectState | undefined
  >();
  const onEditorStateChangeRef = useRef<
    MentionRemoteSelectContext["onEditorStateChange"] | undefined
  >();

  const value: MentionRemoteSelectContext = useMemo(() => {
    const getMentionSelectState = () => {
      if (!mentionSelectStateRef.current) {
        throw new Error(
          "Mention select has not been registered, call 'registerMentionSelectState' before",
        );
      }
      return mentionSelectStateRef.current;
    };
    return {
      isOpen: () => mentionSelectStateRef.current?.isOpen() ?? false,
      updateAnchorRect: (offsetKey, func) => {
        clientRectFunctions.current = clientRectFunctions.current.set(
          offsetKey,
          func,
        );
      },
      getClientRectFn: (offsetKey) =>
        clientRectFunctions.current.get(offsetKey),

      registerMentionSelectState: (state) => {
        if (!mentionSelectStateRef.current) {
          mentionSelectStateRef.current = state;
        }
        return () => {
          mentionSelectStateRef.current = undefined;
        };
      },
      getMentionSelectState,
      show: () => mentionSelectStateRef.current?.state.combobox.show(),

      onDownArrow: (event) => getMentionSelectState().onDownArrow?.(event),
      onUpArrow: (event) => getMentionSelectState().onUpArrow?.(event),
      onEscape: (event) => getMentionSelectState().onEscape?.(event),
      onTab: (event) => getMentionSelectState().onTab?.(event),
      onMove: (event) => getMentionSelectState().onMove?.(event),
      handleReturn: (event) =>
        getMentionSelectState().handleReturn?.(event) ?? "not-handled",

      registerOnEditorStateChange: (callback) => {
        if (!onEditorStateChangeRef.current) {
          onEditorStateChangeRef.current = callback;
        }
        return () => {
          onEditorStateChangeRef.current = undefined;
        };
      },
      onEditorStateChange: (event) => onEditorStateChangeRef.current?.(event),
    };
  }, []);

  return (
    <MentionRemoteSelectContext.Provider value={value}>
      {children}
      <MentionSuggestions />
    </MentionRemoteSelectContext.Provider>
  );
};
export const useMentionRemoteSelect = () => {
  const mentionRemoteSelectContext = useContext(MentionRemoteSelectContext);
  if (!mentionRemoteSelectContext) {
    throw new Error(`MentionRemoteSelectContext is missing`);
  }
  return mentionRemoteSelectContext;
};
