// @ts-check
import { CharacterMetadata, ContentBlock, ContentState } from "draft-js-es";
import { Map as createImmutableMap } from "immutable-es";
import { memoize } from "lodash-es";

import { convertFromHTML } from "./convertFromHTML";
import { memoizeCapped } from "./util/memoizeCapped";

let ID_IDX = 0;

const getSeqId = () => `seq-${ID_IDX++}`;

const getKeyFromId = (/** @type {string | number} */ id) => `node-${id}`;

/** @type {() => ContentState} */
const getEmptyContentState = memoize(() => ContentState.createFromText(""));

/** @typedef {{ entityMap: import('immutable').Map<any, any>, contentState: ContentState, convertText: (text: string) => ContentState }} State */
/** @typedef {{enhanceTextBlock: EnhanceTextBlock}} CTX */

const getConvertTextFromPlugins = memoize((plugins) => {
  return memoizeCapped(
    /** @type {(text: string) => import('draft-js').ContentState} */ (text) =>
      convertFromHTML(text, plugins, "simple"),
  );
});
getConvertTextFromPlugins.cache = new WeakMap();

/**
 * Create atom block.
 * @param {object} params
 * @param {string} params.key
 * @param {string} params.type
 * @param {'MUTABLE' | 'IMMUTABLE'} [params.mutability]
 * @param {{ [key: string]: any }} [params.data]
 * @param {State} state
 * @returns {ContentBlock}
 */
const createAtomicBlock = (
  { key, type, mutability = "IMMUTABLE", data },
  state,
) => {
  const entityContentState = state.contentState.createEntity(
    type,
    mutability,
    data,
  );
  const entityKey = entityContentState.getLastCreatedEntityKey();
  const newBlock = /** @type {ContentBlock} */ (
    new ContentBlock({
      text: " ",
      key,
      type: "atomic",
    })
  );
  return /** @type {ContentBlock} */ (
    newBlock.set(
      "characterList",
      newBlock
        .getCharacterList()
        .map((char) =>
          char ? CharacterMetadata.applyEntity(char, entityKey) : char,
        ),
    )
  );
};

/** @typedef {{commentId: number, resolved: boolean, listIndex?: number, startOffset: number, endOffset: number}} Comment */
/** @typedef {{key: string, type: string, index: number, text:string, data?: Record<string, any>, comments?: Comment[]}} TextBlockParams  */

/**
 * Create text block.
 * @param {TextBlockParams} params
 * @param {State} state
 * @param {CTX} ctx
 * @returns {ContentBlock}
 */
const createTextBlock = (params, state, ctx) => {
  const { key, type, data, text } = params;
  let contentState = state.convertText(text);
  let block = contentState.getFirstBlock();
  block = /** @type {ContentBlock} */ (block.set("key", key));

  block = /** @type {ContentBlock} */ (block.set("key", key));
  block = /** @type {ContentBlock} */ (block.set("type", type));

  if (data) {
    block = /** @type {ContentBlock} */ (
      block.set("data", createImmutableMap(data))
    );
  }

  state.entityMap = state.entityMap.merge(contentState.getEntityMap());

  return ctx.enhanceTextBlock(block, params, state);
};

/**
 * @param {any} node
 * @param {number} index
 * @param {State} state
 * @param {CTX} ctx
 */
const nodeToBlock = (node, index, state, ctx) => {
  const key = node.id ? getKeyFromId(node.id) : getSeqId();
  switch (node.type) {
    case "text": {
      /** @type {Record<string, any>} */
      const data = {};
      const styleName = node.metadata.styleName;
      if (styleName) {
        data["styleName"] = styleName;
      }
      const params = {
        key,
        index,
        text: node.text,
        data,
        comments: node.comments,
      };

      switch (node.metadata.type) {
        case "paragraph":
          return createTextBlock(
            {
              ...params,
              type: "paragraph",
            },
            state,
            ctx,
          );
        case "unordered_list_item":
          return createTextBlock(
            {
              ...params,
              type: "unordered-list-item",
            },
            state,
            ctx,
          );
        case "ordered_list_item":
          return createTextBlock(
            {
              ...params,
              type: "ordered-list-item",
            },
            state,
            ctx,
          );
        case "blockquote":
          return createTextBlock(
            {
              ...params,
              type: "blockquote",
            },
            state,
            ctx,
          );
        case "header_two":
          return createTextBlock(
            {
              ...params,
              type: "header-two",
            },
            state,
            ctx,
          );
        case "header_three":
          return createTextBlock(
            {
              ...params,
              type: "header-three",
            },
            state,
            ctx,
          );
        default:
          throw new Error(`Unknown text block type "${node.metadata.type}"`);
      }
    }
    case "separator": {
      return createAtomicBlock(
        {
          key,
          type: "SEPARATOR",
        },
        state,
      );
    }
    case "article": {
      const metadata = node.metadata ?? node.articleMetadata;
      return createAtomicBlock(
        {
          key,
          type: "ARTICLE",
          data: {
            id: node.articlesId,
            title: metadata?.title,
            prefix: metadata?.prefix,
            displayMode: metadata?.displayMode,
            media: {
              id: node.article.id,
              url: node.article.url,
              title: node.article.title,
            },
          },
        },
        state,
      );
    }
    case "contribution": {
      return createAtomicBlock(
        {
          key,
          type: "CONTRIBUTION",
          data: {
            id: node.contributionsId,
            media: {
              id: node.contribution.id,
              authorName: node.contribution.authorName,
              text: node.contribution.text,
            },
          },
        },
        state,
      );
    }
    case "tweet": {
      return createAtomicBlock(
        {
          key,
          type: "TWEET",
          data: {
            id: node.tweetsId,
            media: {
              id: node.tweet.id,
              url: node.tweet.url,
              text: node.tweet.text,
            },
          },
        },
        state,
      );
    }
    case "image": {
      return createAtomicBlock(
        {
          key,
          type: "IMAGE",
          data: {
            id: node.imagesId,
            caption: node.imageMetadata?.caption,
            aspectRatioId: node.imageMetadata?.aspectRatioId,
            crops: node.imageMetadata?.crops,
            media: {
              id: node.image.id,
              url: node.image.url,
              caption: node.image.caption,
            },
          },
        },
        state,
      );
    }
    case "article_media": {
      return createAtomicBlock(
        {
          key,
          type: "ARTICLE_MEDIA",
          data: {
            id: node.articleMedia.id,
            type: node.articleMedia.__typename,
            media: {
              id: node.articleMedia.media.id,
              url: node.articleMedia.media.url,
              videoTitle: node.articleMedia.media.videoTitle,
              snippetTitle: node.articleMedia.media.snippetTitle,
              code: node.articleMedia.media.code,
              caption: node.articleMedia.media.caption,
              text: node.articleMedia.media.text,
              authorName: node.articleMedia.media.authorName,
              title: node.articleMedia.media.title,
              label: node.articleMedia.media.label,
              name: node.articleMedia.media.name,
              __typename: node.articleMedia.media.__typename,
            },
          },
        },
        state,
      );
    }
    case "video": {
      return createAtomicBlock(
        {
          key,
          type: "VIDEO",
          data: {
            id: node.videosId,
            media: {
              id: node.video.id,
              url: node.video.url,
              videoTitle: node.video.videoTitle,
            },
          },
        },
        state,
      );
    }
    case "snippet": {
      return createAtomicBlock(
        {
          key,
          type: "SNIPPET",
          data: {
            id: node.snippetsId,
            media: {
              id: node.snippet.id,
              url: node.snippet.url,
              snippetTitle: node.snippet.snippetTitle,
              code: node.snippet.code,
            },
          },
        },
        state,
      );
    }
    case "product": {
      return createAtomicBlock(
        {
          key,
          type: "PRODUCT",
          data: { id: node.productsId },
        },
        state,
      );
    }
    case "product_summary": {
      return createAtomicBlock(
        {
          key,
          type: "PRODUCT_SUMMARY",
          data: { id: node.productsSummariesId },
        },
        state,
      );
    }
    case "custom_types_content": {
      return createAtomicBlock(
        {
          key,
          type: "CUSTOM_TYPES_CONTENT",
          data: { id: node.customTypesContentsId },
        },
        state,
      );
    }
    default:
      throw new Error(`Unknown block type "${node.type}"`);
  }
};

/** @typedef {( block: ContentBlock, params: TextBlockParams, state: State ) => ContentBlock} EnhanceTextBlock */
/**
 * @param {any[]} plugins
 * @returns {EnhanceTextBlock}
 */
const getEnhancedTextBlockFromPlugins = (plugins) => {
  /** @type {EnhanceTextBlock[]} */
  const enhanceTextBlock = plugins
    .filter((plugin) => plugin.enhanceTextBlock)
    .map((plugin) => plugin.enhanceTextBlock);
  /** @type {EnhanceTextBlock} */
  return (block, params, state) => {
    return enhanceTextBlock.reduce(
      (block, enhance) => enhance(block, params, state),
      block,
    );
  };
};

/**
 * Convert nodes to content state.
 * @param {any[]} nodes
 * @param {any[]} plugins
 * @returns {ContentState}
 */
export const fromNodes = (nodes, plugins) => {
  if (!nodes.length) return getEmptyContentState();
  /** @type {CTX} */
  const ctx = {
    enhanceTextBlock: getEnhancedTextBlockFromPlugins(plugins),
  };

  /** @type {State} */
  const state = {
    convertText: getConvertTextFromPlugins(plugins),
    contentState: getEmptyContentState(),
    entityMap: createImmutableMap(),
  };

  return ContentState.createFromBlockArray(
    nodes.map((node, index) => nodeToBlock(node, index, state, ctx)),
    state.entityMap,
  );
};
