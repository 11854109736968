import * as React from "react";
import { forwardRef } from "react";

const SvgHandHello = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#hand-hello_svg__a)">
      <path
        d="M12.202 3.728c-.54.003-.95.468-.928 1.013l.193 2.836a.211.211 0 0 1-.305.206.212.212 0 0 1-.091-.085l-3.545-6.14c-.21-.364-.7-.434-1.064-.224-.365.21-.529.658-.318 1.022l2.368 4.102a.34.34 0 0 1-.11.46.333.333 0 0 1-.46-.118l-3.1-5.369c-.21-.364-.65-.463-1.014-.252-.365.21-.506.644-.296 1.008l3.095 5.36a.34.34 0 0 1-.111.46.333.333 0 0 1-.46-.118L3.44 3.359c-.21-.365-.68-.447-1.044-.236-.364.21-.513.683-.303 1.048l2.838 4.914a.34.34 0 0 1-.111.46.333.333 0 0 1-.46-.118L2.47 6.153c-.21-.364-.653-.46-1.018-.25-.365.21-.503.647-.292 1.006l3.266 5.657c1.776 3.075 4.547 3.521 6.852 2.19l.419-.242c2.305-1.33 2.432-2.786 2.231-4.36l-.756-5.466c-.099-.571-.43-.964-.97-.96Z"
        fill="#1B5369"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.67.62a.333.333 0 0 1 .377-.283 3.647 3.647 0 0 1 1.613.673c.55.418 1.007 1.064 1.007 1.99A.333.333 0 1 1 15 3c0-.674-.321-1.139-.743-1.46a2.982 2.982 0 0 0-1.304-.543.333.333 0 0 1-.283-.378ZM3.33 15.38a.333.333 0 0 1-.378.283 3.647 3.647 0 0 1-1.612-.673C.79 14.572.333 13.926.333 13A.333.333 0 0 1 1 13c0 .674.321 1.139.743 1.46.432.328.959.494 1.304.543a.333.333 0 0 1 .283.377Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.67 1.953a.333.333 0 0 1 .377-.283c.266.038.656.16.988.412.342.26.632.668.632 1.251a.333.333 0 1 1-.667 0 .865.865 0 0 0-.368-.72c-.224-.17-.5-.258-.679-.283a.333.333 0 0 1-.283-.377ZM3.33 14.047a.333.333 0 0 1-.378.283 2.226 2.226 0 0 1-.987-.412 1.53 1.53 0 0 1-.632-1.251.333.333 0 1 1 .667 0c0 .331.154.558.368.72.224.17.5.258.679.283a.333.333 0 0 1 .283.377Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="hand-hello_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgHandHello);
export default ForwardRef;
