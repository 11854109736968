import { Droppable } from "react-beautiful-dnd";

import { CapsuleList } from "@/components/CapsuleList";
import { DND_TYPES } from "@/config/constants";

export function DroppableCapsuleList({
  children,
  droppableId,
  dropDisabled,
  disabled,
}) {
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={dropDisabled || disabled}
      type={DND_TYPES.CAPSULE}
    >
      {(provided) => (
        <CapsuleList
          ref={provided.innerRef}
          aria-disabled={disabled}
          data-testid="droppable"
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </CapsuleList>
      )}
    </Droppable>
  );
}
