import styled, { css } from "@xstyled/styled-components";
import { Button as AriakitButton } from "ariakit/button";
import * as React from "react";

const InnerControlButton = styled.buttonBox`
  display: flex;
  flex-shrink: 0;
  border: 1;
  border-color: transparent;
  border-radius: base;
  font-size: 18;
  color: dusk;
  line-height: 20px;
  width: 28;
  height: 28;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0;

  & > svg {
    width: 26;
    height: 25;
  }

  &:hover {
    border-color: dusk;
  }

  &:disabled {
    opacity: 0.38;
  }

  &[aria-pressed="true"] {
    background-color: dusk;
    color: white;
    & > svg {
      color: white;
    }
  }

  &:focus {
    outline: none;
    box-shadow: glow;
  }

  ${({ forcedFocus }) =>
    forcedFocus &&
    css`
      outline: none;
      box-shadow: glow;
    `}
`;

export const ControlButton = React.forwardRef(
  ({ as = InnerControlButton, ...props }, ref) => {
    return <AriakitButton ref={ref} as={as} {...props} />;
  },
);
