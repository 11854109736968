import { setBlockTypeAndStyle } from "../modifiers/setBlockTypeAndStyle";
import { BLOCK_STYLE_TYPE } from "./unordered-list-item-convert";

export * from "./unordered-list-item-convert";

export const name = "unordered-list-item";
export const command = "unordered-list-item";

export const handleKeyCommand = ({ setEditorState }, cmd) => {
  if (cmd === command) {
    setEditorState((editorState) =>
      setBlockTypeAndStyle(editorState, BLOCK_STYLE_TYPE),
    );
    return true;
  }
  return undefined;
};

/**
 * Whether the editor should show its placeholder.
 * See https://draftjs.org/docs/api-reference-editor.html#placeholder
 * for details on why this is useful.
 */
export const hidePlaceholder = (editorState) => {
  const contentState = editorState.getCurrentContent();
  return contentState.getBlockMap().first()?.getType() === name;
};
