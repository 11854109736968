import { cn } from "swash/utils/classNames";

export const Pane = ({ className, ...props }) => (
  <div
    className={cn(
      className,
      "border-l-4 border-l-grey-border-strong bg-dusk-bg-stronger",
    )}
    {...props}
  />
);

export const EditorPanel = ({ className, ...props }) => (
  <div
    className={cn(
      className,
      "flex h-full w-full max-w-full flex-col border-t-4 border-grey-border-strong bg-dusk-bg-stronger",
    )}
    {...props}
  />
);

export const EditorPanelHeader = (props) => (
  <div
    className="border-b-1 shrink-0 border-grey-border-strong p-2"
    {...props}
  />
);

export const EditorPanelHeaderTitle = ({ children }) => (
  <h3 className="font-accent text-base font-semibold text-grey-on">
    {children}
  </h3>
);

export const EditorPanelBody = (props) => (
  <div className="relative shrink-0 grow" {...props} />
);

export const StatusBar = (props) => (
  <div
    className="flex justify-between bg-dusk-bg-stronger text-white"
    {...props}
  />
);

export const StatusBarGroup = (props) => <div className="flex" {...props} />;

export const StatusBarItem = ({ className, ...props }) => (
  <div className={cn(className, "inline-flex p-2")} {...props} />
);
