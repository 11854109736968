import { useParams } from "react-router-dom";

export class RouteError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
  }
}

export const useIdParam = (name) => {
  const params = useParams();
  const param = params[name];
  if (!param) {
    throw new RouteError(`"${name}" param not found`, 404);
  }
  const numParam = parseInt(param, 10);
  if (numParam === 0) {
    throw new RouteError(`Invalid id 0`, 404);
  }
  if (Number.isNaN(numParam)) {
    throw new RouteError(`Invalid id NaN`, 404);
  }
  if (numParam > 2147483647) {
    throw new RouteError(
      `"${name}" param is not a valid 32-bit signed integer`,
      404,
    );
  }
  return numParam;
};
