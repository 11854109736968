import styled, { th } from "@xstyled/styled-components";
import { Editor, EditorState, Modifier } from "draft-js-es";
import "draft-js/dist/Draft.css";
import { forwardRef, memo, useCallback, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { Button } from "swash/Button";
import { IoAddCircleOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { useNextFrameTicked } from "swash/motion/Animation";

import { useRemoteConfig } from "@/containers/RemoteConfig";

import { RichEditorContextProvider } from "./RichEditorContext";
import { addBlock } from "./modifiers/addBlock";
import { setContent } from "./modifiers/setContent";
import { hasCommandModifier } from "./utils";
import { useCopyModifier } from "./utils/useCopyModifier";
import { useSiriusPasteHandler } from "./utils/useSiriusPasteHandler";

const EditorContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  cursor: text;

  figure {
    padding: 0;
    margin: 0;
  }

  &[data-serif] {
    div[data-block-type="paragraph"] {
      font-family: Georgia, serif;
    }
  }

  &[data-appearance="editor"] {
    &[data-multiline] {
      .public-DraftEditorPlaceholder-root {
        padding: 1;
        padding-left: 30px;
        color: placeholder;
      }

      [data-appearance="textBox"] {
        .public-DraftEditorPlaceholder-root {
          padding: 0;
          color: placeholder;
        }
      }
    }
  }

  &[data-hide-placeholder] {
    .public-DraftEditorPlaceholder-root {
      display: none;
    }
  }

  .public-DraftStyleDefault-ul {
    margin: 0;
  }

  .public-DraftStyleDefault-ol {
    margin: 0;
    counter-reset: list;
  }

  .public-DraftStyleDefault-orderedListItem {
    counter-increment: list;
  }

  .public-DraftStyleDefault-unorderedListItem,
  .public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    margin-left: 0;
  }

  .public-DraftStyleDefault-orderedListItem:before {
    content: none;
  }

  .public-DraftStyleDefault-block {
    word-break: break-word;
    white-space: ${({ whiteSpace }) => whiteSpace};
  }

  .public-DraftStyleDefault-ltr {
    text-align: inherit;
  }

  blockquote {
    margin: 0;
    --italic-style: normal;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  hr {
    border-top: 1;
    border-color: dusk;
    text-align: center;
  }

  span[data-focus-locked] {
    background-color: grey-lighter;
    box-shadow:
      0 -2px 0 1px ${th.color("grey-lighter")},
      0 2px 0 1px ${th.color("grey-lighter")};
  }

  &[data-scale="lg"] {
    font-size: 18px;

    h2 {
      font-size: 24px;
      line-height: 1.2;
    }

    h3 {
      font-size: 22px;
      line-height: 1.2;
    }
  }
`;

const BlockAddLine = styled.div`
  text-align: center;
  position: relative;
  transition: base;
  margin: 0 12px 0 25px;

  button {
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const customStyleMap = {
  // This is the default style of sub / sup in a browser
  SUBSCRIPT: { fontSize: "0.8em", verticalAlign: "sub" },
  SUPERSCRIPT: { fontSize: "0.8em", verticalAlign: "super" },
  ITALIC: { fontStyle: "var(--italic-style, italic)" },
};

/**
 * @typedef {import('draft-js')} Draft
 */

const AddLine = memo(({ onMouseDown }) => {
  const label = "Ajouter un bloc";
  return (
    <BlockAddLine>
      <Tooltip tooltip={label}>
        <Button
          aria-label={label}
          appearance="text"
          iconOnly
          onMouseDown={onMouseDown}
        >
          <IoAddCircleOutline />
        </Button>
      </Tooltip>
    </BlockAddLine>
  );
});

const handled = () => "handled";

/**
 * @param {import('./RichEditorState').RichEditorState} props
 */
export const RichEditor = forwardRef(
  (
    { onFocus, onBlur, placeholder, appearance = "editor", scale, ...props },
    externalRef,
  ) => {
    const containerRef = useRef();
    const ticked = useNextFrameTicked();
    const {
      editorState,
      handleKeyCommand,
      setEditorState,
      blockRendererFn,
      keyBindingFn,
      activePlugins,
      readOnly,
      handlePastedText,
      handleReturn,
      focusLocked,
      multiline,
      name,
      stripPastedStyles,
      whiteSpace,
      editorId,
      articleId,
      serif,
      editorRef,
    } = props;

    const ref = useMemo(
      () => mergeRefs([editorRef, externalRef]),
      [editorRef, externalRef],
    );

    const { env } = useRemoteConfig();
    useCopyModifier(
      editorState,
      setEditorState,
      env,
      containerRef,
      editorId,
      articleId,
    );
    useSiriusPasteHandler(
      editorState,
      env,
      containerRef,
      handlePastedText,
      readOnly,
    );

    const handleAddLineMouseDown = useCallback(
      (event) => {
        event.preventDefault();
        setEditorState((editorState) =>
          addBlock(
            editorState,
            { type: "paragraph" },
            editorState.getCurrentContent().getBlockMap().size,
          ),
        );
      },
      [setEditorState],
    );

    /**
     * Fix - OS X "Add period with double-space"
     * feature breaks Editor if invoked after a decorator
     * See {@link https://github.com/facebook/draft-js/issues/2422}
     * @param {string} chars
     * @param {EditorState} editorState
     * @param {number} eventTimeStamp
     * @returns {DraftHandleValue}
     */
    const handleBeforeInput = (chars, editorState, eventTimeStamp) => {
      if (chars === ". ") {
        const currentSelection = editorState.getSelection();
        setEditorState(
          setContent(
            editorState,
            Modifier.replaceText(
              editorState.getCurrentContent(),
              currentSelection,
              " ",
            ),
          ),
        );
        return "handled";
      }
      if (props.handleBeforeInput) {
        return props.handleBeforeInput(chars, editorState, eventTimeStamp);
      }
      return "not-handled";
    };

    const handleClick = ({ target, currentTarget }) => {
      if (target !== currentTarget) return;
      let newEditorState = EditorState.moveSelectionToEnd(editorState);
      newEditorState = EditorState.forceSelection(
        editorState,
        newEditorState.getSelection(),
      );
      setEditorState(newEditorState);
    };

    const handleKeyBindingFn = (event) => {
      if (
        appearance === "textBox" &&
        event.key === "Enter" &&
        hasCommandModifier(event)
      ) {
        return "handled";
      }
      return keyBindingFn(event);
    };

    return (
      <RichEditorContextProvider value={props}>
        <EditorContainer
          whiteSpace={whiteSpace}
          onFocus={onFocus}
          onBlur={focusLocked ? null : onBlur}
          onClick={handleClick}
          data-appearance={appearance}
          data-hide-placeholder={
            activePlugins.some(
              (plugin) =>
                plugin.hidePlaceholder && plugin.hidePlaceholder(editorState),
            )
              ? ""
              : undefined
          }
          data-multiline={multiline ? "" : undefined}
          data-scale={scale}
          data-name={name}
          data-control-container=""
          data-readonly={readOnly}
          data-serif={serif ? "" : undefined}
          ref={containerRef}
        >
          {ticked &&
            activePlugins
              .filter((plugin) => plugin.StaticControls)
              .map((plugin) => (
                <plugin.StaticControls key={plugin.id} {...props} />
              ))}
          <Editor
            ref={ref}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={setEditorState}
            blockRendererFn={blockRendererFn}
            handleDrop={handled}
            handleDroppedFiles={handled}
            handleBeforeInput={handleBeforeInput}
            placeholder={placeholder}
            keyBindingFn={handleKeyBindingFn}
            readOnly={readOnly}
            handlePastedText={() => "handled"}
            handleReturn={handleReturn}
            customStyleMap={customStyleMap}
            stripPastedStyles={stripPastedStyles}
          />
          {multiline && appearance === "editor" && !readOnly && (
            <AddLine onMouseDown={handleAddLineMouseDown} />
          )}
        </EditorContainer>
      </RichEditorContextProvider>
    );
  },
);
