import { useHasLevelAccess, useHasPermission } from "@/containers/User";

import { DisabledAction } from "./DisabledAction";

const useRestrictedLevelReason = ({ level }) => {
  const hasLevelAcces = useHasLevelAccess(level);
  if (hasLevelAcces) return null;
  return "Vous ne disposez pas des droits nécessaires";
};

export const useRestrictedReason = ({ permissions, method }) => {
  const hasPermission = useHasPermission(permissions, { method });
  if (hasPermission) return null;
  return "Vous ne disposez pas des droits nécessaires";
};

export const RestrictedAction = ({ permissions, method, level, children }) => {
  const reason = useRestrictedReason({ permissions, method });
  const levelReason = useRestrictedLevelReason({ level });
  return (
    <DisabledAction reason={levelReason || reason}>{children}</DisabledAction>
  );
};
