import { gql } from "@apollo/client";

import { MediaArchive } from "@/containers/article/medias/Archive";
import { MediaImage } from "@/containers/article/medias/Image";
import { MediaSnippet } from "@/containers/article/medias/Snippet";
import { MediaTweet } from "@/containers/article/medias/Tweet";
import { MediaVideo } from "@/containers/article/medias/Video";
import { apolloClient } from "@/services/apollo/client";

import { fetchMedia } from "./fetchMedia";

const MediaQuery = gql`
  query ArticleMedia_media($id: Int, $url: String, $type: String!) {
    media(id: $id, url: $url, type: $type) {
      id
    }
  }
`;

const ArticleMediaQuery = gql`
  query ArticleMedia_articleMedia(
    $articleId: Int
    $mediaId: Int
    $mediaType: ArticleMediaType
  ) {
    getOrCreateArticleMedia(
      mediaId: $mediaId
      mediaType: $mediaType
      articleId: $articleId
    ) {
      id
      type
      metadata
      media {
        id
        globalId
        ...MediaArchive_media
        ...MediaImage_media__expanded
        ...MediaSnippet_media
        ...MediaTweet_media
        ...MediaVideo_media
        ... on CustomTypeContent {
          label
        }
        ... on Product {
          name
        }
        ... on ProductSummary {
          name
        }
      }
    }
  }

  ${MediaArchive.fragments.media}
  ${MediaImage.fragments.media__expanded}
  ${MediaSnippet.fragments.media}
  ${MediaTweet.fragments.media}
  ${MediaVideo.fragments.media}
`;

/**
 * Fetch article media from id
 * @param {object} params
 * @param {number} [params.articleId]
 * @param {number} [params.id]
 * @param {string} [params.url]
s * @param {import('./providers').MediaProvider["type"]} params.type
 * @return {Promise<object | null>} articleMedia
 */
export async function fetchArticleMedia({ articleId, id, url, type }) {
  try {
    const media = await fetchMedia({
      id,
      url,
      type,
      query: MediaQuery,
    });
    const { data } = await apolloClient.query({
      variables: {
        articleId,
        mediaId: media.id,
        mediaType: type,
      },
      query: ArticleMediaQuery,
      fetchPolicy: "network-only",
    });

    return data.getOrCreateArticleMedia;
  } catch {
    return null;
  }
}
