import { memo } from "react";

export const LoaderRow = memo(() => {
  return (
    <div className="flex grow justify-center p-4 text-center font-accent font-semibold">
      Chargement en cours...
    </div>
  );
});

export const estimateLoaderRowSize = () => {
  return 32;
};
