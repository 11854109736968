import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Manage transition state.
 * | input: false
 * "left"
 * --> input: true
 * "enter"
 * --> stopTransition
 * "entered"
 * --> input: false
 * "leaving"
 * --> stopTransition
 * "left"
 */
function useTransitionState(input: boolean) {
  // Transition state, can be "entering", "entered", "leaving" or "left"
  const [transition, setTransition] = useState(input ? "entered" : "left");

  // Keep a live ref of transition
  const transitionRef = useRef(transition);
  useEffect(() => {
    transitionRef.current = transition;
  });

  // Update transition when visible status change
  const raf = useRef(0);
  useEffect(() => {
    // Double RAF is needed so the browser has enough time to paint the
    // default styles before processing the `data-enter` attribute. Otherwise
    // it wouldn't be considered a transition.
    // See https://github.com/reakit/reakit/issues/643
    raf.current = window.requestAnimationFrame(() => {
      raf.current = window.requestAnimationFrame(() => {
        const { current: transition } = transitionRef;
        if (input && transition !== "entered") {
          setTransition("entering");
        } else if (!input && transition !== "left") {
          setTransition("leaving");
        }
      });
    });
    return () => window.cancelAnimationFrame(raf.current);
  }, [input]);

  const stopTransition = useCallback(() => {
    const { current: transition } = transitionRef;
    if (transition === "entering") {
      setTransition("entered");
    } else if (transition === "leaving") {
      setTransition("left");
    }
  }, []);

  return { transition, stopTransition };
}

/**
 * Manage transition state.
 * | visible: false
 * { hidden: true, enter: false, leave: false }
 * --> visible: true
 * { hidden: false, enter: false, leave: false }
 * // wait for transition end //
 * { hidden: false, enter: true, leave: false }
 * --> visible: false
 * { hidden: false, enter: false, leave: true }
 * // wait for transition end //
 * { hidden: true, enter: false, leave: false }
 */
export function useTransition(visible: boolean) {
  const { transition, stopTransition } = useTransitionState(visible);

  const handleTransitionEnd = useCallback(
    (event: TransitionEvent) => {
      if (event.target !== event.currentTarget) return;
      stopTransition();
    },
    [stopTransition],
  );

  const hidden = !visible && transition === "left";

  return {
    hidden,
    "data-enter":
      transition === "entering" || transition === "entered" ? "" : undefined,
    "data-leave": transition === "leaving" ? "" : undefined,
    onTransitionEnd: handleTransitionEnd,
    style: hidden ? { display: "none" } : null,
  };
}
