import { createContext, useContext } from "react";

import { useStorage } from "@/services/hooks/useStorage";

export function useSetToggledAnalytics() {
  return useContext(SetToggledAnalyticsContext);
}

export function useToggledAnalytics() {
  return useContext(ToggledAnalyticsContext);
}

const SetToggledAnalyticsContext = createContext();
const ToggledAnalyticsContext = createContext();

export function TogglerProvider({
  state: [toggledAnalytics, setToggledAnalytics],
  children,
}) {
  return (
    <ToggledAnalyticsContext.Provider value={toggledAnalytics}>
      <SetToggledAnalyticsContext.Provider value={setToggledAnalytics}>
        {children}
      </SetToggledAnalyticsContext.Provider>
    </ToggledAnalyticsContext.Provider>
  );
}

export function useToggleAnalyticsState() {
  return useStorage("scheduler-analytics");
}
