import * as React from "react";
import { forwardRef } from "react";

const SvgChevronDoubleBackward = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.67 19.546c-.439.439-1.151.439-1.59 0l-6.75-6.75a1.125 1.125 0 0 1 0-1.591l6.75-6.75a1.125 1.125 0 0 1 1.59 1.59L13.716 12l5.955 5.954c.439.44.439 1.152 0 1.591Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.67 19.546c-.439.439-1.151.439-1.59 0l-6.75-6.75a1.125 1.125 0 0 1 0-1.591l6.75-6.75a1.125 1.125 0 0 1 1.59 1.59L5.717 12l5.955 5.954c.439.44.439 1.152 0 1.591Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDoubleBackward);
export default ForwardRef;
