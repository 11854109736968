import { gql } from "@apollo/client";
import { KeyBindingUtil } from "draft-js-es";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { Card } from "swash/Card";
import { EllipsisVertical, IoArrowUp, IoPencil, IoTrash } from "swash/Icon";
import { Menu, MenuButton, MenuItem, useMenuStore } from "swash/Menu";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import { Dialog, useDialogStore } from "swash/v2/Dialog";

import { CommentTextBoxField } from "@/components/fields/CommentTextBoxField";
import { useTextInputField } from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useAmplitude } from "@/containers/Amplitude";
import { useSafeMutation } from "@/containers/Apollo";
import { Comment as DisplayComment } from "@/containers/Comment";
import {
  ActivityTime,
  ModifyBy,
} from "@/containers/admin/CRUD/history/ActivityInfo";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";
import { useStorage } from "@/services/hooks/useStorage";
import { generateShortcut } from "@/services/shortcuts";

const isOSX = KeyBindingUtil.usesMacOSHeuristics();

const CommentFragment = gql`
  fragment CommentFragment on ActivityItem {
    user {
      id
      firstNameInitials
      lastName
    }
    date
    ... on Comment {
      id
      ...Comment_comment
      modifiedAt
      mine
    }
  }
  ${DisplayComment.fragments.comment}
`;

const CreateCommentMutation = gql`
  mutation CommentForm_createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      globalId
      ...CommentFragment
    }
  }
  ${CommentFragment}
`;

const UpdateCommentMutation = gql`
  mutation CommentForm_updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      ...Comment_comment
    }
  }
  ${DisplayComment.fragments.comment}
`;

const DeleteCommentMutation = gql`
  mutation DeleteCommentMutation($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
    }
  }
`;

export const CommentFormContent = forwardRef(
  (
    {
      disabled: rootDisabled,
      onCommentChange,
      onDiscard,
      autoFocus,
      placeholder,
      confirmMessage = "Commenter",
      className,
      context,
      scale = "sm",
      showFormButtons = true,
    },
    ref,
  ) => {
    const form = useForm();
    const field = useTextInputField("comment");
    const comment = useSubscribeFormValue("comment");
    const disabled = rootDisabled || !comment?.value?.trim();
    const commentButtonRef = useRef();
    const [editorFocused, setEditorFocused] = useState(false);
    const showButtons = showFormButtons || editorFocused;
    const onCommentChangeRef = useLiveRef(onCommentChange);

    const edit = context === "update";

    useEffect(() => {
      if (onCommentChangeRef.current) {
        onCommentChangeRef.current(comment);
      }
    }, [comment, onCommentChangeRef]);

    const handleDiscard = useEventCallback(() => {
      if (onDiscard) {
        onDiscard(form);
      }
      setEditorFocused(false);
    });

    const handleKeyDown = useEventCallback((event) => {
      if (event.key === "Escape") onDiscard(form);
      if ((isOSX ? event.metaKey : event.ctrlKey) && event.key === "Enter") {
        // The rich editor should lose focus before submission; otherwise, the content state won't be updated.
        event.target.blur();
        form.submit();
      }
    });

    const toggleBlur = useEventCallback((event) => {
      if (event.currentTarget.contains(event.relatedTarget)) return;
      setEditorFocused(false);
    });

    const toggleFocus = useEventCallback(() => {
      setEditorFocused(true);
    });

    return (
      <div className="flex w-full flex-col" onBlur={toggleBlur}>
        <div
          role="textbox"
          className={cn(
            "relative flex w-full flex-col rounded-md border border-grey-100 bg-white transition focus-within:border-blue-300 hover:border-blue-300",
            className,
          )}
        >
          <div data-testid="comment-content">
            <CommentTextBoxField
              ref={ref}
              {...field}
              readOnly={rootDisabled}
              placeholder={placeholder}
              className={scale === "sm" ? "[&>div]:p-2" : ""}
              onKeyDown={handleKeyDown}
              autoFocus={autoFocus}
              onFocus={toggleFocus}
            />
          </div>
        </div>
        <>
          {edit ? (
            <div className="flex justify-end gap-2 pt-2">
              <Button
                type="button"
                variant="secondary"
                appearance="text"
                scale={scale}
                onClick={handleDiscard}
              >
                Annuler
              </Button>
              <FormSubmit scale={scale} disabled={disabled}>
                Valider
              </FormSubmit>
            </div>
          ) : showButtons ? (
            <div className="flex justify-end gap-2 pt-2">
              <Button
                type="button"
                variant="secondary"
                appearance="text"
                scale={scale}
                onClick={handleDiscard}
              >
                Annuler
              </Button>
              <Tooltip
                tooltip={
                  <div className="px-2 text-center">
                    {confirmMessage}
                    <div className="text-grey-on">
                      {generateShortcut(["⌘", "Entrée"])}
                    </div>
                  </div>
                }
              >
                <FormSubmit
                  ref={commentButtonRef}
                  scale={scale}
                  disabled={disabled}
                  onClick={toggleBlur}
                >
                  <IoArrowUp />
                  {confirmMessage}
                </FormSubmit>
              </Tooltip>
            </div>
          ) : null}
        </>
      </div>
    );
  },
);

export const formatMentions = (mentions) =>
  mentions.map(({ __typename, ...mention }) => ({
    ...mention,
    data: {
      id: mention.data.id,
      typename: mention.data.__typename,
    },
  }));

export const CreateCommentForm = ({ resource, resourceId, scope }) => {
  const { logEvent } = useAmplitude();
  const storageIdentifier = ["comment-value", resource, resourceId, scope]
    .filter(Boolean)
    .join("-");
  const [storedComment, setStoredComment] = useStorage(storageIdentifier, null);
  const [createComment, { loading }] = useSafeMutation(CreateCommentMutation);

  const emptyCommentRef = useRef({ value: "", mentions: [] });
  const initialValues = useMemo(
    () => ({ comment: storedComment ?? emptyCommentRef.current }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleReset = useCallback(
    (form) => {
      form.reset({ comment: emptyCommentRef.current });
    },
    [emptyCommentRef],
  );

  return (
    <Form
      aria-label="Ajouter un commentaire"
      initialValues={initialValues}
      onSubmit={async (values, form) => {
        if (values.comment) {
          logEvent("comment:publish", {
            resource,
            scope,
            firstPublish: true,
            mentions: values.comment.mentions.length,
          });
          await createComment({
            variables: {
              input: {
                scope,
                value: values.comment.value,
                mentions: formatMentions(values.comment.mentions),
                resource,
                resourceId: resourceId || 1,
              },
            },
          });
          handleReset(form);
        }
      }}
    >
      <CommentFormContent
        disabled={loading}
        onCommentChange={setStoredComment}
        placeholder="Ajouter un commentaire..."
        onDiscard={handleReset}
      />
    </Form>
  );
};

export const UpdateCommentForm = ({ comment, onSubmit, onDiscard }) => {
  const { logEvent } = useAmplitude();
  const [updateComment, { loading }] = useSafeMutation(UpdateCommentMutation);
  return (
    <Form
      aria-label="Modifier un commentaire"
      initialValues={{
        comment: { value: comment.value, mentions: comment.mentions },
      }}
      onSubmit={async (values) => {
        logEvent("comment:publish", {
          resource: comment.resource,
          scope: comment.scope,
          firstPublish: false,
          mentions: values.comment.mentions.length,
        });
        await updateComment({
          variables: {
            input: {
              id: comment.id,
              value: values.comment.value,
              mentions: formatMentions(values.comment.mentions),
            },
          },
        });
        onSubmit();
      }}
    >
      <CommentFormContent
        context="update"
        disabled={loading}
        onDiscard={onDiscard}
        autoFocus
        placeholder="Modifier le commentaire..."
      />
    </Form>
  );
};

export const CommentDeleteDialog = memo(
  ({ commentId, queryOptions, onClose }) => {
    const dialog = useDialogStore();
    const refs = useLiveRef({ onClose, dialog });
    const visible = dialog.useState("open");
    const previousVisible = usePrevious(visible);

    const [deleteComment, { loading }] = useSafeMutation(DeleteCommentMutation);

    useEffect(() => {
      if (previousVisible !== undefined && previousVisible && !visible) {
        refs.current.onClose();
      }
    }, [previousVisible, visible, refs]);

    useEffect(() => {
      if (commentId) {
        refs.current.dialog.show();
      } else {
        refs.current.dialog.hide();
      }
    }, [commentId, refs]);

    const handleConfirmClick = () => {
      deleteComment({
        variables: { input: { id: commentId } },
        optimisticResponse: {
          __typename: "Mutation",
          deleteComment: {
            id: commentId,
          },
        },
        update: (cache, { data: { deleteComment } }) => {
          const data = cache.readQuery(queryOptions);
          cache.writeQuery({
            ...queryOptions,
            data: {
              activities: {
                ...data.activities,
                nodes: data.activities.nodes.filter((node) => {
                  if (node.__typename !== "CommentThread") return true;
                  return node.id !== deleteComment.id;
                }),
              },
            },
          });
        },
      }).then(() => {
        dialog.hide();
      });
    };

    return (
      <Dialog store={dialog}>
        <Form onSubmit={() => dialog.hide()}>
          <PanelHeader title="Supprimer le commentaire" onClose={dialog.hide} />
          <PanelBody>
            Êtes-vous sûr de vouloir supprimer ce commentaire ?
          </PanelBody>
          <PanelFooter>
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              disabled={loading}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              type="button"
              variant="danger"
              disabled={loading}
              onClick={handleConfirmClick}
            >
              Supprimer le commentaire
            </Button>
          </PanelFooter>
        </Form>
      </Dialog>
    );
  },
);

export const Comment = ({ comment, setDeletingCommentId }) => {
  const { user, date, mine } = comment;
  const menu = useMenuStore();
  const [modifiedAt, setModifiedAt] = useState(comment.modifiedAt);
  const [readOnly, setReadOnly] = useState(true);
  const userDenomination = `${user.firstNameInitials} ${user.lastName}`;

  return (
    <>
      <Card className="flex w-full flex-col gap-2 pb-4 pl-4 pr-2 pt-2">
        <div className="flex items-center justify-between">
          <span className="text-sm leading-6">
            <UserHoverCardTooltip user={user}>
              <span className="font-semibold">{userDenomination}</span>
            </UserHoverCardTooltip>
            {" a commenté"}
            <ActivityTime date={date} />
            {modifiedAt && (
              <ModifyBy user={userDenomination} date={modifiedAt} />
            )}
          </span>
          {mine && (
            <>
              <MenuButton asChild store={menu}>
                <Button
                  appearance="text"
                  variant="secondary"
                  aria-label="Actions"
                  scale="sm"
                  iconOnly
                  className="opacity-0 group-hover:opacity-100 aria-expanded:opacity-100"
                >
                  <EllipsisVertical />
                </Button>
              </MenuButton>
              <Menu store={menu} aria-label="Actions" portal>
                <MenuItem
                  onClick={() => {
                    setReadOnly(false);
                    menu.hide();
                  }}
                >
                  <IoPencil />
                  Modifier
                </MenuItem>
                <MenuItem
                  variant="danger"
                  onClick={() => {
                    setDeletingCommentId(comment.id);
                  }}
                >
                  <IoTrash />
                  Supprimer
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
        {readOnly ? (
          <DisplayComment comment={comment} />
        ) : (
          <UpdateCommentForm
            comment={comment}
            onSubmit={() => {
              setReadOnly(true);
              setModifiedAt(Date.now());
            }}
            onDiscard={() => setReadOnly(true)}
          />
        )}
      </Card>
    </>
  );
};

Comment.fragments = {
  comment: CommentFragment,
};
