import { memo } from "react";
import { IoNotifications } from "react-icons/io5";
import { Button } from "swash/Button";
import {
  IoEllipsisHorizontal,
  IoNotificationsOffOutline,
  IoNotificationsOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

const renderLoadingButton = ({ scale, appearance }) => (
  <Button
    scale={scale}
    disabled
    variant="secondary"
    appearance={appearance}
    aria-busy="true"
  >
    <IoEllipsisHorizontal />
  </Button>
);

const renderWatchingButton = ({ api, ...props }) => (
  <Tooltip tooltip="Ne plus suivre les commentaires">
    <div className="group">
      <Button
        variant="secondary"
        iconOnly
        appearance="text"
        className="group-hover:hidden"
        {...props}
      >
        <IoNotifications />
      </Button>
      <Button
        variant="danger"
        iconOnly
        appearance="text"
        className="hidden group-hover:block"
        aria-label="Ne plus suivre les commentaires"
        onClick={() => {
          api.unwatch();
        }}
        {...props}
      >
        <IoNotificationsOffOutline />
      </Button>
    </div>
  </Tooltip>
);

const renderIgnoredButton = ({ api, ...props }) => (
  <Tooltip tooltip="Suivre les commentaires">
    <Button
      variant="secondary"
      iconOnly
      appearance="text"
      className="group"
      aria-label="Suivre les commentaires"
      onClick={() => {
        api.watch();
      }}
      {...props}
    >
      <IoNotificationsOffOutline className="group-hover:hidden" />
      <IoNotificationsOutline className="hidden group-hover:block" />
    </Button>
  </Tooltip>
);

const WatchStatusRender = {
  loading: renderLoadingButton,
  watching: renderWatchingButton,
  ignored: renderIgnoredButton,
};

export const WatchButton = memo(({ api, status, ...props }) => {
  const render = WatchStatusRender[status];
  if (!render) return null;
  return render({ api, ...props });
});
