import { ContentBlock } from "draft-js-es";

import { getSelectedBlocks } from "./Selection";

/**
 * Get selected blocks content & offsets
 * @param {import('draft-js').ContentState} contentState
 * @param {import('draft-js').SelectionState} selectionState
 * @returns {{selectedBlocks: import('draft-js').ContentBlock[], offsets: {anchorOffset: number, focusOffset: number}}}
 */
export const extractSelectedBlocksContent = (contentState, selectionState) => {
  const { isBackward, anchorKey, focusKey, anchorOffset, focusOffset } =
    selectionState.toObject();

  const startKey = isBackward ? focusKey : anchorKey;
  const startOffset = isBackward ? focusOffset : anchorOffset;
  const endKey = isBackward ? anchorKey : focusKey;
  const endOffset = isBackward ? anchorOffset : focusOffset;
  const onlyOneBlockSelected = startKey === endKey;

  const blocks = getSelectedBlocks({
    contentState: contentState,
    startKey,
    endKey,
  });

  const selectedBlocks = blocks.map((block) => {
    const key = block.getKey();
    let selectedBlock = {
      key,
      text: block.getText(),
      characterList: block.getCharacterList(),
      data: block.getData(),
      type: block.getType(),
    };

    if (key === startKey && onlyOneBlockSelected) {
      // Handle media blocks
      if (startOffset === endOffset && selectedBlock.type === "atomic") {
        return block;
      }
      selectedBlock.characterList = selectedBlock.characterList
        .take(endOffset)
        .skip(startOffset);
      selectedBlock.text = selectedBlock.text.slice(startOffset, endOffset);

      return new ContentBlock(selectedBlock);
    }
    if (key === startKey) {
      selectedBlock.characterList =
        selectedBlock.characterList.skip(startOffset);
      selectedBlock.text = selectedBlock.text.slice(startOffset);

      return new ContentBlock(selectedBlock);
    }
    if (key === endKey) {
      selectedBlock.characterList = selectedBlock.characterList.take(endOffset);
      selectedBlock.text = selectedBlock.text.slice(0, endOffset);

      return new ContentBlock(selectedBlock);
    }

    return block;
  });

  return { selectedBlocks, offsets: { anchorOffset, focusOffset } };
};
