import { memo } from "react";

import { UnorderedList } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { checkIsBlockActive, handleBinding } from "../utils";
import { BLOCK_STYLE_TYPE, command } from "./unordered-list-item";

const SHORTCUT = ["⌘", "l"];

export const shortcuts = [
  {
    description: "Liste non ordonnée",
    shortcut: SHORTCUT,
    category: "Blocs",
  },
];

export const name = "unordered-list-item-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const UnorderedListToolbarItem = memo(({ active }) => {
  return (
    <RichEditorToolbarButton
      command={command}
      active={active}
      label="Liste non ordonnée"
      shortcut={SHORTCUT}
    >
      <UnorderedList />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  return (
    <UnorderedListToolbarItem
      active={checkIsBlockActive(editorState, BLOCK_STYLE_TYPE)}
    />
  );
};

BlockControls.group = "block-style";
