import moment from "moment";

import { Time } from "@/components/Time";

import {
  ExposurePlanningIndicators,
  PosteriorExposureWarning,
  PriorExposureAlert,
  useShowPosteriorWarning,
  useShowPriorAlert,
} from "./ExposureIndicator";
import { ArticleExposure, Exposure } from "./article/ArticleExposureSelect";
import { HourRange } from "./article/ArticlePublishTime";
import { useResolvePublicationSlots } from "./article/util/publicationSlots";

type IndicatorTooltipProps = {
  articleExposure: ArticleExposure;
  exposure: Exposure;
};
export const TooltipTitle = (props: IndicatorTooltipProps) => {
  const { articleExposure, exposure } = props;
  return (
    <div>
      <span className="font-bold">{exposure.label} </span>
      {articleExposure.suggested &&
        !articleExposure.fulfilled &&
        !articleExposure.publication &&
        !articleExposure.planning.date && <span>(suggéré)</span>}
    </div>
  );
};
const TooltipPublicationBody = (props: {
  articleExposure: IndicatorTooltipProps["articleExposure"];
}) => {
  if (!props.articleExposure.publication || !props.articleExposure.suggested)
    return null;
  return (
    <>
      Suggéré pour l’édition du{" "}
      <Time date={props.articleExposure.publication.date} format="DD/MM/YYYY" />
    </>
  );
};
const TooltipPlanningBody = (props: {
  articleExposure: IndicatorTooltipProps["articleExposure"];
}) => {
  const { articleExposure } = props;
  const { startDate } = useResolvePublicationSlots({
    plannedDate: articleExposure.planning.date,
    range: articleExposure.planning.range,
  });

  if (!startDate || !articleExposure.suggested || articleExposure.publication)
    return null;

  return (
    <>
      Suggéré pour exposition <br />
      <div className="flex">
        le {moment(startDate).format("DD/MM/YYYY")}{" "}
        {articleExposure.planning.range === "hour" ? (
          <div>
            entre <HourRange date={startDate} literal />
          </div>
        ) : (
          <>à {moment(startDate).format("HH:mm")}</>
        )}
      </div>
    </>
  );
};
const ExposureFullfilledText = ({ fulfilledAt }: { fulfilledAt: string }) => {
  return (
    <>
      Exposé le <Time date={fulfilledAt} format="DD/MM/YYYY à HH:mm:ss" />
    </>
  );
};
const TooltipFullfilledBody = (props: {
  articleExposure: IndicatorTooltipProps["articleExposure"];
}) => {
  const {
    articleExposure: { fulfilledAt, suggestedAt },
  } = props;
  if (!fulfilledAt || suggestedAt > fulfilledAt) return null;
  return <ExposureFullfilledText fulfilledAt={fulfilledAt} />;
};

const ExposureSelectedText = ({
  selectedAt,
  publication,
}: {
  selectedAt: string;
  publication?: ArticleExposure["publication"];
}) => {
  return (
    <span>
      Sélectionné{" "}
      {publication && (
        <>
          pour l’édition du <Time date={publication.date} format="DD/MM/YYYY" />{" "}
        </>
      )}
      le <Time date={selectedAt} format="DD/MM/YYYY à HH:mm:ss" />
    </span>
  );
};

const TooltipSelectedBody = (props: {
  articleExposure: IndicatorTooltipProps["articleExposure"];
}) => {
  const {
    articleExposure: { selectedAt, fulfilledAt, suggested, publication },
  } = props;
  if (!selectedAt || fulfilledAt || suggested) return null;
  return (
    <ExposureSelectedText publication={publication} selectedAt={selectedAt} />
  );
};

export const TooltipBody = (props: IndicatorTooltipProps) => {
  const { articleExposure } = props;

  return (
    <>
      <TooltipPublicationBody articleExposure={articleExposure} />
      <TooltipPlanningBody articleExposure={articleExposure} />
      <TooltipSelectedBody articleExposure={articleExposure} />
      <TooltipFullfilledBody articleExposure={articleExposure} />
      <ExposurePlanningIndicators articleExposure={articleExposure}>
        <ExposurePlanningPosteriorToArticlePublication {...props} />
        <ExposurePlanningPriorToArticlePublication {...props} />
      </ExposurePlanningIndicators>
      {articleExposure.suggestedAt > articleExposure.fulfilledAt ? (
        <div className="mt-3">
          <strong>États précédents :</strong>
          <ul>
            {articleExposure.selectedAt && (
              <li>
                <ExposureSelectedText selectedAt={articleExposure.selectedAt} />
              </li>
            )}
            {articleExposure.fulfilledAt && (
              <li>
                <ExposureFullfilledText
                  fulfilledAt={articleExposure.fulfilledAt}
                />
              </li>
            )}
          </ul>
        </div>
      ) : null}
    </>
  );
};
export const ExposureIndicatorTooltip = (props: IndicatorTooltipProps) => {
  return (
    <div className="flex flex-col">
      <TooltipTitle {...props} />
      <TooltipBody {...props} />
    </div>
  );
};
const ExposurePlanningPriorToArticlePublication = (
  props: IndicatorTooltipProps,
) => {
  const { articleExposure, exposure } = props;
  const showPriorAlert = useShowPriorAlert({
    articleExposure,
    exposure,
  });
  if (!showPriorAlert) return null;

  return (
    <div className="mt-2 flex items-start gap-2">
      <PriorExposureAlert className="mt-1 text-base" />
      <span className="font-semibold text-white">
        L’heure d’exposition suggérée ne peut pas précéder la publication
      </span>
    </div>
  );
};
const ExposurePlanningPosteriorToArticlePublication = (
  props: IndicatorTooltipProps,
) => {
  const { articleExposure, exposure } = props;
  const showPosteriorWarning = useShowPosteriorWarning({
    articleExposure,
    exposure,
  });
  if (!showPosteriorWarning) return null;

  return (
    <div className="mt-2 flex items-start gap-2">
      <PosteriorExposureWarning className="mt-1 text-base" />
      <span className="font-semibold text-white">
        Les articles sont plus lus lorsqu’ils sont exposés dans le même créneau
        horaire que leur publication
      </span>
    </div>
  );
};
