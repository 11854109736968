import * as React from "react";
import { forwardRef } from "react";

const SvgIndice = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.3a.7.7 0 1 0 0 1.4h4.3V20a.7.7 0 1 0 1.4 0V6.7H15a.7.7 0 1 0 0-1.4H5Z"
      fill="currentColor"
    />
    <path
      d="M18.463 20a.5.5 0 0 1-.5-.5v-4.512l-1.12.852a.369.369 0 1 1-.443-.59l1.553-1.152A.5.5 0 0 1 18.25 14h.25a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-.037Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIndice);
export default ForwardRef;
