import { EditorState, SelectionState } from "draft-js-es";
import DraftOffsetKey from "draft-js/lib/DraftOffsetKey";

import { acceptSelection } from "@/components/rich-editor/modifiers/acceptSelection";

/**
 * Set selection to a block.
 * @param {EditorState} editorState
 * @param {string} blockKey
 * @param {boolean} [force]
 */
export function selectBlock(editorState, blockKey, force = true) {
  if (force) {
    setTimeout(() => {
      if (!editorState.getSelection().getHasFocus()) {
        // do not have current selection range
        return;
      }
      const offsetKey = DraftOffsetKey.encode(blockKey, 0, 0);
      const node = document.querySelectorAll(
        `[data-offset-key="${offsetKey}"]`,
      )[0];
      if (node) {
        // set the native selection to the node so the caret is not in the text and
        // the selectionState matches the native selection
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.setStart(node, 0);
        range.setEnd(node, 0);
        selection.addRange(range);
      }
    }, 0);
  }

  const setSelection = force ? EditorState.forceSelection : acceptSelection;

  return setSelection(
    editorState,
    force
      ? SelectionState.createEmpty(blockKey)
          .set("focusOffset", 0)
          .set("hasFocus", true)
      : SelectionState.createEmpty(blockKey),
  );
}
