import React, { FC, createContext, useContext } from "react";
import { createTeleporter } from "react-teleporter";
import { Button } from "swash/Button";
import { IoGridOutline, IoListOutline } from "swash/Icon";
import { SegmentedControl } from "swash/controls/SegmentedControl";

export type LayoutMode = "list" | "grid";

const LayoutModeContext = createContext<LayoutMode | null>(null);
export const LayoutModeSwitcher = createTeleporter();

export type LayoutModeProviderProps = {
  state: {
    layoutMode: LayoutMode;
    onLayoutModeChange: (mode: LayoutMode | number) => void;
  };
  children: React.ReactNode;
};

export const LayoutModeProvider = (props: LayoutModeProviderProps) => {
  return (
    <LayoutModeContext.Provider value={props.state.layoutMode}>
      <LayoutModeSwitcher.Source>
        <SegmentedControl
          value={props.state.layoutMode}
          onChange={props.state.onLayoutModeChange}
          scale="sm"
          role="group"
          aria-label="Changer l’affichage"
        >
          <Button iconOnly id="grid" aria-label="Affichage en grille">
            <IoGridOutline />
          </Button>
          <Button iconOnly id="list" aria-label="Affichage en liste">
            <IoListOutline />
          </Button>
        </SegmentedControl>
      </LayoutModeSwitcher.Source>
      {props.children}
    </LayoutModeContext.Provider>
  );
};

export const useLayoutMode = () => useContext(LayoutModeContext);

interface WithLayoutModeProps {
  mode: LayoutMode;
  children: React.ReactNode;
  /** @default false */
  fallback?: boolean;
}

export const WithLayoutMode: FC<WithLayoutModeProps> = ({
  mode,
  fallback = false,
  children,
}) => {
  const layoutMode = useLayoutMode();
  if (!layoutMode && fallback) return <>{children}</>;

  if (layoutMode === mode) {
    return <>{children}</>;
  }
  return null;
};
