// @ts-check
import { EditorState, Modifier, SelectionState } from "draft-js-es";

/** @typedef {import('draft-js').ContentBlock} ContentBlock */

/**
 * @typedef ProlexisCorrection
 * @property {number} elementIndex
 * @property {number} offset
 * @property {number} length
 * @property {string} correction
 */

/**
 * @typedef BlockRange
 * @property {ContentBlock} block
 * @property {number} start
 * @property {number} end
 * @property {number} anchorOffset
 */

const BLOCK_SEPARATOR = "\n";

/**
 * Get text blocks from editor state.
 * @param {EditorState} editorState
 * @returns {ContentBlock[]}
 */
const getEditorStateTextBlocks = (editorState) => {
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .filter((block) => block?.getType() !== "atomic")
    .toArray();
};

/**
 * Get editor state content.
 * @param {EditorState} editorState
 * @returns {string}
 */
export const getEditorStateContent = (editorState) => {
  return getEditorStateTextBlocks(editorState)
    .map((block) => block.getText())
    .join(BLOCK_SEPARATOR)
    .trim();
};

/**
 * Get block ranges.
 * @param {ContentBlock[]} contentBlocks
 * @returns {BlockRange[]}
 */
const getBlockRanges = (contentBlocks) => {
  let start = 0;
  return (
    contentBlocks
      .map((block) => {
        const length = block.getLength();
        const text = block.getText();
        // Prolexis trim the text, so we do not take in account spaces
        // at the start of the first block and at the end of the last block
        // For example, our editor blocks are: ['', '  ', '   hello']
        // Prolexis uses: 'hello'
        // All the blank spaces at the start are trimmed, this is what we do here

        // While "start" is 0, then we trim spaces
        const anchorOffset =
          start === 0 ? text.match(/^(\s+)/)?.[0].length ?? 0 : 0;

        // Start min value is 0
        start = Math.max(start - anchorOffset, 0);

        const end = start + length - anchorOffset;

        const range = {
          block,
          start,
          end,
          anchorOffset,
        };

        // If it is an empty block (end: 0), then we do not add separator
        // it is part of the trim at the start of the text.
        start = end > 0 ? end + BLOCK_SEPARATOR.length : 0;
        return range;
      })
      // Empty blocks must not be part of that
      .filter((block) => block.start !== 0 || block.end !== 0)
  );
};

/**
 * Get block range at a specific offset.
 * @param {BlockRange[]} ranges
 * @param {number} offset
 * @returns {BlockRange | null}
 */
const getBlockRangeAt = (ranges, offset) => {
  return (
    ranges.find((block) => block.start <= offset && offset <= block.end) ?? null
  );
};

/**
 * Apply a Prolexis correction on an editor state.
 * @param {EditorState} editorState
 * @param {ProlexisCorrection[]} corrections
 * @returns {EditorState}
 */
export const applyProlexisCorrections = (editorState, corrections) => {
  return corrections.reduce((editorState, correction) => {
    const { offset, length, correction: replacedText } = correction;

    const blocks = getEditorStateTextBlocks(editorState);
    const blockRanges = getBlockRanges(blocks);
    const blockRange = getBlockRangeAt(blockRanges, offset);

    if (!blockRange) {
      return editorState;
    }

    const { block, start, anchorOffset } = blockRange;

    const blockKey = block.getKey();

    const selAnchorOffset = offset - start + anchorOffset;
    const selFocusOffset = selAnchorOffset + length;

    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: selAnchorOffset,
      focusOffset: selFocusOffset,
    });

    return EditorState.push(
      editorState,
      Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        replacedText,
        block.getInlineStyleAt(selAnchorOffset),
        block.getEntityAt(selAnchorOffset),
      ),
      "insert-characters",
    );
  }, editorState);
};
