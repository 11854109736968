import { memo } from "react";
// @TODO design system missing icon
import { BsTypeStrikethrough as StrikethroughIcon } from "react-icons/bs";

import { handleBinding } from "@/components/rich-editor/utils";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import { getBlockType } from "../utils/Block";
import { INLINE_STYLE, command } from "./strikethrough";

const SHORTCUT = ["⌘", "⌥", "x"];

export const shortcuts = [
  {
    description: "Barrer",
    shortcut: SHORTCUT,
    category: "Mise en forme",
  },
];

export const name = "strikethrough-control";

export const keyBindingFn = (state, event) =>
  handleBinding(SHORTCUT, command, event);

const StrikeToolbarButton = memo(({ active, disabled }) => {
  return (
    <RichEditorToolbarButton
      active={active}
      label="Barrer"
      command={command}
      shortcut={SHORTCUT}
      disabled={disabled}
    >
      <StrikethroughIcon />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = ({ editorState }) => {
  const blockType = getBlockType(editorState);

  return (
    <StrikeToolbarButton
      active={editorState.getCurrentInlineStyle().has(INLINE_STYLE)}
      disabled={blockType === "blockquote"}
    />
  );
};

BlockControls.group = "inline-style";
