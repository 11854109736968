import {
  type ApolloCache,
  ApolloClient,
  type DefaultContext,
  type DocumentNode,
  type MutationFunctionOptions,
  type MutationHookOptions,
  type OperationVariables,
  type QueryHookOptions,
  type QueryOptions,
  type TypedDocumentNode,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import type {
  ASTNode,
  DefinitionNode,
  FragmentDefinitionNode,
  GraphQLError,
} from "graphql";
import type { ValueIteratee } from "lodash";
import { pick, uniqBy } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";

export type {
  DocumentNode,
  QueryResult,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";

export type { VariablesOf, ResultOf } from "@graphql-typed-document-node/core";

type UseFetchMoreQueryOptions<
  TData,
  TVariables extends OperationVariables,
> = Omit<
  QueryOptions<TVariables, TData> & QueryHookOptions<TData, TVariables>,
  "query"
>;

/**
 * Special query to improve fetchMore:
 * - Previous request cancelling
 * - fetchMoreLoading
 */
export function useFetchMoreQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: UseFetchMoreQueryOptions<TData, TVariables> = {},
) {
  const apolloClient = useApolloClient();
  const result = useQuery(query, {
    nextFetchPolicy: "cache-first",
    ...options,
  });
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const resultRef = useRef(result);
  resultRef.current = result;

  const optionsRef = useRef({ query, ...options });
  optionsRef.current = { query, ...options };

  const queryIdRef = useRef(0);

  type FetchMoreOptions = {
    variables: Partial<TVariables>;
    updateQuery: (
      previousResult: TData,
      context: { fetchMoreResult: TData; variables: TVariables },
    ) => TData;
  };

  const fetchMore = useCallback(
    (fetchMoreOptions: FetchMoreOptions) => {
      const combinedVariables = {
        ...optionsRef.current.variables,
        ...fetchMoreOptions.variables,
      } as TVariables;
      const queryId = ++queryIdRef.current;
      setFetchMoreLoading(true);
      const isOutdated = () => queryId !== queryIdRef.current;
      return apolloClient
        .query({ ...optionsRef.current, variables: combinedVariables })
        .then((fetchMoreResult) => {
          if (!isOutdated()) {
            resultRef.current.updateQuery((previousResult) =>
              fetchMoreOptions.updateQuery(previousResult, {
                fetchMoreResult: fetchMoreResult.data,
                variables: combinedVariables,
              }),
            );
          }
        })
        .catch((error) => {
          if (optionsRef.current.onError) {
            optionsRef.current.onError(error);
          }
        })
        .finally(() => {
          if (!isOutdated()) {
            setFetchMoreLoading(false);
          }
        });
    },
    [apolloClient],
  );

  const refetch = useCallback((options?: Partial<TVariables>) => {
    setRefetchLoading(true);
    return resultRef.current.refetch(options).finally(() => {
      setRefetchLoading(false);
    });
  }, []);

  return { ...result, refetch, refetchLoading, fetchMore, fetchMoreLoading };
}

/**
 * Cancellable mutation.
 */
export function useCancellableMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables, TContext, TCache>,
) {
  const [mutate, result] = useMutation(mutation, options);
  const abortController = useRef<AbortController>();
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);
  const cancellableMutate = useCallback(
    (
      mutateParams: MutationFunctionOptions<
        TData,
        TVariables,
        TContext,
        TCache
      >,
    ) => {
      abort();
      const controller = new window.AbortController();
      abortController.current = controller;

      mutate({
        ...mutateParams,
        context: {
          ...(mutateParams as any)?.options?.context,
          fetchOptions: {
            ...(mutateParams as any)?.options?.context?.fetchOptions,
            controller,
          },
        },
      }).catch((error) => {
        if (error.networkError?.name === "AbortError") {
          return;
        }
        throw error;
      });
    },
    [abort, mutate],
  );
  return [cancellableMutate, { ...result, abort }] as const;
}

export type SerialMutationState = {
  error: unknown | null;
  loading: boolean;
};

type PendingOperation<T = any> = [(options: T) => Promise<any>, T];

type SerialMutationContextValue = {
  addOperation: <T>(mutate: (options: T) => Promise<any>, options: T) => void;
  state: SerialMutationState;
};

const SerialMutationContext = createSafeContext<SerialMutationContextValue>();

const PENDING_STATE: SerialMutationState = { error: null, loading: false };
const LOADING_STATE: SerialMutationState = { error: null, loading: true };

export const SerialMutationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const pendingOperationsRef = useRef<PendingOperation[]>([]);
  const currentOperationRef = useRef<PendingOperation | null>(null);
  const [state, setState] = useState<SerialMutationState>(PENDING_STATE);
  const runPendingOperations = useCallback(() => {
    function executeOperation(operation: PendingOperation) {
      currentOperationRef.current = operation;
      setState(LOADING_STATE);
      const [mutate, options] = operation;
      mutate(options)
        .then(() => {
          currentOperationRef.current = null;
          run();
        })
        .catch((error) => {
          currentOperationRef.current = null;
          setState({ error, loading: false });
        });
    }

    function run() {
      if (currentOperationRef.current !== null) return;
      if (pendingOperationsRef.current.length === 0) {
        setState(PENDING_STATE);
      } else {
        executeOperation(pendingOperationsRef.current.shift()!);
      }
    }

    return run();
  }, []);
  const addOperation = useCallback(
    <T,>(mutate: (options: T) => Promise<void>, options: T) => {
      pendingOperationsRef.current.push([mutate, options]);
      runPendingOperations();
    },
    [runPendingOperations],
  );
  const value = useMemo(
    (): SerialMutationContextValue => ({ addOperation, state }),
    [addOperation, state],
  );
  return (
    <SerialMutationContext.Provider value={value}>
      {children}
    </SerialMutationContext.Provider>
  );
};

export const useSerialMutationState = SerialMutationContext.makeSafeHook(
  "useSerialMutationState",
  "SerialMutationProvider",
);

/**
 * Serial mutation.
 * Execute mutation in series in a giving context.
 */
export function useSerialMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Omit<
    MutationHookOptions<TData, TVariables, TContext, TCache>,
    "update"
  > & {
    update?: (
      client: ApolloClient<object>,
      options: {
        data: TData;
        optimistic: boolean;
      },
    ) => void;
  },
) {
  const { addOperation } = useSerialMutationState();
  const optionsRef = useRef(options);
  useEffect(() => {
    optionsRef.current = options;
  });
  const [mutate] = useMutation(
    mutation,
    options as MutationHookOptions<TData, TVariables, TContext, TCache>,
  );
  const client = useApolloClient();
  return useCallback(
    ({
      optimisticResponse,
      ...mutateOptions
    }: MutationFunctionOptions<TData, TVariables, TContext, TCache>) => {
      if (optionsRef.current.update && optimisticResponse) {
        optionsRef.current.update(client, {
          data: optimisticResponse as any,
          optimistic: true,
        });
      }
      addOperation(mutate, mutateOptions);
    },
    [addOperation, mutate, client],
  );
}

/**
 * Use query without error management.
 */
export function useSafeQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    ignoreSubsequentErrors = false,
    ...options
  }: QueryHookOptions<TData, TVariables> & {
    ignoreSubsequentErrors?: boolean;
  } = {},
) {
  const { error, ...others } = useQuery(query, {
    nextFetchPolicy: "cache-first",
    ...options,
  });
  if (ignoreSubsequentErrors) {
    if (error && !others.previousData) {
      throw error;
    }
  } else {
    if (error) {
      throw error;
    }
  }
  return others;
}

/**
 * Use mutation without error management.
 */
export function useSafeMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables, TContext, TCache> = {},
) {
  const [mutate, { error, ...others }] = useMutation(mutation, options);
  if (error) throw error;
  return [mutate, others] as const;
}

const isFragmentDefinition = (node: ASTNode): node is FragmentDefinitionNode =>
  node.kind === "FragmentDefinition";

const getFragmentDefinitionProps = (
  fragmentDefinition: FragmentDefinitionNode,
  state: { definitions: readonly DefinitionNode[] },
): string[] => {
  return fragmentDefinition.selectionSet.selections.reduce(
    (props: string[], selection) => {
      switch (selection.kind) {
        case "Field":
          return [...props, selection.name.value];
        case "FragmentSpread": {
          const spreadFragment = state.definitions.find(
            (node) =>
              isFragmentDefinition(node) &&
              node.name.value === selection.name.value,
          );
          return [
            ...props,
            ...getFragmentDefinitionProps(
              spreadFragment as FragmentDefinitionNode,
              state,
            ),
          ];
        }
        default:
          return props;
      }
    },
    [],
  );
};

export const getFragmentProps = (document: DocumentNode) => {
  const definition = document.definitions.find((node) =>
    isFragmentDefinition(node),
  );
  if (!definition) {
    throw new Error("Fragment definition not found");
  }
  return getFragmentDefinitionProps(definition, {
    definitions: document.definitions,
  });
};

export function pickFragment<T extends object, U extends TypedDocumentNode>(
  obj: T,
  document: U,
): ReturnType<
  typeof pick<T, U extends TypedDocumentNode<infer K> ? keyof K : never>
>;

export function pickFragment<T extends object>(
  obj: T,
  document: DocumentNode,
): ReturnType<typeof pick<T>>;

export function pickFragment<T>(
  obj: T | null | undefined,
  document: TypedDocumentNode | DocumentNode,
) {
  return pick(obj, getFragmentProps(document));
}

export const prependNode = <T,>(
  existingNodes: readonly T[],
  incomingNode: T,
  iteratee: ValueIteratee<T> = "id",
) => {
  return uniqBy([incomingNode, ...existingNodes], iteratee);
};

export const appendNode = <T,>(
  existingNodes: readonly T[],
  incomingNode: T,
  iteratee: ValueIteratee<T> = "id",
) => {
  return uniqBy([...existingNodes, incomingNode], iteratee);
};

export const mergeConnections = <
  Node,
  T extends { nodes: Node[] },
  U extends { nodes: Node[] },
>(
  existing: T,
  incoming: U,
  iteratee: ValueIteratee<Node> = "id",
) => {
  return {
    ...existing,
    ...incoming,
    nodes: uniqBy([...existing.nodes, ...incoming.nodes], iteratee),
  };
};

export const mergeCursorConnections = <
  Node,
  T extends { nodes: Node[] },
  U extends { nodes: Node[] },
>(
  existing: T,
  incoming: U,
) => {
  return {
    ...existing,
    ...incoming,
    nodes: [...existing.nodes, ...incoming.nodes],
  };
};

export const getGraphQlErrorsCode = (error?: {
  graphQLErrors?: GraphQLError[];
}) => {
  const graphQLErrors = error?.graphQLErrors;
  if (!graphQLErrors) return [];

  return graphQLErrors.map((error) => {
    return (
      ((error?.extensions as any)?.exception?.code ||
        (error?.extensions as any)?.code) ??
      "default"
    );
  });
};
