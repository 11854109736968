import * as React from "react";
import { forwardRef } from "react";

const SvgBubbleArrowUp = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M22 12c0 5.523-4.477 10-10 10a9.957 9.957 0 0 1-4.65-1.145l-3.948.987a1.026 1.026 0 0 1-1.244-1.244l.987-3.947a9.936 9.936 0 0 1-.798-2.029l3.214-2.43a.61.61 0 0 1 .853.118L8.06 14.49a1.826 1.826 0 0 0 2.56.355l5.411-4.093-.383 2.766a.609.609 0 1 0 1.206.168l.586-4.223a.609.609 0 0 0-.52-.687L12.7 8.19a.609.609 0 0 0-.167 1.207l2.766.384-5.412 4.092a.61.61 0 0 1-.853-.118l-1.648-2.178a1.828 1.828 0 0 0-2.559-.355l-2.743 2.074A10.096 10.096 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBubbleArrowUp);
export default ForwardRef;
