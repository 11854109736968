import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { NamedExoticComponent, memo } from "react";

import { Time } from "@/components/Time";
import type { ArticlePrintSummary_printSummaryFragment } from "@/gql-types";

import type { DocumentNode } from "../Apollo";

export type ArticlePrintSummaryProps = ArticlePrintSummary_printSummaryFragment;

export const ArticlePrintSummary = memo<ArticlePrintSummaryProps>(
  ({
    periodical,
    periodicalEditionDate,
    periodicalRelease,
    periodicalSection,
    periodicalSectionLayout,
  }) => {
    const parts = [];
    if (periodical?.label) {
      parts.push(periodical.label);
    }
    if (periodicalSection?.label) {
      parts.push(periodicalSection.label);
    }
    if (periodicalSectionLayout?.label) {
      parts.push(periodicalSectionLayout.label);
    }
    if (periodicalRelease?.label) {
      parts.push(periodicalRelease.label);
    }

    return (
      <>
        {parts.join(", ")}
        {periodicalEditionDate && (
          <>
            {", "}
            <Time
              date={moment
                .tz(periodicalEditionDate, "Europe/Paris")
                .toISOString()}
              format="DD/MM/YYYY"
            />
          </>
        )}
      </>
    );
  },
) as NamedExoticComponent<ArticlePrintSummaryProps> & {
  fragments: { article: DocumentNode };
};

ArticlePrintSummary.fragments = {
  article: gql`
    fragment ArticlePrintSummary_printSummary on Article {
      periodicalEditionDate
      periodical {
        id
        label
      }
      periodicalSection {
        id
        label
      }
      periodicalRelease {
        id
        label
      }
      periodicalSectionLayout {
        id
        label
      }
    }
  `,
};
