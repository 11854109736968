import { gql } from "@apollo/client";
import moment from "moment/moment";
import { CgDarkMode } from "react-icons/cg";
import { IoMdPerson } from "react-icons/io";
import { IoAlertCircle } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { CardItemTitle, CardListItemBody } from "@/components/CardListItem";
import { Time } from "@/components/Time";
import {
  WithLayoutMode,
  useLayoutMode,
} from "@/containers/search/LayoutModeContext";
import {
  SnippetKeywordList,
  SnippetKeywordListProps,
} from "@/containers/snippet/SnippetKeywordList";
import { FCWithFragments } from "@/type/utils";

interface CreationInfoProps {
  snippet: {
    creator: {
      id: string;
      fullName: string;
    };
    createdAt: string;
  };
}

const CreationInfo: FCWithFragments<CreationInfoProps> = ({ snippet }) => {
  const { creator, createdAt } = snippet;
  return (
    <div>
      Créé par {creator.fullName} le{" "}
      <Time date={createdAt}>
        {moment(createdAt).format("DD/MM/YYYY à HH:mm")}
      </Time>
    </div>
  );
};

CreationInfo.fragments = {
  snippet: gql`
    fragment CreationInfo_snippet on Snippet {
      id
      creator {
        id
        fullName
      }
      createdAt
    }
  `,
};

interface ObsoleteInformationProps {
  snippet: {
    obsolete: boolean;
  };
}

const ObsoleteInformation: FCWithFragments<ObsoleteInformationProps> = ({
  snippet,
}) => {
  const { obsolete } = snippet;
  if (!obsolete) return null;
  return (
    <Tooltip tooltip="Snippet obsolète">
      {/* handle forwarding refs */}
      <div className="inline-flex">
        <IoAlertCircle className="ml-1 inline-block h-4 w-4 text-red-on" />
      </div>
    </Tooltip>
  );
};

ObsoleteInformation.fragments = {
  snippet: gql`
    fragment ObsoleteInformation_snippet on Snippet {
      id
      obsolete
    }
  `,
};

interface DarkModeInformationProps {
  snippet: {
    darkModeSupported: boolean;
  };
}

const DarkModeInformation: FCWithFragments<DarkModeInformationProps> = ({
  snippet,
}) => {
  const { darkModeSupported } = snippet;
  if (!darkModeSupported) return null;
  return (
    <Tooltip tooltip="Mode sombre supporté">
      <span className="inline-flex">
        <CgDarkMode className="text-dusk-on" />
      </span>
    </Tooltip>
  );
};

DarkModeInformation.fragments = {
  snippet: gql`
    fragment DarkModeInformation_snippet on Snippet {
      id
      darkModeSupported
    }
  `,
};

type SnippetCreatorProps = CreationInfoProps & {
  snippet: {
    creator: {
      id: string;
    };
  };
};

const SnippetCreator: FCWithFragments<SnippetCreatorProps> = ({ snippet }) => {
  const { creator } = snippet;
  const hasCreator = Boolean(creator);
  return (
    <Tooltip
      tooltip={
        hasCreator ? (
          <CreationInfo snippet={snippet} />
        ) : (
          "Pas de créateur associé"
        )
      }
    >
      <span className="inline-flex">
        <IoMdPerson
          className={hasCreator ? "text-dusk-on" : "text-grey-on-light"}
        />
      </span>
    </Tooltip>
  );
};

SnippetCreator.fragments = {
  snippet: gql`
    fragment SnippetCreator_snippet on Snippet {
      id
      creator {
        id
      }
      ...CreationInfo_snippet
    }
    ${CreationInfo.fragments["snippet"]}
  `,
};

type SnippetIconsProps = CreationInfoProps &
  ObsoleteInformationProps &
  DarkModeInformationProps;

const SnippetIcons: FCWithFragments<SnippetIconsProps> = ({ snippet }) => {
  const { darkModeSupported, obsolete, creator, createdAt } = snippet;
  if (!darkModeSupported && !obsolete && !creator && !createdAt) return null;

  return (
    <div className="flex gap-1 py-0.5">
      <SnippetCreator snippet={snippet} />
      <DarkModeInformation snippet={snippet} />
      <ObsoleteInformation snippet={snippet} />
    </div>
  );
};

SnippetIcons.fragments = {
  snippet: gql`
    fragment SnippetIcons_snippet on Snippet {
      id
      darkModeSupported
      obsolete
      createdAt
      creator {
        id
      }
      ...SnippetCreator_snippet
      ...DarkModeInformation_snippet
      ...ObsoleteInformation_snippet
    }
    ${SnippetCreator.fragments["snippet"]}
    ${DarkModeInformation.fragments["snippet"]}
    ${ObsoleteInformation.fragments["snippet"]}
  `,
};

interface SnippetDateProps {
  snippet: {
    createdAt: string;
    updatedAt: string;
  };
}

const SnippetDate: FCWithFragments<SnippetDateProps> = ({ snippet }) => {
  const wasUpdated = !moment(snippet.createdAt).isSame(snippet.updatedAt);
  if (!wasUpdated) return null;
  return (
    <div className="shrink-0 text-xs text-grey-on">
      Modifié <Time date={snippet.updatedAt} />
    </div>
  );
};

SnippetDate.fragments = {
  snippet: gql`
    fragment SnippetDate_snippet on Snippet {
      id
      createdAt
      updatedAt
    }
  `,
};

interface SnippetTitleProps {
  snippet: {
    snippetTitle: string;
  };
}

const SnippetTitle: FCWithFragments<SnippetTitleProps> = ({ snippet }) => {
  const title = snippet.snippetTitle;
  if (!title) return null;

  return <CardItemTitle>{title}</CardItemTitle>;
};

SnippetTitle.fragments = {
  snippet: gql`
    fragment SnippetTitle_snippet on Snippet {
      id
      snippetTitle: title
    }
  `,
};

type SnippetSummaryProps = SnippetTitleProps &
  SnippetDateProps &
  SnippetIconsProps &
  SnippetKeywordListProps & {
    snippet: {
      obsolete: boolean;
    };
  };

export const SnippetSummary: FCWithFragments<SnippetSummaryProps> = ({
  snippet,
}) => {
  const layoutMode = useLayoutMode();
  return (
    <CardListItemBody scale={layoutMode !== "list" ? "lg" : undefined}>
      <SnippetTitle snippet={snippet} />
      <WithLayoutMode mode="grid" fallback>
        <SnippetDate snippet={snippet} />
        <div className="mt-auto inline-flex w-full shrink-0 items-center justify-between gap-2">
          <SnippetIcons snippet={snippet} />
          <SnippetKeywordList snippet={snippet} />
        </div>
      </WithLayoutMode>
    </CardListItemBody>
  );
};

SnippetSummary.fragments = {
  snippet__search: gql`
    fragment SnippetSummary_snippet__search on Snippet {
      id
      obsolete
      snippetTitle: title
      ...SnippetTitle_snippet
      ...SnippetDate_snippet @include(if: $isGrid)
      ...SnippetIcons_snippet @include(if: $isGrid)
      ...SnippetKeywordList_snippet @include(if: $isGrid)
    }
    ${SnippetTitle.fragments["snippet"]}
    ${SnippetDate.fragments["snippet"]}
    ${SnippetIcons.fragments["snippet"]}
    ${SnippetKeywordList.fragments["snippet"]}
  `,
  snippet__article: gql`
    fragment SnippetSummary_snippet__article on Snippet {
      id
      obsolete
      snippetTitle: title
      ...SnippetTitle_snippet
      ...SnippetDate_snippet
      ...SnippetIcons_snippet
      ...SnippetKeywordList_snippet
    }
    ${SnippetTitle.fragments["snippet"]}
    ${SnippetDate.fragments["snippet"]}
    ${SnippetIcons.fragments["snippet"]}
    ${SnippetKeywordList.fragments["snippet"]}
  `,
};
