import React, { forwardRef } from "react";

import { cn } from "../utils/classNames";

export type FormLabelProps = React.ComponentPropsWithoutRef<"label">;

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(
  (props, ref) => {
    return (
      <label
        ref={ref}
        {...props}
        className={cn(
          "flex-1 font-accent text-base font-semibold",
          props.className,
        )}
      />
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FormLabel.displayName = "FormLabel";
}
