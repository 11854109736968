import styled, { th } from "@xstyled/styled-components";

import { mediaQueries } from "@/config/mediaQueries";

export const PANEL_WIDTH = 400;
export const NAVBAR_WIDTH = 48;
const CONTAINER_PADDING = 4;

export const ArticleResizeContainer = styled.div`
  .article-container {
    width: calc(
      ${th.size("article-width")} + ${th.space(CONTAINER_PADDING)} +
        ${th.space(CONTAINER_PADDING)}
    );
    padding: 0 ${CONTAINER_PADDING};
    margin: 0 auto;
  }

  @media ${mediaQueries.desktop.article.md} {
    .article-container {
      width: auto;
      max-width: 640px;
      margin: 0 auto;
    }
  }

  @media ${mediaQueries.desktop.article.sm} {
    .article-container {
      width: auto;
      margin: 0 20px;
    }
  }

  &[data-device="mobile"] .article-container {
    max-width: 360px;
    margin: 0 auto;
  }
`;

export const ArticleContainer = styled.box`
  @media ${mediaQueries.mobile.article.md} {
    width: calc(
      500px + ${th.space(CONTAINER_PADDING)} + ${th.space(CONTAINER_PADDING)}
    );
  }

  @media ${mediaQueries.mobile.article.sm} {
    width: calc(
      300px + ${th.space(CONTAINER_PADDING)} + ${th.space(CONTAINER_PADDING)}
    );
  }

  @media print {
    width: auto;
  }
`;

export const ArticleContent = styled.box`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: article-content;
  z-index: 2;
  &[data-focus-mode="true"] {
    height: 100vh;
  }

  [data-display-mode="preview"] & {
    background-color: grey-lighter;
    box-shadow: none;
  }

  [data-display-mode="compare"] & {
    background-color: grey-lightest;
    box-shadow: none;
  }
`;
