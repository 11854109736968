import { forwardRef } from "react";

import { FieldControl } from "@/components/fields/FieldControl";
import { useTextPreset } from "@/containers/editor/presets/preset-text";

import { StandaloneRichEditorTextBox } from "../rich-editor/RichEditorTextBox";

export const RichEditorTextBoxField = forwardRef((props, ref) => {
  const plugins = useTextPreset();
  return (
    <FieldControl
      ref={ref}
      as={StandaloneRichEditorTextBox}
      plugins={plugins}
      stripPastedStyles={props.rich}
      {...props}
    />
  );
});
