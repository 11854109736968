import { useEffect, useState } from "react";

export function useNextFrameTicked({ skip = false } = {}) {
  const [ticked, setTicked] = useState(false);
  useEffect(() => {
    if (skip || ticked) return undefined;
    const id = requestAnimationFrame(() => setTicked(true));
    return () => cancelAnimationFrame(id);
  }, [skip, ticked]);
  return ticked;
}
