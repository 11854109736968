import { gql } from "@apollo/client";
import { NamedExoticComponent, memo } from "react";

import { Time } from "@/components/Time";
import type { ArticlePrintInfos_printInfosFragment } from "@/gql-types";

import type { DocumentNode } from "../Apollo";

export type ArticlePrintInfosProps = {
  printInfos: ArticlePrintInfos_printInfosFragment;
};

export const ArticlePrintInfos = memo<ArticlePrintInfosProps>(
  ({ printInfos }) => {
    if (!printInfos) {
      return "Pas d’export print" as any;
    }

    const messages = [];

    if (printInfos.decorrelatedAt) {
      messages.push(
        <li key="copy" className="!list-outside">
          <div className="inline-flex flex-col">
            <div className="font-semibold">Copie-print</div>
            <div>
              créée le <Time date={printInfos.decorrelatedAt} format="LLL" />
            </div>
          </div>
        </li>,
      );
    }

    messages.push(
      <li key="export" className="!list-outside">
        <div className="inline-flex flex-col">
          <span className="font-semibold">Exporté vers le print</span>
          <div>
            le{" "}
            <Time date={printInfos.exportedAt} format="DD/MM/YYYY à HH:mm:ss" />
            {printInfos.exportedBy ? (
              <>
                {" "}
                par {printInfos.exportedBy.firstNameInitials}{" "}
                {printInfos.exportedBy.lastName}
              </>
            ) : null}
          </div>
        </div>
      </li>,
    );

    return (
      <div className="ml-5 flex gap-2">
        <ul className="flex flex-col">{messages}</ul>
      </div>
    );
  },
) as NamedExoticComponent<ArticlePrintInfosProps> & {
  fragments: { printInfos: DocumentNode };
};

ArticlePrintInfos.fragments = {
  printInfos: gql`
    fragment ArticlePrintInfos_printInfos on ArticlePrintInfos {
      exportedAt
      exportedBy {
        id
        firstNameInitials
        lastName
      }
      decorrelated
      decorrelatedAt
    }
  `,
};
