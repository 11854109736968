export const INLINE_STYLE = "STRIKETHROUGH";

export const allowedInlineStyles = [INLINE_STYLE];
export const allowedAttributes = { strike: [], del: [], s: [] };

/** @type {import('../types').htmlToStyle} */
export const htmlToStyle = ({ element, getStyle }) => {
  const { tagName } = element;
  const checkHasStrikeStyle = () => {
    const styles = getStyle();
    const textDecoration =
      styles["text-decoration"] || styles["text-decoration-line"];
    return textDecoration === "line-through";
  };
  if (
    tagName === "strike" ||
    tagName === "s" ||
    tagName === "del" ||
    checkHasStrikeStyle()
  ) {
    return [INLINE_STYLE];
  }
  return [];
};
