import { EditorState, Modifier } from "draft-js-es";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";

export const name = "switch-case";
export const command = "switch-case";

export const handleKeyCommand = ({ setEditorState, editorState }, cmd) => {
  if (cmd === command) {
    // getFragmentFromSelection allows to get all selected blocks
    const previousSelection = editorState.getSelection();
    const selected = getFragmentFromSelection(editorState);

    const previousBlockMapKeys = editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq();

    const anchorKeyIndex = previousBlockMapKeys.findIndex(
      (blockKey) => blockKey === previousSelection.getAnchorKey(),
    );
    const focusKeyIndex = previousBlockMapKeys.findIndex(
      (blockKey) => blockKey === previousSelection.getFocusKey(),
    );

    const plainSelectedText = (selected || []).map((x) => x.getText()).join("");
    if (plainSelectedText.length === 0) return undefined;

    const lowercaseCount = plainSelectedText
      .split("")
      .reduce((count, letter) => {
        // excluding non-letter characters
        if (letter.toLowerCase() === letter.toUpperCase()) return count;
        // eslint-disable-next-line no-param-reassign
        count += letter === letter.toLowerCase() ? 1 : 0;
        return count;
      }, 0);

    const transformText = (str) =>
      lowercaseCount > plainSelectedText.length - lowercaseCount
        ? str.toUpperCase()
        : str.toLowerCase();

    const updatedBlocks = selected.map((block) =>
      block.set("text", transformText(block.get("text"))),
    );

    const newContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      updatedBlocks,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-fragment",
    );

    const newBlockMapKeys = newEditorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq();

    const newSelectionState = previousSelection.merge({
      anchorKey: newBlockMapKeys.get(anchorKeyIndex),
      focusKey: newBlockMapKeys.get(focusKeyIndex),
    });

    setEditorState(
      EditorState.forceSelection(newEditorState, newSelectionState),
    );

    return true;
  }
  return undefined;
};
