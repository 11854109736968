import { gql } from "@apollo/client";

export const ConnectionFragment = gql`
  fragment ConnectionFragment on Connection {
    pageInfo {
      hasMore
    }
    totalCount
  }
`;
