import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { EnumField, StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.svg";

export const IntegrationConfigFragment = gql`
  fragment ForecastIntegrationConfigFragment on ForecastIntegrationConfig {
    version
    baseUrl
    clientId
    apiKey
    lang
  }
`;

function formatValues(config) {
  if (!config) {
    return {
      version: "v1",
      clientId: "",
    };
  }

  return {
    name: config.name,
    version: config.version,
    baseUrl: config.baseUrl,
    apiKey: config.apiKey,
    clientId: config.clientId,
    lang: config.lang,
  };
}

function parseValues(values) {
  return {
    ...values,
    name: `Forecast ${values.version}`,
  };
}

function LangField() {
  return (
    <FieldsetField>
      <EnumField
        name="lang"
        enum={{ fr: "fr", en: "en" }}
        label="Langue"
        appearance="checkbox"
        required
      />
    </FieldsetField>
  );
}

function ClientIdField() {
  return (
    <FieldsetField>
      <StringField name="clientId" label="Client Id" required />
    </FieldsetField>
  );
}

function Fields() {
  const version = useSubscribeFormValue("version");
  return (
    <Fieldset>
      <FieldsetField>
        <EnumField
          name="version"
          label="Version"
          appearance="checkbox"
          enum={{ v1: "v1", v2: "v2" }}
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField name="baseUrl" label="Base URL" required />
      </FieldsetField>
      {version === "v2" && <ClientIdField />}
      <FieldsetField>
        <StringField name="apiKey" label="Client Key" required />
      </FieldsetField>
      {version === "v1" && <LangField />}
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({
  Fields,
  formatValues,
  parseValues,
});
