import { useEffect, useRef } from "react";
import { cn } from "swash/utils/classNames";
import { useLiveRef } from "swash/utils/useLiveRef";

type Twitter = {
  widgets: {
    createTweet: (
      id: string,
      element: HTMLElement,
      options: { lang?: string },
    ) => Promise<void>;
  };
};

type TwitterAction = (twttr: Twitter) => void;

declare global {
  interface Window {
    twttr: Twitter;
  }
}

const pendingActions: TwitterAction[] = [];

const runTwitterAction = (action: TwitterAction) => {
  if (window.twttr) action(window.twttr);
  else pendingActions.push(action);
};

async function injectTwitterScript() {
  const id = "twitter-script";
  const existingScript = document.getElementById(id);
  if (existingScript) return null;

  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = id;
    script.onload = () => {
      try {
        pendingActions.forEach((action) => {
          action(window.twttr);
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    script.onerror = reject;
    script.src = "https://platform.twitter.com/widgets.js";
    document.body.appendChild(script);
  });
}

type TwitterEmbedProps = {
  tweetId: string;
  onLoad?: () => void;
  onError?: (error: unknown) => void;
};

export function TwitterEmbed({ tweetId, onLoad, onError }: TwitterEmbedProps) {
  const tweetRef = useRef<HTMLDivElement>(null);
  const onLoadRef = useLiveRef(onLoad);
  const onErrorRef = useLiveRef(onError);

  useEffect(() => {
    let cancelled: boolean;
    runTwitterAction((twttr) => {
      twttr.widgets
        .createTweet(tweetId, tweetRef.current!, { lang: "fr" })
        .then(() => {
          if (!cancelled && onLoadRef.current) {
            onLoadRef.current();
          }
        })
        .catch((error) => {
          if (!cancelled && onErrorRef.current) {
            onErrorRef.current(error);
          }
        });
    });
    injectTwitterScript().catch((error) => {
      if (!cancelled && onErrorRef.current) {
        onErrorRef.current(error);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [tweetId, onLoadRef, onErrorRef]);

  return (
    <div
      ref={tweetRef}
      className={cn(
        "relative grid place-items-center",
        "[&_.twitter-tweet]:!m-0",
        '[[data-change="deleted"]_&]:py-2',
        '[[data-change="deleted"]_&]:before:absolute [[data-change="deleted"]_&]:before:inset-0 [[data-change="deleted"]_&]:before:bg-danger-bg [[data-change="deleted"]_&]:before:opacity-25',
        '[[data-change="deleted"]_&]:after:absolute [[data-change="deleted"]_&]:after:text-[500px] [[data-change="deleted"]_&]:after:text-white [[data-change="deleted"]_&]:after:content-["×"]',
        '[[data-change="added"]_&]:py-2',
        '[[data-change="added"]_&]:before:absolute [[data-change="added"]_&]:before:inset-0 [[data-change="added"]_&]:before:bg-success-bg [[data-change="added"]_&]:before:opacity-25',
      )}
    />
  );
}
