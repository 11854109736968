import * as React from "react";
import { forwardRef } from "react";

const SvgRefreshDoubleCircle = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25c-5.376 0-9.75 4.374-9.75 9.75s4.374 9.75 9.75 9.75 9.75-4.374 9.75-9.75S17.376 2.25 12 2.25ZM9.102 6.96a6.202 6.202 0 0 1 3.62-.667c1.25.148 2.42.669 3.33 1.494a5.258 5.258 0 0 1 1.69 3.1.75.75 0 1 1-1.483.226 3.76 3.76 0 0 0-1.215-2.215 4.506 4.506 0 0 0-2.498-1.115 4.702 4.702 0 0 0-2.742.502c-.415.22-.782.498-1.088.82H9.5a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75v-2.29a.75.75 0 0 1 1.5 0v.383c.394-.39.85-.723 1.352-.989Zm-1.36 5.927a.75.75 0 1 0-1.484.226 5.258 5.258 0 0 0 1.69 3.1 6.006 6.006 0 0 0 3.33 1.494 6.202 6.202 0 0 0 3.62-.666c.502-.266.958-.6 1.352-.989v.384a.75.75 0 0 0 1.5 0v-2.29a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0 0 1.5h.784c-.306.321-.673.6-1.088.819a4.702 4.702 0 0 1-2.742.502 4.506 4.506 0 0 1-2.498-1.115 3.759 3.759 0 0 1-1.214-2.215Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRefreshDoubleCircle);
export default ForwardRef;
