/**
 * @param {string} url
 */
export function getShortUrl(url) {
  try {
    const urlObj = new URL(url);
    return `${urlObj.host.replace(/^www\./, "")}${
      urlObj.pathname === "/" ? "" : urlObj.pathname
    }${urlObj.search}`;
  } catch {
    return "";
  }
}
