import * as React from "react";
import { forwardRef } from "react";

const SvgPieChartOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.002 3.78c.247-.02.496-.03.748-.03 4.969 0 9 4.031 9 9s-4.031 9-9 9a9.005 9.005 0 0 1-8.573-6.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12 2.25A9.75 9.75 0 0 0 2.25 12a9.717 9.717 0 0 0 .848 3.984L12 12V2.25Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPieChartOutline);
export default ForwardRef;
