import memoize from "lodash/memoize";

const MAX_MEMOIZE_SIZE = 300;

export const memoizeCapped = (fn, resolver = (x) => x) => {
  let cache;
  const result = memoize(fn, (...args) => {
    if (cache.size === MAX_MEMOIZE_SIZE) {
      cache.clear();
    }
    return resolver(...args);
  });

  cache = result.cache;
  return result;
};
