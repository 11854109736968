import * as React from "react";
import { forwardRef } from "react";

const SvgCollapse = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12c0-.182.068-.357.188-.486a.622.622 0 0 1 .455-.201h16.714c.17 0 .334.072.455.2.12.13.188.305.188.487a.712.712 0 0 1-.188.486.622.622 0 0 1-.455.201H3.643a.622.622 0 0 1-.455-.2A.712.712 0 0 1 3 12Zm9-11c.17 0 .334.072.455.201.12.13.188.304.188.486v5.216l1.473-1.577a.623.623 0 0 1 .455-.202c.171 0 .335.073.456.202.12.129.188.304.188.487a.713.713 0 0 1-.188.486l-2.572 2.75a.642.642 0 0 1-.209.15.607.607 0 0 1-.701-.15L8.973 6.3a.713.713 0 0 1-.188-.487c0-.182.068-.357.188-.486a.623.623 0 0 1 .456-.202c.17 0 .334.073.455.202l1.473 1.577V1.688c0-.183.068-.358.188-.487A.623.623 0 0 1 12 1Zm-.643 16.097-1.473 1.577a.623.623 0 0 1-.455.202.623.623 0 0 1-.456-.202.713.713 0 0 1-.188-.486c0-.183.068-.358.188-.487l2.572-2.75a.64.64 0 0 1 .209-.15.606.606 0 0 1 .701.15l2.572 2.75c.12.129.188.304.188.486a.713.713 0 0 1-.188.487.623.623 0 0 1-.456.202.623.623 0 0 1-.455-.202l-1.473-1.577v5.215a.713.713 0 0 1-.188.487A.622.622 0 0 1 12 23a.622.622 0 0 1-.455-.201.713.713 0 0 1-.188-.486v-5.216Z"
      fill="#2B3C50"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCollapse);
export default ForwardRef;
