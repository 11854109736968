import { gql } from "@apollo/client";

import {
  ArticleModifications,
  ArticleModificationsProps,
} from "@/containers/article/ArticleModifications";
import type { CellType } from "@/containers/list/ListState";

export type ModificationsCellArticle = ArticleModificationsProps["article"];

export const modificationsCell: CellType<ModificationsCellArticle> = {
  key: "modifications",
  title: "Modifications",
  width: 88,
  render: ({ node }) => <ArticleModifications article={node} />,
  fragment: gql`
    fragment modificationsCell_article on Article {
      ...ArticleModifications_article
    }

    ${ArticleModifications.fragments.article}
  `,
};
