import * as React from "react";
import { forwardRef } from "react";

const SvgSeparator = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM7 6.5c0-.276.187-.5.417-.5h9.166c.23 0 .417.224.417.5s-.186.5-.417.5H7.417C7.187 7 7 6.776 7 6.5ZM7 20.5c0-.276.187-.5.417-.5h9.166c.23 0 .417.224.417.5s-.186.5-.417.5H7.417c-.23 0-.417-.224-.417-.5ZM7 3.5c0-.276.187-.5.417-.5h9.166c.23 0 .417.224.417.5s-.186.5-.417.5H7.417C7.187 4 7 3.776 7 3.5ZM7 17.5c0-.276.187-.5.417-.5h9.166c.23 0 .417.224.417.5s-.186.5-.417.5H7.417c-.23 0-.417-.224-.417-.5Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSeparator);
export default ForwardRef;
