import { x } from "@xstyled/styled-components";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";

import { DropTarget } from "../DropTarget";

export const DEFAULT_MAX_SIZE = 20 * 1000000; // in bytes (20Mo)
export const DEFAULT_MAX_FILES = 100;

export function useFileDropState({
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
  "aria-labelledby": ariaLabelledBy,
  labelledBy = ariaLabelledBy,
  "aria-label": ariaLabel,
  label = ariaLabel,
}) {
  const state = {
    id,
    name,
    label,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    labelledBy: labelledBy || placeholder?.id,
  };
  return { state };
}

function getDragFileErrorMessage(type, maxFileSize) {
  switch (type) {
    case "image":
      return `Seuls les fichiers .jpg, .jpeg et .png sont supportés avec une limite de ${maxFileSize} Mo`;
    case "file":
      return `Seuls les fichiers .doc, .docx et .pdf sont supportés avec une limite de ${maxFileSize} Mo`;
    case "audio":
      return `Seuls les fichiers audio .mp3 et .wav sont supportés avec une limite de ${maxFileSize} Mo`;
    default:
      return `Ce type de fichier n'est pas supporté.`;
  }
}

function getImportFileErrorMessage(files, type, maxFileSize) {
  const extraErrorMessages = [];
  files[0].errors.map((error) => {
    switch (error.code) {
      case "file-invalid-type": {
        switch (type) {
          case "image":
            extraErrorMessages.push({
              code: error.code,
              message: `Seuls les fichiers .jpg, .jpeg et .png sont supportés.`,
            });
            break;
          case "file":
            extraErrorMessages.push({
              code: error.code,
              message: `Seuls les fichiers .doc, .docx et .pdf sont supportés.`,
            });
            break;
          case "audio":
            extraErrorMessages.push({
              code: error.code,
              message: `Seuls les fichiers audio .mp3 et .wav sont supportés.`,
            });
            break;
          default:
            extraErrorMessages.push({
              code: error.code,
              message: `Ce type de fichier n'est pas supporté.`,
            });
        }
        break;
      }
      case "file-too-large": {
        extraErrorMessages.push({
          code: error.code,
          message: `La taille maximale acceptée est de ${
            maxFileSize >= 1 ? `${maxFileSize}Mo` : `${maxFileSize * 1000}Ko`
          }.`,
        });
        break;
      }
      default:
        extraErrorMessages.push({
          code: error.code,
          message: error.message,
        });
    }
    return extraErrorMessages;
  });

  return (
    <div>
      <div>Fichier invalide: </div>
      {extraErrorMessages.length && (
        <ul>
          {extraErrorMessages.map(({ code, message }) => (
            <li key={code}>{message}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export const FileDrop = forwardRef(
  (
    {
      accept,
      children,
      disabled,
      filled,
      getFilesFromEvent,
      invalid: invalidProp,
      label,
      labelledBy,
      maxFiles,
      maxFileSize,
      multiple = false,
      name,
      noClick,
      noKeydown,
      noValidation = false,
      onChange,
      onReject,
      onBlur,
      onFocus,
      required,
      selfValidation = true,
      validator,
      value,
      type,
      ...props
    },
    ref,
  ) => {
    const [skipInvalidRules, setSkipInvalidRules] = useState(false);

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      fileRejections,
      isDragReject,
      open,
    } = useDropzone({
      accept,
      multiple,
      onDropRejected: (fileRejections) =>
        onReject
          ? onReject(multiple ? fileRejections : fileRejections[0])
          : null,

      onDrop: (files) => onChange(multiple ? files : files[0]),
      noClick,
      ...(noValidation
        ? {}
        : {
            maxSize: maxFileSize * 1e6 || DEFAULT_MAX_SIZE,
            maxFiles: maxFiles || DEFAULT_MAX_FILES,
          }),
      noKeydown,
      disabled,
      validator,
      ...(getFilesFromEvent && { getFilesFromEvent }),
    });

    useImperativeHandle(ref, () => ({ open }), [open]);

    const invalid = selfValidation
      ? multiple
        ? isDragReject
        : Boolean(fileRejections.length)
      : false;
    const droptargetAccept = isDragActive ? isDragAccept : undefined;
    const dropInvalid = isDragActive ? !isDragAccept : false;
    const rootProps = getRootProps();
    // skip invalid rules with more than 1 file dragged
    const skipInvalid = isDragActive && skipInvalidRules;

    return (
      <DropTarget
        disabled={disabled}
        invalid={skipInvalid ? false : dropInvalid || invalid || invalidProp}
        accept={skipInvalid || droptargetAccept}
        expandable={false}
        scale="md"
        alignItems="center"
        filled={filled}
        {...rootProps}
        onDragEnter={(event) => {
          setSkipInvalidRules(event.dataTransfer.items.length > 1);
          if (rootProps.onDragEnter) rootProps.onDragEnter(event);
        }}
        onBlur={(event) => {
          if (onBlur) onBlur(event);
          if (rootProps.onBlur) rootProps.onBlur(event);
        }}
        onFocus={(event) => {
          if (onFocus) onFocus(event);
          if (rootProps.onFocus) rootProps.onFocus(event);
        }}
        {...props}
      >
        <input
          {...getInputProps()}
          required={required}
          name={name}
          disabled={disabled}
          aria-label={label}
          aria-labelledby={labelledBy}
          aria-invalid={invalid}
        />
        {!filled && isDragActive ? (
          multiple || isDragAccept ? (
            <x.div display="flex" flexDirection="column" gap={2}>
              {value?.length ? children : undefined}
              <div>Déposer votre fichier ici ...</div>
            </x.div>
          ) : (
            <div>
              <div>Fichier invalide:</div>
              {getDragFileErrorMessage(type, maxFileSize)}
            </div>
          )
        ) : !multiple && invalid ? (
          getImportFileErrorMessage(fileRejections, type, maxFileSize)
        ) : (
          children
        )}
      </DropTarget>
    );
  },
);
