import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { ArrayField, StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment AccengageIntegrationConfigFragment on AccengageIntegrationConfig {
    apiKey
    email
    partnerId
    campaignId
    segments
    tags
  }
`;

function formatValues(config) {
  if (!config) {
    return {
      name: "",
      apiKey: "",
      email: "",
      partnerId: "",
      campaignId: "",
      segments: [],
      tags: [],
    };
  }
  return {
    name: config.name,
    apiKey: config.apiKey,
    email: config.email,
    partnerId: config.partnerId,
    campaignId: config.campaignId,
    segments: config.segments,
    tags: config.tags,
  };
}

function parseValues(values) {
  return {
    ...values,
    segments: values.segments
      .map((value) => Number.parseInt(value.trim(), 10))
      .filter((value) => !Number.isNaN(value)),
  };
}

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="name" label="Name" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="apiKey" label="API Key" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="email" label="Email" type="email" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="partnerId" label="Partner ID" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="campaignId" label="Campaign ID" required />
      </FieldsetField>
      <FieldsetField>
        <ArrayField name="segments" label="Segments" />
      </FieldsetField>
      <FieldsetField>
        <ArrayField
          name="tags"
          label="Tags"
          hint="Espacer les tags par une virgule, exemple : Alerte, Abonnées"
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({
  Fields,
  formatValues,
  parseValues,
});
