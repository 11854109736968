/**
 * Merge entity data.
 * By default, it does not affect the `contentState`, but in an ideal world it should.
 * So we will.
 * @param {import('draft-js').ContentState} contentState
 * @param {string} entityKey
 * @param {object} data
 */
export const mergeEntityData = (contentState, entityKey, data) => {
  // This method mutates the entity data
  contentState.mergeEntityData(entityKey, data);
  // Set a fake "entityMap" property to trigger a change on content state
  return contentState.set("entityMap", contentState.getEntityMap().__getAll());
};
