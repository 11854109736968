import { gql } from "@apollo/client";

import { SnippetPreview } from "@/containers/snippet/SnippetPreview";
import { SnippetSummary } from "@/containers/snippet/SnippetSummary";

export const SnippetCardFragment = gql`
  fragment SnippetCardArticle_snippet on Snippet {
    id
    obsolete
    ...SnippetPreview_snippet
    ...SnippetSummary_snippet__article
  }
  ${SnippetPreview.fragments["snippet"]}
  ${SnippetSummary.fragments["snippet__article"]}
`;
