import { useMemo } from "react";

import { useRemoteConfig } from "@/containers/RemoteConfig";

export const useConfigHosts = () => {
  const config = useRemoteConfig();
  const { frontBaseUrl } = config;
  const { ripUrl } = config.images;

  return useMemo(() => {
    const urlObjFront = new URL(frontBaseUrl);
    const urlObjImage = new URL(ripUrl);

    return { frontHost: urlObjFront.host, imageHost: urlObjImage.host };
  }, [frontBaseUrl, ripUrl]);
};
