import { HTMLAttributes } from "react";
import { VariantProps, cva } from "swash/utils/classNames";

const descriptionList = cva("[&>dt]:opacity-75", {
  variants: {
    layout: {
      fixed: /* tw */ `grid grid-cols-[max-content_1fr]`,
      fluid: /* tw */ `mb-0 [&>dd:after]:block [&>dd:after]:content-[''] [&>dd:last-child]:pb-0 [&>dd]:ml-1 [&>dd]:inline [&>dt:nth-last-child(2)]:pb-0 [&>dt]:inline`,
    },
    scale: {
      sm: null,
      md: null,
    },
  },
  compoundVariants: [
    {
      layout: "fixed",
      scale: "sm",
      className: /* tw */ `gap-3`,
    },
    {
      layout: "fixed",
      scale: "md",
      className: /* tw */ `gap-4`,
    },
    {
      layout: "fluid",
      scale: "sm",
      className: /* tw */ `[&>dd:after]:pb-3`,
    },
    {
      layout: "fluid",
      scale: "md",
      className: /* tw */ `[&>dd:after]:pb-4`,
    },
  ],
  defaultVariants: {
    layout: "fixed",
    scale: "md",
  },
});

interface DescriptionListProps
  extends HTMLAttributes<HTMLDListElement>,
    VariantProps<typeof descriptionList> {}

export const DescriptionList: React.FC<DescriptionListProps> = ({
  layout,
  scale,
  className,
  ...props
}) => {
  return (
    <dl
      className={descriptionList({
        layout,
        scale,
        className,
      })}
      {...props}
    />
  );
};
