import { useCallback, useRef } from "react";
import { useDrag } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useEventCallback } from "swash/utils/useEventCallback";

/**
 * Drag a native URL.
 * @param {string | () => string} url
 * @param {Omit<import('react-dnd').DragSourceHookSpec, 'type' | 'item'>} [options]
 */
export function useDragUrl(url, options) {
  const getUrl = useEventCallback(() => {
    if (typeof url === "function") {
      return url();
    }
    return url;
  });
  const [collected, connectDrag, dragPreview] = useDrag({
    type: NativeTypes.URL,
    item: () => ({
      urls: [getUrl()],
    }),
    ...options,
  });
  const connectUniversalDrag = useCallback(
    (element) => {
      if (!element) return undefined;
      const listener = (event) => {
        const url = getUrl();
        event.dataTransfer.setData("url", url);
        event.dataTransfer.setData("text", url);
      };
      element.addEventListener("dragstart", listener);
      return () => {
        element.removeEventListener("dragstart", listener);
      };
    },
    [getUrl],
  );
  const disconnectUniversalDragRef = useRef();
  const urlDrag = useCallback(
    (element) => {
      connectDrag(element);
      disconnectUniversalDragRef.current?.();
      disconnectUniversalDragRef.current = connectUniversalDrag(element);
    },
    [connectDrag, connectUniversalDrag],
  );
  return [collected, urlDrag, dragPreview];
}
