import { forwardRef } from "react";

const SvgBagDoubleOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.126 10.138a.525.525 0 0 0-.525.525l-.764 7.09c0 .993.845 1.838 1.838 1.838h8.928c.992 0 1.838-.804 1.838-1.797l-.764-7.13a.525.525 0 0 0-.525-.526H9.126Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.988 10.138v-1.05a3.151 3.151 0 0 1 3.15-3.151v0a3.151 3.151 0 0 1 3.152 3.15v1.051"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.306 8.61a.525.525 0 0 0-.525.525l-.764 7.09c0 .993.846 1.838 1.838 1.838h8.928c.993 0 1.839-.804 1.839-1.797l-.764-7.13a.525.525 0 0 0-.526-.526H5.306Z"
      fill="#fff"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.168 8.61V7.56a3.151 3.151 0 0 1 3.151-3.151v0a3.151 3.151 0 0 1 3.151 3.15V8.61"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBagDoubleOutline);
export default ForwardRef;
