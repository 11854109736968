import type { ContentBlock, ContentState } from "draft-js-es";

import type { ComposeEntity } from "@/components/rich-editor/utils/CompositeEntity";

type CommentOnBlock = {
  startOffset: number;
  endOffset: number;
  commentId: number;
};

const getCommentsFromContentBlock = (
  contentBlock: ContentBlock,
  contentState: ContentState,
): CommentOnBlock[] => {
  const ranges: { start: number; end: number; commentIds: number[] }[] = [];

  let commentIds: number[] = [];

  contentBlock.findEntityRanges(
    (charMeta) => {
      const entityKey = charMeta.getEntity();
      if (!entityKey) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() !== "COMPOSITE") return false;
      const composeEntities = entity.getData().entities ?? [];
      const comments = composeEntities.filter(
        ({ type, id }: ComposeEntity) => type === "COMMENT" && id !== -1,
      );
      commentIds = comments.map(({ id }: { id: number }) => id);
      return comments.length > 0;
    },
    (start, end) => {
      ranges.push({ start, end, commentIds });
    },
  );

  // Merge edge ranges
  return ranges.reduce((curr: CommentOnBlock[], { start, end, commentIds }) => {
    commentIds.forEach((commentId) => {
      const foundIndex = curr.findIndex(
        ({ endOffset, commentId: prevCommentId }) =>
          endOffset === start && prevCommentId == commentId,
      );
      if (foundIndex !== -1) {
        curr[foundIndex] = {
          ...(curr[foundIndex] as CommentOnBlock),
          endOffset: end,
        };
      } else {
        curr.push({ startOffset: start, endOffset: end, commentId });
      }
    });
    return curr;
  }, []);
};

export default getCommentsFromContentBlock;
