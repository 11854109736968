import * as React from "react";
import { forwardRef } from "react";

const SvgZipFile = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.538 14.104V13h-1.076v1.104c0 .096-.01.192-.032.286L11 16.27l1 .73 1-.73-.43-1.88a1.282 1.282 0 0 1-.032-.286Z"
      fill="currentColor"
    />
    <path
      d="M6.667 1h10.666c.708 0 1.386.29 1.886.805.5.516.781 1.216.781 1.945v16.5c0 .73-.281 1.429-.781 1.945-.5.515-1.178.805-1.886.805H6.667c-.708 0-1.386-.29-1.886-.805A2.794 2.794 0 0 1 4 20.25V3.75c0-.73.28-1.429.781-1.945A2.626 2.626 0 0 1 6.667 1ZM10 12.688v1.29l-.533 2.198c-.067.275-.05.565.05.829s.276.49.504.648l1.24.852a1.305 1.305 0 0 0 1.479 0l1.24-.852c.229-.158.404-.384.503-.648.1-.265.117-.554.05-.83L14 13.978v-1.29c0-.364-.14-.714-.39-.972a1.313 1.313 0 0 0-.943-.402h-1.334c-.353 0-.692.144-.943.402-.25.258-.39.608-.39.973Zm1.333-7.563H10V6.5h1.333v1.375H10V9.25h1.333v1.375h2V9.25H12V7.875h1.333V6.5H12V5.125h1.333V3.75H12V2.375h-2V3.75h1.333v1.375Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgZipFile);
export default ForwardRef;
