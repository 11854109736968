import { useRef } from "react";
import { useField } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";

import { FieldControl } from "@/components/fields/FieldControl";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import { RichEditor } from "@/components/rich-editor/RichEditor";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";
import { RichEditorToolbar } from "@/components/rich-editor/RichEditorToolbar";
import { fromNodes } from "@/components/rich-editor/convert/fromNodes";
import { toNodes } from "@/components/rich-editor/convert/toNodes";
import { SpellCheckProvider } from "@/components/rich-editor/plugins/spell-check-control/SpellCheckPluginContext";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useBlockTemplates } from "@/containers/BlockTemplates";
import { useRichEditorPreset } from "@/containers/editor/presets/preset-rich-editor";

const toContentState = (value, plugins) => fromNodes(value || [], plugins);

export function DetachedEditor(props) {
  return (
    <SpellCheckProvider>
      <InnerEditor {...props} />
    </SpellCheckProvider>
  );
}

const InnerEditor = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  blockTemplates,
  disabled,
  readOnly,
  ...props
}) => {
  const plugins = useRichEditorPreset({
    blocks: false,
    headerThree: false,
  });
  const editingRef = useRef(false);
  const onChangeRef = useLiveRef(onChange);

  // At start, "value" defines the "contentState"
  const [contentState, setContentState] = useEnhancedState(
    () => toContentState(value, plugins),
    (nextState) => {
      if (onChangeRef.current) {
        onChangeRef.current(toNodes(nextState));
      }
    },
  );

  const editor = useRichEditorState({
    name,
    label,
    contentState,
    setContentState,
    plugins,
    blockTemplates,
    readOnly: readOnly || disabled,
  });

  const handleFocus = (evt) => {
    if (onFocus) onFocus(evt);
    if (editingRef.current) return;
    editingRef.current = true;
  };

  const handleBlur = (evt) => {
    if (onBlur) onBlur(evt);
    editingRef.current = false;
  };

  return (
    <div {...props}>
      <div className="m-4">
        <RichEditorToolbar
          {...editor}
          onMouseDown={(event) => {
            event.preventDefault();
            editor.lockFocus();
          }}
        />
      </div>
      <RichEditor
        {...editor}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

export function useRichTextEditorField(
  name,
  { required = false, id, orientation = "vertical", format, ...options } = {},
) {
  const field = useField(name, {
    format,
    ...options,
  });
  return useFieldState({
    field,
    id,
    orientation,
    required,
  });
}

export function RichTextField({
  name,
  label,
  hint,
  placeholder,
  disabled,
  readOnly,
  ...options
}) {
  const field = useRichTextEditorField(name, options);
  const blockTemplates = useBlockTemplates();
  const fieldProps = {
    ...field,
    placeholder,
    disabled,
    readOnly,
    blockTemplates,
  };
  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FieldControl
        as={DetachedEditor}
        {...fieldProps}
        className="rounded-sm border border-grey-border-light bg-white"
      />
    </FieldGroup>
  );
}
