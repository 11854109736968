// @ts-check

const type = "images";

/** @type {import('.').MediaProvider["getData"]} */
function getData({ url, imageHost }) {
  try {
    const urlObj = new URL(url);
    if (urlObj.host === imageHost) {
      return { url };
    }
    if (urlObj.host === window.location.host) {
      const matches = urlObj.pathname.match(/\/images\/(?<id>\d+)/);
      if (!matches?.groups?.["id"]) return null;
      return { id: Number(matches.groups["id"]) };
    }
    return null;
  } catch {
    return null;
  }
}

/** @type {import('.').MediaProvider} */
export const provider = { type, getData };
