import * as React from "react";
import { forwardRef } from "react";

const SvgIndice1 = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m8.5 5.5 4 4.5-4 4.5"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIndice1);
export default ForwardRef;
