/**
 * @typedef RuntimeConfig
 * @property {string} clientId
 * @property {Object} sentry
 * @property {boolean} sentry.enabled
 * @property {string} sentry.environment
 * @property {Object} amplitude
 * @property {string} amplitude.apiKey
 */

/** @type {RuntimeConfig} */
const runtimeConfig = window.__siriusRuntimeConfig;

export default runtimeConfig;
