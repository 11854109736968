import { gql } from "@apollo/client";
import { useCallback } from "react";
import { IoApps } from "swash/Icon";

import { NamedIcon } from "@/components/NamedIcon";
import { useHasLevelAccess, useHasPermission } from "@/containers/User";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const generateCustomTypeContentAdminSlug = ({ customTypeId, contentId }) => {
  return `${
    new URL(window.location).origin
  }/admin/contents/${customTypeId}/${contentId}`;
};

export function CustomTypeContentNode({ content, tooltip }) {
  const hasLevelAccess = useHasLevelAccess("developer");
  const hasPermission = useHasPermission(
    `content:${content.customType.name}:edit`,
  );
  const canEdit = hasLevelAccess || hasPermission;

  const handleClick = useCallback(() => {
    if (!canEdit) return;

    const url = generateCustomTypeContentAdminSlug({
      customTypeId: content.customType.id,
      contentId: content.id,
    });
    window.open(url, "_blank");
  }, [content, canEdit]);

  return (
    <EditorNodeCard
      onClick={handleClick}
      cursor={canEdit ? "pointer" : "default"}
      variant={
        !content.enabled || !content.customType.enabled ? "danger" : "valid"
      }
    >
      <EditorNodeLayout>
        <EditorNodeTooltip tooltip={tooltip}>
          <EditorNodeIcon>
            {content.customType.icon ? (
              <NamedIcon
                name={content.customType.icon}
                aria-label={content.label}
              />
            ) : (
              <IoApps />
            )}
          </EditorNodeIcon>
        </EditorNodeTooltip>
        <EditorNodeBody multiline>
          <ChangeText rawText={content.label}>{content.label}</ChangeText>
        </EditorNodeBody>
      </EditorNodeLayout>
    </EditorNodeCard>
  );
}

CustomTypeContentNode.fragments = {
  content: gql`
    fragment CustomTypeContentNode_customTypeContent on CustomTypeContent {
      label
      enabled
      customType {
        id
        name
        icon
        enabled
      }
    }
  `,
};
