import * as React from "react";
import { forwardRef } from "react";

const SvgTitlesOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <mask id="titles-outline_svg__a" fill="#fff">
      <rect x={1.091} y={2.215} width={21.818} height={8} rx={1} />
    </mask>
    <rect
      x={1.091}
      y={2.215}
      width={21.818}
      height={8}
      rx={1}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#titles-outline_svg__a)"
    />
    <rect
      x={1.091}
      y={16.215}
      width={21.818}
      height={1.636}
      rx={0.818}
      fill="currentColor"
    />
    <path
      d="M1.09 13.215c0-.452.367-.819.819-.819h20.182a.818.818 0 1 1 0 1.637H1.909a.818.818 0 0 1-.818-.818Z"
      fill="currentColor"
    />
    <rect
      x={1.091}
      y={20.033}
      width={10.909}
      height={1.636}
      rx={0.818}
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTitlesOutline);
export default ForwardRef;
