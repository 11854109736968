// @ts-check
import { blocksToHtml } from "./blocksToHtml";

/**
 * @typedef {import('draft-js').ContentState} ContentState
 * @typedef {import('draft-js').ContentBlock} ContentBlock
 * @typedef {import('draft-js').EntityInstance} EntityInstance
 */

/**
 * convertToPlain
 * @param {string} html
 * @returns
 */
const convertToPlain = (html) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;

  return tempDivElement.textContent || tempDivElement.innerText || "";
};

/**
 * @param {ContentState} contentState
 * @param {ContentBlock[]} selectedBlocks
 * @returns
 */
const selectedEntities = (contentState, selectedBlocks) => {
  const entities = /** @type {{[key: string]: EntityInstance}} */ ({});
  selectedBlocks.forEach((block) => {
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey) {
          entities[entityKey] = contentState.getEntity(entityKey);
        }
        return false;
      },
      () => undefined,
    );
  });
  return entities;
};

/**
 * @param {{event: ClipboardEvent, contentState: ContentState, selectedBlocks: ContentBlock[], env: string, editorId: string, articleId?: number }} param0
 * @returns
 */
export const modifyClipboard = ({
  event,
  contentState,
  selectedBlocks,
  env,
  editorId,
  articleId,
}) => {
  try {
    const html = blocksToHtml(selectedBlocks, contentState);
    const text = convertToPlain(html).trim();
    const entities = selectedEntities(contentState, selectedBlocks);

    event.clipboardData?.setData(`text/plain`, text);
    event.clipboardData?.setData(`text/html`, html);
    event.clipboardData?.setData(
      `text/_sirius-editor-${env}`,
      JSON.stringify({
        contentState: selectedBlocks,
        entities,
        editorId,
        articleId,
      }),
    );
  } catch (/** @type {any} */ error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
    return;
  }
  event.preventDefault();
};
