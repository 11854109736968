import * as React from "react";
import { forwardRef } from "react";

const SvgIconMobile = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4V2h4v2h-4Zm0 0h-2a2.5 2.5 0 0 0 2.5 2.5h3A2.5 2.5 0 0 0 20 4h-2a.5.5 0 0 1-.5.5h-3A.5.5 0 0 1 14 4Z"
      fill="currentColor"
    />
    <path
      d="M10 30h12a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H10a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3ZM9 5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V5Zm4 21a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIconMobile);
export default ForwardRef;
