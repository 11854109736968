import { forwardRef } from "react";

const SvgEdit = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.735 1.872 16.431.175A.56.56 0 0 1 16.84 0c.168 0 .311.058.43.175l2.554 2.536c.117.13.175.273.175.429a.587.587 0 0 1-.175.43l-1.697 1.696zM3.014 13.594 13.702 2.906l3.393 3.374L6.427 16.987zM.029 19.971c-.038-.038-.038-.116 0-.234l1.95-5.11 3.395 3.374-5.11 1.97c-.104.039-.182.039-.235 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
