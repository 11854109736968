import * as React from "react";
import { useForm, useFormState } from "react-final-form";
import { mergeRefs } from "react-merge-refs";
import { Button } from "swash/Button";

import { useSubmitterRef } from "./FormSubmitter";

/**
 * @typedef BaseFormSubmitProps
 * @property {boolean | undefined} [disabledIfPristine]
 *
 * @typedef {BaseFormSubmitProps & React.ComponentProps<typeof Button>} FormSubmitProps
 */

/**
 * @type {React.FC<FormSubmitProps>}
 */
export const FormSubmit = React.forwardRef(
  (
    { as: As = Button, disabledIfPristine, onClick, disabled, ...props },
    outerRef,
  ) => {
    const ref = React.useRef(null);
    const form = useForm();
    const submitterRef = useSubmitterRef();
    const { submitting, pristine, validating } = useFormState({
      subscription: {
        submitting: true,
        pristine: true,
        validating: true,
      },
    });
    function isDisabled() {
      if (disabled) return true;
      if (submitting) return true;
      if (validating) return true;
      if (disabledIfPristine && pristine) return true;
      return false;
    }
    return (
      <As
        ref={mergeRefs([ref, outerRef])}
        type="submit"
        disabled={isDisabled()}
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }
          if (event.defaultPrevented) return;
          event.preventDefault();
          submitterRef.current = ref.current;
          // Sometimes we do not use an explicit `form` or the submit could be outside the form
          Promise.resolve(form.submit()).finally(() => {
            submitterRef.current = null;
          });
        }}
        {...props}
      />
    );
  },
);
