import { gql } from "@apollo/client";
import type { CSSProperties, ReactElement } from "react";

import { useDragUrl } from "@/containers/Dnd";
import { getArticleUrl } from "@/containers/article/ArticleLink";
import {
  GetArticleStatusColorArticle,
  getArticleStatusColor,
} from "@/containers/article/util/status";

import { Capsule } from "./Capsule";
import {
  CapsuleLayoutEditorialTypeNbChar,
  CapsuleLayoutEditorialTypeNbCharProps,
} from "./CapsuleLayoutEditorialTypeNbChar";
import { CapsuleTitle } from "./CapsuleTitle";

export type ArticleCapsuleProps = {
  article: GetArticleStatusColorArticle &
    CapsuleLayoutEditorialTypeNbCharProps["article"];
  title: string;
  toolbar: ReactElement;
  topSection: ReactElement;
  bottomSection: ReactElement;
};

export const CapsuleArticle = (props: ArticleCapsuleProps) => {
  const { article, title, toolbar, topSection, bottomSection } = props;
  const [, dragRef] = useDragUrl(() => getArticleUrl(article.id), {
    canDrag: true,
  });
  const statusColor = getArticleStatusColor(article);

  return (
    <Capsule
      ref={dragRef}
      data-testid="capsule"
      className="group/capsule relative min-w-0 flex-1 shrink-0 select-none rounded border border-l-4 border-grey-border-light border-l-[var(--status-color)] bg-white p-2 shadow-card ring-primary-border/50 transition [[data-dragging=true]_&]:ring-2"
      style={{ "--status-color": statusColor } as CSSProperties}
    >
      {toolbar}
      <div className="flex gap-2">
        <div className="flex shrink-0 flex-col gap-1.5 text-sm">
          <CapsuleLayoutEditorialTypeNbChar article={article} />
          {topSection}
        </div>
        <div className="-mt-0.5 flex min-w-0 flex-1 flex-col gap-1">
          <CapsuleTitle article={article} title={title} />
          {bottomSection}
        </div>
      </div>
    </Capsule>
  );
};

CapsuleArticle.fragments = {
  article: gql`
    fragment ArticleCapsule_article on Article {
      signature
      hasNotes
      ...getArticleStatusColor
      ...CapsuleLayoutEditorialTypeNbChar_article
      ...CapsuleTitle_article
    }
    ${getArticleStatusColor.fragment}
    ${CapsuleLayoutEditorialTypeNbChar.fragments.article}
    ${CapsuleTitle.fragments.article}
  `,
};
