import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { useToaster } from "swash/Toast";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useListReadOnly } from "@/containers/list/List";

import {
  ArticleFragment,
  ArticleSection,
  ArticleSectionsButton,
  ArticleSectionsSelector,
  useArticleSectionsSelectState,
} from "./ArticleSectionsSelect";

const Mutation = gql`
  mutation ArticleSectionsSelect_updateArticle(
    $articleId: Int!
    $sectionIds: [Int!]!
  ) {
    updateArticle(input: { id: $articleId, sectionIds: $sectionIds }) {
      id
      ...ArticleSectionsSelect_article
    }
  }
  ${ArticleFragment}
`;

export type ArticleSectionsEditProps = {
  article: {
    id: number;
    sections: ArticleSection[];
  };
};

export const ArticleSectionsEdit = (props: ArticleSectionsEditProps) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));
  const [updateArticleSections] = useMutation(Mutation);
  const toaster = useToaster();
  const readOnly = useListReadOnly();
  const handleChange = useEventCallback((value: ArticleSection[]) => {
    const sections = value.map((section) => ({
      __typename: "Section",
      id: section.id,
      name: section.name,
    }));
    sections.sort((a, b) => a.name.localeCompare(b.name));
    updateArticleSections({
      variables: {
        articleId: props.article.id,
        sectionIds: value.map((section) => section.id),
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateArticle: {
          __typename: "Article",
          id: props.article.id,
          sections,
        },
      },
    }).catch(() => {
      toaster.danger("La mise à jour du service a échoué");
    });
  });

  if (!readOnly && active) {
    return (
      <ArticleSectionsSelector
        value={props.article.sections}
        onChange={handleChange}
        required
      />
    );
  }
  return (
    <ArticleSectionsButton
      sections={props.article.sections}
      onFocus={activate}
      onMouseEnter={activate}
      disabled={readOnly}
    />
  );
};

ArticleSectionsEdit.fragments = {
  article: gql`
    fragment ArticleSectionsEdit_article on Article {
      ...ArticleSectionsSelect_article
    }
    ${useArticleSectionsSelectState.fragments.article}
  `,
};
