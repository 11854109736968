import { CROPS, HOME_IMG_SIZES } from "@/config/constants";

export const getMask = (articleMedia, ratio) =>
  articleMedia?.metadata?.crops?.[CROPS[ratio]];

export const getResizedMedia = (articleMedia, ratio) =>
  articleMedia ? { ...articleMedia.media, ...HOME_IMG_SIZES[ratio] } : null;

export const agencyFormatter = new Intl.ListFormat("fr", {
  style: "long",
  type: "conjunction",
});
