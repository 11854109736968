import * as React from "react";
import { forwardRef } from "react";

const SvgLinkTilted = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.902 13.326a3.375 3.375 0 1 0 4.773 4.773l1.878-1.878a1.125 1.125 0 0 1 1.59 1.59l-1.877 1.878a5.625 5.625 0 0 1-7.955-7.954l1.836-1.837a1.125 1.125 0 1 1 1.591 1.591l-1.836 1.837ZM9.899 7.738a1.125 1.125 0 0 1 0-1.591l1.836-1.837a5.625 5.625 0 0 1 7.955 7.955l-1.878 1.878a1.125 1.125 0 1 1-1.59-1.591l1.877-1.878A3.375 3.375 0 0 0 13.326 5.9L11.49 7.738c-.44.439-1.152.439-1.591 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.324 15.677a1.125 1.125 0 0 1 0-1.591l5.829-5.83a1.125 1.125 0 0 1 1.59 1.592l-5.828 5.829c-.44.439-1.152.439-1.591 0Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLinkTilted);
export default ForwardRef;
