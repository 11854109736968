import { useLockSubscriptions } from "./LockApi";
import { LockTrackerProvider, useLockedResources } from "./LockTracker";
import { LockUpdatingProvider } from "./LockUpdatingProvider";

const LockPoller = () => {
  const resources = useLockedResources();
  useLockSubscriptions({ ids: resources });
  return null;
};

export const LockProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <LockTrackerProvider>
    <LockUpdatingProvider>
      <LockPoller />
      {children}
    </LockUpdatingProvider>
  </LockTrackerProvider>
);
