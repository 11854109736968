import moment from "moment-timezone";

export const dateToParts = (value) => {
  if (!value) return null;
  const date = moment.tz(value, "Europe/Paris");
  return {
    date: date.format("YYYY-MM-DD"),
    time: date.format("HH:mm:ss"),
  };
};

export const partsToDate = (dateParts) => {
  if (!dateParts) return null;
  if (!dateParts.time || !dateParts.date) return null;
  const [hours, minutes] = dateParts.time.split(":");
  return moment
    .tz(dateParts.date, "Europe/Paris")
    .set("hour", hours)
    .set("minute", minutes)
    .toISOString();
};
