import { gql } from "@apollo/client";
import { memo } from "react";
import { cn } from "swash/utils/classNames";

export type ArticleReferenceLabelProps = {
  article: {
    isReference: boolean;
  };
} & { className?: string };

type SpreadArticleReferenceLabelProps =
  ArticleReferenceLabelProps["article"] & { className?: string };

export const SpreadArticleReferenceLabel =
  memo<SpreadArticleReferenceLabelProps>(({ isReference, className }) => {
    if (!isReference) return null;
    return (
      <span className={cn(className, "select-none font-accent uppercase")}>
        Référence
      </span>
    );
  });

export const ArticleReferenceLabel = ({
  article,
  ...props
}: ArticleReferenceLabelProps) => {
  return (
    <SpreadArticleReferenceLabel isReference={article.isReference} {...props} />
  );
};

ArticleReferenceLabel.fragments = {
  article: gql`
    fragment ArticleReferenceLabel_article on Article {
      isReference
    }
  `,
};
