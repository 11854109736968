export const INLINE_STYLE = "ITALIC";

export const allowedInlineStyles = [INLINE_STYLE];
export const allowedAttributes = { em: [], i: [] };

/** @type {import('../types').htmlToStyle} */
export const htmlToStyle = ({ element, getStyle }) => {
  const { tagName } = element;
  const checkHasItalicStyle = () => {
    const styles = getStyle();
    const fontStyle = styles["font-style"];
    return fontStyle === "italic";
  };
  if (tagName === "em" || tagName === "i" || checkHasItalicStyle()) {
    return [INLINE_STYLE];
  }
  return [];
};
