import { memo } from "react";

import { BooleanFilterField } from "@/containers/search/filterFields/BooleanFilterField";

const enumValues = [
  { value: true, label: "Autorisées sur le numérique" },
  { value: false, label: "Non-autorisées sur le numérique" },
];

const name = "webAuthorized";

const Filter = memo(() => {
  return (
    <BooleanFilterField
      name={name}
      label="Autorisation numérique"
      enum={enumValues}
    />
  );
});

export const WebAuthorizedFilter = {
  name,
  element: <Filter />,
  initialValue: true,
};
