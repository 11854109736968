// @ts-check

const SESSION_STORAGE_KEY = "APP_UUID";

/**
 * Get App UUID.
 * It is unique for a session (a navigator window or tab).
 * @returns {string}
 */
function getAppUuid() {
  const existing = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (existing) return existing;
  const id = Math.random().toString(36).replace(".", "");
  window.sessionStorage.setItem(SESSION_STORAGE_KEY, id);
  return id;
}

export const APP_UUID = getAppUuid();
