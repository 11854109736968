import { forwardRef } from "react";

const SvgSiriusLogo = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 305 305"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle fill="#314559" cx={152.5} cy={152.5} r={152.5} />
      <path
        d="m183.101 152.528-30.103-57.771.011-.021-.01.003L130 50.605l.644-.287c6.68-2.916 14.049-4.318 22.351-4.318 8.386 0 14.97 1.134 21.778 4.061l.62.271.602.273v.017c20.978 9.447 30.803 27.398 30.998 44.38l.002.514a45.794 45.794 0 0 1-4.1 18.952l-.287.624-19.507 37.436Z"
        fill="#FFF"
        opacity={0.399}
      />
      <path
        d="m122.894 152.5 30.103 57.771-.011.021.01-.003 23 44.134-.645.287c-6.68 2.916-14.048 4.318-22.351 4.318-8.386 0-14.97-1.134-21.777-4.061l-.62-.271-.603-.273v-.017c-20.978-9.447-30.803-27.398-30.997-44.38L99 209.51a45.794 45.794 0 0 1 4.1-18.951l.288-.624 19.506-37.436Z"
        fill="#FFF"
        opacity={0.403}
      />
      <path
        d="M153 46c8.303 0 15.67 1.402 22.351 4.318l.644.287c6.295 2.835 11.958 6.68 15.913 10.626-25.075 12.493-28.01 42.92-8.808 91.28L130 50.605l.603-.273C137.633 47.204 144.36 46 153 46ZM152.908 259.011c-8.303 0-15.67-1.402-22.351-4.318l-.644-.287c-6.295-2.835-11.958-6.68-15.913-10.626 25.075-12.493 28.01-42.92 8.808-91.28l53.1 101.906-.603.273c-7.03 3.128-13.757 4.332-22.397 4.332Z"
        fill="#FFF"
        opacity={0.7}
      />
      <path
        d="m130 50.605 72.608 139.342a45.82 45.82 0 0 1 4.387 19.576c0 17.132-9.81 35.352-31 44.894l-72.607-139.342A45.82 45.82 0 0 1 99 95.5c0-17.133 9.81-35.353 31-44.895Z"
        fill="#FFF"
      />
      <path
        d="M153.002 46c8.304 0 15.673 1.402 22.354 4.319l.644.286c-9.843 8.628-19.557 17.615-23.002 44.139L130 50.605l.603-.272c7.03-3.129 13.759-4.333 22.4-4.333ZM152.998 259.044c-8.304 0-15.673-1.402-22.354-4.319l-.644-.287 1.18-1.038 1.177-1.048.878-.797.875-.808.58-.548.577-.555.287-.28.573-.568.57-.577.565-.587.562-.599.28-.303.554-.617.55-.63c6.21-7.22 11.451-17.147 13.794-35.183L176 254.438l-.603.273c-7.03 3.128-13.759 4.333-22.4 4.333Z"
        fill="#FFF"
        opacity={0.7}
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgSiriusLogo);
export default ForwardRef;
