import { gql } from "@apollo/client";
import { memo } from "react";
import { Button, ButtonProps } from "swash/Button";
import { Tooltip } from "swash/Tooltip";
import { BubbleExclamation, BubblePlusOutline } from "swash/icons";

import { ArticleCommentsPanel } from "@/containers/article/panels/comments";

export type SpreadArticleNotesButtonProps = {
  articleId: number;
  hasNotes: boolean;
  scale?: ButtonProps["scale"];
  className?: string;
  /**
   * Show even if there are no notes.
   * @default true
   */
  alwaysVisible?: boolean;
};

export const SpreadArticleNotesButton = memo<SpreadArticleNotesButtonProps>(
  ({ articleId, hasNotes, scale, alwaysVisible = true, className }) => {
    const activatePanel = ArticleCommentsPanel.useActivate();
    const checkIsPanelActivated = ArticleCommentsPanel.useCheckIsActivated();
    const active = checkIsPanelActivated({ articleId: articleId });
    const title = hasNotes ? "Voir les consignes" : "Ajouter une consigne";

    if (!hasNotes && !alwaysVisible) {
      return null;
    }

    if (!activatePanel) {
      return (
        <Tooltip tooltip={title}>
          <div className="flex">
            {hasNotes ? <BubbleExclamation /> : <BubblePlusOutline />}
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip={title}>
        <Button
          aria-label={title}
          aria-pressed={active}
          appearance="text"
          variant="secondary"
          scale={scale ?? "md"}
          iconOnly
          onClick={(event) => {
            event.stopPropagation();
            activatePanel({ articleId: articleId });
          }}
          className={className}
        >
          {hasNotes ? <BubbleExclamation /> : <BubblePlusOutline />}
        </Button>
      </Tooltip>
    );
  },
);

export type ArticleNotesButtonProps = {
  article: {
    id: number;
    hasNotes: boolean;
  };
  scale?: SpreadArticleNotesButtonProps["scale"];
  className?: string;
  /**
   * Show even if there are no notes.
   * @default true
   */
  alwaysVisible?: SpreadArticleNotesButtonProps["alwaysVisible"];
};

export const ArticleNotesButton = (props: ArticleNotesButtonProps) => {
  return (
    <SpreadArticleNotesButton
      articleId={props.article.id}
      hasNotes={props.article.hasNotes}
      scale={props.scale}
      alwaysVisible={props.alwaysVisible}
      className={props.className}
    />
  );
};

ArticleNotesButton.fragments = {
  article: gql`
    fragment ArticleNotesButton_article on Article {
      id
      hasNotes
    }
  `,
};
