// @ts-check

/**
 * @typedef Context
 * @property {string} text
 * @property {number} position
 * @property {string} input
 */

/**
 * @typedef Insertion
 * @property {"insert"} type
 * @property {string} value
 */

/**
 * @typedef Deletion
 * @property {"delete"} type
 * @property {number} length
 */

/**
 * @typedef {Insertion | Deletion} Transformation
 */

/**
 * @typedef Rule
 * @property {string[]} chars
 * @property {(ctx: Context) => Transformation[] | null} transform
 */

export { preset as frenchPreset } from "./presets/french.js";

/**
 * Get all chars from rules.
 * @param {Rule[]} rules
 */
export const getChars = (rules) => {
  return rules.flatMap((rule) => rule.chars);
};

/**
 * Get text from context and transformations.
 * @param {Transformation[] | null} transformations
 * @param {Context} ctx
 * @returns {[string, number]}
 */
export const apply = (transformations, ctx) => {
  if (!transformations) return [ctx.text, ctx.position];
  let text = ctx.text;
  let position = ctx.position;
  for (const transformation of transformations) {
    switch (transformation.type) {
      case "insert":
        text =
          text.slice(0, position) + transformation.value + text.slice(position);
        position += transformation.value.length;
        break;
      case "delete":
        text = text.slice(0, position - transformation.length);
        position -= transformation.length;
        break;
    }
  }
  return [text, position];
};

/**
 * @param {Rule[]} rules
 * @param {Context} ctx
 */
export const transform = (rules, ctx) => {
  for (const rule of rules) {
    const transformation = rule.transform(ctx);
    if (transformation) {
      return transformation;
    }
  }
  return null;
};
