// @ts-check
import {
  elementHasStyle,
  isHastElement,
  isHastParent,
  isHastText,
} from "../hast-util";

/**
 * @typedef {'ordered' | 'unordered'} ListType
 */

const regExpOrderedList = /^\w+.$/;

const nodeListTypeMap = new WeakMap();
/**
 * @param {import('hast').Node} node
 * @param {ListType} listType
 */
const setNodeListType = (node, listType) => {
  nodeListTypeMap.set(node, listType);
};
/**
 * @param {import('hast').Node} node
 * @returns {ListType | undefined}
 */
const getNodeListType = (node) => nodeListTypeMap.get(node);

/**
 * @param {import('hast').Node} node
 * @param {import('hast').Parent | null} parent
 * @returns {boolean}
 */
function toList(node, parent) {
  let typeFound = false;
  /**
   * @param {import('hast').Node} node
   * @returns {null | ListType}
   */
  const findListType = (node) => {
    if (typeFound) {
      return null;
    }

    if (isHastParent(node) && node.children[0]) {
      return findListType(node.children[0]);
    }

    if (isHastText(node)) {
      typeFound = true;
      return /** @type {ListType} */ (
        regExpOrderedList.test(node.value) ? "ol" : "ul"
      );
    }

    return null;
  };

  if (
    parent &&
    isHastElement(node) &&
    node.tagName === "p" &&
    elementHasStyle(node, "mso-list")
  ) {
    const type = findListType(node);
    if (type) {
      setNodeListType(node, type);
      node.tagName = "li";
      // Remove word raw bullet/index
      node.children.shift();
      return true;
    }
  }

  return false;
}

/**
 * @param {import('hast').Root} root
 * @returns {import('hast').Root}
 */
const organizeList = (root) => {
  /** @type {import('hast').Element | null} */
  let listNode = null;

  const siriusRoot = root.children[0];

  if (!siriusRoot || !isHastParent(siriusRoot)) return root;

  siriusRoot.children = siriusRoot.children.reduce((children, node) => {
    if (isHastText(node) && node.value === "\n\n") return children;

    const listType = getNodeListType(node);

    if (listType) {
      if (!listNode || listType !== listNode.tagName) {
        listNode = {
          type: "element",
          tagName: listType,
          children: [node],
          properties: {},
        };
        children.push(listNode);
        return children;
      }

      listNode.children.push(node);
      return children;
    }

    if (isHastElement(node) && listNode) {
      children.push(node);
      listNode = null;
      return children;
    }

    children.push(node);
    return children;
  }, /** @type {import('hast').ElementContent[]} */ ([]));

  return root;
};

/**
 * @param {import('hast').Root} root
 * @param {(node: import('hast').Node, parent: import('hast').Parent | null) => boolean} callback
 * @returns
 */
function transform(root, callback) {
  let changeOccurred = false;
  /**
   * @param {import('hast').Node} node
   * @param {import('hast').Parent | null} parent
   */
  const _transform = (node, parent) => {
    const res = callback(node, parent);
    if (res) changeOccurred = true;

    if (isHastParent(node)) {
      for (let j = 0; j < node.children.length; j++) {
        const child = node.children[j];
        if (child) {
          _transform(child, node);
        }
      }
    }
  };

  _transform(root, null);
  return changeOccurred;
}

/**
 * @param {import('hast').Root} root
 * @returns {import('hast').Root}
 */
export const parseWordList = (root) => {
  const changeOccurred = transform(root, toList);
  return changeOccurred ? organizeList(root) : root;
};
