import { gql } from "@apollo/client";
import { IoCheckmark } from "swash/Icon";

import { useResourceUsedIn } from "@/containers/admin/CRUD/usages/articles";
import type { CellType } from "@/containers/list/ListState";

type Media = {
  id: number;
  featured: boolean;
  media: { id: number };
};

type ArticleFeaturedMediaCellProps = {
  article: {
    id: number;
    medias: {
      nodes: Media[];
    };
  };
};

const ArticleFeaturedMediaCell = (props: ArticleFeaturedMediaCellProps) => {
  const resource = useResourceUsedIn();
  const featured = props.article.medias?.nodes.some(
    (node) => node.media.id === resource.id && node.featured,
  );

  return (
    <div className="flex justify-center text-secondary-on">
      {featured && <IoCheckmark />}
    </div>
  );
};

ArticleFeaturedMediaCell.fragments = {
  article: gql`
    fragment ArticleFeaturedMediaCell_article on Article {
      id
      medias(where: { type: { eq: images } }) {
        nodes {
          id
          type
          featured
          media {
            id
          }
        }
      }
    }
  `,
};

export type FeaturedMediaCellArticle = ArticleFeaturedMediaCellProps["article"];

export const featuredMediaCell: CellType<FeaturedMediaCellArticle> = {
  key: "featured-media",
  title: "Média d’appel",
  width: 72,
  render: ({ node }) => (
    <ArticleFeaturedMediaCell key={node.id} article={node} />
  ),
  fragment: gql`
    fragment featuredMediaCell_article on Article {
      ...ArticleFeaturedMediaCell_article
    }

    ${ArticleFeaturedMediaCell.fragments.article}
  `,
};
