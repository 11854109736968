import { forwardRef } from "react";
import { IoCheckmarkCircle } from "swash/Icon";
import { cn } from "swash/utils/classNames";

import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";

function parseCode(v: string | null) {
  if (!v) return null;
  return v
    .split("")
    .filter((char) => /[0-9]/.test(char))
    .join("");
}

type TwoFactorFieldProps = {
  name: string;
  valid?: boolean;
  disabled?: boolean;
};

export const TwoFactorField = forwardRef<HTMLInputElement, TwoFactorFieldProps>(
  ({ name, valid = false, disabled = false }, ref) => {
    const field = useTextInputField(name, {
      validate: (v: string | null) =>
        v && v.length === 6 ? undefined : "requis",
      parse: parseCode,
    });
    const code = field.state.field.input.value as string;

    return (
      <div className="relative mx-12 flex w-min flex-row gap-2">
        {code
          .padEnd(6, " ")
          .split("")
          .map((char, index) => (
            <div
              key={index}
              className={cn(
                "h-12 w-8 border-b-[6px] border-blue-100 transition-colors",
                "text-center align-middle font-mono text-3xl font-semibold",
                char.trim() && "border-blue-300",
                valid && "border-success-bg text-success-bg-strong",
              )}
            >
              {char}
            </div>
          ))}
        <TextInputField
          as="input"
          ref={ref}
          maxLength={6}
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          disabled={disabled}
          autoFocus
          className="absolute w-full pb-3 font-mono text-3xl font-semibold tracking-[22px] text-transparent caret-black"
          {...field}
        />
        {valid && (
          <IoCheckmarkCircle className="absolute -right-8 top-2 text-lg text-success-bg-strong" />
        )}
      </div>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  TwoFactorField.displayName = "TwoFactorField";
}
