import { useWatchApi } from "@/containers/watch/WatchApi";
import { useWatchStatus } from "@/containers/watch/WatchStatus";

/**
 * @typedef WatchState
 * @property {import('./WatchApi').WatchApi} api
 * @property {import('./WatchStatus').WatchStatus} status
 */

/**
 * Create a watch state that must be spread into WatchButton
 * @param {string} id
 * @returns {WatchState}
 */
export function useWatchState(id) {
  const api = useWatchApi(id);
  const status = useWatchStatus(api);
  return { api, status };
}
