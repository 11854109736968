import { memo } from "react";

import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";

const name = "expired";

const Filter = memo(() => {
  const field = useCheckboxField(name, {
    parse: (v) => (v === true ? false : null),
    format: (v) => v === false,
  });
  return (
    <div className="mx-2">
      <CheckboxField {...field}>Masquer les images expirées</CheckboxField>
    </div>
  );
});

export const ExpiredFilter = {
  name,
  element: <Filter />,
  initialValue: false,
};
