import { gql } from "@apollo/client";

import { CustomTypeContentNode } from "@/containers/editor/nodes/CustomTypeContentNode";
import { fetchMedia } from "@/services/medias/fetchMedia";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "custom-types-content";
export const ATOMIC_ENTITY_TYPE = "CUSTOM_TYPES_CONTENT";

const CustomTypesContentUrlQuery = gql`
  query RichEditorCustomTypesContentPluginUrl_customTypesContent(
    $id: Int!
    $type: String!
  ) {
    media(id: $id, type: $type) {
      id
    }
  }
`;

const CustomTypesContentQuery = gql`
  query RichEditorArticlePlugin_custom_types_content($id: Int!) {
    node: customTypeContent(id: $id) {
      id
      ...CustomTypeContentNode_customTypeContent
    }
  }

  ${CustomTypeContentNode.fragments.content}
`;

function CustomTypeContentBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: CustomTypesContentQuery,
  });

  return (
    <AtomicBlock {...atomic}>
      {({ node }) => (
        <CustomTypeContentNode
          content={node}
          editing={props.editing}
          setEditing={props.setEditing}
        />
      )}
    </AtomicBlock>
  );
}

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: CustomTypeContentBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return options;
}

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

export const createBlockFromUrls = async (state, urls) => {
  const data = getDataFromUrls(state, urls);
  if (!data) return null;

  const customTypesContent = await fetchMedia({
    id: data.id,
    type: "custom_types_contents",
    query: CustomTypesContentUrlQuery,
  });

  if (!customTypesContent) return null;

  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: data.id,
        media: {
          id: data.id,
        },
      },
    },
  };
};
