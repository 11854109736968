import clsx from "clsx";
import * as React from "react";
import { forwardRef, memo } from "react";

import { useDelayedVisible } from "./utils/useDelayedVisible";

export interface SkeletonProps extends React.ComponentProps<"div"> {
  /**
   * Delay the loader from showing up until the delay has passed.
   * @default 300
   */
  delay?: number;
}

export const Skeleton = memo(
  forwardRef<HTMLDivElement, SkeletonProps>(
    ({ delay = 300, className, ...props }, ref) => {
      const visible = useDelayedVisible(delay);

      return (
        <div
          ref={ref}
          role="status"
          aria-busy="true"
          className={clsx(
            className,
            "animate-[1.5s_shine_linear_infinite] bg-grey-bg-light bg-[linear-gradient(102deg,#ececec_8%,#f5f5f5_18%,#ececec_33%)] bg-[size:200%_100%]",
            !visible && "invisible",
          )}
          {...props}
        />
      );
    },
  ),
);

if (process.env["NODE_ENV"] !== "production") {
  Skeleton.displayName = "Skeleton";
}
