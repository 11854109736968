import styled from "@xstyled/styled-components";
import { AtomicBlockUtils } from "draft-js-es";

import { matchAtomicBlock } from "@/components/rich-editor/utils/AtomicUtils";

import { ATOMIC_ENTITY_TYPE as SEPARATOR_ENTITY_TYPE } from "./separator-convert";

export * from "./separator-convert";

export const name = "separator";
export const command = "separator";

export const handleKeyCommand = ({ setEditorState }, cmd) => {
  if (cmd === command) {
    setEditorState((editorState) => {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        SEPARATOR_ENTITY_TYPE,
        "IMMUTABLE",
        {},
      );
      return AtomicBlockUtils.insertAtomicBlock(
        editorState,
        contentStateWithEntity.getLastCreatedEntityKey(),
        " ",
      );
    });
    return "handled";
  }
  return undefined;
};

const SeparatorContainer = styled.div`
  [data-change="deleted"] & hr {
    border-color: change-deleted;
  }
  [data-change="added"] & hr {
    border-color: change-added;
  }
`;

const SeparatorBlock = () => {
  return (
    <SeparatorContainer>
      <hr />
    </SeparatorContainer>
  );
};

export const matchBlock = matchAtomicBlock(SEPARATOR_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: SeparatorBlock, editable: false };
};
