import { autocompletion } from "@codemirror/autocomplete";
import { indentWithTab } from "@codemirror/commands";
import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import { EditorState } from "@codemirror/state";
import { oneDarkTheme } from "@codemirror/theme-one-dark";
import { keymap } from "@codemirror/view";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { graphql } from "cm6-graphql";
import { EditorView, basicSetup } from "codemirror";
import { useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import "./codemirror.css";

const useCodeMirror = (
  value,
  extensions,
  { readOnly = false, tabSize = 2, onChange, onFocus, onBlur, onKeyDown },
) => {
  const ref = useRef();
  const editorRef = useRef();

  const defaultValueRef = useRef(value);
  const extensionsRef = useRef(extensions);
  const tabSizeRef = useRef(tabSize);
  const refs = useLiveRef({
    onChange,
    onBlur,
    onFocus,
    readOnly,
    onKeyDown,
  });

  useEffect(() => {
    const { onChange, onBlur, onFocus, onKeyDown, readOnly } = refs.current;
    if (!editorRef.current) {
      const themes = [oneDarkTheme, dracula];
      const settings = [
        EditorState.readOnly.of(readOnly),
        EditorState.tabSize.of(tabSizeRef.current),
        EditorView.lineWrapping,
      ];
      const events = [
        EditorView.domEventHandlers({
          focus: onFocus,
          blur: onBlur,
          keydown: onKeyDown,
        }),
        EditorView.updateListener.of((editor) => {
          onChange(editor.state.doc.toString());
        }),
      ];
      editorRef.current = new EditorView({
        doc: defaultValueRef.current,
        extensions: [
          basicSetup,
          autocompletion({
            activateOnTyping: false,
          }),
          keymap.of([indentWithTab]),
          ...settings,
          ...themes,
          ...events,
          ...extensionsRef.current,
        ],
        parent: ref.current,
      });
    }

    return () => editorRef.current?.destroy();
  }, [refs]);

  return { ref, editorRef };
};

export function Editor({
  value,
  extensions = [],
  readOnly = false,
  tabSize = 2,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  smartIndent,
}) {
  const { ref } = useCodeMirror(value, extensions, {
    readOnly,
    tabSize,
    smartIndent,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  });
  return (
    <div
      ref={ref}
      data-testid="code-mirror-editor"
      className="absolute inset-0"
    />
  );
}

export function JavaScriptEditor(props) {
  return <Editor {...props} extensions={[javascript()]} />;
}

export function HtmlEditor(props) {
  return <Editor {...props} extensions={[html()]} />;
}

export function HandlebarsEditor(props) {
  return <Editor {...props} extensions={[html()]} />;
}

export function GraphQLEditor({ schema, ...props }) {
  return <Editor {...props} extensions={[graphql(schema)]} />;
}
