import {
  SingleDatePickerInput,
  checkIsValidDateValue,
} from "swash/controls/DatePickerInput";

import { FieldControl } from "@/components/fields/FieldControl";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useTextInputField } from "@/components/fields/TextInputField";

const validateDate = (value) => {
  if (value && !checkIsValidDateValue(value)) return "Date invalide";
};

export function DateField({
  name,
  label,
  hint,
  validate,
  modifiers,
  disabled,
  placeholder,
  modal,
  "data-test-hidden": dataTestHidden,
  w,
  ...options
}) {
  const field = useTextInputField(name, {
    ...options,
    validate: validateDate,
  });

  return (
    <FieldGroup {...field} data-test-hidden={dataTestHidden}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FieldControl
        as={SingleDatePickerInput}
        modifiers={modifiers}
        disabled={disabled}
        placeholder={placeholder}
        modal={modal}
        w={w}
        {...field}
      />
    </FieldGroup>
  );
}
