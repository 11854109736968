import { x } from "@xstyled/styled-components";
import { useField } from "react-final-form";
import { Indice0, Indice1, Indice2, Indice3 } from "swash/Icon";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

export const editorialLevels = [
  {
    label: "Indice 0",
    value: 0,
  },
  {
    label: "Indice 1",
    value: 1,
  },
  {
    label: "Indice 2",
    value: 2,
  },
  {
    label: "Indice 3",
    value: 3,
  },
];

export function EditorialLevelIcon({ editorialLevel, fontSize = "20px" }) {
  switch (editorialLevel.value) {
    case 3:
      return (
        <x.div
          as={Indice3}
          aria-label={editorialLevel.label}
          color="blue-darkest"
          fontSize={fontSize}
        />
      );
    case 2:
      return (
        <x.div
          as={Indice2}
          aria-label={editorialLevel.label}
          color="blue-darker"
          fontSize={fontSize}
        />
      );
    case 1:
      return (
        <x.div
          as={Indice1}
          aria-label={editorialLevel.label}
          color="blue-light"
          fontSize={fontSize}
        />
      );
    case 0:
      return (
        <x.div
          as={Indice0}
          aria-label={editorialLevel.label}
          color="grey"
          fontSize={fontSize}
        />
      );
    default:
      return null;
  }
}

export const EditorialLevelField = ({
  name = "editorialLevel",
  disabled = false,
  multi = true,
  clearable = true,
  ...others
}) => {
  const field = useField(name, {
    ...others,
  });

  const emptyValue = multi ? [] : null;

  const select = useEnumSelectState({
    title: "Indice",
    value: field.input.value || emptyValue,
    onChange: field.input.onChange,
    required: !clearable,
    items: editorialLevels,
    labelSelector: (editorialLevel) => (
      <EditorialLevelIcon editorialLevel={editorialLevel} />
    ),
    valueSelector: (editorialLevel) => editorialLevel.value,
  });

  return (
    <div>
      <EnumSelect
        state={select}
        disabled={disabled}
        placeholder="Indice"
        aria-label="Indice"
      />
    </div>
  );
};
