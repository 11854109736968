import clsx from "clsx";
import React, { ReactElement } from "react";

export type PreviewLinkHoveringProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const PreviewLinkHovering = ({
  children,
  className,
  ...props
}: PreviewLinkHoveringProps) => {
  return (
    <div
      className={clsx(
        className,
        "flex items-center justify-center text-white opacity-0 brightness-125 transition-all",
        "absolute inset-0",
        "group-hover/preview-link:scale-125 group-hover/preview-link:opacity-100",
      )}
      {...props}
    >
      {React.cloneElement(children as ReactElement, {
        className: clsx(
          "w-1/3 h-1/3 max-w-8 max-h-8",
          (children as ReactElement).props.className,
        ),
      })}
    </div>
  );
};

export type PreviewLinkContentProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const PreviewLinkContent = ({
  children,
  className,
  ...props
}: PreviewLinkContentProps) => {
  return (
    <div
      className={clsx(
        className,
        "transition-all group-hover/preview-link:brightness-50",
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export type PreviewLinkProps = {
  children: React.ReactNode;
  className?: string;
  editable?: boolean;
} & React.HTMLAttributes<HTMLAnchorElement>;

export const PreviewLink = React.forwardRef<
  HTMLAnchorElement,
  PreviewLinkProps
>(({ children, className, editable, ...props }: PreviewLinkProps, ref) => {
  return (
    <a
      ref={ref}
      className={clsx(
        className,
        "group/preview-link relative block transition-all focus:outline-none hover:cursor-zoom-in",
      )}
      {...props}
    >
      {children}
    </a>
  );
});
