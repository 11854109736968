import { gql } from "@apollo/client";

export const SnippetFragment = gql`
  fragment SnippetEditFragment on Snippet {
    title
    code
    keywords {
      id
    }
    darkModeSupported
    forceDarkModeSupported
    obsolete
    siriusUrl
  }
`;

export const ArticleMediaFragment = gql`
  fragment ArticleSnippetEdit_articleMedia on ArticleMedia {
    createdAt
    updatedAt
    metadata
  }
`;

export function parseValues(node) {
  return {
    title: node.title,
    code: node.code,
    keywordIds: node.keywordIds,
    darkModeSupported: node.darkMode === "supported",
    obsolete: node.obsolete,
  };
}
export function formatValues(node) {
  if (!node) {
    return null;
  }
  return {
    title: node.title,
    code: node.code,
    darkMode: node.darkModeSupported,
    obsolete: node.obsolete,
    keywordIds: node.keywords.map((keyword) => keyword.id),
  };
}
