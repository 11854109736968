import { gql } from "@apollo/client";
import { useMemo } from "react";

import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";

export const AspectRatioField = ({ name, label, aspectRatios }) => {
  const currentAspectRatioId = useSubscribeFormValue("aspectRatioId");

  const filteredAspectRatios = useMemo(
    () =>
      aspectRatios.filter(
        (ratio) =>
          ratio.enabled ||
          (!ratio.enabled && ratio.id === currentAspectRatioId),
      ),
    [aspectRatios, currentAspectRatioId],
  );

  const enumValues = useNodesToEnumArray(filteredAspectRatios);
  return (
    <EnumField
      required
      name={name}
      label={label}
      enum={enumValues}
      parse={(v) => v?.value && Number(v.value)}
    />
  );
};

AspectRatioField.fragments = {
  aspectRatios: gql`
    fragment AspectRatioField_aspectRatios on AspectRatio {
      id
      label
      enabled
    }
  `,
};
