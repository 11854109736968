import { PUBLISH_MESSAGES } from "@/config/constants";

export const FRIDGE_MESSAGES = {
  DELETE_FRIDGE_FOLDER: {
    title: (name: string) => `Suppression du dossier ${name}`,
    message: (length: number) =>
      `Attention, vous êtes sur le point de supprimer un dossier qui contient ${length} article${
        length > 1 ? "s" : ""
      }. Voulez-vous confirmer cette action ?`,
  },
};

export const LMM = {
  invalidUrl: "L’URL renseignée n’est pas valide",
};

export const PUBLICATION = {
  [PUBLISH_MESSAGES.SUCCESS]: {
    "staging-area": (name: string) => `La zone «${name}» a bien été publiée.`,
    article: () => `L’article a bien été publié.`,
    "tag-layout": () => `La maquette a bien été modifiée.`,
  },
  [PUBLISH_MESSAGES.FAILED]: {
    "staging-area": (name: string) => `La zone «${name}» n’a pas été publiée.`,
    article: () => `L’article n’a pas été publié.`,
    "tag-layout": () => `La maquette n’a pas été publiée.`,
  },
};

export const STAGING_AREAS = {
  DEACTIVATE: {
    title: "Désactivation d'une zone",
    message: (name: string) =>
      `Êtes-vous sûr de vouloir désactiver la zone "${name}" ?`,
  },
  ABSCENCE_FIELDS: {
    title: "Activation d'une zone",
    message:
      "Attention, vous devez définir un type et un titre pour activer la zone",
  },
};

export const ERRORS = {
  default:
    "Oups, une erreur est survenue, veuillez réessayer ou contacter le support.",
  tags: {
    urlAlreadyExists: "Cette url est déjà associée à un tag",
    recursive: ({ path }: { path: string }) =>
      `Impossible de sélectionner cette rubrique car il existe déjà un lien de parenté. (${path})`,
  },
  campaigns: {
    urlAlreadyExists: "Cette url est déjà associée à une campagne",
  },
  blogs: {
    urlAlreadyExists: "Cette url est déjà associée à un blog",
  },
  lmm: {
    titleRequired:
      "La saisie du titre de promotion est obligatoire pour envoyer la matinale",
  },
  newsletters: {
    titleRequired:
      "La saisie de l'objet du message est obligatoire pour envoyer la newsletter",
    descriptionRequired:
      "La saisie du sommaire de l'édition est obligatoire pour envoyer la newsletter",
  },
  images: {
    used: `L’image est encore utilisée`,
  },
  imageImport: {
    unallowedJpegColorspace: `Nous n'acceptons pas les images avec un espace colorimétrique "CMJN" mais uniquement "RVB"`,
    invalidFormat:
      "Format de fichier invalide, seules les images au format JPEG et PNG sont acceptées",
    invalidImage: "Le fichier n’est pas une image valide.",
    missingIptcFields:
      "Erreur lors du téléchargement de l’image. Au moins une de ces métadonnées doit être remplie pour télécharger cette image: IPTC:1:100 (unique_object_name) ou IPTC:2:200 (custom_field_1)",
  },
  imageAgencies: {
    nameAlreadyExists: "Cette clef existe déjà",
  },
  previews: {
    nameAlreadyExists: "Ce nom de prévisualisation existe déjà",
  },
  eventDispatchers: {
    keyAlreadyExists: "Ce type d'événement manuel existe déjà",
  },
  upload: {
    unallowedMimeType: (param: any) => {
      const type = typeof param === "object" ? param.type : param;
      return `Ce type de fichier (${
        type || "type inconnu"
      }) n'est pas supporté`;
    },
    sizeTooLarge: "la taille du fichier est trop grande",
    bucketStorage:
      "Le fichier n’a pas pu être importé, veuillez contacter le support technique",
  },
  archives: {
    invalidFileName: "L'archive contient un fichier dont le nom est invalide",
  },
  download: {
    bucketStorage:
      "Le fichier ne peut être téléchargé, veuillez contacter le support technique",
  },
  roles: {
    nameAlreadyExists: "Le nom existe déjà",
    shortNameAlreadyExists: "Le nom court existe déjà",
  },
  sections: {
    nameAlreadyExists: "Le nom existe déjà",
    shortNameAlreadyExists: "Le nom court existe déjà",
  },
  sources: {
    nameAlreadyExists:
      'Le contenu du champ "Clef" est déjà utilisé dans une autre source.',
  },
  authors: {
    shortNameAlreadyExists: "Le nom court existe déjà",
  },
  editorialTypes: {
    nameAlreadyExists: { name: "Le slug existe déjà" },
  },
  layouts: {
    nameAlreadyExists: { name: "Ce type de gabarit est déjà utilisé" },
  },
  pricingTiers: {
    onlyOneAllowed:
      "Il n’est actuellement pas possible de créer plusieurs droits d’accès.",
    valueAlreadyExists: { value: "La valeur existe déjà." },
  },
  exposures: {
    nameAlreadyExists: { name: "Le slug existe déjà" },
    iconOrAcronymRequired: { icon: "L'icône ou l'acronyme est requis" },
    typeAlreadyExists: "Une exposition identique existe déjà",
  },
  lexemes: {
    valueAlreadyExists: {
      label: "Ce lexème existe déjà dans le dictionnaire de prononciation",
    },
  },
  liveTags: {
    labelAlreadyExists: { label: "Le tag existe déjà" },
    maximumLiveTagsReached:
      "Le nombre maximum de tags pour ce live a été atteint",
  },
  flags: {
    labelAlreadyExists: { label: "Il existe déjà un drapeau avec ce libellé" },
    slugAlreadyExists: { slug: "Il existe déjà un drapeau avec cette clef" },
  },
  users: {
    emailAlreadyExists:
      "Adresse email déjà utilisée dans un autre compte utilisateur",
    contractEnded: "La date de fin de contrat est dépassée",
  },
  integrations: {
    notHealthy:
      "La tentative de validation de l’intégration a échoué. Les paramètres sont probablement erronés, veillez à bien vérifier que tous les paramètres sont valides.",
  },
  forms: {
    validators: {
      mustBeFilled: "Ne peut pas être vide",
      mustBeEmail: "Email invalide",
      mustBePhoneNumber: "Numéro invalide",
      mustBeURL: "URL invalide",
      mustNotBeSiriusURL: "Veuillez saisir l’URL publique de l’article",
      mustNotBePreviewURL: "Veuillez saisir l’URL publique de l’article",
      mustHaveMinLength: "Doit comprendre au moins {0} caractères.",
      mustHaveMaxLength: "Doit comprendre au maximum {0} caractères.",
      mustBeFullTime: "Temps invalide",
      mustBeInFuture: "Date dans le passé",
      mustBeHex: "Couleur invalide",
    },
  },
  customFields: {
    typeNotSupported: `Pour l'instant seuls les champs personnalisés de type "Texte" sont supportés pour les "Publication périodiques" non numériques`,
    valueAlreadyExists: (resource: string) =>
      `Cette valeur existe déjà pour ce champ sur la resource "${resource}"`,
  },
  keywords: {
    labelAlreadyExists: { label: "Ce tag existe déjà" },
  },
  externalVideo: {
    notFound:
      "L’URL ne correspond à aucune vidéo publique des fournisseurs supportés",
  },
  otp: {
    invalidCode: { code: "Code invalide ou expiré" },
  },
  totp: {
    invalidCode: { code: "Code invalide ou expiré" },
  },
  api: {
    rateLimitReached: "Trop de tentatives. Veuillez réessayer plus tard",
  },
  xoembed: {
    providerNotFound: "Service de recherche de vidéos non configuré",
  },
};

export const lockMessage = ({ firstName = "", lastName = "" } = {}) => ({
  onLockRequestReceived: () =>
    `${firstName[0]}.${lastName} demande le verrou sur`,
});
