import { toFrench } from "@lemonde/nlcst-util-to-french";
import { toString } from "nlcst-to-string";
import retextLatin from "retext-latin";
import { unified } from "unified";

/**
 * Apply french rules to a text.
 * @param {string} text
 * @returns {string}
 */
export const frenchifyText = (text) => {
  const tree = unified().use(retextLatin).parse(text);
  return toString(toFrench(tree));
};
