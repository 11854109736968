import * as React from "react";
import { forwardRef } from "react";

const SvgBagDoubleOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.864 9.969a.573.573 0 0 0-.572.573l-.834 7.734c0 1.083.923 2.005 2.005 2.005h9.74c1.083 0 2.005-.877 2.005-1.96l-.833-7.78a.573.573 0 0 0-.573-.572H8.864Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.896 9.969V8.823a3.437 3.437 0 0 1 3.437-3.438v0a3.437 3.437 0 0 1 3.438 3.438v1.146"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.698 8.302a.573.573 0 0 0-.573.573l-.833 7.734c0 1.083.922 2.006 2.005 2.006h9.74c1.082 0 2.005-.878 2.005-1.96l-.834-7.78a.573.573 0 0 0-.573-.573H4.698Z"
      fill="#fff"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.73 8.302V7.156a3.437 3.437 0 0 1 3.437-3.437v0a3.438 3.438 0 0 1 3.437 3.437v1.146"
      stroke="currentColor"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBagDoubleOutline);
export default ForwardRef;
