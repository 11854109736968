import { createContext, useContext, useState } from "react";

const ComposeEntityKeysHoverContext = createContext();
const SetComposeEntityKeysHoverContext = createContext();

export const useHoveredComposeEntityKeys = () =>
  useContext(ComposeEntityKeysHoverContext);

export const useSetHoveredComposeEntityKeys = () =>
  useContext(SetComposeEntityKeysHoverContext);

export const RichEditorCompositeEntitiesContextProvider = ({ children }) => {
  const [hoveredComposeEntityKeys, setHoveredComposeEntityKeys] = useState();
  return (
    <SetComposeEntityKeysHoverContext.Provider
      value={setHoveredComposeEntityKeys}
    >
      <ComposeEntityKeysHoverContext.Provider value={hoveredComposeEntityKeys}>
        {children}
      </ComposeEntityKeysHoverContext.Provider>
    </SetComposeEntityKeysHoverContext.Provider>
  );
};
