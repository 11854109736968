import { matchAtomicBlock } from "../utils/AtomicUtils";

export const name = "core-pending";

export const matchBlock = matchAtomicBlock("PENDING");

function PendingBlock() {
  return <div>Chargement en cours...</div>;
}

export const blockRendererFn = () => {
  return { component: PendingBlock, editable: false };
};
