import * as React from "react";
import { forwardRef } from "react";

const SvgArrowBarDown = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.749 14.256a.888.888 0 0 1 0 1.238l-5.143 5.25a.845.845 0 0 1-1.212 0l-5.143-5.25a.888.888 0 0 1 0-1.238.845.845 0 0 1 1.212 0L12 18.888l4.537-4.632a.845.845 0 0 1 1.212 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8c.552 0 1 .348 1 .776v9.448c0 .428-.448.776-1 .776s-1-.348-1-.776V8.776c0-.428.448-.776 1-.776ZM21 4a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowBarDown);
export default ForwardRef;
