import { forwardRef } from "react";

const SvgRedo = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.122 17.254v-4.605H8.956c-1.704 0-3.285.098-4.741.293-1.457.182-2.862.52-4.215 1.015.91-1.978 2.738-3.356 5.483-4.137a14.612 14.612 0 0 1 2.634-.487 29.2 29.2 0 0 1 3.024-.137v-4.45L20 11.597z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRedo);
export default ForwardRef;
