import { gql } from "@apollo/client";

import { useHasPermission } from "@/containers/User";
import type { CellType } from "@/containers/list/ListState";

import {
  ArticlePrintStatusIcon,
  ArticlePrintStatusIconProps,
} from "../../ArticlePrintInfosIcon";

export type PrintCellArticle = ArticlePrintStatusIconProps["article"];

export const printCell: CellType<PrintCellArticle> = {
  key: "print",
  title: "Print",
  width: 28,
  render: ({ node }) => <ArticlePrintStatusIcon article={node} size={20} />,
  fragment: gql`
    fragment printCell_article on Article {
      ...ArticlePrintStatusIcon_article
    }

    ${ArticlePrintStatusIcon.fragments.article}
  `,
  useIsVisible: () => {
    const canViewExposure = useHasPermission(
      ["exposures:view", "exposures:edit", "exposures:full"],
      { method: "some" },
    );
    return !canViewExposure;
  },
};
