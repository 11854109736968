import * as React from "react";
import { forwardRef } from "react";

const SvgSerif = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M20 7.655h-.893a6.09 6.09 0 0 0-.427-1.138 9.597 9.597 0 0 0-.674-1.259 5.848 5.848 0 0 0-.854-1.07c-.302-.313-.595-.492-.88-.536a11.776 11.776 0 0 0-1.01-.08 25.724 25.724 0 0 0-1.126-.028h-.75v15.198c0 .294.06.566.18.816.121.241.333.429.635.563.155.062.479.147.97.254.501.107.89.165 1.166.174v.951H7.663v-.95c.242-.018.609-.054 1.1-.108.501-.053.846-.12 1.036-.2.285-.117.492-.282.622-.496.138-.223.207-.518.207-.884V3.544h-.751c-.268 0-.6.01-.997.027-.397.01-.776.036-1.139.08-.285.036-.582.215-.893.536-.302.313-.583.67-.842 1.071-.258.402-.487.83-.686 1.286a7.694 7.694 0 0 0-.414 1.111H4V2.5h16v5.155Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSerif);
export default ForwardRef;
