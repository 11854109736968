import { toNodes } from "@/components/rich-editor/convert/toNodes";

/**
 * @param {string} url
 * @returns {boolean}
 */
export function checkIsAnchor(url) {
  return url?.startsWith("#");
}

/**
 * @typedef Anchor
 * @property {string} id
 * @property {string} label
 */

/**
 * @param {{editorState: import('draft-js-es').EditorState}} state
 * @return {Array.<Anchor>}
 */
export const getAnchors = ({ editorState }) => {
  const contentState = editorState.getCurrentContent();
  const nodes = toNodes(contentState);
  const testRegex = /"huit-anchor-.*?"/g;

  const anchorIds = nodes
    .reduce((prev, curr) => {
      return [...prev, ...(curr.text?.match(testRegex) ?? [])];
    }, [])
    .map((/** @type {string} */ text) => {
      const value = `#${text.replace(/"/g, "")}`;
      return {
        id: value,
        label: value.replace("huit-anchor-", ""),
      };
    });

  return anchorIds;
};
