import { UsedInList } from "./UsedInList";

export const name = "Signataires";

const columns = [
  {
    id: "longName",
    Header() {
      return "Signature";
    },
    Value({ node, EditLink }) {
      return <EditLink slug="authors">{node.longName}</EditLink>;
    },
  },
  {
    id: "shortName",
    Header() {
      return "Signature courte";
    },
    Value({ node }) {
      return node.shortName;
    },
  },
  {
    id: "origin",
    Header() {
      return "Origine";
    },
    Value({ node }) {
      return node.origin || "-";
    },
  },
  {
    id: "verified",
    Header() {
      return "Vérifié";
    },
    Value({ node }) {
      return node.verified ? "Oui" : "Non";
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
