import { gql, useSubscription } from "@apollo/client";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Link } from "swash/Link";
import { useLiveRef } from "swash/utils/useLiveRef";

import {
  mergeConnections,
  prependNode,
  useSafeQuery,
} from "@/containers/Apollo";

import { getAlertStatus } from "../AlertStatus";
import { ArticleAlertLightHistoryDetail } from "./ArticleAlertLightHistoryDetail";

const AlertFragment = gql`
  fragment ArticleAlertHistory_alert on Alert {
    ...getAlertStatus_alert
    ...ArticleAlertHistoryDetail_alert
  }

  ${getAlertStatus.fragments.alert}
  ${ArticleAlertLightHistoryDetail.fragments.alert}
`;

const ArticleQuery = gql`
  query ArticleAlertSection_article($articleId: Int!, $offset: Int) {
    article(id: $articleId) {
      id
      alerts(limit: 3, offset: $offset) {
        nodes {
          id
          ...ArticleAlertHistory_alert
        }
        pageInfo {
          hasMore
        }
      }
    }
  }

  ${AlertFragment}
`;

const AlertCreatedSubscription = gql`
  subscription ArticleAlertSection_alertCreated($articleId: Int!) {
    alertCreated(where: { articleId: { eq: $articleId } }) {
      id
      ...ArticleAlertHistory_alert
    }
  }

  ${AlertFragment}
`;

const AlertUpdatedSubscription = gql`
  subscription ArticleAlertSection_alertUpdated($alertIds: [Int!]!) {
    alertUpdated(where: { id: { in: $alertIds } }) {
      id
      ...ArticleAlertHistory_alert
    }
  }

  ${AlertFragment}
`;

const EventSubscription = gql`
  subscription ArticleAlertSection_eventUpdated($alertIds: [Int!]!) {
    eventUpdated(
      where: {
        name: { eq: "articleBroadcast" }
        action: { eq: "published" }
        alertId: { in: $alertIds }
      }
    ) {
      id
      ...getAlertStatus__event
    }
  }

  ${getAlertStatus.fragments._event}
`;

export const ArticleAlertHistory = memo(({ articleId, renderItem }) => {
  const { data, loading, fetchMore, subscribeToMore } = useSafeQuery(
    ArticleQuery,
    {
      variables: { articleId },
    },
  );
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: AlertCreatedSubscription,
      variables: { articleId },
      updateQuery: (previousResult, { subscriptionData: { data } }) => {
        if (!data) return previousResult;
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            alerts: {
              ...previousResult.article.alerts,
              nodes: prependNode(
                previousResult.article.alerts.nodes,
                data.alertCreated,
              ),
            },
          },
        };
      },
    });
    return () => unsubscribe();
  }, [articleId, subscribeToMore]);
  const alerts = data?.article.alerts ?? null;
  const alertIds = useMemo(
    () => alerts?.nodes.map((alert) => alert.id) ?? [],
    [alerts?.nodes],
  );
  useSubscription(AlertUpdatedSubscription, {
    variables: { alertIds },
    skip: !alertIds.length,
  });
  useEffect(() => {
    if (alertIds.length === 0) return;
    const unsubscribe = subscribeToMore({
      document: EventSubscription,
      variables: { alertIds },
      updateQuery: (previousResult, { subscriptionData: { data } }) => {
        if (!data) return previousResult;
        const concernedNode = previousResult.article.alerts.nodes.find(
          (node) => node.id === data.eventUpdated.alertId,
        );
        if (!concernedNode) return previousResult;
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            alerts: {
              ...previousResult.article.alerts,
              nodes: previousResult.article.alerts.nodes.map((node) => {
                if (node === concernedNode) {
                  return {
                    ...node,
                    prePublishedEvents: {
                      ...node.prePublishedEvents,
                      nodes: [data.eventUpdated],
                    },
                  };
                }
                return node;
              }),
            },
          },
        };
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore, alertIds]);
  const dataRef = useLiveRef(data);
  const loadMore = useCallback(() => {
    const data = dataRef.current;
    fetchMore({
      variables: { offset: data.article.alerts.nodes.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            alerts: mergeConnections(
              previousResult.article.alerts,
              fetchMoreResult.article.alerts,
            ),
          },
        };
      },
    });
  }, [dataRef, fetchMore]);
  if (!data) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2 text-xs">
      <div className="flex flex-col gap-3">
        {alerts.nodes.map((alert) => {
          const status = getAlertStatus(alert);
          return renderItem({ key: alert.id, articleId, alert, status });
        })}
      </div>
      {alerts.pageInfo.hasMore && (
        <div>
          <Link asChild>
            <button
              type="button"
              disabled={loading}
              onClick={(event) => {
                event.stopPropagation();
                loadMore();
              }}
            >
              Voir plus...
            </button>
          </Link>
        </div>
      )}
    </div>
  );
});
