import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
} from "draft-js-es";
import { List, Map, OrderedMap, OrderedSet } from "immutable-es";

import { genComposeEntityKey } from "@/components/rich-editor/utils/CompositeEntity";

/**
 * @typedef {import('draft-js').ContentState} DraftContentState
 * @typedef {import('draft-js').ContentBlock} DraftContentBlock
 * @typedef {import('draft-js').EntityInstance} EntityInstance
 * @typedef {import('draft-js').SelectionState} SelectionState
 * @typedef {import('draft-js').BlockMap} BlockMap
 * @typedef {import('draft-js').DraftEntityMutability} DraftEntityMutability
 * @typedef {import('draft-js').EditorState} DraftEditorState
 * @typedef {import('@/services/medias/fetchArticleMedia').fetchArticleMedia} FetchArticleMedia
 * @typedef {import('@/services/medias/providers').MediaProvider["type"]} MediaType
 * @typedef {{ __typename?: string, id: number, title: string | null, url?: string }} Media
 * @typedef {{ title?: string | null, prefix?: string | null, displayMode?: string | null, metadata?: {title?: string | null, prefix?: string | null, displayMode?: string | null} }} ArticleData
 * @typedef {{id: number, media: Media, type: string, __typename?: string } & ArticleData} Data
 * @typedef {{type: string, mutability: DraftEntityMutability, data: Data}} MediaEntity
 * @typedef {{id: number, media: object, metadata: Object, type: MediaType, __typename: string}} ArticleMedia
 * @typedef {{[key: string]: {type: string, mutability: DraftEntityMutability, data: object}}} CreateEntities
 * @typedef {{contentState: object[], entities: CreateEntities, editorId: string}} SiriusContent
 */

const STATIC_ENTITY_TYPES = ["COMPOSITE", "SEPARATOR"];
const ARTICLE_MEDIA_ENTITIES = [
  "IMAGE",
  "ARTICLE",
  "VIDEO",
  "SNIPPET",
  "TWEET",
  "PRODUCT",
  "PRODUCTSSUMMARY",
  "ARCHIVE",
  "CUSTOMTYPECONTENT",
];
/**
 * Format blocks with immutable collections and update entity keys
 * @param {DraftContentState} contentState
 * @param {{[key: string]: string | null}} keyStored
 * @param {string[]} allowedBlockTypes
 * @param {string[]} allowedInlineStyles
 * @param {MergeBlockData[]} mergeBlockData
 * @returns
 */
const formatBlocks = (
  contentState,
  keyStored,
  allowedBlockTypes,
  allowedInlineStyles,
  mergeBlockData,
) => {
  const blockMap = contentState.getBlockMap();
  const newBlockMap = OrderedMap(
    blockMap
      .map((block) => {
        let activeEntity = true;
        let characterList = List(
          /** @type {DraftContentBlock} */ (block)
            .getCharacterList()
            .map((char) => {
              const { entity, style } =
                /** @type {{entity: String, style: string[]}} */ (
                  /** @type {object} */ (char)
                );
              if (keyStored[entity] === null) activeEntity = false;
              return CharacterMetadata.create({
                entity: keyStored[entity] ?? null,
                style: OrderedSet(
                  style.filter((s) => allowedInlineStyles.includes(s)),
                ),
              });
            }),
        );
        const blockType = block?.getType();
        if (blockType === "atomic" && !activeEntity) return null;

        return new ContentBlock(
          /** @type {DraftContentBlock} */ (block)
            .set("characterList", characterList)
            // Ensure data is type of Map even if it's an empty object
            .set(
              "data",
              Map(
                /** @type {DraftContentBlock} */ mergeBlockData.length
                  ? mergeBlockData.reduce(
                      (acc, fn) => fn({ blockData: acc, keyStored }),
                      block.getData(),
                    )
                  : block.getData(),
              ),
            )
            .set(
              "type",
              allowedBlockTypes.includes(blockType ?? "")
                ? blockType
                : "paragraph",
            ),
        );
      })
      .filter(Boolean),
  );
  return /** @type{BlockMap} */ (/** @type {unknown} */ (newBlockMap));
};

/**
 * @param {CreateEntities} entities
 * @param {DraftContentState} contentState
 * @returns
 */
const createEntities = (entities, contentState) => {
  const keyStored = /** @type {{[key: string]: string | null}} */ ({});
  Object.entries(entities).forEach(([key, value]) => {
    if (!value) {
      keyStored[key] = null;
      return;
    }
    contentState = contentState.createEntity(
      value.type,
      value.mutability,
      value.data,
    );
    keyStored[key] = contentState.getLastCreatedEntityKey();
  });

  return keyStored;
};

/**
 * @param {MediaEntity} entity
 * @param {number} articleId
 * @param {FetchArticleMedia} fetchArticleMedia
 * @returns {Promise<ArticleMedia | null>}
 */
const fetchFromArticleMediaType = (entity, articleId, fetchArticleMedia) => {
  const { type } = /** @type {{type: MediaType, metadata?: ArticleData}} */ (
    (() => {
      const type = entity.data.media?.__typename?.toUpperCase() ?? entity.type;

      switch (type) {
        case "ARTICLE": {
          return {
            type: "articles",
          };
        }
        case "IMAGE":
          return { type: "images" };
        case "VIDEO":
          return { type: "videos" };
        case "SNIPPET":
          return { type: "snippets" };
        case "CUSTOMTYPECONTENT":
          return { type: "custom_types_contents" };
        case "TWEET":
          return { type: "tweets" };
        case "PRODUCT":
          return { type: "products" };
        case "PRODUCTSSUMMARY":
          return { type: "products_summaries" };
        case "ARCHIVE":
          return { type: "archives" };
        default:
          throw new Error(`unknown __typename  ${type}`);
      }
    })()
  );

  return /** @type {Promise<ArticleMedia | null>} */ (
    fetchArticleMedia({
      articleId,
      type,
      id: entity.data.media.id,
      url: entity.data.media.url ?? "",
    })
  );
};

/**
 *
 * @param {MediaEntity[]} entities
 * @param {number} articleId
 * @param {FetchArticleMedia} fetchArticleMedia
 * @param {string[]} activeEntityTypes
 * @returns {Promise<(MediaEntity | ArticleMedia | null)[]>}
 */
const generateArticleMediaEntities = (
  entities,
  articleId,
  fetchArticleMedia,
  activeEntityTypes,
) => {
  return Promise.all(
    entities.map((entity) => {
      if (
        !activeEntityTypes.includes(entity.type) &&
        !ARTICLE_MEDIA_ENTITIES.includes(entity.type)
      ) {
        return null;
      }
      if (STATIC_ENTITY_TYPES.includes(entity.type)) return entity;
      return fetchFromArticleMediaType(entity, articleId, fetchArticleMedia);
    }),
  );
};

/**
 * @param {SiriusContent} siriusContent
 * @param {number} [articleId]
 * @param {boolean} [useArticleMediaType]
 * @param {FetchArticleMedia} [fetchArticleMedia]
 * @param {string[]} [activeEntityTypes]
 * @returns
 */
const generateEntities = async (
  siriusContent,
  articleId,
  useArticleMediaType,
  fetchArticleMedia,
  activeEntityTypes,
) => {
  const keys = /** @type {string[]} */ ([]);
  const entities = /** @type {MediaEntity[]} */ (
    Object.entries(siriusContent.entities)
      .map(([key, value]) => {
        if (
          value.type === "COMPOSITE" &&
          articleId !== siriusContent.articleId
        ) {
          const entitiesWithoutComments = value.data.entities.filter(
            ({ type }) => type !== "COMMENT",
          );
          value.data.entities = entitiesWithoutComments;
          if (!entitiesWithoutComments.length) {
            return;
          }
        }
        keys.push(key);
        return value;
      })
      .filter(Boolean)
  );

  if (useArticleMediaType) {
    const results = await generateArticleMediaEntities(
      entities,
      /** @type {number} */ (articleId),
      /** @type {FetchArticleMedia} */ (fetchArticleMedia),
      /** @type {string[]} */ (activeEntityTypes),
    );

    const mapComposeEntityKey = {};

    return keys.reduce((acc, key, index) => {
      const result = results[index];

      const entity = (() => {
        if (!result) return null;
        if ("COMPOSITE" === result.type) {
          result.data.entities = result.data.entities.map((entity) => {
            if (!mapComposeEntityKey[entity.key]) {
              mapComposeEntityKey[entity.key] = genComposeEntityKey(entity);
            }
            return {
              ...entity,
              key: mapComposeEntityKey[entity.key],
            };
          });
        }
        if (STATIC_ENTITY_TYPES.includes(result.type ?? "")) return result;
        return {
          id: /** @type {ArticleMedia | null} */ (result)?.id,
          type: "ARTICLE_MEDIA",
          data: {
            ...result,
            type: /** @type {ArticleMedia | null} */ (result)?.__typename,
          },
        };
      })();

      return {
        ...acc,
        [key]: entity,
      };
    }, {});
  }

  return keys.reduce((acc, key, index) => {
    const entity = /** @type {MediaEntity} */ (entities[index]);
    // Set entity to null if the type is not handled by the editor
    if (
      (entity.type !== "ARTICLE_MEDIA" &&
        !activeEntityTypes?.includes(entity.type)) ||
      (entity.type === "ARTICLE_MEDIA" &&
        !activeEntityTypes?.includes(
          entity.data.media.__typename?.toUpperCase() ?? "",
        ))
    ) {
      return {
        ...acc,
        [key]: null,
      };
    }

    // Case where the entity comes from a post editor -> copy from a post and past into another post
    if (entity.type !== "ARTICLE_MEDIA") {
      return {
        ...acc,
        [key]: entity,
      };
    }

    if (entity.data.media.__typename === "CustomTypeContent") {
      return acc;
    }

    const entityData = {
      type: entity.data.media?.__typename?.toUpperCase(),
      mutability: "IMMUTABLE",
      data: { ...entity.data, id: entity.data.media.id },
    };

    return {
      ...acc,
      [key]: entityData,
    };
  }, {});
};

/** @typedef {<T extends object>(args: {blockData: T, keyStored: Record<string, string>}) => T} MergeBlockData */

/**
 * @param {{editorState: DraftEditorState, setEditorState: (editorState: EditorState) => void }} state
 * @param {SiriusContent} siriusContent
 * @param {{createNewEntities: boolean, articleId?: number, useArticleMediaType?: boolean, fetchArticleMedia?: FetchArticleMedia, activeEntityTypes?: string[], allowedBlockTypes?: string[], allowedInlineStyles?: string[], mergeBlockData: MergeBlockData[]}} options
 */
export const mergePastedSiriusContent = async (
  { editorState, setEditorState },
  siriusContent,
  {
    createNewEntities,
    articleId,
    useArticleMediaType,
    fetchArticleMedia,
    activeEntityTypes,
    allowedBlockTypes,
    allowedInlineStyles,
    mergeBlockData,
  },
) => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const contentBlocks = siriusContent.contentState.map(
    (block) => new ContentBlock(block),
  );

  let nextContentState = ContentState.createFromBlockArray(contentBlocks);

  if (createNewEntities) {
    siriusContent.entities = await generateEntities(
      siriusContent,
      articleId,
      useArticleMediaType,
      fetchArticleMedia,
      activeEntityTypes,
    );
  }

  const keyStored = createEntities(siriusContent.entities, nextContentState);
  const blockMap = formatBlocks(
    nextContentState,
    keyStored,
    ["paragraph", "atomic", "unstyled", ...(allowedBlockTypes ?? [])],
    allowedInlineStyles ?? [],
    mergeBlockData,
  );

  nextContentState = Modifier.replaceWithFragment(
    contentState,
    selection,
    blockMap,
  );

  setEditorState(
    EditorState.push(editorState, nextContentState, "insert-fragment"),
  );
};
