import * as React from "react";
import { forwardRef } from "react";

const SvgPricetagsOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M18.904 1.5h-5.762c-.18 0-.351.07-.478.197L1.144 13.214a1.352 1.352 0 0 0 0 1.908l5.484 5.484a1.353 1.353 0 0 0 1.908 0L20.053 9.094a.678.678 0 0 0 .197-.478V2.85a1.342 1.342 0 0 0-1.346-1.35Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 6.75a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
      fill="currentColor"
    />
    <path
      d="m10.781 22.5 12.281-12.281a.647.647 0 0 0 .188-.469v-6"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPricetagsOutline);
export default ForwardRef;
