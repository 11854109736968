import { forwardRef } from "react";

const SvgBold = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 6h6a3 3 0 0 1 0 6h-6V6ZM7.5 12h6.75a3 3 0 0 1 0 6H7.5v-6Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBold);
export default ForwardRef;
