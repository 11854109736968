import { ContentBlock, ContentState, EditorState } from "draft-js-es";

import { selectBlock } from "@/components/rich-editor/modifiers/selectBlock";

import { acceptSelection } from "./acceptSelection";

/**
 * Move block to a specific index.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} blockKey
 */
export function removeBlock(editorState, blockKey, nextSelectedIndex = 0) {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap().toArray();
  const index = blocks.findIndex((block) => block.getKey() === blockKey);
  if (index === -1) return editorState;

  // Mutate the array and remove the block
  blocks.splice(index, 1);

  // Always keep one block
  if (blocks.length === 0) {
    blocks.push(
      new ContentBlock({
        text: "",
        type: "paragraph",
      }),
    );
  }

  const blockRemovedEditorState = EditorState.push(
    editorState,
    ContentState.createFromBlockArray(blocks, contentState.getEntityMap()),
    "remove-range",
  );

  if (nextSelectedIndex === null) {
    const withOriginalSelection = acceptSelection(
      blockRemovedEditorState,
      editorState.getSelection(),
    );
    return withOriginalSelection;
  }

  // Select the block at the same place
  const selectedBlock =
    blocks[Math.min(blocks.length - 1, index + nextSelectedIndex)] ??
    blocks[Math.min(blocks.length - 1, index)];

  return selectBlock(blockRemovedEditorState, selectedBlock.getKey());
}
