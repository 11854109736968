import { useEffect, useState } from "react";

import * as storage from "@/services/localStorage";

export function useStorage(key, initialValue) {
  const [value, setValue] = useState(
    () => storage.getItem(key) ?? initialValue,
  );
  useEffect(() => {
    if (value === undefined) return;
    storage.setItem(key, value);
  }, [key, value]);
  return [value, setValue];
}
