import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment ProlexisIntegrationConfigFragment on ProlexisIntegrationConfig {
    baseUrl
    apiKey
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="baseUrl" label="Base URL" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="apiKey" label="API Key" required />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
