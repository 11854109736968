import { ContentBlock, ContentState, EditorState } from "draft-js-es";

export const removeAllSelection = (editorState) => {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap().toArray();
  const firstBlock = blocks.at(0);
  const lastBlock = blocks.at(-1);
  const selectionState = editorState.getSelection();

  if (
    selectionState.anchorKey === firstBlock.key &&
    selectionState.focusKey === lastBlock.key &&
    selectionState.anchorOffset === 0 &&
    selectionState.focusOffset === lastBlock.getText().length
  ) {
    return EditorState.push(
      editorState,
      ContentState.createFromBlockArray(
        [
          new ContentBlock({
            text: " ",
            type: "paragraph",
          }),
        ],
        contentState.getEntityMap(),
      ),
      "remove-range",
    );
  }
};
