import { useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

// src: https://github.com/Evercoder/clipboard-inspector/blob/master/index.jsx
async function extractData(data) {
  if (!data) {
    return undefined;
  }

  const file_info = (file) =>
    file
      ? {
          name: file.name,
          size: file.size,
          type: file.type,
          url: URL.createObjectURL(file),
        }
      : null;

  if (data instanceof DataTransfer) {
    return {
      type: "DataTransfer",
      types: Array.from(data.types).map((type) => ({
        type,
        data: data.getData(type),
      })),
      items: data.items
        ? Array.from(data.items).map((item) => ({
            kind: item.kind,
            type: item.type,
            as_file: file_info(item.getAsFile()),
          }))
        : null,
      files: data.files ? Array.from(data.files).map(file_info) : null,
    };
  }

  // eslint-disable-next-line no-undef
  if (data instanceof ClipboardItem) {
    return {
      type: "ClipboardItem",
      types: await Promise.all(
        Array.from(data.types).map(async (type) => {
          const blob = await data.getType(type);
          return {
            type: type,
            data: blob.type.match(/^text\//)
              ? await blob.text()
              : file_info(blob),
          };
        }),
      ),
    };
  }
  return undefined;
}

export const useSiriusPasteHandler = (
  editorState,
  env,
  containerRef,
  handlePastedText,
  readOnly,
) => {
  const editorStateRef = useLiveRef(editorState);
  useEffect(() => {
    const container = containerRef.current;

    const handlePaste = async (event) => {
      event.preventDefault();
      event.stopPropagation();
      const data = event.clipboardData;
      const extractedData = data
        ? await Promise.all(
            (Array.isArray(data) ? data : [data]).map(extractData),
          )
        : [];
      const defaultData = extractedData[0];
      const text = defaultData.types.find(
        (type) => type.type === "text/plain",
      )?.data;
      const html = defaultData.types.find(
        (type) => type.type === "text/html",
      )?.data;
      const sirius = defaultData.types.find(
        (type) => type.type === `text/_sirius-editor-${env}`,
      )?.data;
      handlePastedText(
        text,
        html,
        editorStateRef.current,
        sirius ? JSON.parse(sirius) : undefined,
      );
    };

    if (!readOnly) {
      container.addEventListener("paste", handlePaste);
    }
    return () => {
      container.removeEventListener("paste", handlePaste);
    };
  }, [env, containerRef, editorStateRef, readOnly, handlePastedText]);
};
