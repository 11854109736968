import { gql } from "@apollo/client";
import moment from "moment";
import { useEffect } from "react";
import { Loader } from "swash/Loader";
import { PanelBody } from "swash/Panel";

import { useAmplitude } from "@/containers/Amplitude";
import { useSafeQuery } from "@/containers/Apollo";
import { createPanel } from "@/containers/PanelManager";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";

import { ForecastAnalytics } from "./Forecast";
import { ForecastDataAlert } from "./ForecastAlert";
import { ForecastInsights } from "./ForecastInsights";
import { SiriusAnalytics } from "./Sirius";
import { TogglerProvider, useToggleAnalyticsState } from "./TogglerProvider";

const ArticleFirstPublishDate = gql`
  query ArticleFirstPublishDate($id: Int!) {
    article(id: $id) {
      id
      firstPublished
      published
      url
    }
  }
`;

const ForecastVersionSwitcher = ({ articleId }) => {
  const { data } = useSafeQuery(ArticleFirstPublishDate, {
    variables: { id: articleId },
  });
  if (!data) return <Loader />;

  const {
    article: { firstPublished, published, url },
  } = data;

  if (!published) return <ForecastDataAlert type="published" />;
  if (!url) return <ForecastDataAlert type="url" />;

  // TODO: Remove the date check when sirius data has done the migration
  if (moment(firstPublished).isBefore(moment("2024-07-20"))) {
    return <ForecastAnalytics articleId={articleId} />;
  }
  return <ForecastInsights articleId={articleId} />;
};

export const ArticleAnalyticsPanelBody = ({ articleId }) => {
  const toggledAnalyticsState = useToggleAnalyticsState();

  const { logEvent } = useAmplitude();
  useEffect(() => {
    logEvent("statsPanel:open");
  }, [logEvent, articleId]);

  return (
    <TogglerProvider state={toggledAnalyticsState}>
      <PanelBody className="flex flex-col gap-4">
        <SiriusAnalytics articleId={articleId} />
        <ForecastVersionSwitcher articleId={articleId} />
      </PanelBody>
    </TogglerProvider>
  );
};

const PanelContent = ({ articleId, onClose }) => {
  return (
    <>
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <ArticleAnalyticsPanelBody articleId={articleId} />
    </>
  );
};

export const ArticleAnalyticsPanel = createPanel({
  key: "article-analytics",
  title: "Statistiques",
  render: ({ articleId, onClose }) => {
    return (
      <>
        <TrackPanel panelKey="article-analytics" />
        <PanelContent articleId={articleId} onClose={onClose} />
      </>
    );
  },
});
