import { forwardRef } from "react";

const SvgBagRoundOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.034 8.875a.625.625 0 0 0-.625.625L4.5 17.938c0 1.18 1.006 2.187 2.188 2.187h10.625c1.18 0 2.187-.957 2.187-2.139L18.59 9.5a.625.625 0 0 0-.624-.625H6.034Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 8.875v-1.25A3.75 3.75 0 0 1 12 3.875v0a3.75 3.75 0 0 1 3.75 3.75v1.25"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBagRoundOutline);
export default ForwardRef;
