import { forwardRef } from "react";

import { FieldState } from "./FieldState";

const frSign = (count: number) => (count > 1 ? "signes" : "signe");

type FieldSignCounterProps = {
  state: FieldState;
  limit?: number;
};

export const FieldSignCounter = forwardRef<
  HTMLDivElement,
  FieldSignCounterProps
>(({ state, limit: propLimit, ...props }, ref) => {
  const signCount = state.field.input.value.length;
  const limit = propLimit ?? state.maxLength ?? null;
  return (
    <div
      ref={ref}
      {...props}
      className="text-sm text-dusk-bg-strong [&_strong]:text-danger-bg-strong"
    >
      {limit ? (
        <>
          {signCount > limit ? <strong>{signCount}</strong> : signCount} /{" "}
          {limit} {frSign(limit)}
        </>
      ) : (
        <>
          {signCount} {frSign(signCount)}
        </>
      )}
    </div>
  );
});
if (process.env["NODE_ENV"] !== "production") {
  FieldSignCounter.displayName = "FieldSignCounter";
}
