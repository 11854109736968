/**
 * @param {import('hast').Node} node
 * @returns {node is import('hast').Element}
 */
export const isHastElement = (node) => node.type === "element";

/**
 * @param {import('hast').Node} node
 * @returns {node is import('hast').ElementContent}
 */
export const isHastElementContent = (node) =>
  node.type === "element" || node.type === "comment" || node.type === "text";

/**
 * @param {import('hast').Node} node
 * @returns {node is import('hast').Parent}
 */
export const isHastParent = (node) => Boolean(node.children);

/**
 * @param {import('hast').Node} node
 * @returns {node is import('hast').Text}
 */
export const isHastText = (node) => node.type === "text";

/**
 * @param {import('hast').Element} element
 * @param {string} style
 * @returns {boolean}
 */
export const elementHasStyle = (element, style) => {
  const styleProp = element.properties?.style;
  if (styleProp) {
    return String(styleProp).includes(style);
  }
  return false;
};
