import { gql } from "@apollo/client";
import { memo } from "react";

import { QueryFilterField } from "@/containers/search/filterFields/QueryFilterField";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const MediaFragment = gql`
  fragment MediaFilterField_media on MediaDescriptor {
    id: secondaryType
    label
    defaultValue
  }
`;

const query = gql`
  query MediaFilter_mediaDescriptors {
    connection: mediaDescriptors(
      offset: 0
      limit: 10
      where: { primaryType: { eq: "image" } }
    ) {
      nodes {
        ...MediaFilterField_media
      }
      ...ConnectionFragment
    }
  }
  ${MediaFragment}
  ${ConnectionFragment}
`;

const name = "types";

const Filter = memo(() => {
  return (
    <QueryFilterField
      name={name}
      label="Type d’images"
      query={query}
      fragment={MediaFragment}
      modelName="MediaDescriptor"
      multi
      searchable={false}
    />
  );
});

export const MediaFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
