import { gql } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { EditorState, Modifier } from "draft-js-es";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelHeader } from "swash/Panel";
import { Dialog } from "swash/v2/Dialog";

import { useSafeQuery } from "@/containers/Apollo";
import { diffWordMode } from "@/containers/editor/nodes/diffWord";

import { useSpellCheck } from "../spell-check-control/SpellCheckPluginContext";
import { MerciApp, MerciAppProvider } from "./MerciApp";
import "./merciapp.css";
import logo from "./merciapp.png";

const MerciAppServiceQuery = gql`
  query MerciAppServiceQuery($serviceKey: ThirdPartyServiceKey!) {
    thirdPartyService(key: $serviceKey) {
      integrations {
        id
        config {
          __typename
          ... on MerciappIntegrationConfig {
            token
          }
        }
      }
    }
  }
`;

const MerciAppDialogContent = ({ dialog }) => {
  const { data } = useSafeQuery(MerciAppServiceQuery, {
    variables: { serviceKey: "merciapp" },
  });
  const authToken = data?.thirdPartyService.integrations?.[0]?.config.token;

  if (data && !authToken) {
    return null;
  }

  return (
    <MerciAppProvider token={authToken}>
      <PanelHeader
        title={
          <img
            src={logo}
            alt="MerciApp"
            className="h-7"
            style={{ aspectRatio: "64 / 15" }}
          />
        }
        onClose={dialog.hide}
      />
      <PanelBody>{data ? <MerciAppEditors /> : <PageLoader />}</PanelBody>
    </MerciAppProvider>
  );
};

export const MerciAppDialog = ({ dialog }) => {
  const open = dialog.useState("open");
  return (
    <Dialog
      store={dialog}
      style={{ width: 968 }}
      aria-label="Correcteur MerciApp"
      hideOnInteractOutside={false}
      hideOnEscape={false}
    >
      {open && <MerciAppDialogContent dialog={dialog} />}
    </Dialog>
  );
};

const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  color: dusk;
  font-size: md;
  font-family: base;
  &[data-title] {
    font-size: lg;
    font-weight: 700;
  }
  &[data-chapo] {
    font-size: base;
    font-weight: 600;
    line-height: 23px;
  }
`;

const EditorsContainer = styled.div`
  width: 698px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  > div:not(:last-child):after {
    margin-top: 15px;
    content: "";
    display: block;
    border-bottom: 1px solid;
    border-color: grey-light;
  }
`;

const hasText = (editor) => {
  const editorState = editor?.getEditorState();
  if (!editorState) return false;
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();
  return blocks.some(
    (block) => block.getText() && block.getType() !== "atomic",
  );
};

const MerciAppEditors = memo(() => {
  const { getEditors } = useSpellCheck();
  const editors = getEditors();

  return (
    <EditorsContainer>
      {editors.map((editor) => {
        const hasContent = hasText(editor);
        return (
          <MerciAppEditor
            key={editor.name}
            {...editor}
            data-title={editor.name === "title" && hasContent ? true : null}
            data-chapo={editor.name === "chapo" && hasContent ? true : null}
          />
        );
      })}
      {!editors.length && `Aucun contenu à corriger`}
    </EditorsContainer>
  );
});

const MerciAppEditor = ({ getEditorState, setEditorState, ...props }) => {
  const editorState = getEditorState();
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();

  return (
    <div>
      {blocks
        .filter((block) => block.getType() !== "atomic")
        .map((block) => {
          return (
            <MerciAppEditorTextarea
              key={block.getKey()}
              editorState={editorState}
              setEditorState={setEditorState}
              block={block}
              getEditorState={getEditorState}
              {...props}
            />
          );
        })}
    </div>
  );
};

const useAutoResizeOnChange = (defaultValue) => {
  const elementRef = useRef(null);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value) {
      elementRef.current.style.height = "0px";
      const scrollHeight = elementRef.current.scrollHeight;
      elementRef.current.style.height =
        scrollHeight > 1000 ? "1000px" : scrollHeight + "px";
    }
  }, [value]);

  return { elementRef, setValue };
};

function removeText(editorState, selection) {
  return EditorState.push(
    editorState,
    Modifier.removeRange(editorState.getCurrentContent(), selection, "forward"),
    "spellcheck-change",
  );
}

function addText(editorState, selection, text) {
  return EditorState.push(
    editorState,
    Modifier.insertText(editorState.getCurrentContent(), selection, text),
    "spellcheck-change",
  );
}

function replaceText(
  lastBlock,
  anchorOffset,
  focusOffset,
  editorState,
  selection,
  text,
) {
  const startStyles = lastBlock.getInlineStyleAt(anchorOffset);
  const endStyles = lastBlock.getInlineStyleAt(focusOffset - 2);
  const startEntity = lastBlock.getEntityAt(anchorOffset);
  const endEntity = lastBlock.getEntityAt(focusOffset - 2);

  return EditorState.push(
    editorState,
    Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      text,
      startStyles === endStyles ? startStyles : undefined,
      startEntity === endEntity ? startEntity : undefined,
    ),
    "spellcheck-change",
  );
}

const MerciAppEditorTextarea = ({
  getEditorState,
  setEditorState,
  block,
  ...props
}) => {
  const blockKeyRef = useRef(block.getKey());
  const { elementRef, setValue } = useAutoResizeOnChange(block.getText());

  const handleOnChange = useCallback(
    (event) => {
      const blocKey = blockKeyRef.current;
      const lastEditorState = getEditorState();
      const contentState = lastEditorState.getCurrentContent();
      const lastBlock = contentState.getBlockForKey(blocKey);
      const newBlockText = event.target.value;
      const previousText = lastBlock.getText();

      const diffs = diffWordMode(previousText, newBlockText);

      const removedIndex = diffs.findIndex((diff) => diff[0] === -1);
      const addedIndex = diffs.findIndex((diff) => diff[0] === 1);
      const anchorOffset = diffs[0][0] === 0 ? diffs[0][1].length : 0;

      const removedText = removedIndex > -1 ? diffs[removedIndex][1] : "";
      const addedText = addedIndex > -1 ? diffs[addedIndex][1] : "";
      const focusOffset = anchorOffset + removedText.length;

      const selection = lastEditorState.getSelection().merge({
        anchorOffset,
        focusOffset,
        anchorKey: blocKey,
        focusKey: blocKey,
        hasFocus: false,
      });

      const newEditorState = (() => {
        if (addedIndex === -1) {
          return removeText(lastEditorState, selection);
        }

        if (removedIndex === -1) {
          return addText(lastEditorState, selection, addedText);
        }

        return replaceText(
          lastBlock,
          anchorOffset,
          focusOffset,
          lastEditorState,
          selection,
          addedText,
        );
      })();

      setValue(newBlockText);
      setEditorState(newEditorState);
    },
    [getEditorState, setEditorState, setValue],
  );

  if (!block.getText())
    return (
      <div>
        <br />
      </div>
    );

  return (
    <MerciApp id={block.getKey()}>
      <Textarea
        ref={elementRef}
        className="merciapp-editor"
        onChange={handleOnChange}
        defaultValue={block.getText()}
        oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
        role="textbox"
        {...props}
      />
    </MerciApp>
  );
};
