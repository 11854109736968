import { Modifier, RichUtils, SelectionState } from "draft-js-es";

import { setContent } from "./setContent";

/**
 * If the cursor is in a portion of text that has the style, then remove it.
 * Else fallback on the old behaviour.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} inlineStyle
 */
export const smartToggleInlineStyle = (editorState, inlineStyle) => {
  const selection = editorState.getSelection();
  const currentStyle = editorState.getCurrentInlineStyle();

  // If the selection is collapsed with a current style
  // then remove the style on all adjacent style
  if (selection.isCollapsed() && currentStyle.size > 0) {
    const content = editorState.getCurrentContent();
    const blockKey = selection.getStartKey();
    const offset = selection.getStartOffset();
    const block = content.getBlockForKey(blockKey);
    let start = offset + 1;
    while (start >= 0 && block.getInlineStyleAt(start - 1) === currentStyle) {
      start -= 1;
    }
    let end = offset - 1;
    while (
      end < block.getLength() &&
      block.getInlineStyleAt(end) === currentStyle
    ) {
      end += 1;
    }

    // If start > end, then we are at the edge
    // If start === end, then we are just activated without any char
    // In the two cases we fallback to original behaviour
    if (start >= end) {
      return RichUtils.toggleInlineStyle(editorState, inlineStyle);
    }

    const nodeSelection = SelectionState.createEmpty(blockKey)
      .set("anchorOffset", start)
      .set("focusOffset", end);

    return setContent(
      editorState,
      Modifier.removeInlineStyle(content, nodeSelection, inlineStyle),
    );
  }

  return RichUtils.toggleInlineStyle(editorState, inlineStyle);
};
