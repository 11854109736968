import { expandArticleNodes } from "./articleInterop";
import { fromNodes } from "./fromNodes";

/**
 * @typedef {import('draft-js').ContentState} ContentState
 */

/**
 * Convert article nodes to content state.
 * @param {any[]} nodes
 * @param {any[]} plugins
 * @returns {ContentState}
 */
export const fromArticleNodes = (nodes, plugins) => {
  return fromNodes(expandArticleNodes(nodes), plugins);
};
