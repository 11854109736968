import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

const GetInactivityDuration = createContext();

const useLazyRef = (getInitialValue) => {
  const ref = useRef(null);
  if (ref.current === null) ref.current = getInitialValue();
  return ref;
};

export const ActivityProvider = ({ children }) => {
  const lastActiveRef = useLazyRef(() => new Date().toISOString());
  const getInactivityDuration = useCallback(
    () => new Date() - new Date(lastActiveRef.current),
    [lastActiveRef],
  );

  useEffect(() => {
    const setLastActive = () => {
      lastActiveRef.current = new Date().toISOString();
    };
    document.addEventListener("mousedown", setLastActive);
    document.addEventListener("keydown", setLastActive);
    return () => {
      document.removeEventListener("mousedown", setLastActive);
      document.removeEventListener("keydown", setLastActive);
    };
  }, [lastActiveRef]);

  return (
    <GetInactivityDuration.Provider value={getInactivityDuration}>
      {children}
    </GetInactivityDuration.Provider>
  );
};

export const useGetInactivityDuration = () => {
  return useContext(GetInactivityDuration);
};
