import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { clsx } from "clsx";
import * as React from "react";
import { cloneElement, forwardRef } from "react";

import { Portal } from "./Portal";

export type TooltipProps = {
  children: React.ReactElement;
  delay?: number | undefined;
  gutter?: number | undefined;
  nude?: boolean;
  placement?: Placement | undefined;
  tooltip: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  (
    {
      tooltip,
      children,
      placement,
      gutter = 8,
      delay: delayProp = 500,
      "aria-expanded": ariaExpanded,
      nude,
      ...props
    }: TooltipProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const open = isOpen && ariaExpanded !== true;

    const { x, y, strategy, refs, context } = useFloating({
      open,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
      middleware: [offset(gutter), flip(), shift()],
      placement,
    });

    const hover = useHover(context, {
      move: false,
      delay: { open: delayProp, close: 0 },
    });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: "tooltip" });
    const { getReferenceProps, getFloatingProps } = useInteractions([
      hover,
      focus,
      dismiss,
      role,
    ]);
    const child = React.Children.only(children);
    const referenceRef = useMergeRefs([ref, refs.setReference]);

    if (!tooltip) {
      return cloneElement(child, { ...props, ref });
    }

    const referenceProps = {
      ...getReferenceProps(props),
      ref: referenceRef,
    } as Record<string, any>;

    return (
      <>
        {cloneElement(child, referenceProps)}
        {open && (
          <Portal>
            <div
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: "max-content",
              }}
              className={clsx(
                "z-tooltip max-w-[320px]",
                !nude &&
                  "whitespace-pre-wrap rounded bg-dusk-bg-stronger fill-dusk-bg-stronger px-2 py-1 text-sm text-white",
                /* Mark */
                "[&_mark]:bg-white/25 [&_mark]:text-white",
                /* List */
                "[&_li]:list-inside [&_li]:list-disc",
              )}
              {...getFloatingProps()}
            >
              {tooltip}
            </div>
          </Portal>
        )}
      </>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Tooltip.displayName = "Tooltip";
}
