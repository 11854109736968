export const allowedAttributes = { div: ["data-style-name"] };

/** @type {import('../types').htmlToBlock} */
export const htmlToBlock = ({ element }) => {
  if (element.properties?.dataStyleName) {
    return {
      data: { styleName: element.properties.dataStyleName },
    };
  }
  return null;
};
