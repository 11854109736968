import clsx from "clsx";
import React, { useEffect } from "react";

import { Button, ButtonProps } from "../Button";
import { IoClose } from "../Icon";
import { VariantIcons } from "../VariantIcons";
import { useTransition } from "../motion/Transition";
import { useId } from "../utils/hooks";
import type { BaseToast, ToastOptions } from "./ToastProvider";

export type ToastVariant = "success" | "warning" | "danger" | "info";

const appearances: Record<ToastVariant, string> = {
  success: "bg-success-bg-strong",
  warning: "bg-warning-bg-strong",
  danger: "bg-danger-bg-strong",
  info: "bg-info-bg-strong",
};

export interface BaseToastProps extends ToastOptions, BaseToast {
  message: string | React.ReactNode;
  variant: ToastVariant;
  visible: boolean;
  remove: () => void;
}

export type ToastProps = {
  toast: BaseToastProps;
};

export const Toast = ({ toast }: ToastProps) => {
  const transition = useTransition(toast.visible);

  useEffect(() => {
    if (toast.visible && Boolean(toast.dismissDelay)) {
      const timeoutId = setTimeout(() => {
        toast.dismiss();
      }, toast.dismissDelay);
      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [toast]);

  useEffect(() => {
    if (!toast.visible) {
      toast.remove();
    }
  }, [toast]);

  const messageId = useId();

  if (transition.hidden) return null;

  const ToastIcon = VariantIcons[toast.variant];

  const buttonProps: ButtonProps = {
    type: "button",
    appearance: "fill",
    scale: "sm",
    variant: toast.variant === "info" ? "primary" : toast.variant,
  };

  const action = toast.action ? (
    toast.action({ buttonProps })
  ) : toast.closable ? (
    <Button
      {...buttonProps}
      aria-label="Fermer"
      iconOnly
      onClick={() => {
        toast.dismiss();
      }}
    >
      <IoClose />
    </Button>
  ) : null;

  const {
    style,
    "data-enter": dataEnter,
    "data-leave": dataLeave,
    hidden,
  } = transition;

  return (
    <div
      className={clsx(
        "grid w-fit items-center text-sm text-white opacity-0",
        "leading-2 z-toast mx-3 my-2 gap-3 rounded-3xl px-4 py-2 shadow-xl",
        "data-[toast-has-detail]:rounded-lg",
        "data-[enter]:translate-y-0 data-[enter]:opacity-100 data-[enter]:transition-opacity data-[enter]:duration-300 data-[enter]:ease-in",
        "data-[leave]:transition-opacity data-[leave]:duration-200 data-[leave]:ease-out",
        appearances[toast.variant],
      )}
      aria-labelledby={messageId}
      aria-live={toast.variant === "danger" ? "assertive" : "polite"}
      data-enter={dataEnter}
      data-leave={dataLeave}
      data-toast-has-detail={toast.detail ? "" : undefined}
      style={{
        maxWidth: 920,
        gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
        ...style,
      }}
      hidden={hidden}
      role="alert"
    >
      {ToastIcon ? <ToastIcon size={18} /> : null}
      <div id={messageId}>{toast.message}</div>
      {toast.detail ? (
        <div className="col-start-2 row-start-2">{toast.detail}</div>
      ) : null}
      {action ? <div>{action}</div> : null}
    </div>
  );
};
