import { gql } from "@apollo/client";
import { Anchor } from "swash/Anchor";
import { Tooltip } from "swash/Tooltip";

import { useSyncFields } from "@/components/forms/FormFieldSync";
import { StringField } from "@/containers/admin/CRUD";

import { ChannelAccountPicture, ChannelAccounts } from "../AlertChannelLayout";

export const title = "Twitter";

export const type = "twitter";

export const fieldName = "targetingTwitter";

export const AlertFragment = gql`
  fragment TwitterChannelAlertFragment on Alert {
    targetingTwitter
    tweetText
  }
`;

export const ChannelFragment = gql`
  fragment TwitterChannelFragment on TwitterAlertChannel {
    twitterUsers {
      id
      screenName
      name
      normalProfileImageUrl
    }
  }
`;

export const getInitialValues = ({ channelData, alert, article }) => {
  const channelEnabled = Boolean(
    channelData?.status && channelData.status !== "disabled",
  );
  return {
    targetingTwitter: alert?.targetingTwitter ?? channelEnabled,
    tweetText: alert?.tweetText ?? article.title,
  };
};

export const Title = ({ data }) => {
  return (
    <div>
      <span>{title}</span>
      <ChannelAccounts>
        {data.twitterUsers.map((user) => (
          <Tooltip key={user.id} tooltip={`${user.name} (@${user.screenName})`}>
            <li>
              <Anchor
                href={`https://twitter.com/${user.screenName}`}
                target="_blank"
              >
                <ChannelAccountPicture
                  src={user.normalProfileImageUrl}
                  alt={user.screenName}
                />
              </Anchor>
            </li>
          </Tooltip>
        ))}
      </ChannelAccounts>
    </div>
  );
};

export const Body = ({ disabled }) => {
  useSyncFields({ from: "title", to: "tweetText" });
  return (
    <>
      <StringField
        required
        name="tweetText"
        label="Tweet"
        limit={250}
        limitType="hard"
        rich
        disabled={disabled}
        scale="base"
      />
      <div className="mt-2 text-xs text-grey-on-light">
        L’URL de l’article sera automatiquement ajoutée à ce tweet.
      </div>
    </>
  );
};
