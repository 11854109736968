import { memo } from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Link } from "swash/Link";

import { LivePostContent } from "@/containers/live/LivePostContent";
import { LivePostPublishedLink } from "@/containers/routes/live/LivePostPublishedLink";

import { PostListSet as Set } from "./PostListSet";

export const columns = {
  post: { props: { w: 1 } },
};

export const PostRow = memo(({ post }) => {
  return (
    <Set.ListBody display="block" my={2}>
      <Set.ListRow row border={1} borderColor="layout-border">
        <Set.ListCell colId="post" p={0}>
          <LivePostContent
            post={post}
            actions={
              <>
                <LivePostPublishedLink scale="sm" post={post} />
                <Link asChild className="text-sm">
                  <ReactRouterLink to={`/lives/${post.liveId}`}>
                    Voir le live
                  </ReactRouterLink>
                </Link>
              </>
            }
          />
        </Set.ListCell>
      </Set.ListRow>
    </Set.ListBody>
  );
});
