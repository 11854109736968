// @ts-check

const type = "custom_types_contents";

/** @type {import('.').MediaProvider["getData"]} */
function getData({ url, frontHost }) {
  try {
    const urlObj = new URL(url);
    if (urlObj.host === frontHost) {
      return { url };
    }
    if (urlObj.host === window.location.host) {
      const matches = urlObj.pathname.match(/\/contents\/\d+\/(?<id>\d+)/);
      if (!matches || !matches.groups?.["id"]) return null;
      return { id: Number(matches.groups["id"]) };
    }
    return null;
  } catch {
    return null;
  }
}

/** @type {import('.').MediaProvider} */
export const provider = { type, getData };
