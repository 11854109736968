import { ERRORS } from "@/config/messages";

const checkIsUrl = (url, options) => {
  if (
    /[\s<>]/.test(url) ||
    url.length >= 2083 ||
    url.indexOf("mailto:") === 0
  ) {
    return false;
  }

  const split = url.split("://");
  if (split.length > 1) {
    const protocol = split.shift().toLowerCase();
    if (["https", "http"].indexOf(protocol) === -1) {
      return false;
    }
  } else if (options.requireProtocol) {
    return false;
  }
  url = split.join("://");

  return url !== "";
};

export const mustBeURL = (value, { requireProtocol = true } = {}) => {
  if (!value) return;
  if (!checkIsUrl(value, { requireProtocol })) {
    return ERRORS.forms.validators.mustBeURL;
  }
};
