import { memo } from "react";

import { DateFiltersField } from "@/containers/admin/CRUD/filtersFields/DateFiltersField";

const name = "shootingDate";

const Filter = memo(() => {
  return <DateFiltersField name={name} placeholder="Date de prise de vue" />;
});

export const ShootingDateFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
