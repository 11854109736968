import { gql } from "@apollo/client";

import {
  ArticleStatePublish,
  ArticleStatePublishProps,
} from "@/containers/article/ArticleStatePublish";
import type { CellType } from "@/containers/list/ListState";

export type StatesCellArticle = ArticleStatePublishProps["article"];

export const statesCell: CellType<StatesCellArticle> = {
  key: "states",
  title: "États",
  width: 72,
  render: ({ node }) => <ArticleStatePublish article={node} />,
  fragment: gql`
    fragment statesCell_article on Article {
      ...ArticleStatePublish_article
    }

    ${ArticleStatePublish.fragments.article}
  `,
};
