import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment MerciappIntegrationConfigFragment on MerciappIntegrationConfig {
    token
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="token" label="Authentication token" required />
      </FieldsetField>
    </Fieldset>
  );
}
export const ServiceForm = createServiceForm({ Fields });
