import * as anchor from "@/components/rich-editor/plugins/anchor";
import * as bold from "@/components/rich-editor/plugins/bold";
import * as comment from "@/components/rich-editor/plugins/comment";
import * as emoji from "@/components/rich-editor/plugins/emoji";
import * as italic from "@/components/rich-editor/plugins/italic";
import * as link from "@/components/rich-editor/plugins/link";
import * as removeStyle from "@/components/rich-editor/plugins/remove-style";
import * as strikethrough from "@/components/rich-editor/plugins/strikethrough";
import * as subscript from "@/components/rich-editor/plugins/subscript";
import * as superscript from "@/components/rich-editor/plugins/superscript";
import * as underline from "@/components/rich-editor/plugins/underline";

import presetText from "./preset-text";

/**
 * @typedef {import('./preset-text').Options} Options
 */

/**
 * @param {Options} [options]
 */
const presetRichText = (options) => ({
  presets: [[presetText, options]],
  plugins: [
    bold,
    italic,
    underline,
    ...(options.strikethrough ? [strikethrough] : []),
    superscript,
    subscript,
    link,
    ...(options.anchor ? [anchor] : []),
    emoji,
    removeStyle,
    ...(options.comment ? [comment] : []),
  ],
});

export default presetRichText;
