import React, { forwardRef } from "react";

import { cn } from "../utils/classNames";

export type FormHintProps = React.ComponentPropsWithoutRef<"div">;

export const FormHint = forwardRef<HTMLDivElement, FormHintProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn("text-sm text-grey-on", props.className)}
      />
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  FormHint.displayName = "FormHint";
}
