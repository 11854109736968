// @ts-check
import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  genKey,
} from "draft-js-es";
import { List, Map, OrderedMap, OrderedSet } from "immutable-es";
import { visit } from "unist-util-visit";

import { genComposeEntityKey } from "@/components/rich-editor/utils/CompositeEntity";

import { isHastElement, isHastParent, isHastText } from "./hast-util";

/** @typedef {import('hast').Node} HastNode */
/** @typedef {import('hast').Element} HastElement */
/** @typedef {import('./convertContext').Context} ConvertContext */

/**
 * @typedef LocalContext
 * @property {null | import('draft-js').ContentBlock} block
 * @property {import('draft-js').DraftInlineStyle} inlineStyle
 * @property {import('draft-js').ContentState} contentState
 * @property {string[]} orphanBlockKeys
 */

/** @typedef {ConvertContext & LocalContext} Context */

/**
 * @param {import('hast').Properties['style']} styles
 * @returns {Record<string, string>}
 */
const transformStringToObject = (styles) => {
  const stringStyles = /** @type {string} */ (
    JSON.parse(JSON.stringify(styles))
  );

  return stringStyles.split(";").reduce((obj, str) => {
    let newObj = JSON.parse(JSON.stringify(obj));
    let strParts = str.split(":");
    if (strParts[0] && strParts[1]) {
      newObj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim();
    }
    return newObj;
  }, /** @type {Record<string, string>} */ ({}));
};

/** @type {WeakMap<HastNode, string>} */
const nodeEntityMap = new WeakMap();

/**
 * @param {HastNode} node
 * @param {string} entityKey
 */
const setNodeEntity = (node, entityKey) => {
  nodeEntityMap.set(node, entityKey);
};

/**
 * @param {HastNode} node
 */
const getNodeEntity = (node) => nodeEntityMap.get(node);

/**
 * @param {import('draft-js').ContentBlock} block
 * @param {Context} ctx
 */
const setBlock = (block, ctx) => {
  ctx.block = block;

  const blockMap = ctx.contentState.getBlockMap().set(block.getKey(), block);

  ctx.contentState = new ContentState({ blockMap });
};

/**
 *
 * @param {Partial<import('../types').RawBlock> | null} data
 * @param {Context} ctx
 */
const createBlock = (data, ctx) => {
  const key = genKey();

  const partialBlock = {
    text: "",
    characterList: List(),
    type: "paragraph",
    ...data,
  };

  if (partialBlock.type && !ctx.allowedBlockTypes.includes(partialBlock.type)) {
    partialBlock.type = "paragraph";
  }

  /** @type {import('../types').RawBlock} */
  const rawContentBlock = {
    key,
    depth: 0,
    text: partialBlock.text,
    characterList: partialBlock.characterList,
    type: partialBlock.type,
    data: partialBlock.data ? Map(partialBlock.data) : Map(),
  };

  setBlock(new ContentBlock(rawContentBlock), ctx);
};

/**
 * @param {object} params
 * @param {string} params.text
 * @param {string | null} [params.entity]
 * @param {Context} ctx
 */
const injectText = ({ text, entity }, ctx) => {
  if (!ctx.block) {
    throw new Error(`ctx.block is null`);
  }

  const blockText = ctx.block.getText();
  const characterList = ctx.block.getCharacterList();

  const characters = text.split("").map(() =>
    CharacterMetadata.create({
      style: ctx.inlineStyle,
      // @ts-expect-error bug in types
      entity: entity ?? null,
    }),
  );

  const contentBlock = /** @type {import('draft-js').ContentBlock} */ (
    ctx.block.merge({
      text: blockText + text,
      characterList: characterList.push(...characters),
    })
  );

  setBlock(contentBlock, ctx);
};

/**
 * @param {import('hast').Text} node
 * @param {Context} ctx
 */
const injectTextFromNode = (node, ctx) => {
  if (!ctx.block) {
    throw new Error(`ctx.block is null`);
  }
  return injectText({ text: node.value, entity: getNodeEntity(node) }, ctx);
};

/**
 * @param {string[]} inlineStyles
 * @param {Context} ctx
 * @returns {string[]}
 */
const filterInlineStyles = (inlineStyles, { allowedInlineStyles }) => {
  return inlineStyles.filter((style) => allowedInlineStyles.includes(style));
};

/**
 *
 * @param {import('hast').Node} node
 * @param {import('hast').Parent[]} ancestors
 * @param {Context} ctx
 */
const one = (node, ancestors, ctx) => {
  let blockCreated = false;
  /** @type {string[]} */
  let ownedInlineStyles = [];

  if (isHastElement(node)) {
    if (node.tagName === "br") {
      if (ctx.block) {
        //prevent adding multiple line breaks, a block with only a line break is already a new line
        if (!ctx.block.getText().length) return;
        injectText({ text: "\n" }, ctx);
      }
      return;
    }

    // Entity
    ctx.htmlToEntity({
      element: node,
      createEntity: (type, mutability, data) => {
        ctx.contentState = ctx.contentState.createEntity(
          type,
          mutability,
          data,
        );
        const entityKey = ctx.contentState.getLastCreatedEntityKey();
        setNodeEntity(node, entityKey);
        // Set entity to all children
        visit(node, (node) => {
          setNodeEntity(node, entityKey);
        });
      },
      composeWithEntity: (type, data) => {
        const { contentState } = ctx;
        const activeEntityKey = getNodeEntity(node);
        const compositeEntity = (() => {
          if (activeEntityKey) {
            const entity = contentState.getEntity(activeEntityKey);
            if (entity.getType() === "COMPOSITE") {
              return entity;
            }
          }
          return null;
        })();
        const input = { type, ...data };
        input.key = genComposeEntityKey(input);
        ctx.contentState = contentState.createEntity("COMPOSITE", "MUTABLE", {
          entities: [...(compositeEntity?.getData().entities ?? []), input],
        });
        const entityKey = ctx.contentState.getLastCreatedEntityKey();

        setNodeEntity(node, entityKey);
        // Set entity to all children
        visit(node, (node) => {
          setNodeEntity(node, entityKey);
        });
      },
    });

    const entityKey = getNodeEntity(node) ?? null;
    const entity = entityKey ? ctx.contentState.getEntity(entityKey) : null;

    // Block
    const data = ctx.htmlToBlock({
      element: node,
      ancestors,
      entity,
      entityKey,
    });
    if (
      data &&
      (!ctx.block || ctx.orphanBlockKeys.includes(ctx.block.getKey()))
    ) {
      if (data.type === "atomic") {
        if (!entityKey) {
          throw new Error(`Missing entity for type "atomic"`);
        }
        if (!data.data) {
          throw new Error(`Missing data for type "atomic"`);
        }
        data.text = " ";
        data.characterList = List([
          CharacterMetadata.create({
            style: OrderedSet(),
            entity: entityKey,
          }),
        ]);
      }
      createBlock(data, ctx);
      blockCreated = true;
    }

    // Inline styles
    const inlineStyles = filterInlineStyles(
      ctx.htmlToStyle({
        element: node,
        getStyle: () =>
          node.properties?.["style"]
            ? transformStringToObject(node.properties["style"])
            : {},
        entity,
      }),
      ctx,
    );

    // Compute styles that are owned by this node
    ownedInlineStyles = inlineStyles.filter(
      (inlineStyle) => !ctx.inlineStyle.includes(inlineStyle),
    );

    ctx.inlineStyle = /** @type {import('draft-js').DraftInlineStyle} */ (
      ctx.inlineStyle.concat(inlineStyles)
    );
  }

  if (isHastText(node)) {
    if (!ctx.block) {
      createBlock(null, ctx);
    }
    if (ctx.block) {
      ctx.orphanBlockKeys.push(ctx.block.getKey());
    }
    injectTextFromNode(node, ctx);
  }

  if (isHastParent(node)) {
    const index = ancestors.push(node) - 1;
    all(node.children, ancestors, ctx);
    ancestors.splice(index, 1);
  }

  ctx.inlineStyle = /** @type {import('draft-js').DraftInlineStyle} */ (
    ctx.inlineStyle.filter(
      (style) => style !== undefined && !ownedInlineStyles.includes(style),
    )
  );

  if (blockCreated) {
    ctx.block = null;
  }
};

/**
 *
 * @param {import('hast').Node[]} nodes
 * @param {import('hast').Parent[]} ancestors
 * @param {Context} ctx
 */
const all = (nodes, ancestors, ctx) => {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node) {
      one(node, ancestors, ctx);
    }
  }
};

/**
 * @param {import('hast').Root} ast
 * @param {ConvertContext} convertCtx
 * @returns {import('draft-js').ContentState}
 */
export const hastToContentState = (ast, convertCtx) => {
  const contentState = new ContentState({ blockMap: OrderedMap() });

  const ctx = {
    ...convertCtx,
    inlineStyle: OrderedSet(),
    block: null,
    contentState,
    orphanBlockKeys: [],
  };

  one(ast, [], ctx);

  if (ctx.contentState.getBlockMap().size === 0) {
    return ContentState.createFromText("");
  }

  return ctx.contentState;
};
