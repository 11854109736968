import { useMemo } from "react";
import { PageLoader } from "swash/Loader";

import { ConfirmDialog, ConfirmDialogBody } from "@/components/ConfirmDialog";
import { useSafeQuery } from "@/containers/Apollo";

const QueryHandler = ({ node, query, descriptor, children }) => {
  const { data } = useSafeQuery(query, {
    variables: { id: node.id },
  });
  if (!data) return <PageLoader />;
  return children({
    disabled: descriptor.enableDisable.disabled(data),
    disabledMessage: descriptor.enableDisable.disabledMessage({
      node,
      ...data,
    }),
  });
};
const QueryWrapper = ({ query, node, store, descriptor, children }) => {
  const open = store.useState("open");
  if (!query || !node.id || !open)
    return children({ disabled: descriptor.enableDisable.disabled ?? false });
  return (
    <QueryHandler node={node} query={query} descriptor={descriptor}>
      {children}
    </QueryHandler>
  );
};

export function EnableDisableConfirmDialog({
  onConfirm,
  descriptor,
  node,
  store,
  query,
}) {
  const resource = `${descriptor.term.specificArticle} ${descriptor.term.singular}`;
  const identifier = `${resource} « ${node.label} »`;

  const confirmMessage = useMemo(() => {
    switch (typeof descriptor.enableDisable.confirmMessage) {
      case "undefined":
        return `Êtes-vous sûr de vouloir désactiver ${resource} ?`;
      case "function":
        return descriptor.enableDisable.confirmMessage(node);
      default:
        return descriptor.enableDisable.confirmMessage;
    }
  }, [descriptor.enableDisable, node, resource]);

  return (
    <QueryWrapper
      query={query}
      node={node}
      store={store}
      descriptor={descriptor}
    >
      {({ disabled, disabledMessage }) => (
        <ConfirmDialog
          store={store}
          onConfirm={async () => {
            await onConfirm();
          }}
          title={`Désactiver ${identifier}`}
          confirmButtonLabel="Désactiver"
          confirmationText={
            descriptor.enableDisable.level === "critical" && "DESACTIVER"
          }
          disabled={disabled}
        >
          <ConfirmDialogBody>
            {disabled ? disabledMessage : confirmMessage}
          </ConfirmDialogBody>
        </ConfirmDialog>
      )}
    </QueryWrapper>
  );
}
