// @ts-check
import { AFFILIATED_LINK_DATA_TYPE } from "./constants";

const entitiesTextArea = document.createElement("textarea");

/**
 * @param {string} text
 * @returns {string}
 */
const decodeHTMLEntities = (text) => {
  entitiesTextArea.innerHTML = text;
  return entitiesTextArea.value;
};

/** @type {Record<string, string>} */
const STYLE_TAGS = {
  BOLD: "STRONG",
  ITALIC: "EM",
  UNDERLINE: "U",
  SUPERSCRIPT: "SUP",
  SUBSCRIPT: "SUB",
  STRIKETHROUGH: "S",
};

/**
 * Get style element.
 * @param {string} style
 * @returns {HTMLElement | null}
 */
const getStyleElement = (style) => {
  const styleTag = STYLE_TAGS[style];
  if (!styleTag) return null;
  return document.createElement(styleTag);
};

/**
 * Get elements from composite entity.
 * @param {any} composeEntity
 * @returns {HTMLElement | null}
 */
const getCompositeEntityElement = (composeEntity) => {
  switch (composeEntity.type) {
    case "LINK": {
      const { url, affiliate } = composeEntity;
      if (!url) return null;
      const anchor = document.createElement("a");
      anchor.href = url;
      if (affiliate) {
        anchor.dataset["type"] = AFFILIATED_LINK_DATA_TYPE;
      }
      return anchor;
    }
    case "ANCHOR": {
      const { id } = composeEntity;
      if (!id) return null;
      const span = document.createElement("span");
      span.id = id;
      return span;
    }
    default:
      return null;
  }
};

/**
 * @typedef VirtualNode
 * @property {'style' | 'entity'} type
 * @property {string} id
 * @property {any} [data]
 */

/**
 * Convert content to HTML
 * @param {import('draft-js').ContentState} contentState
 */
export const convertToHTML = (contentState) => {
  const block = contentState.getBlockMap().first();
  const text = block.getText();
  const root = document.createElement("div");

  /** @type {HTMLElement} */
  let current = root;

  /** @type {VirtualNode[]} */
  const activeNodes = [];

  const activeNodesMap = {
    /** @type {Record<string, boolean>} */
    style: {},
    /** @type {Record<string, boolean>} */
    entity: {},
  };

  /**
   * @param {VirtualNode} node
   */
  const markNodeAsActive = (node) => {
    activeNodes.push(node);
    activeNodesMap[node.type][node.id] = true;
  };

  /**
   * @param {VirtualNode} node
   */
  const markNodeAsInactive = (node) => {
    activeNodesMap[node.type][node.id] = false;
  };

  /**
   * @param {string} composeEntityKey
   * @returns {boolean}
   */
  const checkIsComposeEntityActive = (composeEntityKey) =>
    Boolean(activeNodesMap.entity[composeEntityKey]);

  /**
   * @param {string} style
   * @returns {boolean}
   */
  const checkIsStyleActive = (style) => Boolean(activeNodesMap.style[style]);

  /**
   *
   * @param {string} [entityKey]
   * @return {any[]}
   */
  const getComposeEntities = (entityKey) => {
    if (!entityKey) return [];
    const entity = contentState.getEntity(entityKey);
    if (entity.getType() !== "COMPOSITE") return [];
    return contentState.getEntity(entityKey).getData().entities ?? [];
  };

  /**
   * @param {import('draft-js').CharacterMetadata} charMeta
   */
  const leaveCharMeta = (charMeta) => {
    if (activeNodes.length === 0) return;

    const hasStyle = charMeta.getStyle().size > 0;
    const composeEntities = getComposeEntities(charMeta.getEntity());

    for (let i = 0; i < activeNodes.length; i++) {
      const activeNode = activeNodes[i];
      const active = (() => {
        switch (activeNode?.type) {
          case "style":
            return hasStyle && charMeta.hasStyle(activeNode.id);
          case "entity":
            return composeEntities.some(({ key }) => key === activeNode.id);
        }
        return false;
      })();
      if (!active && activeNode) {
        leaveNode(activeNode);
      }
    }
  };

  /**
   * @param {import('draft-js').CharacterMetadata} charMeta
   * @param {number} index
   */
  const enterCharMeta = (charMeta, index) => {
    const composeEntities = getComposeEntities(charMeta.getEntity());

    // Enter compose entities
    const hasEntities = composeEntities.length > 0;
    if (hasEntities) {
      composeEntities.forEach((composeEntity) => {
        if (composeEntity && !checkIsComposeEntityActive(composeEntity.key)) {
          const { key: id, ...data } = composeEntity;
          enterNode({ type: "entity", id, data }, index);
        }
      });
    }

    // Enter styles
    const hasStyle = charMeta.getStyle().size > 0;
    if (hasStyle) {
      charMeta.getStyle().forEach((style) => {
        if (style && !checkIsStyleActive(style)) {
          enterNode({ type: "style", id: style }, index);
        }
      });
    }
  };

  /**
   * @param {VirtualNode} node
   */
  const leaveNode = (node) => {
    while (activeNodes.length > 0) {
      const activeNode = activeNodes.pop();
      if (!activeNode) return;
      markNodeAsInactive(activeNode);
      current = /** @type {HTMLElement} */ (current.parentNode) ?? root;
      if (activeNode === node) return;
    }
  };

  /**
   * @param {VirtualNode} node
   * @param {number} index
   */
  const enterNode = (node, index) => {
    const entered = (() => {
      switch (node.type) {
        case "entity":
          return enterEntity(node, index);
        case "style":
          return enterStyle(node.id);
      }
    })();
    if (entered) {
      markNodeAsActive(node);
    }
  };

  /**
   * @param {string} style
   * @returns {boolean}
   */
  const enterStyle = (style) => {
    const element = getStyleElement(style);
    if (!element) return false;
    current.appendChild(element);
    current = element;
    return true;
  };

  /**
   * Climb to the best root for entity.
   * @param {string} composeEntityKey
   * @param {number} index
   */
  const climbEntityRoot = (composeEntityKey, index) => {
    if (current === root) return;
    const charactersList = block.getCharacterList();
    let currentIndex = index;
    let charMeta = charactersList.get(currentIndex);
    while (
      charMeta &&
      getComposeEntities(charMeta.getEntity()).some(
        ({ key }) => key === composeEntityKey,
      )
    ) {
      if (current === root) return;
      leaveCharMeta(charMeta);
      charMeta = charactersList.get(currentIndex++);
    }
  };

  /**
   * @param {VirtualNode} node
   * @param {number} index
   * @returns {boolean}
   */
  const enterEntity = ({ id, data }, index) => {
    const element = getCompositeEntityElement(data);
    if (!element) return false;
    climbEntityRoot(id, index);
    current.appendChild(element);
    current = element;
    return true;
  };

  block.getCharacterList().forEach((charMeta, index) => {
    if (charMeta === undefined || index === undefined) return;

    leaveCharMeta(charMeta);
    enterCharMeta(charMeta, index);

    const char = text.charAt(index);
    current.appendChild(
      char === "\n"
        ? document.createElement("br")
        : document.createTextNode(char),
    );
  });

  return decodeHTMLEntities(root.innerHTML).trim();
};
