import { gql } from "@apollo/client";

import { CardListItemThumbnail } from "@/components/CardListItem";
import { FCWithFragments } from "@/type/utils";

interface SnippetPreviewProps {
  snippet?: {
    id: string;
    screenshotUrl: string;
  };
}

export const SnippetPreview: FCWithFragments<SnippetPreviewProps> = ({
  snippet,
}) => {
  return <CardListItemThumbnail src={snippet?.screenshotUrl} />;
};

SnippetPreview.fragments = {
  snippet: gql`
    fragment SnippetPreview_snippet on Snippet {
      id
      screenshotUrl
    }
  `,
};
