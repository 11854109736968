import type { htmlToEntity as HtmlToEntity } from "../types";

export const ATOMIC_ENTITY_TYPE = "COMPOSITE";
export const COMPOSE_ENTITY_TYPE = "ANCHOR";

export const allowedTagNames = ["span"];

export const allowedAttributes = {
  span: ["id"],
};

export const htmlToEntity: HtmlToEntity = ({ element, composeWithEntity }) => {
  if (element.tagName === "span" && element.properties?.["id"]) {
    composeWithEntity(COMPOSE_ENTITY_TYPE, { id: element.properties["id"] });
  }
};
