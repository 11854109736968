import React, { memo } from "react";
import { Navigate } from "react-router-dom";
import { DialogLayout } from "swash/DialogLayout";
import { IoEyeOff } from "swash/Icon";
import { PanelBody } from "swash/Panel";

import { Backdrop } from "@/components/Backdrop";
import {
  useHasExperimentalFeature,
  useHasLevelAccess,
  useHasPermission,
} from "@/containers/User";
import { UserLevel } from "@/gql-types";

export const NoPermissionsContent = memo<{
  permissions?: string | string[];
}>(({ permissions = [] }) => {
  const permissionsArr =
    typeof permissions === "string" ? [permissions] : permissions;

  return (
    <>
      <IoEyeOff className="mb-4 inline-block" size={27} />
      <h3 className="mb-8 font-accent text-3xl font-semibold">
        Droits insuffisants
      </h3>
      <hr className="mb-8 h-1 w-7 bg-blue-border" />
      <p className="mb-4 font-accent font-semibold">
        Vous ne disposez pas des droits nécessaires pour accéder à ce contenu
      </p>
      {permissionsArr.length > 0 && (
        <p className="mb-8">
          {permissionsArr.length > 1 ? (
            <>
              Les permissions{" "}
              <i>
                {new Intl.ListFormat("fr", {
                  style: "long",
                  type: "conjunction",
                }).format(permissionsArr)}
              </i>{" "}
              sont nécessaires pour y accéder.
            </>
          ) : (
            <>
              La permission <i>{permissionsArr[0]}</i> est nécessaire pour y
              accéder.
            </>
          )}
        </p>
      )}
    </>
  );
});

export const NoPermissions = memo<{
  permissions?: string | string[];
}>(({ permissions = [] }) => {
  return (
    <>
      <Backdrop />
      <DialogLayout style={{ width: 800 }} className="py-8">
        <PanelBody className="flex flex-col items-center justify-center">
          <NoPermissionsContent permissions={permissions} />
        </PanelBody>
      </DialogLayout>
    </>
  );
});

const BlockIfNotEnoughPermissions: React.FC<{
  permissions: string | string[];
  children?: React.ReactNode;
}> = ({ permissions, children }) => {
  const hasPermission = useHasPermission(permissions, { method: "some" });
  if (!hasPermission) {
    return <NoPermissions permissions={permissions} />;
  }

  return <>{children}</>;
};

const BlockIfNotEnoughLevelAccess: React.FC<{
  level: UserLevel;
  children?: React.ReactNode;
}> = ({ level, children }) => {
  const hasLevelAccess = useHasLevelAccess(level);
  if (!hasLevelAccess) {
    return <NoPermissions />;
  }

  return <>{children}</>;
};

const BlockIfNotEnoughLevelAccessOrNotEnoughPermissions: React.FC<{
  level: UserLevel;
  permissions: string | string[];
  children?: React.ReactNode;
}> = ({ level, permissions, children }) => {
  const hasPermission = useHasPermission(permissions, { method: "some" });
  const hasLevelAccess = useHasLevelAccess(level);
  return hasLevelAccess || hasPermission ? <>{children}</> : <NoPermissions />;
};

export const blockIfNotAllowed =
  ({
    level,
    permissions,
    feature,
  }: {
    level?: UserLevel;
    permissions?: string | string[];
    feature?: string | string[];
  }) =>
  <P extends object>(Component: React.FC<P>): React.FC<P> =>
  (props) => {
    // check feature first then level and permissions
    if (feature) {
      const hasFeature = useHasExperimentalFeature(feature);
      if (!hasFeature) {
        return <Navigate to={"/"} />;
      }
    }
    if (level && permissions) {
      return (
        <BlockIfNotEnoughLevelAccessOrNotEnoughPermissions
          level={level}
          permissions={permissions}
        >
          <Component {...props} />
        </BlockIfNotEnoughLevelAccessOrNotEnoughPermissions>
      );
    }

    if (level) {
      return (
        <BlockIfNotEnoughLevelAccess level={level}>
          <Component {...props} />
        </BlockIfNotEnoughLevelAccess>
      );
    }

    if (permissions) {
      return (
        <BlockIfNotEnoughPermissions permissions={permissions}>
          <Component {...props} />
        </BlockIfNotEnoughPermissions>
      );
    }

    return <Component {...props} />;
  };
