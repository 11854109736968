/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, memo } from "react";
import { Button, ButtonScale } from "swash/Button";
import { Tooltip } from "swash/Tooltip";
import {
  EnumSelectList,
  EnumSelectState,
  useEnumSelectState,
} from "swash/v2/EnumSelect";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { SpreadArticleEditorialLevelIcon } from "./ArticleEditorialLevelIcon";

export type EditorialLevel = {
  value: number;
  label: string;
};

export const editorialLevels: EditorialLevel[] = [
  {
    label: "Indice 0",
    value: 0,
  },
  {
    label: "Indice 1",
    value: 1,
  },
  {
    label: "Indice 2",
    value: 2,
  },
  {
    label: "Indice 3",
    value: 3,
  },
];

export type UseArticleDitorialLevelSelectStateProps = {
  value: EditorialLevel;
  onChange: (value: EditorialLevel) => void;
};

export const useArticleEditorialLevelSelectState = (
  props: UseArticleDitorialLevelSelectStateProps,
) => {
  return useEnumSelectState({
    value: props.value,
    onChange: props.onChange,
    items: editorialLevels,
    labelSelector: () => "",
    iconSelector: (level) => {
      return (
        <span className="flex" aria-label={level.label}>
          <SpreadArticleEditorialLevelIcon editorialLevel={level.value} />
        </span>
      );
    },
    valueSelector: (level) => level.value.toString(),
  });
};

export type ArticleEditorialLevelSelectListProps = {
  state: EnumSelectState<EditorialLevel>;
};

export const ArticleEditorialLevelSelectList = (
  props: ArticleEditorialLevelSelectListProps,
) => {
  return <EnumSelectList state={props.state} />;
};

export type EditorialLevelButtonProps = {
  editorialLevel: EditorialLevel;
  scale?: ButtonScale;
  onFocus?: () => void;
  onMouseEnter?: () => void;
};

export const EditorialLevelButton = memo(
  forwardRef<HTMLButtonElement, EditorialLevelButtonProps>(
    ({ editorialLevel, ...props }, ref) => {
      return (
        <Button
          ref={ref}
          variant="secondary"
          appearance="text"
          iconOnly
          aria-label={editorialLevel.label}
          {...props}
        >
          <SpreadArticleEditorialLevelIcon
            editorialLevel={editorialLevel.value}
          />
        </Button>
      );
    },
  ),
);

export type ArticleEdtorialLevelSelectorProps = {
  tooltip?: string | null;
  scale?: ButtonScale;
} & UseArticleDitorialLevelSelectStateProps;

export const ArticleEditorialLevelSelector = ({
  tooltip,
  ...props
}: ArticleEdtorialLevelSelectorProps) => {
  const state = useArticleEditorialLevelSelectState(props);
  const open = state.select.useState("open");

  return (
    <span onClick={(event) => event.stopPropagation()} className="flex">
      <Tooltip tooltip={open ? null : tooltip ?? "Éditer l’indice éditorial"}>
        <SelectButton asChild store={state.select}>
          <EditorialLevelButton editorialLevel={props.value} />
        </SelectButton>
      </Tooltip>
      <SelectPopover store={state.select} modal>
        <ArticleEditorialLevelSelectList state={state} />
      </SelectPopover>
    </span>
  );
};
