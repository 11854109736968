import { TypedDocumentNode, gql } from "@apollo/client";
import { colors } from "swash/colors";

import type { getArticleStatusColorFragment } from "@/gql-types";

export type GetArticleStatusColorArticle = getArticleStatusColorFragment;

export function getArticleStatusColor(
  article: GetArticleStatusColorArticle,
): string {
  if (!article.initialFirstPublished) return colors.grey.bg.strong;
  if (article.lastPublishedHidden) return colors.danger.bg.strong;
  if (article.isDirty) return colors.warning.bg.strong;
  return colors.success.bg.strong;
}

const getArticleStatusColorFragment: TypedDocumentNode<getArticleStatusColorFragment> = gql`
  fragment getArticleStatusColor on Article {
    initialFirstPublished
    isDirty
    lastPublishedHidden
  }
`;

getArticleStatusColor.fragment = getArticleStatusColorFragment;
