export function sortByDate(time1, time2, mode = "desc") {
  switch (mode) {
    case "desc":
      if (new Date(time1) > new Date(time2)) return -1;
      if (new Date(time1) < new Date(time2)) return 1;
      return 0;
    case "asc":
      if (new Date(time1) < new Date(time2)) return -1;
      if (new Date(time1) > new Date(time2)) return 1;
      return 0;
    default:
      return 0;
  }
}
