// @ts-check
import { getConvertContext } from "./convertContext";
import { hastToContentState } from "./hastToContentState";
import { parseHtmlToAst } from "./parseHtmlToAst";

/** @typedef {'paste' | 'simple'} Mode */
/** @typedef {import('./convertContext').Context} Context */

/**
 * @param {string} html
 * @param {Mode} mode
 * @param {Context} ctx
 * @returns {import('hast').Root}
 */
const convertHTMLIntoAst = (html, mode, ctx) => {
  let ast = parseHtmlToAst(html, {
    allowedAttributes: ctx.allowedAttributes,
    transformTags: ctx.transformTags,
  });

  if (mode === "paste") {
    ast = ctx.transformPastedHTML(ast);
  }

  return ast;
};

/**
 * Convert HTML into AST
 * @param {string} html
 * @param {object[]} plugins
 * @param {Mode} mode
 * @returns {import('hast').Root}
 */
export const convertFromHTMLToAst = (html, plugins, mode) => {
  const ctx = getConvertContext(plugins);
  return convertHTMLIntoAst(html, mode, ctx);
};

/**
 * Convert HTML into Draft Content State
 * @param {string} html
 * @param {object[]} plugins
 * @param {Mode} mode
 * @returns {import('draft-js').ContentState}
 */
export const convertFromHTML = (html, plugins, mode) => {
  const ctx = getConvertContext(plugins);
  ctx.mode = mode;
  const ast = convertHTMLIntoAst(html, mode, ctx);
  return hastToContentState(ast, ctx);
};
