import { gql } from "@apollo/client";

import type { ArticleTitle_articleFragment } from "@/gql-types";

export const getArticleTitle = (title?: string | null) => {
  return title ?? "Sans titre";
};

export type SpreadArticleTitleProps = {
  title?: string | null;
};

export const SpreadArticleTitle: React.FC<SpreadArticleTitleProps> = (
  props,
) => {
  return <>{getArticleTitle(props.title)}</>;
};

export type ArticleTitleProps = {
  article: ArticleTitle_articleFragment;
};

export const ArticleTitle = (props: ArticleTitleProps) => {
  return <SpreadArticleTitle title={props.article.title} />;
};

ArticleTitle.fragments = {
  article: gql`
    fragment ArticleTitle_article on Article {
      id
      title
    }
  `,
};
