import { gql } from "@apollo/client";

import { ArticleNodeFragments } from "@/containers/editor/nodes/ArticleNodeFragments";
import { CustomTypeContentNode } from "@/containers/editor/nodes/CustomTypeContentNode";
import { ImageNodeFragment } from "@/containers/editor/nodes/ImageNodeFragment";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";
import { SignaturesFieldFragment } from "@/containers/fields/SignaturesFieldFragments";
import { LivePostStatusPostFragment } from "@/containers/live/LivePostStatus";
import { ContributionNodeFragment } from "@/containers/live/postNodes/ContributionNodeFragment";

import { LivePostCorrected } from "./LivePostCorrected";
import { LivePostPublishedLink } from "./LivePostPublishedLink";
import { LivePostEditorPostFragment } from "./editor/EditorFragment";

const LivePostDisplayPostFragment = gql`
  fragment LivePostDisplayPostFragment on Post {
    id
    title
    nodes {
      id
      type
      ... on EditorNodeText {
        text
      }
      ... on EditorNodeArticle {
        article {
          id
          ...ArticleNode_article
        }
      }
      ... on EditorNodeContribution {
        contribution {
          id
          ...ContributionNode_contribution
        }
      }
      ... on EditorNodeTweet {
        tweet {
          id
          ...TweetNode_tweet
        }
      }
      ... on EditorNodeImage {
        image {
          id
          ...ImageNode_image
        }
      }
      ... on EditorNodeVideo {
        video {
          id
          ...VideoNode_video
        }
      }
      ... on EditorNodeSnippet {
        snippet {
          id
          ...SnippetNode_snippet
        }
      }
      ... on EditorNodeCustomTypesContent {
        customTypesContentsId
        customTypesContent {
          id
          ...CustomTypeContentNode_customTypeContent
        }
      }
    }
    flag {
      id
      label
    }
    signatures {
      id
      ...SignaturesField_signatures
    }
    ...LivePostStatusPostFragment
    ...LivePostPublishedLink_post
    ...LivePostCorrected_post
  }

  ${ArticleNodeFragments.article}
  ${ContributionNodeFragment}
  ${TweetNode.fragments.tweet}
  ${ImageNodeFragment.image}
  ${VideoNode.fragments.video}
  ${SnippetNodeFragments.snippet}
  ${SignaturesFieldFragment}
  ${LivePostStatusPostFragment}
  ${CustomTypeContentNode.fragments.content}
  ${LivePostPublishedLink.fragments.post}
  ${LivePostCorrected.fragments.post}
`;

export const LivePostFragment = gql`
  fragment LivePostFragment on Post {
    id
    globalId
    updatedAt
    public
    published
    firstPublishedAt
    deleted
    liveId # Used in LivePostList — @TODO use collocated fragments
    ...LivePostEditorPostFragment
    ...LivePostDisplayPostFragment
  }
  ${LivePostEditorPostFragment}
  ${LivePostDisplayPostFragment}
`;

export const CreatePostMutation = gql`
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      ...LivePostFragment
    }
  }
  ${LivePostFragment}
`;

export const UpdatePostMutation = gql`
  mutation UpdatePostMutation($input: UpdatePostInput!) {
    updatePost(input: $input) {
      id
      ...LivePostFragment
    }
  }
  ${LivePostFragment}
`;
