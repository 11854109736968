import { gql } from "@apollo/client";

import {
  ArticlePublishStatus,
  ArticlePublishStatusProps,
} from "@/containers/article/ArticlePublishStatus";
import {
  ArticleStatesTooltip,
  ArticleStatesTooltipProps,
} from "@/containers/article/ArticleStatesTooltip";
import { ArticleStateIcon } from "@/containers/article/state/ArticleStateIcon";

const ArticleState = (props: ArticleStatePublishProps) => {
  const [state] = props.article.states;
  if (!state) return null;
  return (
    <ArticleStatesTooltip article={props.article}>
      <ArticleStateIcon className="text-lg" state={state} />
    </ArticleStatesTooltip>
  );
};

export type ArticleStatePublishProps = {
  article: ArticlePublishStatusProps["article"] &
    ArticleStatesTooltipProps["article"];
};

export const ArticleStatePublish = (props: ArticleStatePublishProps) => {
  return (
    <div className="flex items-center gap-2">
      <ArticleState article={props.article} />
      <ArticlePublishStatus article={props.article} />
    </div>
  );
};

ArticleStatePublish.fragments = {
  article: gql`
    fragment ArticleStatePublish_article on Article {
      ...ArticleStatesTooltip_article
      ...ArticlePublishStatus_article
    }

    ${ArticlePublishStatus.fragments.article}
    ${ArticleStatesTooltip.fragments.article}
  `,
};
