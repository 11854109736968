import { ApolloError } from "@apollo/client";

/**
 * @param {any} error
 * @returns {error is ApolloError}
 */
export const checkIsApolloError = (error) => {
  return error instanceof ApolloError;
};

/**
 * Check if the error is a network error with a status code.
 * @param {any} error
 * @param {number} statusCode
 * @returns {boolean}
 */
export const checkIsNetworkError = (error, statusCode) => {
  const errorStatusCode = error?.statusCode;
  return errorStatusCode !== undefined && errorStatusCode === statusCode;
};

/**
 * Check if the error is a network error offline.
 * @param {any} error
 * @returns {boolean}
 */
export const checkIsOfflineError = (error) => {
  return Boolean(
    error &&
      error.statusCode === undefined &&
      error.message === "Failed to fetch",
  );
};

/**
 * Check if the some graphql errors are elasticsearch timeout errors.
 * @param {import("@apollo/client/errors").GraphQLErrors} [graphQLErrors]
 * @returns {boolean}
 */
export const checkHasTimeoutError = (graphQLErrors) => {
  return Boolean(
    graphQLErrors?.length &&
      graphQLErrors.some((error) =>
        error.message.includes("Request timed out"),
      ),
  );
};
