import { gql } from "@apollo/client";
import { Link } from "swash/Link";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment TwitterIntegrationConfigFragment on TwitterIntegrationConfig {
    consumerKey
    consumerSecret
    accessToken
    accessTokenSecret
    linkQueryParams
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="consumerKey" label="Consumer Key" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="consumerSecret" label="Consumer Secret" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="accessToken" label="Access Token" required />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="accessTokenSecret"
          label="Access Token Secret"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="linkQueryParams"
          label="Link parameters"
          hint={
            <>
              You can use{" "}
              <Link href="https://lodash.com/docs#template" target="_blank">
                template lodash
              </Link>{" "}
              with following variables: <code>article, alert</code>
            </>
          }
          required
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
