import { gql } from "@apollo/client";
import { memo, useCallback } from "react";

import {
  ArticleDescription,
  ArticleDescriptionProps,
} from "@/containers/article/ArticleDescription";
import type { CellType } from "@/containers/list/ListState";
import { useSetValues } from "@/containers/search/SearchFormContext";

type ArticleDescriptionCellProps = {
  article: ArticleDescriptionProps["article"];
  showSignature?: boolean;
  showEditorialLevel?: boolean;
  showPublicationDate?: boolean;
  showArticleDateIndicator?: boolean;
  /** @default true */
  multi?: boolean;
};

const ArticleDescriptionCell = memo<ArticleDescriptionCellProps>(
  ({ multi = true, ...props }) => {
    const setFilters = useSetValues();
    const handleNarrowSectionClick = useCallback(
      (narrowSection: { id: number }) => {
        if (!setFilters) return;
        setFilters((filters: any) => ({
          ...filters,
          ...(multi
            ? { tagId: { in: [narrowSection.id] } }
            : { tagId: { eq: narrowSection.id } }),
        }));
      },
      [multi, setFilters],
    );

    return (
      <ArticleDescription
        article={props.article}
        onNarrowSectionClick={setFilters ? handleNarrowSectionClick : undefined}
        showSignature={props.showSignature}
        showEditorialLevel={props.showEditorialLevel}
        showPublicationDate={props.showPublicationDate}
        showArticleDateIndicator={props.showArticleDateIndicator}
      />
    );
  },
);

export type DescriptionCellArticle = ArticleDescriptionProps["article"];

export const descriptionCell: CellType<DescriptionCellArticle> = {
  key: "description",
  title: "Description",
  width: "1fr",
  minWidth: 280,
  render: ({ node }) => {
    return <ArticleDescriptionCell article={node} showEditorialLevel={false} />;
  },
  fragment: gql`
    fragment descriptionCell_article on Article {
      ...ArticleDescription_article
    }

    ${ArticleDescription.fragments.article}
  `,
};

export const mediasDescriptionCell: CellType<DescriptionCellArticle> = {
  ...descriptionCell,
  render: ({ node }) => {
    return (
      <ArticleDescriptionCell
        article={node}
        showSignature={true}
        showEditorialLevel={true}
      />
    );
  },
};

type InsightsDescriptionCellArticle = {
  article: ArticleDescriptionProps["article"];
};

export const insightsDescriptionCell: CellType<InsightsDescriptionCellArticle> =
  {
    ...descriptionCell,
    render: ({ node }) => {
      return (
        <ArticleDescriptionCell
          article={node.article}
          showEditorialLevel={false}
          showPublicationDate={true}
          multi={false}
        />
      );
    },
  };

export const dateIndicatorDescriptionCell: CellType<DescriptionCellArticle> = {
  ...descriptionCell,
  render: ({ node }) => {
    return (
      <ArticleDescriptionCell
        article={node}
        showArticleDateIndicator
        showEditorialLevel={false}
      />
    );
  },
};
