import { clsx } from "clsx";
import * as React from "react";

export const EmptyState = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div
    className={clsx(
      className,
      "flex flex-col items-center gap-2 px-4 pb-6 pt-16 text-center font-accent @container",
    )}
    {...props}
  />
);

export const EmptyStatePicture = ({
  className,
  ...props
}: React.ComponentProps<"img">) => (
  <img width={32} height={32} className={clsx(className, "mb-4")} {...props} />
);

export const EmptyStateTitle = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div className={clsx(className, "font-bold @xl:text-xl")} {...props} />
);

export const EmptyStateText = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div className={clsx(className, "text-base")} {...props} />
);

export const EmptyStateAction = "div";
