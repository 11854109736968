import { x } from "@xstyled/styled-components";
import { EditorState } from "draft-js-es";
import { memo, useCallback } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { RedoLight, UndoLight } from "@/components/icons";

import { RichEditorToolbarButton } from "../RichEditorToolbar";

export const name = "history-control";

export const shortcuts = [
  {
    description: "Annuler",
    shortcut: ["⌘", "z"],
    category: "Général",
  },
  {
    description: "Rétablir",
    shortcut: ["⌘", "⇧", "z"],
    category: "Général",
  },
];

export const matchBlock = () => true;

const UndoToolbarButton = memo(({ disabled, onToggle }) => {
  return (
    <RichEditorToolbarButton
      onMouseDown={(event) => {
        event.preventDefault();
        onToggle();
      }}
      disabled={disabled}
      label="Annuler"
      shortcut={["⌘", "z"]}
    >
      <UndoLight />
    </RichEditorToolbarButton>
  );
});

const RedoToolbarButton = memo(({ disabled, onToggle }) => {
  return (
    <RichEditorToolbarButton
      onMouseDown={(event) => {
        event.preventDefault();
        onToggle();
      }}
      disabled={disabled}
      label="Rétablir"
      shortcut={["⌘", "⇧", "z"]}
    >
      <RedoLight />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = (props) => {
  const propsRef = useLiveRef(props);
  const handleUndo = useCallback(() => {
    const { setEditorState, editorState } = propsRef.current;
    setEditorState(EditorState.undo(editorState));
  }, [propsRef]);
  const handleRedo = useCallback(() => {
    const { setEditorState, editorState } = propsRef.current;
    setEditorState(EditorState.redo(editorState));
  }, [propsRef]);
  return (
    <x.div display="flex" columnGap={props.columnGap}>
      <UndoToolbarButton
        disabled={props.editorState.getUndoStack().size === 0}
        onToggle={handleUndo}
      />
      <RedoToolbarButton
        disabled={props.editorState.getRedoStack().size === 0}
        onToggle={handleRedo}
      />
    </x.div>
  );
};

BlockControls.group = "history";
BlockControls.buttonsCount = 2;
