import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useToaster } from "swash/Toast";
import { useLiveRef } from "swash/utils/useLiveRef";

import { SpellCheck } from "@/components/icons";
import { useIntegrationHealthCheck } from "@/components/rich-editor/utils/IntegrationHealthCheck";
import { useProlexis } from "@/containers/Prolexis";

import { RichEditorToolbarButton } from "../../RichEditorToolbar";
import { useSpellCheck } from "./SpellCheckPluginContext";
import { applyProlexisCorrections, getEditorStateContent } from "./helpers";

export const name = "spell-check-control";
export const label = "Corriger le texte";

const SpellCheckToolbarButton = memo(({ as, getState }) => {
  const { isHealthy, loading } = useIntegrationHealthCheck("prolexis");
  const { getEditors } = useSpellCheck();
  const [correcting, setCorrecting] = useState(false);
  const { analyze } = useProlexis();
  const toaster = useToaster();
  const buttonRef = useRef();

  const analyzeSpelling = () => {
    const editors = getEditors();
    const state = getState();

    // Start correcting
    setCorrecting(true);
    editors.forEach((editor) => {
      if (state.hasFocus && editor.name === state.name) {
        editor.lockFocus();
      }
    });

    analyze({
      fields: editors.map(({ getContent, label }) => ({
        src: getContent(),
        type: "string",
        label,
      })),
      onCorrections: (corrections) => {
        editors.forEach((editor, index) => {
          const editorCorrections = corrections.filter(
            (correction) => correction.elementIndex === index,
          );
          if (editorCorrections.length) {
            editor.correct({ corrections: editorCorrections });
          }
        });
      },
      parent: buttonRef.current,
    })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        toaster.danger(
          "L’outil de correction est indisponible pour le moment. Veuillez rafraîchir votre navigateur et réessayer.",
        );
      })
      .finally(() => {
        // Stop correcting
        editors.forEach((editor) => {
          editor.unlockFocus();
        });
        setCorrecting(false);
      });
  };

  const defaultProps = {
    disabled: correcting,
    onKeyDown: (event) => {
      if (event.key === "Enter") {
        analyzeSpelling();
      }
    },
    onMouseDown: (event) => {
      event.preventDefault();
      analyzeSpelling();
    },
    label,
  };

  return (
    <RichEditorToolbarButton
      {...defaultProps}
      as={as}
      ref={buttonRef}
      disabledTooltipLabel={
        isHealthy
          ? undefined
          : "Le service tiers Prolexis rencontre actuellement un incident et nous indique qu’il est actuellement indisponible."
      }
      disabled={loading || !isHealthy}
    >
      <SpellCheck />
    </RichEditorToolbarButton>
  );
});

export const ButtonCommand = SpellCheckToolbarButton;

export const BlockControls = (state) => {
  const stateRef = useLiveRef(state);
  const getState = useCallback(() => stateRef.current, [stateRef]);

  return <SpellCheckToolbarButton getState={getState} />;
};

BlockControls.group = "spell-check";

export const usePluginProps = (state) => {
  const { registerEditor } = useSpellCheck();
  const stateRef = useLiveRef(state);

  useEffect(() => {
    const { name, label, lockFocus, unlockFocus, setEditorState } =
      stateRef.current;

    return registerEditor({
      name,
      label,
      lockFocus,
      unlockFocus,
      getContent: () => getEditorStateContent(stateRef.current.editorState),
      correct: ({ corrections }) => {
        setEditorState((editorState) =>
          applyProlexisCorrections(editorState, corrections),
        );
      },
    });
  }, [stateRef, registerEditor]);

  return {};
};
