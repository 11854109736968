import { createContext, useContext, useMemo } from "react";

/** @typedef {"notes" | "text"} Scope */

/**
 * @type {React.Context<Scope>}
 */
const CommentScope = createContext();

export const useCommentScope = () => useContext(CommentScope);

export function CommentScopeProvider(
  /** @type {{ scope: Scope, children: React.ReactNode }} */ {
    scope,
    children,
  },
) {
  const value = useMemo(() => scope, [scope]);
  return (
    <CommentScope.Provider value={value}>{children}</CommentScope.Provider>
  );
}
