import { memo } from "react";
import { Anchor as AnchorIcon } from "swash/Icon";

import { RichEditorToolbarButton } from "../RichEditorToolbar";
import type { State } from "../utils/PluginPopover";
import { checkIsActive, command } from "./anchor";

export const name = "anchor-control";

const AnchorToolbarButton = memo(({ active }: { active: boolean }) => {
  return (
    // @ts-expect-error use a js component
    <RichEditorToolbarButton active={active} label="Ancre" command={command}>
      <AnchorIcon />
    </RichEditorToolbarButton>
  );
});

export const BlockControls = (state: State) => {
  return <AnchorToolbarButton active={checkIsActive(state)} />;
};

BlockControls.group = "inline-style";
