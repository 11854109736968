import * as React from "react";
import { forwardRef } from "react";

const SvgHistory = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M256 184v73.379L325 327"
      stroke="currentColor"
      strokeWidth={32}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M425 353.999a195.777 195.777 0 0 1-71.597 71.742 195.296 195.296 0 0 1-97.8 26.259 195.31 195.31 0 0 1-97.802-26.259 195.79 195.79 0 0 1-71.595-71.742A196.304 196.304 0 0 1 60 256a196.31 196.31 0 0 1 26.206-98A195.796 195.796 0 0 1 157.8 86.259 195.303 195.303 0 0 1 255.603 60"
      stroke="currentColor"
      strokeWidth={32}
      strokeLinecap="round"
    />
    <path
      d="M256 60c108.248 0 196 87.691 196 195.863 0 34.12-8.731 66.202-24.081 94.137"
      stroke="currentColor"
      strokeWidth={32}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="20 80"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHistory);
export default ForwardRef;
