/**
 * @typedef {'loading' | 'watching' | 'ignored' } WatchStatus
 */

/**
 * @param {import('../lock/LockApi').LockApi} watchApi
 * @returns {WatchStatus}
 */
export function useWatchStatus(watchApi) {
  if (watchApi.loading) {
    return "loading";
  }
  if (!watchApi.info || watchApi.info.ignored) {
    return "ignored";
  }
  return "watching";
}
