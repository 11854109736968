import { gql } from "@apollo/client";
import { memo } from "react";
import { Tooltip } from "swash/Tooltip";

import { NamedIcon } from "@/components/NamedIcon";

export type SpreadTagLabelProps = {
  path: string;
};

export const SpreadTagLabel = memo((props: SpreadTagLabelProps) => {
  const paths = props.path.split(">").reverse();
  const title = paths[0]?.trim();
  const head = paths[1]?.trim();
  return (
    <>
      {title}
      {head ? (
        <span className="text-grey-on">
          {" "}
          <span className="font-sans">←</span>&nbsp;{head}
        </span>
      ) : null}
    </>
  );
});

export type TagLabelProps = {
  tag: {
    path: string;
  };
};

export type TagIconProps = {
  tagType: {
    icon: string;
    label: string;
  };
};

export const TagLabel = (props: TagLabelProps) => {
  return <SpreadTagLabel path={props.tag.path} />;
};

export const TagIcon = (props: TagIconProps) => {
  return (
    <Tooltip tooltip={props.tagType.label}>
      <div>
        <NamedIcon name={props.tagType.icon} />
      </div>
    </Tooltip>
  );
};

TagLabel.fragments = {
  tag: gql`
    fragment TagLabel_tag on Tag {
      path
    }
  `,
};

TagIcon.fragments = {
  tagType: gql`
    fragment TagIcon_tagType on ModernTagType {
      icon
      label
    }
  `,
};
