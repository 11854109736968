import * as React from "react";
import { forwardRef } from "react";

const SvgSparkles = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m12.184 12.324-2.04-5.304a.422.422 0 0 0-.788 0l-2.04 5.304a.422.422 0 0 1-.242.242l-5.304 2.04a.422.422 0 0 0 0 .788l5.304 2.04a.421.421 0 0 1 .242.242l2.04 5.304a.421.421 0 0 0 .788 0l2.04-5.304a.42.42 0 0 1 .242-.242l5.304-2.04a.421.421 0 0 0 0-.788l-5.304-2.04a.42.42 0 0 1-.242-.242ZM5.063 3.188 4.125.75l-.938 2.438-2.437.937 2.438.938.937 2.437.938-2.438L7.5 4.125l-2.438-.938ZM20 5.5l-1.25-3.25L17.5 5.5l-3.25 1.25L17.5 8l1.25 3.25L20 8l3.25-1.25L20 5.5Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSparkles);
export default ForwardRef;
