import styled from "@xstyled/styled-components";
import { createContext, useContext, useState } from "react";

const ToggleContext = createContext();
const VisibleContext = createContext();

const Highlighter = styled.div`
  display: contents;

  [data-space-highlight] {
    position: relative;
  }
  [data-space-highlight="space"] {
    opacity: 0.2;
    background-color: text;
  }
  [data-space-highlight="no-break-space"] {
    background-color: red-darker;
    opacity: 0.9;
  }
  [data-space-highlight="narrow-no-break-space"] {
    background-color: blue-darker;
    opacity: 0.9;
  }
  [data-space-highlight]:before {
    content: " ";
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 3px;
  }
`;

export const useToggleInvisibleCharacter = () => useContext(ToggleContext);
export const useInvisibleCharacterVisible = () => useContext(VisibleContext);

export const InvisibleCharactersProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);

  return (
    <ToggleContext.Provider value={setVisible}>
      <VisibleContext.Provider value={visible}>
        <Highlighter>{children}</Highlighter>
      </VisibleContext.Provider>
    </ToggleContext.Provider>
  );
};
