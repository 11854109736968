import { EditorState } from "draft-js-es";

import { CompoundDecorator } from "../utils/CompoundDecorator";

/**
 * Remove decorator.
 * @param {import('draft-js').EditorState} editorState
 * @param {import('draft-js').DraftDecorator} decorator
 */
export function removeDecorator(editorState, decorator) {
  const decorators = editorState.getDecorator()?.getDecorators() ?? [];
  return EditorState.set(editorState, {
    decorator: new CompoundDecorator(decorators.filter((d) => d !== decorator)),
  });
}
