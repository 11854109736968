import type { ContentState } from "draft-js-es";
import type { ReactElement, ReactNode } from "react";
import { cn } from "swash/utils/classNames";

import { useUser } from "@/containers/User";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";

export interface MentionProps {
  children: ReactNode;
  className: string;
  entityKey: string;
  contentState: ContentState;
}

export const Mention = ({
  entityKey,
  contentState,
  className,
  children,
}: MentionProps): ReactElement => {
  const mention = contentState.getEntity(entityKey).getData().mention;
  const user = useUser();
  return (
    <UserHoverCardTooltip user={mention}>
      <span
        className={cn(
          "rounded px-1 font-semibold",
          mention?.id
            ? mention.id === user.id
              ? "bg-orange-bg-light text-orange-on-strong"
              : "bg-primary-bg-light text-primary-on-strong"
            : "bg-secondary-bg-light text-secondary-on-strong",
          className,
        )}
        spellCheck={false}
      >
        {children}
      </span>
    </UserHoverCardTooltip>
  );
};
