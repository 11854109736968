import { gql } from "@apollo/client";
import { Caption } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { useResourceUsedIn } from "@/containers/admin/CRUD/usages/articles";
import type { CellType } from "@/containers/list/ListState";

type Media = {
  id: number;
  type: string;
  metadata: { caption?: string };
  media: { id: number };
};

type ArticleImageCaptionCellProps = {
  article: {
    id: number;
    medias: {
      nodes: Media[];
    };
  };
};

const ArticleImageCaptionCell = (props: ArticleImageCaptionCellProps) => {
  const resource = useResourceUsedIn();
  const articleMedia = props.article.medias?.nodes.find(
    (node) => node.type === "images" && node.media.id === resource?.id,
  );

  const caption = articleMedia?.metadata?.caption;

  return (
    <div className="flex justify-center text-secondary-on">
      {caption && (
        <Tooltip
          tooltip={<div className="max-w-sm">{caption}</div>}
          placement="top-end"
        >
          <div className="inline-flex">
            <Caption />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
ArticleImageCaptionCell.fragments = {
  article: gql`
    fragment ArticleImageCaptionCell_article on Article {
      id
      medias(where: { type: { eq: images } }) {
        nodes {
          id
          type
          metadata
          media {
            id
          }
        }
      }
    }
  `,
};

export type CaptionCellArticle = ArticleImageCaptionCellProps["article"];

export const captionCell: CellType<CaptionCellArticle> = {
  key: "caption",
  title: "Légende",
  width: 72,
  render: ({ node }) => (
    <ArticleImageCaptionCell key={node.id} article={node} />
  ),
  fragment: gql`
    fragment captionCell_article on Article {
      ...ArticleImageCaptionCell_article
    }

    ${ArticleImageCaptionCell.fragments.article}
  `,
};
