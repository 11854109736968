import { KeyBindingUtil } from "draft-js-es";

export const MAC_OS = KeyBindingUtil.usesMacOSHeuristics();

// The one in Draft.js says it should not have "alt"
export const hasCommandModifier = (e) => {
  return Boolean(MAC_OS ? e.metaKey : e.ctrlKey);
};

const DIGIT_REGEX = /^[1-9]$/;
const checkIsDigit = (str) => DIGIT_REGEX.test(str);

/**
 * @param {string[]} shortcut
 * @param {string} command
 * @param {any} event
 */
export const handleBinding = (shortcut, command, event) => {
  let altOrUpper = false;

  const match = shortcut.every((key) => {
    if (key === "⌘") return hasCommandModifier(event);
    if (key === "Ctrl") {
      return event.ctrlKey;
    }
    if (key === "⌥") {
      altOrUpper = true;
      return event.altKey;
    }
    if (key === "⇧") {
      altOrUpper = true;
      return event.shiftKey;
    }

    if (key === " ") {
      return event.code === "Space";
    }

    if (altOrUpper || checkIsDigit(key)) {
      return (
        event.key === key ||
        event.code === `Digit${key}` ||
        event.code === `Key${key.toUpperCase()}`
      );
    }

    return event.key === key;
  });

  return match ? command : undefined;
};

/**
 * @param {import('draft-js').EditorState} editorState
 * @param {string} blockType
 */
export const checkIsBlockActive = (editorState, blockType) => {
  const selection = editorState.getSelection();
  const block = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey());
  if (!block) return false;

  const hasStyle = Boolean(block.getData().get("styleName"));
  return !hasStyle && block.getType() === blockType;
};
