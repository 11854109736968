import { gql } from "@apollo/client";
import { Link } from "swash/Link";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import {
  RadioField,
  RadioGroupField,
  useRadioField,
  useRadioGroupField,
} from "@/components/fields/RadioField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { ArrayField, StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment BatchIntegrationConfigFragment on BatchIntegrationConfig {
    devApiKey
    liveApiKey
    restApiKey
    mode
    tags
    payload
  }
`;

function getIntegrationType(name) {
  return name.startsWith(`lmm-`) ? "lmm" : "alert";
}

function formatPayloadHint(name) {
  const integrationType = getIntegrationType(name);

  switch (integrationType) {
    case "alert":
      return {
        customHint: "Available variables for alerts:",
        code: `
interface AlertData {
  _s: UnderscoreStringLibrary; // https://github.com/esamattis/underscore.string
  _: LodashLibrary; // https://lodash.com
  moment: MomentLibrary; // https://momentjs.com
  jwt: JWTLibrary; // https://github.com/auth0/node-jsonwebtoken
  URL: URLClass; // https://nodejs.org/api/url.html#url_new_url_input_base
  alert: { // Alert sent
    id: number; // Sirius ID
    frontId: number; // Remote system ID
    title: string;
    text: string;
  };
  article: { // Article relative to alert
    id: number; // Sirius ID
    frontId: number; // Remote system ID
    layout: { // Article layout
      name: string;
    };
    universe: 'brand' | 'editorial';
    publicationUrl: string;
    targetUrl: string;
    editorialType: {
      internalId: number;
      key: string;
      name: string;
    };
  }
  getFeatureImageUrl: ({ aspectRatioKey?: string, width?: number, height?: number }) => string | null;
}`.trim(),
      };

    case "lmm":
      return {
        customHint: "Available variables for Matinale:",
        code: `
interface AlertData {
  _s: UnderscoreStringLibrary; // https://github.com/esamattis/underscore.string
  _: LodashLibrary; // https://lodash.com
  moment: MomentLibrary; // https://momentjs.com
  jwt: JWTLibrary; // https://github.com/auth0/node-jsonwebtoken
  URL: URLClass; // https://nodejs.org/api/url.html#url_new_url_input_base
  edition: {
    isFirstPublication: Bool
  }
}
`.trim(),
      };

    default:
      return {};
  }
}

function formatValues(config) {
  if (!config) {
    return {
      name: "",
      devApiKey: "",
      liveApiKey: "",
      restApiKey: "",
      mode: "dev",
      tags: [],
      payload: "",
    };
  }

  return {
    name: config.name,
    devApiKey: config.devApiKey,
    liveApiKey: config.liveApiKey,
    restApiKey: config.restApiKey,
    mode: config.mode,
    tags: config.tags,
    payload: config.payload,
  };
}

function ModeField() {
  const field = useRadioGroupField("mode", { required: true });
  const devField = useRadioField(field, { value: "dev" });
  const liveField = useRadioField(field, { value: "live" });
  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>Mode</FieldLabel>
      <FieldError {...field} />
      <RadioGroupField {...field}>
        <RadioField {...devField}>Development</RadioField>
        <RadioField {...liveField}>Live</RadioField>
      </RadioGroupField>
    </FieldGroup>
  );
}

export function validateTags(tags) {
  if (!tags?.length) return undefined;

  const tag = tags.find((tag) => !/^[a-zA-Z0-9_-]+$/.test(tag));
  if (tag) {
    return "La valeur saisie est invalide. Pour un tag, seuls les lettres, chiffres, tirets et underscores sont autorisés.";
  }

  return undefined;
}

function TagsField() {
  const integrationName = useSubscribeFormValue("name");
  const integrationType = getIntegrationType(integrationName);
  if (integrationType === "lmm") {
    return null;
  }

  return (
    <ArrayField
      name="tags"
      label="Tags"
      hint="Espacer les tags par une virgule, exemple : Web, Mobile"
      validate={validateTags}
    />
  );
}

export function PayloadField({ label }) {
  const integrationName = useSubscribeFormValue("name");
  const { customHint, code } = formatPayloadHint(integrationName);
  return (
    <StringField
      name="payload"
      multiline
      rows="12"
      label={label}
      hint={
        <>
          A JavaScript expression that should return the payload. {customHint}
          <pre>
            <code>{code}</code>
          </pre>
        </>
      }
    />
  );
}

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="name" label="Name" required />
      </FieldsetField>
      <FieldsetField>
        <ModeField />
      </FieldsetField>
      <FieldsetField>
        <StringField name="devApiKey" label="Dev API Key" required />
      </FieldsetField>
      <div className="flex">
        <div className="flex-1">
          <FieldsetField>
            <StringField name="liveApiKey" label="Live API Key" required />
          </FieldsetField>
        </div>
        <div className="flex-1">
          <FieldsetField>
            <StringField name="restApiKey" label="REST API Key" required />
          </FieldsetField>
        </div>
      </div>
      <FieldsetField>
        <TagsField />
      </FieldsetField>
      <FieldsetField>
        <PayloadField
          label={
            <>
              <Link
                href="https://doc.batch.com/api/campaigns/create"
                target="_blank"
              >
                Create Campaign API
              </Link>
              {" - "}
              Body Payload
            </>
          }
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields, formatValues });
