import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDialogApi } from "swash/Dialog";

import { injectScript, removeScript } from "@/components/Script";

const SCRIPT_VERSION = "4.0.0";
const SCRIPT_SRC = `/assets/vendors/prolexis@${SCRIPT_VERSION}/DiagPlWs.js`;

const ProlexisContext = createContext();

const createBatcher = (callback) => {
  let values = [];
  let timeout;
  return (value) => {
    values.push(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(values);
      values = [];
    });
  };
};

export const ProlexisProvider = ({ children }) => {
  const handlersRef = useRef({});
  useEffect(() => () => removeScript(SCRIPT_SRC), []);

  const { forceOutsideDialogInteractions } = useDialogApi();

  const analyze = useCallback(
    async ({ fields, onCorrections, parent = document.body }) => {
      handlersRef.current.onCorrection = createBatcher(onCorrections);

      // Prevent dialog from closing when Prolexis is opened
      const cleanup = forceOutsideDialogInteractions();

      return new Promise((resolve, reject) => {
        handlersRef.current.hCallBack = resolve;

        injectScript({
          src: SCRIPT_SRC,
          parent,
          onload: () => {
            window.DiagPlWs.init({
              sUrlProxy: "/api/prolexis/v4", // url vers le proxy
              sCorePath: `/assets/vendors/prolexis@${SCRIPT_VERSION}/`,
              onCorrection: (correction) => {
                handlersRef.current.onCorrection(correction);
              },
              hCallBack: () => {
                handlersRef.current.hCallBack();
              },
              applyCorrections: false,
            });
          },
        })
          .then(() => {
            window.DiagPlWs.analyze(fields);
          })
          .catch(reject);
      }).finally(() => {
        // At the end, restore options
        cleanup();
      });
    },
    [forceOutsideDialogInteractions],
  );

  const value = useMemo(() => ({ analyze }), [analyze]);

  return (
    <ProlexisContext.Provider value={value}>
      {children}
    </ProlexisContext.Provider>
  );
};

export function useProlexis() {
  return useContext(ProlexisContext);
}
