import * as React from "react";
import { forwardRef } from "react";

const SvgGiphy = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.84 1.714h2.33v16.572H1.84V1.714zm13.988 4.572h2.331v12h-2.33v-12z"
      fill="currentColor"
    />
    <path
      d="M1.84 17.714h16.32V20H1.84v-2.286zM1.84 0h9.326v2.286H1.84V0zm13.989 4.571V2.286h-2.332V0h-2.331v6.857h6.994V4.571m-2.332 4.572V6.857h2.331"
      fill="currentColor"
    />
    <path d="M11.166 0v2.286H8.834" fill="currentColor" />
  </svg>
);
const ForwardRef = forwardRef(SvgGiphy);
export default ForwardRef;
