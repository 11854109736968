/**
 * Get block index.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} blockKey
 */
export const getBlockIndex = (editorState, blockKey) => {
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq()
    .findIndex((key) => key === blockKey);
};

/**
 * Get block type.
 * @param {import('draft-js').EditorState} editorState
 */
export const getBlockType = (editorState) => {
  const key = editorState.getSelection().getStartKey();
  if (!key) return null;

  const block = editorState.getCurrentContent().getBlockForKey(key);
  if (!block) return null;

  return block.getType();
};
