import { ContentState } from "draft-js-es";
import memoize from "lodash/memoize";

import { convertToHTML } from "./convertToHTML";

const getHTMLFromBlockFactory = memoize(
  (entityMap) => {
    const fn = memoize((block) => {
      const blockOnlyContentState = ContentState.createFromBlockArray(
        [block],
        entityMap,
      );
      return convertToHTML(blockOnlyContentState);
    });
    fn.cache = new WeakMap();
    return fn;
  },
  (entityMap) => entityMap.__getAll(),
);
getHTMLFromBlockFactory.cache = new WeakMap();

/**
 * Extract HTML from block.
 * @param {any} entityMap
 * @param {import('draft-js').ContentBlock} block
 */
export const getHTMLFromBlock = (entityMap, block) =>
  getHTMLFromBlockFactory(entityMap)(block);
