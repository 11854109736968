/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql, useMutation } from "@apollo/client";
import { memo, useState } from "react";
import { useToaster } from "swash/Toast";
import { cn } from "swash/utils/classNames";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import type { ArticleEditorialLevel_articleFragment } from "@/gql-types";

import { useHasPermission } from "../User";
import { SpreadArticleEditorialLevelIcon } from "./ArticleEditorialLevelIcon";
import {
  ArticleEditorialLevelSelector,
  EditorialLevelButton,
  EditorialLevelButtonProps,
  editorialLevels,
} from "./ArticleEditorialLevelSelect";

const ArticleFragment = gql`
  fragment ArticleEditorialLevel_article on Article {
    id
    editorialLevel
  }
`;

const Mutation = gql`
  mutation ArticleEditorialLevel_updateArticle(
    $articleId: Int!
    $editorialLevel: Int!
  ) {
    updateArticle(input: { id: $articleId, editorialLevel: $editorialLevel }) {
      id
      ...ArticleEditorialLevel_article
    }
  }
  ${ArticleFragment}
`;

type EditableEditorialLevelProps = {
  articleId: number;
  editorialLevel: number;
  scale?: EditorialLevelButtonProps["scale"];
};

const EditableEditorialLevel = memo<EditableEditorialLevelProps>((props) => {
  const tooltip = useArticleAuditTrailTooltip("editorialLevel");
  const [updateEditorialLevel] = useMutation(Mutation);
  const toaster = useToaster();
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));
  const editorialLevel = editorialLevels[props.editorialLevel];
  const handleChange = useEventCallback((value) => {
    updateEditorialLevel({
      variables: {
        articleId: props.articleId,
        editorialLevel: value.value,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateArticle: {
          __typename: "Article",
          id: props.articleId,
          editorialLevel: value.value,
        },
      },
    }).catch(() => {
      toaster.danger("La mise à jour de l’indice a échoué");
    });
  });

  if (!editorialLevel) {
    return null;
  }
  if (active) {
    return (
      <ArticleEditorialLevelSelector
        value={editorialLevel}
        onChange={handleChange}
        scale={props.scale}
        tooltip={tooltip}
      />
    );
  }
  return (
    <EditorialLevelButton
      onMouseEnter={activate}
      onFocus={activate}
      editorialLevel={editorialLevel}
      scale={props.scale}
    />
  );
});

export type ArticleEditorialLevelProps = {
  article: ArticleEditorialLevel_articleFragment;
  scale?: "sm";
  disabled?: boolean;
};

export const ArticleEditorialLevel = (props: ArticleEditorialLevelProps) => {
  const canSee = useHasPermission("editorialLevel:read");
  const canEdit = useHasPermission("editorialLevel:edit");
  if (!canSee && !canEdit) return null;
  if (!props.disabled && canEdit) {
    return (
      <EditableEditorialLevel
        articleId={props.article.id}
        editorialLevel={props.article.editorialLevel!}
        scale={props.scale}
      />
    );
  }
  return (
    <div
      className={cn(
        "flex",
        props.scale === "sm" ? "p-1.5 text-sm" : "p-2 text-base",
      )}
    >
      <SpreadArticleEditorialLevelIcon
        editorialLevel={props.article.editorialLevel!}
      />
    </div>
  );
};

ArticleEditorialLevel.fragments = {
  article: ArticleFragment,
};
