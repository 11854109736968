/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql } from "@apollo/client";
import { Tooltip } from "swash/Tooltip";
import {
  EnumSelectList,
  EnumSelectState,
  useEnumSelectState,
} from "swash/v2/EnumSelect";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { useSafeQuery } from "@/containers/Apollo";
import {
  EditorialFormatChipButton,
  EditorialFormatLabel,
} from "@/containers/article/fields/EditorialFormat";

const EditorialFormatFragment = gql`
  fragment ArticleLayoutEditorialFormatSelect_editorialFormat on EditorialFormat {
    id
    label
    nbCharMax
    ...EditorialFormatLabel_editorialFormat
  }
  ${EditorialFormatLabel.fragments.editorialFormat}
`;

const LayoutEditorialFormatsQuery = gql`
  query ArticleLayoutEditorialFormatsSelect_editorialFormats(
    $layoutId: Int!
    $offset: Int
  ) {
    layout(id: $layoutId) {
      editorialFormats(offset: $offset, where: { enabled: true }, limit: 100) {
        nodes {
          ...ArticleLayoutEditorialFormatSelect_editorialFormat
        }
        pageInfo {
          hasMore
        }
        totalCount
      }
    }
  }
  ${EditorialFormatFragment}
`;

export type EditorialFormat = {
  id: number;
  label: string;
  nbCharMax: number;
};

type LayoutEditorialFormatVariables = {
  layoutId: number;
};

type LayoutEditorialFormatQueryData = {
  layout: {
    editorialFormats: {
      pageInfo: {
        hasMore: boolean;
      };
      totalCount: number;
      nodes: EditorialFormat[];
    };
  };
};

export type ArticleLayoutEditorialFormatSelectStateProps = {
  layoutId: number;
  value?: EditorialFormat | null;
  onChange: (value: EditorialFormat | null) => void;
  required?: boolean;
};

export const useArticleLayoutEditorialFormatSelectState = (
  props: ArticleLayoutEditorialFormatSelectStateProps,
): EnumSelectState<EditorialFormat> => {
  const { data } = useSafeQuery<
    LayoutEditorialFormatQueryData,
    LayoutEditorialFormatVariables
  >(LayoutEditorialFormatsQuery, {
    variables: { layoutId: props.layoutId },
    skip: !props.layoutId,
  });
  const editorialFormats = data?.layout.editorialFormats.nodes ?? [];

  const enumSelect = useEnumSelectState<
    EditorialFormat,
    EditorialFormat | null
  >({
    value: props.value!,
    onChange: props.onChange,
    items: editorialFormats,
    labelSelector: (editorialFormat) => editorialFormat.label,
    labelElementSelector: (editorialFormat) => (
      <EditorialFormatLabel
        label={editorialFormat.label}
        nbCharMax={editorialFormat.nbCharMax}
      />
    ),
    valueSelector: (editorialFormat) => editorialFormat.id.toString(),
    required: false,
  });
  return enumSelect;
};

type ArticleLayoutEditorialFormatSelectorProps = {
  tooltip: string;
} & ArticleLayoutEditorialFormatSelectStateProps;

export const ArticleLayoutEditorialFormatSelector = ({
  tooltip = "Éditer le format",
  ...props
}: ArticleLayoutEditorialFormatSelectorProps) => {
  const state = useArticleLayoutEditorialFormatSelectState(props);
  const open = state.select.useState("open");

  return (
    <span onClick={(event) => event.stopPropagation()}>
      <Tooltip tooltip={open ? null : tooltip}>
        <SelectButton asChild store={state.select}>
          <EditorialFormatChipButton asButton editorialFormat={props.value!} />
        </SelectButton>
      </Tooltip>
      <SelectPopover store={state.select} modal>
        <EnumSelectList state={state} />
      </SelectPopover>
    </span>
  );
};
