export function getBlockEntityKey({ block }) {
  return block.getEntityAt(0) || null;
}

export function getBlockEntity({ block, contentState }) {
  const entityKey = getBlockEntityKey({ block });
  if (!entityKey) return null; // throw new Error('Entity not found')
  const entity = contentState.getEntity(entityKey);
  return entity;
}
