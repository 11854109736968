/* eslint-disable no-param-reassign */
const FLATTEN_SEPARATOR = "___";

const checkIsPlainObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export const flattenObject = (value) => {
  if (!checkIsPlainObject(value)) return value;
  const reduce = (obj, source = {}, prefix = "") => {
    return Object.entries(obj).reduce((res, [key, value]) => {
      if (!checkIsPlainObject(value)) {
        res[`${prefix}${key}`] = value;
        return res;
      }
      reduce(value, source, `${prefix}${key}${FLATTEN_SEPARATOR}`);
      return res;
    }, source);
  };
  return reduce(value);
};

export const unflattenObject = (value) => {
  if (!checkIsPlainObject(value)) return value;
  return Object.entries(value).reduce((res, [key, value]) => {
    if (key.includes(FLATTEN_SEPARATOR)) {
      const keys = key.split(FLATTEN_SEPARATOR);
      keys.reduce((obj, key, index) => {
        const last = index === keys.length - 1;
        if (last) {
          obj[key] = value;
          return null;
        }
        obj[key] = obj[key] || {};
        return obj[key];
      }, res);
      return res;
    }
    res[key] = value;
    return res;
  }, {});
};
