import { gql } from "@apollo/client";
import axios from "axios";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "swash/Button";
import { Link } from "swash/Link";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { createServiceForm } from "@/containers/admin/Integration";

import slackLogo from "./logo.png";

export { default as logo } from "./logo.png";

function translateError(error) {
  switch (error) {
    case "canceled":
      return "Vous devez autoriser Sirius à accéder à votre Slack";
    case "invalid_token":
      return "Votre token d’accès n’est plus valide, merci de réinstaller l’application Sirius sur Slack";
    case "missing_scope":
      return "Autorisation manquante, veuillez réinstaller l’application Sirius sur Slack pour profiter de l’ensemble des fonctionnalités";
    case "default":
    default:
      return "Une erreur s’est produite, merci de réessayer";
  }
}

export const IntegrationConfigFragment = gql`
  fragment SlackIntegrationConfigFragment on SlackIntegrationConfig {
    slackAccessToken: accessToken
    teamId
  }
`;

const LinkingButton = (props) => {
  const {
    integrationState,
    onLinkAccount: handleLinkAccount,
    onUnlinkAccount: handleUnlinkAccount,
    loading,
  } = props;
  const { handleClick, label, variant } = (() => {
    switch (integrationState) {
      case "configured":
        return {
          handleClick: handleUnlinkAccount,
          label: "Désinstaller",
          variant: "danger",
        };
      case "outdated":
        return {
          handleClick: handleLinkAccount,
          label: "Réinstaller",
          variant: "warning",
        };
      case "missing":
      default:
        return { handleClick: handleLinkAccount, label: "Ajouter à Slack" };
    }
  })();
  return (
    <>
      <Button onClick={handleClick} disabled={loading} variant={variant}>
        {label}
      </Button>
      {integrationState === "outdated" && (
        <LinkingButton {...props} integrationState="configured" />
      )}
    </>
  );
};

function Fields({ initialValues }) {
  const config = useRemoteConfig();
  const [{ isLoading, error, teamInfo }, setDataState] = useState({
    isLoading: false,
    error: null,
    teamInfo: null,
  });

  const location = useLocation();
  const locationSearchRef = useRef(location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(locationSearchRef.current);
    if (searchParams.get("error")) {
      setDataState((prevState) => ({
        ...prevState,
        error: translateError(searchParams.get("error")),
      }));
    }
  }, [locationSearchRef]);

  const hasAccessToken = initialValues.slackAccessToken;
  const integrationState = error
    ? initialValues.slackAccessToken
      ? "outdated"
      : "missing"
    : initialValues.slackAccessToken
      ? "configured"
      : "missing";

  // Getting team info when page mounts
  useEffect(() => {
    const getTeamInfo = async () => {
      try {
        const teamResponse = await axios.get("/api/slack/team");
        return setDataState((prevState) => ({
          ...prevState,
          isLoading: false,
          teamInfo: teamResponse.data.team,
        }));
      } catch (error) {
        return setDataState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: translateError(error.response.data.error),
        }));
      }
    };

    if (hasAccessToken) {
      setDataState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      getTeamInfo();
    }
  }, [hasAccessToken]);

  const handleLinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const params = queryString.stringify({
      client_id: config.slack.clientId,
      redirect_uri: config.slack.redirectUri,
      scope: config.slack.scopes.join(","),
    });
    window.location.href = `https://slack.com/oauth/v2/authorize?${params}`;
  };

  const handleUnlinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await axios.get("api/slack/oauth/revoke");
      return window.location.reload();
    } catch (error) {
      return setDataState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: error.response.data.error,
      }));
    }
  };

  const logoSrc = teamInfo?.icon?.image_88 ?? slackLogo;

  return (
    <Fieldset>
      <FieldsetField>
        <div className="mb-2 flex items-center justify-between gap-4 rounded-md border-2 border-grey-border-light p-4">
          <img src={logoSrc} alt="Slack icon" className="h-10 w-10" />
          <div className="grow">
            <div className="font-semibold">Slack</div>
            {hasAccessToken ? (
              teamInfo && <Link href={teamInfo.url}>{teamInfo.name}</Link>
            ) : (
              <div>
                Vous n’avez pas encore lié l’application Sirius à votre espace
                de travail Slack nous permettant de vous envoyer des
                notifications.
              </div>
            )}
          </div>
          <LinkingButton
            integrationState={integrationState}
            onLinkAccount={handleLinkAccount}
            onUnlinkAccount={handleUnlinkAccount}
            loading={isLoading}
          />
        </div>
        {error && <div className="mb-2 text-danger-on-light">{error}</div>}
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
