import { debounce } from "lodash-es";
import { useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

const checkIsVisible = () =>
  document.visibilityState === undefined ||
  document.visibilityState !== "hidden";

/**
 * Due to bug https://bugs.chromium.org/p/chromium/issues/detail?id=678075,
 * it's not reliable to detect if the browser is currently online or offline
 * based on `navigator.onLine`.
 * As a workaround, we always assume it's online on first load, and change
 * the status upon `online` or `offline` events.
 */
let online = true;
const checkIsOnline = () => online;

const checkIsActive = () => checkIsVisible() && checkIsOnline();

const initReconnect = (callback) => {
  // revalidate on reconnected
  const onOnline = () => {
    online = true;
    callback();
  };
  // nothing to revalidate, just update the status
  const onOffline = () => {
    online = false;
  };
  window.addEventListener("online", onOnline);
  window.addEventListener("offline", onOffline);
  return () => {
    window.removeEventListener("online", onOnline);
    window.removeEventListener("offline", onOffline);
  };
};

const initFocus = (callback) => {
  // focus revalidate
  document.addEventListener("visibilitychange", callback);
  window.addEventListener("focus", callback);
  return () => {
    document.removeEventListener("visibilitychange", callback);
    window.removeEventListener("focus", callback);
  };
};

const initCustomEvent = (callback) => {
  document.addEventListener("sirius-revalidate", callback);
  return () => {
    document.removeEventListener("sirius-revalidate", callback);
  };
};

const init = (callback) => {
  const offReconnect = initReconnect(callback);
  const offFocus = initFocus(callback);
  const offCustomEvent = initCustomEvent(callback);
  return () => {
    offReconnect();
    offFocus();
    offCustomEvent();
  };
};

const callbacks = [];

const run = () => {
  callbacks.forEach((c) => c());
};

let cleanup = null;
const teardown = () => {
  if (cleanup && callbacks.length === 0) {
    cleanup();
    cleanup = null;
  }
};
const setup = () => {
  if (!cleanup && callbacks.length > 0) {
    cleanup = init(run);
  }
};

const listenActive = (callback) => {
  callbacks.push(callback);
  setup();
  return () => {
    const index = callbacks.findIndex((c) => c === callback);
    callbacks.splice(index, 1);
    teardown();
  };
};

/**
 * A smart fetch that call a function in a smart way.
 * It is inspired by [SWR revalidation system](https://swr.vercel.app/docs/revalidation).
 * - When the window takes the focus
 * - When the window becomes visible
 * - When the navigator is online
 * - With a continuous refresh, when the user is active on the window
 * @param {() => void} fn
 * @param {object} [options]
 * @param {number | null} [options.refreshInterval]
 */
export const useRevalidate = (fn, { refreshInterval = 10000 } = {}) => {
  const fnRef = useLiveRef(fn);
  useEffect(() => {
    let interval;
    const run = debounce(() => {
      if (checkIsActive()) {
        fnRef.current();
      }
    }, 32);
    const setupInterval = () => {
      clearInterval(interval);
      if (refreshInterval !== null) {
        interval = setInterval(run, refreshInterval);
      }
    };
    const unlistenActive = listenActive(() => {
      run();
      setupInterval();
    });
    return () => {
      unlistenActive();
      clearInterval(interval);
    };
  }, [fnRef, refreshInterval]);
};
