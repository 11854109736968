import { getIn, setIn } from "final-form";
import { forwardRef, useEffect, useState } from "react";
import { type FormRenderProps, useForm } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";

import {
  ActiveProvider,
  useActiveState,
} from "@/components/forms/CollaborativeFormState";

import { BaseForm, type BaseFormProps } from "./BaseForm";

const InitialValuesInjector: React.FC<{
  initialValues?: Record<string, any>;
  readOnly?: boolean;
}> = ({ initialValues = {}, readOnly }) => {
  const form = useForm();
  const activeState = useActiveState();
  const activeStateRef = useLiveRef(activeState);

  // When `initialValues` changes, inject values in form
  // only for non-active fields
  useEffect(() => {
    const activeState = activeStateRef.current;

    // If form is in readOnly mode, do not block any incoming update
    if (readOnly) {
      form.initialize(initialValues);
      return;
    }

    // If form is active, then block any incoming update
    if (!activeState || activeState.form) return;
    const { active, values } = form.getState();
    const activeFields = [
      ...new Set(active ? [...activeState.fields, active] : activeState.fields),
    ];
    const resultValues = activeFields.reduce((initialValues, field) => {
      return setIn({ ...initialValues }, field, getIn(values, field));
    }, initialValues);
    form.initialize(resultValues);
  }, [form, initialValues, activeStateRef, readOnly]);

  return null;
};

export type CollaborativeFormProps<FormValues = Record<string, any>> = Omit<
  BaseFormProps<FormValues>,
  "children"
> & {
  children: React.FC<FormRenderProps<FormValues>>;
  readOnly?: boolean;
};

export const CollaborativeForm = forwardRef<unknown, CollaborativeFormProps>(
  ({ name, initialValues, onSubmit, readOnly, children, ...props }, ref) => {
    const [initialInitialValues] = useState(initialValues);
    return (
      <BaseForm
        ref={ref}
        name={name}
        onSubmit={onSubmit}
        initialValues={initialInitialValues}
        {...props}
      >
        {(options: FormRenderProps) => {
          return (
            <ActiveProvider>
              <InitialValuesInjector
                initialValues={initialValues}
                readOnly={readOnly}
              />
              {children(options)}
            </ActiveProvider>
          );
        }}
      </BaseForm>
    );
  },
) as <FormValues = Record<string, any>>(
  props: CollaborativeFormProps<FormValues> & {
    ref?: React.ForwardedRef<HTMLElement>;
  },
) => React.ReactElement;
