function is(x: any, y: any): boolean {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}

/**
 * Shallow equal function from Redux.
 */
export function shallowEqual(objA: any, objB: any): boolean {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    const kA = keysA[i];
    if (
      kA === undefined ||
      !Object.prototype.hasOwnProperty.call(objB, kA) ||
      !is(objA[kA], objB[kA])
    ) {
      return false;
    }
  }

  return true;
}
