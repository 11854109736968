import { x } from "@xstyled/styled-components";
import { memo } from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { Button } from "swash/Button";
import { DialogLayout } from "swash/DialogLayout";
import { IoAlertCircleOutline } from "swash/Icon";
import { PanelBody } from "swash/Panel";

import { Backdrop } from "@/components/Backdrop";

export const NotFound = memo(() => {
  return (
    <>
      <Backdrop />
      <DialogLayout style={{ width: 800 }} className="py-8">
        <PanelBody className="flex flex-col items-center justify-center">
          <x.div
            display="inline-block"
            as={IoAlertCircleOutline}
            mb={3}
            h={27}
            w={27}
          />
          <x.div mb={5} fontWeight="600" fontSize="xl" fontFamily="accent">
            Page introuvable
          </x.div>
          <x.div mb={5} w={27} h={3} backgroundColor="blue-light" />
          <x.div mb={3} fontWeight="strong">
            La page que vous recherchez n’existe pas.
          </x.div>
          <x.div mb={3}>
            <Button asChild>
              <ReactRouterLink to="/">Retour à l’accueil</ReactRouterLink>
            </Button>
          </x.div>
        </PanelBody>
      </DialogLayout>
    </>
  );
});
