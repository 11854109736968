import { gql } from "@apollo/client";
import moment from "moment/moment";
import { IoCheckmark } from "swash/Icon";

import { Time } from "@/components/Time";

interface Comment {
  resolved: boolean;
  resolvedAt: string;
  resolvedBy: {
    firstNameInitials: string;
    lastName: string;
  };
}

interface ResolvedCommentBlockInfosProps {
  comment: Comment;
}

export const ResolvedCommentBlockInfos = ({
  comment,
}: ResolvedCommentBlockInfosProps) => {
  if (!comment.resolved || !comment.resolvedBy) return null;
  return (
    <span className="max-w-full truncate text-grey-on">
      <IoCheckmark className="inline-block" />
      {` Résolu par ${comment.resolvedBy.firstNameInitials} ${comment.resolvedBy.lastName} le `}
      <Time date={comment.resolvedAt}>
        {moment(comment.resolvedAt).format("DD/MM/YYYY à HH:mm")}
      </Time>
    </span>
  );
};

ResolvedCommentBlockInfos.fragments = {
  commentThread: gql`
    fragment ResolvedCommentBlockInfos_commentThread on CommentThread {
      id
      resolved
      resolvedAt
      resolvedBy {
        id
        firstNameInitials
        lastName
      }
    }
  `,
};
