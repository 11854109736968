import * as React from "react";
import { forwardRef } from "react";

const SvgNewspaperOutline = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M17.25 19.493V3.375a1.128 1.128 0 0 0-1.125-1.125H3.375A1.128 1.128 0 0 0 2.25 3.375v16.5a1.88 1.88 0 0 0 1.875 1.875H19.5"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 21.75a2.25 2.25 0 0 1-2.25-2.25V6h3.375a1.125 1.125 0 0 1 1.125 1.125V19.5a2.25 2.25 0 0 1-2.25 2.25Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M11.25 6h3M11.25 9h3M5.25 12h9M5.25 15h9M5.25 18h9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 9.75h-3A.75.75 0 0 1 4.5 9V6a.75.75 0 0 1 .75-.75h3A.75.75 0 0 1 9 6v3a.75.75 0 0 1-.75.75Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNewspaperOutline);
export default ForwardRef;
