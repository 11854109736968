import { gql } from "@apollo/client";
import { memo } from "react";

import { HasOneField } from "@/containers/admin/CRUD";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const FlagFragment = gql`
  fragment FlagFragment_live on Flag {
    id
    label
    color
    rank
  }
`;

const ConnectionQuery = gql`
  query FlagFieldFlagsQuery($search: String, $offset: Int, $limit: Int) {
    connection: flags(
      offset: $offset
      limit: $limit
      where: { enabled: true, search: $search }
    ) {
      nodes {
        ...FlagFragment_live
      }
      ...ConnectionFragment
    }
  }
  ${FlagFragment}
  ${ConnectionFragment}
`;

export const FlagField = memo(({ disabled }) => {
  return (
    <HasOneField
      query={ConnectionQuery}
      fragment={FlagFragment}
      modelName="Flag"
      scale="sm"
      name="flagId"
      aria-label="Drapeau"
      placeholder="Drapeau"
      clearable
      searchable
      disabled={disabled}
    />
  );
});

FlagField.fragments = {
  post: gql`
    fragment FlagField_post on Post {
      flagId
    }
  `,
};
