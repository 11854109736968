import { useLiveRef } from "./useLiveRef";
import { useRefHandler } from "./useRefHandler";

/**
 * Listen scroll event.
 */
export const useScrollListener = (listener: (event: Event) => void) => {
  const listenerRef = useLiveRef(listener);
  return useRefHandler<HTMLElement | null>((element) => {
    if (!element) return undefined;
    let ticking = false;
    const listener = (ev: Event) => {
      if (ticking) return;
      window.requestAnimationFrame(() => {
        listenerRef.current(ev);
        ticking = false;
      });
      ticking = true;
    };
    element.addEventListener("scroll", listener, { passive: true });
    return () => {
      element.removeEventListener("scroll", listener);
    };
  });
};
