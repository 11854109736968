type MediaQuery = string;

interface MediaQueryScales {
  sm?: MediaQuery;
  md?: MediaQuery;
}

interface MediaQueryResources {
  article?: MediaQueryScales;
  list?: MediaQueryScales;
}

interface MediaQueries {
  desktop?: MediaQueryResources;
  mobile?: MediaQueryResources;
}

interface SiriusMediaQueries extends MediaQueries {
  desktop: {
    article: {
      sm: MediaQuery;
      md: MediaQuery;
    };
    list: {
      md: MediaQuery;
    };
  };
  mobile: {
    article: {
      md: MediaQuery;
      sm: MediaQuery;
    };
  };
}

export const mediaQueries: SiriusMediaQueries = {
  desktop: {
    article: {
      sm: "(max-width: 950px)",
      md: "(max-width: 1280px)",
    },
    list: {
      md: "(max-width: 1280px)",
    },
  },
  mobile: {
    article: {
      md: "(max-device-width: 768px)",
      sm: "(max-device-width: 450px)",
    },
  },
};
