import { exclude } from "./exclude";
import { include } from "./include";
import { sentence } from "./sentence";

export const operators = [include, exclude, sentence];

type Operator = (typeof operators)[number];

export type OperatorNames = Operator["name"];

export type WordOperator = Operator & { type: "word" };
export type TextOperator = Operator & { type: "text" };

export const wordOperators = [
  ...operators.filter(({ type, isDefault }) => type === "word" && !isDefault),
  ...operators.filter(({ isDefault }) => isDefault),
] as WordOperator[];

export const textOperators = operators.filter(
  ({ type }) => type === "text",
) as TextOperator[];
