import { gql } from "@apollo/client";
import { Link } from "swash/Link";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.png";

export const IntegrationConfigFragment = gql`
  fragment DailymotionIntegrationConfigFragment on DailymotionIntegrationConfig {
    apiKey
    apiSecret
    channel
    playerId
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="apiKey" label="API Key" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="apiSecret" label="API Secret" required />
      </FieldsetField>
      <FieldsetField>
        <StringField
          name="channel"
          label="Chaîne utilisée pour la recherche"
          required
        />
      </FieldsetField>
      <FieldsetField>
        <StringField
          hint={
            <>
              L’identifiant du lecteur qui sera utilisé pour intégrer les vidéos
              Dailymotion dans Sirius. (
              <Link
                href="https://developers.dailymotion.com/player/"
                target="_blank"
              >
                Documentation du lecteur Dailymotion
              </Link>
              )
            </>
          }
          name="playerId"
          label="Player ID"
          required
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
