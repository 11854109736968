import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormError } from "@/components/forms/FormError";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { HeaderToolbarItem } from "@/components/teleporters/HeaderToolbar";

import { SnippetCodeEditor } from "./SnippetCodeEditor";

function FormToolbarItem() {
  return (
    <HeaderToolbarItem>
      <div className="order-1 -m-2 flex grow flex-wrap items-center justify-between">
        <div className="max-w-full grow basis-0 p-2">
          <FormError />
        </div>
      </div>
    </HeaderToolbarItem>
  );
}

export const SnippetForm = ({
  initialValues,
  onSubmit,
  snippet,
  editor,
  operations,
}) => {
  return (
    <Form
      collaborative
      initialValues={initialValues}
      onSubmit={onSubmit}
      style={{ height: "100%" }}
    >
      <FormAutoSubmit />
      <FormToolbarItem />
      <FormSubmittingPrompt />
      <SnippetCodeEditor
        editor={editor}
        snippet={snippet}
        operations={operations}
      />
    </Form>
  );
};
