import { EditorState } from "draft-js-es";

import { CompoundDecorator } from "../utils/CompoundDecorator";

/**
 * @typedef {import('draft-js').DraftDecorator} DraftDecorator
 */

/**
 * Add decorator.
 * @param {import('draft-js').EditorState} editorState
 * @param {DraftDecorator | DraftDecorator[]} decorator
 */
export function addDecorator(editorState, decorator) {
  const decorators = [decorator].flat();
  const currentDecorators = editorState.getDecorator()?.getDecorators() ?? [];
  return EditorState.set(editorState, {
    decorator: new CompoundDecorator([...currentDecorators, ...decorators]),
  });
}
