import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { useAnchorProps } from "./Anchor";

export type LinkProps = {
  /**
   * Inherit the color from the parent.
   */
  inherit?: boolean;

  /**
   * Use this prop to use the child as the link.
   */
  asChild?: boolean;

  /**
   * Add an icon to the link.
   */
  icon?: React.ReactNode;
} & React.ComponentProps<"a">;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, asChild, inherit, className, icon, ...props }, ref) => {
    const anchorProps = useAnchorProps(props);
    const childProps = {
      ref,
      className: clsx(
        className,
        "cursor-pointer no-underline",
        icon && "inline-flex items-center gap-1.5",
        inherit ? "text-inherit" : "text-primary-on",
        "hover:text-primary-on-hover hover:underline",
      ),
      ...anchorProps,
    };

    if (asChild) {
      const child = children as React.ReactElement;
      if (icon) {
        childProps.children = [icon, child.props.children];
      }
      return React.cloneElement(child, childProps);
    }

    return (
      <a {...childProps}>
        {icon}
        {children}
      </a>
    );
  },
);
